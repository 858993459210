// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-responsive.units-table .ant-table-cell.ant-table-selection-column {
  display: none;
}
.table-responsive.units-table .ant-table-cell:nth-child(2) {
  width: 80%;
}
.table-responsive.units-table .ant-table-cell:nth-child(3) {
  width: 20%;
}`, "",{"version":3,"sources":["webpack://./src/feature-module/inventory/units.scss"],"names":[],"mappings":"AACI;EACI,aAAA;AAAR;AAEI;EACI,UAAA;AAAR;AAEI;EACI,UAAA;AAAR","sourcesContent":[".table-responsive.units-table {\n    .ant-table-cell.ant-table-selection-column {\n        display: none;\n    }\n    .ant-table-cell:nth-child(2) {\n        width: 80%;\n    }\n    .ant-table-cell:nth-child(3) {\n        width: 20%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
