export const unitsdata = [
  // {
  // 	"id":1,
  // 	"unit": "Piece",
  // 	"shortname": "PC",
  // 	"noofproducts": "25",
  // 	"createdon": "25 May 2023",
  // 	"status": "Active",

  // },
  // {
  //     "id":2,
  // 	"unit": "Kilogram",
  // 	"shortname": "KG",
  // 	"noofproducts": "15",
  // 	"createdon": "24 Jun 2023",
  // 	"status": "Active",

  // },
  // {
  //     "id":3,
  // 	"unit": "Gram",
  // 	"shortname": "G",
  // 	"noofproducts": "47",
  // 	"createdon": "23 Jul 2023",
  // 	"status": "Active",

  // },
  // {
  //     "id":4,
  // 	"unit": "Meter",
  // 	"shortname": "M",
  // 	"noofproducts": "28",
  // 	"createdon": "22 Aug 2023",
  // 	"status": "Active",

  // },
  // {
  //     "id":5,
  // 	"unit": "Liter",
  // 	"shortname": "L",
  // 	"noofproducts": "47",
  // 	"createdon": "21 Sep 2023",
  // 	"status": "Active",

  // },
  // {
  //     "id":6,
  // 	"unit": "Kilo",
  // 	"shortname": "K",
  // 	"noofproducts": "74",
  // 	"createdon": "20 Sep 2023",
  // 	"status": "Active",

  // }

  { id: 1, unit: "BAGS", quantitytype: "Measure", shortname: "BAG" },
  { id: 2, unit: "BALE", quantitytype: "Measure", shortname: "BAL" },
  { id: 3, unit: "BUNDLES", quantitytype: "Measure", shortname: "BDL" },
  { id: 4, unit: "BUCKLES", quantitytype: "Measure", shortname: "BKL" },
  {
    id: 5,
    unit: "BILLIONS OF UNITS",
    quantitytype: "Measure",
    shortname: "BOU",
  },
  { id: 6, unit: "BOX", quantitytype: "Measure", shortname: "BOX" },
  { id: 7, unit: "BOTTLES", quantitytype: "Measure", shortname: "BTL" },
  { id: 8, unit: "BUNCHES", quantitytype: "Measure", shortname: "BUN" },
  { id: 9, unit: "CANS", quantitytype: "Measure", shortname: "CAN" },
  {
    id: 10,
    unit: "CUBIC METER",
    quantitytype: "Volume",
    shortname: "CBM",
  },
  {
    id: 11,
    unit: "CUBIC CENTIMETER",
    quantitytype: "Volume",
    shortname: "CCM",
  },
  {
    id: 12,
    unit: "CENTIMETER",
    quantitytype: "Length",
    shortname: "CMS",
  },
  {
    id: 13,
    unit: "CARTONS",
    quantitytype: "Measure",
    shortname: "CTN",
  },
  { id: 14, unit: "DOZEN", quantitytype: "Measure", shortname: "DOZ" },
  { id: 15, unit: "DRUM", quantitytype: "Measure", shortname: "DRM" },
  {
    id: 16,
    unit: "GREAT GROSS",
    quantitytype: "Measure",
    shortname: "GGR",
  },
  { id: 17, unit: "GRAMS", quantitytype: "Weight", shortname: "GMS" },
  { id: 18, unit: "GROSS", quantitytype: "Measure", shortname: "GRS" },
  {
    id: 19,
    unit: "GROSS YARDS",
    quantitytype: "Length",
    shortname: "GYD",
  },
  {
    id: 20,
    unit: "KILOGRAMS",
    quantitytype: "Weight",
    shortname: "KGS",
  },
  {
    id: 21,
    unit: "KILOLITER",
    quantitytype: "Volume",
    shortname: "KLR",
  },
  {
    id: 22,
    unit: "KILOMETRE",
    quantitytype: "Length",
    shortname: "KME",
  },
  {
    id: 23,
    unit: "MILLILITRE",
    quantitytype: "Volume",
    shortname: "MLT",
  },
  { id: 24, unit: "METERS", quantitytype: "Length", shortname: "MTR" },
  {
    id: 25,
    unit: "METRIC TONS",
    quantitytype: "Weight",
    shortname: "MTS",
  },
  {
    id: 26,
    unit: "NUMBERS",
    quantitytype: "Measure",
    shortname: "NOS",
  },
  { id: 27, unit: "PACKS", quantitytype: "Measure", shortname: "PAC" },
  { id: 28, unit: "PIECES", quantitytype: "Measure", shortname: "PCS" },
  { id: 29, unit: "PAIRS", quantitytype: "Measure", shortname: "PRS" },
  { id: 30, unit: "QUINTAL", quantitytype: "Weight", shortname: "QTL" },
  { id: 31, unit: "ROLLS", quantitytype: "Measure", shortname: "ROL" },
  { id: 32, unit: "SETS", quantitytype: "Measure", shortname: "SET" },
  {
    id: 33,
    unit: "SQUARE FEET",
    quantitytype: "Area",
    shortname: "SQF",
  },
  {
    id: 34,
    unit: "SQUARE METERS",
    quantitytype: "Area",
    shortname: "SQM",
  },
  {
    id: 35,
    unit: "SQUARE YARDS",
    quantitytype: "Area",
    shortname: "SQY",
  },
  {
    id: 36,
    unit: "TABLETS",
    quantitytype: "Measure",
    shortname: "TBS",
  },
  {
    id: 37,
    unit: "TEN GROSS",
    quantitytype: "Measure",
    shortname: "TGM",
  },
  {
    id: 38,
    unit: "THOUSANDS",
    quantitytype: "Measure",
    shortname: "THD",
  },
  { id: 39, unit: "TONNES", quantitytype: "Weight", shortname: "TON" },
  { id: 40, unit: "TUBES", quantitytype: "Measure", shortname: "TUB" },
  {
    id: 41,
    unit: "US GALLONS",
    quantitytype: "Volume",
    shortname: "UGS",
  },
  { id: 42, unit: "UNITS", quantitytype: "Measure", shortname: "UNT" },
  { id: 43, unit: "YARDS", quantitytype: "Length", shortname: "YDS" },
  { id: 44, unit: "OTHERS", quantitytype: "", shortname: "OTH" },
];
