/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  ChevronUp,
  Filter,
  PlusCircle,
  RotateCcw,
  Sliders,
  StopCircle,
  Zap,
} from "feather-icons-react/build/IconComponents";
import { useDispatch, useSelector } from "react-redux";
import {
  setToogleHeader,
  detailData,
  loaderData,
} from "../../core/redux/action";
import Select from "react-select";
import AddSubcategory from "../../core/modals/inventory/addsubcategory";
import EditSubcategories from "./editsubcategories";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import Table from "../../core/pagination/datatable";
import axios from "axios";
import { API_URL } from "../../constants/AppConstants";
import { subCategoryData, updatePage } from "../../core/redux/action";
import FilterModal from "../../core/modals/FilterModal";
import { fetchSubCategoryData } from "../../utils/utilities";

const SubCategories = () => {
  const dispatch = useDispatch();
  const login_data = useSelector((state) => state.login_data);

  const data = useSelector((state) => state.toggle_header);
  const selected_store = useSelector((state) => state.selected_store);
  const subcategory_data = useSelector((state) => state.subcategory_data);
  const category_data = useSelector((state) => state.category_data);
  const [isCategoryUpdated, setIsCategoryUpdated] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [filters, setFilters] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState(subcategory_data);
  const [filterOptions, setFilterOptions] = useState({ parentCategories: [] });

  const oldandlatestvalue = [
    { value: "date", label: "Sort by Date" },
    { value: "newest", label: "Newest" },
    { value: "oldest", label: "Oldest" },
  ];
  const fruits = [{ value: null, label: "Choose" }];

  const category = [{ value: null, label: "Choose" }];
  const categorycode = [{ value: null, label: "Category" }];

  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const toggleFilterVisibility = () => {
    setIsFilterVisible((prevVisibility) => !prevVisibility);
  };

  const filtersConfig = [
    {
      key: "parentCategory",
      label: "Parent Category",
      type: "select",
      options: filterOptions.parentCategories,
      placeholder: "Select Parent Category",
    },
    {
      key: "status",
      label: "Status",
      type: "select",
      options: [
        { value: "active", label: "Active" },
        { value: "inactive", label: "Inactive" },
      ],
      placeholder: "Select Status",
    },
    {
      key: "sortOrder",
      label: "Sort By Date",
      type: "select",
      options: [
        { value: "newToOld", label: "Newest to Oldest" },
        { value: "oldToNew", label: "Oldest to Newest" },
      ],
      placeholder: "Sort Order",
    },
  ];


  useEffect(()=> {
    console.log(login_data);
  },[])

  const handleApplyFilters = (newFilters) => {
    setFilters(newFilters);
  };

  const getFilterOptions = (subcategories) => {
    const parentCategoryOptions = [];
    subcategories.forEach((subcategory) => {
      if (
        subcategory.parentCategory &&
        !parentCategoryOptions.find(
          (option) => option.value === subcategory.parentCategory.categoryName
        )
      ) {
        parentCategoryOptions.push({
          label: subcategory.parentCategory.categoryName,
          value: subcategory.parentCategory.categoryName,
        });
      }
    });
    return { parentCategoryOptions };
  };

  useEffect(() => {
    const { parentCategoryOptions } = getFilterOptions(subcategory_data);
    setFilterOptions({
      parentCategories: parentCategoryOptions,
    });
  }, []);

  useEffect(() => {
    setFilteredData(subcategory_data);
  }, [subcategory_data]);

  useEffect(() => {
    if (searchTerm) {
      let subCategoryArray = [...subcategory_data];
      let filteredArray = [];
      filteredArray = subCategoryArray.filter((item) =>
        item.subCategoryName.toLowerCase().includes(searchTerm)
      );
      setFilteredData(filteredArray);
    } else {
      setFilteredData(subcategory_data);
    }
  }, [searchTerm]);

  const renderTooltip = (props) => (
    <Tooltip id="pdf-tooltip" {...props}>
      Pdf
    </Tooltip>
  );
  const renderExcelTooltip = (props) => (
    <Tooltip id="excel-tooltip" {...props}>
      Excel
    </Tooltip>
  );
  const renderPrinterTooltip = (props) => (
    <Tooltip id="printer-tooltip" {...props}>
      Printer
    </Tooltip>
  );
  const renderRefreshTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Refresh
    </Tooltip>
  );
  const renderCollapseTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Collapse
    </Tooltip>
  );
  const columns = [
    // {
    //   title: "Image",
    //   dataIndex: "logo",
    //   render: (text, record) => (
    //     <span className="productimgname">
    //       <Link to="#" className="product-img stock-img">
    //         <ImageWithBasePath alt="" src={record.img} />
    //       </Link>
    //     </span>
    //   ),
    //   //   sorter: (a, b) => a.category.length - b.category.length,
    // },
    {
      title: "Sub Category",
      dataIndex: "subCategoryName",
      //   sorter: (a, b) => a.category.length - b.category.length,
    },
    {
      title: "Parent Category",
      dataIndex: "parentCategory",
      render: (item) => <span>{item?.categoryName}</span>,
      //   sorter: (a, b) => a.parentcategory.length - b.parentcategory.length,
    },
    {
      title: "Status",
      dataIndex: "subCategoryStatus",
      render: (text) => (
        <span
          className={`badge ${text ? "badge-linesuccess" : "badge-linedanger"}`}
        >
          {text ? "Active" : "InActive"}
        </span>
      ),
    },
    // {
    //   title: "Description",
    //   dataIndex: "description",
    //   //   sorter: (a, b) => a.description.length - b.description.length,
    // },
    // {
    //   title: "Created By",
    //   dataIndex: "createdby",
    //   //   sorter: (a, b) => a.createdby.length - b.createdby.length,
    // },

    {
      title: "Actions",
      dataIndex: "_id",
      render: (data) => (
        <td className="action-table-data">
          <div className="edit-delete-action">
            <Link
              className="me-2 p-2"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#edit-subcategory"
              onClick={() => {
                dispatch(
                  detailData({
                    type: "Sub-Category",
                    id: data,
                  })
                );
              }}
            >
              <i data-feather="edit" className="feather-edit"></i>
            </Link>
            <Link
              className="confirm-text p-2"
              onClick={(e) => {
                e.preventDefault();
                deleteData(data);
              }}
            >
              <i data-feather="trash-2" className="feather-trash-2"></i>
            </Link>
          </div>
        </td>
      ),
    },
  ];
  const MySwal = withReactContent(Swal);

  useEffect(() => {
    fetchSubCategoryData(login_data, selected_store[0]);
  }, [selected_store, isCategoryUpdated]);

  async function deleteData(dataId) {
    dispatch(
      loaderData({
        type: "Loader_Data",
        payload: true,
      })
    );
    if (dataId) {
      try {
        let response = await axios.put(
          API_URL + "sub_category/delete/" + dataId
        );
        if (response.status === 200) {
          fetchSubCategoryData(login_data, selected_store[0]);
          dispatch(
            loaderData({
              type: "Loader_Data",
              payload: false,
            })
          );
        }
      } catch (err) {
        console.log(err);
      }
    }
  }

  const showConfirmationAlert = () => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes, delete it!",
      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        MySwal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          className: "btn btn-success",
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      } else {
        MySwal.close();
      }
    });
  };

  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Sub Category list</h4>
                <h6>Manage your subcategories</h6>
              </div>
            </div>
            <ul className="table-top-head">
              {/* <li>
                <OverlayTrigger placement="top" overlay={renderTooltip}>
                  <Link>
                    <ImageWithBasePath
                      src="assets/img/icons/pdf.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderExcelTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top">
                    <ImageWithBasePath
                      src="assets/img/icons/excel.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderPrinterTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top">
                    <i data-feather="printer" className="feather-printer" />
                  </Link>
                </OverlayTrigger>
              </li> */}
              <li>
                <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top">
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderCollapseTooltip}>
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    id="collapse-header"
                    className={data ? "active" : ""}
                    onClick={() => {
                      dispatch(setToogleHeader(!data));
                    }}
                  >
                    <ChevronUp />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
            <div className="page-btn">
              <Link
                to="#"
                className="btn btn-added"
                data-bs-toggle="modal"
                data-bs-target="#add-subcategory"
              >
                <PlusCircle className="me-2" />
                Add Sub Category
              </Link>
            </div>
          </div>
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <input
                      type="text"
                      placeholder="Search"
                      className="form-control form-control-sm formsearch"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <Link to className="btn btn-searchset">
                      <i data-feather="search" className="feather-search" />
                    </Link>
                  </div>
                </div>
              </div>
              {/* /Filter */}
              <div
                className={`card${isFilterVisible ? " visible" : ""}`}
                id="filter_inputs"
                style={{ display: isFilterVisible ? "block" : "none" }}
              >
                <div className="card-body pb-0">
                  <div className="row">
                    <div className="col-lg-3 col-sm-6 col-12">
                      <div className="input-blocks">
                        <i data-feather="zap" className="info-img" />
                        <Select
                          options={category}
                          className="select"
                          placeholder="Choose Category"
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-12">
                      <div className="input-blocks">
                        <i data-feather="zap" className="info-img" />
                        <Zap className="info-img" />

                        <Select
                          className="select"
                          options={fruits}
                          placeholder="Newest"
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-12">
                      <div className="input-blocks">
                        <i data-feather="stop-circle" className="info-img" />
                        <StopCircle className="info-img" />

                        <Select
                          className="select"
                          options={category_data}
                          placeholder="Newest"
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-12 ms-auto">
                      <div className="input-blocks">
                        <Link className="btn btn-filters ms-auto">
                          {" "}
                          <i
                            data-feather="search"
                            className="feather-search"
                          />{" "}
                          Search{" "}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /Filter */}
              <div className="table-responsive">
                <Table columns={columns} dataSource={filteredData} />
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
      <FilterModal
        visible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        filtersConfig={filtersConfig}
        onApplyFilters={handleApplyFilters}
        initialValues={filters}
      />
      <AddSubcategory />
      <EditSubcategories setIsCategoryUpdated={setIsCategoryUpdated} />
    </div>
  );
};

export default SubCategories;
