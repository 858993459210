/* eslint-disable no-constant-condition */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import {
  ChevronUp,
  Edit,
  Eye,
  FileText,
  PlusCircle,
  RotateCcw,
  Sliders,
  StopCircle,
  User,
} from "feather-icons-react/build/IconComponents";
import {
  alertData,
  loaderData,
  setToogleHeader,
  stocksData,
} from "../../core/redux/action";
import { useDispatch, useSelector } from "react-redux";
import { Filter } from "react-feather";
import Select from "react-select";
import { DatePicker } from "antd";
import axios from "axios";
import { API_URL } from "../../constants/AppConstants";
import { salesData, updatePage } from "../../core/redux/action";
import { Modal } from "antd";
import PrintPreview from "../../common/PrintPreview";
import PrintLayout from "../../common/PrintLayout";
import CSS from "../../core/modals/PreviewModal.module.scss";
import CustomerModal from "../../core/modals/peoples/customerModal";
import SupplierModal from "../../core/modals/peoples/supplierModal";
import AddProductModal from "../../core/modals/peoples/AddProductModal";
import FilterModal from "../../core/modals/FilterModal";
import {
  fetchAccountsData,
  fetchCustomersData,
  fetchProductsData,
  fetchSalesData,
  fetchStocksData,
  fetchSuppliersData,
  fetchSaleQuotationData,
  fetchSaleBookingData,
} from "../../utils/utilities";
import EditSales from "./EditSales";
import ViewSales from "./ViewSales";
import TransportModal from "../stock/transportmodal";

const SalesList = () => {
  const dispatch = useDispatch();
  const login_data = useSelector((state) => state.login_data);
  const data = useSelector((state) => state.toggle_header);
  const selected_store = useSelector((state) => state.selected_store);
  const customer_data = useSelector((state) => state.customer_data);
  const supplier_data = useSelector((state) => state.supplier_data);
  const products_data = useSelector((state) => state.products_data);
  const sales_data = useSelector((state) => state.sales_data);
  const sales_data_length = useSelector((state) => state.sales_data_length);
  const accounts_data = useSelector((state) => state.accounts_data); //updated
  const stocks_data = useSelector((state) => state.stocks_data);
  const sale_booking_data = useSelector((state) => state.sale_booking_data);
  const sale_quotation_data = useSelector((state) => state.sale_quotation_data);

  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [stocksArray, setStocksArray] = useState([]);

  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [salesInvoiceNumber, setSalesInvoiceNumber] = useState("");
  const [selectedSupplier, setSelectedSupplier] = useState(null);

  const [productInput, setProductInput] = useState("");
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedUnits, setSelectedUnits] = useState([
    {
      label: "Choose",
      value: null,
    },
  ]);
  const [selectedQty, setSelectedQty] = useState([0]);
  const [totalCostAll, setTotalCostAll] = useState([0]);
  const [showProductList, setShowProductList] = useState(false);
  const [filteredList, setFilteredProductList] = useState(products_data);

  const [orderTaxValue, setOrderTaxValue] = useState(0);
  const [discountType, setDiscountType] = useState([
    {
      label: "Choose",
      value: null,
    },
  ]);
  const [discountValue, setDiscountValue] = useState(0);
  const [shippingValue, setShippingValue] = useState(0);
  const [salesStatus, setSalesStatus] = useState({
    label: "Completed",
    value: "Completed",
  });
  const [dueAmount, setDueAmount] = useState("");
  const [dueDate, setDueDate] = useState(new Date());
  const [receivedAmount, setReceivedAmount] = useState("");
  const [paymentStatus, setPaymentStatus] = useState({
    label: "Pending",
    value: "Pending",
  });
  const [paymentMethod, setPaymentMethod] = useState({
    label: "Choose",
    value: null,
  });

  const [grandTotal, setGrandTotal] = useState("");
  let totalTaxList = useRef([null]);
  let totalTax = useRef(0);

  const [showPdfModal, setShowPdfModal] = useState(false);
  const [pdfData, setPdfData] = useState({});

  const [productStocksList, setProductStocksList] = useState([]);
  const [purchasePriceList, setPurchasePriceList] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [filters, setFilters] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState(sales_data);
  const [filterOptions, setFilterOptions] = useState({ customerNames: [] });
  const [viewSalesDetailData, setViewSalesDetailData] = useState({});
  const [showTransportModal, setShowTransportModal] = useState(false);

  const [addSaleType, setAddSaleType] = useState(1);
  const [selectedSaleInvoice, setSelectedSaleInvoice] = useState(null);

  const [selectedDateFilterData, setSelectedDateFilterData] = useState("");
  const [selectedCustomerFilterData, setSelectedCustomerFilterData] =
    useState("");
  const [customerFilterArr, setCustomerFilterArr] = useState([]);

  const [selectedSaleStatusFilterData, setSelectedSaleStatusFilterData] =
    useState("");
  const [selectedPaymentStatusFilterData, setSelectedPaymentStatusFilterData] =
    useState("");
  const [selectedBookingStatusFilterData, setSelectedBookingStatusFilterData] =
    useState("");

  const [bookedCustomerArray, setBookedCustomerArray] = useState([]);
  const [selectedBookedCustomer, setSelectedBookedCustomer] = useState("");
  const [clubbedBookedCustomerProdArr, setClubbedBookedCustomerProdArr] =
    useState([]);

  let dropdownRef = useRef(null);

  let grandTotalValue = useRef(0);

  const oldandlatestvalue = [{ value: "Sort by Date", label: "Sort by Date" }];
  const customername = [{ value: null, label: "Choose" }];
  const status = [{ value: null, label: "Choose" }];
  const paymentstatus = [{ value: null, label: "Choose" }];
  const customer = [{ value: null, label: "Choose" }];
  const suppliername = [{ value: null, label: "Choose" }];
  const statusupdate = [
    { value: "Completed", label: "Completed" },
    { value: "InProgress", label: "InProgress" },
  ];
  const paymenttype = [
    { value: "Cash", label: "Cash" },
    { value: "Card", label: "Card" },
    { value: "Online", label: "Online" },
  ];

  const filtersConfig = [
    {
      key: "customer",
      label: "Customer Name",
      type: "select",
      options: filterOptions.customerNames,
      placeholder: "Select Customer",
    },
    {
      key: "saleStatus",
      label: "Sale Status",
      type: "select",
      options: [
        { value: "completed", label: "Completed" },
        { value: "pending", label: "Pending" },
        { value: "canceled", label: "Canceled" },
      ],
      placeholder: "Select Sale Status",
    },
    {
      key: "paymentStatus",
      label: "Payment Status",
      type: "select",
      options: [
        { value: "paid", label: "Paid" },
        { value: "unpaid", label: "Unpaid" },
        { value: "partial", label: "Partial" },
      ],
      placeholder: "Select Payment Status",
    },
  ];

  const handleApplyFilters = (newFilters) => {
    setFilters(newFilters);
  };

  const getFilterOptions = (salesData) => {
    const customerNamesOptions = [];
    salesData.forEach((sale) => {
      if (
        sale.customer &&
        !customerNamesOptions.find(
          (option) => option.value === sale.customer.label
        )
      ) {
        customerNamesOptions.push({
          label: sale.customer.label,
          value: sale.customer.label,
        });
      }
    });
    return { customerNamesOptions };
  };

  useEffect(() => {
    if (selectedCustomer) {
      console.log(accounts_data);
      console.log("working");
      fetchAccountsData(login_data, selected_store[0]);
    }
  }, [login_data]);

  useEffect(() => {
    clearData();
    setSelectedSaleInvoice(null);
  }, [addSaleType]);

  useEffect(() => {
    fetchCustomersData(login_data, selected_store[0]);
    fetchSuppliersData(login_data, selected_store[0]);
    fetchStocksData(login_data, selected_store[0]);
    fetchSalesData(login_data, selected_store[0]);
    fetchProductsData(login_data, selected_store[0]);
    fetchSaleBookingData(login_data, selected_store[0]);
    fetchSaleQuotationData(login_data, selected_store[0]);
  }, [selected_store]);

  useEffect(() => {
    if (sale_booking_data?.length) {
      let customerArray = [];
      sale_booking_data.forEach((sale) => {
        customerArray.push(sale.customer.label);
      });
      let uniqCustomerArray = [...new Set(customerArray)];
      setBookedCustomerArray(uniqCustomerArray);
    }
  }, [sale_booking_data]);

  useEffect(() => {
    if (addSaleType === 2) {
      sale_booking_data.forEach((item) => {
        if (item.invoiceNumber == selectedSaleInvoice?.label) {
          let productUnit = [];
          let productQty = [];
          let productPrice = [];
          setSelectedCustomer(item.customer);
          // setSelectedDate(new Date(item.date));
          setShippingValue(item.shipping);
          setPaymentStatus(item.paymentStatus);
          setPaymentMethod(item.paymentMethod);
          setDueAmount(item.dueAmount);
          setReceivedAmount(item.receivedAmount);
          setSalesStatus(item.bookingStatus);
          setSelectedProducts(item.productsList);
          item.productsList.forEach((product, index) => {
            productUnit[index] = product.selectedUnit;
            productQty[index] = product.selectedQty;
            productPrice[index] = product.purchasePrice;
          });
          setSelectedUnits(productUnit);
          setSelectedQty(productQty);
          setPurchasePriceList(productPrice);
        }
      });
    } else if (addSaleType === 3) {
      console.log(sale_quotation_data);
      sale_quotation_data.forEach((item) => {
        if (item.invoiceNumber == selectedSaleInvoice?.label) {
          let productUnit = [];
          let productQty = [];
          let productPrice = [];
          setSelectedCustomer(item.customer);
          // setSelectedDate(new Date(item.date));
          setShippingValue(item.shipping);
          // setPaymentStatus(item.paymentStatus);
          // setPaymentMethod(item.paymentMethod);
          // setDueAmount(item.dueAmount);
          // setReceivedAmount(item.receivedAmount);
          // setPurchaseStatus(item.bookingStatus);
          setSelectedProducts(item.productsList);
          item.productsList.forEach((product, index) => {
            productUnit[index] = product.selectedUnit;
            productQty[index] = product.selectedQty;
            productPrice[index] = product.purchasePrice;
          });
          setSelectedUnits(productUnit);
          setSelectedQty(productQty);
          setPurchasePriceList(productPrice);
        }
      });
    }
  }, [selectedSaleInvoice, selectedProducts]);

  useEffect(() => {
    setStocksArray(stocks_data);
  }, [stocks_data]);

  useEffect(() => {
    const { customerNamesOptions } = getFilterOptions(sales_data);
    console.log(sales_data, "1111111");
    setFilterOptions({
      customerNames: customerNamesOptions,
    });
    setFilteredData(sales_data);
  }, [sales_data]);

  useEffect(() => {
    if (searchTerm) {
      setSelectedCustomerFilterData("");
      setSelectedSaleStatusFilterData("");
      setSelectedPaymentStatusFilterData("");
      const filtered = sales_data.filter(
        (item) =>
          item?.invoiceNumber
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          item?.customer?.label.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(sales_data);
    }
  }, [searchTerm]);

  useEffect(() => {
    document.addEventListener("click", (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setShowProductList(false);
      }
    });
  }, []);

  useEffect(() => {
    fetchSalesData(login_data, selected_store[0]);
  }, [selected_store]);

  useEffect(() => {
    setSalesInvoiceNumber(
      `SL-${new Date().getFullYear()}${sales_data_length + 1}`
    );
  }, [selectedCustomer]);

  useEffect(() => {
    let filteredArr = [];

    let newFilteredList = [...products_data];
    if (productInput?.length) {
      setShowProductList(true);
      filteredArr = newFilteredList?.filter((product) => {
        return product?.productName
          .toLowerCase()
          .includes(productInput.toLowerCase());
      });
      setFilteredProductList(filteredArr);
    } else {
      setFilteredProductList(products_data);
    }
  }, [productInput]);

  useEffect(() => {
    let selectedUnitsArr = [...selectedUnits];
    let selectedQtyArr = [...selectedQty];
    let productStocksArr = [...productStocksList];

    if (selectedProducts) {
      for (let i = 0; i < selectedProducts.length; i++) {
        selectedUnitsArr.push({
          label: "Choose",
          value: null,
        });

        stocksArray?.forEach((item) => {
          if (item.productId == selectedProducts[i]._id) {
            productStocksArr[i] = {
              productCurrentQty: Number(item.productCurrentQty),
              productUnit: item.productUnit.label,
              productCurrentAlternateQty: Number(
                item.productCurrentAlternateQty
              ),
              productClubbedUnit: item.productClubbedUnit.label,
            };
          }
        });
      }
    }
    setSelectedUnits(selectedUnits);
    setProductStocksList(productStocksArr);
  }, [selectedProducts, stocksArray]);

  useEffect(() => {
    if (selectedProducts.length) {
      grandTotalValue.current = 0;
      for (let i = 0; i < selectedProducts.length; i++) {
        grandTotalValue.current =
          Number(grandTotalValue.current) +
          Number(
            selectedProducts[i].totalCost ? selectedProducts[i].totalCost : 0
          );
      }

      if (discountValue) {
        if (discountType?.label == "Percent") {
          grandTotalValue.current =
            Number(grandTotalValue.current) -
            (Number(grandTotalValue.current) * Number(discountValue)) / 100;
          grandTotalValue.current =
            Number(grandTotalValue.current) +
            (Number(grandTotalValue.current) * Number(orderTaxValue)) / 100;
          grandTotalValue.current =
            Number(grandTotalValue.current) + Number(shippingValue);
        } else if (discountType?.label == "Cash") {
          grandTotalValue.current =
            Number(grandTotalValue.current) - Number(discountValue);
          grandTotalValue.current =
            Number(grandTotalValue.current) +
            (Number(grandTotalValue.current) * Number(orderTaxValue)) / 100;
          grandTotalValue.current =
            Number(grandTotalValue.current) + Number(shippingValue);
        }
      } else {
        grandTotalValue.current =
          Number(grandTotalValue.current) +
          (Number(grandTotalValue.current) * Number(orderTaxValue)) / 100;
        grandTotalValue.current =
          Number(grandTotalValue.current) + Number(shippingValue);
      }

      setGrandTotal(
        Number(grandTotalValue.current) ? Number(grandTotalValue.current) : 0
      );
    }
    setSelectedUnits(selectedUnits);
  }, [selectedProducts, orderTaxValue, discountValue, shippingValue]);

  useEffect(() => {
    let filteredArr = [...selectedProducts];
    for (let i = 0; i < filteredArr.length; i++) {
      let unitCost =
        Number(purchasePriceList[i] ? purchasePriceList[i] : 0) -
        (filteredArr[i].productDiscountType.label == "Cash"
          ? Number(filteredArr[i].productDiscountValue)
          : (Number(purchasePriceList[i] ? purchasePriceList[i] : 0) *
              Number(filteredArr[i].productDiscountValue)) /
            100) +
        ((Number(purchasePriceList[i] ? purchasePriceList[i] : 0) -
          (filteredArr[i].productDiscountType.label == "Cash"
            ? Number(filteredArr[i].productDiscountValue)
            : (Number(purchasePriceList[i] ? purchasePriceList[i] : 0) *
                Number(filteredArr[i].productDiscountValue)) /
              100)) *
          Number(filteredArr[i].productTaxValue)) /
          100;

      filteredArr[i] = {
        ...filteredArr[i],
        selectedUnit: selectedUnits[i],
        selectedQty: selectedQty[i],
        purchasePrice: purchasePriceList[i],
        productUnitCost: unitCost,
        // totalCost: Number(selectedQty[i])
        //   ? selectedUnits[i]?.label != filteredArr[i]?.productUnit?.label
        //     ? Number(unitCost) *
        //       Number(
        //         selectedQty[i] * Number(filteredArr[i]?.productAlternateQty)
        //       )
        //     : Number(unitCost) * Number(selectedQty[i])
        //   : 0,
        totalCost: Number(selectedQty[i])
          ? Number(unitCost) * Number(selectedQty[i])
          : 0,
        productCurrentTax: totalTaxList.current[i],
      };
    }
    setSelectedProducts(filteredArr);
  }, [selectedUnits, selectedQty, purchasePriceList]);

  useEffect(() => {
    if (sales_data && sales_data?.length) {
      let customerFilterArrCopy = [];

      sales_data.forEach((item) => {
        if (item.customer.value) {
          customerFilterArrCopy.push(item.customer.label);
        }
      });
      let uniqCustomerFilterArrCopy = [...new Set(customerFilterArrCopy)];
      setCustomerFilterArr(uniqCustomerFilterArrCopy);
    }
  }, [sales_data]);

  useEffect(() => {
    if (selectedBookedCustomer.value) {
      let bookedProdArr = [];
      console.log(sale_booking_data, "11111111");
      sale_booking_data.forEach((sale) => {
        if (sale.customer.label == selectedBookedCustomer.label) {
          bookedProdArr.push(...sale.productsList);
        }
      });
      console.log(bookedProdArr, "11111111");
      setSelectedProducts(bookedProdArr);
    }
  }, [selectedBookedCustomer]);

  useEffect(() => {
    if (!searchTerm) {
      if (
        selectedCustomerFilterData?.value ||
        selectedSaleStatusFilterData?.value ||
        selectedPaymentStatusFilterData?.value
      ) {
        const filteredData = sales_data.filter((item) => {
          const matchesCustomer = selectedCustomerFilterData?.value
            ? item.customer.label === selectedCustomerFilterData.label
            : true;
          const matchesSaleStatus = selectedSaleStatusFilterData?.value
            ? item.salesStatus.label === selectedSaleStatusFilterData.label
            : true;
          const matchesPaymentStatus = selectedPaymentStatusFilterData?.value
            ? item.paymentStatus.label ===
              selectedPaymentStatusFilterData?.label
            : true;

          return matchesCustomer && matchesSaleStatus && matchesPaymentStatus;
        });

        setFilteredData(filteredData);
      } else {
        setFilteredData(sales_data);
      }
    }
  }, [
    selectedCustomerFilterData,
    selectedSaleStatusFilterData,
    selectedPaymentStatusFilterData,
    selectedDateFilterData,
  ]);

  useEffect(() => {
    setDueAmount(Number(grandTotal - receivedAmount).toFixed(2));
  }, [grandTotal, receivedAmount, selectedSaleInvoice]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  function changeUnit(dataValue, dataId) {
    let newArr = [...selectedUnits];
    newArr.splice(dataId, 1, dataValue);
    setSelectedUnits(newArr);
    changeQty(0, dataId);
  }

  function changeQty(dataValue, dataId) {
    let newArr = [...selectedQty];
    newArr.splice(dataId, 1, dataValue);
    setSelectedQty(newArr);
  }

  const renderTooltip = (props) => (
    <Tooltip id="pdf-tooltip" {...props}>
      Pdf
    </Tooltip>
  );
  const renderExcelTooltip = (props) => (
    <Tooltip id="excel-tooltip" {...props}>
      Excel
    </Tooltip>
  );
  const renderPrinterTooltip = (props) => (
    <Tooltip id="printer-tooltip" {...props}>
      Printer
    </Tooltip>
  );
  const renderRefreshTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Refresh
    </Tooltip>
  );
  const renderCollapseTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Collapse
    </Tooltip>
  );

  function fetchData() {
    fetchSalesData(login_data, selected_store[0]);
    fetchStocksData(login_data, selected_store[0]);
  }

  function showTransport() {
    setIsLoading(true);
    if (!selectedCustomer || !selectedProducts || !salesInvoiceNumber) {
      setIsLoading(false);
      dispatch(
        alertData({
          type: "error",
          msg: "Please fill the required fields",
        })
      );
      return;
    }

    const invalidProductsCount = selectedProducts.reduce(
      (count, product, index) => {
        if (product.selectedQty > productStocksList[index]?.productQty) {
          return count + 1;
        }
        return count;
      },
      0
    );

    if (invalidProductsCount > 0) {
      setIsLoading(false);
      dispatch(
        alertData({
          type: "error",
          msg: `${invalidProductsCount} product(s) have quantities exceeding the available quantity`,
        })
      );
      return;
    }

    // if (receivedAmount > grandTotal) {
    //   setIsLoading(false);
    //   dispatch(
    //     alertData({
    //       type: "error",
    //       msg: "Paid amount exceeds Grand Total",
    //     })
    //   );
    //   return;
    // }

    const dismissModal = document.querySelector("#dismiss-modal");
    if (dismissModal) {
      dismissModal.click();
    }

    setShowTransportModal(true);
    setIsLoading(false);
  }

  async function submitData(type) {
    console.log(paymentStatus);
    let dataBody = {
      storeId: selected_store[0]?._id,
      customer: selectedCustomer,
      date: new Date(selectedDate).toLocaleDateString().split(",")[0],
      invoiceNumber: salesInvoiceNumber,
      supplier: selectedSupplier,
      productsList: selectedProducts,
      orderTax: Number(orderTaxValue) + Number(totalTax.current),
      discount: discountValue,
      productDiscountType: discountType,
      discountWithUnit:
        discountValue +
        (discountType.label == "Percent"
          ? "%"
          : discountType.label == "Cash"
          ? "₹"
          : ""),
      shipping: shippingValue,
      grandTotal: grandTotal,
      salesStatus: salesStatus,
      dueAmount,
      dueDate: new Date(dueDate).toLocaleDateString().split(",")[0],
      receivedAmount,
      paymentStatus,
      paymentMethod,
      transportDetail: type === 1 ? false : true,
      referenceId: selectedSaleInvoice?.value ? selectedSaleInvoice?.label : "",
      createdOn: new Date().toLocaleDateString().split(",")[0],
      userCode: login_data?.empCode,
      itemActive: true,
    };

    let stocksDataArr = [];

    if (selectedProducts.length) {
      for (let i = 0; i < selectedProducts.length; i++) {
        if (
          selectedProducts[i].selectedUnit.label ==
          selectedProducts[i].productClubbedUnit.label
        ) {
          stocksDataArr.push({
            productCurrentAlternateQty:
              Number(productStocksList[i].productCurrentAlternateQty) -
              Number(selectedProducts[i].selectedQty),
            productCurrentQty:
              Number(selectedProducts[i].productAlternateQty) *
              (Number(productStocksList[i].productCurrentAlternateQty) -
                Number(selectedProducts[i].selectedQty)),
          });
        } else {
          stocksDataArr.push({
            productCurrentQty:
              Number(productStocksList[i].productCurrentQty) -
              Number(selectedProducts[i].selectedQty),
            productCurrentAlternateQty:
              (Number(productStocksList[i].productCurrentQty) -
                Number(selectedProducts[i].selectedQty)) /
              Number(selectedProducts[i].productAlternateQty),
          });
        }
      }
    }

    try {
      let response = await axios.post(API_URL + "sales", dataBody);
      let responseArr = [];
      if (selectedProducts.length) {
        for (let i = 0; i < selectedProducts.length; i++) {
          response[i] = await axios.put(
            API_URL + "stocks/" + selectedProducts[i]._id,
            stocksDataArr[i]
          );
        }
      }
      let failed = 0;
      if (response.status === 200) {
        responseArr?.forEach((response) => {
          if (!response.status == 200) failed = 1;
        });
        if (!failed) {
          dispatch(
            alertData({
              type: "success",
              msg: "Sale added successfully!",
            })
          );

          fetchSalesData();

          try {
            if (selectedCustomer.value) {
              const fetchAccounts = async () => {
                try {
                  let res = await axios.get(API_URL + "accounts");
                  if (res.status === 200) {
                    return `DB-${new Date().getFullYear()}${
                      res.data.length + 1
                    }`;
                  } else {
                    throw new Error("Failed to fetch accounts");
                  }
                } catch (err) {
                  console.error("Error fetching accounts:", err.message);
                  throw err;
                }
              };

              const id = await fetchAccounts();
              const transaction = {
                transactionId: id,
                customerName: selectedCustomer.label,
                customerId: selectedCustomer.value,
                transactionDate: new Date(),
                amount: grandTotal,
                type: "debit",
                description: `Invoice Added for #${salesInvoiceNumber}`,
                userCode: login_data.empCode,
                storeId: login_data.storeId,
                createdAt: new Date(),
                utr: "-",
              };
              let res = await axios.post(API_URL + "accounts", transaction);
              if (res.status === 200) {
                dispatch(
                  alertData({
                    type: "success",
                    msg: "Account Updated Successfully",
                  })
                );
              }
            }
          } catch (error) {
            console.log(error);
          }

          setTimeout(() => {
            setIsLoading(false);
            document?.querySelector("#add-sales-new .close")?.click();
          }, 1000);
          fetchData();
          clearData();
        }
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  }

  function selectProduct(product) {
    // Check if the product ID already exists in selectedProducts
    // if (
    //   selectedProducts.some(
    //     (selectedProduct) => selectedProduct.id === product.id
    //   )
    // ) {
    //   dispatch(
    //     alertData({
    //       type: "error",
    //       msg: "Product already selected!",
    //     })
    //   );
    //   return; // Exit the function if the product ID already exists
    // }

    let productArr = [...selectedProducts];
    productArr.push({
      ...product,
      // productDiscountType: {
      //   label: "Cash",
      //   value: "Cash",
      // },
      productCurrentTax: 40,
    });
    setSelectedProducts(productArr);

    let selectedUnitsArr = [...selectedUnits];
    let selectedQtyArr = [...selectedQty];
    let totalCostArr = [...totalCostAll];
    selectedUnitsArr.splice(productArr.length - 1, 0, {
      label: "Choose",
      value: null,
    });
    totalCostArr.splice(productArr.length - 1, 0, 0);
    selectedQtyArr.splice(productArr.length - 1, 0, "");
  }
  function deleteProduct(productIndex) {
    let productArr = [...selectedProducts];
    let newArr1 = productArr.filter((item, index) => index != productIndex);
    setSelectedProducts(newArr1);

    let selectedUnitArr = [...selectedUnits];
    let newArr2 = selectedUnitArr.filter(
      (item, index) => index != productIndex
    );
    setSelectedUnits(newArr2);

    let selectedQtyArr = [...selectedQty];
    let newArr3 = selectedQtyArr.filter((item, index) => index != productIndex);
    setSelectedQty(newArr3);

    let selectedPriceArr = [...purchasePriceList];
    let newArr4 = selectedPriceArr.filter(
      (item, index) => index != productIndex
    );
    setPurchasePriceList(newArr4);
  }

  function clearData() {
    setSelectedSaleInvoice(null);
    setProductInput("");
    setSalesInvoiceNumber("");
    setOrderTaxValue("");
    setDiscountValue("");
    setShippingValue("");
    setSelectedSupplier("");
    setSelectedCustomer("");
    setSelectedProducts([]);
    setDueAmount("");
    setDueDate(new Date());
    setSelectedDate(new Date());
    setReceivedAmount("");
    setPaymentStatus({
      label: "Pending",
      value: "Pending",
    });
    setPaymentMethod({
      label: "Choose",
      value: null,
    });
    totalTax.current = 0;
    setGrandTotal(0);
    setShippingValue(0);
    setSelectedUnits([
      {
        label: "Choose",
        value: "Choose",
      },
    ]);
    setSelectedQty([0]);
    setPurchasePriceList([]);
  }

  async function deleteData(dataId) {
    dispatch(
      loaderData({
        type: "Loader_Data",
        payload: true,
      })
    );
    if (dataId) {
      try {
        let response = await axios.put(API_URL + "sales/delete/" + dataId);
        if (response.status === 200) {
          fetchData();
          dispatch(
            loaderData({
              type: "Loader_Data",
              payload: false,
            })
          );
        }
      } catch (err) {
        console.log(err);
      }
    }
  }

  function changePurchasePrice(value, index) {
    let priceArr = [...purchasePriceList];
    priceArr[index] = Number(value);
    setPurchasePriceList(priceArr);
  }

  const getProductQty = (id) => {
    const stockQty = stocks_data.filter((item) => item.productId === id);
    return `${
      stockQty[0]?.productCurrentQty ? stockQty[0]?.productCurrentQty : 0
    } ${stockQty[0]?.productUnit?.label} / ${
      Number(stockQty[0]?.productCurrentAlternateQty)
        ? Number(stockQty[0]?.productCurrentAlternateQty).toFixed(2)
        : 0
    } ${stockQty[0]?.productClubbedUnit?.label}`;
  };

  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Sales List</h4>
                <h6>Manage Your Sales</h6>
              </div>
            </div>
            <ul className="table-top-head">
              {/* <li>
                <OverlayTrigger placement="top" overlay={renderTooltip}>
                  <Link>
                    <ImageWithBasePath
                      src="assets/img/icons/pdf.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderExcelTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top">
                    <ImageWithBasePath
                      src="assets/img/icons/excel.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderPrinterTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top">
                    <i data-feather="printer" className="feather-printer" />
                  </Link>
                </OverlayTrigger>
              </li> */}
              <li>
                <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top">
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderCollapseTooltip}>
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    id="collapse-header"
                    className={data ? "active" : ""}
                    onClick={() => {
                      dispatch(setToogleHeader(!data));
                    }}
                  >
                    <ChevronUp />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
            <div className="page-btn">
              <Link
                to="#"
                className="btn btn-added"
                data-bs-toggle="modal"
                data-bs-target="#add-sales-new"
                onClick={clearData}
              >
                <PlusCircle className="me-2" />
                Add New Sales
              </Link>
            </div>
          </div>
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <input
                      type="text"
                      placeholder="Search by invoice or customer"
                      className="form-control form-control-sm formsearch"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <Link to className="btn btn-searchset">
                      <i data-feather="search" className="feather-search" />
                    </Link>
                  </div>
                </div>
                <Select
                  value={
                    selectedCustomerFilterData.value
                      ? selectedCustomerFilterData
                      : [
                          {
                            label: "Customer",
                            value: null,
                          },
                        ]
                  }
                  className="select mr-10"
                  options={customerFilterArr?.map((item, index) => {
                    return { label: item, value: index + 1 };
                  })}
                  placeholder="Customer"
                  onChange={(value) => setSelectedCustomerFilterData(value)}
                />
                <Select
                  value={
                    selectedSaleStatusFilterData.value
                      ? selectedSaleStatusFilterData
                      : [
                          {
                            label: "Sale",
                            value: null,
                          },
                        ]
                  }
                  className="select mr-10"
                  options={[
                    {
                      label: "Completed",
                      value: "Completed",
                    },
                    {
                      label: "Cancelled",
                      value: "Cancelled",
                    },
                  ]}
                  placeholder="Sale"
                  onChange={(value) => setSelectedSaleStatusFilterData(value)}
                />
                <Select
                  value={
                    selectedPaymentStatusFilterData.value
                      ? selectedPaymentStatusFilterData
                      : [
                          {
                            label: "Payment",
                            value: null,
                          },
                        ]
                  }
                  className="select mr-10"
                  options={[
                    {
                      label: "Completed",
                      value: "Completed",
                    },
                    {
                      label: "Pending",
                      value: "Pending",
                    },
                  ]}
                  placeholder="Payment"
                  onChange={(value) =>
                    setSelectedPaymentStatusFilterData(value)
                  }
                />
                {/* <Select
                value={
                  selectedDateFilterData.value
                    ? selectedDateFilterData
                    : [
                        {
                          label: "Date",
                          value: null,
                        },
                      ]
                }
                className="select mr-10"
                options={[
                  {
                    label: "Latest",
                    value: "Latest",
                  },
                  {
                    label: "Oldest",
                    value: "Oldest",
                  },
                ]}
                placeholder="Date"
                onChange={(value) => setSelectedDateFilterData(value)}
              /> */}
                <button
                  className="btn btn-danger"
                  onClick={() => {
                    setSelectedCustomerFilterData("");
                    setSelectedSaleStatusFilterData("");
                    setSelectedPaymentStatusFilterData("");
                    setSelectedDateFilterData("");
                  }}
                >
                  Clear
                </button>
              </div>
              <div
                className={`card${isFilterVisible ? " visible" : ""}`}
                id="filter_inputs"
                style={{ display: isFilterVisible ? "block" : "none" }}
              >
                <div className="card-body pb-0">
                  <div className="row">
                    <div className="col-lg-3 col-sm-6 col-12">
                      <div className="input-blocks">
                        <i data-feather="user" className="info-img" />
                        <User className="info-img" />

                        <Select
                          className="select"
                          options={customername}
                          placeholder="Newest"
                        />
                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-6 col-12">
                      <div className="input-blocks">
                        <StopCircle className="info-img" />

                        <Select
                          className="select"
                          options={status}
                          placeholder="Newest"
                        />
                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-6 col-12">
                      <div className="input-blocks">
                        <FileText className="info-img" />
                        <input
                          type="text"
                          placeholder="Enter Invoice"
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-12">
                      <div className="input-blocks">
                        <StopCircle className="info-img" />

                        <Select
                          className="select"
                          options={paymentstatus}
                          placeholder="Choose Payment Status"
                        />
                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-6 col-12">
                      <div className="input-blocks">
                        <Link className="btn btn-filters ms-auto">
                          {" "}
                          <i
                            data-feather="search"
                            className="feather-search"
                          />{" "}
                          Search{" "}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /Filter */}
              <div className="table-responsive">
                <table className="table  datanew">
                  <thead>
                    <tr>
                      {/* <th className="no-sort">
                        <label className="checkboxs">
                          <input type="checkbox" id="select-all" />
                          <span className="checkmarks" />
                        </label>
                      </th> */}
                      <th>Customer Name</th>
                      <th>Invoice</th>
                      <th>Reference</th>
                      <th>Date</th>
                      <th>Sale Status</th>
                      <th>Grand Total</th>
                      <th>Received</th>
                      <th>Due</th>
                      <th>Payment Status</th>
                      {/* <th>Biller</th> */}
                      <th className="text-right">Action</th>
                    </tr>
                  </thead>
                  <tbody className="sales-list">
                    {filteredData?.map((data, index) => {
                      return (
                        <tr key={index}>
                          {/* <td>
                            <label className="checkboxs">
                              <input type="checkbox" />
                              <span className="checkmarks" />
                            </label>
                          </td> */}
                          <td>{data?.customer?.label}</td>
                          <td>{data?.invoiceNumber}</td>
                          <td>{data?.referenceId}</td>
                          <td>{data.date.split("T")[0]}</td>
                          <td>
                            <span
                              className={`badge ${
                                data?.salesStatus?.label == "Completed"
                                  ? "badge-linesuccess"
                                  : "badge-linedanger"
                              }`}
                            >
                              {data?.salesStatus?.label}
                            </span>
                          </td>
                          <td>₹ {Number(data?.grandTotal).toFixed(2)}</td>
                          <td>
                            ₹{" "}
                            {data?.receivedAmount === ""
                              ? 0
                              : Number(data?.receivedAmount).toFixed(2)}
                          </td>
                          <td>
                            ₹{" "}
                            {!isNaN(data?.dueAmount)
                              ? Number(data?.dueAmount).toFixed(2)
                              : "-"}
                          </td>
                          <td>
                            <span
                              className={`badge ${
                                data?.paymentStatus?.value
                                  ? data?.paymentStatus?.label == "Completed"
                                    ? "badge-linesuccess"
                                    : "badge-linedanger"
                                  : ""
                              }`}
                            >
                              {data?.paymentStatus?.value
                                ? data?.paymentStatus?.label
                                : ""}
                            </span>
                          </td>
                          {/* <td>Admin</td> */}
                          <td>
                            <div className="d-flex justify-content-end">
                              <Link
                                className="action-set"
                                to="#"
                                data-bs-toggle="modal"
                                data-bs-target="#sales-details-new"
                                onClick={() => {
                                  setViewSalesDetailData(data);
                                }}
                              >
                                <Eye className="feather-view" />
                              </Link>
                              <Link
                                className="action-set"
                                to="#"
                                data-bs-toggle="modal"
                                data-bs-target="#edit-sales-new"
                                onClick={() => {
                                  setViewSalesDetailData(data);
                                }}
                              >
                                <Edit className="feather-edit" />
                              </Link>
                              <Link
                                className="action-set"
                                to="#"
                                data-bs-toggle="dropdown"
                                aria-expanded="true"
                              >
                                <i
                                  className="fa fa-ellipsis-v"
                                  aria-hidden="true"
                                />
                              </Link>
                              <ul className="dropdown-menu">
                                {/* <li>
                                <Link
                                  to="#"
                                  className="dropdown-item"
                                  data-bs-toggle="modal"
                                  data-bs-target="#sales-details-new"
                                >
                                  <i data-feather="eye" className="info-img" />
                                  Sale Detail
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="#"
                                  className="dropdown-item"
                                  data-bs-toggle="modal"
                                  data-bs-target="#edit-sales-new"
                                >
                                  <i data-feather="edit" className="info-img" />
                                  Edit Sale
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="#"
                                  className="dropdown-item"
                                  data-bs-toggle="modal"
                                  data-bs-target="#showpayment"
                                >
                                  <i
                                    data-feather="dollar-sign"
                                    className="info-img"
                                  />
                                  Show Payments
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="#"
                                  className="dropdown-item"
                                  data-bs-toggle="modal"
                                  data-bs-target="#createpayment"
                                >
                                  <i
                                    data-feather="plus-circle"
                                    className="info-img"
                                  />
                                  Create Payment
                                </Link>
                              </li> */}
                                <li>
                                  <Link
                                    className="dropdown-item"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setShowPdfModal(true);
                                      setPdfData({
                                        heading: "Sales List",
                                        array: {
                                          from: {
                                            name: "FM Marketing",
                                            email: "fmmarketing@example.com",
                                            phone: "9876543210",
                                            address: "Raipur, Chhattisgarh",
                                            country: {
                                              label: "India",
                                              value: "India",
                                            },
                                            state: {
                                              label: "Chhattisgarh",
                                              value: "Chhattisgarh",
                                            },
                                            city: {
                                              label: "Raipur",
                                              value: "Raipur",
                                            },
                                            status: "Active",
                                            itemActive: true,
                                          },
                                          to: {
                                            name: "",
                                            email: "",
                                            phone: "",
                                            address: "",
                                            country: {
                                              label: "India",
                                              value: "India",
                                            },
                                            state: {
                                              label: "Chhattisgarh",
                                              value: "Chhattisgarh",
                                            },
                                            city: {
                                              label: "Raipur",
                                              value: "Raipur",
                                            },
                                            status: "Active",
                                            itemActive: true,
                                          },
                                          Invoice_Number: data?.invoiceNumber,
                                          Date: data?.date,
                                          Product_List: data?.productsList,
                                          Order_Tax: data?.orderTax,
                                          Discount: data?.discountWithUnit,
                                          Shipping: data?.shipping,
                                          Grand_Total: data?.grandTotal,
                                          Sales_Status:
                                            data?.salesStatus?.label,
                                          Due_Amount: data?.dueAmount,
                                          Due_Date: data?.dueDate,
                                          Received_Amount: data?.receivedAmount,
                                          Payment_Status: data?.paymentStatus,
                                          Payment_Method: data?.paymentMethod,
                                        },
                                      });
                                    }}
                                  >
                                    <i
                                      data-feather="download"
                                      className="info-img"
                                    />
                                    Generate Regular Pdf
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    className="dropdown-item"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setShowPdfModal(true);
                                      setPdfData({
                                        pdfType: "Transport",
                                        heading: "Sales List",
                                        array: {
                                          from: {
                                            name: "FM Marketing",
                                            email: "fmmarketing@example.com",
                                            phone: "9876543210",
                                            address: "Raipur, Chhattisgarh",
                                            country: {
                                              label: "India",
                                              value: "India",
                                            },
                                            state: {
                                              label: "Chhattisgarh",
                                              value: "Chhattisgarh",
                                            },
                                            city: {
                                              label: "Raipur",
                                              value: "Raipur",
                                            },
                                            status: "Active",
                                            itemActive: true,
                                          },
                                          to: {
                                            name: "",
                                            email: "",
                                            phone: "",
                                            address: "",
                                            country: {
                                              label: "India",
                                              value: "India",
                                            },
                                            state: {
                                              label: "Chhattisgarh",
                                              value: "Chhattisgarh",
                                            },
                                            city: {
                                              label: "Raipur",
                                              value: "Raipur",
                                            },
                                            status: "Active",
                                            itemActive: true,
                                          },
                                          Invoice_Number: data?.invoiceNumber,
                                          Date: data?.date,
                                          Product_List: data?.productsList,
                                          Order_Tax: data?.orderTax,
                                          Discount: data?.discountWithUnit,
                                          Shipping: data?.shipping,
                                          Grand_Total: data?.grandTotal,
                                          Sales_Status:
                                            data?.salesStatus?.label,
                                          Due_Amount: data?.dueAmount,
                                          Due_Date: data?.dueDate,
                                          Received_Amount: data?.receivedAmount,
                                          Payment_Status: data?.paymentStatus,
                                          Payment_Method: data?.paymentMethod,
                                        },
                                      });
                                    }}
                                  >
                                    <i
                                      data-feather="download"
                                      className="info-img"
                                    />
                                    Generate Transport Pdf
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    className="dropdown-item confirm-text mb-0"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      deleteData(data?._id);
                                    }}
                                  >
                                    <i
                                      data-feather="trash-2"
                                      className="info-img"
                                    />
                                    Delete Sale
                                  </Link>
                                </li>
                                {/* <li>
                                <Link className="dropdown-item">
                                  <i
                                    data-feather="trash-2"
                                    className="info-img"
                                  />
                                  Edit Sale
                                </Link>
                              </li> */}
                              </ul>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                    {/* <tr>
                                            <td>
                                                <label className="checkboxs">
                                                    <input type="checkbox" />
                                                    <span className="checkmarks" />
                                                </label>
                                            </td>
                                            <td>Thomas</td>
                                            <td>SL0101</td>
                                            <td>19 Jan 2023</td>
                                            <td>
                                                <span className="badge badge-bgsuccess">Completed</span>
                                            </td>
                                            <td>$550</td>
                                            <td>$550</td>
                                            <td>$0.00</td>
                                            <td>
                                                <span className="badge badge-linesuccess">Paid</span>
                                            </td>
                                            <td>Admin</td>
                                            <td className="text-center">
                                                <Link
                                                    className="action-set"
                                                    to="#"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="true"
                                                >
                                                    <i className="fa fa-ellipsis-v" aria-hidden="true" />
                                                </Link>
                                                <ul className="dropdown-menu">
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#sales-details-new"
                                                        >
                                                            <i data-feather="eye" className="info-img" />
                                                            Sale Detail
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#edit-sales-new"
                                                        >
                                                            <i data-feather="edit" className="info-img" />
                                                            Edit Sale
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#showpayment"
                                                        >
                                                            <i data-feather="dollar-sign" className="info-img" />
                                                            Show Payments
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#createpayment"
                                                        >
                                                            <i data-feather="plus-circle" className="info-img" />
                                                            Create Payment
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="#" className="dropdown-item">
                                                            <i data-feather="download" className="info-img" />
                                                            Download pdf
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item confirm-text mb-0"
                                                        >
                                                            <i data-feather="trash-2" className="info-img" />
                                                            Delete Sale
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <label className="checkboxs">
                                                    <input type="checkbox" />
                                                    <span className="checkmarks" />
                                                </label>
                                            </td>
                                            <td>Rose</td>
                                            <td>SL0102</td>
                                            <td>26 Jan 2023</td>
                                            <td>
                                                <span className="badge badge-bgsuccess">Completed</span>
                                            </td>
                                            <td>$250</td>
                                            <td>$250</td>
                                            <td>$0.00</td>
                                            <td>
                                                <span className="badge badge-linesuccess">Paid</span>
                                            </td>
                                            <td>Admin</td>
                                            <td className="text-center">
                                                <Link
                                                    className="action-set"
                                                    to="#"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="true"
                                                >
                                                    <i className="fa fa-ellipsis-v" aria-hidden="true" />
                                                </Link>
                                                <ul className="dropdown-menu">
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#sales-details-new"
                                                        >
                                                            <i data-feather="eye" className="info-img" />
                                                            Sale Detail
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#edit-sales-new"
                                                        >
                                                            <i data-feather="edit" className="info-img" />
                                                            Edit Sale
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#showpayment"
                                                        >
                                                            <i data-feather="dollar-sign" className="info-img" />
                                                            Show Payments
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#createpayment"
                                                        >
                                                            <i data-feather="plus-circle" className="info-img" />
                                                            Create Payment
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="#" className="dropdown-item">
                                                            <i data-feather="download" className="info-img" />
                                                            Download pdf
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item confirm-text mb-0"
                                                        >
                                                            <i data-feather="trash-2" className="info-img" />
                                                            Delete Sale
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <label className="checkboxs">
                                                    <input type="checkbox" />
                                                    <span className="checkmarks" />
                                                </label>
                                            </td>
                                            <td>Benjamin</td>
                                            <td>SL0103</td>
                                            <td>08 Feb 2023</td>
                                            <td>
                                                <span className="badge badge-bgsuccess">Completed</span>
                                            </td>
                                            <td>$570</td>
                                            <td>$570</td>
                                            <td>$0.00</td>
                                            <td>
                                                <span className="badge badge-linesuccess">Paid</span>
                                            </td>
                                            <td>Admin</td>
                                            <td className="text-center">
                                                <Link
                                                    className="action-set"
                                                    to="#"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="true"
                                                >
                                                    <i className="fa fa-ellipsis-v" aria-hidden="true" />
                                                </Link>
                                                <ul className="dropdown-menu">
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#sales-details-new"
                                                        >
                                                            <i data-feather="eye" className="info-img" />
                                                            Sale Detail
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#edit-sales-new"
                                                        >
                                                            <i data-feather="edit" className="info-img" />
                                                            Edit Sale
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#showpayment"
                                                        >
                                                            <i data-feather="dollar-sign" className="info-img" />
                                                            Show Payments
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#createpayment"
                                                        >
                                                            <i data-feather="plus-circle" className="info-img" />
                                                            Create Payment
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="#" className="dropdown-item">
                                                            <i data-feather="download" className="info-img" />
                                                            Download pdf
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item confirm-text mb-0"
                                                        >
                                                            <i data-feather="trash-2" className="info-img" />
                                                            Delete Sale
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <label className="checkboxs">
                                                    <input type="checkbox" />
                                                    <span className="checkmarks" />
                                                </label>
                                            </td>
                                            <td>Lilly</td>
                                            <td>SL0104</td>
                                            <td>12 Feb 2023</td>
                                            <td>
                                                <span className="badge badge-bgdanger">Pending</span>
                                            </td>
                                            <td>$300</td>
                                            <td>$0.00</td>
                                            <td>$300</td>
                                            <td>
                                                <span className="badge badge-linedanger">Due</span>
                                            </td>
                                            <td>Admin</td>
                                            <td className="text-center">
                                                <Link
                                                    className="action-set"
                                                    to="#"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="true"
                                                >
                                                    <i className="fa fa-ellipsis-v" aria-hidden="true" />
                                                </Link>
                                                <ul className="dropdown-menu">
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#sales-details-new"
                                                        >
                                                            <i data-feather="eye" className="info-img" />
                                                            Sale Detail
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#edit-sales-new"
                                                        >
                                                            <i data-feather="edit" className="info-img" />
                                                            Edit Sale
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#showpayment"
                                                        >
                                                            <i data-feather="dollar-sign" className="info-img" />
                                                            Show Payments
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#createpayment"
                                                        >
                                                            <i data-feather="plus-circle" className="info-img" />
                                                            Create Payment
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="#" className="dropdown-item">
                                                            <i data-feather="download" className="info-img" />
                                                            Download pdf
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item confirm-text mb-0"
                                                        >
                                                            <i data-feather="trash-2" className="info-img" />
                                                            Delete Sale
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <label className="checkboxs">
                                                    <input type="checkbox" />
                                                    <span className="checkmarks" />
                                                </label>
                                            </td>
                                            <td>Freda</td>
                                            <td>SL0105</td>
                                            <td>17 Mar 2023</td>
                                            <td>
                                                <span className="badge badge-bgdanger">Pending</span>
                                            </td>
                                            <td>$700</td>
                                            <td>$0.00</td>
                                            <td>$700</td>
                                            <td>
                                                <span className="badge badge-linedanger">Due</span>
                                            </td>
                                            <td>Admin</td>
                                            <td className="text-center">
                                                <Link
                                                    className="action-set"
                                                    to="#"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="true"
                                                >
                                                    <i className="fa fa-ellipsis-v" aria-hidden="true" />
                                                </Link>
                                                <ul className="dropdown-menu">
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#sales-details-new"
                                                        >
                                                            <i data-feather="eye" className="info-img" />
                                                            Sale Detail
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#edit-sales-new"
                                                        >
                                                            <i data-feather="edit" className="info-img" />
                                                            Edit Sale
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#showpayment"
                                                        >
                                                            <i data-feather="dollar-sign" className="info-img" />
                                                            Show Payments
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#createpayment"
                                                        >
                                                            <i data-feather="plus-circle" className="info-img" />
                                                            Create Payment
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="#" className="dropdown-item">
                                                            <i data-feather="download" className="info-img" />
                                                            Download pdf
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item confirm-text mb-0"
                                                        >
                                                            <i data-feather="trash-2" className="info-img" />
                                                            Delete Sale
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <label className="checkboxs">
                                                    <input type="checkbox" />
                                                    <span className="checkmarks" />
                                                </label>
                                            </td>
                                            <td>Alwin</td>
                                            <td>SL0106</td>
                                            <td>24 Mar 2023</td>
                                            <td>
                                                <span className="badge badge-bgsuccess">Completed</span>
                                            </td>
                                            <td>$400</td>
                                            <td>$400</td>
                                            <td>$0.00</td>
                                            <td>
                                                <span className="badge badge-linesuccess">Paid</span>
                                            </td>
                                            <td>Admin</td>
                                            <td className="text-center">
                                                <Link
                                                    className="action-set"
                                                    to="#"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="true"
                                                >
                                                    <i className="fa fa-ellipsis-v" aria-hidden="true" />
                                                </Link>
                                                <ul className="dropdown-menu">
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#sales-details-new"
                                                        >
                                                            <i data-feather="eye" className="info-img" />
                                                            Sale Detail
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#edit-sales-new"
                                                        >
                                                            <i data-feather="edit" className="info-img" />
                                                            Edit Sale
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#showpayment"
                                                        >
                                                            <i data-feather="dollar-sign" className="info-img" />
                                                            Show Payments
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#createpayment"
                                                        >
                                                            <i data-feather="plus-circle" className="info-img" />
                                                            Create Payment
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="#" className="dropdown-item">
                                                            <i data-feather="download" className="info-img" />
                                                            Download pdf
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item confirm-text mb-0"
                                                        >
                                                            <i data-feather="trash-2" className="info-img" />
                                                            Delete Sale
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <label className="checkboxs">
                                                    <input type="checkbox" />
                                                    <span className="checkmarks" />
                                                </label>
                                            </td>
                                            <td>Maybelle</td>
                                            <td>SL0107</td>
                                            <td>06 Apr 2023</td>
                                            <td>
                                                <span className="badge badge-bgdanger">Pending</span>
                                            </td>
                                            <td>$120</td>
                                            <td>$0.00</td>
                                            <td>$120</td>
                                            <td>
                                                <span className="badge badge-linedanger">Due</span>
                                            </td>
                                            <td>Admin</td>
                                            <td className="text-center">
                                                <Link
                                                    className="action-set"
                                                    to="#"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="true"
                                                >
                                                    <i className="fa fa-ellipsis-v" aria-hidden="true" />
                                                </Link>
                                                <ul className="dropdown-menu">
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#sales-details-new"
                                                        >
                                                            <i data-feather="eye" className="info-img" />
                                                            Sale Detail
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#edit-sales-new"
                                                        >
                                                            <i data-feather="edit" className="info-img" />
                                                            Edit Sale
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#showpayment"
                                                        >
                                                            <i data-feather="dollar-sign" className="info-img" />
                                                            Show Payments
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#createpayment"
                                                        >
                                                            <i data-feather="plus-circle" className="info-img" />
                                                            Create Payment
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="#" className="dropdown-item">
                                                            <i data-feather="download" className="info-img" />
                                                            Download pdf
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item confirm-text mb-0"
                                                        >
                                                            <i data-feather="trash-2" className="info-img" />
                                                            Delete Sale
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <label className="checkboxs">
                                                    <input type="checkbox" />
                                                    <span className="checkmarks" />
                                                </label>
                                            </td>
                                            <td>Ellen</td>
                                            <td>SL0108</td>
                                            <td>16 Apr 2023</td>
                                            <td>
                                                <span className="badge badge-bgsuccess">Completed</span>
                                            </td>
                                            <td>$830</td>
                                            <td>$830</td>
                                            <td>$0.00</td>
                                            <td>
                                                <span className="badge badge-linesuccess">Paid</span>
                                            </td>
                                            <td>Admin</td>
                                            <td className="text-center">
                                                <Link
                                                    className="action-set"
                                                    to="#"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="true"
                                                >
                                                    <i className="fa fa-ellipsis-v" aria-hidden="true" />
                                                </Link>
                                                <ul className="dropdown-menu">
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#sales-details-new"
                                                        >
                                                            <i data-feather="eye" className="info-img" />
                                                            Sale Detail
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#edit-sales-new"
                                                        >
                                                            <i data-feather="edit" className="info-img" />
                                                            Edit Sale
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#showpayment"
                                                        >
                                                            <i data-feather="dollar-sign" className="info-img" />
                                                            Show Payments
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#createpayment"
                                                        >
                                                            <i data-feather="plus-circle" className="info-img" />
                                                            Create Payment
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="#" className="dropdown-item">
                                                            <i data-feather="download" className="info-img" />
                                                            Download pdf
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item confirm-text mb-0"
                                                        >
                                                            <i data-feather="trash-2" className="info-img" />
                                                            Delete Sale
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <label className="checkboxs">
                                                    <input type="checkbox" />
                                                    <span className="checkmarks" />
                                                </label>
                                            </td>
                                            <td>Kaitlin</td>
                                            <td>SL0109</td>
                                            <td>04 May 2023</td>
                                            <td>
                                                <span className="badge badge-bgdanger">Pending</span>
                                            </td>
                                            <td>$800</td>
                                            <td>$0.00</td>
                                            <td>$800</td>
                                            <td>
                                                <span className="badge badge-linedanger">Due</span>
                                            </td>
                                            <td>Admin</td>
                                            <td className="text-center">
                                                <Link
                                                    className="action-set"
                                                    to="#"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="true"
                                                >
                                                    <i className="fa fa-ellipsis-v" aria-hidden="true" />
                                                </Link>
                                                <ul className="dropdown-menu">
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#sales-details-new"
                                                        >
                                                            <i data-feather="eye" className="info-img" />
                                                            Sale Detail
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#edit-sales-new"
                                                        >
                                                            <i data-feather="edit" className="info-img" />
                                                            Edit Sale
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#showpayment"
                                                        >
                                                            <i data-feather="dollar-sign" className="info-img" />
                                                            Show Payments
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#createpayment"
                                                        >
                                                            <i data-feather="plus-circle" className="info-img" />
                                                            Create Payment
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="#" className="dropdown-item">
                                                            <i data-feather="download" className="info-img" />
                                                            Download pdf
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item confirm-text mb-0"
                                                        >
                                                            <i data-feather="trash-2" className="info-img" />
                                                            Delete Sale
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <label className="checkboxs">
                                                    <input type="checkbox" />
                                                    <span className="checkmarks" />
                                                </label>
                                            </td>
                                            <td>Grace</td>
                                            <td>SL0110</td>
                                            <td>29 May 2023</td>
                                            <td>
                                                <span className="badge badge-bgsuccess">Completed</span>
                                            </td>
                                            <td>$460</td>
                                            <td>$460</td>
                                            <td>$0.00</td>
                                            <td>
                                                <span className="badge badge-linesuccess">Paid</span>
                                            </td>
                                            <td>Admin</td>
                                            <td className="text-center">
                                                <Link
                                                    className="action-set"
                                                    to="#"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="true"
                                                >
                                                    <i className="fa fa-ellipsis-v" aria-hidden="true" />
                                                </Link>
                                                <ul className="dropdown-menu">
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#sales-details-new"
                                                        >
                                                            <i data-feather="eye" className="info-img" />
                                                            Sale Detail
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#edit-sales-new"
                                                        >
                                                            <i data-feather="edit" className="info-img" />
                                                            Edit Sale
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#showpayment"
                                                        >
                                                            <i data-feather="dollar-sign" className="info-img" />
                                                            Show Payments
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#createpayment"
                                                        >
                                                            <i data-feather="plus-circle" className="info-img" />
                                                            Create Payment
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="#" className="dropdown-item">
                                                            <i data-feather="download" className="info-img" />
                                                            Download pdf
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item confirm-text mb-0"
                                                        >
                                                            <i data-feather="trash-2" className="info-img" />
                                                            Delete Sale
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr> */}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
      <>
        {/*add popup */}
        <div className="modal fade" id="add-sales-new">
          <div className="modal-dialog add-centered">
            <div className="modal-content">
              <div className="page-wrapper p-0 m-0">
                <div className="content p-0">
                  <div className="modal-header border-0 custom-modal-header">
                    <div className="page-title">
                      <h4>Add Sales</h4>
                    </div>
                    <button
                      type="button"
                      className="close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="card">
                    <div className="card-body">
                      <label className="d-inline-flex fs-16 fw-bold align-items-center mb-10">
                        For:{" "}
                      </label>
                      <div className="d-flex mb-20 input-blocks">
                        <input
                          checked={addSaleType === 1 ? true : false}
                          onClick={() => setAddSaleType(1)}
                          type="radio"
                          name="for"
                        />
                        <span
                          className="d-flex align-items-center"
                          style={{
                            position: "relative",
                            marginLeft: 10,
                            marginRight: 50,
                          }}
                        >
                          New
                        </span>
                        <input
                          checked={addSaleType === 2 ? true : false}
                          onClick={() => setAddSaleType(2)}
                          type="radio"
                          name="for"
                        />
                        <span
                          className="d-flex align-items-center"
                          style={{
                            position: "relative",
                            marginLeft: 10,
                            marginRight: 50,
                          }}
                        >
                          Booking
                        </span>
                        <input
                          checked={addSaleType === 3 ? true : false}
                          onClick={() => setAddSaleType(3)}
                          type="radio"
                          name="for"
                        />
                        <span
                          className="d-flex align-items-center"
                          style={{
                            position: "relative",
                            marginLeft: 10,
                            marginRight: 50,
                          }}
                        >
                          Quotation
                        </span>
                      </div>
                      {addSaleType === 2 || addSaleType === 3 ? (
                        <div className="row">
                          <div className="col-lg-3 col-sm-6 col-12 mb-30">
                            <Select
                              value={
                                selectedSaleInvoice
                                  ? selectedSaleInvoice
                                  : [
                                      {
                                        label: "Choose Invoice",
                                        value: null,
                                      },
                                    ]
                              }
                              className="select"
                              options={
                                addSaleType === 2
                                  ? sale_booking_data?.map((item) => {
                                      return {
                                        label: item.invoiceNumber,
                                        value: item._id,
                                      };
                                    })
                                  : sale_quotation_data?.map((item) => {
                                      return {
                                        label: item.invoiceNumber,
                                        value: item._id,
                                      };
                                    })
                              }
                              placeholder="Choose"
                              onChange={(value) =>
                                setSelectedSaleInvoice(value)
                              }
                            />
                          </div>
                          <div className="col-lg-1 col-sm-6 col-12 mb-30 d-inline-flex align-items-center justify-content-center">
                            <span>Or</span>
                          </div>
                          <div className="col-lg-3 col-sm-6 col-12 mb-30">
                            <Select
                              value={
                                selectedBookedCustomer
                                  ? selectedBookedCustomer
                                  : [
                                      {
                                        label: "Choose Customer",
                                        value: null,
                                      },
                                    ]
                              }
                              className="select"
                              options={bookedCustomerArray?.map(
                                (customer, index) => {
                                  return {
                                    label: customer,
                                    value: index + 1,
                                  };
                                }
                              )}
                              placeholder="Choose Customer"
                              onChange={(value) =>
                                setSelectedBookedCustomer(value)
                              }
                            />
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                      <form>
                        <div className="row">
                          <div className="col-lg-3 col-sm-6 col-12">
                            <div className="input-blocks">
                              <label>
                                Customer Name
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <div className="row">
                                <div className="col-lg-12 col-sm-12 col-12 d-flex align-items-center justify-content-between flex-wrap">
                                  <div style={{ width: "84%" }}>
                                    <Select
                                      isDisabled={
                                        addSaleType == 2 || addSaleType == 3
                                          ? true
                                          : false
                                      }
                                      value={
                                        selectedCustomer
                                          ? selectedCustomer
                                          : [
                                              {
                                                label: "Choose",
                                                value: null,
                                              },
                                            ]
                                      }
                                      className="select"
                                      options={customer_data?.map((item) => {
                                        return {
                                          label: item.customerName,
                                          value: item._id,
                                        };
                                      })}
                                      placeholder="Choose Customer"
                                      onChange={(value) =>
                                        setSelectedCustomer(value)
                                      }
                                    />
                                  </div>
                                  <div
                                    className="add-icon d-flex justify-content-end"
                                    style={{ width: "15%" }}
                                  >
                                    <Link
                                      data-bs-toggle="modal"
                                      data-bs-target={`#add-customer`}
                                      onClick={(e) => e.preventDefault()}
                                      className="choose-add"
                                    >
                                      <PlusCircle className="plus" />
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3 col-sm-6 col-12">
                            <div className="input-blocks">
                              <label>Date</label>
                              <div className="input-groupicon calender-input">
                                <DatePicker
                                  disabled
                                  popupStyle={{ zIndex: 2000 }}
                                  selected={selectedDate}
                                  type="date"
                                  className="filterdatepicker add-sales-filterdatepicker"
                                  dateFormat="dd-MM-yyyy"
                                  placeholder={`${selectedDate.toDateString()}`}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3 col-sm-6 col-12">
                            <div className="input-blocks">
                              <label>
                                Invoice Number
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <div className="input-groupicon calender-input">
                                <input
                                  disabled
                                  readOnly
                                  style={{ padding: 10 }}
                                  type="text"
                                  value={salesInvoiceNumber}
                                  placeholder="Enter Invoice Number"
                                  onChange={(e) => {
                                    setSalesInvoiceNumber(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          {/* <div className="col-lg-3 col-sm-6 col-12">
                            <div className="input-blocks">
                              <label>
                                Supplier<span style={{ color: "red" }}>*</span>
                              </label>
                              <div className="col-lg-12 col-sm-12 col-12 d-flex align-items-center justify-content-between flex-wrap">
                                <div style={{ width: "83%" }}>
                                  <Select
                                    className="select"
                                    value={
                                      selectedSupplier
                                        ? selectedSupplier
                                        : [
                                            {
                                              label: "Choose",
                                              value: "Choose",
                                            },
                                          ]
                                    }
                                    options={supplier_data?.map((item) => {
                                      return {
                                        label: item.supplierName,
                                        value: item.id,
                                      };
                                    })}
                                    placeholder="Choose Supplier"
                                    onChange={(value) =>
                                      setSelectedSupplier(value)
                                    }
                                  />
                                </div>
                                <div
                                  className="add-icon"
                                  style={{ width: "15%" }}
                                >
                                  <Link
                                    data-bs-toggle="modal"
                                    data-bs-target={`#add-supplier`}
                                    onClick={(e) => e.preventDefault()}
                                    className="choose-add"
                                  >
                                    <PlusCircle className="plus" />
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div> */}
                          <div className="col-lg-12 col-sm-6 col-12">
                            <div className="input-blocks">
                              <label>
                                Product Name
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <div className="col-lg-12 col-sm-12 col-12 d-flex align-items-center justify-content-between flex-wrap">
                                <div
                                  className="input-groupicon select-code"
                                  ref={dropdownRef}
                                  style={{ width: "96%" }}
                                >
                                  <input
                                    style={{ padding: 10 }}
                                    type="text"
                                    value={productInput}
                                    placeholder="Please type product name and select"
                                    onChange={(e) => {
                                      setProductInput(e.target.value);
                                    }}
                                    onFocus={() => setShowProductList(true)}
                                  />
                                  {showProductList ? (
                                    <div className="select-code-dropdown">
                                      {filteredList?.map((product, index) => {
                                        return (
                                          <div
                                            key={index}
                                            onClick={() => {
                                              selectProduct(product);
                                              setShowProductList(false);
                                            }}
                                            style={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <span>
                                              {" "}
                                              <span style={{ fontWeight: 900 }}>
                                                {product.productName}
                                              </span>{" "}
                                              | {product.productItemCode}{" "}
                                            </span>{" "}
                                            <span
                                              style={{
                                                minWidth: "18%",
                                                display: "flex",
                                              }}
                                            >
                                              {" "}
                                              <span
                                                style={{
                                                  fontWeight: 700,
                                                  marginRight: "5px",
                                                }}
                                              >
                                                Item Qty:{" "}
                                              </span>{" "}
                                              {getProductQty(product._id)}
                                            </span>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                                <div
                                  className="add-icon"
                                  style={{ width: "3.5%" }}
                                >
                                  <Link
                                    data-bs-toggle="modal"
                                    data-bs-target={`#add-product`}
                                    onClick={(e) => e.preventDefault()}
                                    className="choose-add"
                                  >
                                    <PlusCircle className="plus" />
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-2 col-sm-6 col-12">
                            <div className="input-blocks">
                              <label>Order Tax (%)</label>
                              <div className="input-groupicon select-code">
                                <input
                                  disabled
                                  value={orderTaxValue}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    if (/^\d*$/.test(value)) {
                                      const numberValue = Number(value);
                                      if (numberValue <= 100) {
                                        setOrderTaxValue(value);
                                      }
                                    }
                                  }}
                                  type="text"
                                  defaultValue={0}
                                  className="p-2"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-2 col-sm-6 col-12">
                            <div className="input-blocks">
                              <label>Discount Type</label>
                              <div className="input-groupicon select-code">
                                <Select
                                  value={
                                    discountType
                                      ? discountType
                                      : [
                                          {
                                            label: "Choose",
                                            value: null,
                                          },
                                        ]
                                  }
                                  onChange={(value) => setDiscountType(value)}
                                  className="select"
                                  options={[
                                    {
                                      label: "Percent",
                                      value: "Percent",
                                    },
                                    {
                                      label: "Cash",
                                      value: "Cash",
                                    },
                                  ]}
                                  placeholder="Choose"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-2 col-sm-6 col-12">
                            <div className="input-blocks">
                              <label>Discount</label>
                              <div className="input-groupicon select-code">
                                <input
                                  disabled={!discountType?.value ? true : false}
                                  value={discountValue}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    if (/^\d*$/.test(value)) {
                                      const numberValue = Number(value);
                                      if (discountType.value === "Percent") {
                                        if (numberValue <= 100) {
                                          setDiscountValue(value);
                                        }
                                      } else {
                                        setDiscountValue(value);
                                      }
                                    }
                                  }}
                                  type="number"
                                  defaultValue={0}
                                  className="p-2"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-2 col-sm-6 col-12">
                            <div className="input-blocks">
                              <label>Shipping (₹)</label>
                              <div className="input-groupicon select-code">
                                <input
                                  value={shippingValue}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    if (/^\d*$/.test(value)) {
                                      const numberValue = Number(value);
                                      if (numberValue <= 1000) {
                                        setShippingValue(e.target.value);
                                      }
                                    }
                                  }}
                                  type="number"
                                  defaultValue={0}
                                  className="p-2"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-2 col-sm-6 col-12">
                            <div className="input-blocks mb-5">
                              <label>
                                Sale Status{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <Select
                                isDisabled
                                value={
                                  salesStatus
                                    ? salesStatus
                                    : [
                                        {
                                          label: "Completed",
                                          value: "Completed",
                                        },
                                      ]
                                }
                                onChange={(value) => {
                                  setSalesStatus(value);
                                }}
                                className="select"
                                options={[
                                  {
                                    label: "Completed",
                                    value: "Completed",
                                  },
                                ]}
                                placeholder="Choose"
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          className="table-responsive no-pagination"
                          style={{ overflow: "unset" }}
                        >
                          <table className="table datanew">
                            <thead>
                              <tr>
                                <th
                                  style={{
                                    background: "#FAFBFE",
                                    color: "#67748E",
                                  }}
                                >
                                  Product
                                </th>
                                <th
                                  style={{
                                    background: "#FAFBFE",
                                    color: "#67748E",
                                  }}
                                >
                                  Unit
                                </th>
                                <th
                                  style={{
                                    background: "#FAFBFE",
                                    color: "#67748E",
                                  }}
                                >
                                  Qty
                                </th>
                                <th
                                  style={{
                                    background: "#FAFBFE",
                                    color: "#67748E",
                                  }}
                                >
                                  Alt. Qty
                                </th>
                                {/* <th style={{background: '#FAFBFE', color: '#67748E'}}>Current Stock</th> */}
                                <th
                                  style={{
                                    background: "#FAFBFE",
                                    color: "#67748E",
                                  }}
                                >
                                  Price (₹)
                                </th>
                                <th
                                  style={{
                                    background: "#FAFBFE",
                                    color: "#67748E",
                                  }}
                                >
                                  Discount
                                </th>
                                <th
                                  style={{
                                    background: "#FAFBFE",
                                    color: "#67748E",
                                  }}
                                >
                                  Tax(%)
                                </th>
                                <th
                                  style={{
                                    background: "#FAFBFE",
                                    color: "#67748E",
                                  }}
                                >
                                  Tax Amt.(₹)
                                </th>
                                <th
                                  style={{
                                    background: "#FAFBFE",
                                    color: "#67748E",
                                  }}
                                >
                                  Unit Cost(₹)
                                </th>
                                <th
                                  style={{
                                    background: "#FAFBFE",
                                    color: "#67748E",
                                  }}
                                >
                                  Total Cost(₹)
                                </th>
                                {selectedSaleInvoice ? (
                                  ""
                                ) : (
                                  <th
                                    style={{
                                      background: "#FAFBFE",
                                      color: "#67748E",
                                    }}
                                  >
                                    Action
                                  </th>
                                )}
                              </tr>
                            </thead>
                            <tbody>
                              {selectedProducts?.map((product, index) => {
                                totalTax.current = 0;
                                let finalPurchasePrice = Number(
                                  purchasePriceList[index]
                                    ? purchasePriceList[index]
                                    : 0
                                );
                                let finalDiscount =
                                  product?.productDiscountType.label == "Cash"
                                    ? Number(product?.productDiscountValue)
                                    : (Number(
                                        purchasePriceList[index]
                                          ? purchasePriceList[index]
                                          : 0
                                      ) *
                                        Number(product?.productDiscountValue)) /
                                      100;
                                let finalDiscountedPrice =
                                  finalPurchasePrice - finalDiscount;

                                let finalTax =
                                  ((finalDiscountedPrice
                                    ? finalDiscountedPrice
                                    : 0) *
                                    Number(product?.productTaxValue)) /
                                  100;
                                let finalUnitCost = purchasePriceList[index]
                                  ? finalDiscountedPrice + finalTax
                                  : 0;

                                let totalTaxAmt =
                                  Number(purchasePriceList[index]) *
                                  (Number(product?.productTaxValue) / 100) *
                                  Number(selectedQty[index]);

                                let newTaxArr = [...totalTaxList.current];
                                newTaxArr[index] = totalTaxAmt;
                                totalTaxList.current = newTaxArr;

                                totalTax.current = newTaxArr.reduce(
                                  (prev, curr) => Number(prev) + Number(curr)
                                );

                                return (
                                  <tr key={index}>
                                    <td>{product.productName}</td>
                                    <td>
                                      <Select
                                        value={selectedUnits[index]}
                                        onChange={(value) => {
                                          changeUnit(value, index);
                                          changePurchasePrice(0, index);
                                        }}
                                        className="select"
                                        options={
                                          product?.productClubbedUnit?.label
                                            ? [
                                                {
                                                  label:
                                                    product?.productUnit?.label,
                                                  value:
                                                    product?.productUnit?.label,
                                                },
                                                {
                                                  label:
                                                    product?.productClubbedUnit
                                                      ?.label,
                                                  value:
                                                    product?.productClubbedUnit
                                                      ?.label,
                                                },
                                              ]
                                            : product?.productUnit?.label
                                            ? [
                                                {
                                                  label: "Choose",
                                                  value: null,
                                                },
                                                {
                                                  label:
                                                    product?.productUnit?.label,
                                                  value:
                                                    product?.productUnit?.label,
                                                },
                                              ]
                                            : [{ label: "Choose", value: null }]
                                        }
                                        placeholder="Choose"
                                      />
                                    </td>
                                    <td>
                                      <input
                                        disabled={
                                          product?.selectedUnit?.value
                                            ? false
                                            : true
                                        }
                                        style={{ width: 80 }}
                                        value={product?.selectedQty}
                                        onChange={(e) =>
                                          changeQty(e.target.value, index)
                                        }
                                        type="number"
                                        defaultValue={0}
                                        className="p-2"
                                      />
                                    </td>
                                    <td>{product.productAlternateQty}</td>
                                    <td>
                                      <div className="input-groupicon select-code">
                                        <input
                                          style={{ width: 80 }}
                                          value={purchasePriceList[index]}
                                          onChange={(e) =>
                                            changePurchasePrice(
                                              e.target.value,
                                              index
                                            )
                                          }
                                          type="number"
                                          defaultValue={0}
                                          className="p-2"
                                        />
                                      </div>
                                    </td>
                                    <td>
                                      {product?.productDiscountValue
                                        ? product?.productDiscountValue
                                        : 0}
                                      {product?.productDiscountValue
                                        ? product?.productDiscountType.label ==
                                          "Cash"
                                          ? "₹"
                                          : "%"
                                        : ""}
                                    </td>
                                    <td>
                                      {product.productTaxValue
                                        ? product.productTaxValue
                                        : 0}
                                    </td>
                                    <td>
                                      {product.productCurrentTax
                                        ? Number(
                                            product.productCurrentTax
                                          ).toFixed(2)
                                        : 0}
                                    </td>
                                    <td>
                                      {finalUnitCost
                                        ? Number(finalUnitCost).toFixed(2)
                                        : 0}
                                    </td>
                                    <td>
                                      {finalUnitCost && product.totalCost
                                        ? Number(product.totalCost).toFixed(2)
                                        : 0}
                                    </td>
                                    {selectedSaleInvoice ? (
                                      <></>
                                    ) : (
                                      <td>
                                        <span
                                          className="feather-trash-container"
                                          onClick={() => deleteProduct(index)}
                                        >
                                          <i
                                            data-feather="trash-2"
                                            className="feather feather-trash-2 feather-trash-2"
                                          />
                                        </span>
                                      </td>
                                    )}
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                        <div className="row">
                          <div className="col-lg-6 ms-auto">
                            <div className="total-order w-100 max-widthauto m-auto mb-4">
                              <ul>
                                <li>
                                  <h4>Total Tax(₹)</h4>
                                  <h5>
                                    {totalTax.current
                                      ? Number(totalTax.current).toFixed(2)
                                      : 0}
                                  </h5>
                                </li>
                                <li>
                                  <h4>
                                    Discount
                                    {discountType.label == "Percent"
                                      ? "(%)"
                                      : discountType.label == "Cash"
                                      ? "(₹)"
                                      : ""}
                                  </h4>
                                  <h5>
                                    {Number(discountValue)
                                      ? Number(discountValue).toFixed(2)
                                      : 0}
                                  </h5>
                                </li>
                                <li>
                                  <h4>Shipping (₹)</h4>
                                  <h5>
                                    {Number(shippingValue)
                                      ? Number(shippingValue).toFixed(2)
                                      : 0}
                                  </h5>
                                </li>
                                <li>
                                  <h4>Grand Total(₹)</h4>
                                  <h5>
                                    {Number(grandTotal)
                                      ? Number(grandTotal).toFixed(2)
                                      : 0}
                                  </h5>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-2 col-sm-6 col-12">
                            <div className="input-blocks">
                              <label>Due Amount (₹) </label>
                              <div className="input-groupicon select-code">
                                <input
                                  disabled
                                  value={(
                                    Number(grandTotal) - Number(receivedAmount)
                                  ).toFixed(2)}
                                  type="number"
                                  defaultValue={0}
                                  className="p-2"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-2 col-sm-6 col-12">
                            <div className="input-blocks">
                              <label>Due Date</label>
                              <div className="input-groupicon calender-input">
                                {/* <DatePicker
                                  allowClear
                                  placement="bottomLeft"
                                  popupStyle={{ zIndex: 2000 }}
                                  selected={dueDate}
                                  onChange={(date) => setDueDate(date)}
                                  type="date"
                                  className="filterdatepicker"
                                  dateFormat="dd-MM-yyyy"
                                  placeholder="Choose Date"
                                /> */}
                                <input
                                  onChange={(e) => {
                                    setDueDate(e.target.value);
                                  }}
                                  type="date"
                                  className="custom-date-picker"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-2 col-sm-6 col-12">
                            <div className="input-blocks">
                              <label>Received Amount (₹) </label>
                              <div className="input-groupicon select-code">
                                <input
                                  value={receivedAmount}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    if (/^\d*$/.test(value)) {
                                      const numberValue = Number(value);
                                      setReceivedAmount(value);
                                    }
                                  }}
                                  type="number"
                                  defaultValue={0}
                                  className="p-2"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-2 col-sm-6 col-12">
                            <div className="input-blocks mb-5">
                              <label>Payment Status </label>

                              <div
                                className="input-groupicon select-code"
                                style={{ width: "100%" }}
                              >
                                <Select
                                  value={
                                    paymentStatus?.value
                                      ? paymentStatus
                                      : [
                                          {
                                            label: "Choose",
                                            value: null,
                                          },
                                        ]
                                  }
                                  onChange={(value) => setPaymentStatus(value)}
                                  className="select"
                                  options={[
                                    {
                                      label: "Completed",
                                      value: "Completed",
                                    },
                                    { label: "Pending", value: "Pending" },
                                  ]}
                                  placeholder="Choose"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-2 col-sm-6 col-12">
                            <div className="input-blocks mb-5">
                              <label>Payment Method </label>

                              <div className="input-groupicon select-code">
                                <Select
                                  value={
                                    paymentMethod?.value
                                      ? paymentMethod
                                      : [{ label: "Choose", value: null }]
                                  }
                                  onChange={(value) => setPaymentMethod(value)}
                                  className="select"
                                  options={[
                                    { label: "Cash", value: "Cash" },
                                    { label: "Card", value: "Card" },
                                    { label: "Online", value: "Online" },
                                  ]}
                                  placeholder="Choose"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12 text-end">
                            <button
                              type="button"
                              className="btn btn-cancel add-cancel me-3"
                              data-bs-dismiss="modal"
                              onClick={clearData}
                            >
                              Cancel
                            </button>
                            <div
                              className={`btn btn-submit ${
                                isLoading ? "disabled" : ""
                              }`}
                              onClick={showTransport}
                            >
                              Submit
                            </div>
                            <span
                              data-bs-dismiss="modal"
                              id="dismiss-modal"
                            ></span>
                          </div>
                        </div>
                      </form>
                      <span style={{ fontStyle: "italic" }}>
                        All fields with <span style={{ color: "red" }}>*</span>{" "}
                        are required
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /add popup */}
        {/* details popup */}
        <ViewSales
          type="sale"
          viewSalesDetailData={viewSalesDetailData}
          setViewSalesDetailData={setViewSalesDetailData}
        />
        {/* /details popup */}
        {/* edit popup */}
        <EditSales
          type="sale"
          viewSalesDetailData={viewSalesDetailData}
          setViewSalesDetailData={setViewSalesDetailData}
        />
        {/* /edit popup */}
        {/* show payment Modal */}
        <div
          className="modal fade"
          id="showpayment"
          tabIndex={-1}
          aria-labelledby="showpayment"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered stock-adjust-modal">
            <div className="modal-content">
              <div className="page-wrapper-new p-0">
                <div className="content">
                  <div className="modal-header border-0 custom-modal-header">
                    <div className="page-title">
                      <h4>Show Payments</h4>
                    </div>
                    <button
                      type="button"
                      className="close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body custom-modal-body">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="modal-body-table total-orders">
                          <div className="table-responsive">
                            <table className="table  datanew">
                              <thead>
                                <tr>
                                  <th>Date</th>
                                  <th>Invoice</th>
                                  <th>Amount</th>
                                  <th>Paid By</th>
                                  <th className="no-sort">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>19 Jan 2023</td>
                                  <td>INV/SL0101</td>
                                  <td>$1500</td>
                                  <td>Cash</td>
                                  <td className="action-table-data">
                                    <div className="edit-delete-action">
                                      <Link className="me-3 p-2" to="#">
                                        <i
                                          data-feather="printer"
                                          className="feather-rotate-ccw"
                                        />
                                      </Link>
                                      <Link
                                        className="me-3 p-2"
                                        to="#"
                                        data-bs-toggle="modal"
                                        data-bs-target="#editpayment"
                                      >
                                        <i
                                          data-feather="edit"
                                          className="feather-edit"
                                        />
                                      </Link>
                                      <Link className="confirm-text p-2" to="#">
                                        <i
                                          data-feather="trash-2"
                                          className="feather-trash-2"
                                        />
                                      </Link>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* show payment Modal */}
        {/* Create payment Modal */}
        <div
          className="modal fade"
          id="createpayment"
          tabIndex={-1}
          aria-labelledby="createpayment"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header border-0 custom-modal-header">
                <div className="page-title">
                  <h4>Create Payments</h4>
                </div>
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body custom-modal-body">
                <form>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="input-blocks">
                        <label> Date</label>
                        <div className="input-groupicon calender-input">
                          <DatePicker
                            selected={selectedDate}
                            onChange={handleDateChange}
                            type="date"
                            className="filterdatepicker"
                            dateFormat="dd-MM-yyyy"
                            placeholder="Choose Date"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-12 col-12">
                      <div className="input-blocks">
                        <label>Invoice</label>
                        <input type="text" className="form-control" />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-4 col-sm-12 col-12">
                      <div className="input-blocks">
                        <label>Received Amount</label>
                        <div className="input-groupicon calender-input">
                          <i data-feather="dollar-sign" className="info-img" />
                          <input type="text" />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-12 col-12">
                      <div className="input-blocks">
                        <label>Paying Amount</label>
                        <div className="input-groupicon calender-input">
                          <i data-feather="dollar-sign" className="info-img" />
                          <input type="text" />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-12 col-12">
                      <div className="input-blocks">
                        <label>Payment type</label>

                        <Select
                          className="select"
                          options={paymenttype}
                          placeholder="Newest"
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="input-blocks">
                        <label>Description</label>
                        <textarea className="form-control" defaultValue={""} />
                        <p>Maximum 60 Characters</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="modal-footer-btn">
                      <button
                        type="button"
                        className="btn btn-cancel me-2"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </button>
                      <Link to="#" className="btn btn-submit">
                        Submit
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* Create payment Modal */}
        {/* edit payment Modal */}
        <div
          className="modal fade"
          id="editpayment"
          tabIndex={-1}
          aria-labelledby="editpayment"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header border-0 custom-modal-header">
                <div className="page-title">
                  <h4>Edit Payments</h4>
                </div>
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body custom-modal-body">
                <form>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="input-blocks">
                        <label>19 Jan 2023</label>
                        <div className="input-groupicon calender-input">
                          <i data-feather="calendar" className="info-img" />
                          <input
                            type="text"
                            className="datetimepicker form-control"
                            placeholder="Select Date"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-12 col-12">
                      <div className="input-blocks">
                        <label>Invoice</label>
                        <input type="text" defaultValue="INV/SL0101" />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-4 col-sm-12 col-12">
                      <div className="input-blocks">
                        <label>Received Amount</label>
                        <div className="input-groupicon calender-input">
                          <i data-feather="dollar-sign" className="info-img" />
                          <input type="text" defaultValue={1500} />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-12 col-12">
                      <div className="input-blocks">
                        <label>Paying Amount</label>
                        <div className="input-groupicon calender-input">
                          <i data-feather="dollar-sign" className="info-img" />
                          <input type="text" defaultValue={1500} />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-12 col-12">
                      <div className="input-blocks">
                        <label>Payment type</label>
                        <select className="select">
                          <option>Cash</option>
                          <option>Online</option>
                          <option>Inprogress</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="input-blocks summer-description-box transfer">
                        <label>Description</label>
                        <textarea className="form-control" defaultValue={""} />
                      </div>
                      <p>Maximum 60 Characters</p>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="modal-footer-btn mb-3 me-3">
                      <button
                        type="button"
                        className="btn btn-cancel me-2"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="btn btn-submit"
                        data-bs-dismiss="modal"
                      >
                        Save Changes
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* edit payment Modal */}
        {/* <div className="customizer-links" id="setdata">
                    <ul className="sticky-sidebar">
                        <li className="sidebar-icons">
                            <Link
                                to="#"
                                className="navigation-add"
                                data-bs-toggle="tooltip"
                                data-bs-placement="left"
                                data-bs-original-title="Theme"
                            >
                                <i data-feather="settings" className="feather-five" />
                            </Link>
                        </li>
                    </ul>
                </div> */}
      </>

      <CustomerModal from={"Sales"} target="#add-sales-new" />
      <SupplierModal from={"Sales"} target="#add-sales-new" />
      <AddProductModal from={"Sales"} target="#add-sales-new" />

      <Modal
        className={`${CSS.printPreviewModal}`}
        open={showPdfModal}
        footer={false}
        onCancel={() => {
          setShowPdfModal(false);
        }}
      >
        <div className={`${CSS.modalBox}`}>
          <PrintPreview>
            <PrintLayout data={pdfData} />
          </PrintPreview>
        </div>
      </Modal>
      <FilterModal
        visible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        filtersConfig={filtersConfig}
        onApplyFilters={handleApplyFilters}
        initialValues={filters}
      />

      <TransportModal
        showTransportModal={showTransportModal}
        setShowTransportModal={setShowTransportModal}
        submitData={submitData}
        invoiceNumber={salesInvoiceNumber}
        from={"Sales"}
        target="#add-sales-new"
        data={{
          storeId: selected_store[0]?._id,
          userCode: login_data?.empCode,
        }}
      />
    </div>
  );
};

export default SalesList;
