/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Breadcrumbs from "../../core/breadcrumbs";
// import { Eye } from "react-feather";
import { Link } from "react-router-dom";
import { Sliders } from "react-feather";
import Select from "react-select";
import { Filter } from "react-feather";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import { Calendar, StopCircle, User, FileText } from "react-feather";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import Swal from "sweetalert2";
import { all_routes } from "../../Router/all_routes";
import { API_URL } from "../../constants/AppConstants.js";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { alertData, expenseData } from "../../core/redux/action";

const ExpensesList = () => {
  const login_data = useSelector((state) => state.login_data);
  const route = all_routes;
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDateTwo, setSelectedDateTwo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const selected_store = useSelector((state) => state.selected_store);
  const expense_category = useSelector((state) => state.expense_category_data);
  const expense_data = useSelector((state) => state.expense_data);

  const [expenseCategory, setExpenseCategory] = useState({
    label: "Choose",
    value: null,
  });
  const [expenseCategoryArr, setExpenseCategoryArr] = useState({
    label: "",
    value: "",
  });
  const [selectedDateModal, setSelectedDateModal] = useState(null);
  const [expenseAmount, setExpenseAmount] = useState(0);
  const [referenceNumber, setReferenceNumber] = useState("");
  const [expenseFor, setExpenseFor] = useState("");
  const [expenseDescription, setExpenseDescription] = useState("");

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const handlerefChange = (date) => {
    setSelectedDateTwo(date);
  };
  const handleDateChangeModal = (date) => {
    setSelectedDateModal(date);
  };
  const options = [{ value: "sortByDate", label: "Sort by Date" }];
  const optionsOne = [{ value: "chooseName", label: "Choose Name" }];

  const optionsTwo = [{ value: "chooseStatus", label: "Choose Status" }];

  const optionsModalOne = [{ value: "choose", label: "Choose" }];

  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const toggleFilterVisibility = () => {
    setIsFilterVisible((prevVisibility) => !prevVisibility);
  };

  useEffect(() => {
    let expense_category_arr = [];
    if (expense_category) {
      expense_category?.forEach((data) => {
        expense_category_arr.push({
          label: data.expenseName,
          value: data.id,
        });
      });
      setExpenseCategoryArr(expense_category_arr);
    }
  }, []);

  // const confirmText = () => {
  //   Swal.fire({
  //     title: "Are you sure?",
  //     text: "You won't be able to revert this!",
  //     type: "warning",
  //     showCancelButton: !0,
  //     confirmButtonColor: "#3085d6",
  //     cancelButtonColor: "#d33",
  //     confirmButtonText: "Yes, delete it!",
  //     confirmButtonClass: "btn btn-primary",
  //     cancelButtonClass: "btn btn-danger ml-1",
  //     buttonsStyling: !1,
  //   }).then(function (t) {
  //     t.value &&
  //       Swal.fire({
  //         type: "success",
  //         title: "Deleted!",
  //         text: "Your file has been deleted.",
  //         confirmButtonClass: "btn btn-success",
  //       });
  //   });
  // };

  function clearData() {
    setExpenseCategory({
      label: "Choose",
      value: null,
    });
    setSelectedDateModal("");
    setExpenseAmount(0);
    setReferenceNumber("");
    setExpenseFor("");
    setExpenseDescription("");
  }

  async function fetchData() {
    try {
      let response = await axios.get(API_URL + "expenses");
      if (response.status === 200) {
        dispatch(expenseData(response.data));
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function submitData() {
    let dataBody = {
      expenseCategory,
      expenseDate: new Date(selectedDateModal)
        .toLocaleDateString()
        .split(",")[0],
      expenseAmount,
      referenceNumber,
      expenseUser: expenseFor,
      expenseDescription,
      storeId: selected_store[0]?._id,
      createdOn: new Date().toLocaleDateString().split(",")[0],
      userCode: login_data?.empCode,
      itemActive: true,
    };

    try {
      setIsLoading(true);
      let response = await axios.post(API_URL + "expenses", dataBody);
      if (response.status === 200) {
        dispatch(
          alertData({
            type: "success",
            msg: "Expense added successfully!",
          })
        );
        setTimeout(() => {
          setIsLoading(false);
          document?.querySelector("#add-expense .close")?.click();
        }, 1000);
        fetchData();
        clearData();
      }
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <Breadcrumbs
            maintitle="Expense List"
            subtitle="Manage Your Expenses"
            addButton="Add New Expenses"
            modalId="add-expense"
          />
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <input
                      type="text"
                      placeholder="Search"
                      className="form-control form-control-sm formsearch"
                    />
                    <Link to className="btn btn-searchset">
                      <i data-feather="search" className="feather-search" />
                    </Link>
                  </div>
                </div>
                {/* <div className="search-path">
                  <Link
                    className={`btn btn-filter ${
                      isFilterVisible ? "setclose" : ""
                    }`}
                    id="filter_search"
                  >
                    <Filter
                      className="filter-icon"
                      onClick={toggleFilterVisibility}
                    />
                    <span onClick={toggleFilterVisibility}>
                      <ImageWithBasePath
                        src="assets/img/icons/closes.svg"
                        alt="img"
                      />
                    </span>
                  </Link>
                </div> */}
                {/* <div className="form-sort stylewidth">
                  <Sliders className="info-img" />
                  <Select
                    className="select "
                    options={options}
                    placeholder="Sort by Date"
                  />
                </div> */}
              </div>
              {/* /Filter */}
              {/* <div className="card" id="filter_inputs"> */}

              <div
                className={`card${isFilterVisible ? " visible" : ""}`}
                id="filter_inputs"
                style={{ display: isFilterVisible ? "block" : "none" }}
              >
                <div className="card-body pb-0">
                  <div className="row">
                    <div className="col-lg-2 col-sm-6 col-12">
                      <div className="input-blocks">
                        <User className="info-img" />
                        <Select
                          className="select select-height"
                          options={optionsOne}
                          defaultValue={optionsOne[0]}
                        />
                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-6 col-12">
                      <div className="input-blocks">
                        <StopCircle className="info-img" />
                        <Select
                          className="select select-height"
                          options={optionsTwo}
                          defaultValue={optionsTwo[0]}
                        />
                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-6 col-12">
                      <div className="input-blocks">
                        <Calendar className="info-img" />
                        <div className="input-groupicon">
                          <DatePicker
                            selected={selectedDate}
                            onChange={handleDateChange}
                            dateFormat="dd/MM/yyyy"
                            placeholderText="From Date - To Date"
                            className="datetimepicker"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-6 col-12">
                      <div className="input-blocks">
                        {/* <i data-feather="file-text" className="info-img" /> */}
                        <FileText className="info-img" />
                        <div className="input-groupicon">
                          {/* <input
                            type="text"
                            className="datetimepicker"
                            placeholder="Enter Reference"
                          /> */}
                          <DatePicker
                            selected={selectedDateTwo}
                            onChange={handlerefChange}
                            dateFormat="dd/MM/yyyy"
                            placeholderText="Enter Reference"
                            className="datetimepicker"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12">
                      <div className="input-blocks">
                        <Link className="btn btn-filters ms-auto">
                          {" "}
                          <i
                            data-feather="search"
                            className="feather-search"
                          />{" "}
                          Search{" "}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /Filter */}
              <div className="table-responsive">
                <table className="table  datanew">
                  <thead>
                    <tr>
                      {/* <th className="no-sort">
                        <label className="checkboxs">
                          <input type="checkbox" id="select-all" />
                          <span className="checkmarks" />
                        </label>
                      </th> */}
                      <th>Category name</th>
                      <th>Reference</th>
                      <th>Date</th>
                      <th>Expense User</th>
                      <th>Amount</th>
                      <th>Description</th>
                      <th className="no-sort">Action</th>
                    </tr>
                  </thead>
                  <tbody className="Expense-list-blk">
                    {expense_data?.length
                      ? expense_data?.map((data, index) => {
                          return (
                            <tr key={index}>
                              {/* <td>
                                <label className="checkboxs">
                                  <input type="checkbox" />
                                  <span className="checkmarks" />
                                </label>
                              </td> */}
                              <td>{data.expenseCategory.label}</td>
                              <td>{data.referenceNumber}</td>
                              <td>{data.expenseDate}</td>
                              {/* <td>
                                <span className="badge badge-linesuccess">
                                  Active
                                </span>
                              </td> */}
                              <td>{data.expenseUser}</td>
                              <td>{data.expenseAmount}</td>
                              <td>{data.expenseDescription}</td>
                              <td className="action-table-data">
                                <div className="edit-delete-action">
                                  {/* <Link className="me-2 p-2 mb-0" to="#">
                                    <Eye className="action-eye" />
                                  </Link> */}
                                  {/* <Link
                                    className="me-2 p-2 mb-0"
                                    data-bs-toggle="modal"
                                    data-bs-target="#edit-units"
                                  >
                                    <i
                                      data-feather="edit"
                                      className="feather-edit"
                                    />
                                  </Link> */}
                                  <Link
                                    className="me-3 confirm-text p-2 mb-0"
                                    to="#"
                                  >
                                    <i
                                      data-feather="trash-2"
                                      className="feather-trash-2"
                                      // onClick={confirmText}
                                    />
                                  </Link>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      : ""}
                    {/* <tr>
                      <td>
                        <label className="checkboxs">
                          <input type="checkbox" />
                          <span className="checkmarks" />
                        </label>
                      </td>
                      <td>Employee Benefits</td>
                      <td>PT001</td>
                      <td>19 Jan 2023</td>
                      <td>
                        <span className="badge badge-linesuccess">Active</span>
                      </td>
                      <td>$550</td>
                      <td>Employee Vehicle</td>
                      <td className="action-table-data">
                        <div className="edit-delete-action">
                          <Link className="me-2 p-2 mb-0" to="#">
                            <Eye className="action-eye" />
                          </Link>
                          <Link
                            className="me-2 p-2 mb-0"
                            data-bs-toggle="modal"
                            data-bs-target="#edit-units"
                          >
                            <i data-feather="edit" className="feather-edit" />
                          </Link>
                          <Link className="me-3 confirm-text p-2 mb-0" to="#">
                            <i
                              data-feather="trash-2"
                              className="feather-trash-2"
                              onClick={confirmText}
                            />
                          </Link>
                        </div>
                      </td>
                    </tr>*/}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
      {/* Add Expense */}
      <div className="modal fade" id="add-expense">
        <div className="modal-dialog modal-dialog-centered custom-modal-two">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>Add Expense</h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={clearData}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="mb-3">
                        <label className="form-label">Expense Category</label>

                        <Select
                          value={
                            expenseCategory
                              ? expenseCategory
                              : [
                                  {
                                    label: "Choose",
                                    value: null,
                                  },
                                ]
                          }
                          options={expenseCategoryArr}
                          placeholder="Choose"
                          onChange={(value) => setExpenseCategory(value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="input-blocks date-group">
                        <i data-feather="calendar" className="info-img" />
                        <div className="input-groupicon">
                          <DatePicker
                            selected={selectedDateModal}
                            onChange={handleDateChangeModal}
                            dateFormat="dd/MM/yyyy"
                            placeholderText="Choose Date"
                            className="datetimepicker"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="mb-3">
                        <label className="form-label">Amount</label>
                        <input
                          value={expenseAmount}
                          onChange={(e) => setExpenseAmount(e.target.value)}
                          type="text"
                          className="form-control"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="mb-3">
                        <label className="form-label">Reference</label>
                        <input
                          type="text"
                          className="form-control"
                          value={referenceNumber}
                          onChange={(e) => setReferenceNumber(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="mb-3">
                        <label className="form-label">Expense User</label>
                        <input
                          type="text"
                          className="form-control"
                          value={expenseFor}
                          onChange={(e) => setExpenseFor(e.target.value)}
                        />
                      </div>
                    </div>
                    {/* Editor */}
                    <div className="col-md-12">
                      <div className="edit-add card">
                        <div className="edit-add">
                          <label className="form-label">Description</label>
                        </div>
                        <div className="card-body-list input-blocks mb-0">
                          <textarea
                            value={expenseDescription}
                            onChange={(e) =>
                              setExpenseDescription(e.target.value)
                            }
                            className="form-control"
                            defaultValue={""}
                          />
                        </div>
                        <p>Maximum 600 Characters</p>
                      </div>
                    </div>
                    {/* /Editor */}
                  </div>
                  <div className="modal-footer-btn">
                    <button
                      type="button"
                      className="btn btn-cancel me-2"
                      data-bs-dismiss="modal"
                      onClick={clearData}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className={`btn btn-submit ${
                        isLoading ? "disabled" : ""
                      }`}
                      onClick={submitData}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Add Expense */}

      {/* Edit Expense */}
      <div className="modal fade" id="edit-units">
        <div className="modal-dialog modal-dialog-centered custom-modal-two">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>Edit Expense</h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <form>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="mb-3">
                          <label className="form-label">Expense Category</label>
                          <select className="select">
                            <option>Employee Benefits</option>
                            <option>Foods &amp; Snacks</option>
                            <option>Entertainment</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-blocks date-group">
                          <i data-feather="calendar" className="info-img" />
                          <div className="input-groupicon">
                            <input
                              type="text"
                              className="datetimepicker ps-5"
                              placeholder="19 Jan 2023"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="mb-3">
                          <label className="form-label">Amount</label>
                          <input
                            type="text"
                            className="form-control"
                            defaultValue="$550.00"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="mb-3">
                          <label className="form-label">Reference</label>
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={55544}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="mb-3 input-blocks">
                          <label className="form-label">Expense For</label>
                          <input type="text" className="form-control" />
                          <span className="unlimited-text">
                            0 for Unlimited
                          </span>
                        </div>
                      </div>
                      {/* Editor */}
                      <div className="col-md-12">
                        <div className="edit-add card">
                          <div className="edit-add">
                            <label className="form-label">Description</label>
                          </div>
                          <div className="card-body-list input-blocks mb-0">
                            <textarea
                              className="form-control"
                              defaultValue={"Employee Vehicle"}
                            />
                          </div>
                          <p>Maximum 600 Characters</p>
                        </div>
                      </div>
                      {/* /Editor */}
                    </div>
                    <div className="modal-footer-btn">
                      <button
                        type="button"
                        className="btn btn-cancel me-2"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </button>
                      <button type="submit" className="btn btn-submit">
                        Save Changes
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Edit Expense */}
    </div>
  );
};

export default ExpensesList;
