/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import {
  ChevronUp,
  RotateCcw,
  Sliders,
  StopCircle,
  User,
} from "feather-icons-react/build/IconComponents";
import { setToogleHeader } from "../../core/redux/action";
import { useDispatch, useSelector } from "react-redux";
import { Filter } from "react-feather";
import Select from "react-select";
import Table from "../../core/pagination/datatable";
import DateRangePicker from "react-bootstrap-daterangepicker";
import Calendar from "feather-icons-react/build/IconComponents/Calendar";
import { Download } from "react-feather";
import { Modal } from "antd";
import PrintPreview from "../../common/PrintPreview";
import PrintLayout from "../../common/PrintLayout";
import CSS from "../../core/modals/PreviewModal.module.scss";
import { fetchSalesData } from "../../utils/utilities";

const InvoiceReport = () => {
  const login_data = useSelector((state) => state.login_data);
  const sales_data = useSelector((state) => state.sales_data);
  const selected_store = useSelector((state) => state.selected_store);

  const dispatch = useDispatch();
  const data = useSelector((state) => state.toggle_header);

  const [isFilterVisible, setIsFilterVisible] = useState(false);

  const [showPdfModal, setShowPdfModal] = useState(false);
  const [pdfData, setPdfData] = useState({});
  const [selectedInvoiceId, setSelectedInvoiceId] = useState(null);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [invoiceData, setInvoiceData] = useState([]);
  const [filteredInvoiceData, setFilteredInvoiceData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedDateFilterData, setSelectedDateFilterData] = useState("");
  const [selectedCustomerFilterData, setSelectedCustomerFilterData] =
    useState("");
  const [customerFilterArr, setCustomerFilterArr] = useState([]);

  const [selectedSaleStatusFilterData, setSelectedSaleStatusFilterData] =
    useState("");
  const [selectedPaymentStatusFilterData, setSelectedPaymentStatusFilterData] =
    useState("");
  const [selectedBookingStatusFilterData, setSelectedBookingStatusFilterData] =
    useState("");

  const toggleFilterVisibility = () => {
    setIsFilterVisible((prevVisibility) => !prevVisibility);
  };
  const renderTooltip = (props) => (
    <Tooltip id="pdf-tooltip" {...props}>
      Pdf
    </Tooltip>
  );
  const renderExcelTooltip = (props) => (
    <Tooltip id="excel-tooltip" {...props}>
      Excel
    </Tooltip>
  );
  const renderPrinterTooltip = (props) => (
    <Tooltip id="printer-tooltip" {...props}>
      Printer
    </Tooltip>
  );
  const renderRefreshTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Refresh
    </Tooltip>
  );
  const renderCollapseTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Collapse
    </Tooltip>
  );
  const status = [
    { value: "Choose Name", label: "Choose Name" },
    // { value: 'Rose', label: 'Rose' },
    // { value: 'Kaitlin', label: 'Kaitlin' },
  ];
  const statusupdate = [
    { value: "Choose Status", label: "Choose Status" },
    { value: "Paid", label: "Paid" },
    { value: "Unpaid", label: "Unpaid" },
    { value: "Overdue", label: "Overdue" },
  ];
  const oldandlatestvalue = [
    { value: "Sort by Date", label: "Sort by Date" },
    // { value: '07 09 23', label: '07 09 23' },
    // { value: '21 09 23', label: '21 09 23' },
  ];

  const columns = [
    {
      title: "Invoice No.",
      dataIndex: "invoiceNumber",
      //   sorter: (a, b) => a.invoiceno.length - b.invoiceno.length,
    },

    {
      title: "Customer",
      //   dataIndex: "customer",
      render: (data) => data?.customer?.label,
    },
    {
      title: "Total Amount",
      dataIndex: "grandTotal",
      //   sorter: (a, b) => a.amount.length - b.amount.length,
    },
    {
      title: "Amount Received",
      dataIndex: "receivedAmount",
      render: (data) => Number(data).toFixed(2),
      //   sorter: (a, b) => a.paid.length - b.paid.length,
    },
    {
      title: "Amount Due",
      dataIndex: "dueAmount",
      render: (data) => Number(data).toFixed(2),
      //   sorter: (a, b) => a.amountdue.length - b.amountdue.length,
    },
    {
      title: "Due Date",
      dataIndex: "dueDate",
      render: (date) => new Date(date).toDateString(),
      //   sorter: (a, b) => a.duedate.length - b.duedate.length,
    },
    {
      title: "Payment Status",
      dataIndex: "paymentStatus",
      render: (text) => (
        <div>
          {text?.label == "Completed" && (
            <span className="badge badge-linesuccess">{text?.label}</span>
          )}
          {text?.label == "Pending" && (
            <span className="badge badge-linedanger">{text?.label}</span>
          )}
          {/* {text.paymentStatus?.label === "Overdue" && (
            <span className="badge badges-warning">{text}</span>
          )} */}
        </div>
      ),
      //   sorter: (a, b) => a.status.length - b.status.length,
    },
    {
      title: "Sale Status",
      dataIndex: "salesStatus",
      render: (text) => (
        <div>
          {text?.label == "Completed" && (
            <span className="badge badge-linesuccess">{text?.label}</span>
          )}
          {text?.label == "Pending" && (
            <span className="badge badge-linedanger">{text?.label}</span>
          )}
          {/* {text.paymentStatus?.label === "Overdue" && (
            <span className="badge badges-warning">{text}</span>
          )} */}
        </div>
      ),
      //   sorter: (a, b) => a.status.length - b.status.length,
    },
    {
      title: "Actions",
      dataIndex: "_id",
      render: (data) => (
        <td className="action-table-data">
          <div className="edit-delete-action">
            <Link
              className="me-2 p-2"
              onClick={(e) => {
                e.preventDefault();
                setShowPdfModal(true);
                setSelectedInvoiceId(data);
              }}
            >
              <Download className="info-img" style={{ margin: 0 }} />
            </Link>
          </div>
        </td>
      ),
    },
  ];
  const initialSettings = {
    endDate: new Date("2020-08-11T12:30:00.000Z"),
    ranges: {
      "Last 30 Days": [
        new Date("2020-07-12T04:57:17.076Z"),
        new Date("2020-08-10T04:57:17.076Z"),
      ],
      "Last 7 Days": [
        new Date("2020-08-04T04:57:17.076Z"),
        new Date("2020-08-10T04:57:17.076Z"),
      ],
      "Last Month": [
        new Date("2020-06-30T18:30:00.000Z"),
        new Date("2020-07-31T18:29:59.999Z"),
      ],
      "This Month": [
        new Date("2020-07-31T18:30:00.000Z"),
        new Date("2020-08-31T18:29:59.999Z"),
      ],
      Today: [
        new Date("2020-08-10T04:57:17.076Z"),
        new Date("2020-08-10T04:57:17.076Z"),
      ],
      Yesterday: [
        new Date("2020-08-09T04:57:17.076Z"),
        new Date("2020-08-09T04:57:17.076Z"),
      ],
    },
    startDate: new Date("2020-08-04T04:57:17.076Z"), // Set "Last 7 Days" as default
    timePicker: false,
  };

  useEffect(() => {
    if (searchTerm) {
      setSelectedCustomerFilterData("");
      setSelectedSaleStatusFilterData("");
      setSelectedPaymentStatusFilterData("");
      const filtered = sales_data.filter(
        (item) =>
          (item.salesStatus.value === "Completed" &&
            item?.invoiceNumber
              .toLowerCase()
              .includes(searchTerm.toLowerCase())) ||
          item?.customer?.label.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredInvoiceData(filtered);
    } else {
      setFilteredInvoiceData(sales_data);
    }
  }, [searchTerm]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    if (sales_data && sales_data?.length) {
      let customerFilterArrCopy = [];

      sales_data.forEach((item) => {
        if (item.customer.value) {
          customerFilterArrCopy.push(item.customer.label);
        }
      });
      let uniqCustomerFilterArrCopy = [...new Set(customerFilterArrCopy)];
      setCustomerFilterArr(uniqCustomerFilterArrCopy);
    }
  }, [sales_data]);

  useEffect(() => {
    if (!searchTerm) {
      if (
        selectedCustomerFilterData?.value ||
        selectedSaleStatusFilterData?.value ||
        selectedPaymentStatusFilterData?.value
      ) {
        const filteredData = sales_data.filter((item) => {
          const matchesCustomer = selectedCustomerFilterData?.value
            ? item.customer.label === selectedCustomerFilterData.label
            : true;
          const matchesSaleStatus = selectedSaleStatusFilterData?.value
            ? item.salesStatus.label === selectedSaleStatusFilterData.label
            : true;
          const matchesPaymentStatus = selectedPaymentStatusFilterData?.value
            ? item.paymentStatus.label ===
              selectedPaymentStatusFilterData?.label
            : true;

          return matchesCustomer && matchesSaleStatus && matchesPaymentStatus;
        });

        setFilteredInvoiceData(filteredData);
      } else {
        setFilteredInvoiceData(sales_data);
      }
    }
  }, [
    selectedCustomerFilterData,
    selectedSaleStatusFilterData,
    selectedPaymentStatusFilterData,
    selectedDateFilterData,
  ]);

  useEffect(() => {
    let invoiceData = {};
    if (selectedInvoiceId) {
      sales_data?.forEach((data) => {
        if (data?._id == selectedInvoiceId) {
          invoiceData = {
            from: {
              name: "FM Marketing",
              email: "fmmarketing@example.com",
              phone: "9876543210",
              address: "Raipur, Chhattisgarh",
              country: {
                label: "India",
                value: "India",
              },
              state: {
                label: "Chhattisgarh",
                value: "Chhattisgarh",
              },
              city: {
                label: "Raipur",
                value: "Raipur",
              },
              status: "Active",
              itemActive: true,
            },
            to: {
              name: "",
              email: "",
              phone: "",
              address: "",
              country: {
                label: "India",
                value: "India",
              },
              state: {
                label: "Chhattisgarh",
                value: "Chhattisgarh",
              },
              city: {
                label: "Raipur",
                value: "Raipur",
              },
              status: "Active",
              itemActive: true,
            },
            Date: data?.date,
            Reference_Number: data?.invoiceNumber,
            Product_List: data?.productsList,
            Order_Tax: data?.orderTax,
            Discount: data?.discount,
            Shipping: data?.shipping,
            Grand_Total: data?.grandTotal,
            Invoice_Status: data?.invoiceStatus?.label,
            Description: data?.description,
          };
        }
      });
      setPdfData({
        heading: "Invoice",
        array: invoiceData,
      });
    }
  }, [selectedInvoiceId]);

  useEffect(() => {
    fetchSalesData(login_data, selected_store[0]);
  }, [selected_store]);

  useEffect(() => {
    const invoiceData = sales_data.filter(
      (item) => item.salesStatus.value === "Completed"
    );
    setFilteredInvoiceData(invoiceData);
  }, [sales_data]);

  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Invoices </h4>
                <h6>Manage Your Invoices</h6>
              </div>
            </div>
            <ul className="table-top-head">
              {/* <li>
                <OverlayTrigger placement="top" overlay={renderTooltip}>
                  <Link>
                    <ImageWithBasePath
                      src="assets/img/icons/pdf.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderExcelTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top">
                    <ImageWithBasePath
                      src="assets/img/icons/excel.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderPrinterTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top">
                    <i data-feather="printer" className="feather-printer" />
                  </Link>
                </OverlayTrigger>
              </li> */}
              <li>
                <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top">
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderCollapseTooltip}>
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    id="collapse-header"
                    className={data ? "active" : ""}
                    onClick={() => {
                      dispatch(setToogleHeader(!data));
                    }}
                  >
                    <ChevronUp />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
          </div>
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-set">
                    <div className="search-input">
                      <input
                        type="text"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        placeholder="Search by invoice or customer"
                        className="form-control form-control-sm formsearch"
                      />
                      <Link to className="btn btn-searchset">
                        <i data-feather="search" className="feather-search" />
                      </Link>
                    </div>
                  </div>
                </div>
                <Select
                  value={
                    selectedCustomerFilterData.value
                      ? selectedCustomerFilterData
                      : [
                          {
                            label: "Customer",
                            value: null,
                          },
                        ]
                  }
                  className="select mr-10"
                  options={customerFilterArr?.map((item, index) => {
                    return { label: item, value: index + 1 };
                  })}
                  placeholder="Customer"
                  onChange={(value) => setSelectedCustomerFilterData(value)}
                />
                <Select
                  value={
                    selectedSaleStatusFilterData.value
                      ? selectedSaleStatusFilterData
                      : [
                          {
                            label: "Sale",
                            value: null,
                          },
                        ]
                  }
                  className="select mr-10"
                  options={[
                    {
                      label: "Completed",
                      value: "Completed",
                    },
                    {
                      label: "Cancelled",
                      value: "Cancelled",
                    },
                  ]}
                  placeholder="Sale"
                  onChange={(value) => setSelectedSaleStatusFilterData(value)}
                />
                <Select
                  value={
                    selectedPaymentStatusFilterData.value
                      ? selectedPaymentStatusFilterData
                      : [
                          {
                            label: "Payment",
                            value: null,
                          },
                        ]
                  }
                  className="select mr-10"
                  options={[
                    {
                      label: "Completed",
                      value: "Completed",
                    },
                    {
                      label: "Pending",
                      value: "Pending",
                    },
                  ]}
                  placeholder="Payment"
                  onChange={(value) =>
                    setSelectedPaymentStatusFilterData(value)
                  }
                />
                {/* <Select
                value={
                  selectedDateFilterData.value
                    ? selectedDateFilterData
                    : [
                        {
                          label: "Date",
                          value: null,
                        },
                      ]
                }
                className="select mr-10"
                options={[
                  {
                    label: "Latest",
                    value: "Latest",
                  },
                  {
                    label: "Oldest",
                    value: "Oldest",
                  },
                ]}
                placeholder="Date"
                onChange={(value) => setSelectedDateFilterData(value)}
              /> */}
                <button
                  className="btn btn-danger"
                  onClick={() => {
                    setSelectedCustomerFilterData("");
                    setSelectedSaleStatusFilterData("");
                    setSelectedPaymentStatusFilterData("");
                    setSelectedDateFilterData("");
                  }}
                >
                  Clear
                </button>
              </div>
              <div
                className={`card${isFilterVisible ? " visible" : ""}`}
                id="filter_inputs"
                style={{ display: isFilterVisible ? "block" : "none" }}
              >
                <div className="card-body pb-0">
                  <div className="row">
                    <div className="col-lg-3 col-sm-6 col-12">
                      <div className="input-blocks">
                        <User className="info-img" />

                        <Select
                          className="select"
                          options={status}
                          placeholder="Choose Brand"
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-12">
                      <div className="input-blocks">
                        <StopCircle className="info-img" />

                        <Select
                          className="select"
                          options={statusupdate}
                          placeholder="Choose Brand"
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-12">
                      <div className="input-blocks">
                        <div className="position-relative daterange-wraper">
                          <Calendar />
                          <DateRangePicker initialSettings={initialSettings}>
                            <input
                              className="form-control"
                              type="text"
                              //style={{ border: "none" }}
                            />
                          </DateRangePicker>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-12">
                      <div className="input-blocks">
                        <a className="btn btn-filters ms-auto">
                          {" "}
                          <i
                            data-feather="search"
                            className="feather-search"
                          />{" "}
                          Search{" "}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /Filter */}
              <div className="table-responsive">
                <Table columns={columns} dataSource={filteredInvoiceData} />
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
      <Modal
        className={`${CSS.printPreviewModal}`}
        open={showPdfModal}
        footer={false}
        onCancel={() => {
          setShowPdfModal(false);
          setSelectedInvoice(null);
          setSelectedInvoiceId(null);
        }}
      >
        <div className={`${CSS.modalBox}`}>
          <PrintPreview>
            <PrintLayout data={pdfData} />
          </PrintPreview>
        </div>
      </Modal>
    </div>
  );
};

export default InvoiceReport;
