/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Filter, Sliders } from "react-feather";
import Select from "react-select";
import { Globe, User } from "react-feather";
import ImageWithBasePath from "../../img/imagewithbasebath";
import Breadcrumbs from "../../breadcrumbs";
import axios from "axios";
import { API_URL } from "../../../constants/AppConstants";
import { useDispatch, useSelector } from "react-redux";
import { warehouseData, alertData } from "../../../core/redux/action";
import stateCityListArr from "../../../data/StateCityList.json";
import { fetchWarehousesData } from "../../../utils/utilities";

function AddWarehouseModal(props) {
  const login_data = useSelector((state) => state.login_data);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const toggleFilterVisibility = () => {
    setIsFilterVisible((prevVisibility) => !prevVisibility);
  };

  const [warehouseName, setWarehouseName] = useState("");
  const [contactPerson, setContactPerson] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [workphone, setWorkphone] = useState("");
  const [email, setEmail] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [country, setCountry] = useState({
    label: "Choose",
    value: null,
  });
  const [state, setState] = useState({
    label: "Choose",
    value: null,
  });
  const [city, setCity] = useState({
    label: "Choose",
    value: null,
  });
  const [zipcode, setZipcode] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const selected_store = useSelector((state) => state.selected_store);
  const warehouse_data = useSelector((state) => state.warehouse_data);
  const dispatch = useDispatch();

  const options = [
    { value: "sortByDate", label: "Sort by Date" },
    // { value: "140923", label: "14 09 23" },
    // { value: "110923", label: "11 09 23" },
  ];
  const optionsTwo = [{ label: "Choose Store Name", value: "" }];

  const countries = [{ label: "Choose Country", value: "" }];

  const options1 = [{ value: "choose", label: "Choose" }];

  const options2 = [{ value: "choose", label: "Choose" }];

  useEffect(() => {
    fetchWarehousesData(login_data, selected_store[0]);
  }, [selected_store]);

  useEffect(() => {
    if (Object.keys(stateCityListArr)?.length) {
      let stateArr = [];
      Object.keys(stateCityListArr).forEach((state) => {
        stateArr.push({
          label: state,
          value: state,
        });
      });
      setStateList(stateArr);
    }
  }, []);

  useEffect(() => {
    if (state?.value) {
      setCity({
        label: "Choose",
        value: null,
      });
      if (Object.keys(stateCityListArr)?.length) {
        let cityArr = [];
        Object.entries(stateCityListArr).forEach((states, index) => {
          if (states[0] == state?.value) {
            states[1]?.length &&
              states[1]?.forEach((city) => {
                cityArr.push({
                  label: city,
                  value: city,
                });
              });
          }
        });
        setCityList(cityArr);
      }
    }
  }, [state]);

  function clearData() {
    setWarehouseName("");
    setWorkphone("");
    setAddress1("");
    setAddress2("");
    setState({
      label: "Choose",
      value: null,
    });
    setCity({
      label: "Choose",
      value: null,
    });
    setZipcode("");
  }

  async function submitData() {
    setIsLoading(true);

    if (
      !warehouseName ||
      !workphone ||
      !address1 ||
      !state ||
      !city ||
      !zipcode
    ) {
      dispatch(
        alertData({
          type: "error",
          msg: "Please fill all the fields.",
        })
      );
      setIsLoading(false);
      return;
    }

    if (zipcode.length != 6) {
      dispatch(
        alertData({
          type: "error",
          msg: "Please fill valid zipcode.",
        })
      );
      setIsLoading(false);
      return;
    }

    if (workphone.length != 10) {
      dispatch(
        alertData({
          type: "error",
          msg: "Please fill valid workphone.",
        })
      );
      setIsLoading(false);
      return;
    }

    let dataBody = {
      name: warehouseName,
      workphone,
      address1,
      address2,
      state,
      city,
      zipcode,
      storeId: selected_store[0]?._id,
      warehouseStatus: "Active",
      createdOn: new Date().toLocaleDateString().split(",")[0],
      userCode: login_data?.empCode,
      itemActive: true,
    };

    try {
      let response = await axios.post(API_URL + "warehouses", dataBody);
      if (response.status === 200) {
        fetchWarehousesData(login_data, selected_store[0]);
        dispatch(
          alertData({
            type: "success",
            msg: "Warehouse added successfully!",
          })
        );
        setTimeout(() => {
          clearData();
          setIsLoading(false);
          document?.querySelector("#add-warehouse .close")?.click();
        }, 1000);
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  }

  return (
    <div className="modal fade" id="add-warehouse">
      <div className="modal-dialog modal-dialog-centered custom-modal-two">
        <div className="modal-content">
          <div className="page-wrapper-new p-0">
            <div className="content">
              <div className="modal-header border-0 custom-modal-header">
                <div className="page-title">
                  <h4>Add Warehouse</h4>
                </div>
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  data-bs-toggle={props.from ? "modal" : ""}
                  data-bs-target={props.from ? `${props.target}` : ""}
                  onClick={clearData}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body custom-modal-body">
                <form>
                  {/* <div className="modal-title-head">
                        <h6>
                          <span>
                            <i data-feather="info" className="feather-edit" />
                          </span>
                          Warehouse Info
                        </h6>
                      </div> */}
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="mb-3">
                        <label className="form-label">
                          Name<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          value={warehouseName}
                          onChange={(e) => setWarehouseName(e.target.value)}
                          className="form-control"
                        />
                      </div>
                    </div>
                    {/* <div className="col-lg-6">
                          <div className="input-blocks">
                            <label>Contact Person</label>
                            <Select className="select" onChange={(e) => setContactPerson(e.target.value)} options={options1} />
                          </div>
                        </div> */}
                    {/* <div className="col-lg-6">
                          <div className="mb-3 war-add">
                            <label className="mb-2">Phone Number</label>
                            <input
                              onChange={(e) => setPhoneNumber(e.target.value)}
                              className="form-control"
                              id="phone"
                              name="phone"
                              type="text"
                            />
                          </div>
                        </div> */}
                    <div className="col-lg-6">
                      <div className="mb-3">
                        <label className="form-label">
                          Work Phone<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          value={workphone}
                          onChange={(e) => {
                            const newValue = e.target.value.replace(
                              /[^0-9]/g,
                              ""
                            );
                            if (newValue.length <= 10) {
                              setWorkphone(newValue);
                            }
                          }}
                          className="form-control"
                          placeholder="Enter 10-digit Phone Number"
                        />
                      </div>
                    </div>
                    {/* <div className="col-lg-12">
                          <div className="mb-3">
                            <label className="form-label">Email</label>
                            <input
                              type="email"
                              className="form-control"
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </div>
                        </div> */}
                    <div className="modal-title-head">
                      <h6>
                        <span>
                          <i data-feather="map-pin" />
                        </span>
                        Location
                      </h6>
                    </div>
                    <div className="col-lg-12">
                      <div className="mb-3">
                        <label className="form-label">
                          Address 1<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={address1}
                          onChange={(e) => setAddress1(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="mb-3">
                        <label className="form-label">Address 2</label>
                        <input
                          type="text"
                          className="form-control"
                          value={address2}
                          onChange={(e) => setAddress2(e.target.value)}
                        />
                      </div>
                    </div>
                    {/* <div className="col-lg-6">
                          <div className="input-blocks">
                            <label>Country</label>
                            <Select
                              className="select"
                              options={options2}
                              onChange={(e) => setCountry(e.target.value)}
                            />
                          </div>
                        </div> */}
                    <div className="col-lg-6">
                      <div className="mb-3">
                        <label className="form-label">
                          State<span style={{ color: "red" }}>*</span>
                        </label>
                        <Select
                          value={
                            state?.value
                              ? state
                              : [
                                  {
                                    label: "Choose",
                                    value: null,
                                  },
                                ]
                          }
                          onChange={(item) => setState(item)}
                          className="select"
                          options={stateList}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="mb-3 mb-0">
                        <label className="form-label">
                          City<span style={{ color: "red" }}>*</span>
                        </label>
                        <Select
                          value={
                            city?.value
                              ? city
                              : [
                                  {
                                    label: "Choose",
                                    value: null,
                                  },
                                ]
                          }
                          className="select"
                          options={cityList}
                          onChange={(item) => setCity(item)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="mb-3 mb-0">
                        <label className="form-label">
                          Zipcode<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={zipcode}
                          onChange={(e) => {
                            const newValue = e.target.value.replace(
                              /[^0-9]/g,
                              ""
                            );
                            if (newValue.length <= 6) {
                              setZipcode(newValue);
                            }
                          }}
                          placeholder="Enter 6-digit Zipcode"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer-btn">
                    <button
                      type="button"
                      className="btn btn-cancel me-2"
                      data-bs-dismiss="modal"
                      data-bs-toggle={props.from ? "modal" : ""}
                      data-bs-target={props.from ? `${props.target}` : ""}
                      onClick={clearData}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className={`btn btn-submit ${
                        isLoading ? "disabled" : ""
                      }`}
                      onClick={submitData}
                    >
                      Create Warehouse
                    </button>
                  </div>
                </form>
                <span style={{ fontStyle: "italic", fontSize: 12 }}>
                  All fields with<span style={{ color: "red" }}>*</span> are
                  required
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddWarehouseModal;
