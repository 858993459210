import initialState from "./initial.value";

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    // case "Product_list":
    //   return { ...state, product_list: action.payload };
    // case "Dashbaord_RecentProduct":
    //   return { ...state, dashboard_recentproduct: action.payload };
    // case "Dashbaord_ExpiredProduct":
    //   return { ...state, dashboard_expiredproduct: action.payload };
    // case "Salesdashbaord_ExpiredProduct":
    //   return { ...state, saleshdashboard_recenttransaction: action.payload };
    // case "Brand_list":
    //   return { ...state, brand_list: action.payload };

    // case "Unit_Data":
    //   return { ...state, unit_data: action.payload };
    // case "Variantattribute_Data":
    //   return { ...state, variantattributes_data: action.payload };
    // case "Warranty_Data":
    //   return { ...state, warranty_data: action.payload };
    // case "Barcode_Data":
    //   return { ...state, barcode_data: action.payload };
    // case "Department_Data":
    //   return { ...state, departmentlist_data: action.payload };
    // case "Designation_Data":
    //   return { ...state, designation_data: action.payload };
    // case "Shiftlist_Data":
    //   return { ...state, shiftlist_data: action.payload };
    // case "Attendenceemployee_Data":
    //   return { ...state, attendenceemployee_data: action.payload };
    // case "Invoicereport_Data":
    //   return { ...state, invoicereport_data: action.payload };
    // case "Salesreturns_Data":
    //   return { ...state, salesreturns_data: action.payload };
    // case "Quatation_Data":
    //   return { ...state, quotationlist_data: action.payload };
    // case "customer_data":
    //   return { ...state, customerdata: action.payload };
    // case "Userlist_data":
    //   return { ...state, userlist_data: action.payload };
    // case "Rolesandpermission_data":
    //   return { ...state, rolesandpermission_data: action.payload };
    // case "Deleteaccount_data":
    //   return { ...state, deleteaccount_data: action.payload };
    // case "Attendenceadmin_data":
    //   return { ...state, attendanceadmin_data: action.payload };
    // case "Leavesadmin_data":
    //   return { ...state, leavesadmin_data: action.payload };
    // case "Leavestype_data":
    //   return { ...state, leavetypes_data: action.payload };
    // case "Holiday_data":
    //   return { ...state, holiday_data: action.payload };
    // case "Expiredproduct_data":
    //   return { ...state, expiredproduct_data: action.payload };
    // case "Lowstock_data":
    //   return { ...state, lowstock_data: action.payload };
    // case "Categotylist_data":
    //   return { ...state, categotylist_data: action.payload };
    case "Layoutstyle_data":
      return { ...state, layoutstyledata: action.payload };

    case "toggle_header":
      return { ...state, toggle_header: action.payload };
    case "Login_User_Data":
      return { ...state, login_data: action.payload };
    case "Update_Page":
      return { ...state, update_page: action.payload };
    case "selected_store":
      return { ...state, selected_store: action.payload };
    case "Stores_Data":
      return { ...state, stores_data: action.payload };
    case "Brand_Data":
      return { ...state, brand_data: action.payload };
    case "Category_Data":
      return { ...state, category_data: action.payload };
    case "Sub_Category_Data":
      return { ...state, subcategory_data: action.payload };
    case "Warehouse_Data":
      return { ...state, warehouse_data: action.payload };
    case "Supplier_Data":
      return { ...state, supplier_data: action.payload };
    case "Customer_Data":
      return { ...state, customer_data: action.payload };
    case "Products_Data":
      return { ...state, products_data: action.payload };
    case "Sales_Data":
      return { ...state, sales_data: action.payload };
    case "Sales_Data_Length":
      return { ...state, sales_data_length: action.payload };
    case "Sales_Return_Data":
      return { ...state, sales_return_data: action.payload };
    case "Sale_Quotation_Data":
      return { ...state, sale_quotation_data: action.payload };
    case "Sale_Quotation_Data_Length":
      return { ...state, sale_quotation_data_length: action.payload };
    case "Sale_Booking_Data":
      return { ...state, sale_booking_data: action.payload };
    case "Sale_Booking_Data_Length":
      return { ...state, sale_booking_data_length: action.payload };
    case "Purchase_Quotation_Data":
      return { ...state, purchase_quotation_data: action.payload };
    case "Purchase_Quotation_Data_Length":
      return { ...state, purchase_quotation_data_length: action.payload };
    case "Purchase_Booking_Data":
      return { ...state, purchase_booking_data: action.payload };
    case "Purchase_Booking_Data_Length":
      return { ...state, purchase_booking_data_length: action.payload };
    case "Purchase_Data":
      return { ...state, purchase_data: action.payload };
    case "Purchase_Data_Length":
      return { ...state, purchase_data_length: action.payload };
    case "Accounts_Data":
      return { ...state, accounts_data: action.payload };
    case "Accounts_Data_Length":
      return { ...state, accounts_data_length: action.payload };
    case "Receipt_Data":
      return { ...state, receipt_data: action.payload };
    case "Receipt_Data_Length":
      return { ...state, receipt_data_length: action.payload };
    case "Payment_Data":
      return { ...state, payment_data: action.payload };
    case "Payment_Data_Length":
      return { ...state, payment_data_length: action.payload };
    case "Bank_Data":
      return { ...state, bank_data: action.payload };
    case "Bank_Data_Length":
      return { ...state, bank_data_length: action.payload };
    case "Purchase_Return_Data":
      return { ...state, purchase_return_data: action.payload };
    case "Transportation_Data":
      return { ...state, transportation_data: action.payload };
    case "Stocks_Data":
      return { ...state, stocks_data: action.payload };
    case "Joyride_Data":
      return { ...state, joyride_data: action.payload };
    case "Expense_Category_Data":
      return { ...state, expense_category_data: action.payload };
    case "Expense_Data":
      return { ...state, expense_data: action.payload };
    case "Employees_Data":
      return { ...state, employees_data: action.payload };
    case "Employees_Data_Length":
      return { ...state, employees_data_length: action.payload };
    case "Department_Data":
      return { ...state, department_data: action.payload };
    case "Designation_Data":
      return { ...state, designation_data: action.payload };
    case "Roles_Data":
      return { ...state, roles_data: action.payload };
    case "Users_Data":
      return { ...state, users_data: action.payload };
    case "Alert_Data":
      return { ...state, alert_data: action.payload };
    case "Detail_Data":
      return { ...state, detail_data: action.payload };
    case "Reset_Data":
      return initialState;
    case "Loader_Data":
      return { ...state, loader_data: action.payload };
    default:
      return state;
  }
};

export default rootReducer;
