/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from "react";

function ViewPurchase({
  type,
  viewPurchaseDetailData = {},
  setViewPurchaseDetailData = () => {},
  setSelectedPurchaseDetailData = () => {},
}) {
  return (
    <div className="modal fade" id="purchase-details-new">
      <div className="modal-dialog purchase-details-modal">
        <div className="modal-content">
          <div className="page-wrapper details-blk">
            <div className="content p-0">
              <div className="modal-header border-0 custom-modal-header">
                <div className="page-title">
                  <h4>
                    Purchase Detail: #{viewPurchaseDetailData?.invoiceNumber}
                  </h4>
                </div>
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setViewPurchaseDetailData({});
                    setSelectedPurchaseDetailData("");
                  }}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="card">
                <div className="card-body">
                  <form>
                    <div className="flex">
                      <div className="inline-flex mr-50">
                        <h5 className="order-text">Supplier: </h5>
                        <h5 className="order-subtext ml-10">
                          {viewPurchaseDetailData?.supplier?.label}
                        </h5>
                      </div>
                      <div className="inline-flex">
                        <h5 className="order-text">Purchase Date: </h5>
                        <h5 className="order-subtext ml-10">
                          {new Date(
                            viewPurchaseDetailData?.date
                          ).toDateString()}
                        </h5>
                      </div>
                    </div>
                    <div
                      className="reference-box table-height"
                      style={{
                        maxWidth: 1600,
                        width: "100%",
                        overflow: "auto",
                        padding: 0,
                        fontSize: 14,
                        lineHeight: 24,
                        color: "#555",
                      }}
                    >
                      <h5 className="order-text">Order Summary</h5>
                      <div className="table-responsive no-pagination">
                        <table className="table  datanew">
                          <thead>
                            <tr>
                              <th>Product</th>
                              <th>Unit</th>
                              <th>Qty</th>
                              <th>Alt. Qty</th>
                              <th>Price(₹)</th>
                              <th>Discount</th>
                              <th>Tax(%)</th>
                              <th>Tax Amount(₹)</th>
                              <th>Unit Cost(₹)</th>
                              <th>Total Cost(₹)</th>
                            </tr>
                          </thead>
                          <tbody>
                            {viewPurchaseDetailData?.productsList &&
                              viewPurchaseDetailData?.productsList?.map(
                                (item, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>{item?.productName}</td>
                                      <td>{item?.selectedUnit?.label}</td>
                                      <td>{item?.selectedQty}</td>
                                      <td>{item?.productAlternateQty}</td>
                                      <td>{item?.purchasePrice}</td>
                                      <td>
                                        {(item?.productDiscountValue
                                          ? item?.productDiscountValue
                                          : 0) +
                                          (item?.productDiscountType?.label ==
                                          "Cash"
                                            ? "₹"
                                            : "%")}
                                      </td>
                                      <td>{item?.productTaxValue}</td>
                                      <td>{item?.productCurrentTax}</td>
                                      <td>{item?.productUnitCost}</td>
                                      <td>{item?.totalCost}</td>
                                    </tr>
                                  );
                                }
                              )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="row justify-content-end mb-30">
                      <div className="row justify-content-end">
                        <div className="col-lg-6">
                          <div className="total-order w-100 max-widthauto m-auto mb-4">
                            <ul>
                              <li>
                                <h4>Total Tax(₹)</h4>
                                <h5>
                                  {viewPurchaseDetailData?.orderTax
                                    ? viewPurchaseDetailData?.orderTax
                                    : 0}
                                </h5>
                              </li>
                              <li>
                                <h4>Discount(₹)</h4>
                                <h5>
                                  {viewPurchaseDetailData?.discount
                                    ? viewPurchaseDetailData?.discount
                                    : 0}
                                </h5>
                              </li>
                              <li>
                                <h4>Shipping(₹)</h4>
                                <h5>
                                  {viewPurchaseDetailData?.shipping
                                    ? viewPurchaseDetailData?.shipping
                                    : 0}
                                </h5>
                              </li>
                              <li>
                                <h4>Grand Total(₹)</h4>
                                <h5>{viewPurchaseDetailData?.grandTotal}</h5>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex">
                      <div className="inline-flex mr-50">
                        <h5 className="order-text">Due Amount(₹):</h5>
                        <h5 className="order-subtext ml-10">
                          {viewPurchaseDetailData?.dueAmount}
                        </h5>
                      </div>
                      <div className="inline-flex mr-50">
                        <h5 className="order-text">Due Date:</h5>
                        <h5 className="order-subtext ml-10">
                          {viewPurchaseDetailData?.dueDate
                            ? new Date(
                                viewPurchaseDetailData?.dueDate
                              ).toDateString()
                            : ""}
                        </h5>
                      </div>
                      <div className="inline-flex">
                        <h5 className="order-text">Paid Amount(₹):</h5>
                        <h5 className="order-subtext ml-10">
                          {Number(viewPurchaseDetailData?.paidAmount).toFixed(
                            2
                          )}
                        </h5>
                      </div>
                    </div>

                    <div className="flex">
                      <div className="inline-flex mr-50">
                        <h5 className="order-text">
                          {type == "purchase" ? "Purchase" : "Booking"} Status:
                        </h5>
                        <h5 className="order-subtext ml-10">
                          {type == "purchase"
                            ? viewPurchaseDetailData?.purchaseStatus?.label
                            : viewPurchaseDetailData?.bookingStatus?.label}
                        </h5>
                      </div>
                      <div className="inline-flex mr-50">
                        <h5 className="order-text">Payment Status:</h5>
                        <h5 className="order-subtext ml-10">
                          {viewPurchaseDetailData?.paymentStatus?.value
                            ? viewPurchaseDetailData?.paymentStatus?.label
                            : ""}
                        </h5>
                      </div>
                      <div className="inline-flex mr-50">
                        <h5 className="order-text">Payment Method:</h5>
                        <h5 className="order-subtext ml-10">
                          {viewPurchaseDetailData?.paymentMethod?.value
                            ? viewPurchaseDetailData?.paymentMethod?.label
                            : ""}
                        </h5>
                      </div>
                      {type == "purchase" ? (
                        <div className="inline-flex">
                          <h5 className="order-text">Transportation Added:</h5>
                          <h5 className="order-subtext ml-10">
                            {viewPurchaseDetailData?.transportDetail
                              ? "Yes"
                              : "No"}
                          </h5>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewPurchase;
