/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Filter } from "react-feather";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../core/breadcrumbs";
import { useSelector } from "react-redux";
import axios from "axios";
import { API_URL } from "../../constants/AppConstants";

const TaxReport = () => {
  const login_data = useSelector((state) => state.login_data);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [selectedTab, setSelectedTab] = useState(false);

  const [taxesData, setTaxesData] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredTax, setFilteredTax] = useState(taxesData);

  const fetchPurchaseTax = async (tab) => {
    try {
      const res = await axios.get(API_URL + "tax");
      console.log(res.data);
      if (res.status === 200) {
        const filteredData = res.data.filter((item) => item.type === tab);
        setTaxesData(filteredData);
        setFilteredTax(filteredData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (login_data) {
      fetchPurchaseTax("Purchase");
    }
  }, [login_data]);

  useEffect(() => {
    if (selectedTab === "Sale") {
      fetchPurchaseTax("Sale");
    } else {
      fetchPurchaseTax("Purchase");
    }
  }, [selectedTab]);

  useEffect(() => {
    if (searchTerm) {
      const arr = taxesData.filter(
        (item) =>
          item.primePerson.label
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          item.refNo.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.paymentMethod.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredTax(arr);
    } else {
      // Reset filteredTax if searchTerm is empty
      setFilteredTax(taxesData);
    }
    console.log(taxesData);
  }, [searchTerm, taxesData]);

  return (
    <div className="page-wrapper">
      <div className="content">
        <Breadcrumbs maintitle="Tax Report" subtitle="Manage Your Tax Report" />
        {/* /product list */}
        <div className="card table-list-card">
          <div className="card-body">
            <div className="tabs-set">
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="purchase-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#purchase2"
                    type="button"
                    role="tab"
                    aria-controls="purchase2"
                    aria-selected="true"
                    onClick={() => setSelectedTab("Purchase")}
                  >
                    Purchase Tax Report
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="sales-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#sales2"
                    type="button"
                    role="tab"
                    aria-controls="sales2"
                    aria-selected="false"
                    onClick={() => setSelectedTab("Sale")}
                  >
                    Sales Tax Report
                  </button>
                </li>
              </ul>
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="purchase2"
                  role="tabpanel"
                  aria-labelledby="purchase-tab"
                >
                  <div className="table-top">
                    <div className="search-set">
                      <div className="search-input">
                        <input
                          value={searchTerm}
                          type="text"
                          placeholder="Search"
                          className="form-control form-control-sm formsearch"
                          onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        <Link to className="btn btn-searchset">
                          <i data-feather="search" className="feather-search" />
                        </Link>
                      </div>
                    </div>
                    {/* <div className="search-path">
                      <Link
                        className={`btn btn-filter ${isFilterVisible ? "setclose" : ""
                          }`}
                        id="filter_search"
                      >
                        <Filter
                          className="filter-icon"
                          onClick={() => setIsFilterVisible(true)}
                        />
                      </Link>
                    </div> */}
                  </div>
                  <div className="table-responsive">
                    <table className="table datanew">
                      <thead>
                        <tr>
                          <th>Supplier</th>
                          <th>Product</th>
                          <th>Date</th>
                          <th>Ref No</th>
                          <th>Quantity</th>
                          <th>Total Amount</th>
                          <th>Payment Method</th>
                          <th>Tax Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredTax ? (
                          filteredTax.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td>{item.primePerson.label}</td>
                                <td>
                                  {item.productName
                                    ? item.productName.replace(
                                        /\b\w/g,
                                        (match) => match.toUpperCase()
                                      )
                                    : ""}
                                </td>
                                <td>{item.date.split("T")[0]}</td>
                                <td className="ref-number">
                                  {filteredTax[index - 1] &&
                                  filteredTax[index - 1].refNo === item.refNo
                                    ? " "
                                    : `#${item.refNo}`}
                                </td>
                                <td>{item.qty}</td>
                                <td>₹ {item.grandTotal.toFixed(2)}</td>
                                <td className="payment-info">
                                  {item.paymentMethod?.label}
                                </td>
                                <td>₹ {item.taxAmount.toFixed(2)}</td>
                              </tr>
                            );
                          })
                        ) : (
                          <></>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="sales2"
                  role="tabpanel"
                  aria-labelledby="sales-tab"
                >
                  <div className="table-top">
                    <div className="search-set">
                      <div className="search-input">
                        <input
                          value={searchTerm}
                          type="text"
                          placeholder="Search"
                          className="form-control form-control-sm formsearch"
                          onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        <Link to className="btn btn-searchset">
                          <i data-feather="search" className="feather-search" />
                        </Link>
                      </div>
                    </div>
                    {/* <div className="search-path">
                      <Link
                        className={`btn btn-filter ${
                          isFilterVisible ? "setclose" : ""
                        }`}
                        id="filter_search"
                      >
                        <Filter className="filter-icon" />
                      </Link>
                    </div> */}
                    {/* <div className="form-sort stylewidth">
                      <Sliders className="info-img" />

                      <Select
                        className="select"
                        placeholder="Sort by Date"
                      />
                    </div> */}
                  </div>
                  <div className="table-responsive">
                    <table className="table datanew">
                      <thead>
                        <tr>
                          <th>Customer</th>
                          <th>Product</th>
                          <th>Date</th>
                          <th>Ref No</th>
                          <th>Quantity</th>
                          <th>Total Amount</th>
                          <th>Payment Method</th>
                          <th>Tax Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredTax ? (
                          filteredTax.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td>{item.primePerson.label}</td>
                                <td>
                                  {item.productName
                                    ? item.productName.replace(
                                        /\b\w/g,
                                        (match) => match.toUpperCase()
                                      )
                                    : ""}
                                </td>
                                <td>{item.date.split("T")[0]}</td>
                                <td className="ref-number">
                                  {filteredTax[index - 1] &&
                                  filteredTax[index - 1].refNo === item.refNo
                                    ? " "
                                    : `#${item.refNo}`}
                                </td>
                                <td>{item.qty}</td>
                                <td>₹ {item.grandTotal.toFixed(2)}</td>
                                <td className="payment-info">
                                  {item.paymentMethod?.label}
                                </td>
                                <td>₹ {item.taxAmount.toFixed(2)}</td>
                              </tr>
                            );
                          })
                        ) : (
                          <></>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /product list */}
      </div>
    </div>
  );
};

export default TaxReport;
