/* eslint-disable no-constant-condition */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import {
  ChevronUp,
  FileText,
  PlusCircle,
  RotateCcw,
  Sliders,
  StopCircle,
  User,
} from "feather-icons-react/build/IconComponents";
import {
  alertData,
  loaderData,
  setToogleHeader,
} from "../../core/redux/action";
import { useDispatch, useSelector } from "react-redux";
import { Filter } from "react-feather";
import Select from "react-select";
import { DatePicker } from "antd";
import axios from "axios";
import { API_URL } from "../../constants/AppConstants";
import { salesReturnData, updatePage } from "../../core/redux/action";
import { Modal } from "antd";
import PrintPreview from "../../common/PrintPreview";
import PrintLayout from "../../common/PrintLayout";
import CSS from "../../core/modals/PreviewModal.module.scss";
import CustomerModal from "../../core/modals/peoples/customerModal";
import SupplierModal from "../../core/modals/peoples/supplierModal";
import {
  fetchProductsData,
  fetchSalesData,
  fetchSalesReturnData,
  fetchStocksData,
  fetchSuppliersData,
} from "../../utils/utilities";
import TransportModal from "../stock/transportmodal";

const SalesReturnReturnList = () => {
  const dispatch = useDispatch();
  const login_data = useSelector((state) => state.login_data);
  const data = useSelector((state) => state.toggle_header);
  const selected_store = useSelector((state) => state.selected_store);
  const sales_data = useSelector((state) => state.sales_data);
  const supplier_data = useSelector((state) => state.supplier_data);
  const products_data = useSelector((state) => state.products_data);
  const salesReturn_data = useSelector((state) => state.sales_return_data);
  const stocks_data = useSelector((state) => state.stocks_data);

  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedSales, setSelectedSales] = useState(null);
  const [selectedSalesData, setSelectedSalesData] = useState({});

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [salesReturnInvoiceNumber, setSalesReturnInvoiceNumber] = useState("");
  const [selectedSupplier, setSelectedSupplier] = useState({});

  const [productInput, setProductInput] = useState("");
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [salesReturnAmount, setSalesReturnAmount] = useState("");

  const [selectedUnits, setSelectedUnits] = useState([
    {
      label: "Choose",
      value: null,
    },
  ]);
  const [selectedQty, setSelectedQty] = useState([0]);
  const [totalCostAll, setTotalCostAll] = useState([0]);
  const [showProductList, setShowProductList] = useState(false);
  const [filteredList, setFilteredProductList] = useState(products_data);
  const [itemReturnStatus, setItemReturnStatus] = useState([
    {
      label: "Choose",
      value: null,
    },
  ]);

  const [orderTaxValue, setOrderTaxValue] = useState(0);
  const [discountType, setDiscountType] = useState([
    {
      label: "Choose",
      value: null,
    },
  ]);
  const [discountValue, setDiscountValue] = useState("0");
  const [shippingValue, setShippingValue] = useState("0");
  const [salesReturnStatus, setSalesReturnStatus] = useState("");
  const [dueAmount, setDueAmount] = useState("");
  const [dueDate, setDueDate] = useState(new Date());
  const [paidAmount, setPaidAmount] = useState("");
  const [paymentStatus, setPaymentStatus] = useState({
    label: "Pending",
    value: "Pending",
  });
  const [paymentMethod, setPaymentMethod] = useState({
    label: "Choose",
    value: null,
  });

  const [grandTotal, setGrandTotal] = useState("");

  const [showPdfModal, setShowPdfModal] = useState(false);
  const [pdfData, setPdfData] = useState({});

  const [productStocksList, setProductStocksList] = useState([]);
  const [purchasePriceList, setPurchasePriceList] = useState([]);
  const [filteredSales, setFilteredSales] = useState(sales_data);
  const [filteredSalesReturn, setFilteredSalesReturn] = useState(sales_data);
  const [showTransportModal, setShowTransportModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  let dropdownRef = useRef(null);

  let grandTotalValue = useRef(0);

  const toggleFilterVisibility = () => {
    setIsFilterVisible((prevVisibility) => !prevVisibility);
  };
  const oldandlatestvalue = [
    { value: "Sort by Date", label: "Sort by Date" },
    // { value: '07 09 23', label: '07 09 23' },
    // { value: '21 09 23', label: '21 09 23' },
  ];
  const customername = [
    { value: null, label: "Choose Customer Name" },
    // { value: 'Macbook pro', label: 'Macbook pro' },
    // { value: 'Orange', label: 'Orange' },
  ];
  const status = [
    { value: null, label: "Choose Status" },
    // { value: 'Computers', label: 'Computers' },
    // { value: 'Fruits', label: 'Fruits' },
  ];
  const paymentstatus = [
    { value: null, label: "Choose Payment Status" },
    // { value: 'Computers', label: 'Computers' },
    // { value: 'Fruits', label: 'Fruits' },
  ];
  const customer = [
    { value: null, label: "Choose Customer" },
    // { value: 'Customer Name', label: 'Customer Name' },
  ];
  const suppliername = [
    { value: "Supplier", label: "Supplier" },
    // { value: 'Supplier Name', label: 'Supplier Name' },
  ];
  const statusupdate = [
    { value: "Completed", label: "Completed" },
    { value: "InProgress", label: "InProgress" },
  ];
  const paymenttype = [
    { value: "Cash", label: "Cash" },
    { value: "Card", label: "Card" },
    { value: "Online", label: "Online" },
  ];

  useEffect(() => {
    if (sales_data && salesReturn_data) {
      const filterDuplicate = sales_data.filter((item) => {
        const arr = salesReturn_data.some(
          (returnItem) => item.invoiceNumber !== returnItem?.salesNumber?.label
        );
        return arr;
      });
      if (filterDuplicate.length > 0) {
        setFilteredSales(filterDuplicate);
      } else {
        setFilteredSales(sales_data);
      }
    }
  }, [sales_data, salesReturn_data]);

  useEffect(() => {
    document.addEventListener("click", (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setShowProductList(false);
      }
    });
  }, []);

  useEffect(() => {
    fetchSalesReturnData(login_data, selected_store[0]);
    fetchSalesData(login_data, selected_store[0]);
    fetchStocksData(login_data, selected_store[0]);
  }, [selected_store]);

  useEffect(() => {
    setFilteredSalesReturn(sales_data);
  }, [sales_data]);

  useEffect(() => {
    if (selectedSales) {
      const details = sales_data.find(
        (item) =>
          item.salesStatus.value === "Completed" &&
          item._id === selectedSales.value
      );

      if (details) {
        setSelectedSalesData(details);
        setSelectedSupplier(details.supplier);
      }
    }
  }, [selectedSales]);

  useEffect(() => {
    let filteredArr = [];
    let newFilteredList = [...products_data];
    if (productInput?.length) {
      setShowProductList(true);
      filteredArr = newFilteredList?.filter((product) => {
        return product.productName
          .toLowerCase()
          .includes(productInput.toLowerCase());
      });
      setFilteredProductList(filteredArr);
    } else {
      setFilteredProductList(products_data);
    }
  }, [productInput]);

  useEffect(() => {
    let selectedUnitsArr = [...selectedUnits];
    let selectedQtyArr = [...selectedQty];
    let productStocksArr = [...productStocksList];

    if (selectedProducts) {
      for (let i = 0; i < selectedProducts.length; i++) {
        selectedUnitsArr.push({
          label: "Choose",
          value: null,
        });

        stocks_data?.forEach((item) => {
          if (item.productId == selectedProducts[i]._id) {
            productStocksArr[i] = {
              productQty: Number(item.productQty),
              productUnit: item.productUnit.label,
              productAlternateQty: Number(item.productAlternateQty),
              productClubbedUnit: item.productClubbedUnit.label,
            };
          }
        });
      }
    }
    setSelectedUnits(selectedUnits);
    setProductStocksList(productStocksArr);
  }, [selectedProducts]);

  useEffect(() => {
    if (selectedProducts.length) {
      grandTotalValue.current = 0;
      for (let i = 0; i < selectedProducts.length; i++) {
        grandTotalValue.current =
          Number(grandTotalValue.current) +
          Number(
            selectedProducts[i].totalCost ? selectedProducts[i].totalCost : 0
          );
      }

      if (discountValue) {
        if (discountType?.label == "Percent") {
          grandTotalValue.current =
            Number(grandTotalValue.current) -
            (Number(grandTotalValue.current) * Number(discountValue)) / 100;
          grandTotalValue.current =
            Number(grandTotalValue.current) +
            (Number(grandTotalValue.current) * Number(orderTaxValue)) / 100;
          grandTotalValue.current =
            Number(grandTotalValue.current) + Number(shippingValue);
        } else if (discountType?.label == "Cash") {
          grandTotalValue.current =
            Number(grandTotalValue.current) - Number(discountValue);
          grandTotalValue.current =
            Number(grandTotalValue.current) +
            (Number(grandTotalValue.current) * Number(orderTaxValue)) / 100;
          grandTotalValue.current =
            Number(grandTotalValue.current) + Number(shippingValue);
        }
      } else {
        grandTotalValue.current =
          Number(grandTotalValue.current) +
          (Number(grandTotalValue.current) * Number(orderTaxValue)) / 100;
        grandTotalValue.current =
          Number(grandTotalValue.current) + Number(shippingValue);
      }

      setGrandTotal(
        Number(grandTotalValue.current) ? Number(grandTotalValue.current) : 0
      );
    }
    setSelectedUnits(selectedUnits);
  }, [selectedProducts, orderTaxValue, discountValue, shippingValue]);

  useEffect(() => {
    let filteredArr = [...selectedProducts];
    for (let i = 0; i < filteredArr.length; i++) {
      let unitCost =
        Number(purchasePriceList[i] ? purchasePriceList[i] : 0) -
        (filteredArr[i].productDiscountType.label == "Cash"
          ? Number(filteredArr[i].productDiscountValue)
          : (Number(purchasePriceList[i] ? purchasePriceList[i] : 0) *
              Number(filteredArr[i].productDiscountValue)) /
            100) +
        ((Number(purchasePriceList[i] ? purchasePriceList[i] : 0) -
          (filteredArr[i].productDiscountType.label == "Cash"
            ? Number(filteredArr[i].productDiscountValue)
            : (Number(purchasePriceList[i] ? purchasePriceList[i] : 0) *
                Number(filteredArr[i].productDiscountValue)) /
              100)) *
          Number(filteredArr[i].productTaxValue)) /
          100;

      filteredArr[i] = {
        ...filteredArr[i],
        selectedUnit: selectedUnits[i],
        selectedQty: selectedQty[i],
        purchasePrice: purchasePriceList[i],
        productUnitCost: unitCost,
        totalCost: Number(selectedQty[i])
          ? selectedUnits[i]?.label != filteredArr[i]?.productUnit?.label
            ? Number(unitCost) *
              Number(
                selectedQty[i] * Number(filteredArr[i]?.productAlternateQty)
              )
            : Number(unitCost) * Number(selectedQty[i])
          : 0,
      };
    }
    setSelectedProducts(filteredArr);
  }, [selectedUnits, selectedQty, purchasePriceList]);

  useEffect(() => {
    if (searchTerm) {
      const filtered = salesReturn_data.filter(
        (item) =>
          item?.invoiceNumber
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          item?.customer?.label.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredSalesReturn(filtered);
    } else {
      setFilteredSalesReturn(salesReturn_data);
    }
  }, [searchTerm]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  function changeUnit(dataValue, dataId) {
    let newArr = [...selectedUnits];
    if (!dataValue.value) {
      changeQty(0, dataId);
    }
    newArr.splice(dataId, 1, dataValue);
    setSelectedUnits(newArr);
  }

  function changeQty(dataValue, dataId) {
    let newArr = [...selectedQty];
    newArr.splice(dataId, 1, dataValue);
    setSelectedQty(newArr);
  }

  const renderTooltip = (props) => (
    <Tooltip id="pdf-tooltip" {...props}>
      Pdf
    </Tooltip>
  );
  const renderExcelTooltip = (props) => (
    <Tooltip id="excel-tooltip" {...props}>
      Excel
    </Tooltip>
  );
  const renderPrinterTooltip = (props) => (
    <Tooltip id="printer-tooltip" {...props}>
      Printer
    </Tooltip>
  );
  const renderRefreshTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Refresh
    </Tooltip>
  );
  const renderCollapseTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Collapse
    </Tooltip>
  );

  function showTransport() {
    setIsLoading(true);
    if (!salesReturnInvoiceNumber || !selectedSales || !selectedProducts) {
      dispatch(
        alertData({
          type: "error",
          msg: "Please fill the required fields",
        })
      );
      setIsLoading(false);
      return;
    }

    const dismissModal = document.querySelector("#dismiss-modal");
    if (dismissModal) {
      dismissModal.click();
    }

    setShowTransportModal(true);
    setIsLoading(false);
  }

  async function submitData() {
    let dataBody = {
      salesNumber: selectedSales,
      storeId: selected_store[0]?._id,
      customer: selectedSalesData.customer,
      date: new Date(selectedDate).toLocaleDateString().split(",")[0],
      invoiceNumber: salesReturnInvoiceNumber,
      supplier: selectedSupplier,
      productsList: selectedSalesData.productsList,
      orderTax: selectedSalesData.orderTax,
      discount: discountValue,
      discountWithUnit:
        discountValue +
        (discountType.label == "Percent"
          ? "%"
          : discountType.label == "Cash"
          ? "₹"
          : ""),
      shipping: shippingValue,
      grandTotal: grandTotal,
      salesReturnStatus: salesReturnStatus,
      dueAmount,
      dueDate: new Date(dueDate).toLocaleDateString().split(",")[0],
      paidAmount,
      paymentStatus,
      paymentMethod,
      itemReturnStatus,
      salesReturnAmount,
      createdOn: new Date().toLocaleDateString().split(",")[0],
      userCode: login_data?.empCode,
      itemActive: true,
    };

    let stocksDataArr = [];

    if (selectedProducts.length) {
      for (let i = 0; i < selectedProducts.length; i++) {
        if (
          selectedProducts[i].selectedUnit.label ==
          selectedProducts[i].productClubbedUnit.label
        ) {
          stocksDataArr.push({
            productAlternateQty:
              Number(selectedProducts[i].selectedQty) +
              Number(productStocksList[i].productAlternateQty),
            productQty:
              Number(selectedProducts[i].productAlternateQty) *
              (Number(productStocksList[i].productAlternateQty) +
                Number(selectedProducts[i].selectedQty)),
          });
        } else {
          stocksDataArr.push({
            productQty:
              Number(productStocksList[i].productQty) +
              Number(selectedProducts[i].selectedQty),
            productAlternateQty:
              (Number(productStocksList[i].productQty) +
                Number(selectedProducts[i].selectedQty)) /
              Number(selectedProducts[i].productAlternateQty),
          });
        }
      }
    }

    try {
      let response = await axios.post(API_URL + "sales_return", dataBody);
      let responseArr = [];
      if (selectedProducts.length) {
        for (let i = 0; i < selectedProducts.length; i++) {
          response[i] = await axios.put(
            API_URL + "stocks/" + selectedProducts[i]._id,
            stocksDataArr[i]
          );
        }
      }
      let failed = 0;
      if (response.status === 200) {
        responseArr?.forEach((response) => {
          if (!response.status == 200) failed = 1;
        });
        if (!failed) {
          dispatch(
            alertData({
              type: "success",
              msg: "Sale Return added successfully!",
            })
          );
          setTimeout(() => {
            setIsLoading(false);
            document?.querySelector("#add-salesReturn-new .close")?.click();
          }, 1000);
          fetchSalesReturnData(login_data, selected_store[0]);
        }
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  }

  function selectProduct(product) {
    let productArr = [...selectedProducts];
    productArr.push(product);
    setSelectedProducts(productArr);

    let selectedUnitsArr = [...selectedUnits];
    let selectedQtyArr = [...selectedQty];
    let totalCostArr = [...totalCostAll];
    selectedUnitsArr.splice(productArr.length - 1, 0, {
      label: "Choose",
      value: null,
    });
    totalCostArr.splice(productArr.length - 1, 0, 0);
    selectedQtyArr.splice(productArr.length - 1, 0, "");
  }

  function deleteProduct(productIndex) {
    let productArr = [...selectedProducts];
    let newArr = productArr.filter((item, index) => index != productIndex);
    setSelectedProducts(newArr);
  }

  function clearData() {
    setProductInput("");
    setSalesReturnInvoiceNumber("");
    setOrderTaxValue("");
    setDiscountValue("");
    setShippingValue("0");
    setSelectedSupplier("");
    setSelectedSales("");
    setSelectedProducts([]);
    setDueAmount("");
    setDueDate(new Date());
    setSelectedDate(new Date());
    setSalesReturnStatus("");
    setPaidAmount("");
    setPaymentStatus({
      label: "Pending",
      value: "Pending",
    });
    setPaymentMethod({
      label: "Choose",
      value: null,
    });
  }

  async function deleteData(dataId) {
    dispatch(
      loaderData({
        type: "Loader_Data",
        payload: true,
      })
    );
    if (dataId) {
      try {
        let response = await axios.put(API_URL + "sales_return/" + dataId);
        if (response.status === 200) {
          fetchSalesReturnData(login_data, selected_store[0]);
          dispatch(
            loaderData({
              type: "Loader_Data",
              payload: false,
            })
          );
        }
      } catch (err) {
        console.log(err);
      }
    }
  }

  function changePurchasePrice(value, index) {
    let priceArr = [...purchasePriceList];
    priceArr[index] = Number(value);
    setPurchasePriceList(priceArr);
  }

  const getProductStockData = (product) => {
    const filtered = stocks_data.filter(
      (item) => item.productItemCode === product.productItemCode
    );
    return `${filtered[0].productCurrentQty} ${
      filtered[0].productUnit.label
    } | ${Number(filtered[0].productCurrentAlternateQty).toFixed(2)} ${
      filtered[0].productClubbedUnit.label
    }`;
  };

  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Sales Return List</h4>
                <h6>Manage Your Sales Return</h6>
              </div>
            </div>
            <ul className="table-top-head">
              {/* <li>
                <OverlayTrigger placement="top" overlay={renderTooltip}>
                  <Link>
                    <ImageWithBasePath
                      src="assets/img/icons/pdf.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderExcelTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top">
                    <ImageWithBasePath
                      src="assets/img/icons/excel.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderPrinterTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top">
                    <i data-feather="printer" className="feather-printer" />
                  </Link>
                </OverlayTrigger>
              </li> */}
              <li>
                <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top">
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderCollapseTooltip}>
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    id="collapse-header"
                    className={data ? "active" : ""}
                    onClick={() => {
                      dispatch(setToogleHeader(!data));
                    }}
                  >
                    <ChevronUp />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
            <div className="page-btn">
              <Link
                to="#"
                className="btn btn-added"
                data-bs-toggle="modal"
                data-bs-target="#add-salesReturn-new"
                onClick={clearData}
              >
                <PlusCircle className="me-2" />
                Add New Sales Return
              </Link>
            </div>
          </div>
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <input
                      type="text"
                      value={searchTerm}
                      onChange={handleSearchChange}
                      placeholder="Search by invoice or customer"
                      className="form-control form-control-sm formsearch"
                    />
                    <Link to className="btn btn-searchset">
                      <i data-feather="search" className="feather-search" />
                    </Link>
                  </div>
                </div>
                {/* <div className="search-path">
                  <div className="d-flex align-items-center">
                    <div className="search-path">
                      <Link
                        className={`btn btn-filter ${
                          isFilterVisible ? "setclose" : ""
                        }`}
                        id="filter_search"
                      >
                        <Filter
                          className="filter-icon"
                          onClick={toggleFilterVisibility}
                        />
                        <span onClick={toggleFilterVisibility}>
                          <ImageWithBasePath
                            src="assets/img/icons/closes.svg"
                            alt="img"
                          />
                        </span>
                      </Link>
                    </div>
                  </div>
                </div> */}
                {/* <div className="form-sort">
                  <Sliders className="info-img" />
                  <Select
                    className="select"
                    options={oldandlatestvalue}
                    placeholder="Newest"
                  />
                </div> */}
              </div>

              {/* /Filter */}
              <div
                className={`card${isFilterVisible ? " visible" : ""}`}
                id="filter_inputs"
                style={{ display: isFilterVisible ? "block" : "none" }}
              >
                <div className="card-body pb-0">
                  <div className="row">
                    <div className="col-lg-3 col-sm-6 col-12">
                      <div className="input-blocks">
                        <i data-feather="user" className="info-img" />
                        <User className="info-img" />

                        <Select
                          className="select"
                          options={customername}
                          placeholder="Newest"
                        />
                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-6 col-12">
                      <div className="input-blocks">
                        <StopCircle className="info-img" />

                        <Select
                          className="select"
                          options={status}
                          placeholder="Newest"
                        />
                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-6 col-12">
                      <div className="input-blocks">
                        <FileText className="info-img" />
                        <input
                          type="text"
                          placeholder="Enter Invoice"
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-12">
                      <div className="input-blocks">
                        <StopCircle className="info-img" />

                        <Select
                          className="select"
                          options={paymentstatus}
                          placeholder="Choose Payment Status"
                        />
                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-6 col-12">
                      <div className="input-blocks">
                        <Link className="btn btn-filters ms-auto">
                          {" "}
                          <i
                            data-feather="search"
                            className="feather-search"
                          />{" "}
                          Search{" "}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /Filter */}
              <div className="table-responsive">
                <table className="table  datanew">
                  <thead>
                    <tr>
                      {/* <th className="no-sort">
                        <label className="checkboxs">
                          <input type="checkbox" id="select-all" />
                          <span className="checkmarks" />
                        </label>
                      </th> */}
                      <th>Invoice</th>
                      <th>Customer Name</th>
                      <th>Sale Invoice</th>
                      <th>Date</th>
                      <th>Product Return Status</th>
                      <th>Grand Total</th>
                      <th>Paid</th>
                      <th>Due</th>
                      <th>Payment Return Status</th>
                      {/* <th>Biller</th> */}
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody className="salesReturn-list">
                    {filteredSalesReturn?.map((data, index) => {
                      return (
                        <tr key={index}>
                          {/* <td>
                            <label className="checkboxs">
                              <input type="checkbox" />
                              <span className="checkmarks" />
                            </label>
                          </td> */}
                          <td>{data?.invoiceNumber}</td>
                          <td>{data?.customer?.label}</td>
                          <td>{data?.salesNumber?.label}</td>
                          <td>{data.date.split("T")[0]}</td>
                          <td>
                            <span
                              className={`badge ${
                                data?.itemReturnStatus?.label === "Inprogress"
                                  ? "badge-linedanger"
                                  : data?.itemReturnStatus?.label === "Partial"
                                  ? "badge-linewarning"
                                  : "badge-linesuccess"
                              }`}
                            >
                              {data?.itemReturnStatus?.label}
                            </span>
                          </td>
                          <td>{data?.grandTotal}</td>
                          <td>{data?.paidAmount}</td>
                          <td>{data?.dueAmount}</td>
                          <td>
                            <span
                              className={`badge ${
                                data?.paymentStatus?.label == "Completed"
                                  ? "badge-linesuccess"
                                  : "badge-linedanger"
                              }`}
                            >
                              {data?.paymentStatus?.label}
                            </span>
                          </td>
                          {/* <td>Admin</td> */}
                          <td className="text-center">
                            <Link
                              className="action-set"
                              to="#"
                              data-bs-toggle="dropdown"
                              aria-expanded="true"
                            >
                              <i
                                className="fa fa-ellipsis-v"
                                aria-hidden="true"
                              />
                            </Link>
                            <ul className="dropdown-menu">
                              {/* <li>
                                <Link
                                  to="#"
                                  className="dropdown-item"
                                  data-bs-toggle="modal"
                                  data-bs-target="#salesReturn-details-new"
                                >
                                  <i data-feather="eye" className="info-img" />
                                  Sale Detail
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="#"
                                  className="dropdown-item"
                                  data-bs-toggle="modal"
                                  data-bs-target="#edit-salesReturn-new"
                                >
                                  <i data-feather="edit" className="info-img" />
                                  Edit Sale
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="#"
                                  className="dropdown-item"
                                  data-bs-toggle="modal"
                                  data-bs-target="#showpayment"
                                >
                                  <i
                                    data-feather="dollar-sign"
                                    className="info-img"
                                  />
                                  Show Payments
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="#"
                                  className="dropdown-item"
                                  data-bs-toggle="modal"
                                  data-bs-target="#createpayment"
                                >
                                  <i
                                    data-feather="plus-circle"
                                    className="info-img"
                                  />
                                  Create Payment
                                </Link>
                              </li> */}
                              <li>
                                <Link
                                  className="dropdown-item"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setShowPdfModal(true);
                                    setPdfData({
                                      heading: "Sales Return List",
                                      array: {
                                        // Customer: data?.customer?.label,
                                        from: {
                                          name: "FM Marketing",
                                          email: "fmmarketing@example.com",
                                          phone: "9876543210",
                                          address: "Raipur, Chhattisgarh",
                                          country: {
                                            label: "India",
                                            value: "India",
                                          },
                                          state: {
                                            label: "Chhattisgarh",
                                            value: "Chhattisgarh",
                                          },
                                          city: {
                                            label: "Raipur",
                                            value: "Raipur",
                                          },
                                          status: "Active",
                                          itemActive: true,
                                        },
                                        to: {
                                          name: "",
                                          email: "",
                                          phone: "",
                                          address: "",
                                          country: {
                                            label: "India",
                                            value: "India",
                                          },
                                          state: {
                                            label: "Chhattisgarh",
                                            value: "Chhattisgarh",
                                          },
                                          city: {
                                            label: "Raipur",
                                            value: "Raipur",
                                          },
                                          status: "Active",
                                          itemActive: true,
                                        },
                                        Invoice_Number: data?.invoiceNumber,
                                        Date: data?.date,
                                        Product_List: data?.productsList,
                                        Order_Tax: data?.orderTax,
                                        Discount: data?.discountWithUnit,
                                        Shipping: data?.shipping,
                                        Grand_Total: data?.grandTotal,
                                        Sales_Return_Status:
                                          data?.salesReturnStatus?.label,
                                        Due_Amount: data?.dueAmount,
                                        Due_Date: data?.dueDate,
                                        Paid_Amount: data?.paidAmount,
                                        Payment_Status: data?.paymentStatus,
                                        Payment_Method: data?.paymentMethod,
                                      },
                                    });
                                  }}
                                >
                                  <i
                                    data-feather="download"
                                    className="info-img"
                                  />
                                  Generate Regular Pdf
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setShowPdfModal(true);
                                    setPdfData({
                                      pdfType: "Transport",
                                      heading: "Sales Return List",
                                      array: {
                                        // Customer: data?.customer?.label,
                                        from: {
                                          name: "FM Marketing",
                                          email: "fmmarketing@example.com",
                                          phone: "9876543210",
                                          address: "Raipur, Chhattisgarh",
                                          country: {
                                            label: "India",
                                            value: "India",
                                          },
                                          state: {
                                            label: "Chhattisgarh",
                                            value: "Chhattisgarh",
                                          },
                                          city: {
                                            label: "Raipur",
                                            value: "Raipur",
                                          },
                                          status: "Active",
                                          itemActive: true,
                                        },
                                        to: {
                                          name: "",
                                          email: "",
                                          phone: "",
                                          address: "",
                                          country: {
                                            label: "India",
                                            value: "India",
                                          },
                                          state: {
                                            label: "Chhattisgarh",
                                            value: "Chhattisgarh",
                                          },
                                          city: {
                                            label: "Raipur",
                                            value: "Raipur",
                                          },
                                          status: "Active",
                                          itemActive: true,
                                        },
                                        Invoice_Number: data?.invoiceNumber,
                                        Date: data?.date,
                                        Product_List: data?.productsList,
                                        Order_Tax: data?.orderTax,
                                        Discount: data?.discountWithUnit,
                                        Shipping: data?.shipping,
                                        Grand_Total: data?.grandTotal,
                                        Sales_Return_Status:
                                          data?.salesReturnStatus?.label,
                                        Due_Amount: data?.dueAmount,
                                        Due_Date: data?.dueDate,
                                        Paid_Amount: data?.paidAmount,
                                        Payment_Status: data?.paymentStatus,
                                        Payment_Method: data?.paymentMethod,
                                      },
                                    });
                                  }}
                                >
                                  <i
                                    data-feather="download"
                                    className="info-img"
                                  />
                                  Generate Transport Pdf
                                </Link>
                              </li>
                              {/* <li>
                                <Link
                                  className="dropdown-item confirm-text mb-0"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    deleteData(data?._id);
                                  }}
                                >
                                  <i
                                    data-feather="trash-2"
                                    className="info-img"
                                  />
                                  Delete Sale
                                </Link>
                              </li> */}
                            </ul>
                          </td>
                        </tr>
                      );
                    })}
                    {/* <tr>
                                            <td>
                                                <label className="checkboxs">
                                                    <input type="checkbox" />
                                                    <span className="checkmarks" />
                                                </label>
                                            </td>
                                            <td>Thomas</td>
                                            <td>SL0101</td>
                                            <td>19 Jan 2023</td>
                                            <td>
                                                <span className="badge badge-bgsuccess">Completed</span>
                                            </td>
                                            <td>$550</td>
                                            <td>$550</td>
                                            <td>$0.00</td>
                                            <td>
                                                <span className="badge badge-linesuccess">Paid</span>
                                            </td>
                                            <td>Admin</td>
                                            <td className="text-center">
                                                <Link
                                                    className="action-set"
                                                    to="#"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="true"
                                                >
                                                    <i className="fa fa-ellipsis-v" aria-hidden="true" />
                                                </Link>
                                                <ul className="dropdown-menu">
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#salesReturn-details-new"
                                                        >
                                                            <i data-feather="eye" className="info-img" />
                                                            Sale Detail
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#edit-salesReturn-new"
                                                        >
                                                            <i data-feather="edit" className="info-img" />
                                                            Edit Sale
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#showpayment"
                                                        >
                                                            <i data-feather="dollar-sign" className="info-img" />
                                                            Show Payments
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#createpayment"
                                                        >
                                                            <i data-feather="plus-circle" className="info-img" />
                                                            Create Payment
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="#" className="dropdown-item">
                                                            <i data-feather="download" className="info-img" />
                                                            Download pdf
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item confirm-text mb-0"
                                                        >
                                                            <i data-feather="trash-2" className="info-img" />
                                                            Delete Sale
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <label className="checkboxs">
                                                    <input type="checkbox" />
                                                    <span className="checkmarks" />
                                                </label>
                                            </td>
                                            <td>Rose</td>
                                            <td>SL0102</td>
                                            <td>26 Jan 2023</td>
                                            <td>
                                                <span className="badge badge-bgsuccess">Completed</span>
                                            </td>
                                            <td>$250</td>
                                            <td>$250</td>
                                            <td>$0.00</td>
                                            <td>
                                                <span className="badge badge-linesuccess">Paid</span>
                                            </td>
                                            <td>Admin</td>
                                            <td className="text-center">
                                                <Link
                                                    className="action-set"
                                                    to="#"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="true"
                                                >
                                                    <i className="fa fa-ellipsis-v" aria-hidden="true" />
                                                </Link>
                                                <ul className="dropdown-menu">
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#salesReturn-details-new"
                                                        >
                                                            <i data-feather="eye" className="info-img" />
                                                            Sale Detail
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#edit-salesReturn-new"
                                                        >
                                                            <i data-feather="edit" className="info-img" />
                                                            Edit Sale
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#showpayment"
                                                        >
                                                            <i data-feather="dollar-sign" className="info-img" />
                                                            Show Payments
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#createpayment"
                                                        >
                                                            <i data-feather="plus-circle" className="info-img" />
                                                            Create Payment
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="#" className="dropdown-item">
                                                            <i data-feather="download" className="info-img" />
                                                            Download pdf
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item confirm-text mb-0"
                                                        >
                                                            <i data-feather="trash-2" className="info-img" />
                                                            Delete Sale
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <label className="checkboxs">
                                                    <input type="checkbox" />
                                                    <span className="checkmarks" />
                                                </label>
                                            </td>
                                            <td>Benjamin</td>
                                            <td>SL0103</td>
                                            <td>08 Feb 2023</td>
                                            <td>
                                                <span className="badge badge-bgsuccess">Completed</span>
                                            </td>
                                            <td>$570</td>
                                            <td>$570</td>
                                            <td>$0.00</td>
                                            <td>
                                                <span className="badge badge-linesuccess">Paid</span>
                                            </td>
                                            <td>Admin</td>
                                            <td className="text-center">
                                                <Link
                                                    className="action-set"
                                                    to="#"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="true"
                                                >
                                                    <i className="fa fa-ellipsis-v" aria-hidden="true" />
                                                </Link>
                                                <ul className="dropdown-menu">
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#salesReturn-details-new"
                                                        >
                                                            <i data-feather="eye" className="info-img" />
                                                            Sale Detail
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#edit-salesReturn-new"
                                                        >
                                                            <i data-feather="edit" className="info-img" />
                                                            Edit Sale
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#showpayment"
                                                        >
                                                            <i data-feather="dollar-sign" className="info-img" />
                                                            Show Payments
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#createpayment"
                                                        >
                                                            <i data-feather="plus-circle" className="info-img" />
                                                            Create Payment
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="#" className="dropdown-item">
                                                            <i data-feather="download" className="info-img" />
                                                            Download pdf
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item confirm-text mb-0"
                                                        >
                                                            <i data-feather="trash-2" className="info-img" />
                                                            Delete Sale
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <label className="checkboxs">
                                                    <input type="checkbox" />
                                                    <span className="checkmarks" />
                                                </label>
                                            </td>
                                            <td>Lilly</td>
                                            <td>SL0104</td>
                                            <td>12 Feb 2023</td>
                                            <td>
                                                <span className="badge badge-bgdanger">Pending</span>
                                            </td>
                                            <td>$300</td>
                                            <td>$0.00</td>
                                            <td>$300</td>
                                            <td>
                                                <span className="badge badge-linedanger">Due</span>
                                            </td>
                                            <td>Admin</td>
                                            <td className="text-center">
                                                <Link
                                                    className="action-set"
                                                    to="#"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="true"
                                                >
                                                    <i className="fa fa-ellipsis-v" aria-hidden="true" />
                                                </Link>
                                                <ul className="dropdown-menu">
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#salesReturn-details-new"
                                                        >
                                                            <i data-feather="eye" className="info-img" />
                                                            Sale Detail
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#edit-salesReturn-new"
                                                        >
                                                            <i data-feather="edit" className="info-img" />
                                                            Edit Sale
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#showpayment"
                                                        >
                                                            <i data-feather="dollar-sign" className="info-img" />
                                                            Show Payments
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#createpayment"
                                                        >
                                                            <i data-feather="plus-circle" className="info-img" />
                                                            Create Payment
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="#" className="dropdown-item">
                                                            <i data-feather="download" className="info-img" />
                                                            Download pdf
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item confirm-text mb-0"
                                                        >
                                                            <i data-feather="trash-2" className="info-img" />
                                                            Delete Sale
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <label className="checkboxs">
                                                    <input type="checkbox" />
                                                    <span className="checkmarks" />
                                                </label>
                                            </td>
                                            <td>Freda</td>
                                            <td>SL0105</td>
                                            <td>17 Mar 2023</td>
                                            <td>
                                                <span className="badge badge-bgdanger">Pending</span>
                                            </td>
                                            <td>$700</td>
                                            <td>$0.00</td>
                                            <td>$700</td>
                                            <td>
                                                <span className="badge badge-linedanger">Due</span>
                                            </td>
                                            <td>Admin</td>
                                            <td className="text-center">
                                                <Link
                                                    className="action-set"
                                                    to="#"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="true"
                                                >
                                                    <i className="fa fa-ellipsis-v" aria-hidden="true" />
                                                </Link>
                                                <ul className="dropdown-menu">
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#salesReturn-details-new"
                                                        >
                                                            <i data-feather="eye" className="info-img" />
                                                            Sale Detail
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#edit-salesReturn-new"
                                                        >
                                                            <i data-feather="edit" className="info-img" />
                                                            Edit Sale
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#showpayment"
                                                        >
                                                            <i data-feather="dollar-sign" className="info-img" />
                                                            Show Payments
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#createpayment"
                                                        >
                                                            <i data-feather="plus-circle" className="info-img" />
                                                            Create Payment
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="#" className="dropdown-item">
                                                            <i data-feather="download" className="info-img" />
                                                            Download pdf
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item confirm-text mb-0"
                                                        >
                                                            <i data-feather="trash-2" className="info-img" />
                                                            Delete Sale
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <label className="checkboxs">
                                                    <input type="checkbox" />
                                                    <span className="checkmarks" />
                                                </label>
                                            </td>
                                            <td>Alwin</td>
                                            <td>SL0106</td>
                                            <td>24 Mar 2023</td>
                                            <td>
                                                <span className="badge badge-bgsuccess">Completed</span>
                                            </td>
                                            <td>$400</td>
                                            <td>$400</td>
                                            <td>$0.00</td>
                                            <td>
                                                <span className="badge badge-linesuccess">Paid</span>
                                            </td>
                                            <td>Admin</td>
                                            <td className="text-center">
                                                <Link
                                                    className="action-set"
                                                    to="#"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="true"
                                                >
                                                    <i className="fa fa-ellipsis-v" aria-hidden="true" />
                                                </Link>
                                                <ul className="dropdown-menu">
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#salesReturn-details-new"
                                                        >
                                                            <i data-feather="eye" className="info-img" />
                                                            Sale Detail
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#edit-salesReturn-new"
                                                        >
                                                            <i data-feather="edit" className="info-img" />
                                                            Edit Sale
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#showpayment"
                                                        >
                                                            <i data-feather="dollar-sign" className="info-img" />
                                                            Show Payments
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#createpayment"
                                                        >
                                                            <i data-feather="plus-circle" className="info-img" />
                                                            Create Payment
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="#" className="dropdown-item">
                                                            <i data-feather="download" className="info-img" />
                                                            Download pdf
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item confirm-text mb-0"
                                                        >
                                                            <i data-feather="trash-2" className="info-img" />
                                                            Delete Sale
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <label className="checkboxs">
                                                    <input type="checkbox" />
                                                    <span className="checkmarks" />
                                                </label>
                                            </td>
                                            <td>Maybelle</td>
                                            <td>SL0107</td>
                                            <td>06 Apr 2023</td>
                                            <td>
                                                <span className="badge badge-bgdanger">Pending</span>
                                            </td>
                                            <td>$120</td>
                                            <td>$0.00</td>
                                            <td>$120</td>
                                            <td>
                                                <span className="badge badge-linedanger">Due</span>
                                            </td>
                                            <td>Admin</td>
                                            <td className="text-center">
                                                <Link
                                                    className="action-set"
                                                    to="#"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="true"
                                                >
                                                    <i className="fa fa-ellipsis-v" aria-hidden="true" />
                                                </Link>
                                                <ul className="dropdown-menu">
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#salesReturn-details-new"
                                                        >
                                                            <i data-feather="eye" className="info-img" />
                                                            Sale Detail
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#edit-salesReturn-new"
                                                        >
                                                            <i data-feather="edit" className="info-img" />
                                                            Edit Sale
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#showpayment"
                                                        >
                                                            <i data-feather="dollar-sign" className="info-img" />
                                                            Show Payments
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#createpayment"
                                                        >
                                                            <i data-feather="plus-circle" className="info-img" />
                                                            Create Payment
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="#" className="dropdown-item">
                                                            <i data-feather="download" className="info-img" />
                                                            Download pdf
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item confirm-text mb-0"
                                                        >
                                                            <i data-feather="trash-2" className="info-img" />
                                                            Delete Sale
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <label className="checkboxs">
                                                    <input type="checkbox" />
                                                    <span className="checkmarks" />
                                                </label>
                                            </td>
                                            <td>Ellen</td>
                                            <td>SL0108</td>
                                            <td>16 Apr 2023</td>
                                            <td>
                                                <span className="badge badge-bgsuccess">Completed</span>
                                            </td>
                                            <td>$830</td>
                                            <td>$830</td>
                                            <td>$0.00</td>
                                            <td>
                                                <span className="badge badge-linesuccess">Paid</span>
                                            </td>
                                            <td>Admin</td>
                                            <td className="text-center">
                                                <Link
                                                    className="action-set"
                                                    to="#"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="true"
                                                >
                                                    <i className="fa fa-ellipsis-v" aria-hidden="true" />
                                                </Link>
                                                <ul className="dropdown-menu">
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#salesReturn-details-new"
                                                        >
                                                            <i data-feather="eye" className="info-img" />
                                                            Sale Detail
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#edit-salesReturn-new"
                                                        >
                                                            <i data-feather="edit" className="info-img" />
                                                            Edit Sale
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#showpayment"
                                                        >
                                                            <i data-feather="dollar-sign" className="info-img" />
                                                            Show Payments
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#createpayment"
                                                        >
                                                            <i data-feather="plus-circle" className="info-img" />
                                                            Create Payment
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="#" className="dropdown-item">
                                                            <i data-feather="download" className="info-img" />
                                                            Download pdf
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item confirm-text mb-0"
                                                        >
                                                            <i data-feather="trash-2" className="info-img" />
                                                            Delete Sale
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <label className="checkboxs">
                                                    <input type="checkbox" />
                                                    <span className="checkmarks" />
                                                </label>
                                            </td>
                                            <td>Kaitlin</td>
                                            <td>SL0109</td>
                                            <td>04 May 2023</td>
                                            <td>
                                                <span className="badge badge-bgdanger">Pending</span>
                                            </td>
                                            <td>$800</td>
                                            <td>$0.00</td>
                                            <td>$800</td>
                                            <td>
                                                <span className="badge badge-linedanger">Due</span>
                                            </td>
                                            <td>Admin</td>
                                            <td className="text-center">
                                                <Link
                                                    className="action-set"
                                                    to="#"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="true"
                                                >
                                                    <i className="fa fa-ellipsis-v" aria-hidden="true" />
                                                </Link>
                                                <ul className="dropdown-menu">
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#salesReturn-details-new"
                                                        >
                                                            <i data-feather="eye" className="info-img" />
                                                            Sale Detail
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#edit-salesReturn-new"
                                                        >
                                                            <i data-feather="edit" className="info-img" />
                                                            Edit Sale
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#showpayment"
                                                        >
                                                            <i data-feather="dollar-sign" className="info-img" />
                                                            Show Payments
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#createpayment"
                                                        >
                                                            <i data-feather="plus-circle" className="info-img" />
                                                            Create Payment
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="#" className="dropdown-item">
                                                            <i data-feather="download" className="info-img" />
                                                            Download pdf
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item confirm-text mb-0"
                                                        >
                                                            <i data-feather="trash-2" className="info-img" />
                                                            Delete Sale
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <label className="checkboxs">
                                                    <input type="checkbox" />
                                                    <span className="checkmarks" />
                                                </label>
                                            </td>
                                            <td>Grace</td>
                                            <td>SL0110</td>
                                            <td>29 May 2023</td>
                                            <td>
                                                <span className="badge badge-bgsuccess">Completed</span>
                                            </td>
                                            <td>$460</td>
                                            <td>$460</td>
                                            <td>$0.00</td>
                                            <td>
                                                <span className="badge badge-linesuccess">Paid</span>
                                            </td>
                                            <td>Admin</td>
                                            <td className="text-center">
                                                <Link
                                                    className="action-set"
                                                    to="#"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="true"
                                                >
                                                    <i className="fa fa-ellipsis-v" aria-hidden="true" />
                                                </Link>
                                                <ul className="dropdown-menu">
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#salesReturn-details-new"
                                                        >
                                                            <i data-feather="eye" className="info-img" />
                                                            Sale Detail
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#edit-salesReturn-new"
                                                        >
                                                            <i data-feather="edit" className="info-img" />
                                                            Edit Sale
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#showpayment"
                                                        >
                                                            <i data-feather="dollar-sign" className="info-img" />
                                                            Show Payments
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#createpayment"
                                                        >
                                                            <i data-feather="plus-circle" className="info-img" />
                                                            Create Payment
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="#" className="dropdown-item">
                                                            <i data-feather="download" className="info-img" />
                                                            Download pdf
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to="#"
                                                            className="dropdown-item confirm-text mb-0"
                                                        >
                                                            <i data-feather="trash-2" className="info-img" />
                                                            Delete Sale
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr> */}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
      <>
        {/*add popup */}
        <div className="modal fade" id="add-salesReturn-new">
          <div className="modal-dialog add-centered">
            <div className="modal-content">
              <div className="page-wrapper p-0 m-0">
                <div className="content p-0">
                  <div className="modal-header border-0 custom-modal-header">
                    <div className="page-title">
                      <h4>Add Sales Return </h4>
                    </div>
                    <button
                      type="button"
                      className="close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="card">
                    <div className="card-body">
                      <form>
                        <div className="row">
                          <div className="col-lg-3 col-sm-6 col-12">
                            <div className="input-blocks">
                              <label>
                                Sales Number{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <div className="row">
                                <div className="col-lg-12 col-sm-12 col-12">
                                  <div className="d-flex justify-content-between">
                                    <div style={{ width: "100%" }}>
                                      <Select
                                        value={
                                          selectedSales
                                            ? selectedSales
                                            : [
                                                {
                                                  label: "Choose Sales",
                                                  value: null,
                                                },
                                              ]
                                        }
                                        className="select"
                                        options={filteredSales?.map((item) => {
                                          return {
                                            label: item.invoiceNumber,
                                            value: item._id,
                                          };
                                        })}
                                        placeholder="Choose Sales"
                                        onChange={(value) => {
                                          setSelectedSales(value);
                                          setSalesReturnInvoiceNumber(
                                            `SR-${new Date().getFullYear()}${
                                              salesReturn_data?.length + 1
                                            }`
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3 col-sm-6 col-12">
                            <div className="input-blocks">
                              <label>Date</label>
                              <div className="input-groupicon calender-input">
                                <DatePicker
                                  disabled
                                  popupStyle={{ zIndex: 2000 }}
                                  selected={selectedDate}
                                  onChange={handleDateChange}
                                  type="date"
                                  className="filterdatepicker add-salesReturn-filterdatepicker"
                                  dateFormat="dd-MM-yyyy"
                                  placeholder={`${selectedDate.toDateString()}`}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3 col-sm-6 col-12">
                            <div className="input-blocks">
                              <label>
                                Invoice Number
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <div className="input-groupicon calender-input">
                                <input
                                  readOnly
                                  disabled
                                  style={{ padding: 10 }}
                                  type="text"
                                  value={salesReturnInvoiceNumber}
                                  placeholder="Enter Invoice Number"
                                  onChange={(e) => {
                                    setSalesReturnInvoiceNumber(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3 col-sm-6 col-12">
                            <div className="input-blocks mb-5">
                              <label>
                                Customer Name{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <div className="input-groupicon select-code">
                                <input
                                  disabled
                                  style={{ width: "100%" }}
                                  value={selectedSalesData?.customer?.label}
                                  className="p-2"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-3 col-sm-6 col-12">
                            <div className="input-blocks">
                              <label>Order Tax(%)</label>
                              <div className="input-groupicon select-code">
                                <input
                                  disabled
                                  value={selectedSalesData.orderTax}
                                  type="text"
                                  defaultValue={0}
                                  className="p-2"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-2 col-sm-6 col-12">
                            <div className="input-blocks">
                              <label>Discount Type</label>
                              <div className="input-groupicon select-code">
                                <Select
                                  value={
                                    discountType
                                      ? discountType
                                      : [
                                          {
                                            label: "Choose",
                                            value: null,
                                          },
                                        ]
                                  }
                                  onChange={(value) => setDiscountType(value)}
                                  className="select"
                                  options={[
                                    {
                                      label: "Percent",
                                      value: "Percent",
                                    },
                                    {
                                      label: "Cash",
                                      value: "Cash",
                                    },
                                  ]}
                                  placeholder="Choose"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-2 col-sm-6 col-12">
                            <div className="input-blocks">
                              <label>Discount</label>
                              <div className="input-groupicon select-code">
                                <input
                                  disabled
                                  value={selectedSalesData.discount}
                                  type="number"
                                  defaultValue={0}
                                  className="p-2"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-2 col-sm-6 col-12">
                            <div className="input-blocks">
                              <label>Shipping (₹)</label>
                              <div className="input-groupicon select-code">
                                <input
                                  disabled
                                  value={selectedSalesData.shipping}
                                  type="number"
                                  defaultValue="0"
                                  className="p-2"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="table-responsive no-pagination"
                          style={{ overflow: "unset" }}
                        >
                          <table className="table datanew">
                            <thead>
                              <tr>
                                <th>Product</th>
                                <th>Unit</th>
                                <th>Qty</th>
                                <th>Alt. Qty</th>
                                <th>Current Stock</th>
                                <th>Price(₹)</th>
                                <th>Discount</th>
                                <th>Tax(%)</th>
                                <th>Tax Amt(₹)</th>
                                <th>Unit Cost(₹)</th>
                                <th>Total Cost(₹)</th>
                                {/* <th>Action</th> */}
                              </tr>
                            </thead>
                            <tbody>
                              {selectedSalesData ? (
                                selectedSalesData.productsList?.map(
                                  (product, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>{product.productName}</td>
                                        <td>{product.selectedUnit.label}</td>
                                        <td>{product.selectedQty}</td>
                                        <td>{product.productAlternateQty}</td>
                                        <td>{getProductStockData(product)}</td>
                                        <td>{product.purchasePrice}</td>
                                        <td>
                                          {product.productDiscountValue}
                                          {product.productDiscountType.label ==
                                          "Cash"
                                            ? "₹"
                                            : "%"}
                                        </td>
                                        <td>{product.productTaxValue}</td>
                                        <td>{product.productCurrentTax}</td>
                                        <td>{product.productUnitCost}</td>
                                        <td>
                                          {product.totalCost
                                            ? product.totalCost
                                            : 0}
                                        </td>
                                        {/* <td>
                                      <span
                                        className="feather-trash-container"
                                        onClick={() => deleteProduct(index)}
                                      >
                                        <i
                                          data-feather="trash-2"
                                          className="feather feather-trash-2 feather-trash-2"
                                        />
                                      </span>
                                    </td> */}
                                      </tr>
                                    );
                                  }
                                )
                              ) : (
                                <></>
                              )}
                            </tbody>
                          </table>
                        </div>
                        <div className="row">
                          <div className="col-lg-6 ms-auto">
                            <div className="total-order w-100 max-widthauto m-auto mb-4">
                              <ul>
                                <li>
                                  <h4>Order Tax(%)</h4>
                                  <h5>{selectedSalesData?.orderTax}</h5>
                                </li>
                                <li>
                                  <h4>
                                    Discount
                                    {discountType.label == "Percent"
                                      ? "(%)"
                                      : discountType.label == "Cash"
                                      ? "(₹)"
                                      : ""}
                                  </h4>
                                  <h5>{selectedSalesData?.discount}</h5>
                                </li>
                                <li>
                                  <h4>Shipping(₹)</h4>
                                  <h5>{selectedSalesData?.shipping}</h5>
                                </li>
                                <li>
                                  <h4>Grand Total(₹)</h4>
                                  <h5>{selectedSalesData.grandTotal}</h5>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-3 col-sm-6 col-12">
                            <div className="input-blocks">
                              <label>Return Amount (₹) </label>
                              <div className="input-groupicon select-code">
                                <input
                                  value={salesReturnAmount}
                                  type="number"
                                  defaultValue={0}
                                  className="p-2"
                                  onChange={(e) => {
                                    setSalesReturnAmount(e.target.value);

                                    setDueAmount(
                                      selectedSalesData?.paidAmount -
                                        e.target.value
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3 col-sm-6 col-12">
                            <div className="input-blocks">
                              <label>Due Return Amount (₹) </label>
                              <div className="input-groupicon select-code">
                                <input
                                  disabled
                                  value={dueAmount}
                                  type="number"
                                  defaultValue={0}
                                  className="p-2"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3 col-sm-6 col-12">
                            <div className="input-blocks">
                              <label>Due Date</label>
                              <div className="input-groupicon calender-input">
                                {/* <DatePicker
                                  type="date"
                                  onChange={(value) => setDueDate(value)}
                                  popupStyle={{ zIndex: 2000 }}
                                  inputMode={false}
                                  // value={new Date(selectedSalesData?.dueDate)}
                                  className="filterdatepicker"
                                  dateFormat="yyyy-mm-dd"
                                /> */}
                                <input
                                  onChange={(e) => {
                                    setDueDate(e.target.value);
                                  }}
                                  type="date"
                                  className="custom-date-picker"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3 col-sm-6 col-12">
                            <div className="input-blocks">
                              <label>Paid Amount(₹) </label>
                              <div className="input-groupicon select-code">
                                <input
                                  disabled
                                  value={
                                    selectedSalesData?.paidAmount
                                      ? Number(
                                          selectedSalesData?.paidAmount
                                        ).toFixed(2)
                                      : 0
                                  }
                                  type="number"
                                  defaultValue={0}
                                  className="p-2"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-3 col-sm-6 col-12">
                              <div className="input-blocks mb-5">
                                <label>Payment Status </label>

                                <div className="input-groupicon select-code">
                                  <Select
                                    value={
                                      paymentStatus
                                        ? paymentStatus
                                        : [
                                            {
                                              label: "Choose",
                                              value: null,
                                            },
                                          ]
                                    }
                                    onChange={(value) =>
                                      setPaymentStatus(value)
                                    }
                                    className="select"
                                    options={[
                                      { label: "Pending", value: "Pending" },
                                      {
                                        label: "Completed",
                                        value: "Completed",
                                      },
                                    ]}
                                    placeholder="Choose"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-sm-6 col-12">
                              <div className="input-blocks mb-5">
                                <label>Payment Method </label>

                                <div className="input-groupicon select-code">
                                  <Select
                                    value={
                                      paymentMethod
                                        ? paymentMethod
                                        : [{ label: "Choose", value: null }]
                                    }
                                    onChange={(value) =>
                                      setPaymentMethod(value)
                                    }
                                    className="select"
                                    options={[
                                      { label: "Cash", value: "Cash" },
                                      { label: "Card", value: "Card" },
                                      { label: "Online", value: "Online" },
                                    ]}
                                    placeholder="Choose"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-sm-6 col-12">
                              <div className="input-blocks mb-5">
                                <label>Product Return Status </label>
                                <div className="input-groupicon select-code">
                                  <Select
                                    value={
                                      itemReturnStatus
                                        ? itemReturnStatus
                                        : [
                                            {
                                              label: "Inprogress",
                                              value: "Inprogress",
                                            },
                                          ]
                                    }
                                    onChange={(value) =>
                                      setItemReturnStatus(value)
                                    }
                                    className="select"
                                    options={[
                                      { label: "Returned", value: "Returned" },
                                      { label: "Partial", value: "Partial" },
                                      {
                                        label: "Inprogress",
                                        value: "Inprogress",
                                      },
                                    ]}
                                    placeholder="Choose"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12 text-end">
                            <button
                              type="button"
                              className="btn btn-cancel add-cancel me-3"
                              data-bs-dismiss="modal"
                              onClick={clearData}
                            >
                              Cancel
                            </button>
                            <div
                              className={`btn btn-submit ${
                                isLoading ? "disabled" : ""
                              }`}
                              onClick={showTransport}
                            >
                              Submit
                            </div>
                            <span
                              data-bs-dismiss="modal"
                              id="dismiss-modal"
                            ></span>
                          </div>
                        </div>
                      </form>
                      <span style={{ fontStyle: "italic" }}>
                        All fields with <span style={{ color: "red" }}>*</span>{" "}
                        are required
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /add popup */}
        {/* details popup */}
        <div className="modal fade" id="salesReturn-details-new">
          <div className="modal-dialog salesReturn-details-modal">
            <div className="modal-content">
              <div className="page-wrapper details-blk">
                <div className="content p-0">
                  <div className="page-header p-4 mb-0">
                    <div className="add-item d-flex">
                      <div className="page-title modal-datail">
                        <h4>SalesReturn Detail : SL0101</h4>
                      </div>
                      <div className="page-btn">
                        <Link
                          to="#"
                          className="btn btn-added"
                          data-bs-toggle="modal"
                          data-bs-target="#add-payroll-new"
                        >
                          <PlusCircle className="me-2" />
                          Add New SalesReturn
                        </Link>
                      </div>
                    </div>
                    <ul className="table-top-head">
                      <li>
                        <Link
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Pdf"
                        >
                          <i
                            data-feather="edit"
                            className="action-edit salesReturn-action"
                          />
                        </Link>
                      </li>
                      <li>
                        <Link
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Pdf"
                        >
                          <ImageWithBasePath
                            src="assets/img/icons/pdf.svg"
                            alt="img"
                          />
                        </Link>
                      </li>
                      <li>
                        <Link
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Excel"
                        >
                          <ImageWithBasePath
                            src="assets/img/icons/excel.svg"
                            alt="img"
                          />
                        </Link>
                      </li>
                      <li>
                        <Link
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Print"
                        >
                          <i
                            data-feather="printer"
                            className="feather-rotate-ccw"
                          />
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="card">
                    <div className="card-body">
                      <form>
                        <div
                          className="invoice-box table-height"
                          style={{
                            maxWidth: 1600,
                            width: "100%",
                            overflow: "auto",
                            padding: 0,
                            fontSize: 14,
                            lineHeight: 24,
                            color: "#555",
                          }}
                        >
                          <div className="salesReturn-details-items d-flex">
                            <div className="details-item">
                              <h6>Customer Info</h6>
                              <p>
                                walk-in-customer
                                <br />
                                walk-in-customer@example.com
                                <br />
                                123456780
                                <br />
                                N45 , Dhaka
                              </p>
                            </div>
                            <div className="details-item">
                              <h6>Company Info</h6>
                              <p>
                                DGT
                                <br />
                                admin@example.com
                                <br />
                                6315996770
                                <br />
                                3618 Abia Martin Drive
                              </p>
                            </div>
                            <div className="details-item">
                              <h6>Invoice Info</h6>
                              <p>
                                Invoice
                                <br />
                                Payment Status
                                <br />
                                Status
                              </p>
                            </div>
                            <div className="details-item">
                              <h5>
                                <span>SL0101</span>Paid
                                <br /> Completed
                              </h5>
                            </div>
                          </div>
                          <h5 className="order-text">Order Summary</h5>
                          <div className="table-responsive no-pagination">
                            <table className="table  datanew">
                              <thead>
                                <tr>
                                  <th>Product</th>
                                  <th>Qty</th>
                                  <th>Price($)</th>
                                  <th>Discount($)</th>
                                  <th>Tax(%)</th>
                                  <th>Tax Amount($)</th>
                                  <th>Unit Cost($)</th>
                                  <th>Total Cost(%)</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    <div className="productimgname">
                                      <Link
                                        to="#"
                                        className="product-img stock-img"
                                      >
                                        <ImageWithBasePath
                                          src="assets/img/products/stock-img-02.png"
                                          alt="product"
                                        />
                                      </Link>
                                      <Link to="#">Nike Jordan</Link>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="product-quantity">
                                      <span className="quantity-btn">
                                        +
                                        <i
                                          data-feather="plus-circle"
                                          className="plus-circle"
                                        />
                                      </span>
                                      <input
                                        type="text"
                                        className="quntity-input"
                                        defaultValue={2}
                                      />
                                      <span className="quantity-btn">
                                        <i
                                          data-feather="minus-circle"
                                          className="feather-search"
                                        />
                                      </span>
                                    </div>
                                  </td>
                                  <td>2000</td>
                                  <td>500</td>
                                  <td>0.00</td>
                                  <td>0.00</td>
                                  <td>0.00</td>
                                  <td>1500</td>
                                </tr>
                                <tr>
                                  <td>
                                    <div className="productimgname">
                                      <Link
                                        to="#"
                                        className="product-img stock-img"
                                      >
                                        <ImageWithBasePath
                                          src="assets/img/products/stock-img-03.png"
                                          alt="product"
                                        />
                                      </Link>
                                      <Link to="#">Apple Series 5 Watch</Link>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="product-quantity">
                                      <span className="quantity-btn">
                                        +
                                        <i
                                          data-feather="plus-circle"
                                          className="plus-circle"
                                        />
                                      </span>
                                      <input
                                        type="text"
                                        className="quntity-input"
                                        defaultValue={2}
                                      />
                                      <span className="quantity-btn">
                                        <i
                                          data-feather="minus-circle"
                                          className="feather-search"
                                        />
                                      </span>
                                    </div>
                                  </td>
                                  <td>3000</td>
                                  <td>400</td>
                                  <td>0.00</td>
                                  <td>0.00</td>
                                  <td>0.00</td>
                                  <td>1700</td>
                                </tr>
                                <tr>
                                  <td>
                                    <div className="productimgname">
                                      <Link
                                        to="#"
                                        className="product-img stock-img"
                                      >
                                        <ImageWithBasePath
                                          src="assets/img/products/stock-img-05.png"
                                          alt="product"
                                        />
                                      </Link>
                                      <Link to="#">Lobar Handy</Link>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="product-quantity">
                                      <span className="quantity-btn">
                                        +
                                        <i
                                          data-feather="plus-circle"
                                          className="plus-circle"
                                        />
                                      </span>
                                      <input
                                        type="text"
                                        className="quntity-input"
                                        defaultValue={2}
                                      />
                                      <span className="quantity-btn">
                                        <i
                                          data-feather="minus-circle"
                                          className="feather-search"
                                        />
                                      </span>
                                    </div>
                                  </td>
                                  <td>2500</td>
                                  <td>500</td>
                                  <td>0.00</td>
                                  <td>0.00</td>
                                  <td>0.00</td>
                                  <td>2000</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="row">
                          <div className="row">
                            <div className="col-lg-6 ms-auto">
                              <div className="total-order w-100 max-widthauto m-auto mb-4">
                                <ul>
                                  <li>
                                    <h4>Order Tax</h4>
                                    <h5>$ 0.00</h5>
                                  </li>
                                  <li>
                                    <h4>Discount</h4>
                                    <h5>$ 0.00</h5>
                                  </li>
                                  <li>
                                    <h4>Grand Total</h4>
                                    <h5>$ 5200.00</h5>
                                  </li>
                                  <li>
                                    <h4>Paid</h4>
                                    <h5>$ 5200.00</h5>
                                  </li>
                                  <li>
                                    <h4>Due</h4>
                                    <h5>$ 0.00</h5>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /details popup */}
        {/* edit popup */}
        <div className="modal fade" id="edit-salesReturn-new">
          <div className="modal-dialog edit-salesReturn-modal">
            <div className="modal-content">
              <div className="page-wrapper p-0 m-0">
                <div className="content p-0">
                  <div className="page-header p-4 mb-0">
                    <div className="add-item new-sale-items d-flex">
                      <div className="page-title">
                        <h4>Edit SalesReturn</h4>
                      </div>
                      <button
                        type="button"
                        className="close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-body">
                      <form>
                        <div className="row">
                          <div className="col-lg-4 col-sm-6 col-12">
                            <div className="input-blocks">
                              <label>Customer</label>
                              <div className="row">
                                <div className="col-lg-10 col-sm-10 col-10">
                                  <Select
                                    className="select"
                                    options={customer}
                                    placeholder="Newest"
                                  />
                                </div>
                                <div className="col-lg-2 col-sm-2 col-2 ps-0">
                                  <div className="add-icon">
                                    <Link to="#" className="choose-add">
                                      <PlusCircle className="plus" />
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4 col-sm-6 col-12">
                            <div className="input-blocks">
                              <label>Purchase Date</label>
                              <div className="input-groupicon calender-input">
                                <DatePicker
                                  selected={selectedDate}
                                  onChange={handleDateChange}
                                  type="date"
                                  className="filterdatepicker"
                                  dateFormat="dd-MM-yyyy"
                                  placeholder="Choose Date"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4 col-sm-6 col-12">
                            <div className="input-blocks">
                              <label>Supplier</label>
                              <input className="p-2" />
                            </div>
                          </div>
                          <div className="col-lg-12 col-sm-6 col-12">
                            <div className="input-blocks">
                              <label>Product Name</label>
                              <div className="input-groupicon select-code">
                                <input
                                  type="text"
                                  placeholder="Please type product name and select"
                                />
                                <div className="addonset">
                                  <ImageWithBasePath
                                    src="assets/img/icons/scanners.svg"
                                    alt="img"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="table-responsive no-pagination">
                          <table className="table  datanew">
                            <thead>
                              <tr>
                                <th>Product</th>
                                <th>Qty</th>
                                <th>Price($)</th>
                                <th>Discount($)</th>
                                <th>Tax(%)</th>
                                <th>Tax Amount($)</th>
                                <th>Unit Cost($)</th>
                                <th>Total Cost(%)</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <div className="productimgname">
                                    <Link
                                      to="#"
                                      className="product-img stock-img"
                                    >
                                      <ImageWithBasePath
                                        src="assets/img/products/stock-img-02.png"
                                        alt="product"
                                      />
                                    </Link>
                                    <Link to="#">Nike Jordan</Link>
                                  </div>
                                </td>
                                <td>
                                  <div className="product-quantity">
                                    <span className="quantity-btn">
                                      +
                                      <i
                                        data-feather="plus-circle"
                                        className="plus-circle"
                                      />
                                    </span>
                                    <input
                                      type="text"
                                      className="quntity-input"
                                      defaultValue={2}
                                    />
                                    <span className="quantity-btn">
                                      <i
                                        data-feather="minus-circle"
                                        className="feather-search"
                                      />
                                    </span>
                                  </div>
                                </td>
                                <td>2000</td>
                                <td>500</td>
                                <td>0.00</td>
                                <td>0.00</td>
                                <td>0.00</td>
                                <td>1500</td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="productimgname">
                                    <Link
                                      to="#"
                                      className="product-img stock-img"
                                    >
                                      <ImageWithBasePath
                                        src="assets/img/products/stock-img-03.png"
                                        alt="product"
                                      />
                                    </Link>
                                    <Link to="#">Apple Series 5 Watch</Link>
                                  </div>
                                </td>
                                <td>
                                  <div className="product-quantity">
                                    <span className="quantity-btn">
                                      +
                                      <i
                                        data-feather="plus-circle"
                                        className="plus-circle"
                                      />
                                    </span>
                                    <input
                                      type="text"
                                      className="quntity-input"
                                      defaultValue={2}
                                    />
                                    <span className="quantity-btn">
                                      <i
                                        data-feather="minus-circle"
                                        className="feather-search"
                                      />
                                    </span>
                                  </div>
                                </td>
                                <td>3000</td>
                                <td>400</td>
                                <td>0.00</td>
                                <td>0.00</td>
                                <td>0.00</td>
                                <td>1700</td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="productimgname">
                                    <Link
                                      to="#"
                                      className="product-img stock-img"
                                    >
                                      <ImageWithBasePath
                                        src="assets/img/products/stock-img-05.png"
                                        alt="product"
                                      />
                                    </Link>
                                    <Link to="#">Lobar Handy</Link>
                                  </div>
                                </td>
                                <td>
                                  <div className="product-quantity">
                                    <span className="quantity-btn">
                                      +
                                      <i
                                        data-feather="plus-circle"
                                        className="plus-circle"
                                      />
                                    </span>
                                    <input
                                      type="text"
                                      className="quntity-input"
                                      defaultValue={2}
                                    />
                                    <span className="quantity-btn">
                                      <i
                                        data-feather="minus-circle"
                                        className="feather-search"
                                      />
                                    </span>
                                  </div>
                                </td>
                                <td>2500</td>
                                <td>500</td>
                                <td>0.00</td>
                                <td>0.00</td>
                                <td>0.00</td>
                                <td>2000</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="row">
                          <div className="col-lg-6 ms-auto">
                            <div className="total-order w-100 max-widthauto m-auto mb-4">
                              <ul>
                                <li>
                                  <h4>Order Tax</h4>
                                  <h5>$ 0.00</h5>
                                </li>
                                <li>
                                  <h4>Discount</h4>
                                  <h5>$ 0.00</h5>
                                </li>
                                <li>
                                  <h4>Shipping</h4>
                                  <h5>$ 0.00</h5>
                                </li>
                                <li>
                                  <h4>Grand Total</h4>
                                  <h5>$5200.00</h5>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-3 col-sm-6 col-12">
                            <div className="input-blocks">
                              <label>Order Tax(%)</label>
                              <div className="input-groupicon select-code">
                                <input type="text" placeholder={0} />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3 col-sm-6 col-12">
                            <div className="input-blocks">
                              <label>Discount</label>
                              <div className="input-groupicon select-code">
                                <input type="number" placeholder={0} />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3 col-sm-6 col-12">
                            <div className="input-blocks">
                              <label>Shipping(₹)</label>
                              <div className="input-groupicon select-code">
                                <input type="text" placeholder={0} />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3 col-sm-6 col-12">
                            <div className="input-blocks mb-5">
                              <label>Status</label>
                              <Select
                                className="select"
                                options={statusupdate}
                                placeholder="Newest"
                              />
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="input-blocks">
                              <label>Notes</label>
                              <textarea
                                className="form-control"
                                defaultValue={""}
                              />
                            </div>
                          </div>
                          <div className="col-lg-12 text-end">
                            <button
                              type="button"
                              className="btn btn-cancel add-cancel me-3"
                              data-bs-dismiss="modal"
                            >
                              Cancel
                            </button>
                            <Link to="#" className="btn btn-submit add-sale">
                              Submit
                            </Link>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /edit popup */}
        {/* show payment Modal */}
        <div
          className="modal fade"
          id="showpayment"
          tabIndex={-1}
          aria-labelledby="showpayment"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered stock-adjust-modal">
            <div className="modal-content">
              <div className="page-wrapper-new p-0">
                <div className="content">
                  <div className="modal-header border-0 custom-modal-header">
                    <div className="page-title">
                      <h4>Show Payments</h4>
                    </div>
                    <button
                      type="button"
                      className="close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body custom-modal-body">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="modal-body-table total-orders">
                          <div className="table-responsive">
                            <table className="table  datanew">
                              <thead>
                                <tr>
                                  <th>Date</th>
                                  <th>Invoice</th>
                                  <th>Amount</th>
                                  <th>Paid By</th>
                                  <th className="no-sort">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>19 Jan 2023</td>
                                  <td>INV/SL0101</td>
                                  <td>$1500</td>
                                  <td>Cash</td>
                                  <td className="action-table-data">
                                    <div className="edit-delete-action">
                                      <Link className="me-3 p-2" to="#">
                                        <i
                                          data-feather="printer"
                                          className="feather-rotate-ccw"
                                        />
                                      </Link>
                                      <Link
                                        className="me-3 p-2"
                                        to="#"
                                        data-bs-toggle="modal"
                                        data-bs-target="#editpayment"
                                      >
                                        <i
                                          data-feather="edit"
                                          className="feather-edit"
                                        />
                                      </Link>
                                      <Link className="confirm-text p-2" to="#">
                                        <i
                                          data-feather="trash-2"
                                          className="feather-trash-2"
                                        />
                                      </Link>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* show payment Modal */}
        {/* Create payment Modal */}
        <div
          className="modal fade"
          id="createpayment"
          tabIndex={-1}
          aria-labelledby="createpayment"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header border-0 custom-modal-header">
                <div className="page-title">
                  <h4>Create Payments</h4>
                </div>
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body custom-modal-body">
                <form>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="input-blocks">
                        <label> Date</label>
                        <div className="input-groupicon calender-input">
                          <DatePicker
                            selected={selectedDate}
                            onChange={handleDateChange}
                            type="date"
                            className="filterdatepicker"
                            dateFormat="dd-MM-yyyy"
                            placeholder="Choose Date"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-12 col-12">
                      <div className="input-blocks">
                        <label>Invoice</label>
                        <input type="text" className="form-control" />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-4 col-sm-12 col-12">
                      <div className="input-blocks">
                        <label>Received Amount</label>
                        <div className="input-groupicon calender-input">
                          <i data-feather="dollar-sign" className="info-img" />
                          <input type="text" />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-12 col-12">
                      <div className="input-blocks">
                        <label>Paying Amount</label>
                        <div className="input-groupicon calender-input">
                          <i data-feather="dollar-sign" className="info-img" />
                          <input type="text" />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-12 col-12">
                      <div className="input-blocks">
                        <label>Payment type</label>

                        <Select
                          className="select"
                          options={paymenttype}
                          placeholder="Newest"
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="input-blocks">
                        <label>Description</label>
                        <textarea className="form-control" defaultValue={""} />
                        <p>Maximum 60 Characters</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="modal-footer-btn">
                      <button
                        type="button"
                        className="btn btn-cancel me-2"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </button>
                      <Link to="#" className="btn btn-submit">
                        Submit
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* Create payment Modal */}
        {/* edit payment Modal */}
        <div
          className="modal fade"
          id="editpayment"
          tabIndex={-1}
          aria-labelledby="editpayment"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header border-0 custom-modal-header">
                <div className="page-title">
                  <h4>Edit Payments</h4>
                </div>
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body custom-modal-body">
                <form>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="input-blocks">
                        <label>19 Jan 2023</label>
                        <div className="input-groupicon calender-input">
                          <i data-feather="calendar" className="info-img" />
                          <input
                            type="text"
                            className="datetimepicker form-control"
                            placeholder="Select Date"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-12 col-12">
                      <div className="input-blocks">
                        <label>Invoice</label>
                        <input type="text" defaultValue="INV/SL0101" />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-4 col-sm-12 col-12">
                      <div className="input-blocks">
                        <label>Received Amount</label>
                        <div className="input-groupicon calender-input">
                          <i data-feather="dollar-sign" className="info-img" />
                          <input type="text" defaultValue={1500} />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-12 col-12">
                      <div className="input-blocks">
                        <label>Paying Amount</label>
                        <div className="input-groupicon calender-input">
                          <i data-feather="dollar-sign" className="info-img" />
                          <input type="text" defaultValue={1500} />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-12 col-12">
                      <div className="input-blocks">
                        <label>Payment type</label>
                        <select className="select">
                          <option>Cash</option>
                          <option>Online</option>
                          <option>Inprogress</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="input-blocks summer-description-box transfer">
                        <label>Description</label>
                        <textarea className="form-control" defaultValue={""} />
                      </div>
                      <p>Maximum 60 Characters</p>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="modal-footer-btn mb-3 me-3">
                      <button
                        type="button"
                        className="btn btn-cancel me-2"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </button>
                      <button type="submit" className="btn btn-submit">
                        Save Changes
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* edit payment Modal */}
        {/* <div className="customizer-links" id="setdata">
                    <ul className="sticky-sidebar">
                        <li className="sidebar-icons">
                            <Link
                                to="#"
                                className="navigation-add"
                                data-bs-toggle="tooltip"
                                data-bs-placement="left"
                                data-bs-original-title="Theme"
                            >
                                <i data-feather="settings" className="feather-five" />
                            </Link>
                        </li>
                    </ul>
                </div> */}
      </>
      <CustomerModal from={"Sales Return"} target="#add-salesReturn-new" />
      <SupplierModal from={"Sales Return"} target="#add-salesReturn-new" />

      <Modal
        className={`${CSS.printPreviewModal}`}
        open={showPdfModal}
        footer={false}
        onCancel={() => {
          setShowPdfModal(false);
        }}
      >
        <div className={`${CSS.modalBox}`}>
          <PrintPreview>
            <PrintLayout data={pdfData} />
          </PrintPreview>
        </div>
      </Modal>

      <TransportModal
        showTransportModal={showTransportModal}
        setShowTransportModal={setShowTransportModal}
        submitData={submitData}
        invoiceNumber={salesReturnInvoiceNumber}
        from={"Sale Return"}
        target="#add-salesReturn-new"
        data={{
          storeId: selected_store[0]?._id,
          userCode: login_data?.empCode,
        }}
      />
    </div>
  );
};

export default SalesReturnReturnList;
