/* eslint-disable no-constant-condition */
/* eslint-disable no-empty */
/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from "react";
import { Route, Routes } from "react-router-dom";
import Header from "../InitialPage/Sidebar/Header";
import Sidebar from "../InitialPage/Sidebar/Sidebar";
import { pagesRoute, publicRoutes } from "./router.link";
import { Outlet, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ThemeSettings from "../InitialPage/themeSettings";
// import CollapsedSidebar from "../InitialPage/Sidebar/collapsedSidebar";
import Loader from "../feature-module/loader/loader";
import AlertBox from "../common/AlertBox";
import { fetchAll, fetchCategoryData } from "../utils/utilities";

const AllRoutes = () => {
  const data = useSelector((state) => state.toggle_header);
  const login_data = useSelector((state) => state.login_data);
  const loader_data = useSelector((state) => state.loader_data);
  const category_data = useSelector((state) => state.category_data);
  const [isLoading, setIsLoading] = useState(true);
  const selected_store = useSelector((state) => state.selected_store);
  const navigate = useNavigate();

  useEffect(() => {
    window.addEventListener("popstate", browserBackButtonHandler);
    checkLogin();
  }, []);

  function checkLogin() {
    if (
      login_data == null ||
      login_data == undefined ||
      !Object.keys(login_data).length
    ) {
      navigate("/signin");
      setIsLoading(false);
    } else {
      if (!selected_store?.length) {
        setIsLoading(false);
        navigate("/store-list");
      } else {
        setIsLoading(false);
        navigate(window.location.pathname);
      }
    }
  }

  function browserBackButtonHandler() {
    setIsLoading(true);
    checkLogin();
  }

  // const layoutStyles = useSelector((state) => state.layoutstyledata);
  const HeaderLayout = () => (
    <div className={`main-wrapper ${data ? "header-collapse" : ""}`}>
      <Header />
      <Sidebar />
      <Outlet />
      <ThemeSettings />
      <Loader />
    </div>
  );

  const MemoizedHeader = useMemo(() => HeaderLayout());

  const Authpages = () => (
    <div className={data ? "header-collapse" : ""}>
      <Outlet />
      <Loader />
      <ThemeSettings />
    </div>
  );

  return (
    <div>
      <AlertBox />
      <Routes>
        {!isLoading ? (
          <Route path={"/"} element={MemoizedHeader}>
            {publicRoutes.map((route, id) => (
              <Route path={route.path} element={route.element} key={id} />
            ))}
          </Route>
        ) : (
          <></>
        )}

        <Route path={"/"} element={<Authpages />}>
          {pagesRoute.map((route, id) => (
            <Route path={route.path} element={route.element} key={id} />
          ))}
        </Route>
      </Routes>
      {isLoading || loader_data.payload ? (
        <div id="global-loader">
          <div className="whirly-loader"></div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
export default AllRoutes;
