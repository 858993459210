/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { all_routes } from "../../Router/all_routes";
import AddCategory from "../../core/modals/inventory/addcategory";
import AddBrand from "../../core/modals/addbrand";
// import AlertBox from "../../common/AlertBox";

import {
  ArrowLeft,
  ChevronUp,
  Info,
  LifeBuoy,
  List,
  PlusCircle,
  // Trash2,
  // X,
} from "feather-icons-react/build/IconComponents";
import { useDispatch, useSelector } from "react-redux";
import { alertData, setToogleHeader } from "../../core/redux/action";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import axios from "axios";
import { API_URL } from "../../constants/AppConstants";
import AddWarehouseModal from "../../core/modals/peoples/AddWarehouseModal";
import AddSubcategory from "../../core/modals/inventory/addsubcategory";
import {
  fetchBrandsData,
  fetchCategoryData,
  fetchProductsData,
  fetchStocksData,
  fetchSubCategoryData,
  fetchWarehousesData,
} from "../../utils/utilities";
// import ImageWithBasePath from "../../core/img/imagewithbasebath";

const AddProduct = () => {
  const route = all_routes;
  const dispatch = useDispatch();
  const login_data = useSelector((state) => state.login_data);
  const data = useSelector((state) => state.toggle_header);
  const warehouse_data = useSelector((state) => state.warehouse_data);
  const category_data = useSelector((state) => state.category_data);
  const subcategory_data = useSelector((state) => state.subcategory_data);
  const brand_data = useSelector((state) => state.brand_data);
  const selected_store = useSelector((state) => state.selected_store);
  const unit_data = useSelector((state) => state.unit_data);

  const [productStore, setProductStore] = useState(selected_store);

  const [productWarehouse, setProductWarehouse] = useState({});
  const [productName, setProductName] = useState("");
  const [productSKU, setProductSKU] = useState("");
  const [productCategory, setProductCategory] = useState({
    label: "Choose",
    value: null,
  });
  const [productSubCategory, setProductSubCategory] = useState("");
  const [productSubCategoryDropdown, setProductSubCategoryDropdown] = useState({
    label: "Choose",
    value: null,
  });
  const [productBrand, setProductBrand] = useState("");
  const [productUnit, setProductUnit] = useState("");
  const [productSellingType, setProductSellingType] = useState("");
  const [productItemCode, setProductItemCode] = useState("");
  const [productDescription, setProductDescription] = useState("");

  const [productTaxType, setProductTaxType] = useState("");
  const [productTaxValue, setProductTaxValue] = useState("");
  const [productDiscountType, setProductDiscountType] = useState({
    label: "Choose",
    value: null,
  });
  const [productDiscountValue, setProductDiscountValue] = useState("");
  const [productClubbedUnit, setProductClubbedUnit] = useState("");
  const [productAlternateQty, setProductAlternateQty] = useState("");
  const [productQtyAlert, setProductQtyAlert] = useState("");

  const [productMfgDate, setProductMfgDate] = useState(new Date());
  const [productExpiryDate, setProductExpiryDate] = useState(new Date());

  const [isLoading, setIsLoading] = useState(false);

  const [categoryArray, setCategoryArray] = useState(category_data);
  const [subCategoryArray, setSubCategoryArray] = useState([
    {
      label: "Choose",
      value: null,
    },
  ]);
  const [brandsArray, setBrandsArray] = useState(brand_data);
  const [warehousesArray, setWarehousesArray] = useState(warehouse_data);

  useEffect(() => {
    fetchWarehousesData(login_data, selected_store[0]);
    fetchCategoryData(login_data, selected_store[0]);
    fetchSubCategoryData(login_data, selected_store[0]);
    fetchBrandsData(login_data, selected_store[0]);
  }, [selected_store]);

  useEffect(() => {
    setCategoryArray(
      category_data.filter((data) => data.categoryStatus == true)
    );
    setBrandsArray(brand_data.filter((data) => data.brandStatus == true));
    setWarehousesArray(warehouse_data);
  }, [
    selected_store,
    category_data,
    subcategory_data,
    brand_data,
    warehouse_data,
  ]);

  const handleDateChange = (date) => {
    setProductMfgDate(date);
  };

  const handleDateChange1 = (date) => {
    setProductExpiryDate(date);
  };

  const renderCollapseTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Collapse
    </Tooltip>
  );

  const sellingtype = [
    { value: null, label: "Choose" },
    { value: "Transactional Selling", label: "Transactional selling" },
    { value: "Solution Selling", label: "Solution selling" },
  ];

  const taxtype = [
    { value: "Exclusive", label: "Exclusive" },
    { value: "Inclusive", label: "Inclusive" },
    { value: "Sales Tax", label: "Sales Tax" },
  ];
  const discounttype = [
    { value: "Percentage", label: "Percentage" },
    { value: "Cash", label: "Cash" },
  ];

  useEffect(() => {
    let filteredArr = [
      {
        label: "Choose",
        value: null,
      },
    ];

    subcategory_data?.forEach((item) => {
      if (
        productCategory?.value == item?.parentCategory?.id &&
        item?.subCategoryStatus
      ) {
        filteredArr.push({
          label: item.subCategoryName,
          value: item._id,
        });
      }
    });

    setSubCategoryArray(filteredArr);
    setProductSubCategory({
      label: "Choose",
      value: null,
    });
    const newval = generateRandomAlphaNumeric();
    setProductItemCode(newval);
  }, [productCategory, subcategory_data]);

  useEffect(() => {
    if (productName) {
      setProductSKU(
        `${productName?.slice(0, 4).replaceAll(" ", "")}/${
          productCategory
            ? productCategory.label.slice(0, 4).replaceAll(" ", "")
            : "cat"
        }/${
          productBrand
            ? productBrand.label.slice(0, 4).replaceAll(" ", "")
            : "brnd"
        }`
      );
    }
  }, [productName, productCategory, productBrand]);

  function clearData() {
    setProductWarehouse({
      label: "Choose",
      value: null,
    });
    setProductName("");
    setProductSKU("");
    setProductCategory({
      label: "Choose",
      value: null,
    });
    setProductSubCategory({
      label: "Choose",
      value: null,
    });
    setProductBrand({
      label: "Choose",
      value: null,
    });
    setProductSellingType({
      label: "Choose",
      value: null,
    });
    setProductItemCode("");
    setProductDescription("");
    setProductTaxType({
      label: "Choose",
      value: null,
    });
    setProductTaxValue("");
    setProductDiscountType({
      label: "Choose",
      value: null,
    });
    setProductDiscountValue("");
    setProductUnit({
      label: "Choose",
      value: null,
    });
    setProductClubbedUnit({
      label: "Choose",
      value: null,
    });
    setProductAlternateQty("");
    setProductQtyAlert("");
  }

  async function submitData() {
    setIsLoading(true);
    // if (productTaxType && !productTaxValue) {
    //   dispatch(
    //     alertData({
    //       type: "error",
    //       msg: "Please fill the Tax Value",
    //     })
    //   );
    //   setIsLoading(false);
    //   return;
    // }

    // if (productDiscountType && !productDiscountValue) {
    //   dispatch(
    //     alertData({
    //       type: "error",
    //       msg: "Please fill the Discount Value",
    //     })
    //   );
    //   setIsLoading(false);
    //   return;
    // }

    if (
      !productName ||
      !productCategory ||
      !productBrand ||
      !productSellingType ||
      !productItemCode ||
      !productUnit ||
      !productClubbedUnit ||
      !productAlternateQty ||
      !productQtyAlert
    ) {
      dispatch(
        alertData({
          type: "error",
          msg: "Please fill the required fields",
        })
      );
      setIsLoading(false);
      return;
    }

    let dataBody = {
      productStore,
      productWarehouse,
      productName,
      productSKU,
      productCategory,
      productSubCategory,
      productBrand,
      productUnit,
      productSellingType,
      productItemCode,
      productDescription,
      productTaxType,
      productTaxValue,
      productDiscountType,
      productDiscountValue,
      productClubbedUnit,
      productAlternateQty: productAlternateQty,
      productQtyAlert,
      productMfgDate: new Date(productMfgDate)
        .toLocaleDateString()
        .split(",")[0],
      productExpiryDate: new Date(productExpiryDate)
        .toLocaleDateString()
        .split(",")[0],
      storeId: selected_store[0]?._id,
      createdOn: new Date().toLocaleDateString().split(",")[0],
      userCode: login_data?.empCode,
      itemActive: true,
    };

    let stockDataBody = {
      id: Number(new Date().getTime()) + 1000,
      productStore,
      productWarehouse,
      productName,
      productSKU,
      productUnit,
      productCurrentQty: 0,
      productClubbedUnit,
      productQtyAlert,
      productCurrentAlternateQty: 0,
      productAlternateQty: productAlternateQty,
      productItemCode,
      storeId: selected_store[0]?._id,
      stockStatus: "InActive",
      createdOn: new Date().toLocaleDateString().split(",")[0],
      userCode: login_data?.empCode,
      itemActive: true,
    };

    try {
      let response = await axios.post(API_URL + "products", dataBody);
      if (response.status === 200) {
        dispatch(
          alertData({
            type: "success",
            msg: "Product added successfully!",
          })
        );
        fetchProductsData(login_data, selected_store[0]);
        clearData();

        setTimeout(async () => {
          let response2 = await axios.post(API_URL + "stocks", {
            ...stockDataBody,
            productId: response.data._id,
          });
          if (response2.status === 200) {
            dispatch(
              alertData({
                type: "success",
                msg: "Stock added successfully!",
              })
            );
            setIsLoading(false);
            fetchStocksData(login_data, selected_store[0]);
          }
        }, 2500);
      }
    } catch (err) {
      console.log(err);
    }
  }

  function generateRandomAlphaNumeric() {
    const length = 6;
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let result = "";

    for (let i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }

    return result;
  }

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="add-item d-flex">
            <div className="page-title">
              <h4>New Product</h4>
              <h6>Create new product</h6>
            </div>
          </div>
          <ul className="table-top-head">
            <li>
              <div className="page-btn">
                <Link to={route.productlist} className="btn btn-secondary">
                  <ArrowLeft className="me-2" />
                  Back to Product
                </Link>
              </div>
            </li>
            <li>
              <OverlayTrigger placement="top" overlay={renderCollapseTooltip}>
                <Link
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Collapse"
                  id="collapse-header"
                  className={data ? "active" : ""}
                  onClick={() => {
                    dispatch(setToogleHeader(!data));
                  }}
                >
                  <ChevronUp className="feather-chevron-up" />
                </Link>
              </OverlayTrigger>
            </li>
          </ul>
        </div>
        {/* /add */}
        <form>
          <div className="card">
            <div className="card-body add-product pb-0">
              <div
                className="accordion-card-one accordion"
                id="accordionExample"
              >
                <div className="accordion-item">
                  <div className="accordion-header" id="headingOne">
                    <div
                      className="accordion-button"
                      // data-bs-toggle="collapse"
                      // data-bs-target="#collapseOne"
                      aria-controls="collapseOne"
                    >
                      <div className="addproduct-icon">
                        <h5>
                          <Info className="add-info" />

                          <span>Product Information</span>
                        </h5>
                        <span style={{ fontStyle: "italic" }}>
                          All fields with{" "}
                          <span style={{ color: "red" }}>*</span> are required
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <div className="row">
                        <div className="col-lg-6 col-sm-6 col-12">
                          <div className="mb-3 add-product">
                            <label className="form-label">Store</label>
                            <input
                              disabled
                              type="text"
                              className="form-control"
                              value={selected_store[0]?.storeName}
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-sm-6 col-12">
                          <div className="mb-3 add-product">
                            <label className="form-label">
                              Warehouse<span style={{ color: "red" }}>*</span>
                            </label>
                            <div className="col-lg-12 col-sm-12 col-12 d-flex align-items-center justify-content-between flex-wrap">
                              <div style={{ width: "92%" }}>
                                <Select
                                  value={
                                    productWarehouse?.value
                                      ? productWarehouse
                                      : [
                                          {
                                            label: "Choose",
                                            value: null,
                                          },
                                        ]
                                  }
                                  className="select"
                                  options={warehousesArray?.map((item) => {
                                    return {
                                      label: item.name,
                                      value: item._id,
                                    };
                                  })}
                                  placeholder="Choose"
                                  onChange={(value) =>
                                    setProductWarehouse(value)
                                  }
                                />
                              </div>
                              <div
                                className="add-icon d-flex justify-content-end"
                                style={{ width: "8%" }}
                              >
                                <Link
                                  data-bs-toggle="modal"
                                  data-bs-target={`#add-warehouse`}
                                  onClick={(e) => e.preventDefault()}
                                  className="choose-add"
                                >
                                  <PlusCircle className="plus" />
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6 col-sm-6 col-12">
                          <div className="mb-3 add-product">
                            <label className="form-label">
                              Product Name{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={productName}
                              onChange={(e) => setProductName(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-sm-6 col-12">
                          <div className="input-blocks add-product list">
                            <label>SKU</label>
                            <input
                              disabled
                              readOnly
                              type="text"
                              className="form-control list"
                              placeholder="Enter SKU"
                              value={productSKU}
                              onChange={(e) => setProductSKU(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="addservice-info">
                        <div className="row">
                          <div className="col-lg-6 col-sm-6 col-12">
                            <div className="mb-3 add-product">
                              <div className="add-newplus">
                                <label className="form-label">
                                  Category{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                              </div>
                              <div className="col-lg-12 col-sm-12 col-12 d-flex align-items-center justify-content-between flex-wrap">
                                <div style={{ width: "92%" }}>
                                  <Select
                                    value={
                                      productCategory?.value
                                        ? productCategory
                                        : [
                                            {
                                              label: "Choose",
                                              value: null,
                                            },
                                          ]
                                    }
                                    className="select"
                                    options={categoryArray?.map((item) => {
                                      return {
                                        label: item.categoryName,
                                        value: item._id,
                                      };
                                    })}
                                    placeholder="Choose"
                                    onChange={(value) => {
                                      setProductCategory(value);
                                    }}
                                  />
                                </div>
                                <div
                                  className="add-icon d-flex justify-content-end"
                                  style={{ width: "8%" }}
                                >
                                  <Link
                                    data-bs-toggle="modal"
                                    data-bs-target={`#add-category`}
                                    onClick={(e) => e.preventDefault()}
                                    className="choose-add"
                                  >
                                    <PlusCircle className="plus" />
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 col-sm-6 col-12">
                            <div className="mb-3 add-product">
                              <label className="form-label">Sub Category</label>
                              <div className="col-lg-12 col-sm-12 col-12 d-flex align-items-center justify-content-between flex-wrap">
                                <div style={{ width: "92%" }}>
                                  <Select
                                    value={
                                      productSubCategory?.value
                                        ? productSubCategory
                                        : [
                                            {
                                              label: "Choose",
                                              value: null,
                                            },
                                          ]
                                    }
                                    className="select sub-category-dropdown"
                                    options={
                                      subCategoryArray.length == 1
                                        ? subCategoryArray?.map((item) => {
                                            return {
                                              label: item.label,
                                              value: item.value,
                                            };
                                          })
                                        : subCategoryArray?.map((item) => {
                                            return {
                                              label: item.label,
                                              value: item.value,
                                            };
                                          })
                                    }
                                    placeholder="Choose"
                                    onChange={(value) => {
                                      setProductSubCategory(value);
                                    }}
                                  />
                                </div>
                                <div
                                  className="add-icon d-flex justify-content-end"
                                  style={{ width: "8%" }}
                                >
                                  <Link
                                    data-bs-toggle="modal"
                                    data-bs-target={`#add-subcategory`}
                                    onClick={(e) => e.preventDefault()}
                                    className="choose-add"
                                  >
                                    <PlusCircle className="plus" />
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="add-product-new">
                        <div className="row">
                          <div className="col-lg-6 col-sm-6 col-12">
                            <div className="mb-3 add-product">
                              <div className="add-newplus">
                                <label className="form-label">
                                  Brand<span style={{ color: "red" }}>*</span>
                                </label>
                              </div>
                              <div className="col-lg-12 col-sm-12 col-12 d-flex align-items-center justify-content-between flex-wrap">
                                <div style={{ width: "92%" }}>
                                  <Select
                                    value={
                                      productBrand?.value
                                        ? productBrand
                                        : [
                                            {
                                              label: "Choose",
                                              value: null,
                                            },
                                          ]
                                    }
                                    className="select"
                                    options={brandsArray?.map((item) => {
                                      return {
                                        label: item.brandName,
                                        value: item._id,
                                      };
                                    })}
                                    placeholder="Choose"
                                    onChange={(value) => setProductBrand(value)}
                                  />
                                </div>
                                <div
                                  className="add-icon d-flex justify-content-end"
                                  style={{ width: "8%" }}
                                >
                                  <Link
                                    data-bs-toggle="modal"
                                    data-bs-target={`#add-brand`}
                                    onClick={(e) => e.preventDefault()}
                                    className="choose-add"
                                  >
                                    <PlusCircle className="plus" />
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 col-sm-6 col-12">
                            <div className="mb-3 add-product">
                              <label className="form-label">
                                Selling Type{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <Select
                                value={
                                  productSellingType?.value
                                    ? productSellingType
                                    : [
                                        {
                                          label: "Choose",
                                          value: null,
                                        },
                                      ]
                                }
                                className="select"
                                options={sellingtype}
                                placeholder="Choose"
                                onChange={(value) =>
                                  setProductSellingType(value)
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-4 col-sm-6 col-12">
                          <div className="input-blocks add-product list">
                            <label>
                              Item Code<span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              disabled
                              value={productItemCode}
                              type="text"
                              className="form-control list"
                              placeholder="Please Enter Item Code"
                            />
                            <span>
                              Allowed maximum of 12 characters, which only
                              include numbers and alphabets.
                            </span>
                          </div>
                        </div>
                      </div>
                      {/* Editor */}
                      <div className="col-lg-12">
                        <div className="input-blocks summer-description-box transfer mb-3">
                          <label>Description</label>
                          <textarea
                            className="form-control h-100"
                            rows={4}
                            maxLength={1000}
                            defaultValue={""}
                            value={productDescription}
                            onChange={(e) =>
                              setProductDescription(e.target.value)
                            }
                          />
                          <p className="mt-1">Maximum 1000 Characters</p>
                        </div>
                      </div>
                      {/* /Editor */}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="accordion-card-one accordion"
                id="accordionExample2"
              >
                <div className="accordion-item">
                  <div className="accordion-header" id="headingTwo">
                    <div
                      className="accordion-button"
                      // data-bs-toggle="collapse"
                      // data-bs-target="#collapseTwo"
                      aria-controls="collapseTwo"
                    >
                      <div className="text-editor add-list">
                        <div className="addproduct-icon list icon">
                          <h5>
                            <LifeBuoy className="add-info" />
                            <span>Pricing &amp; Stocks</span>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse show"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample2"
                  >
                    <div className="accordion-body">
                      <div className="input-blocks add-products">
                        <label className="d-block">Product Type</label>
                        <div className="single-pill-product">
                          <ul
                            className="nav nav-pills"
                            id="pills-tab1"
                            role="tablist"
                          >
                            <li className="nav-item" role="presentation">
                              <span
                                className="custom_radio me-4 mb-0 active"
                                id="pills-home-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-home"
                                role="tab"
                                aria-controls="pills-home"
                                aria-selected="true"
                              >
                                <input
                                  type="radio"
                                  className="form-control"
                                  name="payment"
                                />
                                <span className="checkmark" /> Single Product
                              </span>
                            </li>
                            {/* <li className="nav-item" role="presentation">
                              <span
                                className="custom_radio me-2 mb-0"
                                id="pills-profile-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-profile"
                                role="tab"
                                aria-controls="pills-profile"
                                aria-selected="false"
                              >
                                <input
                                  type="radio"
                                  className="form-control"
                                  name="sign"
                                />
                                <span className="checkmark" /> Variable Product
                              </span>
                            </li> */}
                          </ul>
                        </div>
                      </div>
                      <div className="tab-content" id="pills-tabContent">
                        <div
                          className="tab-pane fade show active"
                          id="pills-home"
                          role="tabpanel"
                          aria-labelledby="pills-home-tab"
                        >
                          <div className="row"></div>
                          <div className="row">
                            <div className="col-lg-4 col-sm-6 col-12">
                              <div className="input-blocks add-product">
                                <label>Tax Type</label>
                                <Select
                                  value={
                                    productTaxType?.value
                                      ? productTaxType
                                      : [
                                          {
                                            label: "Choose",
                                            value: null,
                                          },
                                        ]
                                  }
                                  className="select"
                                  options={taxtype}
                                  placeholder="Select Option"
                                  onChange={(value) => setProductTaxType(value)}
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 col-12">
                              <div className="input-blocks add-product">
                                <label>Tax Value(%)</label>
                                <input
                                  type="number"
                                  min={0}
                                  placeholder="Choose Tax Value"
                                  value={productTaxValue}
                                  onChange={(e) => {
                                    const newValue = e.target.value.replace(
                                      /[^0-9.]/g,
                                      ""
                                    );
                                    if (newValue <= 100) {
                                      setProductTaxValue(newValue);
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-4 col-sm-6 col-12">
                              <div className="input-blocks add-product">
                                <label>Discount Type</label>
                                <Select
                                  value={
                                    productDiscountType?.value
                                      ? productDiscountType
                                      : [
                                          {
                                            label: "Choose",
                                            value: null,
                                          },
                                        ]
                                  }
                                  className="select"
                                  options={discounttype}
                                  placeholder="Choose Discount Type"
                                  onChange={(value) =>
                                    setProductDiscountType(value)
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 col-12">
                              <div className="input-blocks add-product">
                                <label>Discount Value</label>
                                <input
                                  type="number"
                                  min={0}
                                  placeholder="Choose Discount Value"
                                  value={productDiscountValue}
                                  onChange={(e) => {
                                    const newValue = parseFloat(
                                      e.target.value.replace(/[^0-9.]/g, "")
                                    );

                                    if (productDiscountType.value === "Cash") {
                                      if (!isNaN(newValue)) {
                                        setProductDiscountValue(
                                          newValue.toString()
                                        );
                                      } else {
                                        setProductDiscountValue("");
                                      }
                                    } else {
                                      if (newValue >= 0 && newValue <= 100) {
                                        setProductDiscountValue(
                                          newValue.toString()
                                        );
                                      } else if (!newValue) {
                                        setProductDiscountValue("");
                                      }
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-4 col-sm-6 col-12">
                              <div className="mb-3 add-product">
                                <div className="add-newplus">
                                  <label className="form-label">
                                    Main Unit{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                </div>
                                <Select
                                  value={
                                    productUnit?.value
                                      ? productUnit
                                      : [
                                          {
                                            label: "Choose",
                                            value: null,
                                          },
                                        ]
                                  }
                                  className="select"
                                  options={unit_data?.map((item) => {
                                    return {
                                      label: item.unit,
                                      value: item.id,
                                    };
                                  })}
                                  placeholder="Choose Main Unit"
                                  onChange={(value) => setProductUnit(value)}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-4 col-sm-6 col-12">
                              <div className="input-blocks add-product">
                                <label>
                                  Alternate Unit{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <Select
                                  value={
                                    productClubbedUnit?.value
                                      ? productClubbedUnit
                                      : [
                                          {
                                            label: "Choose",
                                            value: null,
                                          },
                                        ]
                                  }
                                  className="select"
                                  options={unit_data?.map((item) => {
                                    return {
                                      label: item.unit,
                                      value: item.id,
                                    };
                                  })}
                                  placeholder="Choose Alternate Unit"
                                  onChange={(value) =>
                                    setProductClubbedUnit(value)
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 col-12">
                              <div className="input-blocks add-product">
                                <label>
                                  Alternate Qty{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <input
                                  type="number"
                                  min={0}
                                  placeholder=""
                                  value={productAlternateQty}
                                  onChange={(e) =>
                                    setProductAlternateQty(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 col-12">
                              <div className="input-blocks add-product">
                                <label>
                                  Low Stock Alert Qty(Main Unit){" "}
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <input
                                  type="number"
                                  min={0}
                                  placeholder=""
                                  value={productQtyAlert}
                                  onChange={(e) =>
                                    setProductQtyAlert(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="btn-addproduct mb-4">
              <button type="button" className="btn btn-cancel me-2">
                Cancel
              </button>
              <div
                className={`btn btn-submit ${isLoading ? "disabled" : ""}`}
                onClick={submitData}
              >
                Save Product
              </div>
            </div>
          </div>
        </form>
        {/* /add */}
      </div>

      <AddCategory />
      <AddSubcategory productCategory={productCategory} from="add-product" />
      <AddBrand />
      <AddWarehouseModal />
    </div>
  );
};

export default AddProduct;
