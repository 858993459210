/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { alertData, subCategoryData } from "../../core/redux/action";
import { API_URL } from "../../constants/AppConstants";
import axios from "axios";
import { fetchSubCategoryData } from "../../utils/utilities";

const EditSubCategories = (props) => {
  const login_data = useSelector((state) => state.login_data);
  const category_data = useSelector((state) => state.category_data);
  const subcategory_data = useSelector((state) => state.subcategory_data);
  const [categories, setCategories] = useState([]);
  const [parentCategory, setParentCategory] = useState({
    label: "Choose",
    value: null,
  });
  const [categoryName, setCategoryName] = useState("");
  const [categoryStatus, setCategoryStatus] = useState(true);
  const selected_store = useSelector((state) => state.selected_store);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const dispatch = useDispatch();
  const detail_data = useSelector((state) => state.detail_data);

  useEffect(() => {
    props.setIsCategoryUpdated(false);
    if (detail_data?.id && subcategory_data) {
      subcategory_data?.forEach((category) => {
        if (category._id == detail_data.id) {
          setSelectedData(category);
        }
      });
    }
  }, [detail_data]);

  useEffect(() => {
    if (selectedData) {
      setParentCategory({
        label: selectedData?.parentCategory?.categoryName,
        value: selectedData?.parentCategory?.id,
      });
      setCategoryName(selectedData?.subCategoryName);
      setCategoryStatus(selectedData?.subCategoryStatus);
    }
  }, [selectedData]);

  useEffect(() => {
    let newArr = [];
    category_data?.forEach((category) => {
      newArr.push({
        value: category._id,
        label: category.categoryName,
      });
    });
    setCategories(newArr);
  }, [category_data]);

  async function submitData() {
    setIsLoading(true);
    let dataBody = {
      parentCategory: {
        id: parentCategory.value,
        categoryName: parentCategory.label,
      },
      subCategoryName: categoryName,
      subCategoryStatus: categoryStatus,
      storeId: selected_store[0]?._id,
      createdOn: selectedData?.createdOn,
      updatedOn: new Date().toLocaleDateString().split(",")[0],
      userCode: login_data?.empCode,
    };

    try {
      let response = await axios.put(
        API_URL + "sub_category/" + selectedData?._id,
        dataBody
      );
      if (response.status === 200) {
        dispatch(
          alertData({
            type: "success",
            msg: "Sub-Category updated successfully!",
          })
        );
        setTimeout(() => {
          setIsLoading(false);
          document?.querySelector("#edit-subcategory .close")?.click();
        }, 1000);
        fetchSubCategoryData(login_data, selected_store[0]);
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  }

  return (
    <div>
      {/* Add Category */}
      <div className="modal fade" id="edit-subcategory">
        <div className="modal-dialog modal-dialog-centered custom-modal-two">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>Edit Sub Category</h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    data-bs-toggle={props.from ? "modal" : ""}
                    data-bs-target={props.from ? `${props.target}` : ""}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <form>
                    <div className="mb-3">
                      <label className="form-label">Parent Category</label>
                      <Select
                        value={
                          parentCategory?.value
                            ? parentCategory
                            : [
                                {
                                  label: "Choose",
                                  value: null,
                                },
                              ]
                        }
                        onChange={(item) => setParentCategory(item)}
                        className="select"
                        options={categories}
                        placeholder="Choose Category"
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Sub Category Name</label>
                      <input
                        type="text"
                        className="form-control"
                        value={categoryName}
                        onChange={(e) => setCategoryName(e.target.value)}
                      />
                    </div>
                    {/* <div className="mb-3">
                      <label className="form-label">Category Code</label>
                      <input type="text" className="form-control" />
                    </div> */}
                    {/* <div className="mb-3 input-blocks">
                      <label className="form-label">Description</label>
                      <textarea className="form-control" defaultValue={""} />
                    </div> */}
                    <div className="mb-0">
                      <div className="status-toggle modal-status d-flex justify-content-between align-items-center">
                        <span className="status-label">Status</span>
                        <input
                          value={categoryStatus}
                          checked={categoryStatus}
                          onChange={() => setCategoryStatus(!categoryStatus)}
                          type="checkbox"
                          id="user2"
                          className="check"
                        />
                        <label htmlFor="user2" className="checktoggle" />
                      </div>
                    </div>
                    <div className="modal-footer-btn">
                      <button
                        type="button"
                        className="btn btn-cancel me-2"
                        data-bs-dismiss="modal"
                        data-bs-toggle={props.from ? "modal" : ""}
                        data-bs-target={props.from ? `${props.target}` : ""}
                      >
                        Cancel
                      </button>
                      <div
                        className={`btn btn-submit ${
                          isLoading ? "disabled" : ""
                        }`}
                        onClick={submitData}
                      >
                        Update Subcategory
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Add Category */}
    </div>
  );
};

export default EditSubCategories;
