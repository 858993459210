/* eslint-disable no-empty */
/* eslint-disable no-unused-vars */
import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import CSS from "./transportmodal.module.scss";
import { useDispatch } from "react-redux";
import { alertData } from "../../core/redux/action";
import { API_URL } from "../../constants/AppConstants";
import axios from "axios";

function transportmodal({
  showTransportModal,
  setShowTransportModal,
  submitData = () => {},
  invoiceNumber,
  from,
  target,
  data,
}) {
  const dispatch = useDispatch();
  const [addLater, setAddLater] = useState(false);
  const [transportName, setTransportName] = useState("");
  const [transportAddress, setTransportAddress] = useState("");
  const [vehicleNumber, setVehicleNumber] = useState("");
  const [deliveryDate, setDeliveryDate] = useState("");
  const [deliveryDriver, setDeliveryDriver] = useState("");
  const [driverContact, setDriverContact] = useState("");
  const [vehicleType, setVehicleType] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (addLater) clearData();
  }, [addLater]);

  function clearData() {
    setTransportName("");
    setTransportAddress("");
    setVehicleNumber("");
    setDeliveryDate("");
    setDeliveryDriver("");
    setDriverContact("");
    setVehicleType("");
  }

  async function submitTransportData() {
    setIsLoading(true);

    if (addLater) {
      submitData(1);
      setShowTransportModal(false);
    } else {
      if (
        !transportName ||
        !transportAddress ||
        !vehicleNumber ||
        !deliveryDate ||
        !deliveryDriver ||
        !driverContact ||
        !vehicleType
      ) {
        dispatch(
          alertData({
            type: "error",
            msg: "Please fill all details!",
          })
        );
      }
      submitData(2);
      let dataBody = {
        transportCompany: transportName,
        transportAddress,
        vehicleNumber,
        expectedDeliveryDate: deliveryDate,
        deliveryBy: deliveryDriver,
        driverContact,
        vehicleType,
        referenceNumber: invoiceNumber,
        storeId: data.storeId,
        userCode: data.userCode,
        itemActive: true,
      };

      try {
        let response = await axios.post(API_URL + "transportation", dataBody);
        if (response.status === 200) {
          clearData();
          dispatch(
            alertData({
              type: "success",
              msg: `Transportation added successfully!`,
            })
          );

          setTimeout(() => {
            setIsLoading(false);
            setShowTransportModal(false);
            clearData();
          }, 1000);
        }
      } catch (err) {
        setIsLoading(false);
        console.log(err);
      }
    }
  }

  return (
    <Modal
      title="Add Transport Details"
      wrapClassName={`${CSS.transportModalWrap}`}
      className={`${CSS.transportModal}`}
      open={showTransportModal}
      footer={false}
      closable={false}
      onCancel={() => {
        setShowTransportModal(false);
      }}
    >
      <div className="d-flex justify-content-center align-items-center mb-20">
        <input
          type="radio"
          name="add-option"
          onChange={() => setAddLater(true)}
        />
        <span style={{ marginLeft: 10, fontSize: 16, fontWeight: 500 }}>
          Add Later
        </span>
        <input
          type="radio"
          name="add-option"
          style={{ marginLeft: 40 }}
          defaultChecked
          onChange={() => setAddLater(false)}
        />
        <span style={{ marginLeft: 10, fontSize: 16, fontWeight: 500 }}>
          Add Now
        </span>
      </div>
      {!addLater ? (
        <>
          <div className="form-group">
            <span className="label">
              Transport Name:<span style={{ color: "red" }}>*</span>
            </span>
            <input
              type="text"
              value={transportName}
              onChange={(e) => setTransportName(e.target.value)}
              className="input-box"
            />
          </div>
          <div className="form-group">
            <span className="label">
              Transport Address:<span style={{ color: "red" }}>*</span>
            </span>
            <input
              type="text"
              value={transportAddress}
              onChange={(e) => setTransportAddress(e.target.value)}
              className="input-box"
            />
          </div>
          <div className="form-group">
            <span className="label">
              Vehicle Type:<span style={{ color: "red" }}>*</span>
            </span>
            <input
              type="text"
              value={vehicleType}
              onChange={(e) => setVehicleType(e.target.value)}
              className="input-box"
            />
          </div>
          <div className="form-group">
            <span className="label">
              Vehicle Number:<span style={{ color: "red" }}>*</span>
            </span>
            <input
              type="text"
              value={vehicleNumber}
              onChange={(e) => setVehicleNumber(e.target.value)}
              className="input-box"
            />
          </div>
          <div className="form-group">
            <span className="label">
              Expected Delivery Date:<span style={{ color: "red" }}>*</span>
            </span>
            <input
              type="date"
              value={deliveryDate}
              onChange={(e) => setDeliveryDate(e.target.value)}
              className="input-box"
            />
          </div>
          <div className="form-group">
            <span className="label">
              Delivery Driver:<span style={{ color: "red" }}>*</span>
            </span>
            <input
              type="text"
              value={deliveryDriver}
              onChange={(e) => setDeliveryDriver(e.target.value)}
              className="input-box"
            />
          </div>
          <div className="form-group">
            <span className="label">
              Driver Contact:<span style={{ color: "red" }}>*</span>
            </span>
            <input
              type="number"
              value={driverContact}
              onChange={(e) => setDriverContact(e.target.value)}
              className="input-box"
            />
          </div>

          <div
            style={{ fontStyle: "italic", padding: "0 20px", marginTop: 20 }}
          >
            All fields with <span style={{ color: "red" }}>*</span> are required
          </div>
        </>
      ) : (
        <></>
      )}
      <div className="d-flex justify-content-end mt-20 mb-10">
        <span
          className="btn btn-cancel"
          data-bs-toggle="modal"
          data-bs-target={`${target}`}
          onClick={() => {
            setShowTransportModal(false);
          }}
        >
          Cancel
        </span>
        <span
          className={`btn btn-submit mr-20 ml-20 ${
            isLoading ? "disabled" : ""
          }`}
          onClick={() => {
            submitTransportData();
          }}
        >
          Submit
        </span>
      </div>
    </Modal>
  );
}

export default transportmodal;
