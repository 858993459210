// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PreviewModal_printPreviewModal__K3y8- {
  overflow-y: auto;
  scrollbar-width: thin;
  width: 1140px !important;
}
.PreviewModal_printPreviewModal__K3y8-::-webkit-scrollbar {
  width: 8px;
}
.PreviewModal_printPreviewModal__K3y8-::-webkit-scrollbar-track {
  display: none;
}
.PreviewModal_printPreviewModal__K3y8-::-webkit-scrollbar-thumb {
  width: 8px;
  background: rgba(255, 255, 255, 0.8);
}
.PreviewModal_printPreviewModal__K3y8- .PreviewModal_modalBox__dPOGL {
  margin: 50px auto;
  background-color: #fff;
  border-radius: 8px;
  border: 0;
  width: 100%;
  padding: 20px 0;
  outline: none;
  scrollbar-width: none;
}
.PreviewModal_printPreviewModal__K3y8- .PreviewModal_modalBox__dPOGL::-webkit-scrollbar {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/core/modals/PreviewModal.module.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,qBAAA;EACA,wBAAA;AACJ;AAAI;EACI,UAAA;AAER;AADQ;EACI,aAAA;AAGZ;AADQ;EACI,UAAA;EACA,oCAAA;AAGZ;AAAI;EACI,iBAAA;EACA,sBAAA;EACA,kBAAA;EACA,SAAA;EACA,WAAA;EACA,eAAA;EACA,aAAA;EACA,qBAAA;AAER;AADQ;EACI,aAAA;AAGZ","sourcesContent":[".printPreviewModal {\n    overflow-y: auto;\n    scrollbar-width: thin;\n    width: 1140px !important;\n    &::-webkit-scrollbar {\n        width: 8px;\n        &-track {\n            display: none;\n        }\n        &-thumb {\n            width: 8px;\n            background: rgba(255,255,255,0.8);\n        }\n    }\n    .modalBox {\n        margin: 50px auto;\n        background-color: #fff;\n        border-radius: 8px;\n        border: 0;\n        width: 100%;\n        padding: 20px 0;\n        outline: none;\n        scrollbar-width: none;\n        &::-webkit-scrollbar {\n            display: none;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"printPreviewModal": `PreviewModal_printPreviewModal__K3y8-`,
	"modalBox": `PreviewModal_modalBox__dPOGL`
};
export default ___CSS_LOADER_EXPORT___;
