/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Select from "react-select";
import ImageWithBasePath from "../../img/imagewithbasebath";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { API_URL } from "../../../constants/AppConstants";
import { alertData, supplierData } from "../../../core/redux/action";
import stateCityListArr from "../../../data/StateCityList.json";

const SupplierModal = (props) => {
  const login_data = useSelector((state) => state.login_data);
  const [supplierName, setSupplierName] = useState("");
  const [supplierPhone, setSupplierPhone] = useState("");
  const [supplierEmail, setSupplierEmail] = useState("");
  const [supplierAddress, setSupplierAddress] = useState("");
  const [supplierCountry, setSupplierCountry] = useState({
    label: "Choose",
    value: null,
  });
  const [supplierState, setSupplierState] = useState({
    label: "Choose",
    value: null,
  });
  const [supplierCity, setSupplierCity] = useState({
    label: "Choose",
    value: null,
  });
  const [supplierDescription, setSupplierDescription] = useState("");
  const [supplierStatus, setSupplierStatus] = useState("");
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);

  const selected_store = useSelector((state) => state.selected_store);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (Object.keys(stateCityListArr)?.length) {
      let stateArr = [];
      Object.keys(stateCityListArr).forEach((state) => {
        stateArr.push({
          label: state,
          value: state,
        });
      });
      setStateList(stateArr);
    }
  }, []);

  useEffect(() => {
    if (props?.selectedData && Object.keys(props?.selectedData).length) {
      setSupplierName(props.selectedData.supplierName);
      setSupplierPhone(props.selectedData.supplierPhone);
      setSupplierEmail(props.selectedData.supplierEmail);
      setSupplierAddress(props.selectedData.supplierAddress);
      setSupplierCountry(props.selectedData.supplierCountry);
      setSupplierState(props.selectedData.supplierState);
      setSupplierCity(props.selectedData.supplierCity);
      setSupplierDescription(props.selectedData.supplierDescription);
    }
  }, [props.selectedData]);

  useEffect(() => {
    if (supplierState?.value) {
      if (Object.keys(stateCityListArr)?.length) {
        let cityArr = [];
        Object.entries(stateCityListArr).forEach((state, index) => {
          if (state[0] == supplierState?.value) {
            state[1]?.length &&
              state[1]?.forEach((city) => {
                cityArr.push({
                  label: city,
                  value: city,
                });
              });
          }
        });
        setCityList(cityArr);
      }
    }
  }, [supplierState]);

  async function fetchData() {
    try {
      let response = await axios.get(API_URL + "suppliers");

      let filteredData = [];
      if (login_data?.roleId == 1) {
        filteredData = response.data?.filter(
          (data) =>
            data.storeId == selected_store[0]?._id && data.itemActive === true
        );
        dispatch(supplierData(filteredData));
      } else {
        filteredData = response.data?.filter(
          (data) =>
            data.storeId == selected_store[0]?._id &&
            data.itemActive === true &&
            login_data?.empCode == data.userCode
        );
        dispatch(supplierData(filteredData));
      }
    } catch (err) {
      console.log(err);
    }
  }

  function clearData() {
    setSupplierName("");
    setSupplierPhone("");
    setSupplierEmail("");
    setSupplierAddress("");
    setSupplierCountry({
      label: "Choose",
      value: null,
    });
    setSupplierState({
      label: "Choose",
      value: null,
    });
    setSupplierCity({
      label: "Choose",
      value: null,
    });
    setSupplierDescription("");
  }

  async function submitData() {
    if (
      !supplierName ||
      !supplierPhone ||
      !supplierAddress ||
      !supplierCountry ||
      !supplierState ||
      !supplierCity
    ) {
      dispatch(
        alertData({
          type: "error",
          msg: "Please fill the required fields",
        })
      );
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    let dataBody = {
      supplierName,
      supplierEmail,
      supplierPhone,
      supplierAddress,
      supplierCountry,
      supplierState,
      supplierCity,
      supplierDescription,
      supplierStatus: "Active",
      storeId: selected_store[0]?._id,
      createdOn: new Date().toLocaleDateString().split(",")[0],
      userCode: login_data?.empCode,
      itemActive: true,
    };

    try {
      let response = await axios.post(API_URL + "suppliers", dataBody);
      if (response.status === 200) {
        dispatch(
          alertData({
            type: "success",
            msg: "Supplier added successfully!",
          })
        );
        fetchData();
        clearData();
        setTimeout(() => {
          setIsLoading(false);
          if (!props.from)
            document?.querySelector("#add-supplier .close")?.click();
        }, 1000);
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function editData() {
    if (
      !supplierName ||
      !supplierPhone ||
      !supplierAddress ||
      !supplierCountry ||
      !supplierState ||
      !supplierCity
    ) {
      dispatch(
        alertData({
          type: "error",
          msg: "Please fill the required fields",
        })
      );
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    let dataBody = {
      supplierName,
      supplierEmail,
      supplierPhone,
      supplierAddress,
      supplierCountry,
      supplierState,
      supplierCity,
      supplierDescription,
      supplierStatus: supplierStatus,
    };

    try {
      let response = await axios.put(
        API_URL + "suppliers/" + props.selectedData._id,
        dataBody
      );
      if (response.status === 200) {
        dispatch(
          alertData({
            type: "success",
            msg: "Supplier updated successfully!",
          })
        );
        fetchData();
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <div>
      {/* Add Supplier */}
      <div className="modal fade" id="add-supplier">
        <div className="modal-dialog modal-dialog-centered custom-modal-two">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>Add Supplier</h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    data-bs-toggle={props.from ? "modal" : ""}
                    data-bs-target={props.from ? `${props.target}` : ""}
                    onClick={clearData}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <form>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="input-blocks">
                          <label>
                            Supplier Name{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={supplierName}
                            onChange={(e) => setSupplierName(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-blocks">
                          <label>Email</label>
                          <input
                            type="email"
                            className="form-control"
                            value={supplierEmail}
                            onChange={(e) => {
                              setSupplierEmail(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-blocks">
                          <label>
                            Phone<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={supplierPhone}
                            onChange={(e) => {
                              if (/^\d*$/.test(e.target.value)) {
                                if (e.target.value.length <= 10)
                                  setSupplierPhone(e.target.value);
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="input-blocks">
                          <label>
                            Address<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={supplierAddress}
                            onChange={(e) => setSupplierAddress(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-sm-10 col-10">
                        <div className="input-blocks">
                          <label>
                            Country<span style={{ color: "red" }}>*</span>
                          </label>
                          <Select
                            value={
                              supplierCountry?.value
                                ? supplierCountry
                                : [
                                    {
                                      label: "Choose",
                                      value: null,
                                    },
                                  ]
                            }
                            className="select"
                            options={[
                              {
                                label: "India",
                                value: "India",
                              },
                            ]}
                            onChange={(item) => setSupplierCountry(item)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-sm-10 col-10">
                        <div className="input-blocks">
                          <label>
                            State<span style={{ color: "red" }}>*</span>
                          </label>
                          <Select
                            value={
                              supplierState?.value
                                ? supplierState
                                : [
                                    {
                                      label: "Choose",
                                      value: null,
                                    },
                                  ]
                            }
                            className="select"
                            options={stateList}
                            onChange={(item) => setSupplierState(item)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-sm-10 col-10">
                        <div className="input-blocks">
                          <label>
                            City<span style={{ color: "red" }}>*</span>
                          </label>
                          <Select
                            value={
                              supplierCity?.value
                                ? supplierCity
                                : [
                                    {
                                      label: "Choose",
                                      value: null,
                                    },
                                  ]
                            }
                            className="select"
                            options={cityList}
                            onChange={(item) => setSupplierCity(item)}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="mb-0 input-blocks">
                          <label className="form-label">Descriptions</label>
                          <textarea
                            value={supplierDescription}
                            className="form-control mb-1"
                            onChange={(e) =>
                              setSupplierDescription(e.target.value)
                            }
                          />
                          <p>Maximum 600 Characters</p>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer-btn">
                      <button
                        type="button"
                        className="btn btn-cancel me-2"
                        data-bs-dismiss="modal"
                        data-bs-toggle={props.from ? "modal" : ""}
                        data-bs-target={props.from ? `${props.target}` : ""}
                        onClick={clearData}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        data-bs-toggle={props.from ? "modal" : ""}
                        data-bs-target={props.from ? `${props.target}` : ""}
                        className={`btn btn-submit ${
                          isLoading ? "disabled" : ""
                        }`}
                        onClick={submitData}
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                  <span style={{ fontStyle: "italic", fontSize: 12 }}>
                    All fields with<span style={{ color: "red" }}>*</span> are
                    required
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Add Supplier */}
      {/* Edit Supplier */}
      <div className="modal fade" id="edit-units">
        <div className="modal-dialog modal-dialog-centered custom-modal-two">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>Edit Supplier</h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <form>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="input-blocks">
                          <label>
                            Supplier Name{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={supplierName}
                            onChange={(e) => setSupplierName(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-blocks">
                          <label>Email</label>
                          <input
                            type="email"
                            className="form-control"
                            value={supplierEmail}
                            onChange={(e) => {
                              setSupplierEmail(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-blocks">
                          <label>
                            Phone<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={supplierPhone}
                            onChange={(e) => {
                              if (/^\d*$/.test(e.target.value)) {
                                if (e.target.value.length <= 10)
                                  setSupplierPhone(e.target.value);
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="input-blocks">
                          <label>
                            Address<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={supplierAddress}
                            onChange={(e) => setSupplierAddress(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-sm-10 col-10">
                        <div className="input-blocks">
                          <label>
                            Country<span style={{ color: "red" }}>*</span>
                          </label>
                          <Select
                            value={
                              supplierCountry?.value
                                ? supplierCountry
                                : [
                                    {
                                      label: "Choose",
                                      value: null,
                                    },
                                  ]
                            }
                            className="select"
                            options={[
                              {
                                label: "India",
                                value: "India",
                              },
                            ]}
                            onChange={(item) => setSupplierCountry(item)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-sm-10 col-10">
                        <div className="input-blocks">
                          <label>
                            State<span style={{ color: "red" }}>*</span>
                          </label>
                          <Select
                            value={
                              supplierState?.value
                                ? supplierState
                                : [
                                    {
                                      label: "Choose",
                                      value: null,
                                    },
                                  ]
                            }
                            className="select"
                            options={stateList}
                            onChange={(item) => {
                              setSupplierState(item);
                              setSupplierCity({
                                label: "Choose",
                                value: null,
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-sm-10 col-10">
                        <div className="input-blocks">
                          <label>
                            City<span style={{ color: "red" }}>*</span>
                          </label>
                          <Select
                            value={
                              supplierCity?.value
                                ? supplierCity
                                : [
                                    {
                                      label: "Choose",
                                      value: null,
                                    },
                                  ]
                            }
                            className="select"
                            options={cityList}
                            onChange={(item) => setSupplierCity(item)}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="mb-0 input-blocks">
                          <label className="form-label">Descriptions</label>
                          <textarea
                            value={supplierDescription}
                            className="form-control mb-1"
                            onChange={(e) =>
                              setSupplierDescription(e.target.value)
                            }
                          />
                          <p>Maximum 600 Characters</p>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer-btn">
                      <button
                        type="button"
                        className="btn btn-cancel me-2"
                        data-bs-dismiss="modal"
                        data-bs-toggle={props.from ? "modal" : ""}
                        data-bs-target={props.from ? `${props.target}` : ""}
                        onClick={clearData}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        data-bs-toggle={props.from ? "modal" : ""}
                        data-bs-target={props.from ? `${props.target}` : ""}
                        className={`btn btn-submit ${
                          isLoading ? "disabled" : ""
                        }`}
                        onClick={editData}
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                  <span style={{ fontStyle: "italic", fontSize: 12 }}>
                    All fields with<span style={{ color: "red" }}>*</span> are
                    required
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Edit Supplier */}
    </div>
  );
};

export default SupplierModal;
