/* eslint-disable no-unused-vars */
import { API_URL } from "../constants/AppConstants";
import axios from "axios";
import { store } from "../core/redux/store";
import {
  brandData,
  categoryData,
  customerData,
  employeesData,
  employeesDataLength,
  expenseCategoryData,
  expenseData,
  productsData,
  purchaseData,
  purchaseDataLength,
  purchaseReturnData,
  purchaseQuotationData,
  purchaseQuotationDataLength,
  rolesData,
  salesData,
  salesDataLength,
  salesReturnData,
  selectStore,
  stocksData,
  storesData,
  subCategoryData,
  supplierData,
  warehouseData,
  accountsData,
  accountsDataLength,
  saleQuotationData,
  saleQuotationDataLength,
  purchaseBookingData,
  purchaseBookingDataLength,
  saleBookingData,
  saleBookingDataLength,
  receiptData,
  receiptDataLength,
  paymentData,
  paymentDataLength,
  bankData,
  bankDataLength,
  transportationData,
} from "../core/redux/action";

export async function fetchBrandsData(login_data, selectedStore) {
  try {
    let response1 = await axios.get(API_URL + "brands");
    if (response1.status === 200) {
      let filteredData = [];
      if (login_data?.roleId == 1) {
        filteredData = response1.data?.filter(
          (data) =>
            data.storeId == selectedStore?._id && data.itemActive === true
        );
        store.dispatch(brandData(filteredData));
      } else {
        filteredData = response1.data?.filter(
          (data) =>
            data.storeId == selectedStore?._id &&
            data.itemActive === true &&
            login_data?.empCode == data.userCode
        );
        store.dispatch(brandData(filteredData));
      }
    }
  } catch (err) {
    console.log(err);
  }
}

export async function fetchCategoryData(login_data, selectedStore) {
  try {
    let response2 = await axios.get(API_URL + "category");
    if (response2.status === 200) {
      let filteredData = [];
      if (login_data?.roleId == 1) {
        filteredData = response2.data?.filter(
          (data) =>
            data.storeId == selectedStore?._id && data.itemActive === true
        );
        store.dispatch(categoryData(filteredData));
      } else {
        filteredData = response2.data?.filter(
          (data) =>
            data.storeId == selectedStore?._id &&
            data.itemActive === true &&
            login_data?.empCode == data.userCode
        );
        store.dispatch(categoryData(filteredData));
      }
    }
  } catch (err) {
    console.log(err);
  }
}

export async function fetchCustomersData(login_data, selectedStore) {
  try {
    let response3 = await axios.get(API_URL + "customers");
    if (response3.status === 200) {
      let filteredData = [];
      if (login_data?.roleId == 1) {
        filteredData = response3.data?.filter(
          (data) =>
            data.storeId == selectedStore?._id && data.itemActive === true
        );
        store.dispatch(customerData(filteredData));
      } else {
        filteredData = response3.data?.filter(
          (data) =>
            data.storeId == selectedStore?._id &&
            data.itemActive === true &&
            login_data?.empCode == data.userCode
        );
        store.dispatch(customerData(filteredData));
      }
    }
  } catch (err) {
    console.log(err);
  }
}
export async function fetchProductsData(login_data, selectedStore) {
  try {
    let response4 = await axios.get(API_URL + "products");
    if (response4.status === 200) {
      let filteredData = [];
      if (login_data?.roleId == 1) {
        filteredData = response4.data?.filter(
          (data) =>
            data.storeId == selectedStore?._id && data.itemActive === true
        );
        store.dispatch(productsData(filteredData));
      } else {
        filteredData = response4.data?.filter(
          (data) =>
            data.storeId == selectedStore?._id &&
            data.itemActive === true &&
            login_data?.empCode == data.userCode
        );
        store.dispatch(productsData(filteredData));
      }
    }
  } catch (err) {
    console.log(err);
  }
}

export async function fetchSubCategoryData(login_data, selectedStore) {
  try {
    let response5 = await axios.get(API_URL + "sub_category");
    if (response5.status === 200) {
      let filteredData = [];
      if (login_data?.roleId == 1) {
        filteredData = response5.data?.filter((data) => {
          return data.storeId == selectedStore?._id && data.itemActive === true;
        });
        store.dispatch(subCategoryData(filteredData));
      } else {
        filteredData = response5.data?.filter(
          (data) =>
            data.storeId == selectedStore?._id &&
            data.itemActive === true &&
            login_data?.empCode == data.userCode
        );
        store.dispatch(subCategoryData(filteredData));
      }
    }
  } catch (err) {
    console.log(err);
  }
}

export async function fetchSuppliersData(login_data, selectedStore) {
  try {
    let response6 = await axios.get(API_URL + "suppliers");
    if (response6.status === 200) {
      let filteredData = [];
      if (login_data?.roleId == 1) {
        filteredData = response6.data?.filter(
          (data) =>
            data.storeId == selectedStore?._id && data.itemActive === true
        );
        store.dispatch(supplierData(filteredData));
      } else {
        filteredData = response6.data?.filter(
          (data) =>
            data.storeId == selectedStore?._id &&
            data.itemActive === true &&
            login_data?.empCode == data.userCode
        );
        store.dispatch(supplierData(filteredData));
      }
    }
  } catch (err) {
    console.log(err);
  }
}

export async function fetchWarehousesData(login_data, selectedStore) {
  try {
    let response7 = await axios.get(API_URL + "warehouses");
    if (response7.status === 200) {
      let filteredData = [];
      if (login_data?.roleId == 1) {
        filteredData = response7.data?.filter(
          (data) =>
            data.storeId == selectedStore?._id && data.itemActive === true
        );
        store.dispatch(warehouseData(filteredData));
      } else {
        filteredData = response7.data?.filter(
          (data) =>
            data.storeId == selectedStore?._id &&
            data.itemActive === true &&
            login_data?.empCode == data.userCode
        );
        store.dispatch(warehouseData(filteredData));
      }
    }
  } catch (err) {
    console.log(err);
  }
}
export async function fetchStoresData() {
  try {
    let response8 = await axios.get(API_URL + "stores");
    if (response8.status === 200) {
      store.dispatch(storesData(response8.data));
      if (!response8.data?.length) {
        store.dispatch(selectStore([]));
        localStorage.removeItem("updatedHeaderStore");
      }
    }
  } catch (err) {
    console.log(err);
  }
}

export async function fetchSalesData(login_data, selectedStore) {
  try {
    let response9 = await axios.get(API_URL + "sales");
    if (response9.status === 200) {
      let filteredData = [];
      let filteredLength = response9.data?.filter(
        (data) => data.storeId == selectedStore?._id
      );
      if (login_data?.roleId == 1) {
        filteredData = response9.data?.filter(
          (data) =>
            data.storeId == selectedStore?._id && data.itemActive === true
        );
        store.dispatch(salesData(filteredData));
      } else {
        filteredData = response9.data?.filter(
          (data) =>
            data.storeId == selectedStore?._id &&
            data.itemActive === true &&
            login_data?.empCode == data.userCode
        );
        store.dispatch(salesData(filteredData));
      }
      store.dispatch(salesDataLength(filteredLength.length));
    }
  } catch (err) {
    console.log(err);
  }
}

export async function fetchSaleQuotationData(login_data, selectedStore) {
  try {
    let response10 = await axios.get(API_URL + "salequotation");
    if (response10.status === 200) {
      let filteredData = [];
      let filteredLength = response10.data?.filter(
        (data) => data.storeId == selectedStore?._id
      );
      if (login_data?.roleId == 1) {
        filteredData = response10.data?.filter(
          (data) =>
            data.storeId == selectedStore?._id && data.itemActive === true
        );
        store.dispatch(saleQuotationData(filteredData));
      } else {
        filteredData = response10.data?.filter(
          (data) =>
            data.storeId == selectedStore?._id &&
            data.itemActive === true &&
            login_data?.empCode == data.userCode
        );
        store.dispatch(saleQuotationData(filteredData));
      }
      store.dispatch(saleQuotationDataLength(filteredLength.length));
    }
  } catch (err) {
    console.log(err);
  }
}

export async function fetchSaleBookingData(login_data, selectedStore) {
  try {
    let response10 = await axios.get(API_URL + "salebooking");
    if (response10.status === 200) {
      let filteredData = [];
      let filteredLength = response10.data?.filter(
        (data) => data.storeId == selectedStore?._id
      );
      if (login_data?.roleId == 1) {
        filteredData = response10.data?.filter(
          (data) =>
            data.storeId == selectedStore?._id && data.itemActive === true
        );
        store.dispatch(saleBookingData(filteredData));
      } else {
        filteredData = response10.data?.filter(
          (data) =>
            data.storeId == selectedStore?._id &&
            data.itemActive === true &&
            login_data?.empCode == data.userCode
        );
        store.dispatch(saleBookingData(filteredData));
      }
      store.dispatch(saleBookingDataLength(filteredLength.length));
    }
  } catch (err) {
    console.log(err);
  }
}

export async function fetchPurchaseQuotationData(login_data, selectedStore) {
  try {
    let response10 = await axios.get(API_URL + "purchasequotation");
    if (response10.status === 200) {
      let filteredData = [];
      let filteredLength = response10.data?.filter(
        (data) => data.storeId == selectedStore?._id
      );
      if (login_data?.roleId == 1) {
        filteredData = response10.data?.filter(
          (data) =>
            data.storeId == selectedStore?._id && data.itemActive === true
        );
        store.dispatch(purchaseQuotationData(filteredData));
      } else {
        filteredData = response10.data?.filter(
          (data) =>
            data.storeId == selectedStore?._id &&
            data.itemActive === true &&
            login_data?.empCode == data.userCode
        );
        store.dispatch(purchaseQuotationData(filteredData));
      }
      store.dispatch(purchaseQuotationDataLength(filteredLength.length));
    }
  } catch (err) {
    console.log(err);
  }
}

export async function fetchPurchaseBookingData(login_data, selectedStore) {
  try {
    let response10 = await axios.get(API_URL + "purchasebooking");
    if (response10.status === 200) {
      let filteredData = [];
      let filteredLength = response10.data?.filter(
        (data) => data.storeId == selectedStore?._id
      );
      if (login_data?.roleId == 1) {
        filteredData = response10.data?.filter(
          (data) =>
            data.storeId == selectedStore?._id && data.itemActive === true
        );
        store.dispatch(purchaseBookingData(filteredData));
      } else {
        filteredData = response10.data?.filter(
          (data) =>
            data.storeId == selectedStore?._id &&
            data.itemActive === true &&
            login_data?.empCode == data.userCode
        );
        store.dispatch(purchaseBookingData(filteredData));
      }
      store.dispatch(purchaseBookingDataLength(filteredLength.length));
    }
  } catch (err) {
    console.log(err);
  }
}

export async function fetchPurchasesData(login_data, selectedStore) {
  try {
    let response11 = await axios.get(API_URL + "purchases");
    if (response11.status === 200) {
      let filteredData = [];
      let filteredLength = response11.data?.filter(
        (data) => data.storeId == selectedStore?._id
      );
      if (login_data?.roleId == 1) {
        filteredData = response11.data?.filter(
          (data) =>
            data.storeId == selectedStore?._id && data.itemActive === true
        );
        store.dispatch(purchaseData(filteredData));
      } else {
        filteredData = response11.data?.filter(
          (data) =>
            data.storeId == selectedStore?._id &&
            data.itemActive === true &&
            login_data?.empCode == data.userCode
        );
        store.dispatch(purchaseData(filteredData));
      }
      store.dispatch(purchaseDataLength(filteredLength.length));
    }
  } catch (err) {
    console.log(err);
  }
}

export async function fetchSalesReturnData(login_data, selectedStore) {
  try {
    let response12 = await axios.get(API_URL + "sales_return");
    if (response12.status === 200) {
      let filteredData = [];
      if (login_data?.roleId == 1) {
        filteredData = response12.data?.filter(
          (data) =>
            data.storeId == selectedStore?._id && data.itemActive === true
        );
        store.dispatch(salesReturnData(filteredData));
      } else {
        filteredData = response12.data?.filter(
          (data) =>
            data.storeId == selectedStore?._id &&
            data.itemActive === true &&
            login_data?.empCode == data.userCode
        );
        store.dispatch(salesReturnData(filteredData));
      }
    }
  } catch (err) {
    console.log(err);
  }
}

export async function fetchStocksData(login_data, selectedStore) {
  try {
    let response13 = await axios.get(API_URL + "stocks");
    if (response13.status === 200) {
      let filteredData = [];
      if (login_data?.roleId == 1) {
        filteredData = response13.data?.filter(
          (data) =>
            data.storeId == selectedStore?._id && data.itemActive === true
        );
        store.dispatch(stocksData(filteredData));
      } else {
        filteredData = response13.data?.filter(
          (data) =>
            data.storeId == selectedStore?._id &&
            data.itemActive === true &&
            login_data?.empCode == data.userCode
        );
        store.dispatch(stocksData(filteredData));
      }
    }
  } catch (err) {
    console.log(err);
  }
}

export async function fetchTransportationData(login_data, selectedStore) {
  try {
    let response13 = await axios.get(API_URL + "transportation");
    if (response13.status === 200) {
      let filteredData = [];
      console.log(login_data, response13.data, selectedStore);
      if (login_data?.roleId == 1) {
        filteredData = response13.data?.filter(
          (data) =>
            data.storeId == selectedStore?._id && data.itemActive === true
        );
        store.dispatch(transportationData(filteredData));
      } else {
        filteredData = response13.data?.filter(
          (data) =>
            data.storeId == selectedStore?._id &&
            data.itemActive === true &&
            login_data?.empCode == data.userCode
        );
        store.dispatch(transportationData(filteredData));
      }
    }
  } catch (err) {
    console.log(err);
  }
}

export async function fetchPurchaseReturnData(login_data, selectedStore) {
  try {
    let response14 = await axios.get(API_URL + "purchase_return");
    if (response14.status === 200) {
      let filteredData = [];
      if (login_data?.roleId == 1) {
        filteredData = response14.data?.filter(
          (data) =>
            data.storeId == selectedStore?._id && data.itemActive === true
        );
        store.dispatch(purchaseReturnData(filteredData));
      } else {
        filteredData = response14.data?.filter(
          (data) =>
            data.storeId == selectedStore?._id &&
            data.itemActive === true &&
            login_data?.empCode == data.userCode
        );
        store.dispatch(purchaseReturnData(filteredData));
      }
    }
  } catch (err) {
    console.log(err);
  }
}

export async function fetchExpenseCategoryData(login_data, selectedStore) {
  try {
    let response15 = await axios.get(API_URL + "expense_category");
    if (response15.status === 200) {
      let filteredData = [];
      if (login_data?.roleId == 1) {
        filteredData = response15.data?.filter(
          (data) =>
            data.storeId == selectedStore?._id && data.itemActive === true
        );
        store.dispatch(expenseCategoryData(filteredData));
      } else {
        filteredData = response15.data?.filter(
          (data) =>
            data.storeId == selectedStore?._id &&
            data.itemActive === true &&
            login_data?.empCode == data.userCode
        );
        store.dispatch(expenseCategoryData(filteredData));
      }
    }
  } catch (err) {
    console.log(err);
  }
}

export async function fetchExpensesData(login_data, selectedStore) {
  try {
    let response16 = await axios.get(API_URL + "expenses");
    if (response16.status === 200) {
      let filteredData = [];
      if (login_data?.roleId == 1) {
        filteredData = response16.data?.filter(
          (data) =>
            data.storeId == selectedStore?._id && data.itemActive === true
        );
        store.dispatch(expenseData(filteredData));
      } else {
        filteredData = response16.data?.filter(
          (data) =>
            data.storeId == selectedStore?._id &&
            data.itemActive === true &&
            login_data?.empCode == data.userCode
        );
        store.dispatch(expenseData(filteredData));
      }
    }
  } catch (err) {
    console.log(err);
  }
}

export async function fetchEmployeesData(login_data, selectedStore) {
  try {
    let response19 = await axios.get(API_URL + "employees");
    if (response19.status === 200) {
      let filteredData = [];
      let filteredLength = response19.data?.filter(
        (data) => data.storeId == selectedStore?._id
      );
      if (login_data?.roleId == 1) {
        filteredData = response19.data?.filter(
          (data) =>
            data.storeId == selectedStore?._id && data.itemActive === true
        );
        store.dispatch(employeesData(filteredData));
      } else {
        filteredData = response19.data?.filter(
          (data) =>
            data.storeId == selectedStore?._id &&
            data.itemActive === true &&
            login_data?.empCode == data.userCode
        );
        store.dispatch(employeesData(filteredData));
      }
      store.dispatch(employeesDataLength(filteredLength.length));
    }
  } catch (err) {
    console.log(err);
  }
}

export async function fetchRolesPermData(login_data, selectedStore) {
  try {
    let response18 = await axios.get(API_URL + "roles_perm");
    if (response18.status === 200) {
      let filteredData = [];
      if (login_data?.roleId == 1) {
        filteredData = response18.data?.filter(
          (data) =>
            data.storeId == selectedStore?._id && data.itemActive === true
        );
        store.dispatch(rolesData(filteredData));
      } else {
        filteredData = response18.data?.filter(
          (data) =>
            data.storeId == selectedStore?._id &&
            data.itemActive === true &&
            data.roleId == login_data?.roleId
        );
        store.dispatch(rolesData(filteredData));
      }
    }
  } catch (err) {
    console.log(err);
  }
}

export async function fetchAccountsData(login_data, selectedStore) {
  try {
    let response19 = await axios.get(API_URL + "accounts");
    if (response19.status === 200) {
      // let filteredData = [];
      // let filteredLength = response19.data?.filter(
      //   (data) => data.storeId == selectedStore?._id
      // );
      // if (login_data?.roleId == 1) {
      //   console.log("Tesring")
      //   filteredData = response19.data?.filter(
      //     (data) =>
      //       data.storeId == selectedStore?._id && data.itemActive === true
      //   );
      //   store.dispatch(accountsData(filteredData));
      // } else {
      //   filteredData = response19.data?.filter(
      //     (data) =>
      //       data.storeId == selectedStore?._id &&
      //       data.itemActive === true &&
      //       login_data?.empCode == data.userCode
      //   );
      //   store.dispatch(accountsData(filteredData));
      // }
      // store.dispatch(accountsDataLength(filteredLength.length));
    }
  } catch (err) {
    console.log(err);
  }
}

export async function fetchReceiptsData(login_data, selectedStore) {
  try {
    let response20 = await axios.get(API_URL + "receipt");
    if (response20.status === 200) {
      let filteredData = [];
      let filteredLength = response20.data?.filter(
        (data) => data.storeId == selectedStore?._id
      );
      if (login_data?.roleId == 1) {
        filteredData = response20.data?.filter((data) => {
          data.storeId == selectedStore?._id && data.itemActive === true;
        });

        store.dispatch(receiptData(filteredData));
      } else {
        filteredData = response20.data?.filter(
          (data) =>
            data.storeId == selectedStore?._id &&
            data.itemActive === true &&
            login_data?.empCode == data.userCode
        );
        store.dispatch(receiptData(filteredData));
      }
      store.dispatch(receiptDataLength(filteredLength.length));
    }
  } catch (err) {
    console.log(err);
  }
}
export async function fetchPaymentsData(login_data, selectedStore) {
  try {
    let response21 = await axios.get(API_URL + "payment");

    if (response21.status === 200) {
      let filteredData = [];
      let filteredLength = response21.data?.filter(
        (data) => data.storeId == selectedStore?._id
      );
      if (login_data?.roleId == 1) {
        filteredData = response21.data?.filter(
          (data) =>
            data.storeId == selectedStore?._id && data.itemActive === true
        );
        store.dispatch(paymentData(filteredData));
      } else {
        filteredData = response21.data?.filter(
          (data) =>
            data.storeId == selectedStore?._id &&
            data.itemActive === true &&
            login_data?.empCode == data.userCode
        );
        store.dispatch(paymentData(filteredData));
      }
      store.dispatch(paymentDataLength(filteredLength.length));
    }
  } catch (err) {
    console.log(err);
  }
}

export async function fetchBankData(login_data, selectedStore) {
  try {
    let response22 = await axios.get(API_URL + "bankaccount");

    if (response22.status === 200) {
      let filteredData = [];
      let filteredLength = response22.data?.filter(
        (data) => data.storeId == selectedStore?._id
      );

      console.log(selectedStore?._id)
      if (login_data?.roleId == 1) {
        filteredData = response22.data?.filter(
          (data) =>
            data.storeId == selectedStore?._id && data.itemActive === true
        );
        store.dispatch(bankData(filteredData));
      } else {
        filteredData = response22.data?.filter(
          (data) =>
            data.storeId == selectedStore?._id &&
            data.itemActive === true &&
            login_data?.empCode == data.userCode
        );
        store.dispatch(bankData(filteredData));
      }
      store.dispatch(bankDataLength(filteredLength.length));
    }
  } catch (err) {
    console.log(err);
  }
}

export async function fetchAll(login_data, selectedStore) {
  fetchBrandsData(login_data, selectedStore);
  fetchCategoryData(login_data, selectedStore);
  fetchCustomersData(login_data, selectedStore);
  fetchEmployeesData(login_data, selectedStore);
  fetchExpenseCategoryData(login_data, selectedStore);
  fetchExpensesData(login_data, selectedStore);
  fetchProductsData(login_data, selectedStore);
  fetchPurchaseReturnData(login_data, selectedStore);
  fetchPurchasesData(login_data, selectedStore);
  fetchPurchaseQuotationData(login_data, selectedStore);
  fetchSaleQuotationData(login_data, selectedStore);
  fetchSaleBookingData(login_data, selectedStore);
  fetchPurchaseBookingData(login_data, selectedStore);
  fetchRolesPermData(login_data, selectedStore);
  fetchSalesData(login_data, selectedStore);
  fetchSalesReturnData(login_data, selectedStore);
  fetchTransportationData(login_data, selectedStore);
  fetchStocksData(login_data, selectedStore);
  fetchStoresData(login_data, selectedStore);
  fetchSubCategoryData(login_data, selectedStore);
  fetchSuppliersData(login_data, selectedStore);
  fetchWarehousesData(login_data, selectedStore);
  fetchAccountsData(login_data, selectedStore);
  fetchReceiptsData(login_data, selectedStore);
  fetchPaymentsData(login_data, selectedStore);
  fetchBankData(login_data, selectedStore);
}
