/* eslint-disable no-unused-vars */
import { DatePicker } from "antd";
import { ChevronUp, Info } from "feather-icons-react/build/IconComponents";
import ArrowLeft from "feather-icons-react/build/IconComponents/ArrowLeft";
import React, { useEffect, useState } from "react";
import { PlusCircle } from "react-feather";
import { Link } from "react-router-dom";
import Select from "react-select";
import { all_routes } from "../../Router/all_routes";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { alertData, setToogleHeader } from "../../core/redux/action";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { API_URL } from "../../constants/AppConstants";
// import { API_URL } from "../../constants/AppConstants";
// import axios from "axios";

const EditEmployee = () => {
  const route = all_routes;
  const login_data = useSelector((state) => state.login_data);
  const dispatch = useDispatch();
  const data = useSelector((state) => state.toggle_header);
  const selected_store = useSelector((state) => state.selected_store);
  const detail_data = useSelector((state) => state.detail_data.data);
  const [viewMode, setViewMode] = useState(true);
  const [selectedDate, setSelectedDate] = useState(detail_data?.dateOfBirth);
  const [dateOfBirth, setDateOfBirth] = useState(detail_data?.dateOfBirth);
  const [profileImage, setProfileImage] = useState(
    detail_data ? detail_data?.profileImage : null
  );
  const [gender, setGender] = useState(
    detail_data
      ? { label: detail_data?.gender, value: detail_data?.gender }
      : { label: "NA", value: "NA" }
  );
  const [designation, setDesignation] = useState(
    detail_data
      ? detail_data?.designation
      : {
          label: "Choose",
          value: "Choose",
        }
  );
  const [bloodGroup, setBloodGroup] = useState(
    detail_data
      ? detail_data?.bloodGroup
      : {
          label: "Choose",
          value: "Choose",
        }
  );

  const [country, setCountry] = useState(
    detail_data
      ? { label: detail_data?.country, value: detail_data?.country }
      : { label: "Choose", value: null }
  );

  const bloodgroupArr = [
    { value: "Select", label: "Select" },
    { value: "A+", label: "A+" },
    { value: "A-", label: "A-" },
    { value: "B+", label: "B+" },
    { value: "B-", label: "B-" },
    { value: "O+", label: "O+" },
    { value: "O-", label: "O-" },
    { value: "AB+", label: "AB+" },
    { value: "AB-", label: "AB-" },
  ];

  // const [password, setPassword] = useState('');
  // // const [confirmPassword, setConfirmPassword] = useState('');
  // const [showPassword, setShowPassword] = useState(false);
  // const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [firstName, setFirstName] = useState(
    detail_data ? detail_data.firstName : ""
  );
  const [lastName, setLastName] = useState(
    detail_data ? detail_data.lastName : ""
  );
  const [email, setEmail] = useState(detail_data ? detail_data.email : "");
  const [contact, setContact] = useState(
    detail_data ? detail_data.contact : ""
  );
  const [empCode, setEmpCode] = useState(
    detail_data ? detail_data.empCode : ""
  );
  // const [dateOfBirth, setDateOfBirth] = useState(new Date());
  const [joiningDate, setJoiningDate] = useState(new Date());
  const [shift, setShift] = useState({ label: "Choose", value: "Choose" });
  const [emergencyContact1, setEmergencyContact1] = useState(
    detail_data ? detail_data.emergencyContact1 : ""
  );
  const [emergencyContact2, setEmergencyContact2] = useState(
    detail_data ? detail_data.emergencyContact2 : ""
  );
  const [address, setAddress] = useState(
    detail_data ? detail_data.address : ""
  );
  const [state, setState] = useState({ label: "Choose", value: null });
  const [city, setCity] = useState({ label: "Choose", value: null });
  const [zipcode, setZipcode] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleDateChange = (date) => {
    setSelectedDate(new Date(date));
    setDateOfBirth(new Date(date));
  };
  const [selectedDate1, setSelectedDate1] = useState(new Date());
  const handleDateChange1 = (date) => {
    setSelectedDate1(date);
  };

  const genderArr = [
    { value: "Choose", label: "Choose" },
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
  ];

  const designationArr = [
    { value: "Choose", role: null, label: "Choose" },
    { value: "Store_Manager", role: "1", label: "Store Manager" },
    { value: "Sales_Manager", role: "3", label: "Sales Manager" },
    { value: "Accountant", role: "2", label: "Accountant" },
    { value: "Sales_Person", role: "4", label: "Sales Person" },
  ];

  const countryArr = [
    { value: "Choose", label: "Choose" },
    { value: "United Kingdom", label: "United Kingdom" },
    { value: "USA", label: "USA" },
  ];
  const renderCollapseTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Collapse
    </Tooltip>
  );

  useEffect(() => {
    if (detail_data) {
      setProfileImage(detail_data?.profileImage);
      setFirstName(detail_data?.firstName);
      setLastName(detail_data?.lastName);
      setEmail(detail_data?.email);
      setContact(detail_data?.contact);
      setEmpCode(detail_data?.empCode);
      setDateOfBirth(detail_data?.dateOfBirth);
      setJoiningDate(detail_data?.joiningDate);
      setShift(detail_data?.shift);
      setEmergencyContact1(detail_data?.emergencyContact1);
      setEmergencyContact2(detail_data?.emergencyContact2);
      setAddress(detail_data?.address);
      setState(detail_data?.state);
      setCity(detail_data?.city);
      setZipcode(detail_data?.zipcode);
    }
  }, [detail_data]);

  async function submitData() {
    if (
      !profileImage ||
      !firstName ||
      !lastName ||
      !email ||
      !contact ||
      !dateOfBirth ||
      !gender ||
      !joiningDate ||
      !designation ||
      !emergencyContact1 ||
      !address ||
      !country ||
      !state ||
      !city ||
      !zipcode
    ) {
      dispatch(
        alertData({
          type: "error",
          msg: "Please fill all the required fields.",
        })
      );
      setIsLoading(false);
      return;
    }

    const validateEmail = (email) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };

    validateEmail(email);

    if (!validateEmail) {
      dispatch(
        alertData({
          type: "error",
          msg: "Please fill all the required fields.",
        })
      );
      setIsLoading(false);
      return;
    }

    let dataBody = {
      storeId: selected_store[0]?._id,
      profileImage,
      firstName,
      lastName,
      email,
      contact,
      empCode,
      dateOfBirth: new Date(dateOfBirth).toLocaleDateString().split(",")[0],
      gender: gender.value,
      joiningDate: new Date(joiningDate).toLocaleDateString().split(",")[0],
      shift: shift,
      designation: designation,
      bloodGroup: bloodGroup,
      emergencyContact1,
      emergencyContact2,
      address,
      country: country.value,
      state: state.value,
      city: city.value,
      zipcode,
      status: "Active",
      createdOn: new Date().toLocaleDateString().split(",")[0],
      userCode: login_data?.empCode,
    };

    try {
      setIsLoading(true);
      let res = await axios.put(
        API_URL + "employees/" + detail_data._id,
        dataBody
      );
      // let response = await axios.put('https://api.thefmgroup.in/api/' + "employees/" + detail_data ? detail_data?._id : "" , dataBody);
      if (res.status === 200) {
        dispatch(
          alertData({
            type: "success",
            msg: "Employee updated successfully!",
          })
        );
        setIsLoading(false);
        setViewMode(!viewMode);
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  }

  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>{viewMode ? "View" : "Edit"} Employee</h4>
                <h6>{viewMode ? "View" : "Edit"} Employee</h6>
              </div>
            </div>
            <ul className="table-top-head">
              <li>
                <div className="page-btn">
                  <Link to={route.employeegrid} className="btn btn-secondary">
                    <ArrowLeft className="me-2" />
                    Back to Employee List
                  </Link>
                </div>
              </li>

              <li>
                <OverlayTrigger placement="top" overlay={renderCollapseTooltip}>
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    id="collapse-header"
                    className={data ? "active" : ""}
                    onClick={() => {
                      dispatch(setToogleHeader(!data));
                    }}
                  >
                    <ChevronUp />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
          </div>
          {/* /product list */}
          <form>
            <div className="card">
              <div className="card-body">
                <div className="new-employee-field">
                  <div className="card-title-head">
                    <h6>
                      <span>
                        <Info className="feather-edit" />
                      </span>
                      Employee Information
                    </h6>
                  </div>
                  <div className="profile-pic-upload">
                    <div className="profile-pic">
                      {profileImage ? (
                        <img
                          src={profileImage}
                          alt="Profile"
                          className="profile-image"
                        />
                      ) : (
                        <span>
                          <PlusCircle className="plus-down-add" />
                          Profile Photo
                        </span>
                      )}
                    </div>
                    <div className="input-blocks mb-0">
                      <div className="image-upload mb-0">
                        <input type="file" />
                        <div className="image-uploads">
                          <h4>Change Image</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-4 col-md-6">
                      <div className="mb-3">
                        <label className="form-label">
                          First Name<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          disabled={viewMode}
                          type="text"
                          className="form-control"
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="mb-3">
                        <label className="form-label">
                          Last Name<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          disabled={viewMode}
                          type="text"
                          className="form-control"
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="mb-3">
                        <label className="form-label">
                          Email<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          disabled={viewMode}
                          type="email"
                          className="form-control"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="mb-3">
                        <label className="form-label">
                          Contact Number<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          disabled={viewMode}
                          type="text"
                          className="form-control"
                          value={contact}
                          onChange={(e) => setContact(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="mb-3">
                        <label className="form-label">Emp Code</label>
                        <input
                          disabled
                          type="text"
                          className="form-control"
                          value={empCode}
                          onChange={(e) => setEmpCode(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="input-blocks">
                        <label>
                          Date of Birth<span style={{ color: "red" }}>*</span>
                        </label>
                        <div className="input-groupicon calender-input">
                          <DatePicker
                            disabled={viewMode}
                            selected={selectedDate}
                            onChange={handleDateChange}
                            type="date"
                            className="filterdatepicker"
                            dateFormat="dd-MM-yyyy"
                            placeholder="Choose Date"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="mb-3">
                        <label className="form-label">
                          Gender<span style={{ color: "red" }}>*</span>
                        </label>
                        <Select
                          isDisabled={viewMode}
                          className="select"
                          options={genderArr}
                          onChange={(value) => setGender(value)}
                          placeholder="Choose"
                          value={gender}
                        />
                      </div>
                    </div>
                    {/* <div className="col-lg-4 col-md-6">
                      <div className="mb-3">
                        <label className="form-label">Nationality</label>
                        <Select
                          className="select"
                          options={nationality}
                          placeholder="Choose"
                        />
                      </div>
                    </div> */}
                    <div className="col-lg-4 col-md-6">
                      <div className="input-blocks">
                        <label>
                          Joining Date<span style={{ color: "red" }}>*</span>
                        </label>
                        <div className="input-groupicon calender-input">
                          <DatePicker
                            disabled={viewMode}
                            selected={selectedDate1}
                            onChange={handleDateChange1}
                            type="date"
                            className="filterdatepicker"
                            dateFormat="dd-MM-yyyy"
                            placeholder="Choose Date"
                          />
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-lg-4 col-md-6">
                      <div className="mb-3">
                        <div className="add-newplus">
                          <label className="form-label">Shift</label>
                          <Link to="#">
                            <span>
                              <PlusCircle className="plus-down-add" />
                              Add new
                            </span>
                          </Link>
                        </div>

                        <Select
                          className="select"
                          options={types}
                          placeholder="Choose"
                        />
                      </div>
                    </div> */}
                    {/* <div className="col-lg-4 col-md-6">
                      <div className="mb-3">
                        <label className="form-label">Department</label>

                        <Select
                          className="select"
                          options={departments}
                          placeholder="Choose"
                        />
                      </div>
                    </div> */}
                    <div className="col-lg-4 col-md-6">
                      <div className="mb-3">
                        <label className="form-label">
                          Designation<span style={{ color: "red" }}>*</span>
                        </label>
                        <Select
                          isDisabled={viewMode}
                          value={designation}
                          onChange={(value) => setDesignation(value)}
                          className="select"
                          options={designationArr}
                          placeholder="Choose"
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="mb-3">
                        <label className="form-label">Blood Group</label>
                        <Select
                          isDisabled={viewMode}
                          value={bloodGroup}
                          onChange={(value) => setBloodGroup(value)}
                          className="select"
                          options={bloodgroupArr}
                          placeholder="Choose"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="other-info">
                    <div className="card-title-head">
                      <h6>
                        <span>
                          <Info className="feather-edit" />
                        </span>
                        Other Information
                      </h6>
                    </div>
                    <div className="row">
                      <div className="col-lg-4 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">
                            Emergency No 1{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            disabled={viewMode}
                            type="text"
                            className="form-control"
                            value={emergencyContact1}
                            onChange={(e) =>
                              setEmergencyContact1(e.target.value)
                            }
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Emergency No 2</label>
                          <input
                            disabled={viewMode}
                            type="text"
                            className="form-control"
                            value={emergencyContact2}
                            onChange={(e) =>
                              setEmergencyContact2(e.target.value)
                            }
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6"></div>
                      <div className="col-lg-4 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">
                            Address<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            disabled={viewMode}
                            type="text"
                            className="form-control"
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">
                            Country<span style={{ color: "red" }}>*</span>
                          </label>
                          <Select
                            isDisabled={viewMode}
                            value={country}
                            onChange={(value) => setCountry(value)}
                            className="select"
                            options={countryArr}
                            placeholder="Select"
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">
                            State<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            disabled={viewMode}
                            type="text"
                            className="form-control"
                            value={state}
                            onChange={(e) => setState(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">
                            City<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            disabled={viewMode}
                            type="text"
                            className="form-control"
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">
                            Zipcode<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            disabled={viewMode}
                            type="text"
                            className="form-control"
                            value={zipcode}
                            onChange={(e) => setZipcode(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="pass-info">
                    <div className="card-title-head">
                      <h6>
                        <span>
                          <Info />
                        </span>
                        Status
                      </h6>
                    </div>
                    {/* <div className="row">
                      <div className="col-lg-4 col-md-6">
                        <div className="input-blocks mb-md-0 mb-sm-3">
                          <label>Password</label>
                          <div className="pass-group">
                            <input
                              type={showPassword ? 'text' : 'password'}
                              className="pass-input"
                              placeholder="Enter your password"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                            />
                            <span
                              className={`fas toggle-password ${showPassword ? 'fa-eye' : 'fa-eye-slash'}`}
                              onClick={togglePasswordVisibility}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="input-blocks mb-0">
                          <label>Confirm Password</label>
                          <div className="pass-group">
                            <input
                              type={showConfirmPassword ? 'text' : 'password'}
                              className="pass-input"
                              placeholder="Confirm your password"
                              value={confirmPassword}
                              onChange={(e) => setConfirmPassword(e.target.value)}
                            />
                            <span
                              className={`fas toggle-password ${showConfirmPassword ? 'fa-eye' : 'fa-eye-slash'}`}
                              onClick={toggleConfirmPasswordVisibility}
                            />
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            {/* /product list */}
            <div className="text-end mb-3">
              {viewMode ? (
                <Link
                  to="#"
                  className={`btn btn-submit`}
                  onClick={() => {
                    setViewMode(!viewMode);
                  }}
                >
                  Edit Employee
                </Link>
              ) : (
                <>
                  <button
                    type="button"
                    className="btn btn-cancel me-2"
                    onClick={() => {
                      setViewMode(!viewMode);
                    }}
                  >
                    Cancel
                  </button>
                  <Link
                    to="#"
                    className={`btn btn-submit ${isLoading ? "disabled" : ""}`}
                    onClick={submitData}
                  >
                    Save Employee
                  </Link>
                </>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditEmployee;
