/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { alertData, subCategoryData } from "../../../core/redux/action";
import { API_URL } from "../../../constants/AppConstants";
import axios from "axios";
import {
  fetchCategoryData,
  fetchSubCategoryData,
} from "../../../utils/utilities";

const AddSubcategory = (props) => {
  const login_data = useSelector((state) => state.login_data);
  const category_data = useSelector((state) => state.category_data);
  const [categories, setCategories] = useState(category_data);
  const [parentCategory, setParentCategory] = useState({
    label: props.productCategory ? props.productCategory.label : "Choose",
    value: props.productCategory ? props.productCategory.value : null,
  });
  const [subCategoryName, setSubCategoryName] = useState("");
  const [subCategoryStatus, setSubCategoryStatus] = useState(true);
  const selected_store = useSelector((state) => state.selected_store);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchCategoryData(login_data, selected_store[0]);
  }, [selected_store]);

  useEffect(() => {
    setParentCategory({
      label: props.productCategory ? props.productCategory.label : "Choose",
      value: props.productCategory ? props.productCategory.value : null,
    });
  }, [props.productCategory]);

  useEffect(() => {
    let newArr = [];

    category_data?.forEach((category) => {
      newArr.push({
        value: category._id,
        label: category.categoryName,
      });
    });

    setCategories(newArr);
  }, [category_data]);

  async function submitData() {
    setIsLoading(true);
    if (!parentCategory.value || !subCategoryName) {
      dispatch(
        alertData({
          type: "error",
          msg: "Please fill the required fields",
        })
      );
      setIsLoading(false);
      return;
    }

    let dataBody = {
      parentCategory: {
        id: parentCategory.value,
        categoryName: parentCategory.label,
      },
      subCategoryName,
      subCategoryStatus,
      storeId: selected_store[0]?._id,
      createdOn: new Date().toLocaleDateString().split(",")[0],
      userCode: login_data?.empCode,
      itemActive: true,
    };

    try {
      let response = await axios.post(API_URL + "sub_category", dataBody);

      if (response.status === 200) {
        dispatch(
          alertData({
            type: "success",
            msg: "Sub-Category added successfully!",
          })
        );
        setTimeout(() => {
          setIsLoading(false);
          document?.querySelector("#add-subcategory .close")?.click();
          setSubCategoryName("");
          // setParentCategory({
          //   label: props.productCategory.label,
          //   value: props.productCategory.value,
          // });
        }, 1000);
        fetchSubCategoryData(login_data, selected_store[0]);
      }
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <div>
      {/* Add Category */}
      <div className="modal fade" id="add-subcategory">
        <div className="modal-dialog modal-dialog-centered custom-modal-two">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>Create Sub Category</h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    data-bs-toggle={props.from && props.target ? "modal" : ""}
                    data-bs-target={
                      props.from && props.target ? `${props.target}` : ""
                    }
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <form>
                    <div className="mb-3">
                      <label className="form-label">
                        Parent Category <span style={{ color: "red" }}>*</span>
                      </label>
                      <Select
                        isDisabled={
                          props.productCategory
                            ? props.productCategory.value
                              ? true
                              : false
                            : false
                        }
                        value={
                          parentCategory?.value != null
                            ? parentCategory
                            : [
                                {
                                  label: "Choose",
                                  value: null,
                                },
                              ]
                        }
                        onChange={(item) => setParentCategory(item)}
                        className="select"
                        options={categories}
                        placeholder="Choose Category"
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">
                        Sub Category Name{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={subCategoryName}
                        onChange={(e) => setSubCategoryName(e.target.value)}
                      />
                    </div>
                    {/* <div className="mb-3">
                      <label className="form-label">Category Code</label>
                      <input type="text" className="form-control" />
                    </div> */}
                    {/* <div className="mb-3 input-blocks">
                      <label className="form-label">Description</label>
                      <textarea className="form-control" defaultValue={""} />
                    </div> */}
                    <div className="mb-0">
                      <div className="status-toggle modal-status d-flex justify-content-between align-items-center">
                        <span className="status-label">Status</span>
                        <input
                          disabled={props.from ? true : false}
                          value={subCategoryStatus}
                          checked={subCategoryStatus}
                          onChange={(e) =>
                            setSubCategoryStatus(!subCategoryStatus)
                          }
                          type="checkbox"
                          id="user2"
                          className="check"
                          defaultChecked="true"
                        />
                        <label htmlFor="user2" className="checktoggle" />
                      </div>
                    </div>
                    <div className="modal-footer-btn">
                      <button
                        type="button"
                        className="btn btn-cancel me-2"
                        data-bs-dismiss="modal"
                        data-bs-toggle={
                          props.from && props.target ? "modal" : ""
                        }
                        data-bs-target={
                          props.from && props.target ? `${props.target}` : ""
                        }
                      >
                        Cancel
                      </button>
                      <div
                        className={`btn btn-submit ${
                          isLoading ? "disabled" : ""
                        }`}
                        onClick={submitData}
                      >
                        Create Sub Category
                      </div>
                    </div>
                  </form>
                  <span style={{ fontStyle: "italic", fontSize: 12 }}>
                    All fields with<span style={{ color: "red" }}>*</span> are
                    required
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Add Category */}
    </div>
  );
};

export default AddSubcategory;
