// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Activity.css */
.item-activtiy {
    max-width: 100%;
    overflow: hidden;
}

.body-activity {
    max-width: 100%;
    overflow-x: auto;
    /* Allow horizontal scrolling within the accordion body */
}

.btn-activty {
    white-space: normal;
    /* Allow text to wrap within the button */
    word-wrap: break-word;
    /* Break words if necessary */
}

pre {
    text-align: left;
}

.success {
    background: rgb(40 199 111 / 8%) !important;
}

.warning {
    background: rgba(254, 159, 67, 0.08) !important;
}

.danger {
    background: rgb(255 77 77 / 8%) !important;
}

.success,
.warning,
.danger {
    color: #000 !important;
    padding: 20px !important;
    border-radius: 5px !important;
    padding: 15px !important;
    font-size: 14px !important;
}`, "",{"version":3,"sources":["webpack://./src/feature-module/dashboard/Activity.css"],"names":[],"mappings":"AAAA,iBAAiB;AACjB;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,yDAAyD;AAC7D;;AAEA;IACI,mBAAmB;IACnB,yCAAyC;IACzC,qBAAqB;IACrB,6BAA6B;AACjC;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,2CAA2C;AAC/C;;AAEA;IACI,+CAA+C;AACnD;;AAEA;IACI,0CAA0C;AAC9C;;AAEA;;;IAGI,sBAAsB;IACtB,wBAAwB;IACxB,6BAA6B;IAC7B,wBAAwB;IACxB,0BAA0B;AAC9B","sourcesContent":["/* Activity.css */\n.item-activtiy {\n    max-width: 100%;\n    overflow: hidden;\n}\n\n.body-activity {\n    max-width: 100%;\n    overflow-x: auto;\n    /* Allow horizontal scrolling within the accordion body */\n}\n\n.btn-activty {\n    white-space: normal;\n    /* Allow text to wrap within the button */\n    word-wrap: break-word;\n    /* Break words if necessary */\n}\n\npre {\n    text-align: left;\n}\n\n.success {\n    background: rgb(40 199 111 / 8%) !important;\n}\n\n.warning {\n    background: rgba(254, 159, 67, 0.08) !important;\n}\n\n.danger {\n    background: rgb(255 77 77 / 8%) !important;\n}\n\n.success,\n.warning,\n.danger {\n    color: #000 !important;\n    padding: 20px !important;\n    border-radius: 5px !important;\n    padding: 15px !important;\n    font-size: 14px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
