import React from "react";
import PropTypes from "prop-types";
import { Modal, Select, DatePicker } from "antd";
import moment from "moment";

const { RangePicker } = DatePicker;

const FilterModal = ({
  visible,
  onClose,
  filtersConfig,
  onApplyFilters,
  initialValues,
}) => {
  const [filterValues, setFilterValues] = React.useState(initialValues || {});

  const handleChange = (key, value) => {
    setFilterValues((prev) => ({ ...prev, [key]: value }));
  };

  const applyFilters = () => {
    onApplyFilters(filterValues);
    onClose();
  };

  const handleReset = () => {
    setFilterValues({});
  };

  return (
    <Modal
      title="Filter Options"
      open={visible}
      onCancel={onClose}
      onOk={applyFilters}
      footer={[
        <div
          key="reset"
          className="btn btn-cancel"
          onClick={handleReset}
          style={{ marginRight: "10px" }}
        >
          Reset
        </div>,
        <button
          key="back"
          type="button"
          className="btn btn-cancel me-2"
          onClick={onClose}
        >
          Cancel
        </button>,
        <div key="submit" className="btn btn-submit" onClick={applyFilters}>
          Apply Filters
        </div>,
      ]}
    >
      {filtersConfig.map((filter) => (
        <div key={filter.key} style={{ marginBottom: 16 }}>
          <label>{filter.label}:</label>
          {filter.type === "select" && (
            <Select
              style={{ width: "100%" }}
              placeholder={filter.placeholder}
              options={filter.options}
              value={filterValues[filter.key]}
              onChange={(value) => handleChange(filter.key, value)}
            />
          )}
          {filter.type === "dateRange" && (
            <RangePicker
              style={{ width: "100%" }}
              onChange={(dates) => {
                const [start, end] = dates;
                const startDate = moment(start);
                const endDate = moment(end);
                if (startDate.isValid() && endDate.isValid()) {
                  handleChange("createdOn", [startDate, endDate]);
                }
              }}
            />
          )}
        </div>
      ))}
    </Modal>
  );
};

FilterModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  filtersConfig: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          value: PropTypes.any.isRequired,
        })
      ),
      placeholder: PropTypes.string,
    })
  ).isRequired,
  onApplyFilters: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
};

export default FilterModal;
