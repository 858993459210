/* eslint-disable no-empty */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import {
  ChevronUp,
  Edit,
  Eye,
  FileText,
  PlusCircle,
  RotateCcw,
  Sliders,
  StopCircle,
  User,
} from "feather-icons-react/build/IconComponents";
import {
  alertData,
  loaderData,
  setToogleHeader,
  stocksData,
} from "../../core/redux/action";
import { useDispatch, useSelector } from "react-redux";
import { Filter } from "react-feather";
import Select from "react-select";
import { DatePicker } from "antd";
import axios from "axios";
import { API_URL } from "../../constants/AppConstants";
import { purchaseData, updatePage } from "../../core/redux/action";
import { Modal } from "antd";
import PrintPreview from "../../common/PrintPreview";
import PrintLayout from "../../common/PrintLayout";
import CSS from "./purchaselist.module.scss";
import SupplierModal from "../../core/modals/peoples/supplierModal";
import AddProductModal from "../../core/modals/peoples/AddProductModal";
import TransportModal from "../stock/transportmodal";
import {
  fetchProductsData,
  fetchPurchaseBookingData,
  fetchPurchaseQuotationData,
  fetchPurchasesData,
  fetchStocksData,
  fetchSuppliersData,
} from "../../utils/utilities";
import EditPurchase from "./EditPurchase";
import ViewPurchase from "./ViewPurchase";

const PurchasesList = () => {
  const dispatch = useDispatch();
  const login_data = useSelector((state) => state.login_data);
  const data = useSelector((state) => state.toggle_header);
  const selected_store = useSelector((state) => state.selected_store);
  const customer_data = useSelector((state) => state.customer_data);
  const supplier_data = useSelector((state) => state.supplier_data);
  const products_data = useSelector((state) => state.products_data);
  const purchase_data = useSelector((state) => state.purchase_data);
  const purchase_data_length = useSelector(
    (state) => state.purchase_data_length
  );
  const stocks_data = useSelector((state) => state.stocks_data);
  const purchase_quotation_data = useSelector(
    (state) => state.purchase_quotation_data
  );
  const purchase_booking_data = useSelector(
    (state) => state.purchase_booking_data
  );

  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [purchaseInvoiceNumber, setPurchaseInvoiceNumber] = useState();
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [selectedUnits, setSelectedUnits] = useState([
    {
      label: "Choose",
      value: null,
    },
  ]);
  const [selectedQty, setSelectedQty] = useState([0]);
  const [totalCostAll, setTotalCostAll] = useState([0]);

  const [productInput, setProductInput] = useState("");
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [showProductList, setShowProductList] = useState(false);
  const [filteredList, setFilteredProductList] = useState([]);

  const [orderTaxValue, setOrderTaxValue] = useState(0);
  let totalTax = useRef(0);
  const [discountType, setDiscountType] = useState([
    {
      label: "Choose",
      value: null,
    },
  ]);
  const [discountValue, setDiscountValue] = useState(0);
  const [shippingValue, setShippingValue] = useState(0);
  const [purchaseStatus, setPurchaseStatus] = useState({
    value: "Received",
    label: "Received",
  });
  const [dueAmount, setDueAmount] = useState(0);
  const [dueDate, setDueDate] = useState(new Date());
  const [paidAmount, setPaidAmount] = useState(0);
  const [paymentStatus, setPaymentStatus] = useState({
    label: "Pending",
    value: "Pending",
  });
  const [paymentMethod, setPaymentMethod] = useState({
    label: "Choose",
    value: null,
  });

  const [grandTotal, setGrandTotal] = useState("");
  let grandTotalValue = useRef(0);
  let dropdownRef = useRef(null);

  const [showPdfModal, setShowPdfModal] = useState(false);
  const [pdfData, setPdfData] = useState({});

  const [productStocksList, setProductStocksList] = useState([]);
  const [purchasePriceList, setPurchasePriceList] = useState([]);
  let totalTaxList = useRef([null]);
  const [productStockDetails, setProductStockDetails] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [filterpurchase, setFilterPurchase] = useState(purchase_data);

  const [viewPurchaseDetailData, setViewPurchaseDetailData] = useState({});
  const [pdfFileName, setPdfFileName] = useState("");
  const [showTransportModal, setShowTransportModal] = useState(false);

  const [addPurchaseType, setAddPurchaseType] = useState(1);
  const [selectedPurchaseInvoice, setSelectedPurchaseInvoice] = useState(null);
  const [selectedPurchaseData, setSelectedPurchaseData] = useState(null);

  const [selectedDateFilterData, setSelectedDateFilterData] = useState("");
  const [selectedSupplierFilterData, setSelectedSupplierFilterData] =
    useState("");
  const [customerFilterArr, setCustomerFilterArr] = useState([]);

  const [
    selectedPurchaseStatusFilterData,
    setSelectedPurchaseStatusFilterData,
  ] = useState("");
  const [selectedPaymentStatusFilterData, setSelectedPaymentStatusFilterData] =
    useState("");
  const [selectedBookingStatusFilterData, setSelectedBookingStatusFilterData] =
    useState("");

  const toggleFilterVisibility = () => {
    setIsFilterVisible((prevVisibility) => !prevVisibility);
  };
  const oldandlatestvalue = [
    { value: "Sort by Date", label: "Sort by Date" },
    // { value: '07 09 23', label: '07 09 23' },
    // { value: '21 09 23', label: '21 09 23' },
  ];
  const customername = [{ value: null, label: "Choose" }];
  const status = [{ value: null, label: "Choose" }];
  const paymentstatus = [{ value: null, label: "Choose" }];
  const customer = [{ value: null, label: "Choose" }];
  const suppliername = [{ value: null, label: "Choose" }];
  const statusupdate = [
    { value: "Received", label: "Received" },
    // { value: "Partial Received", label: "Partial Received" },
    { value: "Not Received", label: "Not Received" },
  ];
  const paymenttype = [
    { value: "Cash", label: "Cash" },
    { value: "Card", label: "Card" },
    { value: "Online", label: "Online" },
  ];

  useEffect(() => {
    fetchSuppliersData(login_data, selected_store[0]);
    fetchPurchasesData(login_data, selected_store[0]);
    fetchStocksData(login_data, selected_store[0]);
    fetchProductsData(login_data, selected_store[0]);
    fetchPurchaseBookingData(login_data, selected_store[0]);
    fetchPurchaseQuotationData(login_data, selected_store[0]);
  }, [selected_store]);

  useEffect(() => {
    setFilterPurchase(purchase_data);
    fetchProductsData(login_data, selected_store[0]);
  }, [purchase_data]);

  useEffect(() => {
    setFilteredProductList(products_data);
  }, [products_data]);

  useEffect(() => {
    document.addEventListener("click", (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setShowProductList(false);
      }
    });
  }, []);

  useEffect(() => {
    clearData();
    setSelectedPurchaseInvoice(null);
  }, [addPurchaseType]);

  useEffect(() => {
    if (addPurchaseType === 2) {
      purchase_booking_data.forEach((item) => {
        if (item.invoiceNumber == selectedPurchaseInvoice?.label) {
          let productUnit = [];
          let productQty = [];
          let productPrice = [];
          setSelectedSupplier(item.supplier);
          // setSelectedDate(new Date(item.date));
          setShippingValue(item.shipping);
          setPaymentStatus(item.paymentStatus);
          setPaymentMethod(item.paymentMethod);
          setDueAmount(item.dueAmount);
          setPaidAmount(item.paidAmount);
          setSelectedProducts(item.productsList);
          item.productsList.forEach((product, index) => {
            productUnit[index] = product.selectedUnit;
            productQty[index] = product.selectedQty;
            productPrice[index] = product.purchasePrice;
          });
          setSelectedUnits(productUnit);
          setSelectedQty(productQty);
          setPurchasePriceList(productPrice);
          setSelectedPurchaseData(item);
        }
      });
    } else if (addPurchaseType === 3) {
      console.log(purchase_quotation_data);
      purchase_quotation_data.forEach((item) => {
        if (item.invoiceNumber == selectedPurchaseInvoice?.label) {
          let productUnit = [];
          let productQty = [];
          let productPrice = [];
          setSelectedSupplier(item.supplier);
          setShippingValue(item.shipping);
          setSelectedProducts(item.productsList);
          item.productsList.forEach((product, index) => {
            productUnit[index] = product.selectedUnit;
            productQty[index] = product.selectedQty;
            productPrice[index] = product.purchasePrice;
          });
          setSelectedUnits(productUnit);
          setSelectedQty(productQty);
          setPurchasePriceList(productPrice);
        }
      });
    }
  }, [selectedPurchaseInvoice]);

  useEffect(() => {
    if (purchase_data && purchase_data?.length) {
      let supplierFilterArrCopy = [];

      purchase_data.forEach((item) => {
        if (item?.supplier?.value) {
          supplierFilterArrCopy.push(item.supplier.label);
        }
      });
      let uniqSupplierFilterArrCopy = [...new Set(supplierFilterArrCopy)];
      setCustomerFilterArr(uniqSupplierFilterArrCopy);
    }
  }, [purchase_data]);

  useEffect(() => {
    if (!searchTerm) {
      if (
        selectedSupplierFilterData?.value ||
        selectedPurchaseStatusFilterData?.value ||
        selectedPaymentStatusFilterData?.value
      ) {
        const filteredData = purchase_data.filter((item) => {
          const matchesSupplier = selectedSupplierFilterData?.value
            ? item.supplier.label === selectedSupplierFilterData.label
            : true;
          const matchesSaleStatus = selectedPurchaseStatusFilterData?.value
            ? item.purchaseStatus.label ===
              selectedPurchaseStatusFilterData.label
            : true;
          const matchesPaymentStatus = selectedPaymentStatusFilterData?.value
            ? item.paymentStatus.label ===
              selectedPaymentStatusFilterData?.label
            : true;

          return matchesSupplier && matchesSaleStatus && matchesPaymentStatus;
        });

        setFilterPurchase(filteredData);
      } else {
        setFilterPurchase(purchase_data);
      }
    }
  }, [
    selectedSupplierFilterData,
    selectedPurchaseStatusFilterData,
    selectedPaymentStatusFilterData,
    selectedDateFilterData,
  ]);

  useEffect(() => {
    setPurchaseInvoiceNumber(
      `PL-${new Date().getFullYear()}${purchase_data_length + 1}`
    );
  }, [selectedSupplier]);

  const handleSearch = (searchValue) => {
    setSelectedSupplierFilterData("");
    setSelectedPurchaseStatusFilterData("");
    setSelectedPaymentStatusFilterData("");
    const filteredList = purchase_data.filter((item) => {
      return (
        item?.supplier?.label
          .toLowerCase()
          .includes(searchValue.toLowerCase()) ||
        item?.invoiceNumber?.toLowerCase().includes(searchValue.toLowerCase())
      );
    });
    setFilterPurchase(filteredList);
  };

  useEffect(() => {
    handleSearch(searchTerm);
  }, [searchTerm]);

  useEffect(() => {
    let filteredArr = [];

    let newFilteredList = [...products_data];
    if (productInput?.length) {
      setShowProductList(true);
      filteredArr = newFilteredList?.filter((product) => {
        return product?.productName
          .toLowerCase()
          .includes(productInput.toLowerCase());
      });
      setFilteredProductList(filteredArr);
    } else {
      setFilteredProductList(products_data);
    }
  }, [productInput]);

  useEffect(() => {
    let selectedUnitsArr = [...selectedUnits];
    let selectedQtyArr = [...selectedQty];
    let productStocksArr = [...productStocksList];

    fetchStocksData(login_data, selected_store[0]);

    if (selectedProducts) {
      for (let i = 0; i < selectedProducts.length; i++) {
        selectedUnitsArr.push({
          label: "Choose",
          value: null,
        });

        stocks_data?.forEach((item) => {
          if (item.productId == selectedProducts[i]._id) {
            productStocksArr[i] = {
              productCurrentQty: Number(item.productCurrentQty),
              productUnit: item.productUnit.label,
              productCurrentAlternateQty: Number(
                item.productCurrentAlternateQty
              ),
              productClubbedUnit: item.productClubbedUnit.label,
            };
          }
        });
      }
    }
    setSelectedUnits(selectedUnits);
    setProductStocksList(productStocksArr);
  }, [selectedProducts]);

  useEffect(() => {
    grandTotalValue.current = 0;
    if (selectedProducts.length) {
      for (let i = 0; i < selectedProducts.length; i++) {
        grandTotalValue.current =
          Number(grandTotalValue.current) +
          Number(
            selectedProducts[i].totalCost ? selectedProducts[i].totalCost : 0
          );
      }

      if (discountValue) {
        if (discountType?.label == "Percent") {
          grandTotalValue.current =
            Number(grandTotalValue.current) -
            (Number(grandTotalValue.current) * Number(discountValue)) / 100;
          grandTotalValue.current =
            Number(grandTotalValue.current) +
            (Number(grandTotalValue.current) * Number(orderTaxValue)) / 100;
          grandTotalValue.current =
            Number(grandTotalValue.current) + Number(shippingValue);
        } else if (discountType?.label == "Cash") {
          grandTotalValue.current =
            Number(grandTotalValue.current) - Number(discountValue);
          grandTotalValue.current =
            Number(grandTotalValue.current) +
            (Number(grandTotalValue.current) * Number(orderTaxValue)) / 100;
          grandTotalValue.current =
            Number(grandTotalValue.current) + Number(shippingValue);
        }
      } else {
        grandTotalValue.current =
          Number(grandTotalValue.current) +
          (Number(grandTotalValue.current) * Number(orderTaxValue)) / 100;
        grandTotalValue.current =
          Number(grandTotalValue.current) + Number(shippingValue);
      }
    }
    setGrandTotal(
      Number(grandTotalValue.current) ? Number(grandTotalValue.current) : 0
    );
  }, [selectedProducts, orderTaxValue, discountValue, shippingValue]);

  useEffect(() => {
    let filteredArr = [...selectedProducts];
    for (let i = 0; i < filteredArr.length; i++) {
      let unitCost =
        Number(purchasePriceList[i] ? purchasePriceList[i] : 0) -
        (filteredArr[i].productDiscountType.label == "Cash"
          ? Number(filteredArr[i].productDiscountValue)
          : (Number(purchasePriceList[i] ? purchasePriceList[i] : 0) *
              Number(filteredArr[i].productDiscountValue)) /
            100) +
        ((Number(purchasePriceList[i] ? purchasePriceList[i] : 0) -
          (filteredArr[i].productDiscountType.label == "Cash"
            ? Number(filteredArr[i].productDiscountValue)
            : (Number(purchasePriceList[i] ? purchasePriceList[i] : 0) *
                Number(filteredArr[i].productDiscountValue)) /
              100)) *
          Number(filteredArr[i].productTaxValue)) /
          100;

      filteredArr[i] = {
        ...filteredArr[i],
        selectedUnit: selectedUnits[i],
        selectedQty: selectedQty[i],
        purchasePrice: purchasePriceList[i],
        productUnitCost: unitCost,
        // totalCost: selectedQty[i]
        //   ? selectedUnits[i]?.label != filteredArr[i]?.productUnit?.label
        //     ? Number(unitCost) *
        //       Number(
        //         selectedQty[i] * Number(filteredArr[i]?.productAlternateQty)
        //       )
        //     : Number(unitCost) * Number(selectedQty[i])
        //   : 0,
        totalCost: Number(selectedQty[i])
          ? Number(unitCost) * Number(selectedQty[i])
          : 0,
        productCurrentTax: totalTaxList.current[i],
      };
    }
    setSelectedProducts(filteredArr);
  }, [selectedUnits, selectedQty, purchasePriceList]);

  useEffect(() => {
    setDueAmount((Number(grandTotal) - Number(paidAmount)).toFixed(2));
  }, [grandTotal, paidAmount, selectedSupplier]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  function changeUnit(dataValue, dataId) {
    let newArr = [...selectedUnits];
    newArr.splice(dataId, 1, dataValue);
    setSelectedUnits(newArr);
    changeQty(0, dataId);
  }

  function changeQty(dataValue, dataId) {
    let newArr = [...selectedQty];
    newArr.splice(dataId, 1, dataValue);
    setSelectedQty(newArr);
  }

  const renderRefreshTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Refresh
    </Tooltip>
  );
  const renderCollapseTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Collapse
    </Tooltip>
  );

  async function deleteData(dataId) {
    dispatch(
      loaderData({
        type: "Loader_Data",
        payload: true,
      })
    );
    if (dataId) {
      try {
        let response = await axios.put(API_URL + "purchases/delete/" + dataId);
        if (response.status === 200) {
          fetchPurchasesData(login_data, selected_store[0]);
          dispatch(
            loaderData({
              type: "Loader_Data",
              payload: false,
            })
          );
        }
      } catch (err) {
        console.log(err);
      }
    }
  }

  function showTransport() {
    setIsLoading(true);
    if (!selectedSupplier || !selectedProducts || !purchaseInvoiceNumber) {
      dispatch(
        alertData({
          type: "error",
          msg: "Please fill all the required fields!",
        })
      );
      setIsLoading(false);
      return;
    } else {
      const dismissModal = document.querySelector("#dismiss-modal");
      if (dismissModal) {
        dismissModal.click();
      }

      setShowTransportModal(true);
      setIsLoading(false);
    }
  }

  async function submitData(type) {
    let dataBody = {
      storeId: selected_store[0]?._id,
      date: new Date(selectedDate).toLocaleDateString().split(",")[0],
      invoiceNumber: purchaseInvoiceNumber,
      supplier: selectedSupplier,
      productsList: selectedProducts.map((product) => ({
        ...product,
        productDiscountType: {
          label: product.productDiscountType.label || "Cash",
          value: product.productDiscountType.value || "Cash",
        },
        productCurrentTax: product.productCurrentTax,
      })),
      orderTax: Number(orderTaxValue) + Number(totalTax.current),
      discount: discountValue,
      discountWithUnit:
        discountValue +
        (discountType.label == "Percent"
          ? "%"
          : discountType.label == "Cash"
          ? "₹"
          : ""),
      shipping: shippingValue,
      grandTotal: grandTotal,
      purchaseStatus: purchaseStatus,
      dueAmount,
      dueDate: new Date(dueDate).toLocaleDateString().split(",")[0],
      paidAmount,
      paymentStatus,
      paymentMethod,
      transportDetail: type === 1 ? false : true,
      referenceId: selectedPurchaseInvoice?.value
        ? selectedPurchaseInvoice?.label
        : "",
      createdOn: new Date().toLocaleDateString().split(",")[0],
      userCode: login_data?.empCode,
      itemActive: true,
    };

    let stocksDataArr = [];

    if (selectedProducts.length) {
      if (selectedPurchaseData?.stockUpdated) {
        for (let i = 0; i < selectedProducts.length; i++) {
          console.log(selectedProducts);
          if (
            selectedProducts[i].selectedUnit.label ==
            selectedProducts[i].productClubbedUnit.label
          ) {
            stocksDataArr.push({
              productCurrentAlternateQty:
                Number(selectedProducts[i].selectedQty) +
                Number(productStocksList[i].productCurrentAlternateQty) -
                Number(selectedPurchaseData?.productsList[i].selectedQty),
              productCurrentQty:
                Number(selectedProducts[i].productAlternateQty) *
                (Number(selectedProducts[i].selectedQty) +
                  Number(productStocksList[i].productCurrentAlternateQty) -
                  Number(selectedPurchaseData?.productsList[i].selectedQty)),
            });
          } else {
            stocksDataArr.push({
              productCurrentQty:
                Number(selectedProducts[i].selectedQty) +
                Number(productStocksList[i].productCurrentQty) -
                Number(selectedPurchaseData?.productsList[i].selectedQty),
              productCurrentAlternateQty:
                (Number(selectedProducts[i].selectedQty) +
                  +Number(productStocksList[i].productCurrentQty) -
                  Number(selectedPurchaseData?.productsList[i].selectedQty)) /
                Number(selectedProducts[i].productAlternateQty),
            });
          }
        }
      } else {
        for (let i = 0; i < selectedProducts.length; i++) {
          console.log(selectedProducts);
          if (
            selectedProducts[i].selectedUnit.label ==
            selectedProducts[i].productClubbedUnit.label
          ) {
            stocksDataArr.push({
              productCurrentAlternateQty:
                Number(selectedProducts[i].selectedQty) +
                Number(productStocksList[i].productCurrentAlternateQty),
              productCurrentQty:
                Number(selectedProducts[i].productAlternateQty) *
                (Number(selectedProducts[i].selectedQty) +
                  Number(productStocksList[i].productCurrentAlternateQty)),
            });
          } else {
            stocksDataArr.push({
              productCurrentQty:
                Number(selectedProducts[i].selectedQty) +
                Number(productStocksList[i].productCurrentQty),
              productCurrentAlternateQty:
                (Number(selectedProducts[i].selectedQty) +
                  +Number(productStocksList[i].productCurrentQty)) /
                Number(selectedProducts[i].productAlternateQty),
            });
          }
        }
      }
    }

    try {
      let response = await axios.post(API_URL + "purchases", dataBody);

      if (response.status === 200) {
        clearData();
        dispatch(
          alertData({
            type: "success",
            msg: "Purchase added successfully!",
          })
        );
        fetchPurchasesData(login_data, selected_store[0]);
        setTimeout(() => {
          setIsLoading(false);
          document?.querySelector("#add-purchase-new .close")?.click();
        }, 1000);

        if (purchaseStatus.value == "Received") {
          let failed = 0;
          let responseArr = [];

          setTimeout(async () => {
            if (selectedProducts.length) {
              for (let i = 0; i < selectedProducts.length; i++) {
                response[i] = await axios.put(
                  API_URL + "stocks/" + selectedProducts[i]._id,
                  {
                    ...stocksDataArr[i],
                    stockStatus: "Active",
                    updatedOn: new Date().toLocaleDateString().split(",")[0],
                  }
                );
              }
            }
            responseArr?.forEach((response) => {
              if (!response.status == 200) failed = 1;
            });
            if (!failed) {
              dispatch(
                alertData({
                  type: "success",
                  msg: "Stocks added successfully!",
                })
              );
              fetchStocksData(login_data, selected_store[0]);
            }
          }, 3500);
        }

        if (addPurchaseType === 2) {
          try {
            let response = await axios.put(
              API_URL + "purchasebooking/" + selectedPurchaseData?._id,
              {
                bookingStatus: {
                  label: "Purchased",
                  value: "Purchased",
                },
              }
            );
            if (response.status === 200) {
              dispatch(
                alertData({
                  type: "success",
                  msg: "Booking updated successfully!",
                })
              );
              // setTimeout(() => {
              //   setIsLoading(false);
              //   document?.querySelector("#edit-purchase-new .close")?.click();
              // }, 1000);
              // setViewPurchaseDetailData({});
              // setSelectedPurchaseDetailData("");
              // fetchPurchaseBookingData(login_data, selected_store[0]);
              // clearData();
            }
          } catch (err) {
            setIsLoading(false);
            console.log(err);
          }
        }
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  }

  function clearData() {
    setSelectedPurchaseInvoice(null);
    setSelectedCustomer();
    setSelectedDate(new Date());
    setPurchaseInvoiceNumber("");
    setSelectedSupplier(null);
    setProductInput("");
    setSelectedProducts([]);
    setShowProductList(false);
    setFilteredProductList(products_data);
    setOrderTaxValue(0);
    setDiscountValue(0);
    setDiscountType("");
    setShippingValue(0);
    setPurchaseStatus({
      value: "Received",
      label: "Received",
    });
    setDueAmount(0);
    setDueDate(new Date());
    setPaidAmount(0);
    setPaymentStatus({
      label: "Pending",
      value: "Pending",
    });
    setPaymentMethod({
      label: "Choose",
      value: null,
    });
    setGrandTotal(0);
    totalTax.current = 0;
    setSelectedUnits([
      {
        label: "Choose",
        value: null,
      },
    ]);
    setSelectedQty([0]);
    setPurchasePriceList([]);
  }

  function selectProduct(product) {
    // Check if the product ID already exists in selectedProducts
    if (
      selectedProducts.some(
        (selectedProduct) => selectedProduct._id === product._id
      )
    ) {
      return;
    }

    let productArr = [...selectedProducts];
    productArr.push({
      ...product,
    });
    setSelectedProducts(productArr);

    let selectedUnitsArr = [...selectedUnits];
    let selectedQtyArr = [...selectedQty];
    let totalCostArr = [...totalCostAll];
    selectedUnitsArr.splice(productArr.length - 1, 0, {
      label: "Choose",
      value: null,
    });
    totalCostArr.splice(productArr.length - 1, 0, 0);
    selectedQtyArr.splice(productArr.length - 1, 0, "");
  }

  function deleteProduct(productIndex) {
    let productArr = [...selectedProducts];
    let newArr1 = productArr.filter((item, index) => index != productIndex);
    setSelectedProducts(newArr1);

    let selectedUnitArr = [...selectedUnits];
    let newArr2 = selectedUnitArr.filter(
      (item, index) => index != productIndex
    );
    setSelectedUnits(newArr2);

    let selectedQtyArr = [...selectedQty];
    let newArr3 = selectedQtyArr.filter((item, index) => index != productIndex);
    setSelectedQty(newArr3);

    let selectedPriceArr = [...purchasePriceList];
    let newArr4 = selectedPriceArr.filter(
      (item, index) => index != productIndex
    );
    setPurchasePriceList(newArr4);
  }

  function changePurchasePrice(value, index) {
    let priceArr = [...purchasePriceList];
    priceArr[index] = Number(value);
    setPurchasePriceList(priceArr);
  }

  const getProductQty = (id) => {
    const stockQty = stocks_data.filter((item) => item.productId === id);

    return `${
      stockQty[0]?.productCurrentQty ? stockQty[0]?.productCurrentQty : 0
    } ${stockQty[0]?.productUnit?.label} / ${
      stockQty[0]?.productCurrentAlternateQty
        ? Number(stockQty[0]?.productCurrentAlternateQty).toFixed(2)
        : 0
    } ${stockQty[0]?.productClubbedUnit?.label}`;
  };

  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Purchase List</h4>
                <h6>Manage Your Purchase</h6>
              </div>
            </div>
            <ul className="table-top-head">
              <li>
                <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top">
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderCollapseTooltip}>
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    id="collapse-header"
                    className={data ? "active" : ""}
                    onClick={() => {
                      dispatch(setToogleHeader(!data));
                    }}
                  >
                    <ChevronUp />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
            <div className="page-btn">
              <Link
                to="#"
                className="btn btn-added"
                data-bs-toggle="modal"
                data-bs-target="#add-purchase-new"
                onClick={clearData}
              >
                <PlusCircle className="me-2" />
                Add New Purchase
              </Link>
            </div>
          </div>
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <input
                      type="text"
                      placeholder="Search by invoice or supplier"
                      className="form-control form-control-sm formsearch"
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <Link to className="btn btn-searchset">
                      <i data-feather="search" className="feather-search" />
                    </Link>
                  </div>
                </div>
                <Select
                  value={
                    selectedSupplierFilterData.value
                      ? selectedSupplierFilterData
                      : [
                          {
                            label: "Supplier",
                            value: null,
                          },
                        ]
                  }
                  className="select mr-10"
                  options={customerFilterArr?.map((item, index) => {
                    return { label: item, value: index + 1 };
                  })}
                  placeholder="Supplier"
                  onChange={(value) => setSelectedSupplierFilterData(value)}
                />
                <Select
                  value={
                    selectedPurchaseStatusFilterData.value
                      ? selectedPurchaseStatusFilterData
                      : [
                          {
                            label: "Purchase",
                            value: null,
                          },
                        ]
                  }
                  className="select mr-10"
                  options={[
                    {
                      label: "Received",
                      value: "Received",
                    },
                    {
                      label: "Not Received",
                      value: "Not Received",
                    },
                  ]}
                  placeholder="Purchase"
                  onChange={(value) =>
                    setSelectedPurchaseStatusFilterData(value)
                  }
                />
                <Select
                  value={
                    selectedPaymentStatusFilterData.value
                      ? selectedPaymentStatusFilterData
                      : [
                          {
                            label: "Payment",
                            value: null,
                          },
                        ]
                  }
                  className="select mr-10"
                  options={[
                    {
                      label: "Completed",
                      value: "Completed",
                    },
                    {
                      label: "Pending",
                      value: "Pending",
                    },
                  ]}
                  placeholder="Payment"
                  onChange={(value) =>
                    setSelectedPaymentStatusFilterData(value)
                  }
                />
                {/* <Select
                value={
                  selectedDateFilterData.value
                    ? selectedDateFilterData
                    : [
                        {
                          label: "Date",
                          value: null,
                        },
                      ]
                }
                className="select mr-10"
                options={[
                  {
                    label: "Latest",
                    value: "Latest",
                  },
                  {
                    label: "Oldest",
                    value: "Oldest",
                  },
                ]}
                placeholder="Date"
                onChange={(value) => setSelectedDateFilterData(value)}
              /> */}
                <button
                  className="btn btn-danger"
                  onClick={() => {
                    setSelectedSupplierFilterData("");
                    setSelectedPurchaseStatusFilterData("");
                    setSelectedPaymentStatusFilterData("");
                    setSelectedDateFilterData("");
                  }}
                >
                  Clear
                </button>
              </div>
              <div
                className={`card${isFilterVisible ? " visible" : ""}`}
                id="filter_inputs"
                style={{ display: isFilterVisible ? "block" : "none" }}
              >
                <div className="card-body pb-0">
                  <div className="row">
                    <div className="col-lg-3 col-sm-6 col-12">
                      <div className="input-blocks">
                        <i data-feather="user" className="info-img" />
                        <User className="info-img" />

                        <Select
                          className="select"
                          options={customername}
                          placeholder="Newest"
                        />
                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-6 col-12">
                      <div className="input-blocks">
                        <StopCircle className="info-img" />

                        <Select
                          className="select"
                          options={status}
                          placeholder="Newest"
                        />
                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-6 col-12">
                      <div className="input-blocks">
                        <FileText className="info-img" />
                        <input
                          type="text"
                          placeholder="Enter Reference"
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-12">
                      <div className="input-blocks">
                        <StopCircle className="info-img" />

                        <Select
                          className="select"
                          options={paymentstatus}
                          placeholder="Choose Payment Status"
                        />
                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-6 col-12">
                      <div className="input-blocks">
                        <input
                          type="text"
                          placeholder="Search by invoice or supplier"
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /Filter */}
              <div className="table-responsive">
                <table className="table  datanew">
                  <thead>
                    <tr>
                      {/* <th className="no-sort">
                        <label className="checkboxs">
                          <input type="checkbox" id="select-all" />
                          <span className="checkmarks" />
                        </label>
                      </th> */}
                      <th>Supplier Name</th>
                      <th>Invoice</th>
                      <th>Reference</th>
                      <th>Date</th>
                      <th>Purchase Status</th>
                      <th>Grand Total</th>
                      <th>Paid</th>
                      <th>Due</th>
                      <th>Payment Status</th>
                      {/* <th>Biller</th> */}
                      <th className="text-right">Action</th>
                    </tr>
                  </thead>
                  <tbody className="purchase-list">
                    {filterpurchase?.map((data, index) => {
                      return (
                        <tr key={index}>
                          {/* <td>
                            <label className="checkboxs">
                              <input type="checkbox" />
                              <span className="checkmarks" />
                            </label>
                          </td> */}
                          <td>{data?.supplier?.label}</td>
                          <td>{data?.invoiceNumber}</td>
                          <td>{data?.referenceId}</td>
                          <td>{data.date.split("T")[0]}</td>
                          <td>
                            <span
                              className={`badge ${
                                data?.purchaseStatus?.label == "Received"
                                  ? "badge-linesuccess"
                                  : "badge-linedanger"
                              }`}
                            >
                              {data?.purchaseStatus?.label}
                            </span>
                          </td>
                          <td>{data?.grandTotal}</td>
                          <td>{data?.paidAmount}</td>
                          <td>{data?.dueAmount}</td>
                          <td>
                            <span
                              className={`badge ${
                                data?.paymentStatus?.value
                                  ? data?.paymentStatus?.label == "Completed"
                                    ? "badge-linesuccess"
                                    : "badge-linedanger"
                                  : ""
                              }`}
                            >
                              {data?.paymentStatus?.value
                                ? data?.paymentStatus?.label
                                : ""}
                            </span>
                          </td>
                          {/* <td>Admin</td> */}
                          <td>
                            <div className="d-flex justify-content-end">
                              <Link
                                className="action-set"
                                to="#"
                                data-bs-toggle="modal"
                                data-bs-target="#purchase-details-new"
                                onClick={() => {
                                  setViewPurchaseDetailData(data);
                                }}
                              >
                                <Eye className="feather-view" />
                              </Link>
                              <Link
                                className="action-set"
                                to="#"
                                data-bs-toggle="modal"
                                data-bs-target="#edit-purchase-new"
                                onClick={() => {
                                  setViewPurchaseDetailData(data);
                                }}
                              >
                                <Edit className="feather-edit" />
                              </Link>
                              <Link
                                className="action-set"
                                to="#"
                                data-bs-toggle="dropdown"
                                aria-expanded="true"
                              >
                                <i
                                  className="fa fa-ellipsis-v"
                                  aria-hidden="true"
                                />
                              </Link>
                              <ul className="dropdown-menu">
                                <li>
                                  <Link
                                    className="dropdown-item"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setShowPdfModal(true);
                                      let supplierData = {};
                                      supplier_data.forEach((supplier) => {
                                        if (
                                          data?.supplier.value == supplier._id
                                        ) {
                                          supplierData = {
                                            name: supplier.supplierName,
                                            email: supplier.supplierEmail,
                                            phone: supplier.supplierPhone,
                                            address: supplier.supplierAddress,
                                            city: supplier.supplierCity.label,
                                            state: supplier.supplierState.label,
                                          };
                                        }
                                      });

                                      setPdfData({
                                        heading: "Purchase List",
                                        array: {
                                          // Supplier: data?.supplier,
                                          from: {
                                            name: supplierData.name,
                                            email: supplierData.email,
                                            phone: supplierData.phone,
                                            address: supplierData.address,
                                            country: {
                                              label: "",
                                              value: "",
                                            },
                                            state: {
                                              label: supplierData.state,
                                              value: "",
                                            },
                                            city: {
                                              label: supplierData.city,
                                              value: "",
                                            },
                                          },
                                          to: {
                                            name: "FM Marketing",
                                            email: "fmmarketing@example.com",
                                            phone: "9876543210",
                                            address: "Raipur, Chhattisgarh",
                                            country: {
                                              label: "India",
                                              value: "India",
                                            },
                                            state: {
                                              label: "Chhattisgarh",
                                              value: "Chhattisgarh",
                                            },
                                            city: {
                                              label: "Raipur",
                                              value: "Raipur",
                                            },
                                          },
                                          Reference: data?.referenceNumber,
                                          Date: data?.date,
                                          Product_List: data?.productsList,
                                          Order_Tax: data?.orderTax,
                                          Discount: data?.discountWithUnit,
                                          Shipping: data?.shipping,
                                          Grand_Total: data?.grandTotal,
                                          Purchase_Status: data?.purchaseStatus,
                                          Due_Amount: data?.dueAmount,
                                          Due_Date: data?.dueDate,
                                          Paid_Amount: data?.paidAmount,
                                          Payment_Status: data?.paymentStatus,
                                          Payment_Method: data?.paymentMethod,
                                        },
                                      });
                                    }}
                                  >
                                    <i
                                      data-feather="download"
                                      className="info-img"
                                    />
                                    Generate Regular Pdf
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    className="dropdown-item"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setShowPdfModal(true);
                                      setPdfData({
                                        pdfType: "Transport",
                                        heading: "Purchase List",
                                        array: {
                                          from: {
                                            name: "",
                                            email: "",
                                            phone: "",
                                            address: "",
                                            country: {
                                              label: "India",
                                              value: "India",
                                            },
                                            state: {
                                              label: "Chhattisgarh",
                                              value: "Chhattisgarh",
                                            },
                                            city: {
                                              label: "Raipur",
                                              value: "Raipur",
                                            },
                                            status: "Active",
                                            itemActive: true,
                                          },
                                          to: {
                                            name: "FM Marketing",
                                            email: "fmmarketing@example.com",
                                            phone: "9876543210",
                                            address: "Raipur, Chhattisgarh",
                                            country: {
                                              label: "India",
                                              value: "India",
                                            },
                                            state: {
                                              label: "Chhattisgarh",
                                              value: "Chhattisgarh",
                                            },
                                            city: {
                                              label: "Raipur",
                                              value: "Raipur",
                                            },
                                            status: "Active",
                                            itemActive: true,
                                          },
                                          Reference: data?.referenceNumber,
                                          Date: data?.date,
                                          Product_List: data?.productsList,
                                          Order_Tax: data?.orderTax,
                                          Discount: data?.discountWithUnit,
                                          Shipping: data?.shipping,
                                          Grand_Total: data?.grandTotal,
                                          Purchase_Status: data?.purchaseStatus,
                                          Due_Amount: data?.dueAmount,
                                          Due_Date: data?.dueDate,
                                          Paid_Amount: data?.paidAmount,
                                          Payment_Status: data?.paymentStatus,
                                          Payment_Method: data?.paymentMethod,
                                        },
                                      });
                                    }}
                                  >
                                    <i
                                      data-feather="download"
                                      className="info-img"
                                    />
                                    Generate Transport Pdf
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    className="dropdown-item"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      deleteData(data?._id);
                                    }}
                                  >
                                    <i
                                      data-feather="trash-2"
                                      className="info-img"
                                    />
                                    Delete Purchase
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
      <>
        {/*add popup */}
        <div className="modal fade" id="add-purchase-new">
          <div className="modal-dialog add-centered">
            <div className="modal-content">
              <div className="page-wrapper p-0 m-0">
                <div className="content p-0">
                  <div className="modal-header border-0 custom-modal-header">
                    <div className="page-title">
                      <h4>Add Purchase</h4>
                    </div>
                    <button
                      type="button"
                      className="close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="card">
                    <div className="card-body">
                      <label className="d-inline-flex fs-16 fw-bold align-items-center mb-10">
                        For:{" "}
                      </label>
                      <div className="d-flex mb-20 input-blocks">
                        <input
                          checked={addPurchaseType === 1 ? true : false}
                          onClick={() => setAddPurchaseType(1)}
                          type="radio"
                          name="for"
                        />
                        <span
                          className="d-flex align-items-center"
                          style={{
                            position: "relative",
                            marginLeft: 10,
                            marginRight: 50,
                          }}
                        >
                          New
                        </span>
                        <input
                          checked={addPurchaseType === 2 ? true : false}
                          onClick={() => setAddPurchaseType(2)}
                          type="radio"
                          name="for"
                        />
                        <span
                          className="d-flex align-items-center"
                          style={{
                            position: "relative",
                            marginLeft: 10,
                            marginRight: 50,
                          }}
                        >
                          Booking
                        </span>
                        <input
                          checked={addPurchaseType === 3 ? true : false}
                          onClick={() => setAddPurchaseType(3)}
                          type="radio"
                          name="for"
                        />
                        <span
                          className="d-flex align-items-center"
                          style={{
                            position: "relative",
                            marginLeft: 10,
                            marginRight: 50,
                          }}
                        >
                          Quotation
                        </span>
                      </div>
                      {addPurchaseType === 2 || addPurchaseType === 3 ? (
                        <div className="col-lg-3 col-sm-6 col-12 mb-30">
                          <Select
                            value={
                              selectedPurchaseInvoice
                                ? selectedPurchaseInvoice
                                : [
                                    {
                                      label: "Choose",
                                      value: null,
                                    },
                                  ]
                            }
                            className="select"
                            options={
                              addPurchaseType === 2
                                ? purchase_booking_data
                                    ?.filter(
                                      (item) =>
                                        item?.bookingStatus?.label == "Booked"
                                    )
                                    ?.map((item) => {
                                      return {
                                        label: item.invoiceNumber,
                                        value: item._id,
                                      };
                                    })
                                : purchase_quotation_data?.map((item) => {
                                    return {
                                      label: item.invoiceNumber,
                                      value: item._id,
                                    };
                                  })
                            }
                            placeholder="Choose"
                            onChange={(value) =>
                              setSelectedPurchaseInvoice(value)
                            }
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                      <form>
                        <div className="row">
                          <div className="col-lg-3 col-sm-6 col-12">
                            <div className="input-blocks">
                              <label>
                                Supplier<span style={{ color: "red" }}>*</span>
                              </label>
                              <Select
                                value={
                                  selectedSupplier
                                    ? selectedSupplier
                                    : [
                                        {
                                          label: "Choose",
                                          value: null,
                                        },
                                      ]
                                }
                                className="select"
                                options={supplier_data?.map((item) => {
                                  return {
                                    label: item.supplierName,
                                    value: item._id,
                                  };
                                })}
                                placeholder="Choose"
                                onChange={(value) => setSelectedSupplier(value)}
                              />
                            </div>
                          </div>
                          <div className="col-lg-1 col-sm-2 col-2 ps-0">
                            <div className="add-icon" style={{ marginTop: 30 }}>
                              <Link
                                data-bs-toggle="modal"
                                data-bs-target={`#add-supplier`}
                                onClick={(e) => e.preventDefault()}
                                className="choose-add"
                              >
                                <PlusCircle className="plus" />
                              </Link>
                            </div>
                          </div>
                          <div className="col-lg-3 col-sm-6 col-12">
                            <div className="input-blocks">
                              <label>Purchase Date</label>
                              <div className="input-groupicon calender-input">
                                <DatePicker
                                  disabled
                                  popupStyle={{ zIndex: 2000 }}
                                  selected={selectedDate}
                                  type="date"
                                  className="filterdatepicker add-purchase-filterdatepicker"
                                  dateFormat="dd-MM-yyyy"
                                  placeholder={`${selectedDate.toDateString()}`}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3 col-sm-6 col-12">
                            <div className="input-blocks">
                              <label>
                                Invoice Number
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <div className="input-groupicon calender-input">
                                <input
                                  disabled
                                  readOnly
                                  style={{ padding: 10 }}
                                  type="text"
                                  value={purchaseInvoiceNumber}
                                  placeholder="Enter Invoice Number"
                                  onChange={(e) => {
                                    setPurchaseInvoiceNumber(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-11 col-sm-6 col-12">
                            <div className="input-blocks">
                              <label>
                                Product Name
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <div
                                className="input-groupicon select-code"
                                ref={dropdownRef}
                              >
                                <input
                                  style={{ padding: 10 }}
                                  type="text"
                                  value={productInput}
                                  placeholder="Please type product code and select"
                                  onChange={(e) => {
                                    setProductInput(e.target.value);
                                  }}
                                  onFocus={() => setShowProductList(true)}
                                />
                                {/* <div className="addonset">
                                  <ImageWithBasePath
                                    src="assets/img/icons/qrcode-scan.svg"
                                    alt="img"
                                  />
                                </div> */}
                                {showProductList ? (
                                  <div className="select-code-dropdown">
                                    {filteredList?.map((product, index) => {
                                      return (
                                        <div
                                          key={index}
                                          onClick={() => {
                                            selectProduct(product);
                                            setShowProductList(false);
                                          }}
                                          style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          <span>
                                            {" "}
                                            <span style={{ fontWeight: 900 }}>
                                              {product.productName}
                                            </span>{" "}
                                            | {product.productItemCode}{" "}
                                          </span>{" "}
                                          <span
                                            style={{
                                              minWidth: "18%",
                                              display: "flex",
                                            }}
                                          >
                                            {" "}
                                            <span
                                              style={{
                                                fontWeight: 700,
                                                marginRight: "5px",
                                              }}
                                            >
                                              Item Qty:{" "}
                                            </span>{" "}
                                            {getProductQty(product._id)}
                                          </span>
                                        </div>
                                      );
                                    })}
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-1 col-sm-2 col-2 ps-0">
                            <div className="add-icon" style={{ marginTop: 30 }}>
                              <Link
                                data-bs-toggle="modal"
                                data-bs-target={`#add-product`}
                                onClick={(e) => e.preventDefault()}
                                className="choose-add"
                              >
                                <PlusCircle className="plus" />
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-2 col-sm-6 col-12">
                            <div className="input-blocks">
                              <label>Order Tax(%)</label>
                              <div className="input-groupicon select-code">
                                <input
                                  disabled
                                  value={orderTaxValue}
                                  onChange={(e) =>
                                    setOrderTaxValue(e.target.value)
                                  }
                                  defaultValue={0}
                                  type="text"
                                  className="p-2"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-2 col-sm-6 col-12">
                            <div className="input-blocks">
                              <label>Discount Type</label>
                              <div className="input-groupicon select-code">
                                <Select
                                  value={
                                    discountType
                                      ? discountType
                                      : [
                                          {
                                            label: "Choose",
                                            value: null,
                                          },
                                        ]
                                  }
                                  onChange={(value) => setDiscountType(value)}
                                  className="select"
                                  options={[
                                    {
                                      label: "Percent",
                                      value: "Percent",
                                    },
                                    {
                                      label: "Cash",
                                      value: "Cash",
                                    },
                                  ]}
                                  placeholder="Choose"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-2 col-sm-6 col-12">
                            <div className="input-blocks">
                              <label>Discount</label>
                              <div className="input-groupicon select-code">
                                <input
                                  disabled={!discountType?.value ? true : false}
                                  value={discountValue}
                                  onChange={(e) =>
                                    setDiscountValue(e.target.value)
                                  }
                                  type="number"
                                  className="p-2"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-2 col-sm-6 col-12">
                            <div className="input-blocks">
                              <label>Shipping(₹)</label>
                              <div className="input-groupicon select-code">
                                <input
                                  value={shippingValue}
                                  onChange={(e) =>
                                    setShippingValue(e.target.value)
                                  }
                                  type="number"
                                  className="p-2"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="table-responsive no-pagination"
                          style={{ overflow: "unset" }}
                        >
                          <table className="table  datanew">
                            <thead>
                              <tr>
                                <th>Product</th>
                                <th>Unit</th>
                                <th>Qty</th>
                                <th>Alt. Qty</th>
                                <th>Price(₹)</th>
                                <th>Discount</th>
                                <th>Tax(%)</th>
                                <th>Tax Amt.(₹)</th>
                                <th>Unit Cost(₹)</th>
                                <th>Total Cost(₹)</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {selectedProducts?.map((product, index) => {
                                totalTax.current = 0;
                                let finalPurchasePrice = Number(
                                  purchasePriceList[index]
                                    ? purchasePriceList[index]
                                    : 0
                                );
                                let finalDiscount =
                                  product?.productDiscountType.label == "Cash"
                                    ? Number(product?.productDiscountValue)
                                    : (Number(
                                        purchasePriceList[index]
                                          ? purchasePriceList[index]
                                          : 0
                                      ) *
                                        Number(product?.productDiscountValue)) /
                                      100;
                                let finalDiscountedPrice =
                                  finalPurchasePrice - finalDiscount;

                                let finalTax =
                                  ((finalDiscountedPrice
                                    ? finalDiscountedPrice
                                    : 0) *
                                    Number(product?.productTaxValue)) /
                                  100;
                                let finalUnitCost = purchasePriceList[index]
                                  ? finalDiscountedPrice + finalTax
                                  : 0;

                                let totalTaxAmt =
                                  Number(purchasePriceList[index]) *
                                  (Number(product?.productTaxValue) / 100) *
                                  Number(selectedQty[index]);

                                let newTaxArr = [...totalTaxList.current];
                                newTaxArr[index] = totalTaxAmt;
                                totalTaxList.current = newTaxArr;

                                totalTax.current = newTaxArr.reduce(
                                  (prev, curr) => Number(prev) + Number(curr)
                                );

                                return (
                                  <tr key={index}>
                                    <td>{product?.productName}</td>
                                    <td>
                                      <Select
                                        value={selectedUnits[index]}
                                        onChange={(value) => {
                                          changeUnit(value, index);
                                          changePurchasePrice(0, index);
                                        }}
                                        className="select"
                                        options={
                                          product?.productClubbedUnit?.label
                                            ? [
                                                {
                                                  label:
                                                    product?.productUnit?.label,
                                                  value:
                                                    product?.productUnit?.label,
                                                },
                                                {
                                                  label:
                                                    product?.productClubbedUnit
                                                      ?.label,
                                                  value:
                                                    product?.productClubbedUnit
                                                      ?.label,
                                                },
                                              ]
                                            : product?.productUnit?.label
                                            ? [
                                                {
                                                  label:
                                                    product?.productUnit?.label,
                                                  value:
                                                    product?.productUnit?.label,
                                                },
                                              ]
                                            : [{ label: "Choose", value: null }]
                                        }
                                        placeholder="Choose"
                                      />
                                    </td>
                                    <td>
                                      <input
                                        style={{ width: 80 }}
                                        disabled={
                                          product?.selectedUnit?.value
                                            ? false
                                            : true
                                        }
                                        value={selectedQty[index]}
                                        onChange={(e) =>
                                          changeQty(e.target.value, index)
                                        }
                                        type="number"
                                        className="p-2"
                                      />
                                    </td>
                                    <td>{product?.productAlternateQty}</td>
                                    <td>
                                      <input
                                        style={{ width: 80 }}
                                        value={purchasePriceList[index]}
                                        onChange={(e) =>
                                          changePurchasePrice(
                                            e.target.value,
                                            index
                                          )
                                        }
                                        type="number"
                                        className="p-2"
                                      />
                                    </td>
                                    <td>
                                      {product.productDiscountValue
                                        ? product.productDiscountValue
                                        : 0}
                                      {product.productDiscountValue
                                        ? product.productDiscountType?.label ==
                                          "Cash"
                                          ? "₹"
                                          : "%"
                                        : ""}
                                    </td>
                                    <td>{product?.productTaxValue}</td>
                                    <td>
                                      {product?.productCurrentTax
                                        ? Number(
                                            product?.productCurrentTax
                                          )?.toFixed(2)
                                        : 0}
                                    </td>
                                    <td>
                                      {finalUnitCost
                                        ? Number(finalUnitCost)?.toFixed(2)
                                        : 0}
                                    </td>
                                    <td>
                                      {product?.totalCost
                                        ? Number(product?.totalCost)?.toFixed(2)
                                        : 0}
                                    </td>
                                    <td>
                                      <span
                                        className="feather-trash-container"
                                        onClick={() => deleteProduct(index)}
                                        style={{ marginLeft: "20px" }}
                                      >
                                        <i
                                          data-feather="trash-2"
                                          className="feather feather-trash-2 feather-trash-2"
                                        />
                                      </span>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                        <div className="row">
                          <div className="col-lg-6 ms-auto">
                            <div className="total-order w-100 max-widthauto m-auto mb-4">
                              <ul>
                                <li>
                                  <h4>Total Tax(₹)</h4>
                                  <h5>
                                    {totalTax.current
                                      ? Number(totalTax.current).toFixed(2)
                                      : 0}
                                  </h5>
                                </li>
                                <li>
                                  <h4>
                                    Discount
                                    {discountType.label == "Percent"
                                      ? "(%)"
                                      : discountType.label == "Cash"
                                      ? "(₹)"
                                      : ""}
                                  </h4>
                                  <h5>
                                    {discountValue
                                      ? Number(discountValue).toFixed(2)
                                      : 0}
                                  </h5>
                                </li>
                                <li>
                                  <h4>Shipping(₹)</h4>
                                  <h5>
                                    {shippingValue
                                      ? Number(shippingValue).toFixed(2)
                                      : 0}
                                  </h5>
                                </li>
                                <li>
                                  <h4>Grand Total(₹)</h4>
                                  <h5>
                                    {grandTotal
                                      ? Number(grandTotal).toFixed(2)
                                      : 0}
                                  </h5>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-2 col-sm-6 col-12">
                            <div className="input-blocks">
                              <label>Due Amount(₹)</label>
                              <div className="input-groupicon select-code">
                                <input
                                  disabled
                                  readOnly
                                  value={dueAmount}
                                  onChange={(e) => setDueAmount(e.target.value)}
                                  type="number"
                                  className="p-2"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-2 col-sm-6 col-12">
                            <div className="input-blocks">
                              <label>Due Date</label>
                              <div className="input-groupicon calender-input">
                                {/* <DatePicker
                                  style={{ width: "100%" }}
                                  popupStyle={{ zIndex: 2000 }}
                                  selected={dueDate}
                                  onChange={(date) => setDueDate(date)}
                                  type="date"
                                  className="filterdatepicker"
                                  dateFormat="dd-MM-yyyy"
                                  placeholder="Choose Date"
                                /> */}
                                <input
                                  onChange={(e) => {
                                    setDueDate(e.target.value);
                                  }}
                                  type="date"
                                  className="custom-date-picker"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-2 col-sm-6 col-12">
                            <div className="input-blocks">
                              <label>Paid Amount(₹) </label>
                              <div className="input-groupicon select-code">
                                <input
                                  value={paidAmount}
                                  onChange={(e) => {
                                    setPaidAmount(e.target.value);
                                  }}
                                  type="number"
                                  className="p-2"
                                  defaultValue={0}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3 col-sm-6 col-12">
                            <div className="input-blocks mb-5">
                              <label>Purchase Status </label>

                              <Select
                                value={
                                  purchaseStatus?.value
                                    ? purchaseStatus
                                    : [
                                        {
                                          label: "Choose",
                                          value: null,
                                        },
                                      ]
                                }
                                onChange={(value) => setPurchaseStatus(value)}
                                className="select"
                                options={statusupdate}
                                placeholder="status"
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-3 col-sm-6 col-12">
                              <div className="input-blocks mb-5">
                                <label>Payment Status </label>

                                <div className="input-groupicon select-code">
                                  <Select
                                    value={
                                      paymentStatus
                                        ? paymentStatus
                                        : {
                                            label: "Choose",
                                            value: null,
                                          }
                                    }
                                    onChange={(value) =>
                                      setPaymentStatus(value)
                                    }
                                    className="select"
                                    options={["Completed", "Pending"].map(
                                      (option) => ({
                                        label: option,
                                        value: option,
                                      })
                                    )}
                                    placeholder="Status"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-sm-6 col-12">
                              <div className="input-blocks mb-5">
                                <label>Payment Method </label>

                                <div className="input-groupicon select-code">
                                  <Select
                                    value={
                                      paymentMethod?.value
                                        ? paymentMethod
                                        : {
                                            label: "Choose",
                                            value: null,
                                          }
                                    }
                                    onChange={(value) =>
                                      setPaymentMethod(value)
                                    }
                                    className="select"
                                    options={["Card", "Cash", "Online"].map(
                                      (option) => ({
                                        label: option,
                                        value: option,
                                      })
                                    )}
                                    placeholder="Method"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12 text-end">
                            <button
                              type="button"
                              className="btn btn-cancel add-cancel me-3"
                              data-bs-dismiss="modal"
                              onClick={clearData}
                            >
                              Cancel
                            </button>
                            <div
                              className={`btn btn-submit ${
                                isLoading ? "disabled" : ""
                              }`}
                              onClick={showTransport}
                            >
                              Submit
                            </div>
                            <span
                              data-bs-dismiss="modal"
                              id="dismiss-modal"
                            ></span>
                          </div>
                        </div>
                      </form>
                      <span style={{ fontStyle: "italic" }}>
                        All fields with <span style={{ color: "red" }}>*</span>{" "}
                        are required
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /add popup */}
        {/* details popup */}
        <ViewPurchase
          type="purchase"
          viewPurchaseDetailData={viewPurchaseDetailData}
          setViewPurchaseDetailData={setViewPurchaseDetailData}
        />
        {/* /details popup */}
        {/* edit popup */}
        <EditPurchase
          type="purchase"
          viewPurchaseDetailData={viewPurchaseDetailData}
        />
        {/* /edit popup */}
        {/* show payment Modal */}
        <div
          className="modal fade"
          id="showpayment"
          tabIndex={-1}
          aria-labelledby="showpayment"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered stock-adjust-modal">
            <div className="modal-content">
              <div className="page-wrapper-new p-0">
                <div className="content">
                  <div className="modal-header border-0 custom-modal-header">
                    <div className="page-title">
                      <h4>Show Payments</h4>
                    </div>
                    <button
                      type="button"
                      className="close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body custom-modal-body">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="modal-body-table total-orders">
                          <div className="table-responsive">
                            <table className="table  datanew">
                              <thead>
                                <tr>
                                  <th>Date</th>
                                  <th>Reference</th>
                                  <th>Amount</th>
                                  <th>Paid By</th>
                                  <th className="no-sort">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>19 Jan 2023</td>
                                  <td>INV/SL0101</td>
                                  <td>$1500</td>
                                  <td>Cash</td>
                                  <td className="action-table-data">
                                    <div className="edit-delete-action">
                                      <Link className="me-3 p-2" to="#">
                                        <i
                                          data-feather="printer"
                                          className="feather-rotate-ccw"
                                        />
                                      </Link>
                                      <Link
                                        className="me-3 p-2"
                                        to="#"
                                        data-bs-toggle="modal"
                                        data-bs-target="#editpayment"
                                      >
                                        <i
                                          data-feather="edit"
                                          className="feather-edit"
                                        />
                                      </Link>
                                      <Link className="confirm-text p-2" to="#">
                                        <i
                                          data-feather="trash-2"
                                          className="feather-trash-2"
                                        />
                                      </Link>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* show payment Modal */}
        {/* Create payment Modal */}
        <div
          className="modal fade"
          id="createpayment"
          tabIndex={-1}
          aria-labelledby="createpayment"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header border-0 custom-modal-header">
                <div className="page-title">
                  <h4>Create Payments</h4>
                </div>
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body custom-modal-body">
                <form>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="input-blocks">
                        <label> Date</label>
                        <div className="input-groupicon calender-input">
                          <DatePicker
                            selected={selectedDate}
                            onChange={handleDateChange}
                            type="date"
                            className="filterdatepicker"
                            dateFormat="dd-MM-yyyy"
                            placeholder="Choose Date"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-12 col-12">
                      <div className="input-blocks">
                        <label>Reference</label>
                        <input type="text" className="form-control" />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-4 col-sm-12 col-12">
                      <div className="input-blocks">
                        <label>Received Amount</label>
                        <div className="input-groupicon calender-input">
                          <i data-feather="dollar-sign" className="info-img" />
                          <input type="text" />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-12 col-12">
                      <div className="input-blocks">
                        <label>Paying Amount</label>
                        <div className="input-groupicon calender-input">
                          <i data-feather="dollar-sign" className="info-img" />
                          <input type="text" />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-12 col-12">
                      <div className="input-blocks">
                        <label>Payment type</label>

                        <Select
                          className="select"
                          options={paymenttype}
                          placeholder="Newest"
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="input-blocks">
                        <label>Description</label>
                        <textarea className="form-control" defaultValue={""} />
                        <p>Maximum 60 Characters</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="modal-footer-btn">
                      <button
                        type="button"
                        className="btn btn-cancel me-2"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </button>
                      <Link to="#" className="btn btn-submit">
                        Submit
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* Create payment Modal */}
        {/* edit payment Modal */}
        <div
          className="modal fade"
          id="editpayment"
          tabIndex={-1}
          aria-labelledby="editpayment"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header border-0 custom-modal-header">
                <div className="page-title">
                  <h4>Edit Payments</h4>
                </div>
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body custom-modal-body">
                <form>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="input-blocks">
                        <label>19 Jan 2023</label>
                        <div className="input-groupicon calender-input">
                          <i data-feather="calendar" className="info-img" />
                          <input
                            type="text"
                            className="datetimepicker form-control"
                            placeholder="Select Date"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-12 col-12">
                      <div className="input-blocks">
                        <label>Reference</label>
                        <input type="text" defaultValue="INV/SL0101" />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-4 col-sm-12 col-12">
                      <div className="input-blocks">
                        <label>Received Amount</label>
                        <div className="input-groupicon calender-input">
                          <i data-feather="dollar-sign" className="info-img" />
                          <input type="text" defaultValue={1500} />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-12 col-12">
                      <div className="input-blocks">
                        <label>Paying Amount</label>
                        <div className="input-groupicon calender-input">
                          <i data-feather="dollar-sign" className="info-img" />
                          <input type="text" defaultValue={1500} />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-12 col-12">
                      <div className="input-blocks">
                        <label>Payment type</label>
                        <select className="select">
                          <option>Cash</option>
                          <option>Online</option>
                          <option>Inprogress</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="input-blocks summer-description-box transfer">
                        <label>Description</label>
                        <textarea className="form-control" defaultValue={""} />
                      </div>
                      <p>Maximum 60 Characters</p>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="modal-footer-btn mb-3 me-3">
                      <button
                        type="button"
                        className="btn btn-cancel me-2"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </button>
                      <button type="submit" className="btn btn-submit">
                        Save Changes
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
      <SupplierModal from={"Purchase"} target="#add-purchase-new" />
      <AddProductModal from={"Purchase"} target="#add-purchase-new" />
      <Modal
        className={`${CSS.printPreviewModal}`}
        open={showPdfModal}
        footer={false}
        onCancel={() => {
          setShowPdfModal(false);
        }}
      >
        <div className={`${CSS.modalBox}`}>
          <PrintPreview fileName={pdfFileName.toLocaleLowerCase()} type={"pdf"}>
            <PrintLayout data={pdfData} />
          </PrintPreview>
        </div>
      </Modal>

      <TransportModal
        showTransportModal={showTransportModal}
        setShowTransportModal={setShowTransportModal}
        submitData={submitData}
        invoiceNumber={purchaseInvoiceNumber}
        from={"Purchase"}
        target="#add-purchase-new"
        data={{
          storeId: selected_store[0]?._id,
          userCode: login_data?.empCode,
        }}
      />
    </div>
  );
};

export default PurchasesList;
