/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Select from "react-select";
import ImageWithBasePath from "../../img/imagewithbasebath";
import { Link } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../../constants/AppConstants";
import { useDispatch, useSelector } from "react-redux";
import { alertData } from "../../../core/redux/action";
import { fetchStocksData } from "../../../utils/utilities";

const ManageStockModal = ({
  selectedStockData = {},
  setSelectedDataId = () => {},
}) => {
  const dispatch = useDispatch();
  const login_data = useSelector((state) => state.login_data);
  const selected_store = useSelector((state) => state.selected_store);
  const [isLoading, setIsLoading] = useState(false);
  const [productQty, setProductQty] = useState(
    selectedStockData?.productCurrentQty
  );
  const [productAlternateQty, setProductAlternateQty] = useState(
    selectedStockData?.productCurrentAlternateQty
  );

  useEffect(() => {
    setProductQty(selectedStockData?.productCurrentQty);
    setProductAlternateQty(selectedStockData?.productCurrentAlternateQty);
    console.log(selectedStockData);
  }, [selectedStockData]);

  function changeProductQty(value) {
    setProductQty(value);
    setProductAlternateQty(
      Number(value / selectedStockData?.productAlternateQty).toFixed(2)
    );
  }

  function changeProductAlternateQty(value) {
    setProductAlternateQty(value);
    setProductQty(
      Number(value * selectedStockData?.productAlternateQty).toFixed(2)
    );
  }

  async function submitData() {
    setIsLoading(true);
    try {
      let response = await axios.put(
        API_URL + "stocks/" + selectedStockData.productId,
        {
          ...selectedStockData,
          productCurrentQty: productQty,
          productCurrentAlternateQty: productAlternateQty,
          updatedOn: new Date().toLocaleDateString().split(",")[0],
        }
      );
      if (response.status === 200) {
        dispatch(
          alertData({
            type: "success",
            msg: "Stocks updated successfully!",
          })
        );
        setTimeout(() => {
          setIsLoading(false);
          document?.querySelector("#edit-units .close")?.click();
        }, 1000);

        fetchStocksData(login_data, selected_store[0]);
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  }

  return (
    <>
      {/* Edit Stock */}
      <div className="modal fade" id="edit-units">
        <div className="modal-dialog modal-dialog-centered stock-adjust-modal">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>Edit Stock</h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      setSelectedDataId("");
                    }}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <form>
                    <div className="row">
                      <div className="col-lg-9">
                        <div className="input-blocks">
                          <label>Product</label>
                          <input
                            value={selectedStockData?.productName}
                            disabled
                            type="text"
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="input-blocks">
                          <label>Item Code</label>
                          <input
                            value={selectedStockData?.productItemCode}
                            disabled
                            type="text"
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="input-blocks">
                          <label>Main Unit</label>
                          <input
                            value={selectedStockData?.productUnit?.label}
                            disabled
                            type="text"
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="col-lg-4">
                        <div className="input-blocks">
                          <label>Alternate Unit</label>
                          <input
                            value={selectedStockData?.productClubbedUnit?.label}
                            disabled
                            type="text"
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="input-blocks">
                          <label>Alternate Qty</label>
                          <input
                            value={selectedStockData?.productAlternateQty}
                            disabled
                            type="text"
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-blocks">
                          <label>
                            Current Stock(Main Unit :{" "}
                            {selectedStockData?.productUnit?.label})
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            value={productQty}
                            onChange={(e) => {
                              changeProductQty(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-blocks">
                          <label>
                            Current Stock(Alternate Unit :{" "}
                            {selectedStockData?.productClubbedUnit?.label})
                          </label>
                          <input
                            value={productAlternateQty}
                            type="number"
                            onChange={(e) => {
                              changeProductAlternateQty(e.target.value);
                            }}
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer-btn">
                      <button
                        type="button"
                        className="btn btn-cancel me-2"
                        data-bs-dismiss="modal"
                        onClick={() => setSelectedDataId("")}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className={`btn btn-submit ${
                          isLoading ? "disabled" : ""
                        }`}
                        onClick={submitData}
                      >
                        Save Changes
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Edit Stock */}
    </>
  );
};

export default ManageStockModal;
