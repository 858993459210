/* eslint-disable no-constant-condition */
/* eslint-disable no-empty */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { API_URL } from "../../../constants/AppConstants";
import { rolesData } from "../../../core/redux/action";
import { Spin } from "antd";
import { fetchRolesPermData } from "../../../utils/utilities";

const EditRole = () => {
  const dispatch = useDispatch();
  const detail_data = useSelector((state) => state.detail_data);
  const login_data = useSelector((state) => state.login_data);
  const roles_data = useSelector((state) => state.roles_data);
  const selected_store = useSelector((state) => state.selected_store);
  const [isLoading, setIsLoading] = useState(true);

  let isRolePresent = useRef(false);

  const [selectedData, setSelectedData] = useState({});
  const permissionArray = [
    {
      level: 1,
      levelName: "Inventory",
      selected: false,
      permissions: {
        create: false,
        read: false,
        update: false,
        delete: false,
      },
      data: [
        {
          level: 2,
          levelName: "Products",
          selected: false,
          permissions: {
            create: false,
            read: false,
            update: false,
            delete: false,
          },
          data: [
            // {
            //   level: 3,
            //   levelName: "level 31",
            //   selected: true,
            //   permissions: {
            //     create: false,
            //     read: false,
            //     update: false,
            //     delete: false,
            //   },
            // },
          ],
        },
        {
          level: 2,
          levelName: "Create Product",
          selected: false,
          permissions: {
            create: false,
            read: false,
            update: false,
            delete: false,
          },
          data: [],
        },
        {
          level: 2,
          levelName: "Low Stocks",
          selected: false,
          permissions: {
            create: false,
            read: false,
            update: false,
            delete: false,
          },
          data: [],
        },
        {
          level: 2,
          levelName: "Category",
          selected: false,
          permissions: {
            create: false,
            read: false,
            update: false,
            delete: false,
          },
          data: [],
        },
        {
          level: 2,
          levelName: "Sub Category",
          selected: false,
          permissions: {
            create: false,
            read: false,
            update: false,
            delete: false,
          },
          data: [],
        },
        {
          level: 2,
          levelName: "Brands",
          selected: false,
          permissions: {
            create: false,
            read: false,
            update: false,
            delete: false,
          },
          data: [],
        },
        {
          level: 2,
          levelName: "Units",
          selected: false,
          permissions: {
            create: false,
            read: false,
            update: false,
            delete: false,
          },
          data: [],
        },
      ],
    },
    {
      level: 1,
      levelName: "Sales",
      selected: false,
      permissions: {
        create: false,
        read: false,
        update: false,
        delete: false,
      },
      data: [
        {
          level: 2,
          levelName: "Sales",
          selected: false,
          permissions: {
            create: false,
            read: false,
            update: false,
            delete: false,
          },
          data: [
            // {
            //   level: 3,
            //   levelName: "level 31",
            //   selected: true,
            //   permissions: {
            //     create: false,
            //     read: false,
            //     update: false,
            //     delete: false,
            //   },
            // },
          ],
        },
        {
          level: 2,
          levelName: "Invoices",
          selected: false,
          permissions: {
            create: false,
            read: false,
            update: false,
            delete: false,
          },
          data: [],
        },
        {
          level: 2,
          levelName: "Sales Return",
          selected: false,
          permissions: {
            create: false,
            read: false,
            update: false,
            delete: false,
          },
          data: [],
        },
        {
          level: 2,
          levelName: "Quotation",
          selected: false,
          permissions: {
            create: false,
            read: false,
            update: false,
            delete: false,
          },
          data: [],
        },
      ],
    },
    {
      level: 1,
      levelName: "Purchases",
      selected: false,
      permissions: {
        create: false,
        read: false,
        update: false,
        delete: false,
      },
      data: [
        {
          level: 2,
          levelName: "Purchases",
          selected: false,
          permissions: {
            create: false,
            read: false,
            update: false,
            delete: false,
          },
          data: [
            // {
            //   level: 3,
            //   levelName: "level 31",
            //   selected: true,
            //   permissions: {
            //     create: false,
            //     read: false,
            //     update: false,
            //     delete: false,
            //   },
            // },
          ],
        },
        {
          level: 2,
          levelName: "Purchase Return",
          selected: false,
          permissions: {
            create: false,
            read: false,
            update: false,
            delete: false,
          },
          data: [],
        },
      ],
    },
    {
      level: 1,
      levelName: "Stock",
      selected: false,
      permissions: {
        create: false,
        read: false,
        update: false,
        delete: false,
      },
      data: [
        {
          level: 2,
          levelName: "Manage Stock",
          selected: false,
          permissions: {
            create: false,
            read: false,
            update: false,
            delete: false,
          },
          data: [
            // {
            //   level: 3,
            //   levelName: "level 31",
            //   selected: true,
            //   permissions: {
            //     create: false,
            //     read: false,
            //     update: false,
            //     delete: false,
            //   },
            // },
          ],
        },
      ],
    },
    {
      level: 1,
      levelName: "Finance & Accounts",
      selected: false,
      permissions: {
        create: false,
        read: false,
        update: false,
        delete: false,
      },
      data: [
        {
          level: 2,
          levelName: "Expenses",
          selected: false,
          permissions: {
            create: false,
            read: false,
            update: false,
            delete: false,
          },
          data: [
            // {
            //   level: 3,
            //   levelName: "level 31",
            //   selected: true,
            //   permissions: {
            //     create: false,
            //     read: false,
            //     update: false,
            //     delete: false,
            //   },
            // },
          ],
        },
      ],
    },
    {
      level: 1,
      levelName: "People",
      selected: false,
      permissions: {
        create: false,
        read: false,
        update: false,
        delete: false,
      },
      data: [
        {
          level: 2,
          levelName: "Customers",
          selected: false,
          permissions: {
            create: false,
            read: false,
            update: false,
            delete: false,
          },
          data: [
            // {
            //   level: 3,
            //   levelName: "level 31",
            //   selected: true,
            //   permissions: {
            //     create: false,
            //     read: false,
            //     update: false,
            //     delete: false,
            //   },
            // },
          ],
        },
        {
          level: 2,
          levelName: "Suppliers",
          selected: false,
          permissions: {
            create: false,
            read: false,
            update: false,
            delete: false,
          },
          data: [],
        },
        {
          level: 2,
          levelName: "Stores",
          selected: false,
          permissions: {
            create: false,
            read: false,
            update: false,
            delete: false,
          },
          data: [],
        },
        {
          level: 2,
          levelName: "Warehouses",
          selected: false,
          permissions: {
            create: false,
            read: false,
            update: false,
            delete: false,
          },
          data: [],
        },
      ],
    },
    {
      level: 1,
      levelName: "HRM",
      selected: false,
      permissions: {
        create: false,
        read: false,
        update: false,
        delete: false,
      },
      data: [
        {
          level: 2,
          levelName: "Employees",
          selected: false,
          permissions: {
            create: false,
            read: false,
            update: false,
            delete: false,
          },
          data: [
            // {
            //   level: 3,
            //   levelName: "level 31",
            //   selected: true,
            //   permissions: {
            //     create: false,
            //     read: false,
            //     update: false,
            //     delete: false,
            //   },
            // },
          ],
        },
      ],
    },
    {
      level: 1,
      levelName: "Reports",
      selected: false,
      permissions: {
        create: false,
        read: false,
        update: false,
        delete: false,
      },
      data: [
        {
          level: 2,
          levelName: "Sales Report",
          selected: false,
          permissions: {
            create: false,
            read: false,
            update: false,
            delete: false,
          },
          data: [
            // {
            //   level: 3,
            //   levelName: "level 31",
            //   selected: true,
            //   permissions: {
            //     create: false,
            //     read: false,
            //     update: false,
            //     delete: false,
            //   },
            // },
          ],
        },
        {
          level: 2,
          levelName: "Purchase Report",
          selected: false,
          permissions: {
            create: false,
            read: false,
            update: false,
            delete: false,
          },
          data: [],
        },
        {
          level: 2,
          levelName: "Inventory Report",
          selected: false,
          permissions: {
            create: false,
            read: false,
            update: false,
            delete: false,
          },
          data: [],
        },
        {
          level: 2,
          levelName: "Invoice Report",
          selected: false,
          permissions: {
            create: false,
            read: false,
            update: false,
            delete: false,
          },
          data: [],
        },
        {
          level: 2,
          levelName: "Supplier Report",
          selected: false,
          permissions: {
            create: false,
            read: false,
            update: false,
            delete: false,
          },
          data: [],
        },
        {
          level: 2,
          levelName: "Customer Report",
          selected: false,
          permissions: {
            create: false,
            read: false,
            update: false,
            delete: false,
          },
          data: [],
        },
        {
          level: 2,
          levelName: "Expense Report",
          selected: false,
          permissions: {
            create: false,
            read: false,
            update: false,
            delete: false,
          },
          data: [],
        },
        {
          level: 2,
          levelName: "Income Report",
          selected: false,
          permissions: {
            create: false,
            read: false,
            update: false,
            delete: false,
          },
          data: [],
        },
        {
          level: 2,
          levelName: "Tax Report",
          selected: false,
          permissions: {
            create: false,
            read: false,
            update: false,
            delete: false,
          },
          data: [],
        },
        {
          level: 2,
          levelName: "Profit & Loss",
          selected: false,
          permissions: {
            create: false,
            read: false,
            update: false,
            delete: false,
          },
          data: [],
        },
      ],
    },
  ];

  const [levelPermissionArr, setLevelPermissionArr] = useState([]);

  useEffect(() => {
    if (detail_data?._id) {
      if (detail_data.id == 2) {
        setSelectedData({
          roleId: detail_data.id,
          roleName: "Store Manager",
          permissions: [],
        });
      } else if (detail_data.id == 3) {
        setSelectedData({
          roleId: detail_data.id,
          roleName: "Accountant",
          permissions: [],
        });
      } else if (detail_data.id == 4) {
        setSelectedData({
          roleId: detail_data.id,
          roleName: "Sales Manager",
          permissions: [],
        });
      } else if (detail_data.id == 5) {
        setSelectedData({
          roleId: detail_data.id,
          roleName: "Sales Person",
          permissions: [],
        });
      }
    }
  }, [detail_data]);

  useEffect(() => {
    fetchRolesPermData(login_data, selected_store[0]);
  }, [detail_data, selected_store]);

  useEffect(() => {
    if (detail_data?._id) {
      if (roles_data?.length) {
        isRolePresent.current = false;
        const deepCopy = JSON.parse(JSON.stringify(roles_data));
        deepCopy.forEach((data) => {
          if (data?.roleId == detail_data?._id) {
            isRolePresent.current = true;
            setLevelPermissionArr(data.permissions);
          }
        });
        if (!isRolePresent.current) {
          setLevelPermissionArr(permissionArray);
        }
      } else {
        setLevelPermissionArr(permissionArray);
      }
    }
  }, [detail_data, roles_data]);

  async function submitData() {
    let dataBody = {
      roleId: selectedData?.roleId,
      roleName: selectedData?.roleName,
      storeId: selected_store[0]?._id,
      permissions: levelPermissionArr,
      createdOn: new Date().toLocaleDateString().split(",")[0],
      userCode: login_data?.empCode,
      itemActive: true,
    };
    try {
      let response = null;
      if (!isRolePresent.current) {
        response = await axios.post(API_URL + "roles_perm", dataBody);
      } else {
        response = await axios.put(
          API_URL + "roles_perm/" + dataBody.roleId,
          dataBody
        );
      }
      if (response.status === 200) {
        fetchRolesPermData(login_data, selected_store[0]);
      }
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <div>
      {/* Edit Role */}
      <div className="modal fade edit-role-modal" id="edit-units">
        <div className="modal-dialog modal-dialog-centered custom-modal-two">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>Edit Permissions</h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <form>
                    <div className="mb-20">
                      <label className="form-label">Role Name</label>
                      <input
                        disabled
                        type="text"
                        className="form-control"
                        value={selectedData?.roleName}
                      />
                    </div>
                    <div className="data-table">
                      {isLoading ? (
                        <>
                          <Spin
                            size="large"
                            style={{
                              position: "relative",
                              display: "flex",
                              justifyContent: "center",
                              width: "100%",
                              marginTop: 30,
                            }}
                          />
                        </>
                      ) : (
                        <>
                          <div className="permissions-form">
                            <label className="form-label">Permissions</label>
                            {levelPermissionArr.length &&
                              levelPermissionArr.map((level, id1) => {
                                return (
                                  <React.Fragment key={id1}>
                                    <div
                                      className={`level-${level?.level}-perm`}
                                    >
                                      <div className="level-perm-label">
                                        <input
                                          checked={level?.selected}
                                          type="checkbox"
                                          onChange={() => {
                                            let newArr = [
                                              ...levelPermissionArr,
                                            ];
                                            newArr[id1] = {
                                              ...newArr[id1],
                                              selected: !level?.selected,
                                            };
                                            if (!newArr[id1].selected) {
                                              newArr[id1].data.map((level2) => {
                                                level2.selected = false;
                                                level2.permissions.create = false;
                                                level2.permissions.read = false;
                                                level2.permissions.update = false;
                                                level2.permissions.delete = false;
                                              });
                                            } else {
                                              newArr[id1].data.map((level2) => {
                                                level2.selected = true;
                                                level2.permissions.create = false;
                                                level2.permissions.read = true;
                                                level2.permissions.update = false;
                                                level2.permissions.delete = false;
                                              });
                                            }
                                            setLevelPermissionArr(newArr);
                                          }}
                                        />
                                        <label className="form-label">
                                          {level?.levelName}
                                        </label>
                                      </div>
                                      {level?.data?.length &&
                                      level?.selected ? (
                                        level?.data.map((level2, id2) => {
                                          return (
                                            <>
                                              <div
                                                key={id2}
                                                className={`level-${level2.level}-perm child-level`}
                                              >
                                                <div className="level-perm-label">
                                                  <input
                                                    checked={level2.selected}
                                                    type="checkbox"
                                                    onChange={(e) => {
                                                      let newArr = [
                                                        ...levelPermissionArr,
                                                      ];
                                                      newArr[id1].data[id2] = {
                                                        ...newArr[id1].data[
                                                          id2
                                                        ],
                                                        selected:
                                                          !newArr[id1].data[id2]
                                                            .selected,
                                                      };
                                                      if (
                                                        !newArr[id1].data[id2]
                                                          .selected
                                                      ) {
                                                        newArr[id1].data[
                                                          id2
                                                        ].permissions.create = false;
                                                        newArr[id1].data[
                                                          id2
                                                        ].permissions.read = false;
                                                        newArr[id1].data[
                                                          id2
                                                        ].permissions.update = false;
                                                        newArr[id1].data[
                                                          id2
                                                        ].permissions.delete = false;
                                                      } else {
                                                        newArr[id1].data[
                                                          id2
                                                        ].permissions.create = false;
                                                        newArr[id1].data[
                                                          id2
                                                        ].permissions.read = true;
                                                        newArr[id1].data[
                                                          id2
                                                        ].permissions.update = false;
                                                        newArr[id1].data[
                                                          id2
                                                        ].permissions.delete = false;

                                                        // newArr[id1].data[id2].map(
                                                        //   (level2) => {
                                                        //     level2.permissions.create = false;
                                                        //     level2.permissions.read = true;
                                                        //     level2.permissions.update = false;
                                                        //     level2.permissions.delete = false;
                                                        //   }
                                                        // );
                                                      }
                                                      // setLevelPermissionArr(
                                                      //   newArr
                                                      // );
                                                    }}
                                                  />
                                                  <label className="form-label">
                                                    {level2.levelName}
                                                  </label>
                                                </div>
                                                {/* {level2.data?.length &&
                                          level2.selected ? (
                                            level2.data.map((level3, id3) => {
                                              return (
                                                <div
                                                  key={id3}
                                                  className={`level-${level3.level}-perm child-level`}
                                                >
                                                  <div className="level-perm-label">
                                                    <input
                                                      checked={level3.selected}
                                                      type="checkbox"
                                                      onChange={(e) => {
                                                        let newArr = [
                                                          ...levelPermissionArr,
                                                        ];
                                                        newArr[id1].data[
                                                          id2
                                                        ].data[id3] = {
                                                          ...newArr[id1].data[
                                                            id2
                                                          ].data[id3],
                                                          selected:
                                                            !newArr[id1].data[
                                                              id2
                                                            ].data[id3]
                                                              .selected,
                                                        };
                                                        setLevelPermissionArr(
                                                          newArr
                                                        );
                                                      }}
                                                    />
                                                    <label className="form-label">
                                                      {level3.levelName}
                                                    </label>
                                                  </div>
                                                </div>
                                              );
                                            })
                                          ) : (
                                            <></>
                                          )} */}
                                              </div>
                                            </>
                                          );
                                        })
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  </React.Fragment>
                                );
                              })}
                          </div>
                          <div className="create-column">
                            <label className="heading">Create</label>
                            {levelPermissionArr.length &&
                              levelPermissionArr.map((level, id1) => {
                                return (
                                  <React.Fragment key={id1}>
                                    <div className="checkbox-container">
                                      {/* <input
                                        disabled={!level?.selected}
                                        type="checkbox"
                                        checked={level?.permissions.create}
                                        onChange={() => {
                                          let newArr = [...levelPermissionArr];
                                          newArr[id1] = {
                                            ...newArr[id1],
                                            permissions: {
                                              ...level?.permissions,
                                              create:
                                                !level?.permissions.create,
                                            },
                                          };
                                          setLevelPermissionArr(newArr);
                                        }}
                                      /> */}
                                    </div>
                                    {level?.selected &&
                                      level?.data.map((level2, id2) => {
                                        return (
                                          <React.Fragment key={id2}>
                                            <div className="checkbox-container">
                                              <input
                                                type="checkbox"
                                                disabled={!level2.selected}
                                                checked={
                                                  level2.permissions.create
                                                }
                                                onChange={() => {
                                                  let newArr = [
                                                    ...levelPermissionArr,
                                                  ];
                                                  newArr[id1].data[id2] = {
                                                    ...newArr[id1].data[id2],
                                                    permissions: {
                                                      ...level2.permissions,
                                                      create:
                                                        !level2.permissions
                                                          .create,
                                                    },
                                                  };
                                                  setLevelPermissionArr(newArr);
                                                }}
                                              />
                                            </div>
                                            {/* {level2.selected &&
                                        level2.data.map((level3, id3) => {
                                          return (
                                            <React.Fragment key={id3}>
                                              <div className="checkbox-container">
                                                <input type="checkbox" />
                                              </div>
                                            </React.Fragment>
                                          );
                                        })} */}
                                          </React.Fragment>
                                        );
                                      })}
                                  </React.Fragment>
                                );
                              })}
                          </div>
                          <div className="read-column">
                            <label className="heading">Read</label>
                            {levelPermissionArr.map((level, id1) => {
                              return (
                                <React.Fragment key={id1}>
                                  <div className="checkbox-container">
                                    {/* <input
                                      disabled={!level?.selected}
                                      type="checkbox"
                                      checked={level?.permissions.read}
                                      onChange={() => {
                                        let newArr = [...levelPermissionArr];
                                        newArr[id1] = {
                                          ...newArr[id1],
                                          permissions: {
                                            ...level?.permissions,
                                            read: !level?.permissions.read,
                                          },
                                        };
                                        setLevelPermissionArr(newArr);
                                      }}
                                    /> */}
                                  </div>
                                  {level?.selected &&
                                    level?.data.map((level2, id2) => {
                                      return (
                                        <React.Fragment key={id2}>
                                          <div className="checkbox-container">
                                            <input
                                              type="checkbox"
                                              disabled={!level2.selected}
                                              checked={level2.permissions.read}
                                              onChange={() => {
                                                let newArr = [
                                                  ...levelPermissionArr,
                                                ];
                                                newArr[id1].data[id2] = {
                                                  ...newArr[id1].data[id2],
                                                  permissions: {
                                                    ...level2.permissions,
                                                    read: !level2.permissions
                                                      .read,
                                                  },
                                                };
                                                setLevelPermissionArr(newArr);
                                              }}
                                            />
                                          </div>
                                          {/* {level2.selected &&
                                        level2.data.map((level3, id3) => {
                                          return (
                                            <React.Fragment key={id3}>
                                              <div className="checkbox-container">
                                                <input type="checkbox" />
                                              </div>
                                            </React.Fragment>
                                          );
                                        })} */}
                                        </React.Fragment>
                                      );
                                    })}
                                </React.Fragment>
                              );
                            })}
                          </div>
                          <div className="update-column">
                            <label className="heading">Update</label>
                            {levelPermissionArr.map((level, id1) => {
                              return (
                                <React.Fragment key={id1}>
                                  <div className="checkbox-container">
                                    {/* <input
                                      disabled={!level?.selected}
                                      type="checkbox"
                                      checked={level?.permissions.update}
                                      onChange={() => {
                                        let newArr = [...levelPermissionArr];
                                        newArr[id1] = {
                                          ...newArr[id1],
                                          permissions: {
                                            ...level?.permissions,
                                            create: !level?.permissions.update,
                                          },
                                        };
                                        setLevelPermissionArr(newArr);
                                      }}
                                    /> */}
                                  </div>
                                  {level?.selected &&
                                    level?.data.map((level2, id2) => {
                                      return (
                                        <React.Fragment key={id2}>
                                          <div className="checkbox-container">
                                            <input
                                              type="checkbox"
                                              disabled={!level2.selected}
                                              checked={
                                                level2.permissions.update
                                              }
                                              onChange={() => {
                                                let newArr = [
                                                  ...levelPermissionArr,
                                                ];
                                                newArr[id1].data[id2] = {
                                                  ...newArr[id1].data[id2],
                                                  permissions: {
                                                    ...level2.permissions,
                                                    update:
                                                      !level2.permissions
                                                        .update,
                                                  },
                                                };
                                                setLevelPermissionArr(newArr);
                                              }}
                                            />
                                          </div>
                                          {/* {level2.selected &&
                                        level2.data.map((level3, id3) => {
                                          return (
                                            <React.Fragment key={id3}>
                                              <div className="checkbox-container">
                                                <input type="checkbox" />
                                              </div>
                                            </React.Fragment>
                                          );
                                        })} */}
                                        </React.Fragment>
                                      );
                                    })}
                                </React.Fragment>
                              );
                            })}
                          </div>
                          <div className="delete-column">
                            <label className="heading">Delete</label>
                            {levelPermissionArr.map((level, id1) => {
                              return (
                                <React.Fragment key={id1}>
                                  <div className="checkbox-container">
                                    {/* <input
                                      disabled={!level?.selected}
                                      type="checkbox"
                                      checked={level?.permissions.delete}
                                      onChange={() => {
                                        let newArr = [...levelPermissionArr];
                                        newArr[id1] = {
                                          ...newArr[id1],
                                          permissions: {
                                            ...level?.permissions,
                                            delete: !level?.permissions.delete,
                                          },
                                        };
                                        setLevelPermissionArr(newArr);
                                      }}
                                    /> */}
                                  </div>
                                  {level?.selected &&
                                    level?.data.map((level2, id2) => {
                                      return (
                                        <React.Fragment key={id2}>
                                          <div className="checkbox-container">
                                            <input
                                              type="checkbox"
                                              disabled={!level2.selected}
                                              checked={
                                                level2.permissions.delete
                                              }
                                              onChange={() => {
                                                let newArr = [
                                                  ...levelPermissionArr,
                                                ];
                                                newArr[id1].data[id2] = {
                                                  ...newArr[id1].data[id2],
                                                  permissions: {
                                                    ...level2.permissions,
                                                    delete:
                                                      !level2.permissions
                                                        .delete,
                                                  },
                                                };
                                                setLevelPermissionArr(newArr);
                                              }}
                                            />
                                          </div>
                                          {/* {level2.selected &&
                                        level2.data.map((level3, id3) => {
                                          return (
                                            <React.Fragment key={id3}>
                                              <div className="checkbox-container">
                                                <input type="checkbox" />
                                              </div>
                                            </React.Fragment>
                                          );
                                        })} */}
                                        </React.Fragment>
                                      );
                                    })}
                                </React.Fragment>
                              );
                            })}
                          </div>
                        </>
                      )}
                    </div>
                    <div className="modal-footer-btn">
                      <button
                        type="button"
                        className="btn btn-cancel me-2"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </button>
                      <Link
                        to="#"
                        className="btn btn-submit"
                        data-bs-dismiss="modal"
                        onClick={(e) => {
                          e.preventDefault();
                          submitData();
                        }}
                      >
                        Save Changes
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Edit Role */}
    </div>
  );
};

export default EditRole;
