/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { Button, message, Space } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { alertData } from "../core/redux/action";

const AlertBox = () => {
  const alert_data = useSelector((state) => state.alert_data);
  const [messageApi, contextHolder] = message.useMessage();
  const dispatch = useDispatch();

  useEffect(() => {
    if (alert_data.type == "success") success();
    else if (alert_data.type == "error") error();
    else if (alert_data.type == "warning") warning();
  }, [alert_data]);

  const success = () => {
    messageApi.open({
      type: "success",
      content: alert_data.msg,
    });
    dispatch(alertData({}));
  };
  const error = () => {
    messageApi.open({
      type: "error",
      content: alert_data.msg,
    });
    dispatch(alertData({}));
  };
  const warning = () => {
    messageApi.open({
      type: "warning",
      content: alert_data.msg,
    });
    dispatch(alertData({}));
  };

  return <>{contextHolder}</>;
};
export default AlertBox;
