/* eslint-disable no-empty */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Spin } from "antd";
import Scrollbars from "react-custom-scrollbars-2";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { SidebarData } from "../../core/json/siderbar_data";
import HorizontalSidebar from "./horizontalSidebar";
import CollapsedSidebar from "./collapsedSidebar";

const Sidebar = () => {
  const Location = useLocation();
  const selected_store = useSelector((state) => state.selected_store);
  const login_data = useSelector((state) => state.login_data);
  const roles_data = useSelector((state) => state.roles_data);

  const [subOpen, setSubopen] = useState("");
  const [subsidebar, setSubsidebar] = useState("");
  const [filterSidebarData, setFilteredSidebarData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (login_data?.roleId && SidebarData.length) {
      let sidebarArr = [...SidebarData];
      let newArr = [];
      let level2NewArr = [];

      if (login_data?.roleId == 1) {
        setFilteredSidebarData(sidebarArr);
      } else {
        if (roles_data?.length) {
          if (login_data?.roleId != 1) {
            sidebarArr.forEach((menu) => {
              if (menu.label == "Main") {
                newArr.push({
                  ...menu,
                });
              }

              roles_data[0].permissions.forEach((role) => {
                level2NewArr = [];
                if (menu.label == role.levelName && role.selected) {
                  menu.submenuItems.forEach((submenu) => {
                    role.data.forEach((level2) => {
                      if (
                        level2.levelName == submenu.label &&
                        level2.selected
                      ) {
                        level2NewArr.push({
                          ...submenu,
                        });
                      }
                    });
                  });
                  newArr.push({
                    ...menu,
                    submenuItems: level2NewArr,
                  });
                }
              });
            });
            setFilteredSidebarData(newArr);
          } else {
            setFilteredSidebarData(sidebarArr);
          }
        }
      }
    }
  }, [login_data, SidebarData, roles_data]);

  useEffect(() => {
    if (filterSidebarData.length) {
      setIsLoading(false);
    }
  }, [filterSidebarData]);

  const sidebarShow = () => {
    document?.querySelector(".main-wrapper")?.classList?.toggle("slide-nav");
    document?.querySelector(".sidebar-overlay")?.classList?.toggle("opened");
    document?.querySelector("html")?.classList?.toggle("menu-opened");
  };

  const toggleSidebar = (title) => {
    if (title == subOpen) {
      setSubopen("");
    } else {
      setSubopen(title);
    }
  };

  const toggleSubsidebar = (subitem) => {
    if (subitem == subsidebar) {
      setSubsidebar("");
    } else {
      setSubsidebar(subitem);
    }
  };


  return (
    <div>
      <div className="sidebar" id="sidebar">
        <Scrollbars>
          <div className="sidebar-inner slimscroll">
            <div id="sidebar-menu" className="sidebar-menu">
              {!isLoading ? (
                <ul>
                  {filterSidebarData?.map((mainLabel, index) => (
                    <li
                      className="submenu-open"
                      style={{
                        pointerEvents: !selected_store?.length ? "none" : "",
                        opacity: !selected_store?.length ? 0.5 : 1,
                      }}
                      key={index}
                    >
                      <h6 className="submenu-hdr">{mainLabel?.label}</h6>
                      <ul>
                        {mainLabel?.submenuItems?.map((title, i) => {
                          // Generate a unique key for each item in this map function
                          return (
                            <li className="submenu" key={i}>
                              <div
                                onClick={() => {
                                  toggleSidebar(title?.label);
                                  if (!title?.submenuItems) {
                                    sidebarShow();
                                    navigate(title?.link);
                                  }
                                }}
                                className={`link ${subOpen == title?.label ? "subdrop" : ""
                                  } ${title?.link?.includes(Location.pathname) ||
                                    (title?.label == "Expenses" &&
                                      (Location.pathname.includes("expense-list") ||
                                        Location.pathname.includes("expense-category")))
                                    ? "active"
                                    : ""
                                  }`}
                              >
                                {title?.icon}
                                <span>{title?.label}</span>
                                <span className={title?.submenu ? "menu-arrow" : ""} />
                              </div>
                              <ul
                                style={{
                                  display: subOpen == title?.label ? "block" : "none",
                                }}
                              >
                                {title?.submenuItems?.map((item, titleIndex) => (
                                  <li className="submenu submenu-two" key={titleIndex}> {/* Add key here */}
                                    <div
                                      className={`link 
                      ${item?.submenuItems
                                          ?.map((link) => link?.link)
                                          .includes(Location.pathname) ||
                                          item?.link == Location.pathname
                                          ? "link active"
                                          : ""
                                        }
                    `}
                                      onClick={() => {
                                        toggleSubsidebar(item?.label);
                                        if (!item?.submenuItems) {
                                          sidebarShow();
                                          navigate(item?.link);
                                        }
                                      }}
                                    >
                                      {item?.label}
                                      <span className={item?.submenu ? "menu-arrow" : ""} />
                                    </div>
                                    <ul
                                      style={{
                                        display: subsidebar == item?.label ? "block" : "none",
                                      }}
                                    >
                                      {item?.submenuItems?.map((items, subIndex) => (
                                        <li key={subIndex}> {/* Add key here */}
                                          <div
                                            className={`link ${subsidebar == items?.label
                                                ? "submenu-two subdrop"
                                                : "submenu-two"
                                              } ${items?.submenuItems
                                                ?.map((link) => link.link)
                                                .includes(Location.pathname) ||
                                                items?.link == Location.pathname
                                                ? "active"
                                                : ""
                                              }`}
                                            onClick={() => {
                                              sidebarShow();
                                              navigate(items?.link);
                                            }}
                                          >
                                            {items?.label}
                                          </div>
                                        </li>
                                      ))}
                                    </ul>
                                  </li>
                                ))}
                              </ul>
                            </li>
                          );
                        })}
                      </ul>
                    </li>
                  ))}

                </ul>
              ) : (
                <Spin
                  size="large"
                  style={{ position: "fixed", top: "50%", left: "120px" }}
                />
              )}
            </div>
          </div>
        </Scrollbars>
      </div>
      <HorizontalSidebar />
      <CollapsedSidebar />
    </div>
  );
};

export default Sidebar;
