/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import {
  Archive,
  Box,
  ChevronUp,
  Mail,
  PlusCircle,
  RotateCcw,
  Sliders,
  Zap,
  
} from "feather-icons-react/build/IconComponents";
import { useDispatch, useSelector } from "react-redux";
import {
  alertData,
  setToogleHeader,
  stocksData,
} from "../../core/redux/action";
import Select from "react-select";
import { Filter } from "react-feather";
import EditLowStock from "../../core/modals/inventory/editlowstock";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import Table from "../../core/pagination/datatable";
import { API_URL } from "../../constants/AppConstants";
import axios from "axios";
import moment from "moment";
import {
  fetchCustomersData,
  fetchProductsData,
  fetchSalesData,
  fetchStocksData,
  fetchSuppliersData,
  fetchBankData,
  fetchReceiptsData,
} from "../../utils/utilities";
import { Button, DatePicker, Modal, Switch } from "antd";

const BankAccount = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.toggle_header);
  const login_data = useSelector((state) => state.login_data);
  const selected_store = useSelector((state) => state.selected_store);
  const bank_data = useSelector((state) => state.bank_data);
  const bank_data_length = useSelector((state) => state.bank_data_length);
  const customer_data = useSelector((state) => state.customer_data);
  const supplier_data = useSelector((state) => state.supplier_data);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [stocksArr, setStocksArr] = useState([]);
  const [lowStocks, setLowStocks] = useState([]);
  const [expiredList, setExpiredList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredLowStocks, setfilteredLowStocks] = useState(lowStocks);
  const [filteredExpiredList, setfilteredExpiredList] = useState(expiredList);
  const [myBankList, setMyBankList] = useState(
    bank_data.filter((item) => item.userType == "self")
  );
  const [otherBankList, setOtherBankList] = useState(
    bank_data.filter((item) => item.userType != "self")
  );
  const [filteredBankList, setFilteredBankList] = useState(myBankList);
  const [activeTab, setActiveTab] = useState("myBank");
  const [showUserModal, setShowUserModal] = useState(false);
  const [bankAccountType, setBankAccountType] = useState("");
  const [primary, setPrimary] = useState(false);
  const [name, setName] = useState("");
  const [bankAccountNumber, setBankAccountNumber] = useState("");
  const [bankName, setBankName] = useState("");
  const [accountHolderName, setAccountHolderName] = useState("");
  const [ifscCode, setIfscCode] = useState("");
  const [branchName, setBranchName] = useState("");
  const [userType, setUserType] = useState(null);
  const [userDetail, setUserDetail] = useState(null);

  let userOption = [];

  const oldandlatestvalue = [
    { value: "date", label: "Sort by Date" },
    // { value: 'newest', label: 'Newest' },
    // { value: 'oldest', label: 'Oldest' },
  ];
  const productlist = [
    { value: "chooseProduct", label: "Choose Product" },
    // { value: 'lenovo3rdGen', label: 'Lenovo 3rd Generation' },
    // { value: 'nikeJordan', label: 'Nike Jordan' },
    // { value: 'amazonEchoDot', label: 'Amazon Echo Dot' },
  ];
  const category = [
    { value: "chooseCategory", label: "Choose Category" },
    // { value: 'laptop', label: 'Laptop' },
    // { value: 'shoe', label: 'Shoe' },
    // { value: 'speaker', label: 'Speaker' },
  ];
  const warehouse = [
    { value: "chooseWarehouse", label: "Choose Warehouse" },
    // { value: 'lavishWarehouse', label: 'Lavish Warehouse' },
    // { value: 'lobarHandy', label: 'Lobar Handy' },
    // { value: 'traditionalWarehouse', label: 'Traditional Warehouse' },
  ];
  const renderTooltip = (props) => (
    <Tooltip id="pdf-tooltip" {...props}>
      Pdf
    </Tooltip>
  );
  const renderExcelTooltip = (props) => (
    <Tooltip id="excel-tooltip" {...props}>
      Excel
    </Tooltip>
  );
  const renderPrinterTooltip = (props) => (
    <Tooltip id="printer-tooltip" {...props}>
      Printer
    </Tooltip>
  );
  const renderRefreshTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Refresh
    </Tooltip>
  );
  const renderCollapseTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Collapse
    </Tooltip>
  );

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Account Number",
      dataIndex: "accountNumber",
    },
    {
      title: "Bank Name",
      dataIndex: "bankName",
    },
    {
      title: "Account Holder Name",
      dataIndex: "accountHolderName",
    },
    {
      title: "Account Type",
      dataIndex: "accountType",
    },
    {
      title: "Branch Name",
      dataIndex: "branchName",
    },
    {
      title: "User Type",
      dataIndex: "userType",
    },
  ];

  useEffect(() => {
    fetchCustomersData(login_data, selected_store[0]);
    fetchSuppliersData(login_data, selected_store[0]);
    fetchStocksData(login_data, selected_store[0]);
    fetchSalesData(login_data, selected_store[0]);
    fetchProductsData(login_data, selected_store[0]);
    fetchReceiptsData(login_data, selected_store[0]);
    fetchBankData(login_data, selected_store[0]);
  }, [selected_store]);

  useEffect(() => {
    if (myBankList && activeTab === "myBank") {
      const arr = myBankList.filter(
        (item) =>
          item.bankName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.accountNumber.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.accountHolderName
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          item.ifscCode.toLowerCase().includes(searchTerm.toLowerCase())
      );
      console.log(arr);
      if (searchTerm.length > 0) {
        setFilteredBankList(arr);
      } else {
        setFilteredBankList(myBankList);
      }
    } else {
      const arr = otherBankList.filter(
        (item) =>
          item.bankName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.accountNumber.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.accountHolderName
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          item.ifscCode.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.userType.toLowerCase().includes(searchTerm.toLowerCase())
      );
      if (searchTerm.length > 0) {
        setFilteredBankList(arr);
      } else {
        setFilteredBankList(otherBankList);
      }
    }
  }, [searchTerm, myBankList, otherBankList, activeTab]);


  

  useEffect(() => {
    if (activeTab == "myBank") {
      setFilteredBankList(myBankList);
    } else {
      setFilteredBankList(otherBankList);
    }
    console.log(bank_data)
  }, [activeTab]);

  const toggle = (checked) => {
    setPrimary(checked);
  };


  const clearData = ()=> {
    setName("")
    setBankAccountNumber("")
    setBankName("")
    setAccountHolderName("")
    setBankAccountType(null)
    setIfscCode("")
    setBranchName("")
  }

  const submitBankAccount = async () => {
    if (
      !name ||
      !bankAccountNumber ||
      !bankName ||
      !accountHolderName ||
      !bankAccountType ||
      !ifscCode ||
      !branchName ||
      (!primary && !userType)
    ) {
      dispatch(
        alertData({
          type: "error",
          msg: "Please fill all the required fields",
        })
      );
      return;
    }

    try {

      console.log(selected_store)

      const formData = {
        name,
        accountNumber : bankAccountNumber,
        bankName,
        accountHolderName,
        accountType: bankAccountType.value,
        ifscCode,
        branchName,
        userType: activeTab == "myBank" ? "self" : userType.value,
        primary,
        storeId: selected_store[0]?._id,
        balance: 0,
        userCode: login_data.empCode,
      };

      console.log(formData);

      const response = await axios.post(API_URL + "bankaccount", formData);
      console.log(response);
      if (response.status === 201) {
        console.log("Bank Account Added Successfully:", response.data);
        setShowUserModal(false);
        clearData();
        fetchBankData(login_data, selected_store[0]);
        dispatch(
          alertData({
            type: "success",
            msg: "Bank Account Added Successfully",
          })
        );
      }
    } catch (error) {
      console.error("Error submitting receipt:", error.message);
    }
  };

  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="page-title me-auto">
              <h4>Bank Accounts</h4>
              <h6>Manage your bank accounts</h6>
            </div>
            <ul className="table-top-head">
              {/* <li>
                <div className="status-toggle d-flex justify-content-between align-items-center">
                  <input
                    type="checkbox"
                    id="user2"
                    className="check"
                    defaultChecked="true"
                  />
                  <label htmlFor="user2" className="checktoggle">
                    checkbox
                  </label>
                  Notify
                </div>
              </li> */}
              <li>
                <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top">
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderCollapseTooltip}>
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    id="collapse-header"
                    className={data ? "active" : ""}
                    onClick={() => {
                      dispatch(setToogleHeader(!data));
                    }}
                  >
                    <ChevronUp />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
            <div className="page-btn">
              <Link
                to="#"
                className="btn btn-added"
                // data-bs-target="#add-bankaccount"
                onClick={() => setShowUserModal(true)}
              >
                <PlusCircle className="me-2" />
                Add Bank Account
              </Link>
            </div>
          </div>
          <div className="table-tab">
            <ul className="nav nav-pills" id="pills-tab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="pills-home-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-home"
                  type="button"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected="true"
                  onClick={() => setActiveTab("myBank")}
                >
                  My Accounts
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="pills-profile-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-profile"
                  type="button"
                  role="tab"
                  aria-controls="pills-profile"
                  aria-selected="false"
                  onClick={() => setActiveTab("otherBank")}
                >
                  Others Accounts
                </button>
              </li>
            </ul>
            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                {/* /product list */}
                <div className="card table-list-card">
                  <div className="card-body">
                    <div className="table-top">
                      <div className="search-set">
                        <div className="search-input">
                          <input
                            type="text"
                            placeholder="Search by product, item code or sku"
                            className="form-control form-control-sm formsearch"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                          />
                          <Link to className="btn btn-searchset">
                            <i
                              data-feather="search"
                              className="feather-search"
                            />
                          </Link>
                        </div>
                      </div>
                      {/* <div className="search-path">
                        <Link
                          className={`btn btn-filter ${isFilterVisible ? "setclose" : ""
                            }`}
                          id="filter_search"
                        >
                          <Filter
                            className="filter-icon"
                            
                          />
                          <span >
                            <ImageWithBasePath
                              src="assets/img/icons/closes.svg"
                              alt="img"
                            />
                          </span>
                        </Link>
                      </div> */}
                      {/* <div className="form-sort">
                        <Sliders className="info-img" />
                        <Select
                          className="select"
                          options={oldandlatestvalue}
                          placeholder="Newest"
                        />
                      </div> */}
                    </div>
                    {/* /Filter */}
                    <div
                      className={`card${isFilterVisible ? " visible" : ""}`}
                      id="filter_inputs"
                      style={{ display: isFilterVisible ? "block" : "none" }}
                    >
                      {" "}
                      <div className="card-body pb-0">
                        <div className="row">
                          <div className="col-lg-3 col-sm-6 col-12">
                            <div className="input-blocks">
                              <Box className="info-img" />
                              <Select
                                options={productlist}
                                className="select"
                                placeholder="Choose Product"
                              />
                            </div>
                          </div>
                          <div className="col-lg-3 col-sm-6 col-12">
                            <div className="input-blocks">
                              <i data-feather="zap" className="info-img" />
                              <Zap className="info-img" />
                              <Select
                                options={category}
                                className="select"
                                placeholder="Choose Product"
                              />
                            </div>
                          </div>
                          <div className="col-lg-3 col-sm-6 col-12">
                            <div className="input-blocks">
                              <Archive className="info-img" />
                              <Select
                                options={warehouse}
                                className="select"
                                placeholder="Choose Warehouse"
                              />
                            </div>
                          </div>
                          <div className="col-lg-3 col-sm-6 col-12 ms-auto">
                            <div className="input-blocks">
                              <Link className="btn btn-filters ms-auto">
                                {" "}
                                <i
                                  data-feather="search"
                                  className="feather-search"
                                />{" "}
                                Search{" "}
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* /Filter */}
                    <div className="table-responsive">
                      <Table columns={columns} dataSource={filteredBankList} />
                    </div>
                  </div>
                </div>
                {/* /product list */}
              </div>
              <div
                className="tab-pane fade"
                id="pills-profile"
                role="tabpanel"
                aria-labelledby="pills-profile-tab"
              >
                {/* /product list */}
                <div className="card table-list-card">
                  <div className="card-body">
                    <div className="table-top">
                      <div className="search-set">
                        <div className="search-input">
                          <input
                            type="text"
                            placeholder="Search by product, item code or sku"
                            className="form-control form-control-sm formsearch"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                          />
                          <Link to className="btn btn-searchset">
                            <i
                              data-feather="search"
                              className="feather-search"
                            />
                          </Link>
                        </div>
                      </div>
                      {/* <div className="search-path">
                        <Link
                          className={`btn btn-filter ${isFilterVisible ? "setclose" : ""
                            }`}
                          id="filter_search"
                        >
                          <Filter
                            className="filter-icon"
                            
                          />
                          <span >
                            <ImageWithBasePath
                              src="assets/img/icons/closes.svg"
                              alt="img"
                            />
                          </span>
                        </Link>
                      </div> */}
                    </div>
                    {/* /Filter */}
                    {/* <div className="card" id="filter_inputs1">
                      <div className="card-body pb-0">
                        <div className="row">
                          <div className="col-lg-3 col-sm-6 col-12">
                            <div className="input-blocks">
                              <i data-feather="box" className="info-img" />
                              <select className="select">
                                <option>Choose Product</option>
                                <option>Lenovo 3rd Generation </option>
                                <option>Nike Jordan</option>
                                <option>Amazon Echo Dot </option>
                              </select>
                            </div>
                          </div>
                          <div className="col-lg-3 col-sm-6 col-12">
                            <div className="input-blocks">
                              <i data-feather="zap" className="info-img" />
                              <select className="select">
                                <option>Choose Category</option>
                                <option>Laptop</option>
                                <option>Shoe</option>
                                <option>Speaker</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-lg-3 col-sm-6 col-12">
                            <div className="input-blocks">
                              <i data-feather="archive" className="info-img" />
                              <select className="select">
                                <option>Choose Warehouse</option>
                                <option>Lavish Warehouse </option>
                                <option>Lobar Handy </option>
                                <option>Traditional Warehouse </option>
                              </select>
                            </div>
                          </div>
                          <div className="col-lg-3 col-sm-6 col-12 ms-auto">
                            <div className="input-blocks">
                              <Link className="btn btn-filters ms-auto">
                                {" "}
                                <i
                                  data-feather="search"
                                  className="feather-search"
                                />{" "}
                                Search{" "}
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                    {/* /Filter */}
                    <div className="table-responsive">
                      <Table columns={columns} dataSource={filteredBankList} />
                    </div>
                  </div>
                </div>
                {/* /product list */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        title="Add Bank Account"
        open={showUserModal}
        onCancel={() => setShowUserModal(false)}
        footer={[
          <Button key="cancel" onClick={() => setShowUserModal(false)}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={submitBankAccount}>
            Submit
          </Button>,
        ]}
      >
        <div className="p-3">
          <form>
            <div className="row">
              <div className="col-lg-6 col-sm-12 col-12">
                <div className="input-blocks">
                  <label>
                    Name<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    value={name}
                    className="form-control"
                    placeholder="Enter Name"
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-lg-6 col-sm-12 col-12">
                <div className="input-blocks">
                  <label>
                    Bank Account Number<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    value={bankAccountNumber}
                    className="form-control"
                    placeholder="Enter Bank Account Number"
                    onChange={(e) => setBankAccountNumber(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-sm-12 col-12">
                <div className="input-blocks">
                  <label>
                    Bank Name<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    value={bankName}
                    className="form-control"
                    placeholder="Enter Bank Name"
                    onChange={(e) => setBankName(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-lg-6 col-sm-12 col-12">
                <div className="input-blocks">
                  <label>
                    Account Holder Name<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    value={accountHolderName}
                    className="form-control"
                    placeholder="Enter Account Holder Name"
                    onChange={(e) => setAccountHolderName(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-sm-12 col-12">
                <div className="input-blocks">
                  <label>
                    Account Type<span style={{ color: "red" }}>*</span>
                  </label>
                  <Select
                    value={bankAccountType}
                    className="input-blocks"
                    options={[
                      { label: "Savings", value: "saving" },
                      { label: "Current", value: "current" },
                      { label: "Others", value: "others" },
                    ]}
                    placeholder="Choose Account Type"
                    onChange={(value) => {
                      setBankAccountType(value)
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-6 col-sm-12 col-12">
                <div className="input-blocks">
                  <label>
                    IFSC code<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    value={ifscCode}
                    className="form-control"
                    placeholder="Enter IFSC code"
                    onChange={(e) => setIfscCode(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-sm-12 col-12">
                <div className="input-blocks">
                  <label>
                    Branch Name<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    value={branchName}
                    className="form-control"
                    placeholder="Enter Branch Name"
                    onChange={(e) => setBranchName(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-lg-6 col-sm-12 col-12">
                <div
                  className="input-blocks"
                  style={{
                    display: `${activeTab === "myBank" ? "none" : "block"}`,
                  }}
                >
                  <label>
                    User Type<span style={{ color: "red" }}>*</span>
                  </label>
                  <Select
                    value={userType}
                    className="input-blocks"
                    options={[
                      { label: "Supplier", value: "supplier" },
                      { label: "Customer", value: "customer" },
                      { label: "Others", value: "others" },
                    ]}
                    placeholder="Choose User Type"
                    onChange={(value) => {
                      setUserType(value)
                      if(value.value == "supplier"){
 
                        supplier_data.map((item) => {
                          userOption.push({label: item.customerName, value: item._id})
                        }) 
                        console.log(userOption)
                      }else {
                        customer_data.map((item) => {
                          userOption.push({label: item.customerName, value: item._id})
                        })
                        console.log(userOption)
                      }
                    }}
                  />
                </div>
                <div
                  className="input-blocks"
                  style={{
                    display: `${activeTab !== "myBank" ? "none" : "block"}`,
                  }}
                >
                  <label>
                    Make Primary<span style={{ color: "red" }}>*</span>
                  </label>
                  <Switch checked={primary} onChange={toggle} />
                </div>
              </div>
              
              <div className="row">
              <div className="col-lg-12 col-sm-12 col-12">
                <div className="input-blocks">
                  <label>
                    Select User<span style={{ color: "red" }}>*</span>
                  </label>
                
                  <Select
                    value={userDetail}
                    className="input-blocks"
                    options={userOption}
                    placeholder="Choose Account Type"
                    onChange={(value) => setUserDetail(value)}
                  />
                </div>
              </div>
              </div>

            </div>
          </form>
        </div>
      </Modal>

      <EditLowStock />
    </div>
  );
};

export default BankAccount;
