/* eslint-disable no-unused-vars */
const API_URL_LOCAL = "http://localhost:4000/api/";
const API_URL_LIVE = "https://api.thefmgroup.in/api/";
const MAIN_URL_LIVE = "https://api.thefmgroup.in/";
const MAIN_URL_LOCAL = "http://localhost:4000/";

// export const API_URL = API_URL_LIVE;
export const API_URL = API_URL_LIVE;

export const MAIN_URL = MAIN_URL_LIVE;

export const API_BASE_URL = API_URL_LIVE;

