/* eslint-disable react/prop-types */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import ImageWithBasePath from "../../img/imagewithbasebath";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { API_URL } from "../../../constants/AppConstants";
import { alertData, customerData } from "../../../core/redux/action";
import stateCityListArr from "../../../data/StateCityList.json";

const CustomerModal = (props) => {
  const dispatch = useDispatch();
  const login_data = useSelector((state) => state.login_data);
  const [customerName, setCustomerName] = useState("");
  const [customerPhone, setCustomerPhone] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [customerAddress, setCustomerAddress] = useState("");
  const [customerCountry, setCustomerCountry] = useState({
    label: "Choose",
    value: null,
  });
  const [customerState, setCustomerState] = useState({
    label: "Choose",
    value: null,
  });
  const [customerCity, setCustomerCity] = useState({
    label: "Choose",
    value: null,
  });
  const [customerDescription, setCustomerDescription] = useState("");
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);

  const selected_store = useSelector((state) => state.selected_store);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const customer_data = useSelector((state) => state.customer_data);
  const detail_data = useSelector((state) => state.detail_data);
  let customerStateChanged = useRef(false);

  useEffect(() => {
    if (detail_data?.id && customer_data) {
      customer_data?.forEach((customer) => {
        if (customer._id == detail_data.id) {
          setSelectedData(customer);
        }
      });
    }
  }, [detail_data]);

  useEffect(() => {
    if (selectedData) {
      setCustomerName(selectedData?.customerName);
      setCustomerEmail(selectedData?.customerEmail);
      setCustomerPhone(selectedData?.customerPhone);
      setCustomerAddress(selectedData?.customerAddress);
      setCustomerCountry(selectedData?.customerCountry);
      setCustomerState(selectedData?.customerState);
      setCustomerCity(selectedData?.customerCity);
      setCustomerDescription(selectedData?.customerDescription);
    }
  }, [selectedData]);

  async function fetchData() {
    try {
      let response = await axios.get(API_URL + "customers");
      let filteredData = [];
      if (login_data?.roleId == 1) {
        filteredData = response.data?.filter(
          (data) =>
            data.storeId == selected_store[0]?._id && data.itemActive === true
        );
        dispatch(customerData(filteredData));
      } else {
        filteredData = response.data?.filter(
          (data) =>
            data.storeId == selected_store[0]?._id &&
            data.itemActive === true &&
            login_data?.empCode == data.userCode
        );
        dispatch(customerData(filteredData));
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    if (Object.keys(stateCityListArr)?.length) {
      let stateArr = [];
      Object.keys(stateCityListArr).forEach((state) => {
        stateArr.push({
          label: state,
          value: state,
        });
      });
      setStateList(stateArr);
    }
  }, []);

  useEffect(() => {
    if (Object.keys(stateCityListArr)?.length) {
      let cityArr = [];
      Object.entries(stateCityListArr).forEach((state, index) => {
        if (state[0] == customerState?.value) {
          state[1]?.length &&
            state[1]?.forEach((city) => {
              cityArr.push({
                label: city,
                value: city,
              });
            });
        }
      });
      setCityList(cityArr);
    }
  }, [customerState]);

  function clearData() {
    setCustomerName("");
    setCustomerPhone("");
    setCustomerEmail("");
    setCustomerAddress("");
    setCustomerCountry({
      label: "Choose",
      value: null,
    });
    setCustomerState({
      label: "Choose",
      value: null,
    });
    setCustomerCity({
      label: "Choose",
      value: null,
    });
    setCustomerDescription("");
  }

  async function submitData() {
    if (
      !customerName ||
      !customerPhone ||
      !customerAddress ||
      !customerCountry ||
      !customerState ||
      !customerCity
    ) {
      dispatch(
        alertData({
          type: "error",
          msg: "Please fill the required fields",
        })
      );
      setIsLoading(false);
      return;
    }

    let dataBody = {
      customerName,
      customerEmail,
      customerPhone,
      customerAddress,
      customerCountry,
      customerState,
      customerCity,
      customerDescription,
      customerStatus: "Active",
      storeId: selected_store[0]?._id,
      createdOn: new Date().toLocaleDateString().split(",")[0],
      userCode: login_data?.empCode,
      itemActive: true,
    };

    try {
      setIsLoading(true);
      let response = await axios.post(API_URL + "customers", dataBody);
      if (response.status === 200) {
        dispatch(
          alertData({
            type: "success",
            msg: "Customer added successfully!",
          })
        );
        fetchData();
        clearData();
        setTimeout(() => {
          setIsLoading(false);
          if (!props.from)
            document?.querySelector("#add-customer .close")?.click();
        }, 1000);
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  }

  async function updateData() {
    let dataBody = {
      id: selectedData?._id,
      customerName,
      customerEmail,
      customerPhone,
      customerAddress,
      customerCountry,
      customerState,
      customerCity,
      customerDescription,
      customerStatus: selectedData?.customerStatus,
    };

    try {
      setIsLoading(true);
      let response = await axios.put(
        API_URL + "customers/" + selectedData?._id,
        dataBody
      );
      if (response.status === 200) {
        dispatch(
          alertData({
            type: "success",
            msg: "Customer updated successfully!",
          })
        );

        fetchData();
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <>
      {/* Add Customer */}
      <div className="modal fade" id="add-customer">
        <div className="modal-dialog modal-dialog-centered custom-modal-two">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>Add Customer</h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    data-bs-toggle={props.from ? "modal" : ""}
                    data-bs-target={props.from ? `${props.target}` : ""}
                    onClick={clearData}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <form>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="input-blocks">
                          <label>
                            Customer Name{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={customerName}
                            onChange={(e) => setCustomerName(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-blocks">
                          <label>Email</label>
                          <input
                            type="email"
                            className="form-control"
                            value={customerEmail}
                            onChange={(e) => setCustomerEmail(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-blocks">
                          <label>
                            Phone<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={customerPhone}
                            onChange={(e) => setCustomerPhone(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="input-blocks">
                          <label>
                            Address<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={customerAddress}
                            onChange={(e) => setCustomerAddress(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-sm-10 col-10">
                        <div className="input-blocks">
                          <label>
                            Country<span style={{ color: "red" }}>*</span>
                          </label>
                          <Select
                            value={
                              customerCountry?.value
                                ? customerCountry
                                : [
                                    {
                                      label: "Choose",
                                      value: null,
                                    },
                                  ]
                            }
                            className="select"
                            options={[
                              {
                                label: "India",
                                value: "India",
                              },
                            ]}
                            onChange={(item) => setCustomerCountry(item)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-sm-10 col-10">
                        <div className="input-blocks">
                          <label>
                            State<span style={{ color: "red" }}>*</span>
                          </label>
                          <Select
                            value={
                              customerState?.value
                                ? customerState
                                : [
                                    {
                                      label: "Choose",
                                      value: null,
                                    },
                                  ]
                            }
                            className="select"
                            options={stateList}
                            onChange={(item) => setCustomerState(item)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-sm-10 col-10">
                        <div className="input-blocks">
                          <label>
                            City<span style={{ color: "red" }}>*</span>
                          </label>
                          <Select
                            value={
                              customerCity?.value
                                ? customerCity
                                : [
                                    {
                                      label: "Choose",
                                      value: null,
                                    },
                                  ]
                            }
                            className="select"
                            options={cityList}
                            onChange={(item) => setCustomerCity(item)}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="mb-0 input-blocks">
                          <label className="form-label">Descriptions</label>
                          <textarea
                            value={customerDescription}
                            className="form-control mb-1"
                            defaultValue={""}
                            onChange={(e) =>
                              setCustomerDescription(e.target.value)
                            }
                          />
                          <p>Maximum 600 Characters</p>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer-btn">
                      <button
                        type="button"
                        className="btn btn-cancel me-2"
                        data-bs-dismiss="modal"
                        data-bs-toggle={props.from ? "modal" : ""}
                        data-bs-target={props.from ? `${props.target}` : ""}
                        onClick={clearData}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        data-bs-toggle={props.from ? "modal" : ""}
                        data-bs-target={props.from ? `${props.target}` : ""}
                        className={`btn btn-submit ${
                          isLoading ? "disabled" : ""
                        }`}
                        onClick={submitData}
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                  <span style={{ fontStyle: "italic", fontSize: 12 }}>
                    All fields with<span style={{ color: "red" }}>*</span> are
                    required
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Add Customer */}

      {/* Edit Customer */}
      <div className="modal fade" id="edit-customer">
        <div className="modal-dialog modal-dialog-centered custom-modal-two">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>Edit Customer</h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    data-bs-toggle={props.from ? "modal" : ""}
                    data-bs-target={props.from ? `${props.target}` : ""}
                    onClick={clearData}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <form>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="input-blocks">
                          <label>
                            Customer Name{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={customerName}
                            onChange={(e) => setCustomerName(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-blocks">
                          <label>Email</label>
                          <input
                            type="email"
                            className="form-control"
                            value={customerEmail}
                            onChange={(e) => setCustomerEmail(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-blocks">
                          <label>
                            Phone<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={customerPhone}
                            onChange={(e) => setCustomerPhone(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="input-blocks">
                          <label>
                            Address<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={customerAddress}
                            onChange={(e) => setCustomerAddress(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-sm-10 col-10">
                        <div className="input-blocks">
                          <label>
                            Country<span style={{ color: "red" }}>*</span>
                          </label>
                          <Select
                            value={
                              customerCountry?.value
                                ? customerCountry
                                : [
                                    {
                                      label: "Choose",
                                      value: null,
                                    },
                                  ]
                            }
                            className="select"
                            options={[
                              {
                                label: "India",
                                value: "India",
                              },
                            ]}
                            onChange={(item) => setCustomerCountry(item)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-sm-10 col-10">
                        <div className="input-blocks">
                          <label>
                            State<span style={{ color: "red" }}>*</span>
                          </label>
                          <Select
                            value={
                              customerState?.value
                                ? customerState
                                : [
                                    {
                                      label: "Choose",
                                      value: null,
                                    },
                                  ]
                            }
                            className="select"
                            options={stateList}
                            onChange={(item) => {
                              setCustomerState(item);
                              setCustomerCity({
                                label: "Choose",
                                value: null,
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-sm-10 col-10">
                        <div className="input-blocks">
                          <label>
                            City<span style={{ color: "red" }}>*</span>
                          </label>
                          <Select
                            value={
                              customerCity?.value
                                ? customerCity
                                : [
                                    {
                                      label: "Choose",
                                      value: null,
                                    },
                                  ]
                            }
                            className="select"
                            options={cityList}
                            onChange={(item) => setCustomerCity(item)}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="mb-0 input-blocks">
                          <label className="form-label">Descriptions</label>
                          <textarea
                            value={customerDescription}
                            className="form-control mb-1"
                            defaultValue={""}
                            onChange={(e) =>
                              setCustomerDescription(e.target.value)
                            }
                          />
                          <p>Maximum 600 Characters</p>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer-btn">
                      <button
                        type="button"
                        className="btn btn-cancel me-2"
                        data-bs-dismiss="modal"
                        data-bs-toggle={props.from ? "modal" : ""}
                        data-bs-target={props.from ? `${props.target}` : ""}
                        onClick={clearData}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        data-bs-toggle={props.from ? "modal" : ""}
                        data-bs-target={props.from ? `${props.target}` : ""}
                        className={`btn btn-submit ${
                          isLoading ? "disabled" : ""
                        }`}
                        onClick={updateData}
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                  <span style={{ fontStyle: "italic", fontSize: 12 }}>
                    All fields with<span style={{ color: "red" }}>*</span> are
                    required
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Edit Customer */}
    </>
  );
};

export default CustomerModal;
