/* eslint-disable no-empty */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import { Search, XCircle } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { loginUserData, resetData } from "../../core/redux/action";
import { selectStore } from "../../core/redux/action";
import axios from "axios";
import io, { Socket } from "socket.io-client";
import { MAIN_URL, API_URL } from "../../constants/AppConstants";
import { persistor } from "../../core/redux/store";
import { Modal } from "antd";

const socket = io(API_URL);

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [toggle, SetToggle] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const selected_store = useSelector((state) => state.selected_store);
  const login_data = useSelector((state) => state.login_data);
  const stores_data = useSelector((state) => state.stores_data);
  const [notificationData, setNotificationData] = useState([]);

  const [storesArray, setStoresArray] = useState([]);
  const [fetchedStore, setFetchedStore] = useState(false);
  const [noticePopupShow, setNoticePopupShow] = useState(null);

  useEffect(() => {
    async function updateStores() {
      try {
        let response = await axios.get(API_URL + "stores");
        if (response.status == 200) {
          setStoresArray(response.data);
          setFetchedStore(true);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    if (
      login_data != null &&
      login_data != undefined &&
      Object.keys(login_data).length &&
      login_data?.roleId == 1
    )
      updateStores();
  }, [stores_data]);

  useEffect(() => {
    if (fetchedStore) {
      if (!storesArray.length) {
        dispatch(selectStore([]));
        navigate("/store-list");
      }
    }
  }, [fetchedStore]);

  useEffect(() => {
    // const getNotificationsData = async () => {
    //   try {
    //     const res = await axios.get(
    //       API_URL + `employees/notifications/` + login_data?.empCode
    //     );
    //     setNotificationData(res.data);
    //   } catch (error) {
    //     console.log("Error fetching notifications:", error);
    //   }
    // };
    // if (login_data?.empCode) {
    //   getNotificationsData();
    // }
    // socket.on("notification", handleNotification);
    // return () => {
    //   socket.off("notification", handleNotification);
    // };
  }, [login_data]);

  useEffect(() => {
    const handleNotification = (notificationData) => {
      // console.log("emit");
      setNotificationData((prevNotifications) => [
        ...prevNotifications,
        notificationData,
      ]);
    };
    socket.on("notification", handleNotification);
  }, [socket]);

  const handleNotification = (notificationData) => {
    setNotificationData((prevNotifications) => [
      ...prevNotifications,
      notificationData,
    ]);
  };

  // console.log(socket.on("notification", handleNotification));

  const isElementVisible = (element) => {
    return element.offsetWidth > 0 || element.offsetHeight > 0;
  };

  const slideDownSubmenu = () => {
    const subdropPlusUl = document.getElementsByClassName("subdrop");
    for (let i = 0; i < subdropPlusUl.length; i++) {
      const submenu = subdropPlusUl[i].nextElementSibling;
      if (submenu && submenu.tagName.toLowerCase() === "ul") {
        submenu.style.display = "block";
      }
    }
  };

  const slideUpSubmenu = () => {
    const subdropPlusUl = document.getElementsByClassName("subdrop");
    for (let i = 0; i < subdropPlusUl.length; i++) {
      const submenu = subdropPlusUl[i].nextElementSibling;
      if (submenu && submenu.tagName.toLowerCase() === "ul") {
        submenu.style.display = "none";
      }
    }
  };

  useEffect(() => {
    const handleMouseover = (e) => {
      e.stopPropagation();

      const body = document.body;
      const toggleBtn = document.getElementById("toggle_btn");

      if (
        body.classList.contains("mini-sidebar") &&
        isElementVisible(toggleBtn)
      ) {
        const target = e.target.closest(".sidebar, .header-left");

        if (target) {
          // body.classList.add("expand-menu");
          slideDownSubmenu();
        } else {
          // body.classList.remove("expand-menu");
          slideUpSubmenu();
        }

        e.preventDefault();
      }
    };

    document.addEventListener("mouseover", handleMouseover);

    return () => {
      document.removeEventListener("mouseover", handleMouseover);
    };
  }, []); // Empty dependency array ensures that the effect runs only once on mount

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(
        document.fullscreenElement ||
          document.mozFullScreenElement ||
          document.webkitFullscreenElement ||
          document.msFullscreenElement
      );
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);
    document.addEventListener("mozfullscreenchange", handleFullscreenChange);
    document.addEventListener("webkitfullscreenchange", handleFullscreenChange);
    document.addEventListener("msfullscreenchange", handleFullscreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
      document.removeEventListener(
        "mozfullscreenchange",
        handleFullscreenChange
      );
      document.removeEventListener(
        "webkitfullscreenchange",
        handleFullscreenChange
      );
      document.removeEventListener(
        "msfullscreenchange",
        handleFullscreenChange
      );
    };
  }, []);

  const handlesidebar = () => {
    document.body.classList.toggle("mini-sidebar");
    SetToggle((current) => !current);
  };
  const expandMenu = () => {
    document.body.classList.remove("expand-menu");
  };
  const expandMenuOpen = () => {
    document.body.classList.add("expand-menu");
  };
  const sidebarOverlay = () => {
    document?.querySelector(".main-wrapper")?.classList?.toggle("slide-nav");
    document?.querySelector(".sidebar-overlay")?.classList?.toggle("opened");
    document?.querySelector("html")?.classList?.toggle("menu-opened");
  };

  let pathname = location.pathname;

  const exclusionArray = [
    "/reactjs/template/dream-pos/index-three",
    "/reactjs/template/dream-pos/index-one",
  ];
  if (exclusionArray.indexOf(window.location.pathname) >= 0) {
    return "";
  }

  const toggleFullscreen = (elem) => {
    elem = elem || document.documentElement;
    if (
      !document.fullscreenElement &&
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement &&
      !document.msFullscreenElement
    ) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    }
  };

  return (
    <>
      <div className="header">
        {/* Logo */}
        <div
          className={`header-left ${toggle ? "" : "active"}`}
          // onMouseLeave={expandMenu}
          // onMouseOver={expandMenuOpen}
        >
          <Link to="/dashboard" className="logo logo-normal">
            {/* <ImageWithBasePath src="assets/img/logo.png" alt="img" /> */}
          </Link>
          <Link to="/dashboard" className="logo logo-white">
            {/* <ImageWithBasePath src="assets/img/logo-white.png" alt="img" /> */}
          </Link>
          <Link to="/dashboard" className="logo-small">
            {/* <ImageWithBasePath src="assets/img/logo-small.png" alt="img" /> */}
          </Link>
          {/* <Link
            id="toggle_btn"
            to="#"
            style={{
              display: pathname.includes("tasks")
                ? "none"
                : pathname.includes("compose")
                ? "none"
                : "",
            }}
            onClick={handlesidebar}
          >
            <FeatherIcon icon="chevrons-left" className="feather-16" />
          </Link> */}
        </div>
        {/* /Logo */}
        <Link
          id="mobile_btn"
          className="mobile_btn"
          to="#"
          onClick={sidebarOverlay}
        >
          <span className="bar-icon">
            <span />
            <span />
            <span />
          </span>
        </Link>
        {/* Header Menu */}
        <ul className="nav user-menu">
          {/* Search */}
          {/* <li className="nav-item nav-searchinputs">
            <div className="top-nav-search">
              <Link to="#" className="responsive-search">
                <Search />
              </Link>
              <form action="#" className="dropdown">
                <div
                  className="searchinputs dropdown-toggle"
                  id="dropdownMenuClickable"
                  data-bs-toggle="dropdown"
                  data-bs-auto-close="false"
                >
                  <input type="text" placeholder="Search" />
                  <div className="search-addon">
                    <span>
                      <XCircle className="feather-14" />
                    </span>
                  </div>
                </div>
                <div
                  className="dropdown-menu search-dropdown"
                  aria-labelledby="dropdownMenuClickable"
                >
                  <div className="search-info">
                    <h6>
                      <span>
                        <i data-feather="search" className="feather-16" />
                      </span>
                      Recent Searches
                    </h6>
                    <ul className="search-tags">
                      <li>
                        <Link to="#">Products</Link>
                      </li>
                      <li>
                        <Link to="#">Sales</Link>
                      </li>
                      <li>
                        <Link to="#">Applications</Link>
                      </li>
                    </ul>
                  </div>
                  <div className="search-info">
                    <h6>
                      <span>
                        <i data-feather="user" className="feather-16" />
                      </span>
                      Customers
                    </h6>
                    <ul className="customers">
                      <li>
                        <Link to="#">
                          Aron Varu
                          <ImageWithBasePath
                            src="assets/img/profiles/avator1.jpg"
                            alt
                            className="img-fluid"
                          />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </form>
            </div>
          </li> */}
          {/* /Search */}

          {/* Select Store */}
          {login_data?.roleId == 1 ? (
            <li className="nav-item dropdown has-arrow main-drop select-store-dropdown">
              <Link
                title="Select Store"
                to="#"
                className="dropdown-toggle nav-link select-store"
                data-bs-toggle="dropdown"
              >
                <span className="user-info">
                  {/* <span className="user-letter">
                  <ImageWithBasePath
                    src="assets/img/store/store-01.png"
                    alt="Store Logo"
                    className="img-fluid"
                  />
                </span> */}
                  <span className="user-detail">
                    <span className="user-name">
                      {selected_store?.length
                        ? selected_store[0]?.storeName
                        : "Select Store"}
                    </span>
                  </span>
                </span>
              </Link>
              <div className="dropdown-menu dropdown-menu-right">
                {storesArray?.length
                  ? storesArray?.map((store, id) => {
                      if (store.storeStatus == "Active")
                        return (
                          <div
                            key={id}
                            className="dropdown-item"
                            onClick={() => dispatch(selectStore([store]))}
                          >
                            {store?.storeName}
                          </div>
                        );
                    })
                  : ""}
              </div>
            </li>
          ) : (
            <></>
          )}

          {/* /Select Store */}

          {/* Flag */}
          {/* <li className="nav-item dropdown has-arrow flag-nav nav-item-box">
            <Link
              className="nav-link dropdown-toggle"
              data-bs-toggle="dropdown"
              to="#"
              role="button"
            >
              <ImageWithBasePath
                src="assets/img/flags/us.png"
                alt="img"
                height={16}
              />
            </Link>
            <div className="dropdown-menu dropdown-menu-right">
              <Link to="#" className="dropdown-item active">
                <ImageWithBasePath
                  src="assets/img/flags/us.png"
                  alt="img"
                  height={16}
                />
                English
              </Link>
              <Link to="#" className="dropdown-item">
                <ImageWithBasePath
                  src="assets/img/flags/fr.png"
                  alt="img"
                  height={16}
                />{" "}
                French
              </Link>
              <Link to="#" className="dropdown-item">
                <ImageWithBasePath
                  src="assets/img/flags/es.png"
                  alt="img"
                  height={16}
                />{" "}
                Spanish
              </Link>
              <Link to="#" className="dropdown-item">
                <ImageWithBasePath
                  src="assets/img/flags/de.png"
                  alt="img"
                  height={16}
                />{" "}
                German
              </Link>
            </div>
          </li> */}
          {/* /Flag */}

          <li className="nav-item nav-item-box">
            {/* Full Screen */}
            <Link
              title="Fullscreen"
              to="#"
              id="btnFullscreen"
              onClick={() => toggleFullscreen()}
              className={isFullscreen ? "Exit Fullscreen" : "Go Fullscreen"}
            >
              <FeatherIcon icon="maximize" />
            </Link>
          </li>

          {/* <li className="nav-item dropdown nav-item-box">
            <Link
              to="#"
              className="dropdown-toggle nav-link"
              data-bs-toggle="dropdown"
              data-bs-target="#add-notice-popover"
            >
              <FeatherIcon icon="plus" />
            </Link>
            <div
              className="dropdown-menu dropdown-menu-right"
              id="add-notice-popover"
            >
              <div
                className="dropdown-item"
                onClick={() => setNoticePopupShow(1)}
              >
                Create Reminder
              </div>
              <div
                className="dropdown-item"
                onClick={() => setNoticePopupShow(2)}
              >
                Create Notice
              </div>
            </div>
          </li> */}

          {/* Email */}
          {/* <li className="nav-item nav-item-box">
            <Link to="/email">
              <FeatherIcon icon="mail" />
              <span className="badge rounded-pill">1</span>
            </Link>
          </li> */}

          {/* Notifications */}
          <li className="nav-item dropdown nav-item-box">
            <Link
              title="Notifications"
              to="#"
              className="dropdown-toggle nav-link"
              data-bs-toggle="dropdown"
            >
              <FeatherIcon icon="bell" />
              <span className="badge rounded-pill">
                {notificationData.length}
              </span>
            </Link>
            <div className="dropdown-menu notifications">
              <div className="topnav-dropdown-header">
                <span className="notification-title">Notifications</span>
                <Link to="#" className="clear-noti">
                  {" "}
                  Clear All{" "}
                </Link>
              </div>
              <div className="noti-content">
                <ul className="notification-list">
                  {notificationData.map((notification, index) => (
                    <li className="notification-message" key={index}>
                      <Link to="/activities">
                        <div className="media d-flex">
                          <span className="avatar flex-shrink-0">
                            {/* Display profile image if available */}
                            {/* <img src={notification.profileImage || "assets/img/profiles/default-avatar.jpg"} alt="Avatar" /> */}
                          </span>
                          <div className="media-body flex-grow-1">
                            <p className="noti-details">
                              <span className="noti-title">
                                {notification.from}
                              </span>{" "}
                              sent a message:{" "}
                              <span className="noti-title">
                                {notification.message}
                              </span>
                            </p>
                            <p className="noti-time">
                              <span className="notification-time">
                                {notification.date} {notification.time}
                              </span>
                            </p>
                          </div>
                        </div>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="topnav-dropdown-footer">
                <Link to="/activities">View all Notifications</Link>
              </div>
            </div>
          </li>

          {/* Settings */}
          {/* <li className="nav-item nav-item-box">
            <Link to="/general-settings">
              <FeatherIcon icon="settings" />
            </Link>
          </li> */}

          {/* User Dropdown */}
          <li className="nav-item dropdown has-arrow main-drop">
            <Link
              to="#"
              className="dropdown-toggle nav-link userset"
              data-bs-toggle="dropdown"
            >
              <span className="user-info">
                <span className="user-letter">
                  <ImageWithBasePath
                    src="assets/img/profiles/avator1.jpg"
                    alt="img"
                    className="img-fluid"
                  />
                </span>
                <span className="user-detail">
                  <span className="user-name">{login_data?.empCode}</span>
                  <span className="user-role">{login_data?.roleName}</span>
                </span>
              </span>
            </Link>
            <div className="dropdown-menu menu-drop-user">
              <div className="profilename">
                <div className="profileset">
                  <span className="user-img">
                    <ImageWithBasePath
                      src="assets/img/profiles/avator1.jpg"
                      alt="img"
                    />
                    <span className="status online" />
                  </span>
                  <div className="profilesets">
                    <span className="user-name">{login_data?.empCode}</span>
                    <span className="user-role">{login_data?.roleName}</span>
                  </div>
                </div>
                <hr className="m-0" />
                <Link className="dropdown-item" to={"/profile"}>
                  <i className="me-2" data-feather="user" /> My Profile
                </Link>
                {/* <Link className="dropdown-item" to={route.generalsettings}>
                  <i className="me-2" data-feather="settings" />
                  Settings
                </Link> */}
                <hr className="m-0" />
                <Link
                  className="dropdown-item logout pb-0"
                  to="/signin"
                  onClick={() => {
                    localStorage.clear();
                    dispatch(loginUserData(null));
                    persistor.purge().then(() => {
                      dispatch(resetData());
                    });
                  }}
                >
                  <ImageWithBasePath
                    src="assets/img/icons/log-out.svg"
                    alt="img"
                    className="me-2"
                  />
                  Logout
                </Link>
              </div>
            </div>
          </li>
        </ul>
        {/* /Header Menu */}
        {/* Mobile Menu */}
        <div className="dropdown mobile-user-menu">
          <Link
            to="#"
            className="nav-link dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="fa fa-ellipsis-v" />
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
            <Link className="dropdown-item" to="profile">
              My Profile
            </Link>
            {/* <Link className="dropdown-item" to="generalsettings">
              Settings
            </Link> */}
            <Link className="dropdown-item" to="signin">
              Logout
            </Link>
          </div>
        </div>
        {/* /Mobile Menu */}
      </div>

      <>
        <Modal
          open={noticePopupShow == 1}
          onCancel={() => setNoticePopupShow(null)}
          footer={false}
        >
          <form>
            <label htmlFor="to" className="form-label" style={{ fontSize: 20 }}>
              Add Reminder
            </label>
            <div className="modal-body">
              <div className="mb-3">
                <label htmlFor="to" className="form-label">
                  To:
                </label>
                <select
                  id="to"
                  name="to"
                  className="form-control"
                  value={notificationData.to}
                  // onChange={handleInputChange}
                  required
                >
                  <option value="">Select recipient</option>
                  {/* Populate options dynamically based on available users */}

                  {/* {
                        userList ? 
                        userList.map((item, index)=> {
                          return (
                            <option key={index} value={item}>{item}</option>
                          )
                        })
                        : <></>
                      } */}
                </select>
              </div>
              <div className="mb-3">
                <label htmlFor="message" className="form-label">
                  Message:
                </label>
                <textarea
                  id="message"
                  name="message"
                  className="form-control"
                  value={notificationData.message}
                  rows="3"
                  required
                ></textarea>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary">
                Close
              </button>
              <button type="submit" className="btn btn-primary">
                Send Reminder
              </button>
            </div>
          </form>
        </Modal>

        <Modal
          open={noticePopupShow == 2}
          onCancel={() => setNoticePopupShow(null)}
          footer={false}
        >
          <form>
            <label htmlFor="to" className="form-label" style={{ fontSize: 20 }}>
              Add Notice
            </label>
            <div className="modal-body">
              <div className="mb-3">
                <label htmlFor="to" className="form-label">
                  To:
                </label>
                <select
                  id="to"
                  name="to"
                  className="form-control"
                  value={notificationData.to}
                  // onChange={handleInputChange}
                  required
                >
                  <option value="">Select recipient</option>
                  {/* Populate options dynamically based on available users */}

                  {/* {
                        userList ? 
                        userList.map((item, index)=> {
                          return (
                            <option key={index} value={item}>{item}</option>
                          )
                        })
                        : <></>
                      } */}
                </select>
              </div>
              <div className="mb-3">
                <label htmlFor="message" className="form-label">
                  Message:
                </label>
                <textarea
                  id="message"
                  name="message"
                  className="form-control"
                  value={notificationData.message}
                  rows="3"
                  required
                ></textarea>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary">
                Close
              </button>
              <button type="submit" className="btn btn-primary">
                Send Notification
              </button>
            </div>
          </form>
        </Modal>
      </>
    </>
  );
};

export default Header;
