/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  ChevronUp,
  Eye,
  PlusCircle,
  RotateCcw,
} from "feather-icons-react/build/IconComponents";
import { loaderData, setToogleHeader } from "../../core/redux/action";
import { useDispatch, useSelector } from "react-redux";
import { Box, Filter, Sliders, StopCircle, User } from "react-feather";
import Select from "react-select";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import Table from "../../core/pagination/datatable";
import EditQuotation from "../../core/modals/sales/editquotation";
import axios from "axios";
import { API_URL } from "../../constants/AppConstants";
import { quotationData, updatePage } from "../../core/redux/action";
import { Download } from "react-feather";
import { Modal } from "antd";
import PrintPreview from "../../common/PrintPreview";
import PrintLayout from "../../common/PrintLayout";
import CSS from "../../core/modals/PreviewModal.module.scss";
import { fetchSaleQuotationData } from "../../utils/utilities";
import AddSaleQuotation from "../../core/modals/sales/addsalequotation";
import CommonFilter from "../../common/CommonFilter.js";

const SaleQuotationList = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.toggle_header);
  const quotation_data = useSelector((state) => state.sale_quotation_data);
  const login_data = useSelector((state) => state.login_data);
  const selected_store = useSelector((state) => state.selected_store);

  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const toggleFilterVisibility = () => {
    setIsFilterVisible((prevVisibility) => !prevVisibility);
  };
  const [showPdfModal, setShowPdfModal] = useState(false);
  const [pdfData, setPdfData] = useState({});
  const [selectedQuotationId, setSelectedQuotationId] = useState(null);
  const [selectedQuotation, setSelectedQuotation] = useState(null);
  const [filteredList, setFilteredList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const [filterByArray, setFilterByArray] = useState([
    false,
    false,
    false,
    false,
  ]);

  const [selectedCustomerFilterData, setSelectedCustomerFilterData] =
    useState("");
  const [selectedDateFilterData, setSelectedDateFilterData] = useState("");
  const [customerFilterArr, setCustomerFilterArr] = useState([]);

  const oldandlatestvalue = [
    { value: "date", label: "Sort by Date" },
    // { value: 'newest', label: 'Newest' },
    // { value: 'oldest', label: 'Oldest' },
  ];
  const products = [
    { value: "Choose product", label: "Choose product" },
    // { value: 'Bold V3.2', label: 'Bold V3.2' },
    // { value: 'Apple Series 5 Watch', label: 'Apple Series 5 Watch' },
  ];
  const status = [
    { value: "Choose product", label: "Choose Status" },
    { value: "Ordered", label: "Ordered" },
    { value: "Sent", label: "Sent" },
  ];
  const customername = [
    { value: "Choose Custmer", label: "Choose Customer" },
    // { value: 'walk-in-customer', label: 'walk-in-customer' },
    // { value: 'John Smith', label: 'John Smith' },
  ];

  const renderTooltip = (props) => (
    <Tooltip id="pdf-tooltip" {...props}>
      Pdf
    </Tooltip>
  );
  const renderExcelTooltip = (props) => (
    <Tooltip id="excel-tooltip" {...props}>
      Excel
    </Tooltip>
  );
  const renderPrinterTooltip = (props) => (
    <Tooltip id="printer-tooltip" {...props}>
      Printer
    </Tooltip>
  );
  const renderRefreshTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Refresh
    </Tooltip>
  );
  const renderCollapseTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Collapse
    </Tooltip>
  );

  const columns = [
    {
      title: "Product Name",
      dataIndex: "productsList",
      render: (data) =>
        data[0]?.productName +
        (data?.length > 1 ? ` +${data?.length - 1}More` : ""),
      //   sorter: (a, b) => a.productname.length - b.productname.length,
    },
    {
      title: "Invoice",
      dataIndex: "invoiceNumber",
      //   sorter: (a, b) => a.reference.length - b.reference.length,
    },
    {
      title: "Customer Name",
      dataIndex: "customer",
      render: (data) => data?.label,
      //   sorter: (a, b) => a.customername.length - b.customername.length,
    },
    // {
    //   title: "Status",
    //   dataIndex: "quotationStatus",
    //   render: (text) => (
    //     <div>
    //       {text?.label === "Pending" && (
    //         <span className="badge badge-linewarning">{text?.value}</span>
    //       )}
    //       {text?.label === "Completed" && (
    //         <span className="badge badge-linesuccess">{text?.value}</span>
    //       )}
    //       {text?.label === "Cancelled" && (
    //         <span className="badge badge-linedanger">{text?.value}</span>
    //       )}
    //     </div>
    //   ),
    //   //   sorter: (a, b) => a.status.length - b.status.length,
    // },
    {
      title: "Grand Total",
      dataIndex: "grandTotal",
      render: (text) => (
        <span style={{ fontWeight: "600x" }}>₹ {text.toFixed(2)}</span>
      ),
      //   sorter: (a, b) => a.grandtotal.length - b.grandtotal.length,
    },
    {
      title: "Actions",
      dataIndex: "_id",
      render: (data) => (
        <td className="action-table-data">
          <div className="edit-delete-action">
            {/* <Link className="me-2 p-2" to="#">
              <Eye className="feather-view" />
            </Link>
            <Link
              className="me-2 p-2"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#edit-units"
            >
              <i data-feather="edit" className="feather-edit"></i>
            </Link> */}
            <Link
              className="me-2 p-2"
              onClick={(e) => {
                e.preventDefault();
                setShowPdfModal(true);
                setSelectedQuotationId(data);
              }}
            >
              <Download className="info-img" style={{ margin: 0 }} />
            </Link>

            <Link
              className="confirm-text p-2"
              onClick={(e) => {
                e.preventDefault();
                deleteData(data);
              }}
            >
              <i
                data-feather="trash-2"
                className="feather-trash-2"
                // onClick={showConfirmationAlert}
              ></i>
            </Link>
          </div>
        </td>
      ),
    },
  ];
  const MySwal = withReactContent(Swal);

  useEffect(() => {
    fetchSaleQuotationData(login_data, selected_store[0]);
  }, [selected_store]);

  useEffect(() => {
    setFilteredList(quotation_data);
  }, [quotation_data]);

  useEffect(() => {
    if (quotation_data && quotation_data?.length) {
      let customerFilterArrCopy = [];

      quotation_data.forEach((item) => {
        if (item.customer.value) {
          customerFilterArrCopy.push(item.customer.label);
        }
      });
      let uniqCustomerFilterArrCopy = [...new Set(customerFilterArrCopy)];
      setCustomerFilterArr(uniqCustomerFilterArrCopy);
    }
  }, [quotation_data]);

  useEffect(() => {
    let quotationData = {};
    if (selectedQuotationId) {
      quotation_data?.forEach((data) => {
        if (data?._id == selectedQuotationId) {
          quotationData = {
            from: {
              name: "FM Marketing",
              email: "fmmarketing@example.com",
              phone: "9876543210",
              address: "Raipur, Chhattisgarh",
              country: {
                label: "India",
                value: "India",
              },
              state: {
                label: "Chhattisgarh",
                value: "Chhattisgarh",
              },
              city: {
                label: "Raipur",
                value: "Raipur",
              },
              status: "Active",
              itemActive: true,
            },
            to: {
              name: "",
              email: "",
              phone: "",
              address: "",
              country: {
                label: "India",
                value: "India",
              },
              state: {
                label: "Chhattisgarh",
                value: "Chhattisgarh",
              },
              city: {
                label: "Raipur",
                value: "Raipur",
              },
              status: "Active",
              itemActive: true,
            },
            Date: data?.date,
            Reference_Number: data?.invoiceNumber,
            Product_List: data?.productsList,
            Order_Tax: data?.orderTax,
            Discount: data?.discountWithUnit,
            Shipping: data?.shipping,
            Grand_Total: data?.grandTotal,
            Quotation_Status: data?.quotationStatus?.label,
            Description: data?.description,
          };
        }
      });
      setPdfData({
        heading: "Quotation",
        array: quotationData,
      });
    }
  }, [selectedQuotationId]);

  useEffect(() => {
    if (!searchTerm) {
      if (selectedCustomerFilterData?.value) {
        const filteredData = quotation_data.filter((product) => {
          const matchesCustomer = selectedCustomerFilterData?.value
            ? product.customer.label === selectedCustomerFilterData.label
            : true;
          return matchesCustomer;
        });

        setFilteredList(filteredData);
      } else {
        setFilteredList(quotation_data);
      }
    }
  }, [selectedCustomerFilterData, selectedDateFilterData, searchTerm]);

  async function deleteData(dataId) {
    dispatch(
      loaderData({
        type: "Loader_Data",
        payload: true,
      })
    );
    if (dataId) {
      try {
        let response = await axios.put(
          API_URL + "salequotation/delete/" + dataId
        );
        if (response.status === 200) {
          fetchSaleQuotationData(login_data, selected_store[0]);
          dispatch(
            loaderData({
              type: "Loader_Data",
              payload: false,
            })
          );
        }
      } catch (err) {
        console.log(err);
        dispatch(
          loaderData({
            type: "Loader_Data",
            payload: true,
          })
        );
      }
    }
  }

  useEffect(() => {
    if (searchTerm) {
      setSelectedCustomerFilterData("");
      const filtered = quotation_data.filter(
        (item) =>
          item?.invoiceNumber
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          item?.customer?.label.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredList(filtered);
    } else {
      setFilteredList(quotation_data);
    }
  }, [searchTerm, quotation_data]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const showConfirmationAlert = () => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes, delete it!",
      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        MySwal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          className: "btn btn-success",
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      } else {
        MySwal.close();
      }
    });
  };
  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Sale Quotation List</h4>
                <h6>Manage Your Sale Quotation</h6>
              </div>
            </div>
            <ul className="table-top-head">
              {/* <li>
                <OverlayTrigger placement="top" overlay={renderTooltip}>
                  <Link>
                    <ImageWithBasePath
                      src="assets/img/icons/pdf.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderExcelTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top">
                    <ImageWithBasePath
                      src="assets/img/icons/excel.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderPrinterTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top">
                    <i data-feather="printer" className="feather-printer" />
                  </Link>
                </OverlayTrigger>
              </li> */}
              <li>
                <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top">
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderCollapseTooltip}>
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    id="collapse-header"
                    className={data ? "active" : ""}
                    onClick={() => {
                      dispatch(setToogleHeader(!data));
                    }}
                  >
                    <ChevronUp />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
            <div className="page-btn">
              <Link
                to="#"
                className="btn btn-added"
                data-bs-toggle="modal"
                data-bs-target="#add-quotation"
              >
                <PlusCircle className="me-2" />
                Add New Sale Quotation
              </Link>
            </div>
          </div>
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <input
                      type="text"
                      value={searchTerm}
                      onChange={handleSearchChange}
                      placeholder="Search by invoice or customer"
                      className="form-control form-control-sm formsearch"
                    />
                    <Link to="#" className="btn btn-searchset">
                      <i data-feather="search" className="feather-search" />
                    </Link>
                  </div>
                </div>
                <Select
                  value={
                    selectedCustomerFilterData.value
                      ? selectedCustomerFilterData
                      : [
                          {
                            label: "Customer",
                            value: null,
                          },
                        ]
                  }
                  className="select mr-10"
                  options={customerFilterArr?.map((item, index) => {
                    return { label: item, value: index + 1 };
                  })}
                  placeholder="Customer"
                  onChange={(value) => setSelectedCustomerFilterData(value)}
                />
                {/* <Select
                value={
                  selectedDateFilterData.value
                    ? selectedDateFilterData
                    : [
                        {
                          label: "Date",
                          value: null,
                        },
                      ]
                }
                className="select mr-10"
                options={[
                  {
                    label: "Latest",
                    value: "Latest",
                  },
                  {
                    label: "Oldest",
                    value: "Oldest",
                  },
                ]}
                placeholder="Date"
                onChange={(value) => setSelectedDateFilterData(value)}
              /> */}
                <button
                  className="btn btn-danger"
                  onClick={() => {
                    setSelectedCustomerFilterData("");
                    setSelectedDateFilterData("");
                  }}
                >
                  Clear
                </button>
              </div>
              <div className="table-responsive">
                <Table columns={columns} dataSource={filteredList} />
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
      <AddSaleQuotation />
      <EditQuotation />
      <Modal
        className={`${CSS.printPreviewModal}`}
        open={showPdfModal}
        footer={false}
        onCancel={() => {
          setShowPdfModal(false);
          setSelectedQuotation(null);
          setSelectedQuotationId(null);
        }}
      >
        <div className={`${CSS.modalBox}`}>
          <PrintPreview>
            <PrintLayout data={pdfData} />
          </PrintPreview>
        </div>
      </Modal>
    </div>
  );
};

export default SaleQuotationList;
