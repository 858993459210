// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.transportmodal_transportModal__PpqKE .form-group {
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.transportmodal_transportModal__PpqKE .form-group .label {
  font-size: 14px;
  font-weight: 500;
}
.transportmodal_transportModal__PpqKE .form-group .input-box {
  border: 1px solid #adadad;
  border-radius: 4px;
  padding: 5px;
  min-width: 200px;
}`, "",{"version":3,"sources":["webpack://./src/feature-module/stock/transportmodal.module.scss"],"names":[],"mappings":"AACI;EACI,kBAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;AAAR;AACQ;EACI,eAAA;EACA,gBAAA;AACZ;AACQ;EACI,yBAAA;EACA,kBAAA;EACA,YAAA;EACA,gBAAA;AACZ","sourcesContent":[".transportModal {\n    :global(.form-group) {\n        padding: 10px 20px;\n        display: flex;\n        justify-content: space-between;\n        align-items: center;\n        :global(.label) {\n            font-size: 14px;\n            font-weight: 500;\n        }\n        :global(.input-box) {\n            border: 1px solid #adadad;\n            border-radius: 4px;\n            padding: 5px;\n            min-width: 200px;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"transportModal": `transportmodal_transportModal__PpqKE`
};
export default ___CSS_LOADER_EXPORT___;
