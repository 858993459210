/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import { API_URL } from "../../../constants/AppConstants";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { alertData, categoryData } from "../../../core/redux/action";
import { fetchCategoryData } from "../../../utils/utilities";

const AddCategory = (props) => {
  const login_data = useSelector((state) => state.login_data);
  const [categoryName, setCategoryName] = useState("");
  const [categoryStatus, setCategorystatus] = useState(true);
  const selected_store = useSelector((state) => state.selected_store);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  async function submitData() {
    if (!categoryName) {
      dispatch(
        alertData({
          type: "error",
          msg: "Please fill all the required fields.",
        })
      );
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    let dataBody = {
      categoryName,
      categoryStatus,
      storeId: selected_store[0]?._id,
      createdOn: new Date().toLocaleDateString().split(",")[0],
      userCode: login_data?.empCode,
      itemActive: true,
    };

    try {
      let response = await axios.post(API_URL + "category", dataBody);
      if (response.status === 200) {
        fetchCategoryData(login_data, selected_store[0]);
        dispatch(
          alertData({
            type: "success",
            msg: "Category added successfully!",
          })
        );
        setTimeout(() => {
          setCategoryName("");
          setIsLoading(false);
          document?.querySelector("#add-category .close")?.click();
        }, 1000);
      }
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <div>
      {/* Add Category */}
      <div className="modal fade" id="add-category">
        <div className="modal-dialog modal-dialog-centered custom-modal-two">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>Create Category</h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    data-bs-toggle={props.from ? "modal" : ""}
                    data-bs-target={props.from ? `${props.target}` : ""}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <form>
                    <div className="mb-3">
                      <label className="form-label">
                        Category<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={categoryName}
                        onChange={(e) => setCategoryName(e.target.value)}
                      />
                    </div>
                    {/* <div className="mb-3">
                                            <label className="form-label">Category Slug</label>
                                            <input type="text" className="form-control" />
                                        </div> */}
                    <div className="mb-0">
                      <div className="status-toggle modal-status d-flex justify-content-between align-items-center">
                        <span className="status-label">Status</span>
                        <input
                          disabled
                          type="checkbox"
                          id="user2"
                          className="check"
                          defaultChecked="true"
                          value={categoryStatus}
                          onChange={(e) => setCategorystatus(e.target.value)}
                        />
                        <label htmlFor="user2" className="checktoggle" />
                      </div>
                    </div>
                    <div className="modal-footer-btn">
                      <button
                        type="button"
                        className="btn btn-cancel me-2"
                        data-bs-dismiss="modal"
                        data-bs-toggle={props.from ? "modal" : ""}
                        data-bs-target={props.from ? `${props.target}` : ""}
                      >
                        Cancel
                      </button>
                      <div
                        className={`btn btn-submit ${
                          isLoading ? "disabled" : ""
                        }`}
                        onClick={submitData}
                      >
                        Create Category
                      </div>
                    </div>
                  </form>
                  <span style={{ fontStyle: "italic", fontSize: 12 }}>
                    All fields with<span style={{ color: "red" }}>*</span> are
                    required
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Add Category */}
    </div>
  );
};

export default AddCategory;
