/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
// import CountUp from "react-countup";
import {
  File,
  User,
  UserCheck,
} from "feather-icons-react/build/IconComponents";
import Chart from "react-apexcharts";
import { Link } from "react-router-dom";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import { ArrowRight } from "react-feather";
import { useSelector } from "react-redux";
import { all_routes } from "../../Router/all_routes";

// import { all_routes } from "../../Router/all_routes";
// import withReactContent from "sweetalert2-react-content";
// import Swal from "sweetalert2";

const Dashboard = () => {
  const route = all_routes;

  const dayData = ["7", "14", "30", "90"];
  const monthsData = ["Jan", "Feb"];
  const yearData = [
    new Date().getFullYear(),
    new Date().getFullYear() - 1,
    new Date().getFullYear() - 2,
    new Date().getFullYear() - 3,
  ];
  const quarterData = ["First", "Second", "Third", "Fourth"];

  const data = useSelector((state) => state.toggle_header);
  const brand_data = useSelector((state) => state.brand_data);
  const selected_store = useSelector((state) => state.selected_store);
  const supplier_data = useSelector((state) => state.supplier_data);
  const products_data = useSelector((state) => state.products_data);
  const purchase_data = useSelector((state) => state.purchase_data);
  const sales_data = useSelector((state) => state.sales_data);
  const stocks_data = useSelector((state) => state.stocks_data);
  const customer_data = useSelector((state) => state.customer_data);

  const [purchaseDataDue, setPurchaseDataDue] = useState(0);
  const [purchaseData, setPurchaseData] = useState([]);
  const [saleDataDue, setSaleDataDue] = useState(0);
  const [salesData, setSalesData] = useState([]);
  const [grandTotal, setGrandTotal] = useState(0);
  const [customersData, setCustomersData] = useState([]);
  const [supplierData, setSupplierData] = useState([]);
  const [productdata, setProductData] = useState([]);
  const [expiredProducts, setExpiredProducts] = useState(
    products_data ? products_data : []
  );

  const [chartOptions, setChartOptions] = useState({
    series: [],
    colors: ["#28C76F", "#EA5455"],
    chart: {
      type: "bar",
      height: 320,
      stacked: true,
      zoom: {
        enabled: true,
      },
    },
    responsive: [
      {
        breakpoint: 280,
        options: {
          legend: {
            position: "bottom",
            offsetY: 0,
          },
        },
      },
    ],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 4,
        borderRadiusApplication: "end",
        borderRadiusWhenStacked: "all",
        columnWidth: "20%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    yaxis: {
      min: -200,
      max: 300,
      tickAmount: 5,
    },
    xaxis: {
      categories: [],
    },
    legend: { show: false },
    fill: {
      opacity: 1,
    },
  });

  const [subDropDown, setSubDropDown] = useState(dayData);

  const handleDropdownChange = (event) => {
    const value = event.target.value;
    switch (value) {
      case "day":
        setSubDropDown(dayData);
        break;
      case "month":
        setSubDropDown(monthsData);
        break;
      case "year":
        setSubDropDown(yearData);
        break;
      case "quarter":
        setSubDropDown(quarterData);
        break;

      default:
        break;
    }
  };

  const calculateDataCounts = (data, startDate, endDate, value) => {
    const filteredData = data.filter(
      (item) =>
        new Date(item.date.$date) >= startDate &&
        new Date(item.date.$date) < endDate
    );
    const counts = Array.from({ length: parseInt(value) }, () => 0);

    filteredData.forEach((item) => {
      const index = Math.floor(
        (new Date(item.date.$date) - startDate) / (24 * 60 * 60 * 1000)
      );
      counts[index]++;
    });

    return counts;
  };

  const handleSubDropDown = (event) => {
    const value = event.target.value;
    let newData = [];
    let categories = [];

    const currentDate = new Date();
    const endDate = new Date();
    endDate.setDate(currentDate.getDate() + 1); // Add 1 day to include current day

    let startDate;
    startDate = new Date();
    startDate.setDate(startDate.getDate() - parseInt(value) + 1);

    categories = Array.from(
      { length: parseInt(value) },
      (_, i) => `Day ${i + 1}`
    );

    const salesCount = calculateDataCounts(
      salesData,
      startDate,
      endDate,
      value
    );
    const purchaseCount = calculateDataCounts(
      purchaseData,
      startDate,
      endDate,
      value
    );

    switch (value) {
      case "7":
      case "14":
      case "30":
      case "90":
        newData = [
          { name: "Sales", data: salesCount },
          { name: "Purchase", data: purchaseCount },
        ];
        break;

      default:
        // Default case (e.g., for 7 days)
        break;
    }

    setChartOptions({
      ...chartOptions,
      series: newData,
      xaxis: {
        ...chartOptions.xaxis,
        categories: categories,
      },
    });
  };

  useEffect(() => {
    // console.log(data)
    // console.log(brand_data)
    // console.log(selected_store)
    // console.log(supplier_data)
    // console.log(products_data)
    // console.log(purchase_data)
    // console.log(stocks_data);

    if (purchase_data) {
      setPurchaseData(purchase_data);
      const totalDueAmount = purchase_data.reduce(
        (total, purchase) =>
          total +
          (parseInt(purchase.grandTotal) -
            parseInt(purchase.paidAmount !== "" ? purchase.paidAmount : 0)),
        0
      );
      // console.log(totalDueAmount);
      setPurchaseDataDue(totalDueAmount);
    }

    if (sales_data) {
      setSalesData(sales_data);
      const totalDueAmount = sales_data.reduce(
        (total, sale) =>
          total +
          (parseInt(sale.grandTotal) -
            parseInt(sale.paidAmount !== "" ? sale.paidAmount : 0)),
        0
      );

      const totalGrandAmount = sales_data.reduce(
        (total, sale) => total + parseInt(sale.grandTotal),
        0
      );
      setGrandTotal(totalGrandAmount);
      setSaleDataDue(totalDueAmount);
    }

    if (customer_data) {
      setCustomersData(customer_data);
    }

    if (supplier_data) {
      setSupplierData(supplier_data);
    }

    if (products_data) {
      setProductData(products_data);
    }

    if (products_data) {
      const filtered = stocks_data.filter(
        (item) => item.productQty <= item.minQty
      );

      // Sort the filtered array based on the difference between productQty and minQty
      filtered.sort((a, b) => {
        return a.productQty - b.productQty;
      });
      // console.log(filtered);

      setExpiredProducts(filtered);
    }
  }, [
    data,
    selected_store,
    purchase_data,
    sales_data,
    customer_data,
    products_data,
  ]);

  function formatAmount(amount) {
    const formatter = new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      maximumFractionDigits: 2,
    });
    return formatter.format(amount);
  }

  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="row">
            <div className="col-xl-3 col-sm-6 col-12 d-flex">
              <div className="dash-widget w-100">
                <div className="dash-widgetimg">
                  <span>
                    <ImageWithBasePath
                      src="assets/img/icons/dash1.svg"
                      alt="img"
                    />
                  </span>
                </div>
                <div className="dash-widgetcontent">
                  <h5>
                    {purchaseDataDue
                      ? formatAmount(purchaseDataDue.toFixed(2))
                      : `0`}
                    {/* <CountUp start={0} end={307144} duration={3} prefix="$" /> */}
                  </h5>
                  <h6>Total Purchase Due</h6>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6 col-12 d-flex">
              <div className="dash-widget dash1 w-100">
                <div className="dash-widgetimg">
                  <span>
                    <ImageWithBasePath
                      src="assets/img/icons/dash2.svg"
                      alt="img"
                    />
                  </span>
                </div>
                <div className="dash-widgetcontent">
                  <h5>
                    {saleDataDue ? formatAmount(saleDataDue.toFixed(2)) : `₹0`}
                    {/* $
                    <CountUp
                      start={0}
                      end={4385}
                      duration={3} // Duration in seconds
                    /> */}
                  </h5>
                  <h6>Total Sales Due</h6>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6 col-12 d-flex">
              <div className="dash-widget dash2 w-100">
                <div className="dash-widgetimg">
                  <span>
                    <ImageWithBasePath
                      src="assets/img/icons/dash3.svg"
                      alt="img"
                    />
                  </span>
                </div>
                <div className="dash-widgetcontent">
                  <h5>
                    {grandTotal ? formatAmount(grandTotal.toFixed(2)) : `0`}
                    {/* $
                    <CountUp
                      start={0}
                      end={385656.5}
                      duration={3} // Duration in seconds
                      decimals={1}
                    /> */}
                  </h5>
                  <h6>Total Sale Amount</h6>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6 col-12 d-flex">
              <div className="dash-widget dash3 w-100">
                <div className="dash-widgetimg">
                  <span>
                    <ImageWithBasePath
                      src="assets/img/icons/dash4.svg"
                      alt="img"
                    />
                  </span>
                </div>
                <div className="dash-widgetcontent">
                  <h5>
                    0
                    {/* $
                    <CountUp
                      start={0}
                      end={40000}
                      duration={3} // Duration in seconds
                    /> */}
                  </h5>
                  <h6>Total Expense Amount</h6>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6 col-12 d-flex">
              <div className="dash-count">
                <div className="dash-counts">
                  <h4>{customersData.length}</h4>
                  <h5>Customers</h5>
                </div>
                <div className="dash-imgs">
                  <User />
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6 col-12 d-flex">
              <div className="dash-count das1">
                <div className="dash-counts">
                  <h4>{supplierData.length}</h4>
                  <h5>Suppliers</h5>
                </div>
                <div className="dash-imgs">
                  <UserCheck />
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6 col-12 d-flex">
              <div className="dash-count das2">
                <div className="dash-counts">
                  <h4>
                    {
                      purchaseData.filter(
                        (item) => item?.purchaseStatus?.value === "Received"
                      ).length
                    }
                  </h4>
                  <h5>Purchase Invoice</h5>
                </div>
                <div className="dash-imgs">
                  <ImageWithBasePath
                    src="assets/img/icons/file-text-icon-01.svg"
                    className="img-fluid"
                    alt="icon"
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6 col-12 d-flex">
              <div className="dash-count das3">
                <div className="dash-counts">
                  <h4>
                    {
                      salesData.filter(
                        (item) => item.salesStatus.value === "Completed"
                      ).length
                    }
                  </h4>
                  <h5>Sales Invoice</h5>
                </div>
                <div className="dash-imgs">
                  <File />
                </div>
              </div>
            </div>
          </div>
          {/* Button trigger modal */}

          <div className="row">
            <div className="col-xl-7 col-sm-12 col-12 d-flex">
              <div className="card flex-fill">
                <div className="card-header d-flex justify-content-between align-items-center">
                  <h5 className="card-title mb-0">Purchase &amp; Sales</h5>
                  <div className="graph-sets">
                    <ul className="mb-0">
                      <li>
                        <span>Sales</span>
                      </li>
                      <li>
                        <span>Purchase</span>
                      </li>
                    </ul>
                    <div className="dropdown dropdown-wraper">
                      <select
                        className="form-select"
                        onChange={handleDropdownChange}
                      >
                        <option value="day">Day</option>
                        <option value="month">Month</option>
                        <option value="year">Year</option>
                        <option value="quarter">Quarter</option>
                      </select>
                    </div>
                    <div
                      className="dropdown dropdown-wraper"
                      style={{ marginLeft: "30px" }}
                    >
                      <select
                        className="form-select"
                        onChange={handleSubDropDown}
                      >
                        {subDropDown.map((item, index) => (
                          <option key={index} value={item}>
                            {item}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div id="sales_charts" />
                  <Chart
                    options={chartOptions}
                    series={chartOptions.series}
                    type="bar"
                    height={320}
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-5 col-sm-12 col-12 d-flex">
              <div className="card flex-fill default-cover mb-4">
                <div className="card-header d-flex justify-content-between align-items-center">
                  <h4 className="card-title mb-0">Recent Products</h4>
                  <div className="view-all-link">
                    <Link
                      to={route.productlist}
                      className="view-all d-flex align-items-center"
                    >
                      View All
                      <span className="ps-2 d-flex align-items-center">
                        <ArrowRight className="feather-16" />
                      </span>
                    </Link>
                  </div>
                </div>
                <div className="card-body">
                  <div className="table-responsive dataview">
                    <table className="table dashboard-recent-products">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Products</th>
                          <th>Category</th>
                          <th>ItemCode</th>
                        </tr>
                      </thead>
                      <tbody>
                        {productdata.reverse().map((item, index) => {
                          return (
                            <tr key={index + 1}>
                              <td>{index + 1}</td>
                              <td className="productimgname">
                                <Link to={route.productlist}>
                                  {item.productName}
                                </Link>
                              </td>
                              <td>{item.productCategory.label}</td>
                              <td>{item.productItemCode}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Expired Products</h4>
            </div>
            <div className="card-body">
              <div className="table-responsive dataview">
                <table className="table dashboard-expired-products">
                  <thead>
                    <tr>
                      <th className="no-sort">#</th>
                      <th>Product</th>
                      <th>SKU</th>
                      <th>ItemCode</th>
                      <th style={{ textAlign: "left" }}>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {expiredProducts.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            <div className="productimgname">
                              <Link to="#">{item.productName}</Link>
                            </div>
                          </td>
                          <td>
                            <Link to="#">{item.productSKU}</Link>
                          </td>
                          <td>{item.productItemCode.slice(0, 6)}</td>
                          <td>
                            <span
                              className={`${
                                parseFloat(item.productQty) <= 0
                                  ? " badge badge-bgdanger" // If productQty is 0, show danger (expired)
                                  : parseFloat(item.productQty) <
                                    parseFloat(item.minQty)
                                  ? "badge badge-bgwarning" // If productQty is less than minQty, show warning
                                  : "" // Otherwise, no additional class
                              }`}
                            >
                              {`Available QTY: ${item.productQty}`}
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
