/* eslint-disable no-empty */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import {
  ChevronUp,
  Eye,
  FileText,
  PlusCircle,
  RotateCcw,
  Sliders,
  StopCircle,
  User,
} from "feather-icons-react/build/IconComponents";
import {
  alertData,
  loaderData,
  setToogleHeader,
} from "../../core/redux/action";
import { useDispatch, useSelector } from "react-redux";
import { Filter } from "react-feather";
import Select from "react-select";
import { DatePicker } from "antd";
import axios from "axios";
import { API_URL } from "../../constants/AppConstants";
import { purchaseReturnData, updatePage } from "../../core/redux/action";
import { Modal } from "antd";
import PrintPreview from "../../common/PrintPreview";
import PrintLayout from "../../common/PrintLayout";
import CSS from "./purchaselist.module.scss";
import SupplierModal from "../../core/modals/peoples/supplierModal";
import TransportModal from "../stock/transportmodal";
import {
  fetchPurchaseReturnData,
  fetchPurchasesData,
} from "../../utils/utilities";

const PurchaseReturns = () => {
  const dispatch = useDispatch();
  const login_data = useSelector((state) => state.login_data);
  const data = useSelector((state) => state.toggle_header);
  const selected_store = useSelector((state) => state.selected_store);
  const customer_data = useSelector((state) => state.customer_data);
  const supplier_data = useSelector((state) => state.supplier_data);
  const products_data = useSelector((state) => state.products_data);
  const purchase_data = useSelector((state) => state.purchase_data);
  const purchase_return_data = useSelector(
    (state) => state.purchase_return_data
  );

  const stocks_data = useSelector((state) => state.stocks_data);

  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedPurchaseData, setSelectedPurchaseData] = useState({});
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [selectedUnits, setSelectedUnits] = useState([
    {
      label: "Choose",
      value: null,
    },
  ]);
  const [selectedQty, setSelectedQty] = useState([null]);

  const [productInput, setProductInput] = useState("");
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [updatedProductList, setUpdatedProductList] = useState([null]);
  const [showProductList, setShowProductList] = useState(false);
  const [filteredList, setFilteredProductList] = useState(products_data);

  const [orderTaxValue, setOrderTaxValue] = useState(0);
  const [discountType, setDiscountType] = useState([
    {
      label: "Choose",
      value: null,
    },
  ]);
  const [discountValue, setDiscountValue] = useState(0);
  const [shippingValue, setShippingValue] = useState(null);
  const [purchaseStatus, setPurchaseStatus] = useState("");
  const [dueAmount, setDueAmount] = useState(0);
  const [dueDate, setDueDate] = useState(new Date());
  const [paidAmount, setPaidAmount] = useState("");
  const [paymentStatus, setPaymentStatus] = useState({
    label: "Pending",
    value: "Pending",
  });
  const [paymentMethod, setPaymentMethod] = useState({
    label: "Choose",
    value: null,
  });
  const [returnAmount, setReturnAmount] = useState(0);
  const [purchaseReturnStatus, setPurchaseReturnStatus] = useState("");
  const [newProductQtyArr, setNewProductArr] = useState([]);

  const [grandTotal, setGrandTotal] = useState(null);
  let totalCostRef = useRef(0);
  let dropdownRef = useRef(null);

  const [showPdfModal, setShowPdfModal] = useState(false);
  const [pdfData, setPdfData] = useState({});

  const [productStocksList, setProductStocksList] = useState([]);
  const [purchasePriceList, setPurchasePriceList] = useState([]);
  const [filteredPurchase, setFilteredPurchase] = useState([]);
  const [selectedPurchase, setSelectedPurchase] = useState(null);
  const [purchaseReturnNumber, setPurchaseReturnNumber] = useState("");

  const [viewPurchaseDetailData, setViewPurchaseDetailData] = useState({});
  const [showTransportModal, setShowTransportModal] = useState(false);

  const toggleFilterVisibility = () => {
    setIsFilterVisible((prevVisibility) => !prevVisibility);
  };
  const oldandlatestvalue = [{ value: "Sort by Date", label: "Sort by Date" }];
  const customername = [{ value: null, label: "Choose" }];
  const status = [{ value: null, label: "Choose" }];
  const paymentstatus = [{ value: null, label: "Choose" }];
  const customer = [{ value: null, label: "Choose" }];
  const suppliername = [{ value: null, label: "Choose" }];
  const statusupdate = [
    { value: "Sent", label: "Sent" },
    // { value: "Pending", label: "Pending" },
    // { value: "Partial", label: "Partial" },
  ];
  const paymenttype = [
    { value: "Cash", label: "Cash" },
    { value: "Card", label: "Card" },
    { value: "Online", label: "Online" },
  ];

  useEffect(() => {
    fetchPurchaseReturnData(login_data, selected_store[0]);
    fetchPurchasesData(login_data, selected_store[0]);
  }, [selected_store]);

  useEffect(() => {
    if (purchase_data?.length && purchase_return_data?.length) {
      let filteredData = [];

      purchase_data.forEach((purchase_item) => {
        purchase_return_data.forEach((return_item) => {
          if (purchase_item.invoiceNumber != return_item.purchaseNumber) {
            filteredData.push(purchase_item);
          }
        });
      });
      setFilteredPurchase(filteredData);
    } else {
      setFilteredPurchase(purchase_data);
    }

    // Use the filtered list of purchase items
  }, [products_data, purchase_return_data, purchase_data]);

  useEffect(() => {
    if (selectedPurchase) {
      resetData();
      const details = purchase_data.find(
        (item) =>
          item.purchaseStatus.value === "Received" &&
          item._id === selectedPurchase.value
      );

      if (details) {
        setSelectedPurchaseData(details);
        setSelectedSupplier(details.supplier);
      }
    }
  }, [selectedPurchase]);

  useEffect(() => {
    document.addEventListener("click", (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setShowProductList(false);
      }
    });
  }, []);

  useEffect(() => {
    setPurchaseReturnNumber(
      `PR-${new Date().getFullYear()}${purchase_return_data?.length + 1}`
    );
  }, [selectedPurchase]);

  useEffect(() => {
    let filteredArr = [];
    let newFilteredList = [...products_data];
    if (productInput?.length) {
      setShowProductList(true);
      filteredArr = newFilteredList?.filter((product) => {
        return product.productName
          .toLowerCase()
          .includes(productInput.toLowerCase());
      });
      setFilteredProductList(filteredArr);
    } else {
      setFilteredProductList(products_data);
    }
  }, [productInput]);

  useEffect(() => {
    let productStocksArr = [...productStocksList];

    if (selectedPurchaseData?.productsList?.length) {
      for (let i = 0; i < selectedPurchaseData.productsList.length; i++) {
        stocks_data?.forEach((item) => {
          if (item.productId == selectedPurchaseData.productsList[i]._id) {
            productStocksArr[i] = {
              productCurrentQty: Number(item.productCurrentQty),
              productUnit: item.productUnit.label,
              productCurrentAlternateQty: Number(
                item.productCurrentAlternateQty
              ),
              productClubbedUnit: item.productClubbedUnit.label,
            };
          }
        });
      }
      setPaidAmount(selectedPurchaseData.paidAmount);
    }
    setProductStocksList(productStocksArr);
  }, [selectedPurchaseData, stocks_data]);

  useEffect(() => {
    if (
      selectedQty.every((item) => item != null) &&
      !isNaN(totalCostRef.current)
    ) {
      setGrandTotal(
        Number(totalCostRef.current) +
          (shippingValue != null
            ? Number(shippingValue)
            : selectedPurchaseData?.shipping)
      );

      let updatedProductListArr = [...selectedPurchaseData.productsList];
      updatedProductListArr.forEach((item, index) => {
        updatedProductListArr[index] = {
          ...item,
          selectedQty: Number(selectedQty[index]),
          totalCost: Number(totalCostRef.current),
        };
      });
      setUpdatedProductList(updatedProductListArr);
    } else if (shippingValue != null) {
      setGrandTotal(
        Number(selectedPurchaseData?.grandTotal) -
          Number(selectedPurchaseData?.shipping) +
          Number(shippingValue)
      );
    }
  }, [selectedQty, shippingValue]);

  useEffect(() => {
    if (Number(grandTotal) >= Number(selectedPurchaseData?.paidAmount)) {
      setDueAmount(
        (
          Number(selectedPurchaseData?.paidAmount) -
          Number(!isNaN(returnAmount) ? returnAmount : 0)
        ).toFixed(2)
      );
    } else {
      setDueAmount(
        (
          Number(selectedPurchaseData?.paidAmount) -
          Number(grandTotal) -
          Number(!isNaN(returnAmount) ? returnAmount : 0)
        ).toFixed(2)
      );
    }
  }, [returnAmount, grandTotal, selectedPurchaseData]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  function changeQty(dataValue, dataId) {
    let newArr = [...selectedQty];
    newArr.splice(dataId, 1, dataValue);

    setSelectedQty(newArr);
  }

  const renderRefreshTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Refresh
    </Tooltip>
  );
  const renderCollapseTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Collapse
    </Tooltip>
  );

  async function deleteData(dataId) {
    dispatch(
      loaderData({
        type: "Loader_Data",
        payload: true,
      })
    );
    if (dataId) {
      try {
        let response = await axios.put(
          API_URL + "purchase_return/delete/" + dataId
        );
        if (response.status === 200) {
          fetchPurchaseReturnData(login_data, selected_store[0]);
          dispatch(
            loaderData({
              type: "Loader_Data",
              payload: false,
            })
          );
        }
      } catch (err) {
        console.log(err);
      }
    }
  }

  function showTransport() {
    setIsLoading(true);
    if (!selectedPurchase || !purchaseReturnNumber) {
      dispatch(
        alertData({
          type: "error",
          msg: "All fields are required",
        })
      );
      setIsLoading(false);
      return;
    } else {
      const dismissModal = document.querySelector("#dismiss-modal");
      if (dismissModal) {
        dismissModal.click();
      }

      setShowTransportModal(true);
      setIsLoading(false);
    }
  }

  async function submitData(type) {
    let dataBody = {
      storeId: selected_store[0]?._id, //
      date: new Date(selectedDate).toLocaleDateString().split(",")[0], //
      invoiceNumber: purchaseReturnNumber, //
      supplier: selectedPurchaseData?.supplier, //
      productsList: updatedProductList.every((item) => item != null)
        ? updatedProductList
        : selectedPurchaseData?.productsList, //
      orderTax: selectedPurchaseData?.orderTax, //
      discount: selectedPurchaseData?.discount, //
      discountWithUnit: selectedPurchaseData?.discountWithUnit, //
      shipping:
        shippingValue != null
          ? shippingValue
            ? shippingValue
            : 0
          : selectedPurchaseData?.shipping, //
      grandTotal:
        grandTotal != null ? grandTotal : selectedPurchaseData?.grandTotal, //
      purchaseStatus: purchaseStatus, //
      dueReturnAmount: dueAmount, //
      dueDate: new Date(dueDate).toLocaleDateString().split(",")[0], //
      paidAmount: selectedPurchaseData?.paidAmount, //
      paymentStatus, //
      paymentMethod, //
      returnAmount, //
      transportDetail: type === 1 ? false : true,
      purchaseReturnStatus, //
      purchaseNumber: selectedPurchase?.label, //
      createdOn: new Date().toLocaleDateString().split(",")[0], //
      userCode: login_data?.empCode, //
      itemActive: true, //
    };

    let stocksDataArr = [];

    if (dataBody?.productsList?.length) {
      for (let i = 0; i < dataBody.productsList.length; i++) {
        if (
          dataBody.productsList[i].selectedUnit.label ==
          dataBody.productsList[i].productClubbedUnit.label
        ) {
          stocksDataArr.push({
            productCurrentAlternateQty:
              Number(productStocksList[i].productCurrentAlternateQty) -
              Number(dataBody.productsList[i].selectedQty),
            productCurrentQty:
              Number(dataBody.productsList[i].productAlternateQty) *
              (Number(productStocksList[i].productCurrentAlternateQty) -
                Number(dataBody.productsList[i].selectedQty)),
          });
        } else {
          stocksDataArr.push({
            productCurrentQty:
              Number(productStocksList[i].productCurrentQty) -
              Number(dataBody.productsList[i].selectedQty),
            productCurrentAlternateQty:
              (Number(productStocksList[i].productCurrentQty) -
                Number(dataBody.productsList[i].selectedQty)) /
              Number(dataBody.productsList[i].productAlternateQty),
          });
        }
      }
    }

    try {
      let response = await axios.post(API_URL + "purchase_return", dataBody);
      let responseArr = [];
      if (selectedPurchaseData?.productsList?.length) {
        for (let i = 0; i < selectedPurchaseData.productsList.length; i++) {
          response[i] = await axios.put(
            API_URL + "stocks/" + selectedPurchaseData.productsList[i]._id,
            stocksDataArr[i]
          );
        }
      }
      let failed = 0;
      if (response.status === 200) {
        responseArr?.forEach((response) => {
          if (!response.status == 200) failed = 1;
        });
        if (!failed) {
          dispatch(
            alertData({
              type: "success",
              msg: "Purchase Return added successfully!",
            })
          );
          setTimeout(() => {
            setIsLoading(false);
            document?.querySelector("#add-purchase-new .close")?.click();
          }, 1000);
          fetchPurchaseReturnData(login_data, selected_store[0]);
          clearData();
        }
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  }

  function resetData() {
    // setIsLoading(false);
    // setSelectedPurchase(null);
    setSelectedPurchaseData({});
    // setSelectedDate(new Date());
    setPurchaseReturnNumber(null);

    setProductInput("");
    setReturnAmount(null);
    setPaidAmount(null);
    setSelectedProducts([]);
    setShowProductList(false);
    setFilteredProductList(
      products_data.filter((item) => item.itemActive === true)
    );

    setOrderTaxValue(null);
    setDiscountValue(null);
    setDiscountType("");
    setShippingValue(null);
    setPurchaseStatus("");
    setDueAmount(0);
    setDueDate(new Date());
    setPaymentStatus({
      label: "Pending",
      value: "Pending",
    });
    setPaymentMethod({
      label: "Choose",
      value: null,
    });
    setGrandTotal(null);
    setSelectedQty([null]);
    totalCostRef.current = 0;
  }

  function clearData() {
    setIsLoading(false);
    setSelectedPurchase(null);
    setSelectedPurchaseData({});
    setSelectedDate(new Date());
    setPurchaseReturnNumber(null);

    setProductInput("");
    setReturnAmount(null);
    setPaidAmount(null);
    setSelectedProducts([]);
    setShowProductList(false);
    setFilteredProductList(
      products_data.filter((item) => item.itemActive === true)
    );

    setOrderTaxValue(null);
    setDiscountValue(null);
    setDiscountType("");
    setShippingValue(null);
    setPurchaseStatus("");
    setDueAmount(0);
    setDueDate(new Date());
    setPaymentStatus({
      label: "Pending",
      value: "Pending",
    });
    setPaymentMethod({
      label: "Choose",
      value: null,
    });
    setGrandTotal(null);
    setSelectedQty([null]);
    totalCostRef.current = 0;
  }

  const getProductStockData = (product) => {
    const filtered = stocks_data.filter(
      (item) => item?.productItemCode === product.productItemCode
    );
    return `${
      filtered[0]?.productCurrentQty
        ? Number(filtered[0]?.productCurrentQty).toFixed(2)
        : 0
    } ${filtered[0]?.productUnit?.label} | ${
      filtered[0]?.productCurrentAlternateQty
        ? Number(filtered[0]?.productCurrentAlternateQty).toFixed(2)
        : 0
    } ${filtered[0]?.productClubbedUnit.label}`;
  };

  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Purchase Return List</h4>
                <h6>Manage Your Purchase Return</h6>
              </div>
            </div>
            <ul className="table-top-head">
              <li>
                <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top">
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderCollapseTooltip}>
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    id="collapse-header"
                    className={data ? "active" : ""}
                    onClick={() => {
                      dispatch(setToogleHeader(!data));
                    }}
                  >
                    <ChevronUp />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
            <div className="page-btn">
              <Link
                to="#"
                className="btn btn-added"
                data-bs-toggle="modal"
                data-bs-target="#add-purchase-new"
                onClick={clearData}
              >
                <PlusCircle className="me-2" />
                Add New Purchase Return
              </Link>
            </div>
          </div>
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <input
                      type="text"
                      placeholder="Search"
                      className="form-control form-control-sm formsearch"
                    />
                    <Link to className="btn btn-searchset">
                      <i data-feather="search" className="feather-search" />
                    </Link>
                  </div>
                </div>
                {/* <div className="search-path">
                  <div className="d-flex align-items-center">
                    <div className="search-path">
                      <Link
                        className={`btn btn-filter ${
                          isFilterVisible ? "setclose" : ""
                        }`}
                        id="filter_search"
                      >
                        <Filter
                          className="filter-icon"
                          onClick={toggleFilterVisibility}
                        />
                        <span onClick={toggleFilterVisibility}>
                          <ImageWithBasePath
                            src="assets/img/icons/closes.svg"
                            alt="img"
                          />
                        </span>
                      </Link>
                    </div>
                  </div>
                </div> */}
                {/* <div className="form-sort">
                  <Sliders className="info-img" />
                  <Select
                    className="select"
                    options={oldandlatestvalue}
                    placeholder="Newest"
                  />
                </div> */}
              </div>
              {/* /Filter */}
              <div
                className={`card${isFilterVisible ? " visible" : ""}`}
                id="filter_inputs"
                style={{ display: isFilterVisible ? "block" : "none" }}
              >
                <div className="card-body pb-0">
                  <div className="row">
                    <div className="col-lg-3 col-sm-6 col-12">
                      <div className="input-blocks">
                        <i data-feather="user" className="info-img" />
                        <User className="info-img" />
                        <Select
                          className="select"
                          options={customername}
                          placeholder="Newest"
                        />
                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-6 col-12">
                      <div className="input-blocks">
                        <StopCircle className="info-img" />

                        <Select
                          className="select"
                          options={status}
                          placeholder="Newest"
                        />
                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-6 col-12">
                      <div className="input-blocks">
                        <FileText className="info-img" />
                        <input
                          type="text"
                          placeholder="Enter Reference"
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-12">
                      <div className="input-blocks">
                        <StopCircle className="info-img" />

                        <Select
                          className="select"
                          options={paymentstatus}
                          placeholder="Choose Payment Status"
                        />
                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-6 col-12">
                      <div className="input-blocks">
                        <Link className="btn btn-filters ms-auto">
                          {" "}
                          <i
                            data-feather="search"
                            className="feather-search"
                          />{" "}
                          Search{" "}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /Filter */}
              <div className="table-responsive">
                <table className="table  datanew">
                  <thead>
                    <tr>
                      <th>Purchase Return</th>
                      <th>Supplier Name</th>
                      <th>Invoice</th>
                      <th>Date</th>
                      <th>Product Return Status</th>
                      <th>Grand Total</th>
                      <th>Refunded</th>
                      <th>Due</th>
                      <th>Payment Return Status</th>
                      <th className="text-right">Action</th>
                    </tr>
                  </thead>
                  <tbody className="purchase-list">
                    {purchase_return_data?.map((data, index) => {
                      return (
                        <tr key={index}>
                          <td>{data?.invoiceNumber}</td>
                          <td>{data?.supplier?.label}</td>
                          <td>{data?.purchaseNumber}</td>
                          <td>{data?.date?.split("T")[0]}</td>
                          <td>
                            <span
                              className={`badge ${
                                data?.purchaseReturnStatus?.label == "Sent"
                                  ? "badge-linesuccess"
                                  : "badge-linedanger"
                              }`}
                            >
                              {data?.purchaseReturnStatus?.label}
                            </span>
                          </td>
                          <td>{data?.grandTotal}</td>
                          <td>{data?.returnAmount}</td>
                          <td>{data?.dueReturnAmount}</td>
                          <td>
                            <span
                              className={`badge ${
                                data?.paymentStatus?.label === "Due"
                                  ? "badge-linedanger"
                                  : data?.paymentStatus?.label === "Partial"
                                  ? "badge-linewarning"
                                  : "badge-linesuccess"
                              }`}
                            >
                              {data?.paymentStatus?.label}
                            </span>
                          </td>
                          {/* <td>Admin</td> */}
                          <td>
                            <div className="d-flex justify-content-end">
                              <Link
                                className="action-set"
                                to="#"
                                data-bs-toggle="modal"
                                data-bs-target="#purchase-return-details-new"
                                onClick={() => {
                                  setViewPurchaseDetailData(data);
                                }}
                              >
                                <Eye className="feather-view" />
                              </Link>
                              <Link
                                className="action-set"
                                to="#"
                                data-bs-toggle="dropdown"
                                aria-expanded="true"
                              >
                                <i
                                  className="fa fa-ellipsis-v"
                                  aria-hidden="true"
                                />
                              </Link>
                              <ul className="dropdown-menu">
                                <li>
                                  <Link
                                    className="dropdown-item"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setShowPdfModal(true);
                                      setPdfData({
                                        heading: "Purchase Return List",
                                        array: {
                                          // Supplier: data?.supplier,
                                          from: {
                                            name: "",
                                            email: "",
                                            phone: "",
                                            address: "",
                                            country: {
                                              label: "India",
                                              value: "India",
                                            },
                                            state: {
                                              label: "Chhattisgarh",
                                              value: "Chhattisgarh",
                                            },
                                            city: {
                                              label: "Raipur",
                                              value: "Raipur",
                                            },
                                            status: "Active",
                                            itemActive: true,
                                          },
                                          to: {
                                            name: "FM Marketing",
                                            email: "fmmarketing@example.com",
                                            phone: "9876543210",
                                            address: "Raipur, Chhattisgarh",
                                            country: {
                                              label: "India",
                                              value: "India",
                                            },
                                            state: {
                                              label: "Chhattisgarh",
                                              value: "Chhattisgarh",
                                            },
                                            city: {
                                              label: "Raipur",
                                              value: "Raipur",
                                            },
                                            status: "Active",
                                            itemActive: true,
                                          },
                                          Reference: data?.purchaseReturnNumber,
                                          Date: data?.date,
                                          Product_List: data?.productsList,
                                          Order_Tax: data?.orderTax,
                                          Discount: data?.discountWithUnit,
                                          Shipping: data?.shipping,
                                          Grand_Total: data?.grandTotal,
                                          Purchase_Return_Status:
                                            data?.purchaseStatus,
                                          Due_Amount: data?.dueAmount,
                                          Due_Date: data?.dueDate,
                                          Paid_Amount: data?.paidAmount,
                                          Payment_Status: data?.paymentStatus,
                                          Payment_Method: data?.paymentMethod,
                                        },
                                      });
                                    }}
                                  >
                                    <i
                                      data-feather="download"
                                      className="info-img"
                                    />
                                    Generate Regular Pdf
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    className="dropdown-item"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setShowPdfModal(true);
                                      setPdfData({
                                        pdfType: "Transport",
                                        heading: "Purchase Return List",
                                        array: {
                                          // Supplier: data?.supplier,
                                          from: {
                                            name: "",
                                            email: "",
                                            phone: "",
                                            address: "",
                                            country: {
                                              label: "India",
                                              value: "India",
                                            },
                                            state: {
                                              label: "Chhattisgarh",
                                              value: "Chhattisgarh",
                                            },
                                            city: {
                                              label: "Raipur",
                                              value: "Raipur",
                                            },
                                            status: "Active",
                                            itemActive: true,
                                          },
                                          to: {
                                            name: "FM Marketing",
                                            email: "fmmarketing@example.com",
                                            phone: "9876543210",
                                            address: "Raipur, Chhattisgarh",
                                            country: {
                                              label: "India",
                                              value: "India",
                                            },
                                            state: {
                                              label: "Chhattisgarh",
                                              value: "Chhattisgarh",
                                            },
                                            city: {
                                              label: "Raipur",
                                              value: "Raipur",
                                            },
                                            status: "Active",
                                            itemActive: true,
                                          },
                                          Reference: data?.purchaseReturnNumber,
                                          Date: data?.date,
                                          Product_List: data?.productsList,
                                          Order_Tax: data?.orderTax,
                                          Discount: data?.discountWithUnit,
                                          Shipping: data?.shipping,
                                          Grand_Total: data?.grandTotal,
                                          Purchase_Return_Status:
                                            data?.purchaseStatus,
                                          Due_Amount: data?.dueAmount,
                                          Due_Date: data?.dueDate,
                                          Paid_Amount: data?.paidAmount,
                                          Payment_Status: data?.paymentStatus,
                                          Payment_Method: data?.paymentMethod,
                                        },
                                      });
                                    }}
                                  >
                                    <i
                                      data-feather="download"
                                      className="info-img"
                                    />
                                    Generate Transport Pdf
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    className="dropdown-item"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      deleteData(data?._id);
                                    }}
                                  >
                                    <i
                                      data-feather="trash-2"
                                      className="info-img"
                                    />
                                    Delete Purchase
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
      <>
        {/*add popup */}
        <div className="modal fade" id="add-purchase-new">
          <div className="modal-dialog add-centered">
            <div className="modal-content">
              <div className="page-wrapper p-0 m-0">
                <div className="content p-0">
                  <div className="modal-header border-0 custom-modal-header">
                    <div className="page-title">
                      <h4>Add Purchase Return</h4>
                    </div>
                    <button
                      type="button"
                      className="close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={clearData}
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="card">
                    <div className="card-body">
                      <form>
                        <div className="row">
                          <div className="col-lg-3 col-sm-6 col-12">
                            <div className="input-blocks">
                              <label>
                                Purchase Number{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <div className="row">
                                <div className="col-lg-12 col-sm-12 col-12">
                                  <div className="d-flex justify-content-between">
                                    <div style={{ width: "100%" }}>
                                      <Select
                                        value={
                                          selectedPurchase
                                            ? selectedPurchase
                                            : [
                                                {
                                                  label: "Choose",
                                                  value: null,
                                                },
                                              ]
                                        }
                                        className="select"
                                        options={
                                          filteredPurchase?.length
                                            ? filteredPurchase?.map((item) => {
                                                return {
                                                  label: item?.invoiceNumber,
                                                  value: item?._id,
                                                };
                                              })
                                            : [
                                                {
                                                  label: "Choose",
                                                  value: null,
                                                },
                                              ]
                                        }
                                        placeholder="Choose"
                                        onChange={(value) =>
                                          setSelectedPurchase(value)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3 col-sm-6 col-12">
                            <div className="input-blocks">
                              <label>Purchase Return Date</label>
                              <div className="input-groupicon calender-input">
                                <DatePicker
                                  disabled
                                  popupStyle={{ zIndex: 2000 }}
                                  selected={selectedDate}
                                  onChange={handleDateChange}
                                  type="date"
                                  className="filterdatepicker add-purchase-filterdatepicker"
                                  dateFormat="dd-MM-yyyy"
                                  placeholder={`${selectedDate.toDateString()}`}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3 col-sm-6 col-12">
                            <div className="input-blocks">
                              <label>
                                Invoice Number
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <div className="input-groupicon calender-input">
                                <input
                                  disabled
                                  readOnly
                                  style={{ padding: 10 }}
                                  type="text"
                                  value={purchaseReturnNumber}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3 col-sm-6 col-12">
                            <div className="input-blocks">
                              <label>
                                Supplier<span style={{ color: "red" }}>*</span>
                              </label>
                              <div className="row">
                                <div className="col-lg-12 col-sm-12 col-12">
                                  <div className="d-flex justify-content-between">
                                    <div style={{ width: "100%" }}>
                                      <Select
                                        isDisabled={true}
                                        isOptionDisabled={true}
                                        value={
                                          selectedPurchaseData
                                            ? {
                                                label:
                                                  selectedPurchaseData?.supplier
                                                    ?.label,
                                                value:
                                                  selectedPurchaseData?.supplier
                                                    ?._id,
                                              }
                                            : []
                                        }
                                        className="select"
                                        placeholder="Choose"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-3 col-sm-6 col-12">
                            <div className="input-blocks">
                              <label>Order Tax(%)</label>
                              <div className="input-groupicon select-code">
                                <input
                                  disabled
                                  // value={selectedPurchaseData?.orderTax}
                                  value={0}
                                  type="text"
                                  defaultValue={0}
                                  className="p-2"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3 col-sm-6 col-12">
                            <div className="input-blocks">
                              <label>Discount Type</label>
                              <div className="input-groupicon select-code">
                                <Select
                                  isDisabled={true}
                                  value={
                                    selectedPurchaseData?.discount
                                      ? selectedPurchaseData.discountWithUnit.includes(
                                          "%"
                                        )
                                        ? { label: "Percent", value: "Percent" }
                                        : { label: "Cash", value: "Cash" }
                                      : null
                                  }
                                  className="select"
                                  placeholder="Choose"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3 col-sm-6 col-12">
                            <div className="input-blocks">
                              <label>Discount</label>
                              <div className="input-groupicon select-code">
                                <input
                                  disabled
                                  value={
                                    selectedPurchaseData
                                      ? selectedPurchaseData?.discount
                                      : 0
                                  }
                                  onChange={(e) =>
                                    setDiscountValue(e.target.value)
                                  }
                                  type="number"
                                  defaultValue={0}
                                  className="p-2"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3 col-sm-6 col-12">
                            <div className="input-blocks">
                              <label>Shipping(₹)</label>
                              <div className="input-groupicon select-code">
                                <input
                                  value={
                                    shippingValue != null
                                      ? shippingValue
                                        ? Number(shippingValue)
                                        : 0
                                      : selectedPurchaseData.shipping
                                  }
                                  onChange={(e) =>
                                    setShippingValue(e.target.value)
                                  }
                                  type="number"
                                  defaultValue={0}
                                  className="p-2"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="table-responsive no-pagination"
                          style={{ overflow: "unset" }}
                        >
                          <table className="table  datanew">
                            <thead>
                              <tr>
                                <th>Product</th>
                                <th>Unit</th>
                                <th>Qty</th>
                                <th>Alt. Qty</th>
                                <th>Current Stock</th>
                                <th>Price(₹)</th>
                                <th>Discount</th>
                                <th>Tax(%)</th>
                                <th>Tax Amt.(₹)</th>
                                <th>Unit Cost(₹)</th>
                                <th>Total Cost(₹)</th>
                              </tr>
                            </thead>
                            <tbody>
                              {selectedPurchaseData ? (
                                selectedPurchaseData.productsList?.map(
                                  (product, index) => {
                                    let newTotalCost = 0;
                                    totalCostRef.current = 0;
                                    if (selectedQty[index] != null) {
                                      newTotalCost =
                                        Number(product?.productUnitCost) *
                                        Number(selectedQty[index]);

                                      totalCostRef.current =
                                        Number(totalCostRef.current) +
                                        Number(newTotalCost);
                                    }
                                    return (
                                      <tr key={index}>
                                        <td>{product.productName}</td>
                                        <td>
                                          <input
                                            style={{ width: 100 }}
                                            disabled
                                            value={
                                              product?.selectedUnit?.label
                                                ? product?.selectedUnit?.label
                                                : ""
                                            }
                                            className="p-2"
                                          />
                                        </td>
                                        <td>
                                          <input
                                            style={{ width: 80 }}
                                            value={
                                              selectedQty[index] != null
                                                ? selectedQty[index]
                                                : product?.selectedQty
                                                ? product?.selectedQty
                                                : ""
                                            }
                                            type="number"
                                            className="p-2"
                                            onChange={(e) =>
                                              changeQty(e.target.value, index)
                                            }
                                          />
                                        </td>
                                        <td>{product?.productAlternateQty}</td>
                                        <td>{getProductStockData(product)}</td>
                                        <td>
                                          <input
                                            disabled
                                            style={{ width: 80 }}
                                            value={product?.purchasePrice}
                                            type="text"
                                            defaultValue={0}
                                            className="p-2"
                                          />
                                        </td>
                                        <td>
                                          {`
                                      ${
                                        product?.productDiscountValue
                                          ? product?.productDiscountType
                                              .label == "Cash"
                                            ? "₹"
                                            : ""
                                          : ""
                                      }
                                        ${
                                          product?.productDiscountValue
                                            ? product?.productDiscountValue
                                            : 0
                                        }
                                        ${
                                          product?.productDiscountValue
                                            ? product?.productDiscountType
                                                .label !== "Cash"
                                              ? "%"
                                              : ""
                                            : ""
                                        }
                                        `}
                                        </td>
                                        <td>
                                          {product.productTaxValue
                                            ? product.productTaxValue
                                            : 0}
                                        </td>
                                        <td>{product.productCurrentTax}</td>
                                        <td>
                                          {product?.productUnitCost.toFixed(2)}
                                        </td>
                                        <td>
                                          {selectedQty[index] != null
                                            ? newTotalCost.toFixed(2)
                                            : product.totalCost
                                            ? product.totalCost.toFixed(2)
                                            : 0}
                                        </td>
                                      </tr>
                                    );
                                  }
                                )
                              ) : (
                                <></>
                              )}
                            </tbody>
                          </table>
                        </div>
                        <div className="row">
                          {/* <div className="col-lg-6 ms-auto">
                            <div className="total-order w-100 max-widthauto m-auto mb-4">
                              <ul>
                                <li>
                                  <h4>Previous Total Tax(₹)</h4>
                                  <h5>{selectedPurchaseData?.orderTax}</h5>
                                </li>
                                <li>
                                  <h4>
                                    Previous Discount
                                    {discountType.label == "Percent"
                                      ? "(%)"
                                      : discountType.label == "Cash"
                                      ? "(₹)"
                                      : ""}
                                  </h4>
                                  <h5>{selectedPurchaseData?.discount}</h5>
                                </li>
                                <li>
                                  <h4>Previous Shipping(₹)</h4>
                                  <h5>
                                    {shippingValue != null
                                      ? shippingValue
                                        ? shippingValue
                                        : 0
                                      : selectedPurchaseData?.shipping}
                                  </h5>
                                </li>
                                <li>
                                  <h4>Previous Grand Total(₹)</h4>
                                  <h5>
                                    {grandTotal != null
                                      ? grandTotal
                                      : selectedPurchaseData?.grandTotal}
                                  </h5>
                                </li>
                              </ul>
                            </div>
                          </div> */}
                          <div className="col-lg-6 ms-auto">
                            <div className="total-order w-100 max-widthauto m-auto mb-4">
                              <ul>
                                <li>
                                  <h4>Total Tax(₹)</h4>
                                  <h5>{selectedPurchaseData?.orderTax}</h5>
                                </li>
                                <li>
                                  <h4>
                                    Discount
                                    {discountType.label == "Percent"
                                      ? "(%)"
                                      : discountType.label == "Cash"
                                      ? "(₹)"
                                      : ""}
                                  </h4>
                                  <h5>{selectedPurchaseData?.discount}</h5>
                                </li>
                                <li>
                                  <h4>Shipping(₹)</h4>
                                  <h5>
                                    {shippingValue != null
                                      ? shippingValue
                                        ? shippingValue
                                        : 0
                                      : selectedPurchaseData?.shipping}
                                  </h5>
                                </li>
                                <li>
                                  <h4>Grand Total(₹)</h4>
                                  <h5>
                                    {grandTotal != null
                                      ? Number(grandTotal).toFixed(2)
                                      : Number(
                                          selectedPurchaseData?.grandTotal
                                        ).toFixed(2)}
                                  </h5>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-3 col-sm-6 col-12">
                            <div className="input-blocks">
                              <label>Refund Amount(₹) </label>
                              <div className="input-groupicon select-code">
                                <input
                                  value={returnAmount}
                                  onChange={(e) => {
                                    setReturnAmount(e.target.value);
                                  }}
                                  type="number"
                                  defaultValue={0}
                                  className="p-2"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3 col-sm-6 col-12">
                            <div className="input-blocks">
                              <label>Due Refund Amount(₹) </label>
                              <div className="input-groupicon select-code">
                                <input
                                  readOnly
                                  disabled
                                  value={dueAmount}
                                  type="number"
                                  defaultValue={0}
                                  className="p-2"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3 col-sm-6 col-12">
                            <div className="input-blocks">
                              <label>Due Date</label>
                              <div className="input-groupicon calender-input">
                                {/* <DatePicker
                                  style={{ width: "100%" }}
                                  popupStyle={{ zIndex: 2000 }}
                                  selected={dueDate}
                                  onChange={(date) => setDueDate(date)}
                                  type="date"
                                  className="filterdatepicker"
                                  dateFormat="dd-MM-yyyy"
                                  placeholder="Choose Date"
                                /> */}
                                <input
                                  onChange={(e) => {
                                    setDueDate(e.target.value);
                                  }}
                                  type="date"
                                  className="custom-date-picker"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3 col-sm-6 col-12">
                            <div className="input-blocks">
                              <label>Paid Amount(₹)</label>
                              <div className="input-groupicon select-code">
                                <input
                                  disabled
                                  value={paidAmount}
                                  onChange={(e) =>
                                    setPaidAmount(e.target.value)
                                  }
                                  type="number"
                                  defaultValue={0}
                                  className="p-2"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-lg-3 col-sm-6 col-12">
                              <div className="input-blocks mb-5">
                                <label>Products Return Status </label>

                                <Select
                                  value={
                                    purchaseReturnStatus
                                      ? purchaseReturnStatus
                                      : [
                                          {
                                            label: "Choose",
                                            value: null,
                                          },
                                        ]
                                  }
                                  onChange={(value) =>
                                    setPurchaseReturnStatus(value)
                                  }
                                  className="select"
                                  options={statusupdate}
                                  placeholder="status"
                                />
                              </div>
                            </div>
                            <div className="col-lg-3 col-sm-6 col-12">
                              <div className="input-blocks mb-5">
                                <label>Payment Status </label>

                                <div className="input-groupicon select-code">
                                  <Select
                                    value={paymentStatus}
                                    className="select"
                                    options={[
                                      { label: "Received", value: "Received" },
                                      { label: "Pending", value: "Pending" },
                                    ]}
                                    placeholder="Choose"
                                    onChange={(value) =>
                                      setPaymentStatus(value)
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-sm-6 col-12">
                              <div className="input-blocks mb-5">
                                <label>Payment Method </label>

                                <div className="input-groupicon select-code">
                                  <Select
                                    value={paymentMethod}
                                    className="select"
                                    options={[
                                      { value: "Cash", label: "Cash" },
                                      { value: "Card", label: "Card" },
                                      { value: "Online", label: "Online" },
                                    ]}
                                    placeholder="Choose"
                                    onChange={(value) =>
                                      setPaymentMethod(value)
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12 text-end">
                            <button
                              type="button"
                              className="btn btn-cancel add-cancel me-3"
                              data-bs-dismiss="modal"
                              onClick={clearData}
                            >
                              Cancel
                            </button>
                            <div
                              className={`btn btn-submit ${
                                isLoading ? "disabled" : ""
                              }`}
                              onClick={showTransport}
                            >
                              Submit
                            </div>
                            <span
                              data-bs-dismiss="modal"
                              id="dismiss-modal"
                            ></span>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /add popup */}
        {/* details popup */}
        <div className="modal fade" id="purchase-return-details-new">
          <div className="modal-dialog purchase-return-details-modal">
            <div className="modal-content">
              <div className="page-wrapper details-blk">
                <div className="content p-0">
                  <div className="modal-header border-0 custom-modal-header">
                    <div className="page-title">
                      <h4>
                        Purchase Return Detail: #
                        {/* {viewPurchaseDetailData?.invoiceNumber} */}
                      </h4>
                    </div>
                    <button
                      type="button"
                      className="close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={() => {
                        setViewPurchaseDetailData({});
                      }}
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="card">
                    <div className="card-body">
                      <form>
                        <div className="flex">
                          <div className="inline-flex mr-50">
                            <h5 className="order-text">Supplier: </h5>
                            <h5 className="order-subtext ml-10">
                              {/* {viewPurchaseDetailData?.supplier?.label} */}
                            </h5>
                          </div>
                          <div className="inline-flex">
                            <h5 className="order-text">Purchase Date: </h5>
                            <h5 className="order-subtext ml-10">
                              {/* {viewPurchaseDetailData?.createdOn} */}
                            </h5>
                          </div>
                        </div>
                        <div
                          className="reference-box table-height"
                          style={{
                            maxWidth: 1600,
                            width: "100%",
                            overflow: "auto",
                            padding: 0,
                            fontSize: 14,
                            lineHeight: 24,
                            color: "#555",
                          }}
                        >
                          <h5 className="order-text">Order Summary</h5>
                          <div className="table-responsive no-pagination">
                            <table className="table  datanew">
                              <thead>
                                <tr>
                                  <th>Product</th>
                                  <th>Unit</th>
                                  <th>Qty</th>
                                  <th>Alt. Qty</th>
                                  <th>Price(₹)</th>
                                  <th>Discount</th>
                                  <th>Tax(%)</th>
                                  <th>Tax Amount(₹)</th>
                                  <th>Unit Cost(₹)</th>
                                  <th>Total Cost(₹)</th>
                                </tr>
                              </thead>
                              <tbody>
                                {/* {viewPurchaseDetailData?.productsList &&
                                  viewPurchaseDetailData?.productsList?.map(
                                    (item, index) => {
                                      return (
                                        <tr key={index}>
                                          <td>{item?.productName}</td>
                                          <td>{item?.selectedUnit?.label}</td>
                                          <td>{item?.selectedQty}</td>
                                          <td>{item?.productAlternateQty}</td>
                                          <td>{item?.purchasePrice}</td>
                                          <td>
                                            {item?.productDiscountValue +
                                              (item?.productDiscountType
                                                ?.label == "Cash"
                                                ? "₹"
                                                : "%")}
                                          </td>
                                          <td>{item?.productTaxValue}</td>
                                          <td>{item?.productCurrentTax}</td>
                                          <td>{item?.productUnitCost}</td>
                                          <td>{item?.totalCost}</td>
                                        </tr>
                                      );
                                    }
                                  )} */}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="row justify-content-end mb-30">
                          <div className="row justify-content-end">
                            <div className="col-lg-6">
                              <div className="total-order w-100 max-widthauto m-auto mb-4">
                                <ul>
                                  <li>
                                    <h4>Total Tax(₹)</h4>
                                    <h5>
                                      {/* {viewPurchaseDetailData?.orderTax
                                        ? viewPurchaseDetailData?.orderTax
                                        : 0} */}
                                    </h5>
                                  </li>
                                  <li>
                                    <h4>Discount(₹)</h4>
                                    <h5>
                                      {/* {viewPurchaseDetailData?.discount
                                        ? viewPurchaseDetailData?.discount
                                        : 0} */}
                                    </h5>
                                  </li>
                                  <li>
                                    <h4>Shipping(₹)</h4>
                                    <h5>
                                      {/* {viewPurchaseDetailData?.shipping
                                        ? viewPurchaseDetailData?.shipping
                                        : 0} */}
                                    </h5>
                                  </li>
                                  <li>
                                    <h4>Grand Total(₹)</h4>
                                    <h5>
                                      {/* {viewPurchaseDetailData?.grandTotal} */}
                                    </h5>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex">
                          <div className="inline-flex mr-50">
                            <h5 className="order-text">Due Amount:</h5>
                            <h5 className="order-subtext ml-10">
                              {/* {viewPurchaseDetailData?.dueAmount} */}
                            </h5>
                          </div>
                          <div className="inline-flex mr-50">
                            <h5 className="order-text">Due Date:</h5>
                            <h5 className="order-subtext ml-10">
                              {/* {viewPurchaseDetailData?.dueDate} */}
                            </h5>
                          </div>
                          <div className="inline-flex">
                            <h5 className="order-text">Paid Amount:</h5>
                            <h5 className="order-subtext ml-10">
                              {/* {viewPurchaseDetailData?.paidAmount} */}
                            </h5>
                          </div>
                        </div>

                        <div className="flex">
                          <div className="inline-flex mr-50">
                            <h5 className="order-text">Purchase Status:</h5>
                            <h5 className="order-subtext ml-10">
                              {/* {viewPurchaseDetailData?.purchaseStatus?.label} */}
                            </h5>
                          </div>
                          <div className="inline-flex mr-50">
                            <h5 className="order-text">Payment Status:</h5>
                            <h5 className="order-subtext ml-10">
                              {/* {viewPurchaseDetailData?.paymentStatus} */}
                            </h5>
                          </div>
                          <div className="inline-flex">
                            <h5 className="order-text">Payment Method:</h5>
                            <h5 className="order-subtext ml-10">
                              {/* {viewPurchaseDetailData?.paymentMethod} */}
                            </h5>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /details popup */}
        {/* edit popup */}
        <div className="modal fade" id="edit-purchase-new">
          <div className="modal-dialog edit-purchase-modal">
            <div className="modal-content">
              <div className="page-wrapper p-0 m-0">
                <div className="content p-0">
                  <div className="page-header p-4 mb-0">
                    <div className="add-item new-purchase-items d-flex">
                      <div className="page-title">
                        <h4>Edit Purchase</h4>
                      </div>
                      <button
                        type="button"
                        className="close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-body">
                      <form>
                        <div className="row">
                          <div className="col-lg-4 col-sm-6 col-12">
                            <div className="input-blocks">
                              <label>Customer</label>
                              <div className="row">
                                <div className="col-lg-10 col-sm-10 col-10">
                                  <Select
                                    className="select"
                                    options={customer}
                                    placeholder="Newest"
                                  />
                                </div>
                                <div className="col-lg-2 col-sm-2 col-2 ps-0">
                                  <div className="add-icon">
                                    <Link to="#" className="choose-add">
                                      <PlusCircle className="plus" />
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4 col-sm-6 col-12">
                            <div className="input-blocks">
                              <label>Purchase Date</label>
                              <div className="input-groupicon calender-input">
                                <DatePicker
                                  selected={selectedDate}
                                  onChange={handleDateChange}
                                  type="date"
                                  className="filterdatepicker"
                                  dateFormat="dd-MM-yyyy"
                                  placeholder="Choose Date"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4 col-sm-6 col-12">
                            <div className="input-blocks">
                              <label>Purchase Number</label>
                              <Select
                                value={
                                  selectedPurchase
                                    ? selectedPurchase
                                    : [
                                        {
                                          label: "Choose",
                                          value: null,
                                        },
                                      ]
                                }
                                className="select"
                                options={
                                  filteredPurchase?.length &&
                                  filteredPurchase?.map((item) => {
                                    return {
                                      label: item.invoiceNumber,
                                      value: item._id,
                                    };
                                  })
                                }
                                placeholder="Choose Supplier"
                                onChange={(value) => setSelectedPurchase(value)}
                              />
                            </div>
                          </div>
                          <div className="col-lg-12 col-sm-6 col-12">
                            <div className="input-blocks">
                              <label>Product Name</label>
                              <div className="input-groupicon select-code">
                                <input
                                  type="text"
                                  placeholder="Please type product code and select"
                                />
                                <div className="addonset">
                                  <ImageWithBasePath
                                    src="assets/img/icons/scanners.svg"
                                    alt="img"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="table-responsive no-pagination">
                          <table className="table  datanew">
                            <thead>
                              <tr>
                                <th>Product</th>
                                <th>Qty</th>
                                <th>Price($)</th>
                                <th>Discount($)</th>
                                <th>Tax(%)</th>
                                <th>Tax Amount($)</th>
                                <th>Unit Cost($)</th>
                                <th>Total Cost(%)</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <div className="productimgname">
                                    <Link
                                      to="#"
                                      className="product-img stock-img"
                                    >
                                      <ImageWithBasePath
                                        src="assets/img/products/stock-img-02.png"
                                        alt="product"
                                      />
                                    </Link>
                                    <Link to="#">Nike Jordan</Link>
                                  </div>
                                </td>
                                <td>
                                  <div className="product-quantity">
                                    <span className="quantity-btn">
                                      +
                                      <i
                                        data-feather="plus-circle"
                                        className="plus-circle"
                                      />
                                    </span>
                                    <input
                                      type="text"
                                      className="quntity-input"
                                      defaultValue={2}
                                    />
                                    <span className="quantity-btn">
                                      <i
                                        data-feather="minus-circle"
                                        className="feather-search"
                                      />
                                    </span>
                                  </div>
                                </td>
                                <td>2000</td>
                                <td>500</td>
                                <td>0.00</td>
                                <td>0.00</td>
                                <td>0.00</td>
                                <td>1500</td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="productimgname">
                                    <Link
                                      to="#"
                                      className="product-img stock-img"
                                    >
                                      <ImageWithBasePath
                                        src="assets/img/products/stock-img-03.png"
                                        alt="product"
                                      />
                                    </Link>
                                    <Link to="#">Apple Series 5 Watch</Link>
                                  </div>
                                </td>
                                <td>
                                  <div className="product-quantity">
                                    <span className="quantity-btn">
                                      +
                                      <i
                                        data-feather="plus-circle"
                                        className="plus-circle"
                                      />
                                    </span>
                                    <input
                                      type="text"
                                      className="quntity-input"
                                      defaultValue={2}
                                    />
                                    <span className="quantity-btn">
                                      <i
                                        data-feather="minus-circle"
                                        className="feather-search"
                                      />
                                    </span>
                                  </div>
                                </td>
                                <td>3000</td>
                                <td>400</td>
                                <td>0.00</td>
                                <td>0.00</td>
                                <td>0.00</td>
                                <td>1700</td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="productimgname">
                                    <Link
                                      to="#"
                                      className="product-img stock-img"
                                    >
                                      <ImageWithBasePath
                                        src="assets/img/products/stock-img-05.png"
                                        alt="product"
                                      />
                                    </Link>
                                    <Link to="#">Lobar Handy</Link>
                                  </div>
                                </td>
                                <td>
                                  <div className="product-quantity">
                                    <span className="quantity-btn">
                                      +
                                      <i
                                        data-feather="plus-circle"
                                        className="plus-circle"
                                      />
                                    </span>
                                    <input
                                      type="text"
                                      className="quntity-input"
                                      defaultValue={2}
                                    />
                                    <span className="quantity-btn">
                                      <i
                                        data-feather="minus-circle"
                                        className="feather-search"
                                      />
                                    </span>
                                  </div>
                                </td>
                                <td>2500</td>
                                <td>500</td>
                                <td>0.00</td>
                                <td>0.00</td>
                                <td>0.00</td>
                                <td>2000</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="row">
                          <div className="col-lg-6 ms-auto">
                            <div className="total-order w-100 max-widthauto m-auto mb-4">
                              <ul>
                                <li>
                                  <h4>Order Tax</h4>
                                  <h5>$ 0.00</h5>
                                </li>
                                <li>
                                  <h4>Discount</h4>
                                  <h5>$ 0.00</h5>
                                </li>
                                <li>
                                  <h4>Shipping</h4>
                                  <h5>$ 0.00</h5>
                                </li>
                                <li>
                                  <h4>Grand Total</h4>
                                  <h5>$5200.00</h5>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-3 col-sm-6 col-12">
                            <div className="input-blocks">
                              <label>Order Tax</label>
                              <div className="input-groupicon select-code">
                                <input type="text" placeholder={0} />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3 col-sm-6 col-12">
                            <div className="input-blocks">
                              <label>Discount</label>
                              <div className="input-groupicon select-code">
                                <input type="text" placeholder={0} />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3 col-sm-6 col-12">
                            <div className="input-blocks">
                              <label>Shipping</label>
                              <div className="input-groupicon select-code">
                                <input type="text" placeholder={0} />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3 col-sm-6 col-12">
                            <div className="input-blocks mb-5">
                              <label>Status</label>
                              <Select
                                className="select"
                                options={statusupdate}
                                placeholder="Newest"
                              />
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="input-blocks">
                              <label>Notes</label>
                              <textarea
                                className="form-control"
                                defaultValue={""}
                              />
                            </div>
                          </div>
                          <div className="col-lg-12 text-end">
                            <button
                              type="button"
                              className="btn btn-cancel add-cancel me-3"
                              data-bs-dismiss="modal"
                              onClick={clearData}
                            >
                              Cancel
                            </button>
                            <Link
                              to="#"
                              className="btn btn-submit add-purchase"
                            >
                              Submit
                            </Link>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /edit popup */}
        {/* show payment Modal */}
        <div
          className="modal fade"
          id="showpayment"
          tabIndex={-1}
          aria-labelledby="showpayment"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered stock-adjust-modal">
            <div className="modal-content">
              <div className="page-wrapper-new p-0">
                <div className="content">
                  <div className="modal-header border-0 custom-modal-header">
                    <div className="page-title">
                      <h4>Show Payments</h4>
                    </div>
                    <button
                      type="button"
                      className="close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body custom-modal-body">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="modal-body-table total-orders">
                          <div className="table-responsive">
                            <table className="table  datanew">
                              <thead>
                                <tr>
                                  <th>Date</th>
                                  <th>Reference</th>
                                  <th>Amount</th>
                                  <th>Paid By</th>
                                  <th className="no-sort">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>19 Jan 2023</td>
                                  <td>INV/SL0101</td>
                                  <td>$1500</td>
                                  <td>Cash</td>
                                  <td className="action-table-data">
                                    <div className="edit-delete-action">
                                      <Link className="me-3 p-2" to="#">
                                        <i
                                          data-feather="printer"
                                          className="feather-rotate-ccw"
                                        />
                                      </Link>
                                      <Link
                                        className="me-3 p-2"
                                        to="#"
                                        data-bs-toggle="modal"
                                        data-bs-target="#editpayment"
                                      >
                                        <i
                                          data-feather="edit"
                                          className="feather-edit"
                                        />
                                      </Link>
                                      <Link className="confirm-text p-2" to="#">
                                        <i
                                          data-feather="trash-2"
                                          className="feather-trash-2"
                                        />
                                      </Link>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* show payment Modal */}
        {/* Create payment Modal */}
        <div
          className="modal fade"
          id="createpayment"
          tabIndex={-1}
          aria-labelledby="createpayment"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header border-0 custom-modal-header">
                <div className="page-title">
                  <h4>Create Payments</h4>
                </div>
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body custom-modal-body">
                <form>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="input-blocks">
                        <label> Date</label>
                        <div className="input-groupicon calender-input">
                          <DatePicker
                            selected={selectedDate}
                            onChange={handleDateChange}
                            type="date"
                            className="filterdatepicker"
                            dateFormat="dd-MM-yyyy"
                            placeholder="Choose Date"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-12 col-12">
                      <div className="input-blocks">
                        <label>Reference</label>
                        <input type="text" className="form-control" />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-4 col-sm-12 col-12">
                      <div className="input-blocks">
                        <label>Received Amount</label>
                        <div className="input-groupicon calender-input">
                          <i data-feather="dollar-sign" className="info-img" />
                          <input type="text" />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-12 col-12">
                      <div className="input-blocks">
                        <label>Paying Amount</label>
                        <div className="input-groupicon calender-input">
                          <i data-feather="dollar-sign" className="info-img" />
                          <input type="text" />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-12 col-12">
                      <div className="input-blocks">
                        <label>Payment type</label>

                        <Select
                          className="select"
                          options={paymenttype}
                          placeholder="Newest"
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="input-blocks">
                        <label>Description</label>
                        <textarea className="form-control" defaultValue={""} />
                        <p>Maximum 60 Characters</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="modal-footer-btn">
                      <button
                        type="button"
                        className="btn btn-cancel me-2"
                        data-bs-dismiss="modal"
                        onClick={clearData}
                      >
                        Cancel
                      </button>
                      <Link to="#" className="btn btn-submit">
                        Submit
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* Create payment Modal */}
        {/* edit payment Modal */}
        <div
          className="modal fade"
          id="editpayment"
          tabIndex={-1}
          aria-labelledby="editpayment"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header border-0 custom-modal-header">
                <div className="page-title">
                  <h4>Edit Payments</h4>
                </div>
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body custom-modal-body">
                <form>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="input-blocks">
                        <label>19 Jan 2023</label>
                        <div className="input-groupicon calender-input">
                          <i data-feather="calendar" className="info-img" />
                          <input
                            type="text"
                            className="datetimepicker form-control"
                            placeholder="Select Date"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-12 col-12">
                      <div className="input-blocks">
                        <label>Reference</label>
                        <input type="text" defaultValue="INV/SL0101" />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-4 col-sm-12 col-12">
                      <div className="input-blocks">
                        <label>Received Amount</label>
                        <div className="input-groupicon calender-input">
                          <i data-feather="dollar-sign" className="info-img" />
                          <input type="text" defaultValue={1500} />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-12 col-12">
                      <div className="input-blocks">
                        <label>Paying Amount</label>
                        <div className="input-groupicon calender-input">
                          <i data-feather="dollar-sign" className="info-img" />
                          <input type="text" defaultValue={1500} />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-12 col-12">
                      <div className="input-blocks">
                        <label>Payment type</label>
                        <select className="select">
                          <option>Cash</option>
                          <option>Online</option>
                          <option>Inprogress</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="input-blocks summer-description-box transfer">
                        <label>Description</label>
                        <textarea className="form-control" defaultValue={""} />
                      </div>
                      <p>Maximum 60 Characters</p>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="modal-footer-btn mb-3 me-3">
                      <button
                        type="button"
                        className="btn btn-cancel me-2"
                        data-bs-dismiss="modal"
                        onClick={clearData}
                      >
                        Cancel
                      </button>
                      <button type="submit" className="btn btn-submit">
                        Save Changes
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* edit payment Modal */}
      </>
      {/* <SupplierModal from={"Purchase Return"} target="#add-purchase-new" /> */}

      <Modal
        className={`${CSS.printPreviewModal}`}
        open={showPdfModal}
        footer={false}
        onCancel={() => {
          setShowPdfModal(false);
        }}
      >
        <div className={`${CSS.modalBox}`}>
          <PrintPreview>
            <PrintLayout data={pdfData} />
          </PrintPreview>
        </div>
      </Modal>

      <TransportModal
        showTransportModal={showTransportModal}
        setShowTransportModal={setShowTransportModal}
        submitData={submitData}
        invoiceNumber={purchaseReturnNumber}
        from={"Purchase Return"}
        target="#add-purchase-new"
        data={{
          storeId: selected_store[0]?._id,
          userCode: login_data?.empCode,
        }}
      />
    </div>
  );
};

export default PurchaseReturns;
