/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Filter, Sliders } from "react-feather";
import Select from "react-select";
import { Edit, Eye, Globe, Trash2, User } from "react-feather";
import { useSelector, useDispatch } from "react-redux";
import { Table } from "antd";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ImageWithBasePath from "../../img/imagewithbasebath";
import Breadcrumbs from "../../breadcrumbs";
import StoreModal from "./storeModals";
import axios from "axios";
import { AlignRight } from "feather-icons-react/build/IconComponents";
import { API_URL } from "../../../constants/AppConstants";
import {
  storesData,
  updatePage,
  selectStore,
  joyrideData,
  detailData,
  loaderData,
} from "../../../core/redux/action";
import Joyride, {
  ACTIONS,
  EVENTS,
  ORIGIN,
  STATUS,
  CallBackProps,
} from "react-joyride";
import { fetchStoresData } from "../../../utils/utilities";

const StoreList = () => {
  const stores_data = useSelector((state) => state.stores_data);
  const joyride_data = useSelector((state) => state.joyride_data);

  const dispatch = useDispatch();
  const step_0 = [
    {
      target: ".store-list .btn-added",
      content: "Step 1 is to add a store.",
    },
  ];
  const step_1 = [
    {
      target: ".header .nav .select-store",
      content: "Step 2 is to select a store after adding store(s)",
    },
  ];

  const step_2 = [
    {
      target: ".sidebar-menu ul li:nth-child(2)",
      content:
        "Next step is to create category, sub-category & brand to create a product.",
    },
  ];

  const step_3 = [
    {
      target: ".sidebar-menu ul li:nth-child(7)",
      content:
        "To make sales, purchase, sales-return, purchase-return, quotation, you need to add customer, supplier first.",
    },
  ];

  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const toggleFilterVisibility = () => {
    setIsFilterVisible((prevVisibility) => !prevVisibility);
  };

  const options = [
    { value: "sortByDate", label: "Sort by Date" },
    // { value: "140923", label: "14 09 23" },
    // { value: "110923", label: "11 09 23" },
  ];
  const optionsTwo = [
    { label: "Choose Store Name", value: "" },
    // { label: "Benjamin", value: "Benjamin" },
    // { label: "Ellen", value: "Ellen" },
    // { label: "Freda", value: "Freda" },
    // { label: "Kaitlin", value: "Kaitlin" },
  ];

  const countries = [
    { label: "Choose Country", value: "" },
    // { label: "India", value: "India" },
    // { label: "USA", value: "USA" },
  ];

  const columns = [
    // {
    //   render: () => (
    //     <label className="checkboxs">
    //       <input type="checkbox" />
    //       <span className="checkmarks" />
    //     </label>
    //   ),
    // },

    {
      title: "Store Name",
      dataIndex: "storeName",
      // sorter: (a, b) => a.CustomerName.length - b.CustomerName.length,
    },
    // {
    //   title: "Code",
    //   dataIndex: "Code",
    //   sorter: (a, b) => a.Code.length - b.Code.length,
    // },
    // {
    //   title: "Store",
    //   dataIndex: "Customer",
    //   sorter: (a, b) => a.Customer.length - b.Customer.length,
    // },
    {
      title: "Email",
      dataIndex: "storeEmail",
      // sorter: (a, b) => a.Email.length - b.Email.length,
    },

    {
      title: "Phone",
      dataIndex: "storePhone",
      // sorter: (a, b) => a.Phone.length - b.Phone.length,
    },

    {
      title: "City",
      dataIndex: "storeCity",
      render: (data) => data.label,
      // sorter: (a, b) => a.Store.length - b.Store.length,
    },
    {
      title: "Status",
      dataIndex: "storeStatus",
      render: (text) => (
        <span
          className={`badge ${
            text == "Active" ? "badge-linesuccess" : "badge-linedanger"
          }`}
        >
          {text}
        </span>
      ),
      // sorter: (a, b) => a.Store.length - b.Store.length,
    },

    {
      title: "Action",
      AlignRight,
      dataIndex: "_id",
      render: (data) => (
        <td className="action-table-data">
          <div className="edit-delete-action">
            <div className="input-block add-lists"></div>

            {/* <Link className="me-2 p-2" to="#">
              <Eye className="feather-view" />
            </Link> */}
            <Link
              className="me-2 p-2"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#edit-units"
              onClick={() => {
                dispatch(
                  detailData({
                    type: "Store",
                    id: data,
                  })
                );
              }}
            >
              <Edit className="feather-edit" />
            </Link>

            {/* <div
              className="confirm-text p-2 feather-trash-container"
              onClick={() => deleteData(data)}
            >
              <Trash2 className="feather-trash-2" />
            </div> */}
          </div>
        </td>
      ),
      // sorter: (a, b) => a.createdby.length - b.createdby.length,
    },
  ];

  useEffect(() => {
    if (!stores_data?.length) {
      dispatch(joyrideData(0));
    }
    fetchStoresData();
  }, []);

  const MySwal = withReactContent(Swal);

  async function deleteData(dataId) {
    console.log(dataId);
    // dispatch(
    //   loaderData({
    //     type: "Loader_Data",
    //     payload: true,
    //   })
    // );
    // if (dataId) {
    //   try {
    //     let response = await axios.put(API_URL + "stores/" + dataId);
    //     if (response.status === 200) {
    //       dispatch(storesData(response.data));
    //       dispatch(selectStore([]));
    //       dispatch(
    //         loaderData({
    //           type: "Loader_Data",
    //           payload: false,
    //         })
    //       );
    //     }
    //   } catch (err) {
    //     console.log(err);
    //   }
    // }
  }

  const showConfirmationAlert = () => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes, delete it!",
      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        MySwal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          className: "btn btn-success",
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      } else {
        MySwal.close();
      }
    });
  };

  const handleJoyrideCallback0 = (data) => {
    const { action, index, origin, status, type } = data;
    if (action === ACTIONS.CLOSE) {
      dispatch(joyrideData(1));
    }
  };

  const handleJoyrideCallback1 = (data) => {
    const { action, index, origin, status, type } = data;
    if (action === ACTIONS.CLOSE) {
      dispatch(joyrideData(2));
    }
  };

  const handleJoyrideCallback2 = (data) => {
    const { action, index, origin, status, type } = data;
    if (action === ACTIONS.CLOSE) {
      dispatch(joyrideData(3));
    }
  };

  const handleJoyrideCallback3 = (data) => {
    const { action, index, origin, status, type } = data;
    if (action === ACTIONS.CLOSE) {
      dispatch(joyrideData(4));
    }
  };

  return (
    <div className="page-wrapper">
      {joyride_data == 0 ? (
        <Joyride
          callback={handleJoyrideCallback0}
          disableOverlayClose
          steps={step_0}
        />
      ) : (
        <></>
      )}
      {joyride_data == 1 ? (
        <Joyride
          callback={handleJoyrideCallback1}
          disableOverlayClose
          steps={step_1}
        />
      ) : (
        <></>
      )}
      {joyride_data == 2 ? (
        <Joyride
          callback={handleJoyrideCallback2}
          disableOverlayClose
          steps={step_2}
        />
      ) : (
        <></>
      )}
      {joyride_data == 3 ? (
        <Joyride
          callback={handleJoyrideCallback3}
          disableOverlayClose
          steps={step_3}
        />
      ) : (
        <></>
      )}
      <div className="content">
        <Breadcrumbs
          maintitle="Store List"
          subtitle="Manage Your Store"
          addButton="Add Store"
          pageName="store-list"
        />

        {/* /product list */}
        <div className="card table-list-card">
          <div className="card-body">
            <div className="table-top">
              <div className="search-set">
                <div className="search-input">
                  <input
                    type="text"
                    placeholder="Search"
                    className="form-control form-control-sm formsearch"
                  />
                  <Link to className="btn btn-searchset">
                    <i data-feather="search" className="feather-search" />
                  </Link>
                </div>
              </div>
              {/* <div className="search-path">
                <Link
                  className={`btn btn-filter ${
                    isFilterVisible ? "setclose" : ""
                  }`}
                  id="filter_search"
                >
                  <Filter
                    className="filter-icon"
                    onClick={toggleFilterVisibility}
                  />
                  <span onClick={toggleFilterVisibility}>
                    <ImageWithBasePath
                      src="assets/img/icons/closes.svg"
                      alt="img"
                    />
                  </span>
                </Link>
              </div> */}
              {/* <div className="form-sort stylewidth">
                <Sliders className="info-img" />
                <Select
                  className="select "
                  options={options}
                  placeholder="Sort by Date"
                />
              </div> */}
            </div>
            {/* /Filter */}
            <div
              className={`card${isFilterVisible ? " visible" : ""}`}
              id="filter_inputs"
              style={{ display: isFilterVisible ? "block" : "none" }}
            >
              <div className="card-body pb-0">
                <div className="row">
                  <div className="col-lg-3 col-sm-6 col-12">
                    <div className="input-blocks">
                      <User className="info-img" />
                      <Select
                        options={optionsTwo}
                        placeholder="Choose Store Name"
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6 col-12">
                    <div className="input-blocks">
                      <Globe className="info-img" />
                      <Select
                        options={countries}
                        placeholder="Choose Country"
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6 col-12 ms-auto">
                    <div className="input-blocks">
                      <a className="btn btn-filters ms-auto">
                        {" "}
                        <i
                          data-feather="search"
                          className="feather-search"
                        />{" "}
                        Search{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /Filter */}
            <div className="table-responsive">
              <Table
                className="table datanew"
                columns={columns}
                dataSource={stores_data}
                rowKey={(record) => record._id}
                // pagination={true}
              />
            </div>
          </div>
        </div>
        {/* /product list */}
      </div>
      <StoreModal />
    </div>
  );
};

export default StoreList;
