/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../core/breadcrumbs";
import { Filter, Sliders } from "react-feather";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import Select from "react-select";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Archive, Box, Calendar, User } from "react-feather";
import ManageStockModal from "../../core/modals/stocks/managestockModal";
import { Edit, Trash2 } from "react-feather";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Table from "../../core/pagination/datatable";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { API_URL } from "../../constants/AppConstants";
import { stocksData } from "../../core/redux/action";
import { fetchStocksData } from "../../utils/utilities";

const Managestock = () => {
  const login_data = useSelector((state) => state.login_data);
  const data = useSelector((state) => state.toggle_header);
  const stocks_data = useSelector((state) => state.stocks_data);
  const selected_store = useSelector((state) => state.selected_store);
  const dispatch = useDispatch();

  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);

  const [filteredStocks, setFilteredStocks] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const [categoryFilterArr, setCategoryFilterArr] = useState([]);
  const [brandListFilterArr, setBrandListFilterArr] = useState([]);
  const [warehouseFilterArr, setWarehouseFilterArr] = useState([]);
  const [selectedCategoryFilterData, setSelectedCategoryFilterData] =
    useState("");
  const [selectedBrandFilterData, setSelectedBrandFilterData] = useState("");
  const [selectedWarehouseFilterData, setSelectedWarehouseFilterData] =
    useState("");
  const [selectedDateFilterData, setSelectedDateFilterData] = useState("");
  const [selectedDataId, setSelectedDataId] = useState("");
  const [selectedStockData, setSelectedStockData] = useState({});

  const toggleFilterVisibility = () => {
    setIsFilterVisible((prevVisibility) => !prevVisibility);
  };
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const options = [
    { value: "sortByDate", label: "Sort by Date" },
    // { value: "140923", label: "14 09 23" },
    // { value: "110923", label: "11 09 23" },
  ];

  const warehouseOptions = [
    { value: "Choose Warehouse", label: "Choose Warehouse" },
    // { value: "Lobar Handy", label: "Lobar Handy" },
    // { value: "Quaint Warehouse", label: "Quaint Warehouse" },
    // { value: "Traditional Warehouse", label: "Traditional Warehouse" },
    // { value: "Cool Warehouse", label: "Cool Warehouse" },
  ];

  const productOptions = [
    { value: "Choose Product", label: "Choose Product" },
    // { value: "Nike Jordan", label: "Nike Jordan" },
    // { value: "Apple Series 5 Watch", label: "Apple Series 5 Watch" },
    // { value: "Amazon Echo Dot", label: "Amazon Echo Dot" },
    // { value: "Lobar Handy", label: "Lobar Handy" },
  ];

  const personOptions = [
    { value: "Choose Person", label: "Choose Person" },
    // { value: "Steven", label: "Steven" },
    // { value: "Gravely", label: "Gravely" },
  ];

  const columns = [
    {
      title: "Product",
      dataIndex: "productName",
      // render: (text, record) => (
      //   <span className="userimgname">
      //     <Link to="#" className="product-img">
      //       <ImageWithBasePath alt="img" src={record.Product.Image} />
      //     </Link>
      //     <Link to="#">{record.Product.Name}</Link>
      //   </span>
      // ),
      // sorter: (a, b) => a.Product.Name.length - b.Product.Name.length,
    },
    {
      title: "SKU",
      dataIndex: "productSKU",
      // sorter: (data) => (data ? data : 0),
    },
    {
      title: "Item Code",
      dataIndex: "productItemCode",
      // sorter: (data) => (data ? data : 0),
    },
    // {
    //   title: "Category",
    //   dataIndex: "productItemCode",
    //   // sorter: (data) => (data ? data : 0),
    // },
    // {
    //   title: "Brand",
    //   dataIndex: "productItemCode",
    //   // sorter: (data) => (data ? data : 0),
    // },
    {
      title: "Main Unit",
      dataIndex: "productUnit",
      render: (item) => item.label,
    },
    // {
    //   title: "Main Qty",
    //   dataIndex: "productQty",
    //   render: (data) => (data ? data : 0),
    // },
    {
      title: "Alternate Unit",
      dataIndex: "productClubbedUnit",
      render: (item) => item.label,
    },
    {
      title: "Alternate Qty",
      dataIndex: "productAlternateQty",
      render: (data) => (data ? data : 0),
    },
    {
      title: "Current Stock",
      render: (data) =>
        data.productCurrentQty +
        ` ${data.productUnit.label} (${
          Number(data.productCurrentAlternateQty)
            ? Number(data.productCurrentAlternateQty).toFixed(2)
            : 0
        } ${data.productClubbedUnit.label})`,
    },
    {
      title: "Date",
      dataIndex: "createdOn",
      // sorter: (a, b) => a.Email.length - b.Email.length,
    },
    {
      title: "Warehouse",
      dataIndex: "productWarehouse",
      render: (text) => <span>{text?.label}</span>,
      // sorter: (a, b) => a.Warehouse.length - b.Warehouse.length,
    },
    {
      title: "Action",
      dataIndex: "_id",
      render: (data) => (
        <td className="action-table-data">
          <div className="edit-delete-action">
            <div className="input-block add-lists"></div>

            <Link
              className="me-2 p-2"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#edit-units"
              onClick={() => {
                setSelectedDataId(data);
              }}
            >
              <Edit className="feather-edit" />
            </Link>

            {/* <Link
              className="confirm-text p-2"
              to="#"
              // onClick={showConfirmationAlert}
            >
              <Trash2 className="feather-trash-2" />
            </Link> */}
          </div>
        </td>
      ),
      // sorter: (a, b) => a.createdby.length - b.createdby.length,
    },
  ];

  const MySwal = withReactContent(Swal);

  useEffect(() => {
    fetchStocksData(login_data, selected_store[0]);
  }, [selected_store]);

  useEffect(() => {
    setFilteredStocks(stocks_data);
  }, [stocks_data]);

  useEffect(() => {
    if (selectedDataId) {
      stocks_data.forEach((data) => {
        if (data._id == selectedDataId) {
          setSelectedStockData(data);
        }
      });
    }
  }, [selectedDataId]);

  useEffect(() => {
    if (searchTerm) {
      setSelectedWarehouseFilterData("");
      const filteredData = filteredStocks.filter((product) => {
        // const matchesWarehouse = filters.category
        //   ? product.productCategory.label === filters.category
        //   : true;
        // const matchesBrand = filters.brand
        //   ? product.productBrand.label === filters.brand
        //   : true;
        const matchesSearch =
          product.productName
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          product.productItemCode
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          (product.productSKU &&
            product.productSKU
              .toLowerCase()
              .includes(searchTerm.toLowerCase()));

        return matchesSearch;
      });

      setFilteredStocks(filteredData);
    } else {
      setFilteredStocks(stocks_data);
    }
  }, [searchTerm]);

  useEffect(() => {
    if (stocks_data && stocks_data?.length) {
      // let categoryFilterArrCopy = [];
      let warehouseFilterArrCopy = [];
      // let brandFilterArrCopy = [];

      stocks_data.forEach((item) => {
        // if (item.productCategory.value) {
        //   categoryFilterArrCopy.push(item.productCategory.label);
        // }
        if (item.productWarehouse.label) {
          warehouseFilterArrCopy.push(item.productWarehouse.label);
        }
        // if (item.productBrand.value) {
        //   brandFilterArrCopy.push(item.productBrand.label);
        // }
      });

      // let uniqCategoryFilterArrCopy = [...new Set(categoryFilterArrCopy)];
      let uniqWarehouseFilterArrCopy = [...new Set(warehouseFilterArrCopy)];
      // let uniqBrandFilterArrCopy = [...new Set(brandFilterArrCopy)];

      // setCategoryFilterArr(uniqCategoryFilterArrCopy);
      setWarehouseFilterArr(uniqWarehouseFilterArrCopy);
      // setBrandListFilterArr(uniqBrandFilterArrCopy);
    }
  }, [stocks_data]);

  useEffect(() => {
    if (!searchTerm) {
      if (
        selectedCategoryFilterData?.value ||
        selectedWarehouseFilterData?.value ||
        selectedBrandFilterData?.value
      ) {
        const filteredData = stocks_data.filter((product) => {
          const matchesCategory = selectedCategoryFilterData?.value
            ? product.productCategory.label === selectedCategoryFilterData.label
            : true;
          const matchesWarehouse = selectedWarehouseFilterData?.value
            ? product.productWarehouse.label ===
              selectedWarehouseFilterData.label
            : true;
          const matchesBrand = selectedBrandFilterData?.value
            ? product.productBrand.label === selectedBrandFilterData?.label
            : true;

          return matchesCategory && matchesWarehouse && matchesBrand;
        });

        setFilteredStocks(filteredData);
      } else {
        setFilteredStocks(stocks_data);
      }
    }
  }, [
    selectedCategoryFilterData,
    selectedWarehouseFilterData,
    selectedBrandFilterData,
    selectedDateFilterData,
  ]);

  const showConfirmationAlert = () => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes, delete it!",
      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        MySwal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          className: "btn btn-success",
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      } else {
        MySwal.close();
      }
    });
  };
  return (
    <div className="page-wrapper">
      <div className="content">
        <Breadcrumbs
          maintitle="Manage Stock"
          subtitle="Manage your stock"
          addButton="Add New"
        />
        {/* /product list */}
        <div className="card table-list-card">
          <div className="card-body">
            <div className="table-top">
              <div className="search-set">
                <div className="search-input">
                  <input
                    type="text"
                    placeholder="Search by product, item code or sku"
                    className="form-control form-control-sm formsearch"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  <Link to className="btn btn-searchset">
                    <i data-feather="search" className="feather-search" />
                  </Link>
                </div>
              </div>
              {/* <Select
                value={
                  selectedCategoryFilterData.value
                    ? selectedCategoryFilterData
                    : [
                        {
                          label: "Category",
                          value: null,
                        },
                      ]
                }
                className="select mr-10"
                options={categoryFilterArr?.map((item, index) => {
                  return { label: item, value: index + 1 };
                })}
                placeholder="Category"
                onChange={(value) => setSelectedCategoryFilterData(value)}
              /> */}
              <Select
                value={
                  selectedWarehouseFilterData.value
                    ? selectedWarehouseFilterData
                    : [
                        {
                          label: "Warehouse",
                          value: null,
                        },
                      ]
                }
                className="select mr-10"
                options={warehouseFilterArr?.map((item, index) => {
                  return { label: item, value: index + 1 };
                })}
                placeholder="Warehouse"
                onChange={(value) => setSelectedWarehouseFilterData(value)}
              />
              {/* <Select
                value={
                  selectedBrandFilterData.value
                    ? selectedBrandFilterData
                    : [
                        {
                          label: "Brand",
                          value: null,
                        },
                      ]
                }
                className="select mr-10"
                options={brandListFilterArr?.map((item, index) => {
                  return { label: item, value: index + 1 };
                })}
                placeholder="Brand"
                onChange={(value) => setSelectedBrandFilterData(value)}
              /> */}
              {/* <Select
                value={
                  selectedDateFilterData.value
                    ? selectedDateFilterData
                    : [
                        {
                          label: "Date",
                          value: null,
                        },
                      ]
                }
                className="select mr-10"
                options={[
                  {
                    label: "Latest",
                    value: "Latest",
                  },
                  {
                    label: "Oldest",
                    value: "Oldest",
                  },
                ]}
                placeholder="Date"
                onChange={(value) => setSelectedDateFilterData(value)}
              /> */}
              <button
                className="btn btn-danger"
                onClick={() => {
                  // setSelectedCategoryFilterData("");
                  setSelectedWarehouseFilterData("");
                  // setSelectedBrandFilterData("");
                  // setSelectedDateFilterData("");
                }}
              >
                Clear
              </button>
            </div>
            <div
              className={`card${isFilterVisible ? " visible" : ""}`}
              id="filter_inputs"
              style={{ display: isFilterVisible ? "block" : "none" }}
            >
              <div className="card-body pb-0">
                <div className="row">
                  <div className="col-lg-2 col-sm-6 col-12">
                    <div className="input-blocks">
                      <Archive className="info-img" />
                      <Select
                        className="select"
                        options={warehouseOptions}
                        placeholder="Choose Warehouse"
                      />
                    </div>
                  </div>
                  <div className="col-lg-2 col-sm-6 col-12">
                    <div className="input-blocks">
                      <Box className="info-img" />
                      <Select
                        className="select"
                        options={productOptions}
                        placeholder="Choose Product"
                      />
                    </div>
                  </div>
                  <div className="col-lg-2 col-sm-6 col-12">
                    <div className="input-blocks">
                      <Calendar className="info-img" />
                      <div className="input-groupicon">
                        <DatePicker
                          selected={selectedDate}
                          onChange={handleDateChange}
                          dateFormat="dd/MM/yyyy"
                          placeholderText="Choose Date"
                          className="datetimepicker"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-2 col-sm-6 col-12">
                    <div className="input-blocks">
                      <User className="info-img" />
                      <Select
                        className="select"
                        options={personOptions}
                        placeholder="Choose Person"
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-sm-6 col-12 ms-auto">
                    <div className="input-blocks">
                      <a className="btn btn-filters ms-auto">
                        {" "}
                        <i
                          data-feather="search"
                          className="feather-search"
                        />{" "}
                        Search{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /Filter */}
            <div className="table-responsive">
              <Table
                className="table datanew"
                columns={columns}
                dataSource={filteredStocks}
                rowKey={(record) => record.id}
                // pagination={true}
              />
            </div>
          </div>
        </div>
        {/* /product list */}
      </div>
      <ManageStockModal
        setSelectedDataId={setSelectedDataId}
        selectedStockData={selectedStockData}
        setSelectedStockData={setSelectedStockData}
      />
    </div>
  );
};

export default Managestock;
