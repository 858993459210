/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from "react";
import "./PrintLayout.css";
function PrintLayout({ data }) {
  const { heading, array, pdfType = "Regular" } = data;

  console.log(array);
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString("en-US", options);
  };

  return (
    <>
      <link
        href="https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css"
        rel="stylesheet"
      />

      <div className="container">
        <div className="col-md-12">
          <div
            className="invoice"
            style={{ border: "1px solid lightgrey", borderRadius: "10px" }}
          >
            {/* <!-- begin invoice-company --> */}
            <div className="invoice-company text-inverse f-w-600">
              FM Marketing Pvt. Ltd.
            </div>
            {/* <!-- end invoice-company --> */}
            {/* <!-- begin invoice-header --> */}
            <div className="invoice-header">
              <div className="invoice-date">
                <small>
                  Invoice / {formatDate(array?.Date).split(" ")[0]} period
                </small>
                <div className="date text-inverse m-t-5">
                  {formatDate(array?.Date)}
                </div>
                <div className="invoice-detail">#{array?.Reference}</div>
              </div>
              <div className="invoice-header-flex">
                <div className="invoice-to">
                  <small>To:</small>
                  {array?.to ? (
                    <address className="mt-20">
                      <div className="mb-5">
                        <strong className="text-inverse">
                          {array?.to?.name.replace(/\b\w/g, (char) =>
                            char.toUpperCase()
                          )}
                        </strong>
                      </div>
                      <div className="mb-5">
                        <b>Email:</b> {array?.to?.email}
                      </div>
                      <div className="mb-5">
                        <b>Address: </b>
                        {`${array?.to?.address}, ${array?.to?.city.label}, ${array?.to?.state.label}`}
                      </div>
                      <div className="mb-5">
                        <b>Phone:</b> +91 {array?.to?.phone}
                      </div>
                    </address>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="invoice-from">
                  <small>From:</small>

                  {array?.from ? (
                    <address className="mt-20">
                      <div className="mb-5">
                        <strong className="text-inverse">
                          {array?.from?.name?.replace(/\b\w/g, (char) =>
                            char.toUpperCase()
                          )}
                        </strong>
                      </div>
                      <div className="mb-5">
                        <b>Email:</b> {array?.from.email}
                      </div>
                      <div className="mb-5">
                        <b>Address: </b>
                        {`${array?.from.address}, ${array?.from?.city?.label}, ${array?.from?.state?.label}`}
                      </div>
                      <div className="mb-5">
                        <b>Phone:</b> +91 {array?.from?.phone}
                      </div>
                    </address>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            {/* <!-- end invoice-header --> */}
            {/* <!-- begin invoice-content --> */}
            <div className="invoice-content">
              {/* <!-- begin table-responsive --> */}
              <div className="table-responsive">
                <table className="table table-invoice">
                  <thead>
                    <tr>
                      <th>Product Details</th>
                      <th width="10%">Qty</th>
                      <th width="10%">Tax Amt.</th>
                      {pdfType == "Regular" && <th width="10%">Unit Cost</th>}
                      {pdfType == "Regular" && (
                        <th className="text-right" width="20%">
                          Total Price
                        </th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {array?.["Product_List"]?.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <span
                              className="text-inverse"
                              style={{ fontWeight: 600 }}
                            >
                              {item?.productName}
                            </span>
                            <br />
                            <small>{item?.productDescription} </small>
                            <small style={{ fontStyle: "italic" }}>
                              {item?.productCategory?.label} |{" "}
                              {item?.productBrand?.label} | Item Code:{" "}
                              {item?.productItemCode} | SKU: {item?.productSKU}{" "}
                            </small>
                          </td>
                          <td>{item?.selectedQty}</td>
                          <td>{item?.productCurrentTax}</td>
                          {pdfType == "Regular" && (
                            <td>₹ {item?.productUnitCost}.00</td>
                          )}
                          {pdfType == "Regular" && (
                            <td
                              className="text-right"
                              style={{ textAlign: "right" }}
                            >
                              ₹{" "}
                              {Number(item?.productUnitCost) *
                                Number(item?.selectedQty)}
                            </td>
                          )}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              {/* <!-- end table-responsive -->
            <!-- begin invoice-price --> */}
              {pdfType == "Regular" && (
                <div className="invoice-price">
                  <div className="invoice-price-left">
                    <div className="invoice-price-row">
                      <div className="sub-price">
                        <small>SUBTOTAL</small>
                        <span className="text-inverse">
                          ₹{" "}
                          {Number(array?.["Grand_Total"]) -
                            Number(array?.Shipping ? array?.Shipping : 0)}
                        </span>
                      </div>
                      <div className="sub-price">
                        <i className="fa fa-plus text-muted"></i>
                      </div>
                      <div className="sub-price">
                        <small>Shipping Cost</small>
                        <span className="text-inverse">
                          ₹ {array?.Shipping ? array?.Shipping : "00"}.00
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="invoice-price-right">
                    <small>TOTAL</small>{" "}
                    <span className="f-w-600">
                      ₹ {Number(array?.["Grand_Total"])}
                    </span>
                  </div>
                </div>
              )}
              {/* <!-- end invoice-price --> */}
            </div>
            {/* <!-- end invoice-content -->
         <!-- begin invoice-note --> */}
            <div className="invoice-note"></div>
            <div className="invoice-footer">
              <p className="text-center m-b-5 f-w-600">
                THANK YOU FOR YOUR BUSINESS
              </p>
              <p>
                <span className="m-r-10">
                  <i className="fa fa-fw fa-lg fa-globe"></i>{" "}
                  matiasgallipoli.com
                </span>
                <span className="m-r-10">
                  <i className="fa fa-fw fa-lg fa-phone-volume"></i>{" "}
                  T:016-18192302
                </span>
                <span className="m-r-10">
                  <i className="fa fa-fw fa-lg fa-envelope"></i>{" "}
                  rtiemps@gmail.com
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PrintLayout;
