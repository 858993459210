/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
// import { PlusCircle } from 'feather-icons-react/build/IconComponents'
import { API_URL } from "../../../constants/AppConstants";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { alertData, brandData } from "../../../core/redux/action";

const EditBrand = (props) => {
  const login_data = useSelector((state) => state.login_data);
  const dispatch = useDispatch();
  const [brandName, setBrandName] = useState("");
  const [brandStatus, setBrandstatus] = useState(true);
  const selected_store = useSelector((state) => state.selected_store);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const detail_data = useSelector((state) => state.detail_data);
  const brand_data = useSelector((state) => state.brand_data);

  useEffect(() => {
    props.setIsCategoryUpdated(false);
    if (detail_data?.id && brand_data) {
      brand_data?.forEach((brand) => {
        if (brand._id == detail_data.id) {
          setSelectedData(brand);
        }
      });
    }
  }, [detail_data]);

  useEffect(() => {
    if (selectedData) {
      setBrandName(selectedData?.brandName);
      setBrandstatus(selectedData?.brandStatus);
    }
  }, [selectedData]);

  async function fetchData() {
    try {
      let response = await axios.get(API_URL + "brands");
      if (response.status === 200) {
        dispatch(brandData(response.data));
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function submitData() {
    setIsLoading(true);
    let dataBody = {
      brandName,
      brandStatus,
      storeId: selected_store[0]?._id,
      createdOn: selectedData?.createdOn,
      upadtedOn: new Date().toLocaleDateString().split(",")[0],
      userCode: login_data?.empCode,
    };

    try {
      let response = await axios.put(
        API_URL + "brands/" + selectedData?._id,
        dataBody
      );
      if (response.status === 200) {
        dispatch(
          alertData({
            type: "success",
            msg: "Brand updated successfully!",
          })
        );
        setTimeout(() => {
          setIsLoading(false);
          document?.querySelector("#edit-brand .close")?.click();
        }, 1000);
        fetchData();
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  }

  return (
    <>
      {/* Add Brand */}
      <div className="modal fade" id="edit-brand">
        <div className="modal-dialog modal-dialog-centered custom-modal-two">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>Edit Brand</h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body new-employee-field">
                  <form>
                    <div className="mb-3">
                      <label className="form-label">Brand</label>
                      <input
                        type="text"
                        value={brandName}
                        className="form-control"
                        onChange={(e) => setBrandName(e.target.value)}
                      />
                    </div>
                    {/* <label className="form-label">Logo</label>
                                        <div className="profile-pic-upload mb-3">
                                            <div className="profile-pic brand-pic">
                                                <span>
                                                    <PlusCircle className="plus-down-add"/>
                                                    Add Image
                                                </span>
                                            </div>
                                            <div className="image-upload mb-0">
                                                <input type="file" />
                                                <div className="image-uploads">
                                                    <h4>Change Image</h4>
                                                </div>
                                            </div>
                                        </div> */}
                    <div className="mb-0">
                      <div className="status-toggle modal-status d-flex justify-content-between align-items-center">
                        <span className="status-label">Status</span>
                        <input
                          value={brandStatus}
                          onChange={() => setBrandstatus(!brandStatus)}
                          type="checkbox"
                          id="user2"
                          className="check"
                        />
                        <label htmlFor="user2" className="checktoggle" />
                      </div>
                    </div>
                    <div className="modal-footer-btn">
                      <button
                        type="button"
                        className="btn btn-cancel me-2"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </button>
                      <div
                        className={`btn btn-submit ${
                          isLoading ? "disabled" : ""
                        }`}
                        onClick={submitData}
                      >
                        Update Brand
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Add Brand */}
    </>
  );
};

export default EditBrand;
