/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../core/breadcrumbs";
import { Link } from "react-router-dom";
import { Filter, Sliders } from "react-feather";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import Select from "react-select";
import { Edit, Eye, Globe, Trash2, User } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { Collapse, Modal, Table } from "antd";
import CustomerModal from "../../core/modals/peoples/customerModal";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import axios from "axios";
import { API_URL } from "../../constants/AppConstants";
import {
  alertData,
  customerData,
  detailData,
  loaderData,
  salesData,
} from "../../core/redux/action";
import { fetchCustomersData } from "../../utils/utilities";
import "./customers.css";

const Customers = () => {
  const login_data = useSelector((state) => state.login_data);
  const customer_data = useSelector((state) => state.customer_data);
  const selected_store = useSelector((state) => state.selected_store);
  const sales_data = useSelector((state) => state.sales_data);
  const [customerModal, setCustomerModal] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [saleList, setSaleList] = useState([]);
  const [ledgerUpdate, setLedgerUpdate] = useState("");
  const [comment, setComment] = useState("");
  const [accountList, setAccountList] = useState(
    saleList ? saleList : sales_data
  );
  const dispatch = useDispatch();

  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const toggleFilterVisibility = () => {
    setIsFilterVisible((prevVisibility) => !prevVisibility);
  };

  const options = [
    { value: "sortByDate", label: "Sort by Date" },
    // { value: "140923", label: "14 09 23" },
    // { value: "110923", label: "11 09 23" },
  ];
  const optionsTwo = [
    { label: "Choose Customer Name", value: "" },
    // { label: "Benjamin", value: "Benjamin" },
    // { label: "Ellen", value: "Ellen" },
    // { label: "Freda", value: "Freda" },
    // { label: "Kaitlin", value: "Kaitlin" },
  ];

  const countries = [
    { label: "Choose Country", value: "" },
    // { label: "India", value: "India" },
    // { label: "USA", value: "USA" },
  ];

  const columns = [
    {
      title: "Customer Name",
      dataIndex: "customerName",
      sorter: (a, b) => a.customerName.length - b.customerName.length,
    },
    {
      title: "Email",
      dataIndex: "customerEmail",
      // sorter: (a, b) => a.Email.length - b.Email.length,
    },

    {
      title: "Phone",
      dataIndex: "customerPhone",
      // sorter: (a, b) => a.Phone.length - b.Phone.length,
    },

    {
      title: "Country",
      dataIndex: "customerCountry",
      render: (data) => data.label,
      // sorter: (a, b) => a.Country.length - b.Country.length,
    },
    {
      title: "State",
      dataIndex: "customerState",
      render: (data) => data.label,
      // sorter: (a, b) => a.Country.length - b.Country.length,
    },
    {
      title: "City",
      dataIndex: "customerCity",
      render: (data) => data.label,
      // sorter: (a, b) => a.Country.length - b.Country.length,
    },

    {
      title: "Action",
      dataIndex: "_id",
      render: (data) => (
        <td className="action-table-data">
          <div className="edit-delete-action">
            <div className="input-block add-lists"></div>

            {/* <Link className="me-2 p-2" to="#">
              <Eye className="feather-view" />
            </Link> */}

            <Link
              className="me-2 p-2"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#edit-customer"
              onClick={(e) => {
                e.stopPropagation();
                dispatch(
                  detailData({
                    type: "Customer",
                    id: data,
                  })
                );
              }}
            >
              <Edit className="feather-edit" />
            </Link>

            <div
              className="confirm-text p-2 feather-trash-container"
              // onClick={showConfirmationAlert}
              onClick={(e) => {
                e.stopPropagation();
                deleteData(data);
              }}
            >
              <Trash2 className="feather-trash-2" />
            </div>
          </div>
        </td>
      ),
      // sorter: (a, b) => a.createdby.length - b.createdby.length,
    },
  ];

  const MySwal = withReactContent(Swal);

  useEffect(() => {
    fetchCustomersData(login_data, selected_store[0]);
  }, [selected_store]);

  const fetchAccounts = async () => {
    try {
      let response = await axios.get(
        API_URL + "customerledger/customer/" + selectedCustomer._id
      );
      if (response.status === 200) {
        const filtered = response?.data.filter(
          (item) => selectedCustomer._id === item.customerId
        );

        setAccountList(filtered);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAccounts();
  }, [selectedCustomer]);

  async function deleteData(dataId) {
    dispatch(
      loaderData({
        type: "Loader_Data",
        payload: true,
      })
    );
    if (dataId) {
      try {
        let response = await axios.put(API_URL + "customers/" + dataId);
        if (response.status === 200) {
          fetchCustomersData(login_data, selected_store[0]);
          dispatch(
            loaderData({
              type: "Loader_Data",
              payload: false,
            })
          );
        }
      } catch (err) {
        console.log(err);
      }
    }
  }

  const customerDetails = (data) => {
    if (data) {
      setCustomerModal(true);
      if (customer_data && sales_data) {
        const filteredData = sales_data.filter(
          (item) => item.customer.value === data._id
        );
        setSelectedCustomer(data);

        setSaleList(
          data.ledger.length > 0
            ? [...filteredData, ...data.ledger]
            : filteredData
        );
      }
    }
  };

  const handleModalClose = () => {
    setCustomerModal(false);
    setSelectedCustomer({});
    setLedgerUpdate("");
    setComment("");
  };

  const updatedCustomerLedger = async () => {
    const fetchAccounts = async () => {
      let res = await axios.get(API_URL + "accounts");
      return `CR-${new Date().getFullYear()}${res.data.length + 1}`;
    };

    const id = await fetchAccounts();

    const transaction = {
      transactionId: id,
      customerName: selectedCustomer.customerName,
      customerId: selectedCustomer._id,
      transactionDate: new Date(),
      amount: ledgerUpdate,
      type: "credit",
      description: `Patment Credited`,
      userCode: login_data.empCode,
      storeId: login_data.storeId,
      createdAt: new Date(),
      utr: "-",
    };

    let remainingAmount = parseFloat(ledgerUpdate);

    const updatedSales = saleList.map((sale) => {
      if (sale.amount > 0) {
        if (remainingAmount >= sale.amount) {
          remainingAmount -= sale.amount;
          transaction.clearBill.push({
            invoiceNumber: sale.invoiceNumber,
            amount: sale.amount,
            cleared: true,
          });
          return { ...sale, amount: 0 };
        } else {
          transaction.clearBill.push({
            invoiceNumber: sale.invoiceNumber,
            amount: remainingAmount,
            cleared: false,
          });
          const updatedAmount = sale.amount - remainingAmount;
          remainingAmount = 0;
          return { ...sale, amount: updatedAmount };
        }
      }
      return sale;
    });

    try {
      const res = await axios.post(`${API_URL}accounts`, transaction);
      setSaleList(updatedSales);
      if (res.status === 200) {
        const data = await res.data;
        setAccountList(data);
        dispatch(
          alertData({
            type: "success",
            msg: "Ledger Updated Sucessfully!",
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleLedgerUpdate = () => {
    updatedCustomerLedger();
    setComment("");
    setLedgerUpdate("");
  };

  let closingBalance = 0;

  const getReceiptNumber = (number) => {
    let ans = "";
    if (Array.isArray(number)) {
      for (let i = 0; i < number.length; i++) {
        ans += number[i].label;
        if (i < number.length - 1) {
          ans += ", "; // Add comma if it's not the last element
        }
      }
    } else {
      return number; // Return the number as is if it's not an array
    }
    return ans;
  };

  return (
    <div className="page-wrapper">
      <div className="content">
        <Breadcrumbs
          maintitle="Customer List "
          subtitle="Manage Your Customer"
          addButton="Add New Customer"
          modalId="add-customer"
        />
        {/* /product list */}
        <div className="card table-list-card">
          <div className="card-body">
            <div className="table-top">
              <div className="search-set">
                <div className="search-input">
                  <input
                    type="text"
                    placeholder="Search"
                    className="form-control form-control-sm formsearch"
                  />
                  <Link to className="btn btn-searchset">
                    <i data-feather="search" className="feather-search" />
                  </Link>
                </div>
              </div>
              {/* <div className="search-path">
                <Link
                  className={`btn btn-filter ${isFilterVisible ? "setclose" : ""
                    }`}
                  id="filter_search"
                >
                  <Filter
                    className="filter-icon"
                    onClick={toggleFilterVisibility}
                  />
                  <span onClick={toggleFilterVisibility}>
                    <ImageWithBasePath
                      src="assets/img/icons/closes.svg"
                      alt="img"
                    />
                  </span>
                </Link>
              </div> */}
              {/* <div className="form-sort stylewidth">
                <Sliders className="info-img" />
                <Select
                  className="select "
                  options={options}
                  placeholder="Sort by Date"
                />
              </div> */}
            </div>
            {/* /Filter */}
            <div
              className={`card${isFilterVisible ? " visible" : ""}`}
              id="filter_inputs"
              style={{ display: isFilterVisible ? "block" : "none" }}
            >
              <div className="card-body pb-0">
                <div className="row">
                  <div className="col-lg-3 col-sm-6 col-12">
                    <div className="input-blocks">
                      <User className="info-img" />
                      <Select
                        options={optionsTwo}
                        placeholder="Choose Customer Name"
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6 col-12">
                    <div className="input-blocks">
                      <Globe className="info-img" />
                      <Select
                        options={countries}
                        placeholder="Choose Country"
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6 col-12 ms-auto">
                    <div className="input-blocks">
                      <a className="btn btn-filters ms-auto">
                        {" "}
                        <i
                          data-feather="search"
                          className="feather-search"
                        />{" "}
                        Search{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /Filter */}
            <div className="table-responsive">
              <Table
                className="table datanew pointer-cursor"
                columns={columns}
                dataSource={customer_data}
                rowKey={(record) => record.id}
                onRow={(record) => ({
                  onClick: () => customerDetails(record),
                })}
              // pagination={true}
              />
            </div>
          </div>
        </div>
        {/* /product list */}
      </div>
      <Modal
        title="Customer Details"
        open={customerModal}
        onCancel={handleModalClose}
        footer={false}
        width={"90%"}
        style={{ maxWidth: "90%", top: 20 }}
      >
        {selectedCustomer && (
          <div className="container">
            <div className="row supplier-details">
              <div className="col-md-4">
                <table className="table">
                  <tbody>
                    <tr>
                      <td className="fw-bold" style={{ maxWidth: "120px" }}>
                        Name:
                      </td>
                      <td>{selectedCustomer.customerName}</td>
                    </tr>
                    <tr>
                      <td className="fw-bold" style={{ maxWidth: "120px" }}>
                        Address:
                      </td>
                      <td>{selectedCustomer.customerAddress}</td>
                    </tr>
                    <tr>
                      <td className="fw-bold" style={{ maxWidth: "120px" }}>
                        City:
                      </td>
                      <td>{selectedCustomer?.customerCity?.label}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="col-md-4">
                <table className="table">
                  <tbody>
                    <tr>
                      <td className="fw-bold" style={{ maxWidth: "120px" }}>
                        State:
                      </td>
                      <td>{selectedCustomer?.customerCity?.label}</td>
                    </tr>

                    <tr>
                      <td className="fw-bold" style={{ maxWidth: "120px" }}>
                        Zip:
                      </td>
                      <td>{selectedCustomer.customerPhone}</td>
                    </tr>
                    <tr>
                      <td className="fw-bold" style={{ maxWidth: "120px" }}>
                        Phone Number:
                      </td>
                      <td>{selectedCustomer?.customerPhone}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="col-md-4">
                <table className="table">
                  <tbody>
                    <tr>
                      <td className="fw-bold" style={{ maxWidth: "120px" }}>
                        Whatsapp Number:
                      </td>
                      <td>{selectedCustomer.customerPhone}</td>
                    </tr>
                    <tr>
                      <td className="fw-bold" style={{ maxWidth: "120px" }}>
                        Purchaser Manager:
                      </td>
                      <td>-</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            {/* <div
              className="row align-items-center mt-2"
              Delivery Details 
              Items list
              Transferred to Acc
              From Acc

              style={{ background: "lightgrey", padding: 20 }}
            > */}
            {/* <div className="col-md-4">
                <p>
                  Update Ledger{" "}
                  <span>
                    <input
                      type="text"
                      className="form-control"
                      value={ledgerUpdate}
                      onChange={(e) => setLedgerUpdate(e.target.value)}
                      placeholder="Enter amount"
                    />
                  </span>
                </p>
              </div> */}
            {/* <div className="col-md-4">
                <p>
                  Comment{" "}
                  <span>
                    <textarea
                      className="form-control"
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                      placeholder="Enter comment"
                      rows="1"
                    ></textarea>
                  </span>
                </p>
              </div> */}
            {/* <div className="col-md-4 mt-4">
                <button
                  className="btn btn-primary ml-0"
                  onClick={handleLedgerUpdate}
                >
                  Update
                </button>
              </div> */}
            {/* </div> */}
            <div className="br-20">
              <table className="table table-bordered ledger-table mt-3 br-20">
                <thead>
                  <tr>
                    <th colSpan={2}>INVOICE NO. #</th>
                    <th colSpan={5} style={{ width: 200 }}>DATE</th>
                    {/* <th>REF ORDER #</th>
                    <th>DESCRIPTION</th>
                    <th>UTR No.</th>
                    <th>STATUS</th>
                    <th>CREDIT</th>
                    <th>DEBIT</th> */}
                    <th colSpan={2}>CLOSING BALANCE</th>
                  </tr>
                </thead>
                <tbody style={{ border: '0px', color: 'white' }}>
                  {accountList.length > 0 ? (
                    accountList.map((transaction, index) => {
                      const formattedDate = new Date(
                        transaction.transactionDate || transaction.createdAt
                      ).toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      });
                      // const [prefix, refNumber] = transaction.description.split('#');
                      if (transaction.typeOfLedger === "credit") {
                        closingBalance += parseFloat(transaction.amount);
                      } else {
                        closingBalance -= parseFloat(transaction.amount);
                      }

                      return (


                        <tr key={index} style={{ border: 0 }}>
                          {/* <td colSpan={9} style={{ border: 0, padding: "10px 0px" }}> */}
                          <td colSpan={9} style={{ border: 0, padding: '15px 0 10px 0' }}>
                            <Collapse style={{ background: 'white', boxShadow: '#00000008 2px 2px 10px' }}
                              items={[
                                {
                                  key: '1',
                                  label: <>
                                    <table>
                                      <tbody>
                                        <td colSpan={2}
                                          className={`ref-number ${transaction.typeOfLedger === "credit"
                                            ? "credit"
                                            : "debit"
                                            }`}
                                        >
                                          {`#${transaction.transactionId}` || "-"}
                                        </td>
                                        <td colSpan={5} style={{ width: 200 }}>{formattedDate}</td>
                                        <td colSpan={2}>₹ {closingBalance.toFixed(2)}</td>
                                      </tbody>
                                    </table>
                                  </>,
                                  children:
                                    <>
                                      <div style={{ padding: '10px 0', display: `${transaction?.productsList?.length > 0 ? "block": "none"}` }}>
                                        <h5>Product List</h5>
                                        <table>
                                          <thead>
                                            <th>Name</th>
                                            <th>Brand</th>
                                            <th>Sub Category (Category)</th>
                                            <th>Qty</th>
                                            <th>Unit Price</th>
                                            <th>Price</th>
                                          </thead>
                                          <tbody>
                                            {
                                             transaction?.productsList?.map((product, index) => {
                                                return (
                                                  <tr key={index}>
                                                    <td>{product?.productName}</td>
                                                    <td>{product?.productBrand?.label} </td>
                                                    <td>{product?.productSubCategory?.label} ({product?.productCategory?.label}) </td>
                                                    <td>{product?.selectedQty} </td>
                                                    <td>{product?.productUnitCost}</td>
                                                    <td>{product?.purchasePrice} </td>
                                                  </tr>
                                                )
                                              }
                                              )
                                            }
                                          </tbody>
                                        </table>
                                      </div>

                                      <div style={{ padding: '10px 0', display: `${transaction?.productsList?.length > 0 ? "block": "none"}` }} className="deliveryDetails">
                                        <h5>Delivery Details</h5>
                                        <table>
                                          <tbody>
                                            <tr >
                                              <td > <span style={{fontWeight: 700}}> Transport Company: </span>   {transaction?.deliveryDetails?.transportCompany} </td>
                                              <td > <span style={{fontWeight: 700}}> Delivery By: </span>  {transaction?.deliveryDetails?.deliveryBy}</td>
                                              </tr >
                                            <tr >
                                             
                                              <td><span style={{fontWeight: 700}}> Driver Contact: </span>  {transaction?.deliveryDetails?.driverContact} </td>
                                              <td> <span style={{fontWeight: 700}}> Exp Delivery Date: </span>   {transaction?.deliveryDetails?.expectedDeliveryDate}</td>
                                           
                                              </tr >
                                              <tr >
                                           
                                              <td> <span style={{fontWeight: 700}}> Vehicle Number: </span>   {transaction?.deliveryDetails?.vehicleNumber}</td>
                                              <td> <span style={{fontWeight: 700}}> Vehicle Type: </span>   {transaction?.deliveryDetails?.vehicleType} </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>

                                      <div style={{ padding: '10px 0' }}>
                                        <h5>Account Details</h5>
                                        <table>
                                          <tbody>
                                            <tr>
                                            <td style={{textAlign: "center"}}>
                                              No account details
                                            </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>



                                      <div style={{ padding: '10px 0' }}>
                                        <h5>Ledger Details</h5>
                                      <table border={1}>
                                        <thead>
                                          <th>REF ORDER #</th>
                                          <th>INVOICE NO. #</th>
                                          <th>DATE</th>
                                          <th>DESCRIPTION</th>
                                          <th>UTR No.</th>
                                          <th>STATUS</th>
                                          <th>CREDIT</th>
                                          <th>DEBIT</th>
                                          <th>CLOSING BALANCE</th>
                                        </thead>
                                        <tbody>

                                          <td className="ref-number">{ }</td>
                                          <td
                                            className={`ref-number ${transaction.typeOfLedger === "credit"
                                              ? "credit"
                                              : "debit"
                                              }`}
                                          >

                                            {`#${transaction.transactionId}` || "-"}
                                          </td>
                                          <td>{formattedDate}</td>
                                          <td
                                            style={{
                                              textAlign: "left",
                                              maxWidth: "200px",
                                              textWrap: "balance",
                                            }}
                                          >
                                            {transaction.description || "-"}
                                          </td>
                                          <td>{transaction.utr || "-"}</td>
                                          <td>{transaction.status || "-"}</td>
                                          <td>
                                            {transaction.typeOfLedger === "credit"
                                              ? `₹ ${parseFloat(transaction.amount).toFixed(2)}`
                                              : "-"}
                                          </td>
                                          <td>
                                            {transaction.typeOfLedger === "debit"
                                              ? `₹ ${parseFloat(transaction.amount).toFixed(2)}`
                                              : "-"}
                                          </td>
                                          <td>₹ {closingBalance.toFixed(2)}</td>
                                        </tbody>
                                      </table>
                                      </div>
                                    
                                    </>
                                }
                              ]}
                            />
                          </td>
                          {/* </td> */}

                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td
                        colSpan={9}
                        style={{ textAlign: "center", fontStyle: "italic" }}
                      >
                        No Ledger Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="br-20">
              <table className="finalAmount">

                <tbody>
                  <tr style={{ width: '100%' }}>
                    <td style={{ fontWeight: 700, fontSize: '18px' }}>
                      Net Balance
                    </td>
                    <td style={{ fontWeight: 700, fontSize: '18px', color: `${closingBalance > 0 ? '#198754' : '#dc3545'}` }}>
                    ₹ {closingBalance.toFixed(2)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}
      </Modal>
      <CustomerModal />
    </div>
  );
};

export default Customers;
