/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../core/breadcrumbs";
import { ChevronUp, Filter, Sliders } from "react-feather";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import Select from "react-select";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Archive, Box, Calendar, User } from "react-feather";
// import ManageTransportModal from "../../core/modals/stocks/ManageTransportModal";
import { Edit, Trash2 } from "react-feather";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Table from "../../core/pagination/datatable";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { API_URL } from "../../constants/AppConstants";
import { setToogleHeader, stocksData } from "../../core/redux/action";
import {
  fetchStocksData,
  fetchTransportationData,
} from "../../utils/utilities";
import AddTransport from "../../core/modals/stocks/addTransport";
import { Modal } from "antd";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  PlusCircle,
  RotateCcw,
} from "feather-icons-react/build/IconComponents";

const ManageTransport = () => {
  const dispatch = useDispatch();
  const login_data = useSelector((state) => state.login_data);
  const data = useSelector((state) => state.toggle_header);
  const transportation_data = useSelector((state) => state.transportation_data);
  const selected_store = useSelector((state) => state.selected_store);

  const [filteredTransportationList, setFilteredTransportationList] = useState(
    []
  );

  const columns = [
    {
      title: "Reference Number",
      dataIndex: "referenceNumber",
    },
    {
      title: "Tansport Company",
      dataIndex: "transportCompany",
    },
    {
      title: "Vehicle Type",
      dataIndex: "vehicleType",
    },
    {
      title: "Vehicle Number",
      dataIndex: "vehicleNumber",
    },
    {
      title: "Expected Delivery Date",
      dataIndex: "expectedDeliveryDate",
    },
    {
      title: "Driver",
      dataIndex: "deliveryBy",
    },
    // {
    //   title: "Action",
    //   dataIndex: "action",
    //   render: () => (
    //     <td className="action-table-data">
    //       <div className="edit-delete-action">
    //         <div className="input-block add-lists"></div>

    //         <Link
    //           className="me-2 p-2"
    //           to="#"
    //           data-bs-toggle="modal"
    //           data-bs-target="#edit-units"
    //         >
    //           <Edit className="feather-edit" />
    //         </Link>

    //         {/* <Link
    //           className="confirm-text p-2"
    //           to="#"
    //           // onClick={showConfirmationAlert}
    //         >
    //           <Trash2 className="feather-trash-2" />
    //         </Link> */}
    //       </div>
    //     </td>
    //   ),
    //   // sorter: (a, b) => a.createdby.length - b.createdby.length,
    // },
  ];

  const renderRefreshTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Refresh
    </Tooltip>
  );
  const renderCollapseTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Collapse
    </Tooltip>
  );

  const MySwal = withReactContent(Swal);

  useEffect(() => {
    fetchTransportationData(login_data, selected_store[0]);
  }, [selected_store]);

  useEffect(() => {
    setFilteredTransportationList(transportation_data);
  }, [transportation_data]);

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="add-item d-flex">
            <div className="page-title">
              <h4>Transportation List</h4>
              <h6>Manage your transportations</h6>
            </div>
          </div>
          <ul className="table-top-head">
            {/* <li>
              <OverlayTrigger placement="top" overlay={renderTooltip}>
                <Link>
                  <ImageWithBasePath src="assets/img/icons/pdf.svg" alt="img" />
                </Link>
              </OverlayTrigger>
            </li>
            <li>
              <OverlayTrigger placement="top" overlay={renderExcelTooltip}>
                <Link data-bs-toggle="tooltip" data-bs-placement="top">
                  <ImageWithBasePath
                    src="assets/img/icons/excel.svg"
                    alt="img"
                  />
                </Link>
              </OverlayTrigger>
            </li>
            <li>
              <OverlayTrigger placement="top" overlay={renderPrinterTooltip}>
                <Link data-bs-toggle="tooltip" data-bs-placement="top">
                  <i data-feather="printer" className="feather-printer" />
                </Link>
              </OverlayTrigger>
            </li> */}
            <li>
              <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>
                <Link data-bs-toggle="tooltip" data-bs-placement="top">
                  <RotateCcw />
                </Link>
              </OverlayTrigger>
            </li>
            <li>
              <OverlayTrigger placement="top" overlay={renderCollapseTooltip}>
                <Link
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  id="collapse-header"
                  className={data ? "active" : ""}
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(setToogleHeader(!data));
                  }}
                >
                  <ChevronUp />
                </Link>
              </OverlayTrigger>
            </li>
          </ul>
          {/* <div className="page-btn">
            <Link onClick={toggleModalVisibility} className="btn btn-added">
              <PlusCircle className="me-2 iconsize" />
              Add New Transport
            </Link>
          </div> */}
          {/* <div className="page-btn import">
            <Link
              to="#"
              className="btn btn-added color"
              data-bs-toggle="modal"
              data-bs-target="#view-notes"
            >
              <Download className="me-2" />
              Import Product
            </Link>
          </div> */}
        </div>
        {/* /product list */}
        <div className="card table-list-card">
          <div className="card-body">
            <div className="table-top">
              <div className="search-set">
                <div className="search-input">
                  <input
                    type="text"
                    placeholder="Search"
                    className="form-control form-control-sm formsearch"
                  />
                  <Link to className="btn btn-searchset">
                    <i data-feather="search" className="feather-search" />
                  </Link>
                </div>
              </div>
              {/* <div className="search-path">
                <Link
                  className={`btn btn-filter ${
                    isFilterVisible ? "setclose" : ""
                  }`}
                  id="filter_search"
                >
                  <Filter
                    className="filter-icon"
                    onClick={toggleFilterVisibility}
                  />
                  <span onClick={toggleFilterVisibility}>
                    <ImageWithBasePath
                      src="assets/img/icons/closes.svg"
                      alt="img"
                    />
                  </span>
                </Link>
              </div> */}
              {/* <div className="form-sort stylewidth">
                <Sliders className="info-img" />
                <Select
                  className="select "
                  options={options}
                  placeholder="Sort by Date"
                />
              </div> */}
            </div>
            {/* /Filter */}
            {/* <div
              className={`card${isFilterVisible ? " visible" : ""}`}
              id="filter_inputs"
              style={{ display: isFilterVisible ? "block" : "none" }}
            >
              <div className="card-body pb-0">
                <div className="row">
                  <div className="col-lg-2 col-sm-6 col-12">
                    <div className="input-blocks">
                      <Archive className="info-img" />
                      <Select
                        className="select"
                        options={warehouseOptions}
                        placeholder="Choose Warehouse"
                      />
                    </div>
                  </div>
                  <div className="col-lg-2 col-sm-6 col-12">
                    <div className="input-blocks">
                      <Box className="info-img" />
                      <Select
                        className="select"
                        options={productOptions}
                        placeholder="Choose Product"
                      />
                    </div>
                  </div>
                  <div className="col-lg-2 col-sm-6 col-12">
                    <div className="input-blocks">
                      <Calendar className="info-img" />
                      <div className="input-groupicon">
                        <DatePicker
                          selected={selectedDate}
                          onChange={handleDateChange}
                          dateFormat="dd/MM/yyyy"
                          placeholderText="Choose Date"
                          className="datetimepicker"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-2 col-sm-6 col-12">
                    <div className="input-blocks">
                      <User className="info-img" />
                      <Select
                        className="select"
                        options={personOptions}
                        placeholder="Choose Person"
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-sm-6 col-12 ms-auto">
                    <div className="input-blocks">
                      <a className="btn btn-filters ms-auto">
                        {" "}
                        <i
                          data-feather="search"
                          className="feather-search"
                        />{" "}
                        Search{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            {/* /Filter */}
            <div className="table-responsive">
              <Table
                className="table datanew"
                columns={columns}
                dataSource={filteredTransportationList}
                rowKey={(record) => record._id}
                // pagination={true}
              />
            </div>
          </div>
        </div>
        {/* /product list */}
      </div>
    </div>
  );
};

export default ManageTransport;
