/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { Link, useNavigate } from "react-router-dom";
import { all_routes } from "../../../Router/all_routes";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { Alert, Space } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { loginUserData } from "../../../core/redux/action";
import axios from "axios";
import { API_URL } from "../../../constants/AppConstants";

const SigninThree = () => {
  const route = all_routes;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [togglePassword, setTogglePassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [emptyError, setShowEmptyError] = useState(false);
  const [loginError, setShowLoginError] = useState(false);
  const login_data = useSelector((state) => state.login_data);

  useEffect(() => {
    if (
      login_data != null &&
      login_data != undefined &&
      Object.keys(login_data).length
    ) {
      navigate("/dashboard");
      setIsPageLoading(false);
    } else {
      setIsPageLoading(false);
    }
  }, []);

  async function handleLogin() {
    setIsLoading(true);

    if (!username || !password) {
      setShowEmptyError(true);
      setIsLoading(false);
      setTimeout(() => {
        setShowEmptyError(false);
      }, 5000);
      return;
    } else {
      try {
        let response = await axios.post(API_URL + "users/login", {
          empCode: username,
          password,
        });
        if (response.status === 200) {
          setIsLoading(false);
          console.log(response.data);
          dispatch(loginUserData(response.data));
          navigate("/");
        } else {
          setShowLoginError(true);
          setIsLoading(false);
          setTimeout(() => {
            setShowLoginError(false);
          }, 5000);
        }
      } catch (err) {
        setShowLoginError(true);
        setIsLoading(false);
        setTimeout(() => {
          setShowLoginError(false);
        }, 5000);
        console.log(err);
      }
    }
  }

  return !isPageLoading ? (
    <div className="main-wrapper">
      <div className="account-content">
        <div className="login-wrapper login-new">
          <div className="container">
            <div className="login-content user-login">
              <div className="login-logo">
                {/* <ImageWithBasePath src="assets/img/logo.png" alt="img" />
                <Link to={route.dashboard} className="login-logo logo-white">
                  <ImageWithBasePath src="assets/img/logo-white.png" alt />
                </Link> */}
              </div>
              <form>
                <div className="login-userset">
                  <div className="login-userheading">
                    <h3>Sign In</h3>
                    <h4>
                      Access the ProEdge panel using your email/username and
                      passcode.
                    </h4>
                  </div>
                  <div className="form-login">
                    <label className="form-label">Username/Email</label>
                    <div className="form-addons">
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => setUsername(e.target.value)}
                      />
                      <ImageWithBasePath
                        src="assets/img/icons/mail.svg"
                        alt="img"
                      />
                    </div>
                  </div>
                  <div className="form-login">
                    <label>Password</label>
                    <div className="pass-group">
                      <input
                        type={`${togglePassword ? "text" : "password"}`}
                        className="pass-input"
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      {togglePassword ? (
                        <span
                          onClick={() => setTogglePassword(!togglePassword)}
                          className="fas toggle-password fa-eye"
                        />
                      ) : (
                        <span
                          onClick={() => setTogglePassword(!togglePassword)}
                          className="fas toggle-password fa-eye-slash"
                        />
                      )}
                    </div>
                  </div>
                  {/* <div className="form-login authentication-check">
                    <div className="row">
                      <div className="col-6">
                        <div className="custom-control custom-checkbox">
                          <label className="checkboxs ps-4 mb-0 pb-0 line-height-1">
                            <input type="checkbox" />
                            <span className="checkmarks" />
                            Remember me
                          </label>
                        </div>
                      </div>
                      <div className="col-6 text-end">
                        <Link
                          className="forgot-link"
                          to={route.forgotPasswordThree}
                        >
                          Forgot Password?
                        </Link>
                      </div>
                    </div>
                  </div> */}
                  <div className="form-login">
                    <div
                      className={`btn btn-login ${isLoading ? "disabled" : ""}`}
                      onClick={handleLogin}
                    >
                      Sign In
                      {isLoading ? (
                        <Spin
                          indicator={
                            <LoadingOutlined
                              style={{ fontSize: 24, marginLeft: 10 }}
                              spin
                            />
                          }
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  {/* <div className="signinform">
                    <h4>
                      New on our platform?
                      <Link to={route.registerThree} className="hover-a">
                        {" "}
                        Create an account
                      </Link>
                    </h4>
                  </div>
                  <div className="form-setlogin or-text">
                    <h4>OR</h4>
                  </div>
                  <div className="form-sociallink">
                    <ul className="d-flex">
                      <li>
                        <Link to="#" className="facebook-logo">
                          <ImageWithBasePath
                            src="assets/img/icons/facebook-logo.svg"
                            alt="Facebook"
                          />
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <ImageWithBasePath
                            src="assets/img/icons/google.png"
                            alt="Google"
                          />
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="apple-logo">
                          <ImageWithBasePath
                            src="assets/img/icons/apple-logo.svg"
                            alt="Apple"
                          />
                        </Link>
                      </li>
                    </ul>
                  </div> */}
                </div>
              </form>
            </div>
            <div className="my-4 d-flex justify-content-center align-items-center copyright-text">
              <p>Copyright © 2024 ProEdge. All rights reserved</p>
            </div>
          </div>
        </div>
      </div>
      {emptyError ? (
        <Alert
          className="error-alert"
          message="Please fill all the details"
          type="error"
          showIcon
        />
      ) : (
        ""
      )}
      {loginError ? (
        <Alert
          className="error-alert"
          message="Wrong username/password entered"
          type="error"
          showIcon
        />
      ) : (
        ""
      )}
    </div>
  ) : (
    <div id="global-loader">
      <div className="whirly-loader"></div>
    </div>
  );
};

export default SigninThree;
