/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import { API_URL } from "../../../constants/AppConstants";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { alertData, categoryData } from "../../../core/redux/action";
import { fetchCategoryData } from "../../../utils/utilities";

const EditCategoryList = (props) => {
  const login_data = useSelector((state) => state.login_data);
  const selected_store = useSelector((state) => state.selected_store);
  const detail_data = useSelector((state) => state.detail_data);
  const category_data = useSelector((state) => state.category_data);
  const [categoryName, setCategoryName] = useState("");
  const [categoryStatus, setCategorystatus] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    props.setIsCategoryUpdated(false);
    if (detail_data?.id && category_data) {
      category_data?.forEach((category) => {
        if (category._id == detail_data.id) {
          setSelectedData(category);
        }
      });
    }
  }, [detail_data]);

  useEffect(() => {
    if (selectedData) {
      setCategoryName(selectedData?.categoryName);
      setCategorystatus(selectedData?.categoryStatus);
    }
  }, [selectedData]);

  async function submitData() {
    setIsLoading(true);
    let dataBody = {
      categoryName,
      categoryStatus,
      storeId: selected_store[0]?._id,
      createdOn: selectedData?.createdOn,
      updatedOn: new Date().toLocaleDateString().split(",")[0],
      userCode: login_data?.empCode,
    };

    try {
      let response = await axios.put(
        API_URL + "category/" + selectedData?._id,
        dataBody
      );
      if (response.status === 200) {
        fetchCategoryData(login_data, selected_store[0]);
        dispatch(
          alertData({
            type: "success",
            msg: "Category updated successfully!",
          })
        );
        setTimeout(() => {
          setIsLoading(false);
          document?.querySelector("#edit-category .close")?.click();
          props.setIsCategoryUpdated(true);
        }, 1000);
      }
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <div>
      {/* Add Category */}
      <div className="modal fade" id="edit-category">
        <div className="modal-dialog modal-dialog-centered custom-modal-two">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>Edit Category</h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    data-bs-toggle={props.from ? "modal" : ""}
                    data-bs-target={props.from ? `${props.target}` : ""}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <form>
                    <div className="mb-3">
                      <label className="form-label">Category</label>
                      <input
                        type="text"
                        className="form-control"
                        value={categoryName}
                        onChange={(e) => setCategoryName(e.target.value)}
                      />
                    </div>
                    <div className="mb-0">
                      <div className="status-toggle modal-status d-flex justify-content-between align-items-center">
                        <span className="status-label">Status</span>
                        <input
                          type="checkbox"
                          id="user2"
                          className="check"
                          value={categoryStatus}
                          onChange={(e) => {
                            setCategorystatus(!categoryStatus);
                          }}
                        />
                        <label htmlFor="user2" className="checktoggle" />
                      </div>
                    </div>
                    <div className="modal-footer-btn">
                      <button
                        type="button"
                        className="btn btn-cancel me-2"
                        data-bs-dismiss="modal"
                        data-bs-toggle={props.from ? "modal" : ""}
                        data-bs-target={props.from ? `${props.target}` : ""}
                      >
                        Cancel
                      </button>
                      <div
                        className={`btn btn-submit ${
                          isLoading ? "disabled" : ""
                        }`}
                        onClick={submitData}
                      >
                        Update Category
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Add Category */}
    </div>
  );
};

export default EditCategoryList;
