/* eslint-disable no-unused-vars */
import {
  Box,
  ChevronUp,
  Edit,
  Eye,
  Filter,
  GitMerge,
  PlusCircle,
  RotateCcw,
  Sliders,
  StopCircle,
  Trash2,
} from "feather-icons-react/build/IconComponents";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Select from "react-select";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import Brand from "../../core/modals/inventory/brand";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { all_routes } from "../../Router/all_routes";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Table from "../../core/pagination/datatable";
import {
  detailData,
  loaderData,
  setToogleHeader,
  stocksData,
} from "../../core/redux/action";
import { Download } from "react-feather";
import axios from "axios";
import { API_URL } from "../../constants/AppConstants";
import { productsData, updatePage } from "../../core/redux/action";
import { Alert } from "antd";
import FilterModal from "../../core/modals/FilterModal";
import moment from "moment";
import { fetchProductsData } from "../../utils/utilities";

const ProductList = () => {
  const data = useSelector((state) => state.toggle_header);
  const products_data = useSelector((state) => state.products_data);
  const login_data = useSelector((state) => state.login_data);
  const selected_store = useSelector((state) => state.selected_store);
  const dispatch = useDispatch();
  const stocks_data = useSelector((state) => state.stocks_data);

  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [deleteErrModal, setDeleteErrModal] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [filterOptions, setFilterOptions] = useState({
    categories: [],
    brands: [],
    minDate: new Date(),
    maxDate: new Date(),
  });
  const [filters, setFilters] = useState({});
  const [searchTerm, setSearchTerm] = useState("");

  const [categoryFilterArr, setCategoryFilterArr] = useState([]);
  const [subCategoryFilterArr, setSubCategoryFilterArr] = useState([]);
  const [brandListFilterArr, setBrandListFilterArr] = useState([]);
  const [selectedCategoryFilterData, setSelectedCategoryFilterData] =
    useState("");
  const [selectedSubCategoryFilterData, setSelectedSubCategoryFilterData] =
    useState("");
  const [selectedBrandFilterData, setSelectedBrandFilterData] = useState("");
  const [selectedDateFilterData, setSelectedDateFilterData] = useState("");

  const toggleFilterVisibility = () => {
    setIsFilterVisible((prevVisibility) => !prevVisibility);
  };
  const route = all_routes;

  const [filteredProducts, setFilteredProducts] = useState(products_data);

  const filtersConfig = [
    {
      key: "category",
      label: "Category",
      type: "select",
      options: filterOptions.categories,
      placeholder: "Select a category",
    },
    {
      key: "brand",
      label: "Brand",
      type: "select",
      options: filterOptions.brands,
      placeholder: "Select a brand",
    },
    // {
    //   key: 'createdOn',
    //   label: 'Created On',
    //   type: 'dateRange',
    //   placeholder: 'Select date range'
    // }
  ];

  useEffect(() => {
    setFilteredProducts(products_data);
  }, [products_data]);

  useEffect(() => {
    if (products_data && products_data?.length) {
      let categoryFilterArrCopy = [];
      let subCategoryFilterArrCopy = [];
      let brandFilterArrCopy = [];

      products_data.forEach((item) => {
        if (item.productCategory.value) {
          categoryFilterArrCopy.push(item.productCategory.label);
        }
        if (item.productSubCategory.value) {
          subCategoryFilterArrCopy.push(item.productSubCategory.label);
        }
        if (item.productBrand.value) {
          brandFilterArrCopy.push(item.productBrand.label);
        }
      });

      let uniqCategoryFilterArrCopy = [...new Set(categoryFilterArrCopy)];
      let uniqSubCategoryFilterArrCopy = [...new Set(subCategoryFilterArrCopy)];
      let uniqBrandFilterArrCopy = [...new Set(brandFilterArrCopy)];

      setCategoryFilterArr(uniqCategoryFilterArrCopy);
      setSubCategoryFilterArr(uniqSubCategoryFilterArrCopy);
      setBrandListFilterArr(uniqBrandFilterArrCopy);
    }
  }, [products_data]);

  const getFilterOptions = (products) => {
    const categoryOptions = [];
    const brandOptions = [];
    let minDate = moment(); // Initialize to the current date
    let maxDate = moment(0); // Initialize to the Unix epoch (this is a very old date)

    products.forEach((product) => {
      // Categories
      if (
        product.productCategory &&
        !categoryOptions.find(
          (option) => option.value === product.productCategory.label
        )
      ) {
        categoryOptions.push({
          label: product.productCategory.label,
          value: product.productCategory.label,
        });
      }
      // Brands
      if (
        product.productBrand &&
        !brandOptions.find(
          (option) => option.value === product.productBrand.label
        )
      ) {
        brandOptions.push({
          label: product.productBrand.label,
          value: product.productBrand.label,
        });
      }
      // Date Range for Created On
      if (product.createdOn) {
        const createdOnDate = moment(product.createdOn, "MM/DD/YYYY"); // Assuming the format "MM/DD/YYYY"
        if (createdOnDate.isValid()) {
          // Check if the date is valid
          if (createdOnDate.isBefore(minDate)) {
            minDate = createdOnDate;
          }
          if (createdOnDate.isAfter(maxDate)) {
            maxDate = createdOnDate;
          }
        }
      }
    });

    // Check if minDate and maxDate are still at their initial values and adjust if necessary
    if (minDate.isSame(moment())) {
      minDate = null;
    }
    if (maxDate.isSame(moment(0))) {
      maxDate = null;
    }

    return { categoryOptions, brandOptions, minDate, maxDate };
  };

  useEffect(() => {
    const { categoryOptions, brandOptions, minDate, maxDate } =
      getFilterOptions(products_data);

    // Assuming setFilterOptions can handle more data or you might need to adjust state structure
    setFilterOptions({
      categories: categoryOptions,
      brands: brandOptions,
      minDate: minDate,
      maxDate: maxDate,
    });
  }, [products_data]);

  useEffect(() => {
    if (!searchTerm) {
      if (
        selectedCategoryFilterData?.value ||
        selectedSubCategoryFilterData?.value ||
        selectedBrandFilterData?.value
      ) {
        const filteredData = products_data.filter((product) => {
          const matchesCategory = selectedCategoryFilterData?.value
            ? product.productCategory.label === selectedCategoryFilterData.label
            : true;
          const matchesSubCategory = selectedSubCategoryFilterData?.value
            ? product.productSubCategory.label ===
              selectedSubCategoryFilterData.label
            : true;
          const matchesBrand = selectedBrandFilterData?.value
            ? product.productBrand.label === selectedBrandFilterData?.label
            : true;

          return matchesCategory && matchesSubCategory && matchesBrand;
        });

        setFilteredProducts(filteredData);
      } else {
        setFilteredProducts(products_data);
      }
    }
  }, [
    selectedCategoryFilterData,
    selectedSubCategoryFilterData,
    selectedBrandFilterData,
    selectedDateFilterData,
  ]);

  function parseDate(dateStr) {
    const [month, day, year] = dateStr.split("/");
    return new Date(year, month - 1, day); // month is 0-based in JS
  }

  const handleApplyFilters = (newFilters) => {
    const filteredData = products_data.filter((product) => {
      const matchesCategory = newFilters.category
        ? product.productCategory.label === newFilters.category
        : true;
      const matchesBrand = newFilters.brand
        ? product.productBrand.label === newFilters.brand
        : true;

      let matchesDate = true;
      if (newFilters.createdOn && newFilters.createdOn.length === 2) {
        const productDate = parseDate(product.createdOn);
        const startDate = parseDate(newFilters.createdOn[0]);
        const endDate = parseDate(newFilters.createdOn[1]);

        // Ensure the product date is within the start and end dates inclusive
        matchesDate = productDate >= startDate && productDate <= endDate;
      }

      return matchesCategory && matchesBrand && matchesDate;
    });

    setFilteredProducts(filteredData);
  };

  const options = [
    { value: "sortByDate", label: "Sort by Date" },
    // { value: "140923", label: "14 09 23" },
    // { value: "110923", label: "11 09 23" },
  ];
  const productlist = [{ value: "choose", label: "Choose Product" }];
  const categorylist = [{ value: "choose", label: "Choose Category" }];
  const subcategorylist = [{ value: "choose", label: "Choose Sub Category" }];
  const brandlist = [{ value: "all", label: "All Brand" }];
  const price = [{ value: "price", label: "Price" }];

  const columns = [
    {
      title: "Product",
      dataIndex: "productName",
      key: "productName",
      // sorter: (a, b) => a.productName.localeCompare(b.productName),
    },
    {
      title: "Item Code",
      dataIndex: "productItemCode",
    },
    {
      title: "SKU",
      dataIndex: "productSKU",
    },
    {
      title: "Category",
      dataIndex: "productCategory",
      render: (item) => item?.label,
      key: "productCategory",
      // sorter: (a, b) =>
      //   a.productCategory.label.localeCompare(b.productCategory.label),
    },
    {
      title: "Sub Category",
      dataIndex: "productSubCategory",
      render: (item) => (item?.value ? item?.label : ""),
      key: "productSubCategory",
      // sorter: (a, b) =>
      //   a.productSubCategory.label.localeCompare(b.productSubCategory.label),
    },
    {
      title: "Brand",
      dataIndex: "productBrand",
      render: (item) => item?.label,
    },
    {
      title: "Main Unit",
      dataIndex: "productUnit",
      render: (item) => item?.label,
    },
    {
      title: "Alternate Unit",
      dataIndex: "productClubbedUnit",
      render: (item) => item?.label,
    },
    {
      title: "Alternate Qty",
      dataIndex: "productAlternateQty",
    },
    {
      title: "Action",
      dataIndex: "_id",
      render: (data) => (
        <td className="action-table-data">
          <div className="edit-delete-action">
            <div className="input-block add-lists"></div>
            <Link
              className="me-2 p-2"
              to={route.productdetails}
              onClick={() => {
                dispatch(
                  detailData({
                    type: "Product",
                    id: data,
                  })
                );
              }}
            >
              <Eye className="feather-view" />
            </Link>
            <Link
              className="me-2 p-2"
              to={route.editproduct}
              onClick={() => {
                dispatch(
                  detailData({
                    type: "Product",
                    id: data,
                  })
                );
              }}
            >
              <Edit className="feather-edit" />
            </Link>
            <div
              className="confirm-text p-2 feather-trash-container"
              // onClick={showConfirmationAlert}
              onClick={() => deleteData(data)}
            >
              <Trash2 className="feather-trash-2" />
            </div>
          </div>
        </td>
      ),
    },
  ];
  const MySwal = withReactContent(Swal);

  useEffect(() => {
    fetchProductsData(login_data, selected_store[0]);
  }, [selected_store]);

  async function deleteFn(dataId) {
    dispatch(
      loaderData({
        type: "Loader_Data",
        payload: true,
      })
    );
    try {
      let response = await axios.put(API_URL + "products/delete/" + dataId);
      if (response.status === 200) {
        fetchProductsData(login_data, selected_store[0]);
        const filterproduct = products_data.filter(
          (item) => item._id === dataId
        );
        dispatch(
          loaderData({
            type: "Loader_Data",
            payload: false,
          })
        );

        const resstock = await axios.put(
          API_URL + "stocks/delete/" + filterproduct[0].productItemCode
        );
      }
    } catch (err) {
      console.log(err);
    }
  }

  function deleteData(dataId) {
    if (dataId) {
      let isStockPresent = 0;
      stocks_data?.forEach((stock) => {
        if (dataId == stock.productId) {
          isStockPresent = true;
          if (stock.productQty > 0) {
            setDeleteErrModal(true);
            setTimeout(() => {
              setDeleteErrModal(false);
            }, 5000);
          } else {
            deleteFn(dataId);
          }
        }
      });
      if (!isStockPresent) {
        deleteFn(dataId);
      }
    }
  }

  const showConfirmationAlert = () => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes, delete it!",
      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        MySwal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          className: "btn btn-success",
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      } else {
        MySwal.close();
      }
    });
  };

  const renderTooltip = (props) => (
    <Tooltip id="pdf-tooltip" {...props}>
      Pdf
    </Tooltip>
  );
  const renderExcelTooltip = (props) => (
    <Tooltip id="excel-tooltip" {...props}>
      Excel
    </Tooltip>
  );
  const renderPrinterTooltip = (props) => (
    <Tooltip id="printer-tooltip" {...props}>
      Printer
    </Tooltip>
  );
  const renderRefreshTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Refresh
    </Tooltip>
  );
  const renderCollapseTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Collapse
    </Tooltip>
  );

  useEffect(() => {
    const filteredData = products_data.filter((product) => {
      const matchesCategory = filters.category
        ? product.productCategory.label === filters.category
        : true;
      const matchesBrand = filters.brand
        ? product.productBrand.label === filters.brand
        : true;
      const matchesSearch =
        product.productName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        product.productItemCode
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        (product.productSKU &&
          product.productSKU.toLowerCase().includes(searchTerm.toLowerCase()));

      return matchesCategory && matchesBrand && matchesSearch;
    });

    setFilteredProducts(filteredData);
  }, [searchTerm]);

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="add-item d-flex">
            <div className="page-title">
              <h4>Product List</h4>
              <h6>Manage your products</h6>
            </div>
          </div>
          <ul className="table-top-head">
            {/* <li>
              <OverlayTrigger placement="top" overlay={renderTooltip}>
                <Link>
                  <ImageWithBasePath src="assets/img/icons/pdf.svg" alt="img" />
                </Link>
              </OverlayTrigger>
            </li>
            <li>
              <OverlayTrigger placement="top" overlay={renderExcelTooltip}>
                <Link data-bs-toggle="tooltip" data-bs-placement="top">
                  <ImageWithBasePath
                    src="assets/img/icons/excel.svg"
                    alt="img"
                  />
                </Link>
              </OverlayTrigger>
            </li>
            <li>
              <OverlayTrigger placement="top" overlay={renderPrinterTooltip}>
                <Link data-bs-toggle="tooltip" data-bs-placement="top">
                  <i data-feather="printer" className="feather-printer" />
                </Link>
              </OverlayTrigger>
            </li> */}
            <li>
              <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>
                <Link data-bs-toggle="tooltip" data-bs-placement="top">
                  <RotateCcw />
                </Link>
              </OverlayTrigger>
            </li>
            <li>
              <OverlayTrigger placement="top" overlay={renderCollapseTooltip}>
                <Link
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  id="collapse-header"
                  className={data ? "active" : ""}
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(setToogleHeader(!data));
                  }}
                >
                  <ChevronUp />
                </Link>
              </OverlayTrigger>
            </li>
          </ul>
          <div className="page-btn">
            <Link to={route.addproduct} className="btn btn-added">
              <PlusCircle className="me-2 iconsize" />
              Add New Product
            </Link>
          </div>
          {/* <div className="page-btn import">
            <Link
              to="#"
              className="btn btn-added color"
              data-bs-toggle="modal"
              data-bs-target="#view-notes"
            >
              <Download className="me-2" />
              Import Product
            </Link>
          </div> */}
        </div>
        {/* /product list */}
        <div className="card table-list-card">
          <div className="card-body">
            <div className="table-top">
              <div className="search-set">
                <div className="search-input">
                  <input
                    type="text"
                    placeholder="Search by product, item code or sku"
                    className="form-control form-control-sm formsearch"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />

                  <Link to className="btn btn-searchset">
                    <i data-feather="search" className="feather-search" />
                  </Link>
                </div>
              </div>
              <Select
                value={
                  selectedCategoryFilterData.value
                    ? selectedCategoryFilterData
                    : [
                        {
                          label: "Category",
                          value: null,
                        },
                      ]
                }
                className="select mr-10"
                options={categoryFilterArr?.map((item, index) => {
                  return { label: item, value: index + 1 };
                })}
                placeholder="Category"
                onChange={(value) => setSelectedCategoryFilterData(value)}
              />
              <Select
                value={
                  selectedSubCategoryFilterData.value
                    ? selectedSubCategoryFilterData
                    : [
                        {
                          label: "Sub Category",
                          value: null,
                        },
                      ]
                }
                className="select mr-10"
                options={subCategoryFilterArr?.map((item, index) => {
                  return { label: item, value: index + 1 };
                })}
                placeholder="Sub Category"
                onChange={(value) => setSelectedSubCategoryFilterData(value)}
              />
              <Select
                value={
                  selectedBrandFilterData.value
                    ? selectedBrandFilterData
                    : [
                        {
                          label: "Brand",
                          value: null,
                        },
                      ]
                }
                className="select mr-10"
                options={brandListFilterArr?.map((item, index) => {
                  return { label: item, value: index + 1 };
                })}
                placeholder="Brand"
                onChange={(value) => setSelectedBrandFilterData(value)}
              />
              {/* <Select
                value={
                  selectedDateFilterData.value
                    ? selectedDateFilterData
                    : [
                        {
                          label: "Date",
                          value: null,
                        },
                      ]
                }
                className="select mr-10"
                options={[
                  {
                    label: "Latest",
                    value: "Latest",
                  },
                  {
                    label: "Oldest",
                    value: "Oldest",
                  },
                ]}
                placeholder="Date"
                onChange={(value) => setSelectedDateFilterData(value)}
              /> */}
              <button
                className="btn btn-danger"
                onClick={() => {
                  setSelectedCategoryFilterData("");
                  setSelectedSubCategoryFilterData("");
                  setSelectedBrandFilterData("");
                  setSelectedDateFilterData("");
                }}
              >
                Clear
              </button>
            </div>
            <div
              className={`card${isFilterVisible ? " visible" : ""}`}
              id="filter_inputs"
              style={{ display: isFilterVisible ? "block" : "none" }}
            >
              <div className="card-body pb-0">
                <div className="row">
                  <div className="col-lg-12 col-sm-12">
                    <div className="row">
                      <div className="col-lg-2 col-sm-6 col-12">
                        <div className="input-blocks">
                          <Box className="info-img" />
                          <Select
                            className="select"
                            options={productlist}
                            placeholder="Choose Product"
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 col-sm-6 col-12">
                        <div className="input-blocks">
                          <StopCircle className="info-img" />
                          <Select
                            className="select"
                            options={categorylist}
                            placeholder="Choose Category"
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 col-sm-6 col-12">
                        <div className="input-blocks">
                          <GitMerge className="info-img" />
                          <Select
                            className="select"
                            options={subcategorylist}
                            placeholder="Choose Sub Category"
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 col-sm-6 col-12">
                        <div className="input-blocks">
                          <StopCircle className="info-img" />
                          <Select
                            className="select"
                            options={brandlist}
                            placeholder="Nike"
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 col-sm-6 col-12">
                        <div className="input-blocks">
                          <i className="fas fa-money-bill info-img" />

                          <Select
                            className="select"
                            options={price}
                            placeholder="Price"
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 col-sm-6 col-12">
                        <div className="input-blocks">
                          <Link className="btn btn-filters ms-auto">
                            {" "}
                            <i
                              data-feather="search"
                              className="feather-search"
                            />{" "}
                            Search{" "}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /Filter */}
            <div className="table-responsive">
              <Table columns={columns} dataSource={filteredProducts} />
            </div>
          </div>
        </div>
        <FilterModal
          visible={isModalVisible}
          onClose={() => setIsModalVisible(false)}
          filtersConfig={filtersConfig}
          onApplyFilters={handleApplyFilters}
          initialValues={filters}
        />
        {/* /product list */}
        {deleteErrModal ? (
          <Alert
            message="Delete Error"
            description="Product stock is not empty."
            type="error"
            showIcon
          />
        ) : (
          <></>
        )}
        <Brand />
      </div>
    </div>
  );
};

export default ProductList;
