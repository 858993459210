// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.supplier-details td {
  background-color: transparent !important; /* Override any existing background color */
  color: black !important;
}

.supplier-details th {
  color: black !important;
}

.supplier-details {
  background: #e9ecef45;
  border-radius: 5px;
  margin: 0px;
  border: 1px solid #dee2e6;
}

.ant-modal-content {
  padding: 0px !important;
}

.ant-modal-header,
.ant-modal-footer {
  padding: 10px 20px !important;
  background: #ff9f43 !important;
}

.ant-modal-header {
  margin-bottom: 20px !important;
}

.ant-modal-footer {
  margin-top: 20px !important;
}

.ant-modal-close-x {
  color: black;
}

.ant-modal-header .ant-modal-title {
  font-weight: 700;
  color: #fff;
}
`, "",{"version":3,"sources":["webpack://./src/feature-module/people/suppliers.css"],"names":[],"mappings":"AAAA;EACE,wCAAwC,EAAE,2CAA2C;EACrF,uBAAuB;AACzB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,WAAW;EACX,yBAAyB;AAC3B;;AAEA;EACE,uBAAuB;AACzB;;AAEA;;EAEE,6BAA6B;EAC7B,8BAA8B;AAChC;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,WAAW;AACb","sourcesContent":[".supplier-details td {\n  background-color: transparent !important; /* Override any existing background color */\n  color: black !important;\n}\n\n.supplier-details th {\n  color: black !important;\n}\n\n.supplier-details {\n  background: #e9ecef45;\n  border-radius: 5px;\n  margin: 0px;\n  border: 1px solid #dee2e6;\n}\n\n.ant-modal-content {\n  padding: 0px !important;\n}\n\n.ant-modal-header,\n.ant-modal-footer {\n  padding: 10px 20px !important;\n  background: #ff9f43 !important;\n}\n\n.ant-modal-header {\n  margin-bottom: 20px !important;\n}\n\n.ant-modal-footer {\n  margin-top: 20px !important;\n}\n\n.ant-modal-close-x {\n  color: black;\n}\n\n.ant-modal-header .ant-modal-title {\n  font-weight: 700;\n  color: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
