/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import {
  Archive,
  Box,
  ChevronUp,
  Mail,
  RotateCcw,
  Sliders,
  Zap,
} from "feather-icons-react/build/IconComponents";
import { useDispatch, useSelector } from "react-redux";
import { setToogleHeader, stocksData } from "../../core/redux/action";
import Select from "react-select";
import { Filter } from "react-feather";
import EditLowStock from "../../core/modals/inventory/editlowstock";
import Swal from "sweetalert2";
import Table from "../../core/pagination/datatable";
import { API_URL } from "../../constants/AppConstants";
import axios from "axios";

const Referrals = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.toggle_header);
  const login_data = useSelector((state) => state.login_data);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const stocks_data = useSelector((state) => state.stocks_data);
  const selected_store = useSelector((state) => state.selected_store);
  const [stocksArr, setStocksArr] = useState([]);
  const [lowStocks, setLowStocks] = useState([]);
  const [expiredList, setExpiredList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredLowStocks, setfilteredLowStocks] = useState(lowStocks);
  const [filteredExpiredList, setfilteredExpiredList] = useState(expiredList);

  const oldandlatestvalue = [
    { value: "date", label: "Sort by Date" },
    // { value: 'newest', label: 'Newest' },
    // { value: 'oldest', label: 'Oldest' },
  ];
  const productlist = [
    { value: "chooseProduct", label: "Choose Product" },
    // { value: 'lenovo3rdGen', label: 'Lenovo 3rd Generation' },
    // { value: 'nikeJordan', label: 'Nike Jordan' },
    // { value: 'amazonEchoDot', label: 'Amazon Echo Dot' },
  ];
  const category = [
    { value: "chooseCategory", label: "Choose Category" },
    // { value: 'laptop', label: 'Laptop' },
    // { value: 'shoe', label: 'Shoe' },
    // { value: 'speaker', label: 'Speaker' },
  ];
  const warehouse = [
    { value: "chooseWarehouse", label: "Choose Warehouse" },
    // { value: 'lavishWarehouse', label: 'Lavish Warehouse' },
    // { value: 'lobarHandy', label: 'Lobar Handy' },
    // { value: 'traditionalWarehouse', label: 'Traditional Warehouse' },
  ];
  const renderTooltip = (props) => (
    <Tooltip id="pdf-tooltip" {...props}>
      Pdf
    </Tooltip>
  );
  const renderExcelTooltip = (props) => (
    <Tooltip id="excel-tooltip" {...props}>
      Excel
    </Tooltip>
  );
  const renderPrinterTooltip = (props) => (
    <Tooltip id="printer-tooltip" {...props}>
      Printer
    </Tooltip>
  );
  const renderRefreshTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Refresh
    </Tooltip>
  );
  const renderCollapseTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Collapse
    </Tooltip>
  );

  const columns = [
    {
      title: "Reference Name",
      dataIndex: "referenceName",
    },
    {
      title: "Reffered Customer",
      dataIndex: "refferedCustomer",
    },
    {
      title: "Qty Sold",
      dataIndex: "qtySold",
    },
    {
      title: "Total Amount",
      dataIndex: "grandTotal",
      render: (text, record) => (
        <span>{record.productUnit ? record.productUnit.label : "N/A"}</span>
      ),
    },
    {
      title: "Commisioned Earned",
      dataIndex: "commissionEarned",
    },
  ];

  async function fetchData() {
    try {
      let response = await axios.get(API_URL + "stocks");
      if (response.status === 200) {
        let filteredData = [];
        filteredData = response.data?.filter(
          (data) =>
            data.storeId == selected_store[0]?._id && data.itemActive === true
        );
        setStocksArr(filteredData);
        dispatch(stocksData(response.data));
        const lowStockitems = filteredData.filter(
          (item) =>
            parseInt(item.productQty) <= item.minQty &&
            parseInt(item.productQty) > 0
        );
        const expiredList = filteredData.filter(
          (item) => parseInt(item.productQty) <= 0
        );
        setLowStocks(lowStockitems);
        setfilteredLowStocks(lowStockitems);
        setExpiredList(expiredList);
        setfilteredExpiredList(expiredList);
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    // fetchData();
  }, []);

  useEffect(() => {
    const LowStock = lowStocks.filter(
      (item) =>
        item.productName
          .toLowerCase()
          .includes(searchTerm ? searchTerm.toLowerCase() : "") ||
        item.productSKU
          .toLowerCase()
          .includes(searchTerm ? searchTerm.toLowerCase() : "")
    );

    setfilteredLowStocks(LowStock);

    const ExpiredLists = expiredList.filter(
      (item) =>
        item.productName
          .toLowerCase()
          .includes(searchTerm ? searchTerm.toLowerCase() : "") ||
        item.productSKU
          .toLowerCase()
          .includes(searchTerm ? searchTerm.toLowerCase() : "")
    );

    setfilteredExpiredList(ExpiredLists);
  }, [searchTerm]);

  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="page-title me-auto">
              <h4>Refferal Lists</h4>
              <h6>Manage your refferal accounts</h6>
            </div>
            <ul className="table-top-head">
              {/* <li>
                <div className="status-toggle d-flex justify-content-between align-items-center">
                  <input
                    type="checkbox"
                    id="user2"
                    className="check"
                    defaultChecked="true"
                  />
                  <label htmlFor="user2" className="checktoggle">
                    checkbox
                  </label>
                  Notify
                </div>
              </li> */}
              <li>
                <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top">
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderCollapseTooltip}>
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    id="collapse-header"
                    className={data ? "active" : ""}
                    onClick={() => {
                      dispatch(setToogleHeader(!data));
                    }}
                  >
                    <ChevronUp />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
          </div>
          <div className="table-tab">
            <ul className="nav nav-pills" id="pills-tab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="pills-home-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-home"
                  type="button"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected="true"
                >
                  Sale Referrals
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="pills-profile-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-profile"
                  type="button"
                  role="tab"
                  aria-controls="pills-profile"
                  aria-selected="false"
                >
                  Purchase Referrals
                </button>
              </li>
            </ul>
            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                {/* /product list */}
                <div className="card table-list-card">
                  <div className="card-body">
                    <div className="table-top">
                      <div className="search-set">
                        <div className="search-input">
                          <input
                            type="text"
                            placeholder="Search"
                            className="form-control form-control-sm formsearch"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                          />
                          <Link to className="btn btn-searchset">
                            <i
                              data-feather="search"
                              className="feather-search"
                            />
                          </Link>
                        </div>
                      </div>
                      {/* <div className="search-path">
                        <Link
                          className={`btn btn-filter ${isFilterVisible ? "setclose" : ""
                            }`}
                          id="filter_search"
                        >
                          <Filter
                            className="filter-icon"
                            
                          />
                          <span >
                            <ImageWithBasePath
                              src="assets/img/icons/closes.svg"
                              alt="img"
                            />
                          </span>
                        </Link>
                      </div> */}
                      {/* <div className="form-sort">
                        <Sliders className="info-img" />
                        <Select
                          className="select"
                          options={oldandlatestvalue}
                          placeholder="Newest"
                        />
                      </div> */}
                    </div>
                    {/* /Filter */}
                    <div
                      className={`card${isFilterVisible ? " visible" : ""}`}
                      id="filter_inputs"
                      style={{ display: isFilterVisible ? "block" : "none" }}
                    >
                      {" "}
                      <div className="card-body pb-0">
                        <div className="row">
                          <div className="col-lg-3 col-sm-6 col-12">
                            <div className="input-blocks">
                              <Box className="info-img" />
                              <Select
                                options={productlist}
                                className="select"
                                placeholder="Choose Product"
                              />
                            </div>
                          </div>
                          <div className="col-lg-3 col-sm-6 col-12">
                            <div className="input-blocks">
                              <i data-feather="zap" className="info-img" />
                              <Zap className="info-img" />
                              <Select
                                options={category}
                                className="select"
                                placeholder="Choose Product"
                              />
                            </div>
                          </div>
                          <div className="col-lg-3 col-sm-6 col-12">
                            <div className="input-blocks">
                              <Archive className="info-img" />
                              <Select
                                options={warehouse}
                                className="select"
                                placeholder="Choose Warehouse"
                              />
                            </div>
                          </div>
                          <div className="col-lg-3 col-sm-6 col-12 ms-auto">
                            <div className="input-blocks">
                              <Link className="btn btn-filters ms-auto">
                                {" "}
                                <i
                                  data-feather="search"
                                  className="feather-search"
                                />{" "}
                                Search{" "}
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* /Filter */}
                    <div className="table-responsive">
                      <Table columns={columns} dataSource={filteredLowStocks} />
                    </div>
                  </div>
                </div>
                {/* /product list */}
              </div>
              <div
                className="tab-pane fade"
                id="pills-profile"
                role="tabpanel"
                aria-labelledby="pills-profile-tab"
              >
                {/* /product list */}
                <div className="card table-list-card">
                  <div className="card-body">
                    <div className="table-top">
                      <div className="search-set">
                        <div className="search-input">
                          <input
                            type="text"
                            placeholder="Search"
                            className="form-control form-control-sm formsearch"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                          />
                          <Link to className="btn btn-searchset">
                            <i
                              data-feather="search"
                              className="feather-search"
                            />
                          </Link>
                        </div>
                      </div>
                      {/* <div className="search-path">
                        <Link
                          className={`btn btn-filter ${isFilterVisible ? "setclose" : ""
                            }`}
                          id="filter_search"
                        >
                          <Filter
                            className="filter-icon"
                            
                          />
                          <span >
                            <ImageWithBasePath
                              src="assets/img/icons/closes.svg"
                              alt="img"
                            />
                          </span>
                        </Link>
                      </div> */}
                    </div>
                    {/* /Filter */}
                    {/* <div className="card" id="filter_inputs1">
                      <div className="card-body pb-0">
                        <div className="row">
                          <div className="col-lg-3 col-sm-6 col-12">
                            <div className="input-blocks">
                              <i data-feather="box" className="info-img" />
                              <select className="select">
                                <option>Choose Product</option>
                                <option>Lenovo 3rd Generation </option>
                                <option>Nike Jordan</option>
                                <option>Amazon Echo Dot </option>
                              </select>
                            </div>
                          </div>
                          <div className="col-lg-3 col-sm-6 col-12">
                            <div className="input-blocks">
                              <i data-feather="zap" className="info-img" />
                              <select className="select">
                                <option>Choose Category</option>
                                <option>Laptop</option>
                                <option>Shoe</option>
                                <option>Speaker</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-lg-3 col-sm-6 col-12">
                            <div className="input-blocks">
                              <i data-feather="archive" className="info-img" />
                              <select className="select">
                                <option>Choose Warehouse</option>
                                <option>Lavish Warehouse </option>
                                <option>Lobar Handy </option>
                                <option>Traditional Warehouse </option>
                              </select>
                            </div>
                          </div>
                          <div className="col-lg-3 col-sm-6 col-12 ms-auto">
                            <div className="input-blocks">
                              <Link className="btn btn-filters ms-auto">
                                {" "}
                                <i
                                  data-feather="search"
                                  className="feather-search"
                                />{" "}
                                Search{" "}
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                    {/* /Filter */}
                    <div className="table-responsive">
                      <Table
                        columns={columns}
                        dataSource={filteredExpiredList}
                      />
                    </div>
                  </div>
                </div>
                {/* /product list */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <EditLowStock />
    </div>
  );
};

export default Referrals;
