/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { all_routes } from "../../../Router/all_routes";
import { DatePicker } from "antd";
import Addunits from "../../../core/modals/inventory/addunits";
import AddCategory from "../../../core/modals/inventory/addcategory";
import AddBrand from "../../../core/modals/addbrand";
import {
  ArrowLeft,
  Calendar,
  ChevronDown,
  ChevronUp,
  Info,
  LifeBuoy,
  List,
  PlusCircle,
  // Trash2,
  // X,
} from "feather-icons-react/build/IconComponents";
import { useDispatch, useSelector } from "react-redux";
import {
  setToogleHeader,
  alertData,
  productsData,
  stocksData,
} from "../../../core/redux/action";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import axios from "axios";
import { API_URL } from "../../../constants/AppConstants.js";
import AddWarehouseModal from "../../../core/modals/peoples/AddWarehouseModal";
import AddSubcategory from "../../../core/modals/inventory/addsubcategory";
import {
  fetchBrandsData,
  fetchCategoryData,
  fetchProductsData,
  fetchStocksData,
  fetchSubCategoryData,
  fetchWarehousesData,
} from "../../../utils/utilities";

// import ImageWithBasePath from "../../core/img/imagewithbasebath";

const AddProductModal = (props) => {
  const route = all_routes;
  const dispatch = useDispatch();
  const login_data = useSelector((state) => state.login_data);
  const data = useSelector((state) => state.toggle_header);
  const warehouse_data = useSelector((state) => state.warehouse_data);
  const category_data = useSelector((state) => state.category_data);
  const subcategory_data = useSelector((state) => state.subcategory_data);
  const brand_data = useSelector((state) => state.brand_data);
  const selected_store = useSelector((state) => state.selected_store);
  const unit_data = useSelector((state) => state.unit_data);

  const [productStore, setProductStore] = useState(selected_store);
  const [productWarehouse, setProductWarehouse] = useState([
    {
      label: "Choose",
      value: null,
    },
  ]);
  const [productName, setProductName] = useState("");
  const [productSKU, setProductSKU] = useState("");
  const [productCategory, setProductCategory] = useState([
    {
      label: "Choose",
      value: null,
    },
  ]);
  const [productSubCategory, setProductSubCategory] = useState([
    {
      label: "Choose",
      value: null,
    },
  ]);
  const [productSubCategoryDropdown, setProductSubCategoryDropdown] = useState([
    {
      label: "Choose",
      value: null,
    },
  ]);
  const [productBrand, setProductBrand] = useState([
    {
      label: "Choose",
      value: null,
    },
  ]);
  const [productUnit, setProductUnit] = useState("");
  const [productSellingType, setProductSellingType] = useState("");
  const [productItemCode, setProductItemCode] = useState("");
  const [productDescription, setProductDescription] = useState("");

  const [productQty, setProductQty] = useState("");
  const [productPrice, setProductPrice] = useState("");
  const [productTaxType, setProductTaxType] = useState("");
  const [productTaxValue, setProductTaxValue] = useState("");
  const [productDiscountType, setProductDiscountType] = useState({
    label: "Cash",
    value: "Cash",
  });
  const [productDiscountValue, setProductDiscountValue] = useState("");
  const [productUnitCost, setProductUnitCost] = useState("");
  const [productClubbedUnit, setProductClubbedUnit] = useState("");
  const [productAlternateQty, setProductAlternateQty] = useState("");
  const [productQtyAlert, setProductQtyAlert] = useState("");

  const [productMfgDate, setProductMfgDate] = useState(new Date());
  const [productExpiryDate, setProductExpiryDate] = useState(new Date());

  const [isLoading, setIsLoading] = useState(false);

  const handleDateChange = (date) => {
    setProductMfgDate(date);
  };

  const handleDateChange1 = (date) => {
    setProductExpiryDate(date);
  };

  const renderCollapseTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Collapse
    </Tooltip>
  );
  const store = [{ value: "Choose", label: "Choose" }];
  const warehouse = [{ value: "Choose", label: "Choose" }];
  const category = [{ value: "Choose", label: "Choose" }];
  const subcategory = [{ value: "Choose", label: "Choose" }];
  const brand = [{ value: "Choose", label: "Choose" }];
  const unit = [{ value: "Choose", label: "Choose" }];
  const sellingtype = [
    { value: "Choose", label: "Choose" },
    { value: "Transactional Selling", label: "Transactional selling" },
    { value: "Solution Selling", label: "Solution selling" },
  ];
  const barcodesymbol = [{ value: "Choose", label: "Choose" }];
  const taxtype = [
    { value: "Exclusive", label: "Exclusive" },
    { value: "Sales Tax", label: "Sales Tax" },
  ];
  const discounttype = [
    { value: "Percentage", label: "Percentage" },
    { value: "Cash", label: "Cash" },
  ];

  useEffect(() => {
    fetchWarehousesData(login_data, selected_store[0]);
    fetchCategoryData(login_data, selected_store[0]);
    fetchSubCategoryData(login_data, selected_store[0]);
    fetchBrandsData(login_data, selected_store[0]);
  }, [selected_store]);

  useEffect(() => {
    let filteredArr = [
      {
        label: "Choose",
        value: null,
      },
    ];

    subcategory_data?.forEach((item) => {
      if (productCategory?.label == item?.parentCategory?.categoryName) {
        filteredArr.push({
          label: item.subCategoryName,
          value: item._id,
        });
      }
    });
    setProductSubCategoryDropdown(filteredArr);
    setProductSubCategory({
      label: "Choose",
      value: null,
    });
  }, [productCategory, subcategory_data]);

  useEffect(() => {
    if (productName && productBrand && productCategory) {
      setProductSKU(
        `${productName?.slice(0, 4).replaceAll(" ", "")}/${
          Array.isArray(productCategory)
            ? "cat"
            : productCategory?.label.slice(0, 4).replaceAll(" ", "")
        }/${
          Array.isArray(productBrand)
            ? "brnd"
            : productBrand?.label.slice(0, 4).replaceAll(" ", "")
        }`
      );
    }
  }, [productName, productCategory, productBrand]);

  useEffect(() => {
    const newval = generateRandomAlphaNumeric();
    setProductItemCode(newval);
  }, []);

  async function fetchData() {
    try {
      let response = await axios.get(API_URL + "products");
      let filteredData = [];
      if (login_data?.roleId == 1) {
        filteredData = response.data?.filter(
          (data) =>
            data.storeId == selected_store[0]?._id && data.itemActive === true
        );
        dispatch(productsData(filteredData));
      } else {
        filteredData = response.data?.filter(
          (data) =>
            data.storeId == selected_store[0]?._id &&
            data.itemActive === true &&
            login_data?.empCode == data.userCode
        );
        dispatch(productsData(filteredData));
      }
    } catch (err) {
      console.log(err);
    }
  }

  function clearData() {
    setProductWarehouse({
      label: "Choose",
      value: null,
    });
    setProductName("");
    setProductSKU("");
    setProductCategory({
      label: "Choose",
      value: null,
    });
    setProductSubCategory({
      label: "Choose",
      value: null,
    });
    setProductBrand({
      label: "Choose",
      value: null,
    });
    setProductSellingType({
      label: "Choose",
      value: null,
    });
    setProductItemCode("");
    setProductTaxType({
      label: "Choose",
      value: null,
    });
    setProductTaxValue("");
    setProductDiscountType({
      label: "Cash",
      value: "Cash",
    });
    setProductDiscountValue("");
    setProductUnit({
      label: "Choose",
      value: null,
    });
    setProductClubbedUnit({
      label: "Choose",
      value: null,
    });
    setProductAlternateQty("");
  }

  async function fetchStockData() {
    let response = await axios.get(API_URL + "stocks");
    if (response.status === 200) {
      dispatch(stocksData(response.data));
    }
  }

  async function submitData() {
    if (
      !productStore ||
      !productWarehouse ||
      !productName ||
      !productSKU ||
      !productCategory ||
      !productBrand ||
      !productUnit ||
      !productSellingType ||
      !productItemCode ||
      !productClubbedUnit ||
      !productAlternateQty
    ) {
      dispatch(
        alertData({
          type: "error",
          msg: "Please fill the required fields",
        })
      );
      setIsLoading(false);
      return;
    }

    setIsLoading(true);

    let dataBody = {
      productStore,
      productWarehouse,
      productName,
      productSKU,
      productCategory,
      productSubCategory,
      productBrand,
      productUnit,
      productSellingType,
      productItemCode,
      productDescription,
      productTaxType,
      productTaxValue,
      productDiscountType,
      productDiscountValue,
      productClubbedUnit,
      productAlternateQty: productAlternateQty,
      productQtyAlert,
      productMfgDate: new Date(productMfgDate)
        .toLocaleDateString()
        .split(",")[0],
      productExpiryDate: new Date(productExpiryDate)
        .toLocaleDateString()
        .split(",")[0],
      storeId: selected_store[0]?._id,
      createdOn: new Date().toLocaleDateString().split(",")[0],
      userCode: login_data?.empCode,
      itemActive: true,
    };

    let stockDataBody = {
      id: Number(new Date().getTime()) + 1000,
      productStore,
      productWarehouse,
      productName,
      productSKU,
      productUnit,
      productCurrentQty: 0,
      productClubbedUnit,
      productQtyAlert,
      productCurrentAlternateQty: 0,
      productAlternateQty: productAlternateQty,
      productItemCode,
      storeId: selected_store[0]?._id,
      stockStatus: "InActive",
      createdOn: new Date().toLocaleDateString().split(",")[0],
      userCode: login_data?.empCode,
      itemActive: true,
    };

    try {
      let response = await axios.post(API_URL + "products", dataBody);
      if (response.status === 200) {
        dispatch(
          alertData({
            type: "success",
            msg: "Product added successfully!",
          })
        );
        fetchProductsData(login_data, selected_store[0]);
        clearData();

        setTimeout(async () => {
          let response2 = await axios.post(API_URL + "stocks", {
            ...stockDataBody,
            productId: response.data._id,
          });
          if (response2.status === 200) {
            dispatch(
              alertData({
                type: "success",
                msg: "Stock added successfully!",
              })
            );
            setIsLoading(false);
            fetchStocksData(login_data, selected_store[0]);
          }
        }, 2500);
      }
    } catch (err) {
      console.log(err);
    }
  }

  function generateRandomAlphaNumeric() {
    const length = 6;
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let result = "";

    for (let i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }

    return result;
  }

  return (
    <>
      {/* Add Customer */}
      <div className="modal fade" id="add-product">
        <div className="modal-dialog modal-dialog-centered custom-modal-two">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>Add Product</h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    data-bs-toggle={props.from ? "modal" : ""}
                    data-bs-target={props.from ? `${props.target}` : ""}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <form>
                    <div className="card">
                      <div className="card-body add-product pb-0">
                        <div
                          className="accordion-card-one accordion"
                          id="accordionExample"
                        >
                          <div className="accordion-item">
                            <div className="accordion-header" id="headingOne">
                              <div
                                className="accordion-button"
                                // data-bs-toggle="collapse"
                                // data-bs-target="#collapseOne"
                                // aria-controls="collapseOne"
                              >
                                <div className="addproduct-icon">
                                  <h5>
                                    <Info className="add-info" />

                                    <span>Product Information</span>
                                  </h5>
                                </div>
                              </div>
                            </div>
                            <div
                              id="collapseOne"
                              className="accordion-collapse collapse show"
                              aria-labelledby="headingOne"
                              data-bs-parent="#accordionExample"
                            >
                              <div className="accordion-body">
                                <div className="row">
                                  <div className="col-lg-6 col-sm-6 col-12">
                                    <div className="mb-3 add-product">
                                      <label className="form-label">
                                        Store
                                        <span style={{ color: "red" }}>*</span>
                                      </label>
                                      <input
                                        disabled
                                        type="text"
                                        className="form-control"
                                        value={selected_store[0]?.storeName}
                                        readOnly
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-sm-6 col-12">
                                    <div className="mb-3 add-product">
                                      <label className="form-label">
                                        Warehouse
                                        <span style={{ color: "red" }}>*</span>
                                      </label>
                                      <div className="d-flex justify-content-between align-items-center">
                                        <div style={{ width: "83%" }}>
                                          <Select
                                            value={
                                              productWarehouse?.value
                                                ? productWarehouse
                                                : [
                                                    {
                                                      label: "Choose",
                                                      value: null,
                                                    },
                                                  ]
                                            }
                                            className="select"
                                            options={warehouse_data?.map(
                                              (item) => {
                                                return {
                                                  label: item.name,
                                                  value: item._id,
                                                };
                                              }
                                            )}
                                            placeholder="Choose Warehouse"
                                            onChange={(value) =>
                                              setProductWarehouse(value)
                                            }
                                          />
                                        </div>
                                        <div
                                          className="add-icon"
                                          style={{ width: "15%" }}
                                        >
                                          <Link
                                            data-bs-toggle="modal"
                                            data-bs-target={`#add-warehouse`}
                                            onClick={(e) => e.preventDefault()}
                                            className="choose-add"
                                          >
                                            <PlusCircle className="plus" />
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-6 col-sm-6 col-12">
                                    <div className="mb-3 add-product">
                                      <label className="form-label">
                                        Product Name
                                        <span style={{ color: "red" }}>*</span>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        value={productName}
                                        onChange={(e) =>
                                          setProductName(e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-sm-6 col-12">
                                    <div className="input-blocks add-product list">
                                      <label>
                                        SKU{" "}
                                        <span style={{ color: "red" }}>*</span>
                                      </label>
                                      <input
                                        readOnly
                                        type="text"
                                        className="form-control list"
                                        placeholder="Enter SKU"
                                        value={productSKU}
                                        onChange={(e) =>
                                          setProductSKU(e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="addservice-info">
                                  <div className="row">
                                    <div className="col-lg-6 col-sm-6 col-12">
                                      <div className="mb-3 add-product">
                                        <div className="add-newplus">
                                          <label className="form-label">
                                            Category
                                            <span style={{ color: "red" }}>
                                              *
                                            </span>
                                          </label>
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center">
                                          <div style={{ width: "84%" }}>
                                            <Select
                                              value={
                                                productCategory?.value
                                                  ? productCategory
                                                  : [
                                                      {
                                                        label: "Choose",
                                                        value: null,
                                                      },
                                                    ]
                                              }
                                              className="select"
                                              options={category_data?.map(
                                                (item) => {
                                                  return {
                                                    label: item.categoryName,
                                                    value: item._id,
                                                  };
                                                }
                                              )}
                                              placeholder="Choose Category"
                                              onChange={(value) => {
                                                setProductCategory(value);
                                              }}
                                            />
                                          </div>
                                          <div
                                            className="add-icon"
                                            style={{ width: "14%" }}
                                          >
                                            <Link
                                              data-bs-toggle="modal"
                                              data-bs-target={`#add-category`}
                                              onClick={(e) =>
                                                e.preventDefault()
                                              }
                                              className="choose-add"
                                            >
                                              <PlusCircle className="plus" />
                                            </Link>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-lg-6 col-sm-6 col-12">
                                      <div className="mb-3 add-product">
                                        <label className="form-label">
                                          Sub Category
                                        </label>
                                        <div className="d-flex justify-content-between align-items-center">
                                          <div style={{ width: "84%" }}>
                                            <Select
                                              className="select sub-category-dropdown"
                                              options={
                                                productSubCategoryDropdown
                                              }
                                              value={
                                                productSubCategory?.value
                                                  ? productSubCategory
                                                  : productSubCategoryDropdown[0]
                                              }
                                              placeholder="Choose Sub Category"
                                              onChange={(value) => {
                                                setProductSubCategory(value);
                                              }}
                                            />
                                          </div>
                                          <div
                                            className="add-icon"
                                            style={{ width: "14%" }}
                                          >
                                            <Link
                                              data-bs-toggle="modal"
                                              data-bs-target={`#add-subcategory`}
                                              onClick={(e) =>
                                                e.preventDefault()
                                              }
                                              className="choose-add"
                                            >
                                              <PlusCircle className="plus" />
                                            </Link>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="add-product-new">
                                  <div className="row">
                                    <div className="col-lg-6 col-sm-6 col-12">
                                      <div className="mb-3 add-product">
                                        <div className="add-newplus">
                                          <label className="form-label">
                                            Brand
                                            <span style={{ color: "red" }}>
                                              *
                                            </span>
                                          </label>
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center">
                                          <div style={{ width: "84%" }}>
                                            <Select
                                              value={
                                                productBrand?.value
                                                  ? productBrand
                                                  : [
                                                      {
                                                        label: "Choose",
                                                        value: null,
                                                      },
                                                    ]
                                              }
                                              className="select"
                                              options={brand_data?.map(
                                                (item) => {
                                                  return {
                                                    label: item.brandName,
                                                    value: item._id,
                                                  };
                                                }
                                              )}
                                              placeholder="Choose Brand"
                                              onChange={(value) =>
                                                setProductBrand(value)
                                              }
                                            />
                                          </div>
                                          <div
                                            className="add-icon"
                                            style={{ width: "14%" }}
                                          >
                                            <Link
                                              data-bs-toggle="modal"
                                              data-bs-target={`#add-brand`}
                                              onClick={(e) =>
                                                e.preventDefault()
                                              }
                                              className="choose-add"
                                            >
                                              <PlusCircle className="plus" />
                                            </Link>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-lg-6   col-sm-6 col-12">
                                      <div className="mb-3 add-product">
                                        <label className="form-label">
                                          Selling Type
                                          <span style={{ color: "red" }}>
                                            *
                                          </span>
                                        </label>
                                        <Select
                                          value={
                                            productSellingType?.value
                                              ? productSellingType
                                              : [
                                                  {
                                                    label: "Choose",
                                                    value: null,
                                                  },
                                                ]
                                          }
                                          className="select"
                                          options={sellingtype}
                                          placeholder="Choose"
                                          onChange={(value) =>
                                            setProductSellingType(value)
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-12 col-sm-6 col-12">
                                    <div className="input-blocks add-product list">
                                      <label>
                                        Item Code{" "}
                                        <span style={{ color: "red" }}>*</span>
                                      </label>
                                      <input
                                        disabled
                                        value={productItemCode}
                                        type="text"
                                        className="form-control list"
                                      />
                                    </div>
                                  </div>
                                </div>
                                {/* Editor */}
                                <div className="col-lg-12">
                                  <div className="input-blocks summer-description-box transfer mb-3">
                                    <label>Description</label>
                                    <textarea
                                      className="form-control h-100"
                                      rows={5}
                                      defaultValue={""}
                                      value={productDescription}
                                      onChange={(e) =>
                                        setProductDescription(e.target.value)
                                      }
                                    />
                                    <p className="mt-1">
                                      Maximum 60 Characters
                                    </p>
                                  </div>
                                </div>
                                {/* /Editor */}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="accordion-card-one accordion"
                          id="accordionExample2"
                        >
                          <div className="accordion-item">
                            <div className="accordion-header" id="headingTwo">
                              <div
                                className="accordion-button"
                                // data-bs-toggle="collapse"
                                // data-bs-target="#collapseTwo"
                                // aria-controls="collapseTwo"
                              >
                                <div className="text-editor add-list">
                                  <div className="addproduct-icon list icon">
                                    <h5>
                                      <LifeBuoy className="add-info" />
                                      <span>Pricing &amp; Stocks</span>
                                    </h5>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              id="collapseTwo"
                              className="accordion-collapse collapse show"
                              aria-labelledby="headingTwo"
                              data-bs-parent="#accordionExample2"
                            >
                              <div className="accordion-body">
                                <div className="input-blocks add-products">
                                  <label className="d-block">
                                    Product Type
                                  </label>
                                  <div className="single-pill-product">
                                    <ul
                                      className="nav nav-pills"
                                      id="pills-tab1"
                                      role="tablist"
                                    >
                                      <li
                                        className="nav-item"
                                        role="presentation"
                                      >
                                        <span
                                          className="custom_radio me-4 mb-0 active"
                                          id="pills-home-tab"
                                          data-bs-toggle="pill"
                                          data-bs-target="#pills-home"
                                          role="tab"
                                          aria-controls="pills-home"
                                          aria-selected="true"
                                        >
                                          <input
                                            type="radio"
                                            className="form-control"
                                            name="payment"
                                          />
                                          <span className="checkmark" /> Single
                                          Product
                                        </span>
                                      </li>
                                      {/* <li className="nav-item" role="presentation">
                              <span
                                className="custom_radio me-2 mb-0"
                                id="pills-profile-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-profile"
                                role="tab"
                                aria-controls="pills-profile"
                                aria-selected="false"
                              >
                                <input
                                  type="radio"
                                  className="form-control"
                                  name="sign"
                                />
                                <span className="checkmark" /> Variable Product
                              </span>
                            </li> */}
                                    </ul>
                                  </div>
                                </div>
                                <div
                                  className="tab-content"
                                  id="pills-tabContent"
                                >
                                  <div
                                    className="tab-pane fade show active"
                                    id="pills-home"
                                    role="tabpanel"
                                    aria-labelledby="pills-home-tab"
                                  >
                                    <div className="row"></div>
                                    <div className="row">
                                      <div className="col-lg-6 col-sm-6 col-12">
                                        <div className="input-blocks add-product">
                                          <label>Tax Type</label>
                                          <Select
                                            className="select"
                                            options={taxtype}
                                            placeholder="Select Option"
                                            value={productTaxType}
                                            onChange={(value) =>
                                              setProductTaxType(value)
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-sm-6 col-12">
                                        <div className="input-blocks add-product">
                                          <label>Tax Value(%)</label>
                                          <input
                                            type="text"
                                            placeholder="Choose"
                                            value={productTaxValue}
                                            onChange={(e) =>
                                              setProductTaxValue(e.target.value)
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-lg-6 col-sm-6 col-12">
                                        <div className="input-blocks add-product">
                                          <label>Discount Type</label>
                                          <Select
                                            className="select"
                                            options={discounttype}
                                            placeholder="Choose"
                                            value={productDiscountType}
                                            onChange={(value) =>
                                              setProductDiscountType(value)
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-sm-6 col-12">
                                        <div className="input-blocks add-product">
                                          <label>Discount Value</label>
                                          <input
                                            type="text"
                                            placeholder="Choose"
                                            value={productDiscountValue}
                                            onChange={(e) =>
                                              setProductDiscountValue(
                                                e.target.value
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-lg-12 col-sm-6 col-12">
                                        <div className="mb-3 add-product">
                                          <div className="add-newplus">
                                            <label className="form-label">
                                              Main Unit{" "}
                                              <span style={{ color: "red" }}>
                                                *
                                              </span>
                                            </label>
                                          </div>
                                          <Select
                                            value={
                                              productUnit?.value
                                                ? productUnit
                                                : [
                                                    {
                                                      label: "Choose",
                                                      value: null,
                                                    },
                                                  ]
                                            }
                                            className="select"
                                            options={unit_data?.map((item) => {
                                              return {
                                                label: item.unit,
                                                value: item.id,
                                              };
                                            })}
                                            placeholder="Choose"
                                            onChange={(value) =>
                                              setProductUnit(value)
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-lg-6 col-sm-6 col-12">
                                        <div className="input-blocks add-product">
                                          <label>
                                            Alternate Unit{" "}
                                            <span style={{ color: "red" }}>
                                              *
                                            </span>
                                          </label>
                                          <Select
                                            value={
                                              productClubbedUnit?.value
                                                ? productClubbedUnit
                                                : [
                                                    {
                                                      label: "Choose",
                                                      value: null,
                                                    },
                                                  ]
                                            }
                                            className="select"
                                            options={unit_data?.map((item) => {
                                              return {
                                                label: item.unit,
                                                value: item.id,
                                              };
                                            })}
                                            placeholder="Choose"
                                            onChange={(value) =>
                                              setProductClubbedUnit(value)
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-sm-6 col-12">
                                        <div className="input-blocks add-product">
                                          <label>
                                            Alternate Qty{" "}
                                            <span style={{ color: "red" }}>
                                              *
                                            </span>
                                          </label>
                                          <input
                                            type="text"
                                            placeholder=""
                                            value={productAlternateQty}
                                            onChange={(e) =>
                                              setProductAlternateQty(
                                                e.target.value
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="btn-addproduct mb-4">
                        <button
                          type="button"
                          className="btn btn-cancel me-2"
                          data-bs-dismiss="modal"
                          data-bs-toggle={props.from ? "modal" : ""}
                          data-bs-target={props.from ? `${props.target}` : ""}
                        >
                          Cancel
                        </button>
                        <div
                          className={`btn btn-submit ${
                            isLoading ? "disabled" : ""
                          }`}
                          onClick={submitData}
                        >
                          Save Product
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <AddCategory from={"add-product"} target={"#add-product"} />
      <AddSubcategory from={"add-product"} target={"#add-product"} />
      <AddBrand from={"add-product"} target={"#add-product"} />
      <AddWarehouseModal from={"add-product"} target={"#add-product"} />
      {/* Add Product */}
    </>
  );
};

export default AddProductModal;
