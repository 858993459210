/* eslint-disable no-unused-vars */

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Filter, Sliders } from "react-feather";
import Select from "react-select";
import { Globe, User } from "react-feather";
import ImageWithBasePath from "../../img/imagewithbasebath";
import Breadcrumbs from "../../breadcrumbs";
import axios from "axios";
import { API_URL } from "../../../constants/AppConstants";
import { useDispatch, useSelector } from "react-redux";
import {
  warehouseData,
  alertData,
  loaderData,
} from "../../../core/redux/action";
import stateCityListArr from "../../../data/StateCityList.json";
import { fetchWarehousesData } from "../../../utils/utilities";

const WareHouses = () => {
  const login_data = useSelector((state) => state.login_data);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const toggleFilterVisibility = () => {
    setIsFilterVisible((prevVisibility) => !prevVisibility);
  };

  const [warehouseName, setWarehouseName] = useState("");
  const [contactPerson, setContactPerson] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [workphone, setWorkphone] = useState("");
  const [email, setEmail] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [country, setCountry] = useState({
    label: "Choose",
    value: null,
  });
  const [state, setState] = useState({
    label: "Choose",
    value: null,
  });
  const [city, setCity] = useState({
    label: "Choose",
    value: null,
  });
  const [zipcode, setZipcode] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const selected_store = useSelector((state) => state.selected_store);
  const warehouse_data = useSelector((state) => state.warehouse_data);
  const dispatch = useDispatch();
  const [selectedWarehouse, setSelectedWarehouse] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredWareHouse, setFilteredWareHouse] = useState(warehouse_data);
  const [warehouseStatus, setWarehouseStatus] = useState("");

  const options = [
    { value: "sortByDate", label: "Sort by Date" },
    // { value: "140923", label: "14 09 23" },
    // { value: "110923", label: "11 09 23" },
  ];
  const optionsTwo = [{ label: "Choose Store Name", value: "" }];

  const countries = [{ label: "Choose Country", value: "" }];

  const options1 = [{ value: "choose", label: "Choose" }];

  const options2 = [{ value: "choose", label: "Choose" }];

  const searchWarehouse = (term) => {
    const filtered = warehouse_data.filter((item) =>
      item.name.toLowerCase().includes(term.toLowerCase())
    );
    setFilteredWareHouse(filtered);
  };

  useEffect(() => {
    setFilteredWareHouse(warehouse_data);
  }, [warehouse_data]);

  useEffect(() => {
    searchWarehouse(searchTerm);
  }, [searchTerm, warehouse_data]);

  useEffect(() => {
    fetchWarehousesData(login_data, selected_store[0]);
  }, [selected_store]);

  useEffect(() => {
    if (Object.keys(stateCityListArr)?.length) {
      let stateArr = [];
      Object.keys(stateCityListArr).forEach((state) => {
        stateArr.push({
          label: state,
          value: state,
        });
      });
      setStateList(stateArr);
    }
  }, []);

  useEffect(() => {
    if (state?.value) {
      if (Object.keys(stateCityListArr)?.length) {
        let cityArr = [];
        Object.entries(stateCityListArr).forEach((states, index) => {
          if (states[0] == state?.value) {
            states[1]?.length &&
              states[1]?.forEach((city) => {
                cityArr.push({
                  label: city,
                  value: city,
                });
              });
          }
        });
        setCityList(cityArr);
      }
    }
  }, [state]);

  function resetCity() {
    setCity({
      label: "Choose",
      value: null,
    });
  }

  function clearData() {
    setWarehouseName("");
    setWorkphone("");
    setAddress1("");
    setAddress2("");
    setState({
      label: "Choose",
      value: null,
    });
    setCity({
      label: "Choose",
      value: null,
    });
    setZipcode("");
  }

  async function submitData() {
    setIsLoading(true);

    if (
      !warehouseName ||
      !workphone ||
      !address1 ||
      !state ||
      !city ||
      !zipcode
    ) {
      dispatch(
        alertData({
          type: "error",
          msg: "Please fill all the fields.",
        })
      );
      setIsLoading(false);
      return;
    }

    if (zipcode.length != 6) {
      dispatch(
        alertData({
          type: "error",
          msg: "Please fill valid zipcode.",
        })
      );
      setIsLoading(false);
      return;
    }

    if (workphone.length != 10) {
      dispatch(
        alertData({
          type: "error",
          msg: "Please fill valid workphone.",
        })
      );
      setIsLoading(false);
      return;
    }

    let dataBody = {
      name: warehouseName,
      workphone,
      address1,
      address2,
      state,
      city,
      zipcode,
      storeId: selected_store[0]?._id,
      warehouseStatus: "Active",
      createdOn: new Date().toLocaleDateString().split(",")[0],
      userCode: login_data?.empCode,
      itemActive: true,
    };

    try {
      let response = await axios.post(API_URL + "warehouses", dataBody);
      if (response.status === 200) {
        dispatch(
          alertData({
            type: "success",
            msg: "Warehouse added successfully!",
          })
        );
        fetchWarehousesData(login_data, selected_store[0]);
        setTimeout(() => {
          setIsLoading(false);
          document?.querySelector("#add-warehouse .close")?.click();
        }, 1000);
        clearData();
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  }

  const editWarehouse = (id) => {
    const data = warehouse_data.filter((item) => item._id === id);
    if (data) {
      setSelectedWarehouse(data[0]);
      setWarehouseName(data[0].name);
      setWorkphone(data[0].workphone);
      setAddress1(data[0].address1);
      setAddress2(data[0].address2);
      setState(data[0].state);
      setCity(data[0].city);
      setZipcode(data[0].zipcode);
      setWarehouseStatus(data[0].warehouseStatus == "Active" ? true : false);
    }
  };

  async function saveEditedWarehouse(id) {
    setIsLoading(true);
    if (
      !warehouseName ||
      !workphone ||
      !address1 ||
      !state ||
      !city ||
      !zipcode
    ) {
      dispatch(
        alertData({
          type: "error",
          msg: "Please fill all the fields.",
        })
      );
      setIsLoading(false);
      return;
    }

    if (zipcode.length != 6) {
      dispatch(
        alertData({
          type: "error",
          msg: "Please fill valid zipcode.",
        })
      );
      setIsLoading(false);
      return;
    }

    if (workphone.length != 10) {
      dispatch(
        alertData({
          type: "error",
          msg: "Please fill valid workphone.",
        })
      );
      setIsLoading(false);
      return;
    }

    let dataBody = {
      name: warehouseName,
      workphone,
      address1,
      address2,
      state,
      city,
      zipcode,
      warehouseStatus: warehouseStatus ? "Active" : "InActive",
    };

    try {
      let response = await axios.put(
        API_URL + `warehouses/${selectedWarehouse._id}`,
        dataBody
      );
      if (response.status === 200) {
        dispatch(
          alertData({
            type: "success",
            msg: "Warehouse updated successfully!",
          })
        );
        fetchWarehousesData(login_data, selected_store[0]);
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  }

  async function deleteData(dataId) {
    dispatch(
      loaderData({
        type: "Loader_Data",
        payload: true,
      })
    );
    console.log(dataId);
    if (dataId) {
      try {
        let response = await axios.put(API_URL + "warehouses/delete/" + dataId);
        if (response.status === 200) {
          fetchWarehousesData(login_data, selected_store[0]);
          dispatch(
            loaderData({
              type: "Loader_Data",
              payload: false,
            })
          );
        }
      } catch (err) {
        console.log(err);
      }
    }
  }

  return (
    <div className="page-wrapper">
      <div className="content">
        <Breadcrumbs
          maintitle="Warehouse"
          subtitle="Manage Your Warehouse"
          addButton="Add New Warehouse"
          modalId="add-warehouse"
        />

        {/* /product list */}
        <div className="card table-list-card">
          <div className="card-body">
            <div className="table-top">
              <div className="search-set">
                <div className="search-input">
                  <input
                    type="text"
                    placeholder="Search"
                    className="form-control form-control-sm formsearch"
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  <Link to className="btn btn-searchset">
                    <i data-feather="search" className="feather-search" />
                  </Link>
                </div>
              </div>
              {/* <div className="search-path">
                <Link
                  className={`btn btn-filter ${
                    isFilterVisible ? "setclose" : ""
                  }`}
                  id="filter_search"
                >
                  <Filter
                    className="filter-icon"
                    onClick={toggleFilterVisibility}
                  />
                  <span onClick={toggleFilterVisibility}>
                    <ImageWithBasePath
                      src="assets/img/icons/closes.svg"
                      alt="img"
                    />
                  </span>
                </Link>
              </div> */}
            </div>
            {/* /Filter */}
            <div
              className={`card${isFilterVisible ? " visible" : ""}`}
              id="filter_inputs"
              style={{ display: isFilterVisible ? "block" : "none" }}
            >
              <div className="card-body pb-0">
                <div className="row">
                  <div className="col-lg-3 col-sm-6 col-12">
                    <div className="input-blocks">
                      <User className="info-img" />
                      <Select
                        options={optionsTwo}
                        placeholder="Choose Store Name"
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6 col-12">
                    <div className="input-blocks">
                      <Globe className="info-img" />
                      <Select
                        options={countries}
                        placeholder="Choose Country"
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6 col-12 ms-auto">
                    <div className="input-blocks">
                      <Link className="btn btn-filters ms-auto">
                        {" "}
                        <i
                          data-feather="search"
                          className="feather-search"
                        />{" "}
                        Search{" "}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /Filter */}
            <div className="table-responsive">
              <table className="table  datanew">
                <thead>
                  <tr>
                    {/* <th className="no-sort">
                      <label className="checkboxs">
                        <input type="checkbox" id="select-all" />
                        <span className="checkmarks" />
                       <span style={{ color: "red" }}>*</span></label>
                    </th> */}
                    <th>Warehouse</th>
                    {/* <th>Contact Person</th> */}
                    {/* <th>Phone</th> */}
                    <th>Total Products</th>
                    {/* <th>Stock</th> */}
                    {/* <th>Qty</th> */}
                    <th>Created On</th>
                    <th>Status</th>
                    <th className="no-sort">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredWareHouse
                    ? filteredWareHouse?.map((data, index) => {
                        return (
                          <tr key={index}>
                            {/* <td>
                          <label className="checkboxs">
                            <input type="checkbox" />
                            <span className="checkmarks" />
                           <span style={{ color: "red" }}>*</span></label>
                        </td> */}
                            <td>{data.name}</td>
                            {/* <td>
                          <div className="userimgname">
                            <Link to="#" className="product-img">
                              <ImageWithBasePath
                                src="assets/img/users/user-08.jpg"
                                alt="product"
                              />
                            </Link>
                            <Link to="#">Steven</Link>
                          </div>
                        </td> */}
                            {/* <td>+1 45445 4454</td> */}
                            <td>0</td>
                            {/* <td>55</td>
                        <td>600</td> */}
                            <td>{data.createdOn}</td>
                            <td>
                              <span
                                className={`badge ${
                                  data.warehouseStatus == "Active"
                                    ? "badge-linesuccess"
                                    : "badge-linedanger"
                                }`}
                              >
                                {data.warehouseStatus}
                              </span>
                            </td>
                            <td className="action-table-data">
                              <div className="edit-delete-action">
                                <Link
                                  className="me-2 p-2"
                                  to="#"
                                  data-bs-toggle="modal"
                                  data-bs-target="#edit-warehouse"
                                  onClick={() => editWarehouse(data._id)}
                                >
                                  <i
                                    data-feather="edit"
                                    className="feather-edit"
                                  />
                                </Link>
                                <div
                                  style={{ marginLeft: "10px" }}
                                  className="confirm-text p-2  feather-trash-container"
                                  onClick={() => deleteData(data._id)}
                                >
                                  <i
                                    data-feather="trash-2"
                                    className="feather-trash-2"
                                  />
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    : ""}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {/* /product list */}
      </div>

      <div>
        {/* Add Warehouse */}
        <div className="modal fade" id="add-warehouse">
          <div className="modal-dialog modal-dialog-centered custom-modal-two">
            <div className="modal-content">
              <div className="page-wrapper-new p-0">
                <div className="content">
                  <div className="modal-header border-0 custom-modal-header">
                    <div className="page-title">
                      <h4>Add Warehouse</h4>
                    </div>
                    <button
                      type="button"
                      className="close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={clearData}
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body custom-modal-body">
                    <form>
                      {/* <div className="modal-title-head">
                        <h6>
                          <span>
                            <i data-feather="info" className="feather-edit" />
                          </span>
                          Warehouse Info
                        </h6>
                      </div> */}
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="mb-3">
                            <label className="form-label">
                              Name<span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              required
                              type="text"
                              value={warehouseName}
                              onChange={(e) => setWarehouseName(e.target.value)}
                              className="form-control"
                            />
                          </div>
                        </div>
                        {/* <div className="col-lg-6">
                          <div className="input-blocks">
                            <label>Contact Person <span style={{ color: "red" }}>*</span></label>
                            <Select className="select" onChange={(e) => setContactPerson(e.target.value)} options={options1} />
                          </div>
                        </div> */}
                        {/* <div className="col-lg-6">
                          <div className="mb-3 war-add">
                            <label className="mb-2">Phone Number <span style={{ color: "red" }}>*</span></label>
                            <input
                              onChange={(e) => setPhoneNumber(e.target.value)}
                              className="form-control"
                              id="phone"
                              name="phone"
                              type="text"
                            />
                          </div>
                        </div> */}
                        <div className="col-lg-6">
                          <div className="mb-3">
                            <label className="form-label">
                              Work Phone
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              required
                              type="number"
                              maxLength={10}
                              minLength={10}
                              value={workphone}
                              onChange={(e) => setWorkphone(e.target.value)}
                              className="form-control"
                              placeholder="Enter 10-digit Phone Number"
                            />
                          </div>
                        </div>
                        {/* <div className="col-lg-12">
                          <div className="mb-3">
                            <label className="form-label">Email <span style={{ color: "red" }}>*</span></label>
                            <input
                              type="email"
                              className="form-control"
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </div>
                        </div> */}
                        <div className="modal-title-head">
                          <h6>
                            <span>
                              <i data-feather="map-pin" />
                            </span>
                            Location
                          </h6>
                        </div>
                        <div className="col-lg-12">
                          <div className="mb-3">
                            <label className="form-label">
                              Address 1<span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              required
                              type="text"
                              className="form-control"
                              value={address1}
                              onChange={(e) => setAddress1(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="mb-3">
                            <label className="form-label">Address 2 </label>
                            <input
                              type="text"
                              className="form-control"
                              value={address2}
                              onChange={(e) => setAddress2(e.target.value)}
                            />
                          </div>
                        </div>
                        {/* <div className="col-lg-6">
                          <div className="input-blocks">
                            <label>Country <span style={{ color: "red" }}>*</span></label>
                            <Select
                              className="select"
                              options={options2}
                              onChange={(e) => setCountry(e.target.value)}
                            />
                          </div>
                        </div> */}
                        <div className="col-lg-6">
                          <div className="mb-3">
                            <label className="form-label">
                              State<span style={{ color: "red" }}>*</span>
                            </label>
                            <Select
                              required
                              value={
                                state?.value
                                  ? state
                                  : [
                                      {
                                        label: "Choose",
                                        value: null,
                                      },
                                    ]
                              }
                              onChange={(item) => {
                                setState(item);
                                resetCity();
                              }}
                              className="select"
                              options={stateList}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="mb-3 mb-0">
                            <label className="form-label">
                              City<span style={{ color: "red" }}>*</span>
                            </label>
                            <Select
                              required
                              value={
                                city?.value
                                  ? city
                                  : [
                                      {
                                        label: "Choose",
                                        value: null,
                                      },
                                    ]
                              }
                              className="select"
                              options={cityList}
                              onChange={(item) => setCity(item)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="mb-3 mb-0">
                            <label className="form-label">
                              Zipcode<span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              required
                              type="number"
                              minLength={6}
                              maxLength={6}
                              className="form-control"
                              value={zipcode}
                              onChange={(e) => setZipcode(e.target.value)}
                              placeholder="Enter 6-digit Zipcode"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer-btn">
                        <button
                          type="button"
                          className="btn btn-cancel me-2"
                          data-bs-dismiss="modal"
                          onClick={clearData}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className={`btn btn-submit ${
                            isLoading ? "disabled" : ""
                          }`}
                          onClick={submitData}
                        >
                          Create Warehouse
                        </button>
                      </div>
                    </form>
                    <span style={{ fontStyle: "italic" }}>
                      All fields with <span style={{ color: "red" }}>*</span>{" "}
                      are required
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /Add Warehouse */}
        {/* Edit Warehouse */}
        <div className="modal fade" id="edit-warehouse">
          <div className="modal-dialog modal-dialog-centered custom-modal-two">
            <div className="modal-content">
              <div className="page-wrapper-new p-0">
                <div className="content">
                  <div className="modal-header border-0 custom-modal-header">
                    <div className="page-title">
                      <h4>Edit Warehouse </h4>
                    </div>
                    <button
                      type="button"
                      className="close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={clearData}
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body custom-modal-body">
                    <form>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="mb-3">
                            <label className="form-label">
                              Name <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={warehouseName}
                              onChange={(e) => setWarehouseName(e.target.value)}
                            />
                          </div>
                        </div>
                        {/* <div className="col-lg-6">
                          <div className="input-blocks">
                            <label>Contact Person <span style={{ color: "red" }}>*</span></label>
                            <Select className="select" options={options1} />
                          </div>
                        </div> */}
                        {/* <div className="col-lg-6">
                          <div className="mb-3 war-edit-phone">
                            <label className="mb-2">Phone Number <span style={{ color: "red" }}>*</span></label>
                            <input
                              className="form-control"
                              id="phone2"
                              name="phone"
                              type="text"
                            />
                          </div>
                        </div> */}
                        <div className="col-lg-6">
                          <div className="mb-3">
                            <label className="form-label">
                              Work Phone <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              type="text"
                              value={workphone}
                              onChange={(e) => setWorkphone(e.target.value)}
                              className="form-control"
                            />
                          </div>
                        </div>
                        {/* <div className="col-lg-12">
                          <div className="mb-3">
                            <label className="form-label">Email <span style={{ color: "red" }}>*</span></label>
                            <input
                              type="email"
                              className="form-control"
                              // defaultValue="stevenlegendary@example.com"
                            />
                          </div>
                        </div> */}
                        <div className="modal-title-head">
                          <h6>
                            <span>
                              <i data-feather="map-pin" />
                            </span>
                            Location
                          </h6>
                        </div>
                        <div className="col-lg-12">
                          <div className="mb-3">
                            <label className="form-label">
                              Address 1 <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={address1}
                              onChange={(e) => setAddress1(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="mb-3">
                            <label className="form-label">Address 2 </label>
                            <input
                              type="text"
                              className="form-control"
                              value={address2}
                              onChange={(e) => setAddress2(e.target.value)}
                            />
                          </div>
                        </div>
                        {/* <div className="col-lg-6">
                          <div className="input-blocks">
                            <label>Country <span style={{ color: "red" }}>*</span></label>
                            <Select className="select" options={options2} />
                          </div>
                        </div> */}
                        <div className="col-lg-6">
                          <div className="mb-3">
                            <label className="form-label">
                              State <span style={{ color: "red" }}>*</span>
                            </label>
                            <Select
                              value={
                                state?.value
                                  ? state
                                  : [
                                      {
                                        label: "Choose",
                                        value: null,
                                      },
                                    ]
                              }
                              onChange={(item) => {
                                setState(item);
                                resetCity();
                              }}
                              className="select"
                              options={stateList}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="mb-3 mb-0">
                            <label className="form-label">
                              City <span style={{ color: "red" }}>*</span>
                            </label>
                            <Select
                              value={
                                city?.value
                                  ? city
                                  : [
                                      {
                                        label: "Choose",
                                        value: null,
                                      },
                                    ]
                              }
                              className="select"
                              options={cityList}
                              onChange={(item) => setCity(item)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="mb-3 mb-0">
                            <label className="form-label">
                              Zipcode <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={zipcode}
                              onChange={(e) => setZipcode(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        style={{ width: "fit-content" }}
                        className="status-toggle modal-status d-inline-flex align-items-center"
                      >
                        <span
                          className="status-label"
                          style={{ marginRight: 20 }}
                        >
                          Status
                        </span>
                        <input
                          value={warehouseStatus}
                          checked={warehouseStatus}
                          type="checkbox"
                          id="user2"
                          className="check"
                          onChange={() => {
                            setWarehouseStatus(!warehouseStatus);
                          }}
                        />
                        <label htmlFor="user2" className="checktoggle" />
                      </div>
                      <div className="modal-footer-btn">
                        <button
                          type="button"
                          className="btn btn-cancel me-2"
                          data-bs-dismiss="modal"
                          onClick={clearData}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className={`btn btn-submit ${
                            isLoading ? "disabled" : ""
                          }`}
                          onClick={saveEditedWarehouse}
                        >
                          Save Changes
                        </button>
                      </div>
                    </form>
                    <span style={{ fontStyle: "italic" }}>
                      All fields with <span style={{ color: "red" }}>*</span>{" "}
                      are required
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WareHouses;
