/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { all_routes } from "../../Router/all_routes";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { Link } from "react-router-dom";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import {
  Edit,
  Grid,
  List,
  MoreVertical,
  PlusCircle,
  RotateCcw,
  Trash2,
} from "feather-icons-react/build/IconComponents";
import {
  detailData,
  employeesData,
  setToogleHeader,
} from "../../core/redux/action";
import {
  ChevronUp,
  Filter,
  Sliders,
  StopCircle,
  User,
  Users,
} from "react-feather";
import Select from "react-select";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import axios from "axios";
import { API_URL } from "../../constants/AppConstants";
import { fetchEmployeesData } from "../../utils/utilities";

const EmployeesGrid = () => {
  const route = all_routes;
  const login_data = useSelector((state) => state.login_data);
  const dispatch = useDispatch();
  const data = useSelector((state) => state.toggle_header);
  const employees_data = useSelector((state) => state.employees_data);
  const selected_store = useSelector((state) => state.selected_store);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchEmployeesData(login_data, selected_store[0]);
  }, [selected_store]);

  useEffect(() => {
    setFilteredData(employees_data);
  }, [employees_data]);

  useEffect(() => {
    if (filteredData) setIsLoading(false);
  }, [filteredData]);

  const handleSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchTerm(searchTerm);
    const filteredEmployees = employees_data.filter(
      (employee) =>
        employee.firstName.toLowerCase().includes(searchTerm) ||
        employee.lastName.toLowerCase().includes(searchTerm) ||
        employee.empCode.toLowerCase().includes(searchTerm) ||
        employee.contact.toLowerCase().includes(searchTerm) ||
        employee.email.toLowerCase().includes(searchTerm)
    );
    setFilteredData(filteredEmployees);
  };

  const oldandlatestvalue = [
    { value: "date", label: "Sort by Date" },
    // { value: "newest", label: "Newest" },
    // { value: "oldest", label: "Oldest" },
  ];
  const status = [
    { value: "Choose Status", label: "Choose Status" },
    // { value: "Active", label: "Active" },
    // { value: "Inactive", label: "Inactive" },
  ];

  const names = [{ value: "Choose Name", label: "Choose Name" }];

  const renderTooltip = (props) => (
    <Tooltip id="pdf-tooltip" {...props}>
      Pdf
    </Tooltip>
  );
  const renderExcelTooltip = (props) => (
    <Tooltip id="excel-tooltip" {...props}>
      Excel
    </Tooltip>
  );
  const renderPrinterTooltip = (props) => (
    <Tooltip id="printer-tooltip" {...props}>
      Printer
    </Tooltip>
  );
  const renderRefreshTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Refresh
    </Tooltip>
  );
  const renderCollapseTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Collapse
    </Tooltip>
  );
  const MySwal = withReactContent(Swal);
  const showConfirmationAlert = () => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes, delete it!",
      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        MySwal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          className: "btn btn-success",
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      } else {
        MySwal.close();
      }
    });
  };

  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Employees</h4>
                <h6>Manage your employees</h6>
              </div>
            </div>
            <ul className="table-top-head">
              {/* <li>
                <OverlayTrigger placement="top" overlay={renderTooltip}>
                  <Link>
                    <ImageWithBasePath
                      src="assets/img/icons/pdf.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li> */}
              {/* <li>
                <OverlayTrigger placement="top" overlay={renderExcelTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top">
                    <ImageWithBasePath
                      src="assets/img/icons/excel.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li> */}
              {/* <li>
                <OverlayTrigger placement="top" overlay={renderPrinterTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top">
                    <i data-feather="printer" className="feather-printer" />
                  </Link>
                </OverlayTrigger>
              </li> */}
              <li>
                <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top">
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderCollapseTooltip}>
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    id="collapse-header"
                    className={data ? "active" : ""}
                    onClick={() => {
                      dispatch(setToogleHeader(!data));
                    }}
                  >
                    <ChevronUp />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
            <div className="page-btn">
              <Link to={route.addemployee} className="btn btn-added">
                <PlusCircle className="me-2" />
                Add New Employee
              </Link>
            </div>
          </div>
          {/* /Employees List */}
          <div className="card">
            <div className="card-body pb-0">
              <div className="table-top table-top-two table-top-new">
                <div className="search-set mb-0">
                  <div className="total-employees">
                    <h6>
                      <Users />
                      Total Employees
                      <span>{employees_data?.length}</span>
                    </h6>
                  </div>
                  <div className="search-input">
                    <input
                      type="search"
                      className="form-control"
                      placeholder="Search employees..."
                      value={searchTerm}
                      onChange={handleSearch}
                    />
                  </div>
                </div>
                {/* <div className="search-path d-flex align-items-center search-path-new">
                  <div className="d-flex">
                    <Link className="btn btn-filter" id="filter_search">
                      <Filter
                        className="filter-icon"
                        // onClick={toggleFilterVisibility}
                      />
                      <span>
                        <ImageWithBasePath
                          src="assets/img/icons/closes.svg"
                          alt="img"
                        />
                      </span>
                    </Link>
                    <Link to={route.employeelist} className="btn-list">
                      <List />
                    </Link>
                    <Link to={route.employeegrid} className="btn-grid active">
                      <Grid />
                    </Link>
                  </div>
                </div> */}
              </div>
              {/* /Filter */}
              <div
                className={`card${isFilterVisible ? " visible" : ""}`}
                id="filter_inputs"
                style={{ display: isFilterVisible ? "block" : "none" }}
              >
                <div className="card-body pb-0">
                  <div className="row">
                    <div className="col-lg-3 col-sm-6 col-12">
                      <div className="input-blocks">
                        <User className="info-img" />
                        <Select
                          className="select"
                          options={names}
                          placeholder="Choose Name"
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-12">
                      <div className="input-blocks">
                        <StopCircle className="info-img" />

                        <Select
                          className="select"
                          options={status}
                          placeholder="Choose Status"
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-12 ms-auto">
                      <div className="input-blocks">
                        <Link className="btn btn-filters ms-auto">
                          {" "}
                          <i
                            data-feather="search"
                            className="feather-search"
                          />{" "}
                          Search{" "}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /Filter */}
            </div>
          </div>
          {/* /Employees List */}
          <div className="employee-grid-widget">
            {!isLoading ? (
              <div className="row">
                {filteredData?.map((data, index) => {
                  return (
                    <div
                      className="col-xxl-3 col-xl-4 col-lg-6 col-md-6"
                      key={index}
                    >
                      <div className="employee-grid-profile">
                        <div className="profile-head">
                          <label className="checkboxs">
                            {/* <input type="checkbox" />
                              <span className="checkmarks" /> */}
                          </label>
                          <div className="profile-head-action">
                            {data.status == "Active" ? (
                              <span className="badge badge-linesuccess text-center w-auto me-1">
                                Active
                              </span>
                            ) : (
                              <span className="badge badge-linedanger text-center w-auto me-1">
                                InActive
                              </span>
                            )}
                            <div className="dropdown profile-action">
                              <Link
                                to="#"
                                className="action-icon dropdown-toggle"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <MoreVertical />
                              </Link>
                              <ul className="dropdown-menu">
                                <li>
                                  <Link
                                    to={route.editemployee}
                                    className="dropdown-item"
                                    onClick={() => {
                                      dispatch(
                                        detailData({
                                          type: "Employees",
                                          data,
                                        })
                                      );
                                    }}
                                  >
                                    <Edit className="info-img" />
                                    View/Edit
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="#"
                                    className="dropdown-item confirm-text mb-0"
                                    onClick={showConfirmationAlert}
                                  >
                                    <Trash2 className="info-img" />
                                    Delete
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="profile-info">
                          <div className="profile-pic active-profile">
                            <img src={data.profileImage} alt="" />
                          </div>
                          <h5>EMP ID : {data.empCode}</h5>
                          <h4>{data.firstName + " " + data.lastName}</h4>
                          <span>{data?.designation?.label}</span>
                        </div>
                        <ul className="department">
                          <li style={{ minWidth: "50%" }}>
                            Joined
                            <span>{data.joiningDate.split("T")[0]}</span>
                          </li>
                          <li>
                            Location
                            <span>
                              {`${data.city},${data.state}`.length > 12
                                ? `${(data.city + "," + data.state).slice(
                                    0,
                                    11
                                  )}...`
                                : `${data.city},${data.state}`}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <></>
            )}
          </div>
          {/* <div className="container-fluid">
            <div className="row custom-pagination">
              <div className="col-md-12">
                <div className="paginations d-flex justify-content-end mb-3">
                  <span>
                    <i className="fas fa-chevron-left" />
                  </span>
                  <ul className="d-flex align-items-center page-wrap">
                    <li>
                      <Link to="#" className="active">
                        1
                      </Link>
                    </li>
                    <li>
                      <Link to="#">2</Link>
                    </li>
                    <li>
                      <Link to="#">3</Link>
                    </li>
                    <li>
                      <Link to="#">4</Link>
                    </li>
                  </ul>
                  <span>
                    <i className="fas fa-chevron-right" />
                  </span>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default EmployeesGrid;
