/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { API_URL } from "../../../constants/AppConstants.js";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { alertData, rolesData } from "../../redux/action";

const AddRole = () => {
  const login_data = useSelector((state) => state.login_data);
  const [roleName, setRoleName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const selected_store = useSelector((state) => state.selected_store);

  function clearData() {
    setRoleName("");
  }

  async function fetchData() {
    try {
      let response = await axios.get(API_URL + "roles_perm");
      if (response.status === 200) {
        dispatch(rolesData(response.data));
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function submitData() {
    let dataBody = {
      roleName,
      storeId: selected_store[0]?._id,
      createdOn: new Date().toLocaleDateString().split(",")[0],
      userCode: login_data?.empCode,
      itemActive: true,
    };

    try {
      setIsLoading(true);
      let response = await axios.post(API_URL + "roles_perm", dataBody);
      if (response.status === 200) {
        dispatch(
          alertData({
            type: "success",
            msg: "Role added successfully!",
          })
        );
        setTimeout(() => {
          setIsLoading(false);
          document?.querySelector("#add-role .close")?.click();
        }, 1000);
        fetchData();
        clearData();
      }
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <div>
      {/* Add Role */}
      <div className="modal fade" id="add-role">
        <div className="modal-dialog modal-dialog-centered custom-modal-two">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>Create Role</h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={clearData}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <form>
                    <div className="mb-0">
                      <label className="form-label">Role Name</label>
                      <input
                        type="text"
                        className="form-control"
                        value={roleName}
                        onChange={(e) => setRoleName(e.target.value)}
                      />
                    </div>
                    <div className="modal-footer-btn">
                      <button
                        type="button"
                        className="btn btn-cancel me-2"
                        data-bs-dismiss="modal"
                        onClick={clearData}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className={`btn btn-submit ${
                          isLoading ? "disabled" : ""
                        }`}
                        onClick={submitData}
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Add Role */}
    </div>
  );
};

export default AddRole;
