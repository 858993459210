/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
// import { PlusCircle } from 'feather-icons-react/build/IconComponents'
import { API_URL } from "../../../constants/AppConstants";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { alertData, brandData } from "../../redux/action";

const AddTransport = (props) => {
  const selected_store = useSelector((state) => state.selected_store);
  const login_data = useSelector((state) => state.login_data);
  const [brandName, setBrandName] = useState("");
  const [brandStatus, setBrandstatus] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  async function fetchData() {
    try {
      let response = await axios.get(API_URL + "brands");
      if (response.status === 200) {
        dispatch(brandData(response.data));
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function submitData() {
    setIsLoading(true);
    let dataBody = {
      brandName,
      brandStatus,
      storeId: selected_store[0]?._id,
      createdOn: new Date().toLocaleDateString().split(",")[0],
      userCode: login_data?.empCode,
      itemActive: true,
    };

    try {
      let response = await axios.post(API_URL + "brands", dataBody);
      if (response.status === 200) {
        dispatch(
          alertData({
            type: "success",
            msg: "Brand added successfully!",
          })
        );
        setTimeout(() => {
          setIsLoading(false);
          document?.querySelector("#add-brand .close")?.click();
        }, 1000);
        setBrandName("");
        fetchData();
      }
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <>
      {/* Add Brand */}
      <div className="modal fade" id="add-brand">
        <div className="modal-dialog modal-dialog-centered custom-modal-two">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>Create Brand</h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    data-bs-toggle={props.from ? "modal" : ""}
                    data-bs-target={props.from ? `${props.target}` : ""}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body new-employee-field">
                  <form>
                    <div className="mb-3">
                      <label className="form-label">Brand</label>
                      <input
                        type="text"
                        value={brandName}
                        className="form-control"
                        onChange={(e) => setBrandName(e.target.value)}
                      />
                    </div>
                    {/* <label className="form-label">Logo</label>
                                        <div className="profile-pic-upload mb-3">
                                            <div className="profile-pic brand-pic">
                                                <span>
                                                    <PlusCircle className="plus-down-add"/>
                                                    Add Image
                                                </span>
                                            </div>
                                            <div className="image-upload mb-0">
                                                <input type="file" />
                                                <div className="image-uploads">
                                                    <h4>Change Image</h4>
                                                </div>
                                            </div>
                                        </div> */}
                    <div className="mb-0">
                      <div className="status-toggle modal-status d-flex justify-content-between align-items-center">
                        <span className="status-label">Status</span>
                        <input
                          disabled
                          value={brandStatus}
                          onChange={(e) => setBrandstatus(e.target.value)}
                          type="checkbox"
                          id="user2"
                          className="check"
                          defaultChecked="true"
                        />
                        <label htmlFor="user2" className="checktoggle" />
                      </div>
                    </div>
                    <div className="modal-footer-btn">
                      <button
                        type="button"
                        className="btn btn-cancel me-2"
                        data-bs-dismiss="modal"
                        data-bs-toggle={props.from ? "modal" : ""}
                        data-bs-target={props.from ? `${props.target}` : ""}
                      >
                        Cancel
                      </button>
                      <div
                        className={`btn btn-submit ${
                          isLoading ? "disabled" : ""
                        }`}
                        onClick={submitData}
                      >
                        Create Brand
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Add Brand */}
    </>
  );
};

export default AddTransport;
