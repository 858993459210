// export const product_list = () => ({ type: "Product_list" });
// export const set_product_list = (payload) => ({
//   type: "Product_list",
//   payload,
// });

// export const dashboard_recentproduct = () => ({
//   type: "Dashbaord_RecentProduct",
// });
// export const setdashboard_recentproduct = (payload) => ({
//   type: "Dashbaord_RecentProduct",
//   payload,
// });
// export const dashboard_expiredproduct = () => ({
//   type: "Dashbaord_ExpiredProduct",
// });
// export const setdashboard_expiredproduct = (payload) => ({
//   type: "Dashbaord_ExpiredProduct",
//   payload,
// });
// export const saleshdashboard_recenttransaction = () => ({
//   type: "Salesdashbaord_ExpiredProduct",
// });
// export const setsaleshdashboard_recenttransaction = (payload) => ({
//   type: "Salesdashbaord_ExpiredProduct",
//   payload,
// });
// export const brand_list = () => ({ type: "Brand_list" });
// export const setbrand_list = (payload) => ({
//   type: "Brand_list",
//   payload,
// });
// export const unit_data = () => ({ type: "Unit_Data" });
// export const setunit_data = (payload) => ({
//   type: "Unit_Data",
//   payload,
// });
// export const variantattributes_data = () => ({ type: "Variantattribute_Data" });
// export const setvariantattributes_data = (payload) => ({
//   type: "Variantattribute_Data",
//   payload,
// });
// export const warranty_data = () => ({ type: "Warranty_Data" });
// export const setwarranty_data = (payload) => ({
//   type: "Warranty_Data",
//   payload,
// });
// export const barcode_data = () => ({ type: "Barcode_Data" });
// export const setbarcode_data = (payload) => ({
//   type: "Barcode_Data",
//   payload,
// });
// export const departmentlist_data = () => ({ type: "Department_Data" });
// export const setdepartmentlist_data = (payload) => ({
//   type: "Department_Data",
//   payload,
// });
// export const designation_data = () => ({ type: "Designation_Data" });
// export const setdesignation_data = (payload) => ({
//   type: "Designation_Data",
//   payload,
// });
// export const shiftlist_data = () => ({ type: "Shiftlist_Data" });
// export const setshiftlist_data = (payload) => ({
//   type: "Shiftlist_Data",
//   payload,
// });
// export const attendenceemployee_data = () => ({
//   type: "Attendenceemployee_Data",
// });
// export const setattendenceemployee_data = (payload) => ({
//   type: "Attendenceemployee_Data",
//   payload,
// });
// export const toogleHeader_data = () => ({ type: "toggle_header" });
// export const setToogleHeader = (payload) => ({
//   type: "toggle_header",
//   payload,
// });
// export const invoicereport_data = () => ({ type: "Invoicereport_Data" });
// export const setinvoicereport_data = (payload) => ({
//   type: "Invoicereport_Data",
//   payload,
// });
// export const salesreturns_data = () => ({ type: "Salesreturns_Data" });
// export const setsalesreturns_data = (payload) => ({
//   type: "Salesreturns_Data",
//   payload,
// });
// export const quotationlist_data = () => ({ type: "Quatation_Data" });
// export const setquotationlist_data = (payload) => ({
//   type: "Quatation_Data",
//   payload,
// });
// export const customer_data = () => ({ type: "customer_data" });
// export const setcustomer_data = (payload) => ({
//   type: "customer_data",
//   payload,
// });
// export const userlist_data = () => ({ type: "Userlist_data" });
// export const setuserlist_data = (payload) => ({
//   type: "Userlist_data",
//   payload,
// });
// export const rolesandpermission_data = () => ({
//   type: "Rolesandpermission_data",
// });
// export const setrolesandpermission_data = (payload) => ({
//   type: "Rolesandpermission_data",
//   payload,
// });
// export const deleteaccount_data = () => ({ type: "Deleteaccount_data" });
// export const setdeleteaccount_data = (payload) => ({
//   type: "Deleteaccount_data",
//   payload,
// });
// export const attendanceadmin_data = () => ({ type: "Attendenceadmin_data" });
// export const setattendanceadmin_data = (payload) => ({
//   type: "Attendenceadmin_data",
//   payload,
// });
// export const leavesadmin_data = () => ({ type: "Leavesadmin_data" });
// export const setleavesadmin_data = (payload) => ({
//   type: "Leavesadmin_data",
//   payload,
// });
// export const leavetypes_data = () => ({ type: "Leavestype_data" });
// export const setleavetypes_data = (payload) => ({
//   type: "Leavestype_data",
//   payload,
// });
// export const holiday_data = () => ({ type: "Holiday_data" });
// export const setholiday_data = (payload) => ({
//   type: "Holiday_data",
//   payload,
// });
// export const expiredproduct_data = () => ({ type: "Expiredproduct_data" });
// export const setexpiredproduct_data = (payload) => ({
//   type: "Expiredproduct_data",
//   payload,
// });
// export const lowstock_data = () => ({ type: "Lowstock_data" });
// export const setlowstock_data = (payload) => ({
//   type: "Lowstock_data",
//   payload,
// });
// export const categotylist_data = () => ({ type: "Categotylist_data" });
// export const setcategotylist_data = (payload) => ({
//   type: "Categotylist_data",
//   payload,
// });

export const setToogleHeader = (payload) => ({
  type: "toggle_header",
  payload,
});

export const setLayoutChange = (payload) => ({
  type: "Layoutstyle_data",
  payload,
});

export const loginUserData = (payload) => ({
  type: "Login_User_Data",
  payload,
});

export const selectStore = (payload) => ({
  type: "selected_store",
  payload,
});

export const storesData = (payload) => ({
  type: "Stores_Data",
  payload,
});

export const brandData = (payload) => ({
  type: "Brand_Data",
  payload,
});

export const accountsData = (payload) => ({
  type: "Accounts_Data",
  payload,
});

export const accountsDataLength = (payload) => ({
  type: "Accounts_Data_Length",
  payload,
});

export const receiptData = (payload) => ({
  type: "Receipt_Data",
  payload,
});

export const receiptDataLength = (payload) => ({
  type: "Receipt_Data_Length",
  payload,
});

export const paymentData = (payload) => ({
  type: "Payment_Data",
  payload,
});

export const paymentDataLength = (payload) => ({
  type: "Payment_Data_Length",
  payload,
});

export const bankData = (payload) => ({
  type: "Bank_Data",
  payload,
});

export const bankDataLength = (payload) => ({
  type: "Bank_Data_Length",
  payload,
});

export const categoryData = (payload) => ({
  type: "Category_Data",
  payload,
});

export const subCategoryData = (payload) => ({
  type: "Sub_Category_Data",
  payload,
});

export const warehouseData = (payload) => ({
  type: "Warehouse_Data",
  payload,
});

export const supplierData = (payload) => ({
  type: "Supplier_Data",
  payload,
});

export const customerData = (payload) => ({
  type: "Customer_Data",
  payload,
});

export const productsData = (payload) => ({
  type: "Products_Data",
  payload,
});

export const salesData = (payload) => ({
  type: "Sales_Data",
  payload,
});

export const salesDataLength = (payload) => ({
  type: "Sales_Data_Length",
  payload,
});

export const salesReturnData = (payload) => ({
  type: "Sales_Return_Data",
  payload,
});

export const saleQuotationData = (payload) => ({
  type: "Sale_Quotation_Data",
  payload,
});

export const saleQuotationDataLength = (payload) => ({
  type: "Sale_Quotation_Data_Length",
  payload,
});

export const saleBookingData = (payload) => ({
  type: "Sale_Booking_Data",
  payload,
});

export const saleBookingDataLength = (payload) => ({
  type: "Sale_Booking_Data_Length",
  payload,
});

export const purchaseQuotationData = (payload) => ({
  type: "Purchase_Quotation_Data",
  payload,
});

export const purchaseQuotationDataLength = (payload) => ({
  type: "Purchase_Quotation_Data_Length",
  payload,
});

export const purchaseBookingData = (payload) => ({
  type: "Purchase_Booking_Data",
  payload,
});

export const purchaseBookingDataLength = (payload) => ({
  type: "Purchase_Booking_Data_Length",
  payload,
});

export const purchaseData = (payload) => ({
  type: "Purchase_Data",
  payload,
});

export const purchaseDataLength = (payload) => ({
  type: "Purchase_Data_Length",
  payload,
});

export const purchaseReturnData = (payload) => ({
  type: "Purchase_Return_Data",
  payload,
});

export const transportationData = (payload) => ({
  type: "Transportation_Data",
  payload,
});

export const stocksData = (payload) => ({
  type: "Stocks_Data",
  payload,
});

export const joyrideData = (payload) => ({
  type: "Joyride_Data",
  payload,
});

export const expenseCategoryData = (payload) => ({
  type: "Expense_Category_Data",
  payload,
});

export const expenseData = (payload) => ({
  type: "Expense_Data",
  payload,
});

export const employeesData = (payload) => ({
  type: "Employees_Data",
  payload,
});

export const employeesDataLength = (payload) => ({
  type: "Employees_Data_Length",
  payload,
});

export const departmentData = (payload) => ({
  type: "Department_Data",
  payload,
});

export const designationData = (payload) => ({
  type: "Designation_Data",
  payload,
});

export const rolesData = (payload) => ({
  type: "Roles_Data",
  payload,
});

export const usersData = (payload) => ({
  type: "Users_Data",
  payload,
});

export const alertData = (payload) => ({
  type: "Alert_Data",
  payload,
});

export const detailData = (payload) => ({
  type: "Detail_Data",
  payload,
});

export const resetData = () => ({
  type: "Reset_Data",
});

export const loaderData = (payload) => ({
  type: "Loader_Data",
  payload,
});
