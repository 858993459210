// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.receipt-user-selection-modal .ant-modal-footer {
  display: none;
}
.receipt-user-selection-modal .ant-modal-body {
  padding: 30px;
}
.receipt-user-selection-modal .ant-modal-body label {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
}
.receipt-user-selection-modal .ant-modal-body span {
  font-size: 16px;
  font-weight: 600;
  margin-left: 10px;
}

.popup-button span {
  color: #FF9F43;
}

.popup-button:hover {
  background: rgba(255, 159, 67, 0.1019607843);
}`, "",{"version":3,"sources":["webpack://./src/feature-module/Transfers/Receipts.scss"],"names":[],"mappings":"AACI;EACI,aAAA;AAAR;AAEI;EACI,aAAA;AAAR;AACQ;EACI,eAAA;EACA,gBAAA;EACA,mBAAA;AACZ;AACQ;EACI,eAAA;EACA,gBAAA;EACA,iBAAA;AACZ;;AAIA;EACI,cAAA;AADJ;;AAIA;EACI,4CAAA;AADJ","sourcesContent":[".receipt-user-selection-modal {\n    .ant-modal-footer {\n        display: none;\n    }\n    .ant-modal-body {\n        padding: 30px;\n        label {\n            font-size: 18px;\n            font-weight: 600;\n            margin-bottom: 10px;\n        }\n        span {\n            font-size: 16px;\n            font-weight: 600;\n            margin-left: 10px;\n        }\n    }\n}\n\n.popup-button span {\n    color: #FF9F43;\n}\n\n.popup-button:hover {\n    background: #FF9F431a;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
