/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from "react";

function ViewSales({
  viewSalesDetailData,
  setViewSalesDetailData = () => {},
  type,
}) {
  return (
    <div className="modal fade" id="sales-details-new">
      <div className="modal-dialog sales-details-modal">
        <div className="modal-content">
          <div className="page-wrapper details-blk">
            <div className="content p-0">
              <div className="modal-header border-0 custom-modal-header">
                <div className="page-title">
                  <h4>
                    Sale {type == "booking" ? "Booking" : ""} Detail: #
                    {viewSalesDetailData?.invoiceNumber}
                  </h4>
                </div>
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setViewSalesDetailData({});
                  }}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="card">
                <div className="card-body">
                  <form>
                    <div className="flex">
                      <div className="inline-flex mr-50">
                        <h5 className="order-text">Customer: </h5>
                        <h5 className="order-subtext ml-10">
                          {viewSalesDetailData?.customer?.label}
                        </h5>
                      </div>
                      <div className="inline-flex">
                        <h5 className="order-text">Sale Date: </h5>
                        <h5 className="order-subtext ml-10">
                          {new Date(viewSalesDetailData?.date).toDateString()}
                        </h5>
                      </div>
                    </div>
                    <div
                      className="reference-box table-height"
                      style={{
                        maxWidth: 1600,
                        width: "100%",
                        overflow: "auto",
                        padding: 0,
                        fontSize: 14,
                        lineHeight: 24,
                        color: "#555",
                      }}
                    >
                      <h5 className="order-text">Order Summary</h5>
                      <div className="table-responsive no-pagination">
                        <table className="table  datanew">
                          <thead>
                            <tr>
                              <th>Product</th>
                              <th>Unit</th>
                              <th>Qty</th>
                              <th>Alt. Qty</th>
                              <th>Price(₹)</th>
                              <th>Discount</th>
                              <th>Tax(%)</th>
                              <th>Tax Amount(₹)</th>
                              <th>Unit Cost(₹)</th>
                              <th>Total Cost(₹)</th>
                            </tr>
                          </thead>
                          <tbody>
                            {viewSalesDetailData?.productsList &&
                              viewSalesDetailData?.productsList?.map(
                                (item, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>{item?.productName}</td>
                                      <td>{item?.selectedUnit?.label}</td>
                                      <td>{item?.selectedQty}</td>
                                      <td>{item?.productAlternateQty}</td>
                                      <td>{item?.purchasePrice}</td>
                                      <td>
                                        {item?.productDiscountValue +
                                          (item?.productDiscountType?.label ==
                                          "Cash"
                                            ? "₹"
                                            : "%")}
                                      </td>
                                      <td>{item?.productTaxValue}</td>
                                      <td>{item?.productCurrentTax}</td>
                                      <td>{item?.productUnitCost}</td>
                                      <td>{item?.totalCost}</td>
                                    </tr>
                                  );
                                }
                              )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="row justify-content-end mb-30">
                      <div className="row justify-content-end">
                        <div className="col-lg-6">
                          <div className="total-order w-100 max-widthauto m-auto mb-4">
                            <ul>
                              <li>
                                <h4>Total Tax(₹)</h4>
                                <h5>
                                  {viewSalesDetailData?.orderTax
                                    ? viewSalesDetailData?.orderTax
                                    : 0}
                                </h5>
                              </li>
                              <li>
                                <h4>Discount(₹)</h4>
                                <h5>
                                  {viewSalesDetailData?.discount
                                    ? viewSalesDetailData?.discount
                                    : 0}
                                </h5>
                              </li>
                              <li>
                                <h4>Shipping(₹)</h4>
                                <h5>
                                  {viewSalesDetailData?.shipping
                                    ? viewSalesDetailData?.shipping
                                    : 0}
                                </h5>
                              </li>
                              <li>
                                <h4>Grand Total(₹)</h4>
                                <h5>{viewSalesDetailData?.grandTotal}</h5>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex">
                      <div className="inline-flex mr-50">
                        <h5 className="order-text">Due Amount(₹):</h5>
                        <h5 className="order-subtext ml-10">
                          {Number(viewSalesDetailData?.dueAmount) >= 0
                            ? Number(viewSalesDetailData?.dueAmount).toFixed(2)
                            : "-"}
                        </h5>
                      </div>
                      <div className="inline-flex mr-50">
                        <h5 className="order-text">Due Date:</h5>
                        <h5 className="order-subtext ml-10">
                          {new Date(
                            viewSalesDetailData?.dueDate
                          ).toDateString()}
                        </h5>
                      </div>
                      <div className="inline-flex">
                        <h5 className="order-text">Received Amount(₹):</h5>
                        <h5 className="order-subtext ml-10">
                          {Number(viewSalesDetailData?.receivedAmount).toFixed(
                            2
                          )}
                        </h5>
                      </div>
                    </div>

                    <div className="flex">
                      {type == "Sale" ? (
                        <div className="inline-flex mr-50">
                          <h5 className="order-text">Sale Status:</h5>
                          <h5 className="order-subtext ml-10">
                            {viewSalesDetailData?.salesStatus?.label}
                          </h5>
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="inline-flex mr-50">
                        <h5 className="order-text">Payment Status:</h5>
                        <h5 className="order-subtext ml-10">
                          {viewSalesDetailData?.paymentStatus?.value
                            ? viewSalesDetailData?.paymentStatus?.label
                            : ""}
                        </h5>
                      </div>
                      <div className="inline-flex mr-50">
                        <h5 className="order-text">Payment Method:</h5>
                        <h5 className="order-subtext ml-10">
                          {viewSalesDetailData?.paymentMethod?.value
                            ? viewSalesDetailData?.paymentMethod?.label
                            : ""}
                        </h5>
                      </div>
                      {type == "sale" ? (
                        <div className="inline-flex">
                          <h5 className="order-text">Transportation Added:</h5>
                          <h5 className="order-subtext ml-10">
                            {viewSalesDetailData?.transportDetail
                              ? "Yes"
                              : "No"}
                          </h5>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewSales;
