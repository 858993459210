/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  ChevronUp,
  Eye,
  PlusCircle,
  Edit,
  RotateCcw,
} from "feather-icons-react/build/IconComponents";
import { loaderData, setToogleHeader } from "../../core/redux/action";
import { useDispatch, useSelector } from "react-redux";
import { Box, Filter, Sliders, StopCircle, User } from "react-feather";
import Select from "react-select";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import Table from "../../core/pagination/datatable";
import axios from "axios";
import { API_URL } from "../../constants/AppConstants";
import { quotationData, updatePage } from "../../core/redux/action";
import { Download } from "react-feather";
import { Modal } from "antd";
import PrintPreview from "../../common/PrintPreview";
import PrintLayout from "../../common/PrintLayout";
import CSS from "../../core/modals/PreviewModal.module.scss";
import { fetchSaleBookingData } from "../../utils/utilities";
import AddSaleBooking from "../../core/modals/sales/AddSaleBooking";
import EditSales from "./EditSales";
import ViewSales from "./ViewSales";

const SaleBookingList = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.toggle_header);
  const quotation_data = useSelector((state) => state.sale_booking_data);
  const login_data = useSelector((state) => state.login_data);
  const selected_store = useSelector((state) => state.selected_store);

  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const toggleFilterVisibility = () => {
    setIsFilterVisible((prevVisibility) => !prevVisibility);
  };
  const [showPdfModal, setShowPdfModal] = useState(false);
  const [pdfData, setPdfData] = useState({});
  const [selectedQuotationId, setSelectedQuotationId] = useState(null);
  const [selectedQuotation, setSelectedQuotation] = useState(null);
  const [filteredList, setFilteredList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const [selectedDateFilterData, setSelectedDateFilterData] = useState("");
  const [selectedCustomerFilterData, setSelectedCustomerFilterData] =
    useState("");
  const [customerFilterArr, setCustomerFilterArr] = useState([]);
  const [viewSalesDetailData, setViewSalesDetailData] = useState({});
  const [selectedSaleDetailData, setSelectedSaleDetailData] = useState("");

  const [selectedSaleStatusFilterData, setSelectedSaleStatusFilterData] =
    useState("");
  const [selectedPaymentStatusFilterData, setSelectedPaymentStatusFilterData] =
    useState("");
  const [selectedBookingStatusFilterData, setSelectedBookingStatusFilterData] =
    useState("");

  const oldandlatestvalue = [
    { value: "date", label: "Sort by Date" },
    // { value: 'newest', label: 'Newest' },
    // { value: 'oldest', label: 'Oldest' },
  ];
  const products = [
    { value: "Choose product", label: "Choose product" },
    // { value: 'Bold V3.2', label: 'Bold V3.2' },
    // { value: 'Apple Series 5 Watch', label: 'Apple Series 5 Watch' },
  ];
  const status = [
    { value: "Choose product", label: "Choose Status" },
    { value: "Ordered", label: "Ordered" },
    { value: "Sent", label: "Sent" },
  ];
  const customername = [
    { value: "Choose Custmer", label: "Choose Customer" },
    // { value: 'walk-in-customer', label: 'walk-in-customer' },
    // { value: 'John Smith', label: 'John Smith' },
  ];

  const renderTooltip = (props) => (
    <Tooltip id="pdf-tooltip" {...props}>
      Pdf
    </Tooltip>
  );
  const renderExcelTooltip = (props) => (
    <Tooltip id="excel-tooltip" {...props}>
      Excel
    </Tooltip>
  );
  const renderPrinterTooltip = (props) => (
    <Tooltip id="printer-tooltip" {...props}>
      Printer
    </Tooltip>
  );
  const renderRefreshTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Refresh
    </Tooltip>
  );
  const renderCollapseTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Collapse
    </Tooltip>
  );

  const columns = [
    {
      title: "Product Name",
      dataIndex: "productsList",
      render: (data) =>
        data[0]?.productName +
        (data?.length > 1 ? ` +${data?.length - 1}More` : ""),
      //   sorter: (a, b) => a.productname.length - b.productname.length,
    },
    {
      title: "Invoice",
      dataIndex: "invoiceNumber",
      //   sorter: (a, b) => a.reference.length - b.reference.length,
    },
    {
      title: "Customer Name",
      dataIndex: "customer",
      render: (data) => data?.label,
      //   sorter: (a, b) => a.customername.length - b.customername.length,
    },
    {
      title: "Grand Total",
      dataIndex: "grandTotal",
      render: (text) => (
        <span style={{ fontWeight: "600x" }}>₹ {Number(text)?.toFixed(2)}</span>
      ),
      //   sorter: (a, b) => a.grandtotal.length - b.grandtotal.length,
    },
    {
      title: "Due Amount",
      dataIndex: "dueAmount",
      render: (text) => (
        <span style={{ fontWeight: "600x" }}>₹ {Number(text)?.toFixed(2)}</span>
      ),
      //   sorter: (a, b) => a.grandtotal.length - b.grandtotal.length,
    },
    {
      title: "Booking Status",
      dataIndex: "bookingStatus",
      render: (text) => (
        <div>
          {text?.label == "Booked" && (
            <span className="badge badge-linewarning">{text?.value}</span>
          )}
          {text?.label == "Sold" && (
            <span className="badge badge-linesuccess">{text?.value}</span>
          )}
          {text?.label == "Cancelled" && (
            <span className="badge badge-linedanger">{text?.value}</span>
          )}
        </div>
      ),
      //   sorter: (a, b) => a.status.length - b.status.length,
    },
    {
      title: "Payment Status",
      dataIndex: "paymentStatus",
      render: (text) => (
        <div>
          {text?.label == "Completed" && (
            <span className="badge badge-linesuccess">{text?.value}</span>
          )}
          {text?.label == "Pending" && (
            <span className="badge badge-linedanger">{text?.value}</span>
          )}
        </div>
      ),
      //   sorter: (a, b) => a.status.length - b.status.length,
    },
    {
      title: "Actions",
      dataIndex: "_id",
      render: (data) => (
        <td className="action-table-data">
          <div className="edit-delete-action">
            <Link
              className="action-set"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#sales-details-new"
              onClick={() => {
                setSelectedSaleDetailData(data);
              }}
            >
              <Eye className="feather-view" />
            </Link>
            <Link
              className="action-set"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#edit-sales-new"
              onClick={() => {
                setSelectedSaleDetailData(data);
              }}
            >
              <Edit className="feather-edit" />
            </Link>
            <Link
              className="me-2 p-2"
              onClick={(e) => {
                e.preventDefault();
                setShowPdfModal(true);
                setSelectedQuotationId(data);
              }}
            >
              <Download className="info-img" style={{ margin: 0 }} />
            </Link>

            <Link
              className="confirm-text p-2"
              onClick={(e) => {
                e.preventDefault();
                deleteData(data);
              }}
            >
              <i
                data-feather="trash-2"
                className="feather-trash-2"
                // onClick={showConfirmationAlert}
              ></i>
            </Link>
          </div>
        </td>
      ),
    },
  ];
  const MySwal = withReactContent(Swal);

  useEffect(() => {
    fetchSaleBookingData(login_data, selected_store[0]);
  }, [selected_store]);

  useEffect(() => {
    setFilteredList(quotation_data);
  }, [quotation_data]);

  useEffect(() => {
    if (quotation_data && quotation_data?.length) {
      let customerFilterArrCopy = [];

      quotation_data.forEach((item) => {
        if (item.customer.value) {
          customerFilterArrCopy.push(item.customer.label);
        }
      });
      let uniqCustomerFilterArrCopy = [...new Set(customerFilterArrCopy)];
      setCustomerFilterArr(uniqCustomerFilterArrCopy);
    }
  }, [quotation_data]);

  useEffect(() => {
    let quotationData = {};
    if (selectedQuotationId) {
      quotation_data?.forEach((data) => {
        if (data?._id == selectedQuotationId) {
          quotationData = {
            from: {
              name: "FM Marketing",
              email: "fmmarketing@example.com",
              phone: "9876543210",
              address: "Raipur, Chhattisgarh",
              country: {
                label: "India",
                value: "India",
              },
              state: {
                label: "Chhattisgarh",
                value: "Chhattisgarh",
              },
              city: {
                label: "Raipur",
                value: "Raipur",
              },
              status: "Active",
              itemActive: true,
            },
            to: {
              name: "",
              email: "",
              phone: "",
              address: "",
              country: {
                label: "India",
                value: "India",
              },
              state: {
                label: "Chhattisgarh",
                value: "Chhattisgarh",
              },
              city: {
                label: "Raipur",
                value: "Raipur",
              },
              status: "Active",
              itemActive: true,
            },
            Date: data?.date,
            Reference_Number: data?.invoiceNumber,
            Product_List: data?.productsList,
            Order_Tax: data?.orderTax,
            Discount: data?.discountWithUnit,
            Shipping: data?.shipping,
            Grand_Total: data?.grandTotal,
            Quotation_Status: data?.quotationStatus?.label,
            Description: data?.description,
          };
        }
      });
      setPdfData({
        heading: "Quotation",
        array: quotationData,
      });
    }
  }, [selectedQuotationId]);

  async function deleteData(dataId) {
    dispatch(
      loaderData({
        type: "Loader_Data",
        payload: true,
      })
    );
    if (dataId) {
      try {
        let response = await axios.put(
          API_URL + "salebooking/delete/" + dataId
        );
        if (response.status === 200) {
          fetchSaleBookingData(login_data, selected_store[0]);
          dispatch(
            loaderData({
              type: "Loader_Data",
              payload: false,
            })
          );
        }
      } catch (err) {
        console.log(err);
      }
    }
  }

  useEffect(() => {
    if (searchTerm) {
      setSelectedCustomerFilterData("");
      setSelectedBookingStatusFilterData("");
      setSelectedPaymentStatusFilterData("");
      const filtered = quotation_data.filter(
        (item) =>
          item?.invoiceNumber
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          item?.customer?.label.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredList(filtered);
    } else {
      setFilteredList(quotation_data);
    }
  }, [searchTerm]);

  useEffect(() => {
    if (!searchTerm) {
      if (
        selectedCustomerFilterData?.value ||
        selectedBookingStatusFilterData?.value ||
        selectedPaymentStatusFilterData?.value
      ) {
        const filteredData = quotation_data.filter((item) => {
          const matchesCustomer = selectedCustomerFilterData?.value
            ? item.customer.label === selectedCustomerFilterData.label
            : true;
          const matchesBookingStatus = selectedBookingStatusFilterData?.value
            ? item.bookingStatus.label === selectedBookingStatusFilterData.label
            : true;
          const matchesPaymentStatus = selectedPaymentStatusFilterData?.value
            ? item.paymentStatus.label ===
              selectedPaymentStatusFilterData?.label
            : true;

          return (
            matchesCustomer && matchesBookingStatus && matchesPaymentStatus
          );
        });

        setFilteredList(filteredData);
      } else {
        setFilteredList(quotation_data);
      }
    }
  }, [
    selectedCustomerFilterData,
    selectedBookingStatusFilterData,
    selectedPaymentStatusFilterData,
    selectedDateFilterData,
  ]);

  useEffect(() => {
    if (selectedSaleDetailData) {
      quotation_data.forEach((data) => {
        if (data._id == selectedSaleDetailData) {
          setViewSalesDetailData(data);
        }
      });
    }
  }, [selectedSaleDetailData]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const showConfirmationAlert = () => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes, delete it!",
      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        MySwal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          className: "btn btn-success",
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      } else {
        MySwal.close();
      }
    });
  };
  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Sale Booking</h4>
                <h6>Manage Your Sale Booking</h6>
              </div>
            </div>
            <ul className="table-top-head">
              {/* <li>
                <OverlayTrigger placement="top" overlay={renderTooltip}>
                  <Link>
                    <ImageWithBasePath
                      src="assets/img/icons/pdf.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderExcelTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top">
                    <ImageWithBasePath
                      src="assets/img/icons/excel.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderPrinterTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top">
                    <i data-feather="printer" className="feather-printer" />
                  </Link>
                </OverlayTrigger>
              </li> */}
              <li>
                <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top">
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderCollapseTooltip}>
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    id="collapse-header"
                    className={data ? "active" : ""}
                    onClick={() => {
                      dispatch(setToogleHeader(!data));
                    }}
                  >
                    <ChevronUp />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
            <div className="page-btn">
              <Link
                to="#"
                className="btn btn-added"
                data-bs-toggle="modal"
                data-bs-target="#add-quotation"
              >
                <PlusCircle className="me-2" />
                Add New Sale Booking
              </Link>
            </div>
          </div>
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <input
                      type="text"
                      value={searchTerm}
                      onChange={handleSearchChange}
                      placeholder="Search by invoice or customer"
                      className="form-control form-control-sm formsearch"
                    />
                    <Link to="#" className="btn btn-searchset">
                      <i data-feather="search" className="feather-search" />
                    </Link>
                  </div>
                </div>
                <Select
                  value={
                    selectedCustomerFilterData.value
                      ? selectedCustomerFilterData
                      : [
                          {
                            label: "Customer",
                            value: null,
                          },
                        ]
                  }
                  className="select mr-10"
                  options={customerFilterArr?.map((item, index) => {
                    return { label: item, value: index + 1 };
                  })}
                  placeholder="Customer"
                  onChange={(value) => setSelectedCustomerFilterData(value)}
                />
                <Select
                  value={
                    selectedBookingStatusFilterData.value
                      ? selectedBookingStatusFilterData
                      : [
                          {
                            label: "Booking",
                            value: null,
                          },
                        ]
                  }
                  className="select mr-10"
                  options={[
                    {
                      label: "Booked",
                      value: "Booked",
                    },
                    {
                      label: "Sold",
                      value: "Sold",
                    },
                    {
                      label: "Cancelled",
                      value: "Cancelled",
                    },
                  ]}
                  placeholder="Booking"
                  onChange={(value) =>
                    setSelectedBookingStatusFilterData(value)
                  }
                />
                {/* <Select
                  value={
                    selectedSaleStatusFilterData.value
                      ? selectedSaleStatusFilterData
                      : [
                          {
                            label: "Sale",
                            value: null,
                          },
                        ]
                  }
                  className="select mr-10"
                  options={[
                    {
                      label: "Completed",
                      value: "Completed",
                    },
                    {
                      label: "Cancelled",
                      value: "Cancelled",
                    },
                  ]}
                  placeholder="Sale"
                  onChange={(value) => setSelectedSaleStatusFilterData(value)}
                /> */}
                <Select
                  value={
                    selectedPaymentStatusFilterData.value
                      ? selectedPaymentStatusFilterData
                      : [
                          {
                            label: "Payment",
                            value: null,
                          },
                        ]
                  }
                  className="select mr-10"
                  options={[
                    {
                      label: "Completed",
                      value: "Completed",
                    },
                    {
                      label: "Pending",
                      value: "Pending",
                    },
                  ]}
                  placeholder="Payment"
                  onChange={(value) =>
                    setSelectedPaymentStatusFilterData(value)
                  }
                />
                {/* <Select
                value={
                  selectedDateFilterData.value
                    ? selectedDateFilterData
                    : [
                        {
                          label: "Date",
                          value: null,
                        },
                      ]
                }
                className="select mr-10"
                options={[
                  {
                    label: "Latest",
                    value: "Latest",
                  },
                  {
                    label: "Oldest",
                    value: "Oldest",
                  },
                ]}
                placeholder="Date"
                onChange={(value) => setSelectedDateFilterData(value)}
              /> */}
                <button
                  className="btn btn-danger"
                  onClick={() => {
                    setSelectedCustomerFilterData("");
                    setSelectedBookingStatusFilterData("");
                    setSelectedPaymentStatusFilterData("");
                    setSelectedDateFilterData("");
                  }}
                >
                  Clear
                </button>
              </div>
              {/* /Filter */}
              <div
                className={`card${isFilterVisible ? " visible" : ""}`}
                id="filter_inputs"
                style={{ display: isFilterVisible ? "block" : "none" }}
              >
                <div className="card-body pb-0">
                  <div className="row">
                    <div className="col-lg-2 col-sm-6 col-12">
                      <div className="input-blocks">
                        <Box className="info-img" />

                        <Select
                          className="select"
                          options={products}
                          placeholder="Choose Product"
                        />
                      </div>
                    </div>
                    {/* <div className="col-lg-2 col-sm-6 col-12">
                      <div className="input-blocks">
                        <StopCircle className="info-img" />
                        <Select
                          className="select"
                          options={status}
                          placeholder="Choose Status"
                        />
                      </div>
                    </div> */}
                    {/* <div className="col-lg-2 col-sm-6 col-12">
                      <div className="input-blocks">
                        <User className="info-img" />

                        <Select
                          className="select"
                          options={customername}
                          placeholder="Choose Custmer"
                        />
                      </div>
                    </div> */}
                    {/* <div className="col-lg-2 col-sm-6 col-12">
                      <div className="input-blocks">
                        <i data-feather="file-text" className="info-img" />
                        <div className="input-groupicon">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Reference"
                          />
                        </div>
                      </div>
                    </div> */}
                    {/* <div className="col-lg-4 col-sm-6 col-12">
                      <div className="input-blocks">
                        <Link className="btn btn-filters ms-auto">
                          {" "}
                          <i
                            data-feather="search"
                            className="feather-search"
                          />{" "}
                          Search{" "}
                        </Link>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
              {/* /Filter */}
              <div className="table-responsive">
                <Table columns={columns} dataSource={filteredList} />
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
      <AddSaleBooking />
      {/* <EditQuotation /> */}

      <ViewSales
        type="booking"
        setSelectedSaleDetailData={setSelectedSaleDetailData}
        viewSalesDetailData={viewSalesDetailData}
        setViewSalesDetailData={setViewSalesDetailData}
      />
      <EditSales
        type="booking"
        setSelectedSaleDetailData={setSelectedSaleDetailData}
        viewSalesDetailData={viewSalesDetailData}
        setViewSalesDetailData={setViewSalesDetailData}
      />

      <Modal
        className={`${CSS.printPreviewModal}`}
        open={showPdfModal}
        footer={false}
        onCancel={() => {
          setShowPdfModal(false);
          // setSelectedQuotation(null);
          // setSelectedQuotationId(null);
        }}
      >
        <div className={`${CSS.modalBox}`}>
          <PrintPreview>
            <PrintLayout data={pdfData} />
          </PrintPreview>
        </div>
      </Modal>
    </div>
  );
};

export default SaleBookingList;
