import { Filter } from "feather-icons-react/build/IconComponents";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
// import { all_routes } from "../../Router/all_routes";
import Table from "../../core/pagination/datatable";
import axios from "axios";
import { API_URL, MAIN_URL } from "../../constants/AppConstants";
import "./Activity.css"; // Import the CSS file for custom styles
import io from "socket.io-client";
const socket = io(MAIN_URL);

const Activity = () => {
  const login_data = useSelector((state) => state.login_data);
  const employees_data = useSelector((state) => state.employees_data);
  // const selected_store = useSelector((state) => state.selected_store);
  // const dispatch = useDispatch();

  // const [isFilterVisible, setIsFilterVisible] = useState(false);
  // const [deleteErrModal, setDeleteErrModal] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  // const [filterOptions, setFilterOptions] = useState({
  //   categories: [],
  //   brands: [],
  //   minDate: new Date(),
  //   maxDate: new Date(),
  // });
  // const [filters, setFilters] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [activityData, setActivityData] = useState([]);
  const [notificationData, setNotificationData] = useState({
    to: "", // Will hold the recipient user code
    from: "sender@example.com", // Fixed sender email or user code
    message: "",
    date: "",
    time: "",
    type: "Reminder",
  });
  const [userList, setUserList] = useState([]);

  // const route = all_routes;

  const columns = [
    {
      title: "S.No",
      dataIndex: "_id",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Description",
      dataIndex: "details",
      render: (data, record) => {
        const date = new Date(record.timestamp).toLocaleString();
        return (
          <>
            <div className="accordion-item item-activtiy">
              <h2 className="accordion-header" id={`heading${record._id}`}>
                <button
                  className={`accordion-button btn-activty ${
                    record.type === "update"
                      ? "warning"
                      : record.type === "delete"
                      ? "danger"
                      : "success"
                  }`}
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#collapse${record._id}`}
                  aria-expanded="true"
                  aria-controls={`collapse${record._id}`}
                >
                  {`${record.userCode} ${
                    record.section.charAt(0).toUpperCase() +
                    record.section.slice(1)
                  } was ${record.type}d in the ${
                    record.section
                  } section on ${date}`}
                </button>
              </h2>
              <div
                id={`collapse${record._id}`}
                className="accordion-collapse collapse"
                aria-labelledby={`heading${record._id}`}
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body body-activity">
                  <pre>{JSON.stringify(record.details, null, 2)}</pre>
                </div>
              </div>
            </div>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    async function fetchActivityData() {
      try {
        let response = await axios.get(API_URL + "activity");
        if (response.status === 200) {
          setActivityData(response.data.reverse());
        }
        setNotificationData({
          ...notificationData,
          from: login_data.empCode,
        });
      } catch (err) {
        console.log(err);
      }
    }
    fetchActivityData();

    if (employees_data) {
      const arr1 = [];
      employees_data.map((item) => {
        return arr1.push(item.empCode);
      });
      setUserList(arr1);
    }
  }, [login_data, employees_data]);

  // Handle form input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNotificationData({
      ...notificationData,
      [name]: value,
    });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    // Emit socket event with notificationData
    socket.emit("notification", {
      to: notificationData.to,
      from: notificationData.from,
      message: notificationData.message,
      date: new Date().toLocaleDateString(),
      time: new Date().toLocaleTimeString(),
      type: "Reminder",
    });

    // Optionally, you can clear the form fields after submission
    setNotificationData({
      ...notificationData,
      message: "", // Clear the message field or reset as needed
    });
    setIsModalVisible(false);
  };

  return (
    <div className="page-wrapper pagehead">
      <div className="content">
        <div className="page-header">
          <div className="page-title">
            <h4>Activity</h4>
            <h6>Detail description of updated activity</h6>
          </div>
          <ul className="table-top-head">
            <li>
              <a
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Refresh"
              >
                <i data-feather="rotate-ccw" className="feather-rotate-ccw" />
              </a>
            </li>
            <li>
              <a
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Collapse"
                id="collapse-header"
              >
                <i data-feather="chevron-up" className="feather-chevron-up" />
              </a>
            </li>
          </ul>
        </div>
        <div className="card table-list-card">
          <div className="card-body">
            <div className="table-top">
              <div className="search-set">
                <div className="search-input">
                  <input
                    type="text"
                    placeholder="Search"
                    className="form-control form-control-sm formsearch"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  <Link to className="btn btn-searchset">
                    <i data-feather="search" className="feather-search" />
                  </Link>
                </div>
              </div>
              <div className="search-path">
                <span className={`btn btn-filter`} id="filter_search">
                  <Filter
                    className="filter-icon"
                    onClick={() => setIsModalVisible(true)}
                  />
                </span>
                {/* <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#notificationModal" 
                    onClick={() => {
                      console.log("qwwe")
                      setIsModalVisible(true)
                    }

                    }
                    >
                    Testing</button> */}
              </div>
            </div>
            <div className="table-responsive">
              <Table columns={columns} dataSource={activityData} />
            </div>
          </div>
        </div>

        {/* Notification Form Popup */}
        <div
          className={`modal fade ${isModalVisible ? "show" : ""}`}
          id="notificationModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="notificationModalLabel"
          aria-hidden="true"
          style={{ display: `${isModalVisible ? "block" : "none"}` }}
        >
          {/* <div className={`modal fade ${isModalVisible ? 'show' : ''}`} id="notificationModal" tabIndex="-1" aria-labelledby="notificationModalLabel" aria-hidden={!isModalVisible}> */}
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="notificationModalLabel">
                  Send Notification
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                  onClick={() => setIsModalVisible(false)}
                ></button>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="modal-body">
                  <div className="mb-3">
                    <label htmlFor="to" className="form-label">
                      To:
                    </label>
                    <select
                      id="to"
                      name="to"
                      className="form-control"
                      value={notificationData.to}
                      onChange={handleInputChange}
                      required
                    >
                      <option value="">Select recipient</option>
                      {/* Populate options dynamically based on available users */}

                      {userList ? (
                        userList.map((item, index) => {
                          return (
                            <option key={index} value={item}>
                              {item}
                            </option>
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </select>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="message" className="form-label">
                      Message:
                    </label>
                    <textarea
                      id="message"
                      name="message"
                      className="form-control"
                      value={notificationData.message}
                      onChange={handleInputChange}
                      rows="3"
                      required
                    ></textarea>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => setIsModalVisible(false)}
                  >
                    Close
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Send Notification
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* End Notification Form Popup */}
      </div>
    </div>
  );
};

export default Activity;
