/* eslint-disable no-constant-condition */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  ChevronUp,
  Edit,
  Eye,
  FileText,
  PlusCircle,
  RotateCcw,
  StopCircle,
  User,
} from "feather-icons-react/build/IconComponents";
import {
  alertData,
  setToogleHeader,
} from "../../core/redux/action";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { Button, DatePicker } from "antd";
import axios from "axios";
import { API_URL } from "../../constants/AppConstants";
import { Modal } from "antd";
import {
  fetchAccountsData,
  fetchCustomersData,
  fetchProductsData,
  fetchSalesData,
  fetchStocksData,
  fetchSuppliersData,
  fetchReceiptsData  
} from "../../utils/utilities";
import "./Receipts.scss";

const Receipts = () => {
  const dispatch = useDispatch();
  const login_data = useSelector((state) => state.login_data);
  const data = useSelector((state) => state.toggle_header);
  const selected_store = useSelector((state) => state.selected_store);
  const sales_data = useSelector((state) => state.sales_data);
  const purchase_data = useSelector((state) => state.purchase_data);
  const customer_data = useSelector((state) => state.customer_data);
  const supplier_data = useSelector((state) => state.supplier_data);
  const receipt_data = useSelector((state) => state.receipt_data);
  const receipt_data_length = useSelector((state) => state.receipt_data_length);

  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [salesInvoiceNumber, setSalesInvoiceNumber] = useState("");
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [selectedRef, setSelectedRef] = useState([])
  const [selectedSalesData, setSelectedSalesData] = useState(sales_data);
  const [selectedPurchaseData, setSelectedPurchaseData] = useState(purchase_data);
  const [orderTaxValue, setOrderTaxValue] = useState(0);
  const [userType, setUserType] = useState(null);

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState(receipt_data);
  const [viewSalesDetailData, setViewSalesDetailData] = useState({});
  const [showUserModal, setShowUserModal] = useState(false);
  const [transactionType, setTransactionType] = useState({});
  const [billType, setBillType] = useState("");


  const [receiptData, setReceiptData] = useState([]);
  const customername = [{ value: null, label: "Choose" }];
  const status = [{ value: null, label: "Choose" }];
  const paymentstatus = [{ value: null, label: "Choose" }];
  const [refno, setRefNo] = useState("");



  const transactionTypeOptions = [
    {
      label: "Bank Deposit",
      value: "bank_deposit"
    },
    {
      label: "Cheque",
      value: "cheque"
    },
    {
      label: "Online",
      value: "online"
    },
    {
      label: "Debit Card",
      value: "debit_card"
    },
    {
      label: "Credit Card",
      value: "credit_card"
    },
    {
      label: "Cash",
      value: "cash"
    },
    {
      label: "Others",
      value: "others"
    }
  ]


  useEffect(() => {
    if (selectedCustomer || selectedSupplier) {
      fetchAccountsData(login_data, selected_store[0]);
    }
  }, [login_data]);

  useEffect(() => {
    fetchCustomersData(login_data, selected_store[0]);
    fetchSuppliersData(login_data, selected_store[0]);
    fetchStocksData(login_data, selected_store[0]);
    fetchSalesData(login_data, selected_store[0]);
    fetchProductsData(login_data, selected_store[0]);
    fetchReceiptsData(login_data, selected_store[0]);
  }, [selected_store]);


  const setPurchaseDatafn = (type) => {
    let filtered;
    if (userType == 1) {
      console.log(type);
      setSelectedCustomer(type);
      filtered = sales_data.filter(
        (item) =>{

          console.log(item);
          console.log(type);
          item.customer.value === type.value &&
          parseInt(item.dueAmount) > 0}
      );
      setSelectedSalesData(filtered);
      console.log(filtered);

    } else {
      setSelectedSupplier(type);
      filtered = purchase_data.filter(
        (item) =>
          item.supplier.value === type.value &&
          parseInt(item.dueAmount) > 0
      );
      setSelectedPurchaseData(filtered);
      console.log(filtered);
    }
    return
  }




  const renderRefreshTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Refresh
    </Tooltip>
  );
  const renderCollapseTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Collapse
    </Tooltip>
  );


  const calculateReceiptNumber = () => {
    return `RP-${new Date().getFullYear()}${receipt_data_length + 1}`;
  };

  const clearData = () => {

    setSalesInvoiceNumber("");
    setSelectedSupplier("");
    setOrderTaxValue(0);
    setSelectedCustomer("");
    setRefNo(0);

  }


  useEffect(()=> {
    console.log(filteredData);
    console.log("woring")
  },[])

  const submitReceipt = async () => {
    try {
      setIsLoading(true);

      const response = await axios.post(API_URL + "receipt", {
        name: userType == 1 ?  selectedCustomer.label : selectedSupplier.label,
        date: selectedDate,
        receiptNumber: salesInvoiceNumber,
        referenceNumber: selectedSupplier,
        amount: orderTaxValue,
        storeId: selected_store.storeId,
        userCode: login_data.userCode,
        id: userType == 1 ?  selectedCustomer.value : selectedSupplier.value,
        transactionType,
        refno,
        billType,
        accountDetails: {
          from: "me",
          to: "someone"
        }

      });

      console.log(response);

      if (response.status === 200) {
        console.log("Receipt submitted successfully:", response.data);
        setShowUserModal(false);
        setUserType(null);
        fetchReceiptsData(login_data, selected_store[0])
        dispatch(
          alertData({
            type: "success",
            msg: "Receipt Added Successfully & Ledger Updated",
          })
        );
      }
    } catch (error) {
      console.error("Error submitting receipt:", error.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const autoReceiptNumber = calculateReceiptNumber();
    setSalesInvoiceNumber(autoReceiptNumber);
    setSelectedDate(new Date());
  }, []); 

  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Receipts List</h4>
                <h6>Manage Your Receipts</h6>
              </div>
            </div>
            <ul className="table-top-head">
              <li>
                <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top">
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderCollapseTooltip}>
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    id="collapse-header"
                    className={data ? "active" : ""}
                    onClick={() => {
                      dispatch(setToogleHeader(!data));
                    }}
                  >
                    <ChevronUp />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
            <div className="page-btn">
              <Link
                to="#"
                className="btn btn-added"
                onClick={() => {
                  setShowUserModal(true);
                }}
              >
                <PlusCircle className="me-2" />
                Add New Receipt
              </Link>
            </div>
          </div>
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <input
                      type="text"
                      placeholder="Search"
                      className="form-control form-control-sm formsearch"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <Link to className="btn btn-searchset">
                      <i data-feather="search" className="feather-search" />
                    </Link>
                  </div>
                </div>
              </div>
              {/* /Filter */}
              <div
                className={`card${isFilterVisible ? " visible" : ""}`}
                id="filter_inputs"
                style={{ display: isFilterVisible ? "block" : "none" }}
              >
                <div className="card-body pb-0">
                  <div className="row">
                    <div className="col-lg-3 col-sm-6 col-12">
                      <div className="input-blocks">
                        <i data-feather="user" className="info-img" />
                        <User className="info-img" />

                        <Select
                          className="select"
                          options={customername}
                          placeholder="Newest"
                        />
                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-6 col-12">
                      <div className="input-blocks">
                        <StopCircle className="info-img" />

                        <Select
                          className="select"
                          options={status}
                          placeholder="Newest"
                        />
                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-6 col-12">
                      <div className="input-blocks">
                        <FileText className="info-img" />
                        <input
                          type="text"
                          placeholder="Enter Invoice"
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-12">
                      <div className="input-blocks">
                        <StopCircle className="info-img" />

                        <Select
                          className="select"
                          options={paymentstatus}
                          placeholder="Choose Payment Status"
                        />
                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-6 col-12">
                      <div className="input-blocks">
                        <Link className="btn btn-filters ms-auto">
                          {" "}
                          <i
                            data-feather="search"
                            className="feather-search"
                          />{" "}
                          Search{" "}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /Filter */}
              <div className="table-responsive">
                <table className="table  datanew">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Invoice</th>
                      <th>Date</th>
                      <th>Amount</th>
                      <th>UTR</th>
                      <th>Description</th>
                      <th>Biller</th>
                      <th className="text-right">Action</th>
                    </tr>
                  </thead>
                  <tbody className="sales-list">
                    {filteredData?.map((data, index) => {
                      return (
                        <tr key={index}>
                          <td>{data?.name}</td>
                          <td className="ref-number">#{data?.receiptNumber}</td>
                          <td>{data.date.split("T")[0]}</td>
                          <td>₹ {data?.amount}</td>
                          <td>{data?.utr}</td>
                          <td style={{ textWrap: "balance" }}>
                            {data?.description}
                          </td>
                          <td>
                            {data.billType}
                          </td>
                          {/* <td>Admin</td> */}
                          <td>
                            <div className="d-flex justify-content-end">
                              <Link
                                className="action-set"
                                to="#"
                                data-bs-toggle="modal"
                                data-bs-target="#sales-details-new"
                                onClick={() => {
                                  setViewSalesDetailData(data);
                                }}
                              >
                                <Eye className="feather-view" />
                              </Link>
                              <Link
                                className="action-set"
                                to="#"
                                data-bs-toggle="modal"
                                data-bs-target="#edit-sales-new"
                                onClick={() => {
                                  setViewSalesDetailData(data);
                                }}
                              >
                                <Edit className="feather-edit" />
                              </Link>
                              <Link
                                className="action-set"
                                to="#"
                                data-bs-toggle="dropdown"
                                aria-expanded="true"
                              >
                                <i
                                  className="fa fa-ellipsis-v"
                                  aria-hidden="true"
                                />
                              </Link>
                              <ul className="dropdown-menu"></ul>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
      <>
        {/*add popup Customer*/}
        <Modal
          title={`${userType === 1 ? "Add Customer Receipt" : "Add Supplier Receipt"
            }`}
          open={userType}
          onCancel={() => {
            setUserType(null);
            setShowUserModal(true);
            clearData()
          }}
          footer={[
            <Button key="cancel" onClick={() =>
              setShowUserModal(true)
            }>
              Cancel
            </Button>,
            <Button key="submit" type="primary" onClick={submitReceipt}>
              Submit
            </Button>,
          ]}
        >
          <div className="p-3">
            <form>
              <div className="row">
                <div className="col-lg-6 col-sm-12 col-12">
                  <div className="input-blocks">
                    <label>
                      {userType === 1 ? 'Customer Name' : 'Supplier Name'}
                      <span style={{ color: 'red' }}>*</span>
                    </label>
                    <Select
                      value={userType == 1 ? selectedCustomer : selectedSupplier}
                      className="input-blocks"
                      options={(userType == 1 ? customer_data : supplier_data)?.map((item) => (
                        userType == 1 ?
                          {
                            label: item.customerName,
                            value: item._id,
                          } : {
                            label: item.supplierName,
                            value: item._id,
                          }
                      ))}
                      placeholder={`Choose ${userType === 1 ? 'Customer' : 'Supplier'}`}
                      onChange={(value) => setPurchaseDatafn(value)}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-12 col-12">

                  <div className="input-blocks">
                    <label>Date</label>
                    <DatePicker
                      disabled
                      popupStyle={{ zIndex: 2000 }}
                      selected={selectedDate}
                      type="date"
                      className="filterdatepicker add-purchase-filterdatepicker"
                      dateFormat="dd-MM-yyyy"
                      placeholder={`${selectedDate.toDateString()}`}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 col-sm-12 col-12">

                  <div className="input-blocks">
                    <label>Reference Number</label>
                    <Select
                      value={selectedRef}
                      isMulti
                      options={(userType == 1 ? selectedSalesData : selectedPurchaseData)?.map((item) => (
                        {
                          label: item.invoiceNumber,
                          value: item._id,
                        }
                      ))
                    }
                      placeholder="Choose Reference"
                      onChange={(value) =>
                      {
                        console.log(selectedSalesData)
                        console.log(selectedPurchaseData)
                        setSelectedRef(value)

                      }
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6 col-sm-12 col-12">
                  <div className="input-blocks">
                    <label>
                      Transaction Via <span style={{ color: 'red' }}>*</span>
                    </label>
                    <Select
                      value={transactionType}
                      className="input-blocks"
                      options={transactionTypeOptions?.map((item) => ({
                        label: item.label,
                        value: item.value,
                      }))}
                      placeholder={`Choose Transaction`}
                      onChange={(value) => setTransactionType(value)}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-12 col-12">
                  <div className="input-blocks">
                    <label>
                      Ref. No. <span style={{ color: 'red' }}>*</span>
                    </label>
                    <input
                      type="text"
                      value={refno}
                      className="form-control"
                      placeholder="Enter Reference Number last 4 digit"
                      onChange={(e) => setRefNo(e.target.value)}
                    />
                  </div>
                </div>
              </div>


              <div className="row">
                <div className="col-lg-12 col-sm-12 col-12">
                  <div className="input-blocks">
                    <label>
                      Amount<span style={{ color: 'red' }}>*</span>
                    </label>
                    <input
                      type="text"
                      value={orderTaxValue}
                      className="form-control"
                      placeholder="Enter Amount"
                      onChange={(e) => setOrderTaxValue(e.target.value)}
                    />
                  </div>
                </div>
              </div>



            </form>
          </div>

        </Modal>

      </>

      <Modal
        centered
        open={showUserModal}
        footer={false}
        onCancel={() => {
          setShowUserModal(false);
        }}
        className="receipt-user-selection-modal"
      >
        <div className="d-flex justify-content-around">
          <div className="d-inline-flex align-items-center">
            <label className="popup-button" style={{ cursor: 'pointer', border: '1px solid #FF9F43', padding: '5px 15px', borderRadius: '5px' }}>
              <input
                type="radio"
                name="data-type"
                onClick={() => {
                  setUserType(1)
                  setBillType("customer")
                }
                }
                checked={userType === 1 ? true : false}
                style={{ display: 'none' }}
              />
              <span style={{marginLeft: 0}}>Customer</span>
            </label>
          </div>
          <div className="d-inline-flex align-items-center">
            <label className="popup-button" style={{ cursor: 'pointer', border: '1px solid #FF9F43', padding: '5px 15px', borderRadius: '5px' }}>
              <input
                type="radio"
                name="data-type"
                onClick={() => {
                  setUserType(2)
                  setBillType("supplier")
                }}
                checked={userType === 2 ? true : false}
                style={{ display: 'none' }}
              />
              <span style={{marginLeft: 0}}>Supplier</span>
            </label>

          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Receipts;
