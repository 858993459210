/* eslint-disable no-constant-condition */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import {
  ChevronUp,
  FileText,
  PlusCircle,
  RotateCcw,
  Sliders,
  StopCircle,
  User,
} from "feather-icons-react/build/IconComponents";
import { alertData, setToogleHeader } from "../../../core/redux/action";
import { useDispatch, useSelector } from "react-redux";
import { Filter } from "react-feather";
import Select from "react-select";
import { DatePicker } from "antd";
import axios from "axios";
import { API_URL } from "../../../constants/AppConstants";
import { quotationData } from "../../../core/redux/action";
import CustomerModal from "../peoples/customerModal";
import {
  fetchCustomersData,
  fetchProductsData,
  fetchSaleBookingData,
  fetchSaleQuotationData,
  fetchStocksData,
} from "../../../utils/utilities";

const AddSaleBooking = () => {
  const dispatch = useDispatch();
  const login_data = useSelector((state) => state.login_data);
  const data = useSelector((state) => state.toggle_header);
  const selected_store = useSelector((state) => state.selected_store);
  const customer_data = useSelector((state) => state.customer_data);
  const supplier_data = useSelector((state) => state.supplier_data);
  const stocks_data = useSelector((state) => state.stocks_data);
  const products_data = useSelector((state) => state.products_data);
  const booking_data = useSelector((state) => state.sale_booking_data);
  const booking_data_length = useSelector(
    (state) => state.sale_booking_data_length
  );
  const sale_quotation_data = useSelector((state) => state.sale_quotation_data);
  const sale_quotation_data_length = useSelector(
    (state) => state.sale_quotation_data_length
  );

  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [quotationInvoiceNumber, setSalesInvoiceNumber] = useState("");
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [productStocksList, setProductStocksList] = useState([]);

  const [selectedUnits, setSelectedUnits] = useState([
    {
      label: "Choose",
      value: null,
    },
  ]);
  const [selectedQty, setSelectedQty] = useState([0]);
  const [totalCostAll, setTotalCostAll] = useState([0]);
  const [discountType, setDiscountType] = useState([
    {
      label: "Choose",
      value: null,
    },
  ]);

  const [productInput, setProductInput] = useState("");
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [showProductList, setShowProductList] = useState(false);
  const [filteredList, setFilteredProductList] = useState(products_data);

  const [orderTaxValue, setOrderTaxValue] = useState("");
  const [discountValue, setDiscountValue] = useState("");
  const [shippingValue, setShippingValue] = useState("");
  const [quotationStatus, setQuotationStatus] = useState({
    label: "Booked",
    value: "Booked",
  });
  const [description, setDescription] = useState("");
  const [dueDate, setDueDate] = useState(new Date());
  const [paidAmount, setPaidAmount] = useState("");
  const [paymentStatus, setPaymentStatus] = useState({
    label: "Pending",
    value: "Pending",
  });
  const [paymentMethod, setPaymentMethod] = useState({
    label: "Choose",
    value: null,
  });

  const [grandTotal, setGrandTotal] = useState("");
  let totalTaxList = useRef([null]);
  let totalTax = useRef(0);

  const [purchasePriceList, setPurchasePriceList] = useState([]);
  const [forQuotation, setForQuotation] = useState(false);
  const [selectedQuotation, setSelectedQuotation] = useState("");
  const [updateStockCheck, setUpdateStockCheck] = useState(true);

  let grandTotalValue = useRef(0);
  let dropdownRef = useRef(null);

  const toggleFilterVisibility = () => {
    setIsFilterVisible((prevVisibility) => !prevVisibility);
  };

  useEffect(() => {
    fetchCustomersData(login_data, selected_store[0]);
    fetchSaleQuotationData(login_data, selected_store[0]);
    fetchSaleBookingData(login_data, selected_store[0]);
    fetchStocksData(login_data, selected_store[0]);
    fetchProductsData(login_data, selected_store[0]);
  }, [selected_store]);

  useEffect(() => {
    document.addEventListener("click", (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setShowProductList(false);
      }
    });
  }, []);

  useEffect(() => {
    if (selectedProducts.length) {
      grandTotalValue.current = 0;
      for (let i = 0; i < selectedProducts.length; i++) {
        grandTotalValue.current =
          Number(grandTotalValue.current) +
          Number(
            selectedProducts[i].totalCost ? selectedProducts[i].totalCost : 0
          );
      }

      if (discountValue) {
        if (discountType?.label == "Percent") {
          grandTotalValue.current =
            Number(grandTotalValue.current) -
            (Number(grandTotalValue.current) * Number(discountValue)) / 100;
          grandTotalValue.current =
            Number(grandTotalValue.current) +
            (Number(grandTotalValue.current) * Number(orderTaxValue)) / 100;
          grandTotalValue.current =
            Number(grandTotalValue.current) + Number(shippingValue);
        } else if (discountType?.label == "Cash") {
          grandTotalValue.current =
            Number(grandTotalValue.current) - Number(discountValue);
          grandTotalValue.current =
            Number(grandTotalValue.current) +
            (Number(grandTotalValue.current) * Number(orderTaxValue)) / 100;
          grandTotalValue.current =
            Number(grandTotalValue.current) + Number(shippingValue);
        }
      } else {
        grandTotalValue.current =
          Number(grandTotalValue.current) +
          (Number(grandTotalValue.current) * Number(orderTaxValue)) / 100;
        grandTotalValue.current =
          Number(grandTotalValue.current) + Number(shippingValue);
      }

      setGrandTotal(
        Number(grandTotalValue.current) ? Number(grandTotalValue.current) : 0
      );
    }
    setSelectedUnits(selectedUnits);
  }, [selectedProducts, orderTaxValue, discountValue, shippingValue]);

  useEffect(() => {
    let filteredArr = [];
    let newFilteredList = [...products_data];
    if (productInput?.length) {
      setShowProductList(true);
      filteredArr = newFilteredList?.filter((product) => {
        return product.productName
          .toLowerCase()
          .includes(productInput.toLowerCase());
      });
      setFilteredProductList(filteredArr);
    } else {
      setFilteredProductList(products_data);
    }
  }, [productInput]);

  useEffect(() => {
    let selectedUnitsArr = [...selectedUnits];
    let selectedQtyArr = [...selectedQty];

    if (selectedProducts) {
      for (let i = 0; i < selectedProducts.length; i++) {
        selectedUnitsArr.push({
          label: "Choose",
          value: null,
        });
      }
    }
    setSelectedUnits(selectedUnits);
  }, [selectedProducts]);

  useEffect(() => {
    let filteredArr = [...selectedProducts];
    for (let i = 0; i < filteredArr.length; i++) {
      let unitCost =
        Number(purchasePriceList[i] ? purchasePriceList[i] : 0) -
        (filteredArr[i].productDiscountType?.label == "Cash"
          ? Number(filteredArr[i].productDiscountValue)
          : (Number(purchasePriceList[i] ? purchasePriceList[i] : 0) *
              Number(filteredArr[i].productDiscountValue)) /
            100) +
        ((Number(purchasePriceList[i] ? purchasePriceList[i] : 0) -
          (filteredArr[i].productDiscountType?.label == "Cash"
            ? Number(filteredArr[i].productDiscountValue)
            : (Number(purchasePriceList[i] ? purchasePriceList[i] : 0) *
                Number(filteredArr[i].productDiscountValue)) /
              100)) *
          Number(filteredArr[i].productTaxValue)) /
          100;

      filteredArr[i] = {
        ...filteredArr[i],
        selectedUnit: selectedUnits[i],
        selectedQty: selectedQty[i],
        purchasePrice: purchasePriceList[i],
        productUnitCost: unitCost,
        // totalCost: Number(selectedQty[i])
        //   ? selectedUnits[i]?.label != filteredArr[i]?.productUnit?.label
        //     ? Number(unitCost) *
        //       Number(
        //         selectedQty[i] * Number(filteredArr[i]?.productAlternateQty)
        //       )
        //     : Number(unitCost) * Number(selectedQty[i])
        //   : 0,
        totalCost: Number(selectedQty[i])
          ? Number(unitCost) * Number(selectedQty[i])
          : 0,
        productCurrentTax: totalTaxList.current[i],
      };
    }
    setSelectedProducts(filteredArr);
  }, [selectedUnits, selectedQty, purchasePriceList]);

  useEffect(() => {
    if (selectedQuotation?.value) {
      sale_quotation_data?.forEach((item) => {
        if (item._id == selectedQuotation.value) {
          let productUnit = [];
          let productQty = [];
          let productPrice = [];
          setSelectedCustomer(item.customer);
          setShippingValue(item.shipping);
          setSelectedProducts(item.productsList);
          item.productsList.forEach((product, index) => {
            productUnit[index] = product.selectedUnit;
            productQty[index] = product.selectedQty;
            productPrice[index] = product.purchasePrice;
          });
          setSelectedUnits(productUnit);
          setSelectedQty(productQty);
          setPurchasePriceList(productPrice);
          setSalesInvoiceNumber(
            `SBL-${new Date().getFullYear()}${booking_data_length + 1}`
          );
        }
      });
    }
  }, [selectedQuotation]);

  function changeUnit(dataValue, dataId) {
    let newArr = [...selectedUnits];
    newArr.splice(dataId, 1, dataValue);
    setSelectedUnits(newArr);
    changeQty(0, dataId);
  }

  function changeQty(dataValue, dataId) {
    let newArr = [...selectedQty];
    newArr.splice(dataId, 1, dataValue);
    setSelectedQty(newArr);
  }

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const renderTooltip = (props) => (
    <Tooltip id="pdf-tooltip" {...props}>
      Pdf
    </Tooltip>
  );
  const renderExcelTooltip = (props) => (
    <Tooltip id="excel-tooltip" {...props}>
      Excel
    </Tooltip>
  );
  const renderPrinterTooltip = (props) => (
    <Tooltip id="printer-tooltip" {...props}>
      Printer
    </Tooltip>
  );
  const renderRefreshTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Refresh
    </Tooltip>
  );
  const renderCollapseTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Collapse
    </Tooltip>
  );

  async function submitData() {
    setIsLoading(true);

    if (
      !selectedCustomer ||
      !selectedProducts ||
      !quotationInvoiceNumber ||
      !quotationStatus?.value
    ) {
      dispatch(
        alertData({
          type: "error",
          msg: "Please fill all the required fields!",
        })
      );
      setIsLoading(false);
      return;
    }

    let dataBody = {
      storeId: selected_store[0]?._id,
      customer: selectedCustomer,
      date: selectedDate,
      invoiceNumber: quotationInvoiceNumber,
      productsList: selectedProducts,
      orderTax: totalTax.current,
      discount: discountValue,
      discountWithUnit:
        discountValue +
        (discountType.label == "Percent"
          ? "%"
          : discountType.label == "Cash"
          ? "₹"
          : ""),
      shipping: shippingValue,
      grandTotal: grandTotal,
      bookingStatus: quotationStatus,
      description,
      dueDate,
      dueAmount: Number(grandTotal).toFixed(2) - Number(paidAmount).toFixed(2),
      receivedAmount: paidAmount,
      paymentStatus,
      paymentMethod,
      createdOn: new Date().toLocaleDateString().split(",")[0],
      userCode: login_data?.empCode,
      itemActive: true,
    };

    const invalidProductsCount = selectedProducts.reduce(
      (count, product, index) => {
        if (product.selectedQty > productStocksList[index]?.productCurrentQty) {
          return count + 1;
        }
        return count;
      },
      0
    );

    if (invalidProductsCount > 0) {
      setIsLoading(false);
      dispatch(
        alertData({
          type: "error",
          msg: `${invalidProductsCount} product(s) have quantities exceeding the available quantity`,
        })
      );
      return;
    }

    let stocksDataArr = [];

    if (selectedProducts.length) {
      for (let i = 0; i < selectedProducts.length; i++) {
        if (
          selectedProducts[i].selectedUnit.label ==
          selectedProducts[i].productClubbedUnit.label
        ) {
          stocksDataArr.push({
            productCurrentAlternateQty:
              Number(productStocksList[i].productCurrentAlternateQty) -
              Number(selectedProducts[i].selectedQty),
            productCurrentQty:
              Number(selectedProducts[i].productAlternateQty) *
              (Number(productStocksList[i].productCurrentAlternateQty) -
                Number(selectedProducts[i].selectedQty)),
          });
        } else {
          stocksDataArr.push({
            productCurrentQty:
              Number(productStocksList[i].productCurrentQty) -
              Number(selectedProducts[i].selectedQty),
            productCurrentAlternateQty:
              (Number(productStocksList[i].productCurrentQty) -
                Number(selectedProducts[i].selectedQty)) /
              Number(selectedProducts[i].productAlternateQty),
          });
        }
      }
    }

    try {
      let response = await axios.post(API_URL + "salebooking", dataBody);
      if (response.status === 200) {
        dispatch(
          alertData({
            type: "success",
            msg: "Booking added successfully!",
          })
        );
        setTimeout(() => {
          setIsLoading(false);
          document?.querySelector("#add-quotation .close")?.click();
        }, 1000);
        fetchSaleBookingData(login_data, selected_store[0]);

        if (updateStockCheck) {
          let failed = 0;
          let responseArr = [];

          setTimeout(async () => {
            if (selectedProducts.length) {
              for (let i = 0; i < selectedProducts.length; i++) {
                response[i] = await axios.put(
                  API_URL + "stocks/" + selectedProducts[i]._id,
                  {
                    ...stocksDataArr[i],
                    stockStatus: "Active",
                    updatedOn: new Date().toLocaleDateString().split(",")[0],
                  }
                );
              }
            }
            responseArr?.forEach((response) => {
              if (!response.status == 200) failed = 1;
            });
            if (!failed) {
              dispatch(
                alertData({
                  type: "success",
                  msg: "Stocks updated successfully!",
                })
              );
              fetchStocksData(login_data, selected_store[0]);
            }
          }, 3500);
        }

        clearData();
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  }

  function selectProduct(product) {
    let productArr = [...selectedProducts];
    productArr.push(product);
    setSelectedProducts(productArr);

    let selectedUnitsArr = [...selectedUnits];
    let selectedQtyArr = [...selectedQty];
    let totalCostArr = [...totalCostAll];
    selectedUnitsArr.splice(productArr.length - 1, 0, {
      label: "Choose",
      value: null,
    });
    totalCostArr.splice(productArr.length - 1, 0, 0);
    selectedQtyArr.splice(productArr.length - 1, 0, "");
  }

  function deleteProduct(productIndex) {
    let productArr = [...selectedProducts];
    let newArr1 = productArr.filter((item, index) => index != productIndex);
    setSelectedProducts(newArr1);

    let selectedUnitArr = [...selectedUnits];
    let newArr2 = selectedUnitArr.filter(
      (item, index) => index != productIndex
    );
    setSelectedUnits(newArr2);

    let selectedQtyArr = [...selectedQty];
    let newArr3 = selectedQtyArr.filter((item, index) => index != productIndex);
    setSelectedQty(newArr3);

    let selectedPriceArr = [...purchasePriceList];
    let newArr4 = selectedPriceArr.filter(
      (item, index) => index != productIndex
    );
    setPurchasePriceList(newArr4);
  }

  function clearData() {
    setProductInput("");
    setSalesInvoiceNumber("");
    setOrderTaxValue("");
    setDiscountType([
      {
        label: "Choose",
        value: null,
      },
    ]);
    setDiscountValue("");
    setShippingValue("");
    setSelectedSupplier("");
    setSelectedCustomer("");
    setSelectedProducts([]);
    setDueDate(new Date());
    setSelectedDate(new Date());
    setQuotationStatus({
      label: "Completed",
      value: "Completed",
    });
    setDescription("");
    setPaidAmount("");
    setPaymentStatus({
      label: "Pending",
      value: "Pending",
    });
    setPaymentMethod({
      label: "Choose",
      value: null,
    });
    setGrandTotal("");
    totalTax.current = 0;
    setSelectedUnits([
      {
        label: "Choose",
        value: null,
      },
    ]);
    setPurchasePriceList([]);
    setSelectedQty([0]);
  }

  function changePurchasePrice(value, index) {
    let priceArr = [...purchasePriceList];
    priceArr[index] = Number(value);
    setPurchasePriceList(priceArr);
  }

  const getProductQty = (id) => {
    const stockQty = stocks_data.filter((item) => item.productId === id);
    return `${
      Number(stockQty[0]?.productCurrentQty)
        ? Number(stockQty[0]?.productCurrentQty).toFixed(2)
        : 0
    } ${stockQty[0]?.productUnit?.label} / ${
      Number(stockQty[0]?.productCurrentAlternateQty)
        ? Number(stockQty[0]?.productCurrentAlternateQty).toFixed(2)
        : 0
    } ${stockQty[0]?.productClubbedUnit?.label}`;
  };

  useEffect(() => {
    let selectedUnitsArr = [...selectedUnits];
    let selectedQtyArr = [...selectedQty];
    let productStocksArr = [...productStocksList];

    if (selectedProducts) {
      for (let i = 0; i < selectedProducts.length; i++) {
        selectedUnitsArr.push({
          label: "Choose",
          value: null,
        });

        stocks_data?.forEach((item) => {
          if (item.productId == selectedProducts[i]._id) {
            productStocksArr[i] = {
              productCurrentQty: Number(item.productCurrentQty),
              productUnit: item.productUnit.label,
              productCurrentAlternateQty: Number(
                item.productCurrentAlternateQty
              ),
              productClubbedUnit: item.productClubbedUnit.label,
            };
          }
        });
      }
    }
    setSelectedUnits(selectedUnits);
    setProductStocksList(productStocksArr);
  }, [selectedProducts, stocks_data]);

  return (
    <div>
      {/*Add Quotation */}
      <div className="modal fade" id="add-quotation">
        <div className="modal-dialog add-centered">
          <div className="modal-content">
            <div className="page-wrapper p-0 m-0">
              <div className="content p-0">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>Add Sale Booking</h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="card">
                  <div className="card-body">
                    <form>
                      <div className="row">
                        <div className="col-lg-3 col-sm-6 col-12">
                          <div className="input-blocks">
                            <label className="d-inline-flex align-items-center">
                              For Quotation{" "}
                              <span
                                className="d-flex align-items-center"
                                style={{ position: "relative" }}
                              >
                                <input
                                  checked={forQuotation}
                                  value={forQuotation}
                                  onChange={() =>
                                    setForQuotation(!forQuotation)
                                  }
                                  type="checkbox"
                                  id="user2"
                                  className="check"
                                  defaultChecked="false"
                                />
                                <label
                                  htmlFor="user2"
                                  className="checktoggle m-0 ml-10"
                                />
                              </span>
                            </label>

                            <div className="row">
                              <div className="col-lg-12 col-sm-12 col-12">
                                <Select
                                  isDisabled={forQuotation ? false : true}
                                  value={
                                    selectedQuotation?.value
                                      ? selectedQuotation
                                      : [
                                          {
                                            label: "Choose",
                                            value: null,
                                          },
                                        ]
                                  }
                                  className="select"
                                  options={sale_quotation_data?.map((item) => {
                                    return {
                                      label: item.invoiceNumber,
                                      value: item._id,
                                    };
                                  })}
                                  placeholder="Choose"
                                  onChange={(value) => {
                                    setSelectedQuotation(value);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-3 col-sm-6 col-12">
                          <div className="input-blocks">
                            <label>
                              Customer
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <div className="row">
                              <div className="col-lg-12 col-sm-12 col-12">
                                <Select
                                  isDisabled={selectedQuotation ? true : false}
                                  value={
                                    selectedCustomer
                                      ? selectedCustomer
                                      : [
                                          {
                                            label: "Choose",
                                            value: null,
                                          },
                                        ]
                                  }
                                  className="select"
                                  options={customer_data?.map((item) => {
                                    return {
                                      label: item.customerName,
                                      value: item._id,
                                    };
                                  })}
                                  placeholder="Choose Customer"
                                  onChange={(value) => {
                                    setSelectedCustomer(value);
                                    setSalesInvoiceNumber(
                                      `SBL-${new Date().getFullYear()}${
                                        booking_data_length + 1
                                      }`
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-1 col-sm-2 col-2 ps-0">
                          <div className="add-icon" style={{ marginTop: 30 }}>
                            <Link
                              data-bs-toggle="modal"
                              data-bs-target={`#add-customer`}
                              onClick={(e) => e.preventDefault()}
                              className="choose-add"
                            >
                              <PlusCircle className="plus" />
                            </Link>
                          </div>
                        </div>
                        <div className="col-lg-3 col-sm-6 col-12">
                          <div className="input-blocks">
                            <label>Date</label>
                            <div className="input-groupicon calender-input">
                              <DatePicker
                                disabled
                                style={{ width: "100%" }}
                                popupStyle={{ zIndex: 2000 }}
                                selected={selectedDate}
                                onChange={handleDateChange}
                                type="date"
                                className="filterdatepicker add-quotation-filterdatepicker"
                                dateFormat="dd-MM-yyyy"
                                placeholder={`${selectedDate.toDateString()}`}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-sm-6 col-12">
                          <div className="input-blocks">
                            <label>
                              Invoice Number
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <div className="input-groupicon calender-input">
                              <input
                                disabled
                                readOnly
                                style={{ padding: 10 }}
                                type="text"
                                value={quotationInvoiceNumber}
                                placeholder="Enter Invoice Number"
                                onChange={(e) => {
                                  setSalesInvoiceNumber(e.target.value);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12 col-sm-6 col-12">
                          <div className="input-blocks">
                            <label>
                              Product Name
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <div
                              className="input-groupicon select-code"
                              ref={dropdownRef}
                            >
                              <input
                                style={{ padding: 10 }}
                                type="text"
                                value={productInput}
                                placeholder="Please type product code and select"
                                onChange={(e) => {
                                  setProductInput(e.target.value);
                                }}
                                onFocus={() => setShowProductList(true)}
                              />
                              {/* <div className="addonset">
                                  <ImageWithBasePath
                                    src="assets/img/icons/qrcode-scan.svg"
                                    alt="img"
                                  />
                                </div> */}
                              {showProductList ? (
                                <div className="select-code-dropdown">
                                  {filteredList?.map((product, index) => {
                                    return (
                                      <div
                                        key={index}
                                        onClick={() => {
                                          selectProduct(product);
                                          setShowProductList(false);
                                        }}
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <span>
                                          {" "}
                                          <span style={{ fontWeight: 900 }}>
                                            {product.productName}
                                          </span>{" "}
                                          | {product.productItemCode}{" "}
                                        </span>{" "}
                                        <span
                                          style={{
                                            minWidth: "18%",
                                            display: "flex",
                                          }}
                                        >
                                          {" "}
                                          <span
                                            style={{
                                              fontWeight: 700,
                                              marginRight: "5px",
                                            }}
                                          >
                                            Item Qty:{" "}
                                          </span>{" "}
                                          {getProductQty(product._id)}
                                        </span>
                                      </div>
                                    );
                                  })}
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-2 col-sm-6 col-12">
                          <div className="input-blocks">
                            <label>Order Tax(%)</label>
                            <div className="input-groupicon select-code">
                              <input
                                disabled
                                value={orderTaxValue}
                                onChange={(e) =>
                                  setOrderTaxValue(e.target.value)
                                }
                                type="number"
                                min={0}
                                defaultValue={0}
                                className="p-2"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-2 col-sm-6 col-12">
                          <div className="input-blocks">
                            <label>Discount Type</label>
                            <div className="input-groupicon select-code">
                              <Select
                                value={
                                  discountType
                                    ? discountType
                                    : [
                                        {
                                          label: "Choose",
                                          value: null,
                                        },
                                      ]
                                }
                                onChange={(value) => setDiscountType(value)}
                                className="select"
                                options={[
                                  {
                                    label: "Percent",
                                    value: "Percent",
                                  },
                                  {
                                    label: "Cash",
                                    value: "Cash",
                                  },
                                ]}
                                placeholder="Choose"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-2 col-sm-6 col-12">
                          <div className="input-blocks">
                            <label>Discount</label>
                            <div className="input-groupicon select-code">
                              <input
                                disabled={!discountType?.value ? true : false}
                                value={discountValue}
                                onChange={(e) =>
                                  setDiscountValue(e.target.value)
                                }
                                type="number"
                                min={0}
                                defaultValue={0}
                                className="p-2"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-2 col-sm-6 col-12">
                          <div className="input-blocks">
                            <label>Shipping(₹)</label>
                            <div className="input-groupicon select-code">
                              <input
                                value={shippingValue}
                                onChange={(e) =>
                                  setShippingValue(e.target.value)
                                }
                                type="number"
                                min={0}
                                defaultValue={0}
                                className="p-2"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-2 col-sm-6 col-12">
                          <div className="input-blocks mb-5">
                            <label>
                              Booking Status{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>

                            <Select
                              isDisabled={true}
                              value={
                                quotationStatus?.value
                                  ? quotationStatus
                                  : [
                                      {
                                        label: "Choose",
                                        value: null,
                                      },
                                    ]
                              }
                              onChange={(value) => setQuotationStatus(value)}
                              className="select"
                              options={[
                                {
                                  label: "Booked",
                                  value: "Booked",
                                },
                              ]}
                              placeholder="status"
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        className="table-responsive no-pagination"
                        style={{ overflow: "unset" }}
                      >
                        <table className="table  datanew">
                          <thead>
                            <tr>
                              <th>Product</th>
                              <th>Unit</th>
                              <th>Qty</th>
                              <th>Alt. Qty</th>
                              <th>Price(₹)</th>
                              <th>Discount</th>
                              <th>Tax(%)</th>
                              <th>Tax Amount(₹)</th>
                              <th>Unit Cost(₹)</th>
                              <th>Total Cost(₹)</th>
                              {selectedQuotation?.value ? "" : <th>Action</th>}
                            </tr>
                          </thead>
                          <tbody>
                            {selectedProducts?.map((product, index) => {
                              let totalTaxAmt =
                                Number(purchasePriceList[index]) *
                                (Number(product?.productTaxValue) / 100) *
                                Number(selectedQty[index]);

                              let newTaxArr = [...totalTaxList.current];
                              newTaxArr[index] = totalTaxAmt;
                              totalTaxList.current = newTaxArr;

                              totalTax.current = newTaxArr.reduce(
                                (prev, curr) => Number(prev) + Number(curr)
                              );
                              return (
                                <tr key={index}>
                                  <td>{product.productName}</td>
                                  <td>
                                    <Select
                                      value={selectedUnits[index]}
                                      onChange={(value) => {
                                        changeUnit(value, index);
                                        changePurchasePrice(0, index);
                                      }}
                                      className="select"
                                      options={[
                                        {
                                          label: product?.productUnit?.label,
                                          value: product?.productUnit?.label,
                                        },
                                        {
                                          label:
                                            product?.productClubbedUnit?.label,
                                          value:
                                            product?.productClubbedUnit?.label,
                                        },
                                      ]}
                                      placeholder="Choose"
                                    />
                                  </td>
                                  <td>
                                    <input
                                      style={{ width: 80 }}
                                      disabled={
                                        product?.selectedUnit ? false : true
                                      }
                                      value={product?.selectedQty}
                                      onChange={(e) =>
                                        changeQty(e.target.value, index)
                                      }
                                      type="number"
                                      min={0}
                                      defaultValue={0}
                                      className="p-2"
                                    />
                                  </td>
                                  <td>{product.productAlternateQty}</td>
                                  <td>
                                    <input
                                      style={{ width: 80 }}
                                      value={purchasePriceList[index]}
                                      onChange={(e) =>
                                        changePurchasePrice(
                                          e.target.value,
                                          index
                                        )
                                      }
                                      type="number"
                                      min={0}
                                      defaultValue={0}
                                      className="p-2"
                                    />
                                  </td>
                                  <td>
                                    {product.productDiscountValue
                                      ? product.productDiscountValue
                                      : 0}
                                    {product.productDiscountValue
                                      ? product.productDiscountType?.label ==
                                        "Cash"
                                        ? "₹"
                                        : "%"
                                      : ""}
                                  </td>
                                  <td>
                                    {product.productTaxValue
                                      ? product.productTaxValue
                                      : 0}
                                  </td>
                                  <td>
                                    {product.productCurrentTax
                                      ? Number(
                                          product.productCurrentTax
                                        ).toFixed(2)
                                      : 0}
                                  </td>
                                  <td>
                                    {(
                                      Number(
                                        purchasePriceList[index]
                                          ? purchasePriceList[index]
                                          : 0
                                      ) -
                                      (product.productDiscountType?.label ==
                                      "Cash"
                                        ? Number(product.productDiscountValue)
                                        : (Number(
                                            purchasePriceList[index]
                                              ? purchasePriceList[index]
                                              : 0
                                          ) *
                                            Number(
                                              product.productDiscountValue
                                            )) /
                                          100) +
                                      ((Number(
                                        purchasePriceList[index]
                                          ? purchasePriceList[index]
                                          : 0
                                      ) -
                                        (product.productDiscountType?.label ==
                                        "Cash"
                                          ? Number(product.productDiscountValue)
                                          : (Number(
                                              purchasePriceList[index]
                                                ? purchasePriceList[index]
                                                : 0
                                            ) *
                                              Number(
                                                product.productDiscountValue
                                              )) /
                                            100)) *
                                        Number(product.productTaxValue)) /
                                        100
                                    ).toFixed(2)}
                                  </td>
                                  <td>
                                    {product?.totalCost
                                      ? Number(product?.totalCost).toFixed(2)
                                      : 0}
                                  </td>
                                  {selectedQuotation?.value ? (
                                    ""
                                  ) : (
                                    <td>
                                      <span
                                        className="feather-trash-container"
                                        onClick={() => deleteProduct(index)}
                                      >
                                        <i
                                          data-feather="trash-2"
                                          className="feather feather-trash-2 feather-trash-2"
                                        />
                                      </span>
                                    </td>
                                  )}
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                      <div className="row">
                        <div className="col-lg-6 ms-auto">
                          <div className="total-order w-100 max-widthauto m-auto mb-4">
                            <ul>
                              <li>
                                <h4>Total Tax(₹)</h4>
                                <h5>
                                  {totalTax.current
                                    ? Number(totalTax.current).toFixed(2)
                                    : 0}
                                </h5>
                              </li>
                              <li>
                                <h4>
                                  Discount
                                  {discountType.label == "Percent"
                                    ? "(%)"
                                    : discountType.label == "Cash"
                                    ? "(₹)"
                                    : ""}
                                </h4>
                                <h5>
                                  {discountValue
                                    ? Number(discountValue).toFixed(2)
                                    : 0}
                                </h5>
                              </li>
                              <li>
                                <h4>Shipping(₹)</h4>
                                <h5>{shippingValue ? shippingValue : 0}</h5>
                              </li>
                              <li>
                                <h4>Grand Total(₹)</h4>
                                <h5>
                                  {grandTotal
                                    ? Number(grandTotal).toFixed(2)
                                    : 0}
                                </h5>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-3 col-sm-6 col-12">
                          <div className="input-blocks">
                            <label>Due Amount(₹)</label>
                            <div className="input-groupicon calender-input">
                              <input
                                disabled
                                value={
                                  Number(grandTotal).toFixed(2) -
                                  Number(paidAmount).toFixed(2)
                                }
                                type="number"
                                min={0}
                                className="custom-date-picker"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-sm-6 col-12">
                          <div className="input-blocks">
                            <label>Due Date</label>
                            <div className="input-groupicon calender-input">
                              <input
                                onChange={(e) => {
                                  setDueDate(e.target.value);
                                }}
                                type="date"
                                className="custom-date-picker"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-sm-6 col-12">
                          <div className="input-blocks">
                            <label>Received Amount(₹)</label>
                            <div className="input-groupicon select-code">
                              <input
                                value={paidAmount}
                                onChange={(e) => setPaidAmount(e.target.value)}
                                type="number"
                                min={0}
                                defaultValue={0}
                                className="p-2"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-sm-6 col-12">
                          <div className="input-blocks mb-5">
                            <label>Payment Status</label>
                            <Select
                              value={
                                paymentStatus?.value
                                  ? paymentStatus
                                  : [
                                      {
                                        label: "Choose",
                                        value: null,
                                      },
                                    ]
                              }
                              onChange={(value) => setPaymentStatus(value)}
                              className="select"
                              options={[
                                {
                                  label: "Completed",
                                  value: "Completed",
                                },
                                {
                                  label: "Pending",
                                  value: "Pending",
                                },
                              ]}
                              placeholder="status"
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-sm-6 col-12">
                          <div className="input-blocks mb-5">
                            <label>Payment Method</label>

                            <div className="input-groupicon select-code">
                              <Select
                                value={
                                  paymentMethod?.value
                                    ? paymentMethod
                                    : [
                                        {
                                          label: "Choose",
                                          value: null,
                                        },
                                      ]
                                }
                                onChange={(value) => setPaymentMethod(value)}
                                className="select"
                                options={[
                                  {
                                    label: "Cash",
                                    value: "Cash",
                                  },
                                  {
                                    label: "Card",
                                    value: "Card",
                                  },
                                  {
                                    label: "Online",
                                    value: "Online",
                                  },
                                ]}
                                placeholder="status"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-3 col-sm-6 col-12">
                          <div className="input-blocks mb-5">
                            <label>Update Stock</label>

                            <div className="input-groupicon select-code">
                              <input
                                type="radio"
                                checked={updateStockCheck}
                                onClick={() => setUpdateStockCheck(true)}
                                name="updatestock"
                              />
                              <span style={{ marginRight: 30, marginLeft: 10 }}>
                                Yes
                              </span>
                              <input
                                type="radio"
                                checked={!updateStockCheck}
                                onClick={() => setUpdateStockCheck(false)}
                                name="updatestock"
                              />
                              <span style={{ marginLeft: 10 }}>No</span>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-12 text-end">
                          <button
                            type="button"
                            className="btn btn-cancel add-cancel me-3"
                            data-bs-dismiss="modal"
                            onClick={clearData}
                          >
                            Cancel
                          </button>
                          <div
                            className={`btn btn-submit ${
                              isLoading ? "disabled" : ""
                            }`}
                            onClick={submitData}
                          >
                            Submit
                          </div>
                        </div>
                      </div>
                    </form>
                    <span style={{ fontStyle: "italic" }}>
                      All fields with <span style={{ color: "red" }}>*</span>{" "}
                      are required
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomerModal from={"Quotation"} target="#add-quotation" />
    </div>
  );
};

export default AddSaleBooking;
