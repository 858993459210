/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import { all_routes } from "../../Router/all_routes";
import { DatePicker } from "antd";
import AddCategory from "../../core/modals/inventory/addcategory";
import AddBrand from "../../core/modals/addbrand";
import {
  ArrowLeft,
  Calendar,
  ChevronDown,
  ChevronUp,
  Info,
  LifeBuoy,
  List,
  PlusCircle,
  // Trash2,
  // X,
} from "feather-icons-react/build/IconComponents";
import { useDispatch, useSelector } from "react-redux";
import {
  alertData,
  productsData,
  setToogleHeader,
} from "../../core/redux/action";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import axios from "axios";
import { API_URL } from "../../constants/AppConstants";
import AddWarehouseModal from "../../core/modals/peoples/AddWarehouseModal";
import AddSubcategory from "../../core/modals/inventory/addsubcategory";
import { fetchProductsData, fetchStocksData } from "../../utils/utilities";
// import ImageWithBasePath from "../../core/img/imagewithbasebath";

const EditProduct = (props) => {
  const route = all_routes;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const login_data = useSelector((state) => state.login_data);
  const data = useSelector((state) => state.toggle_header);
  const warehouse_data = useSelector((state) => state.warehouse_data);
  const category_data = useSelector((state) => state.category_data);
  const subcategory_data = useSelector((state) => state.subcategory_data);
  const brand_data = useSelector((state) => state.brand_data);
  const selected_store = useSelector((state) => state.selected_store);
  const unit_data = useSelector((state) => state.unit_data);
  const detail_data = useSelector((state) => state.detail_data);
  const products_data = useSelector((state) => state.products_data);
  const stocks_data = useSelector((state) => state.stocks_data);

  const [selectedData, setSelectedData] = useState({});
  const [selectedStockData, setSelectedStockData] = useState({});

  const [productStore, setProductStore] = useState(selected_store);
  const [productWarehouse, setProductWarehouse] = useState({});
  const [productName, setProductName] = useState("");
  const [productSKU, setProductSKU] = useState("");
  const [productCategory, setProductCategory] = useState("");
  const [productSubCategory, setProductSubCategory] = useState("");

  const [productSubCategoryDropdown, setProductSubCategoryDropdown] = useState([
    {
      label: "Choose",
      value: null,
    },
  ]);
  const [productBrand, setProductBrand] = useState("");
  const [productUnit, setProductUnit] = useState("");
  const [productSellingType, setProductSellingType] = useState("");
  const [productItemCode, setProductItemCode] = useState("");
  const [productDescription, setProductDescription] = useState("");

  const [productTaxType, setProductTaxType] = useState("");
  const [productTaxValue, setProductTaxValue] = useState("");
  const [productDiscountType, setProductDiscountType] = useState("");
  const [productDiscountValue, setProductDiscountValue] = useState("");
  const [productClubbedUnit, setProductClubbedUnit] = useState("");
  const [productAlternateQty, setProductAlternateQty] = useState("");
  const [productQtyAlert, setProductQtyAlert] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  let subCategoryDropdownChange = useRef(false);

  const renderCollapseTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Collapse
    </Tooltip>
  );

  const store = [{ value: null, label: "Choose" }];
  const warehouse = [{ value: null, label: "Choose" }];
  const category = [{ value: null, label: "Choose" }];
  const subcategory = [{ value: null, label: "Choose" }];
  const brand = [{ value: null, label: "Choose" }];
  const unit = [{ value: null, label: "Choose" }];
  const sellingtype = [
    { value: null, label: "Choose" },
    { value: "Transactional Selling", label: "Transactional selling" },
    { value: "Solution Selling", label: "Solution selling" },
  ];
  const barcodesymbol = [{ value: null, label: "Choose" }];
  const taxtype = [
    { value: "Exclusive", label: "Exclusive" },
    { value: "Sales Tax", label: "Sales Tax" },
  ];
  const discounttype = [
    { value: null, label: "Choose" },
    { value: "Percentage", label: "Percentage" },
    { value: "Cash", label: "Cash" },
  ];

  useEffect(() => {
    fetchProductsData(login_data, selected_store[0]);
    fetchStocksData(login_data, selected_store[0]);
  }, [selected_store]);

  useEffect(() => {
    if (stocks_data && Object.keys(selectedData).length) {
      stocks_data.forEach((item) => {
        if (item.productId == selectedData._id) {
          setSelectedStockData(item);
        }
      });
    }
  }, [stocks_data, selectedData]);

  useEffect(() => {
    let filteredArr = [
      {
        label: "Choose",
        value: null,
      },
    ];

    if (
      selectedData &&
      productCategory?.label &&
      (productCategory?.value != selectedData?.productCategory?.value ||
        subCategoryDropdownChange.current)
    ) {
      subCategoryDropdownChange.current = true;
      subcategory_data?.forEach((item) => {
        if (productCategory?.value == item?.parentCategory?._id) {
          filteredArr.push({
            label: item.categoryName,
            value: item.id,
          });
        }
      });
      setProductSubCategoryDropdown(filteredArr);
      setProductSubCategory([
        {
          label: "Choose",
          value: null,
        },
      ]);
    }
  }, [productCategory]);

  useEffect(() => {
    if (products_data?.length) {
      products_data?.forEach((product) => {
        if (product._id == detail_data.id) {
          setSelectedData(product);
        }
      });
    }
  }, [detail_data, products_data]);

  useEffect(() => {
    if (selectedData) {
      setProductWarehouse(selectedData?.productWarehouse);
      setProductName(selectedData?.productName);
      setProductSKU(selectedData?.productSKU);
      setProductCategory(selectedData?.productCategory);
      setProductBrand(selectedData?.productBrand);
      setProductSubCategory(selectedData?.productSubCategory);
      setProductSellingType(selectedData?.productSellingType);
      setProductItemCode(selectedData?.productItemCode);
      setProductDescription(selectedData?.productDescription);
      setProductTaxType(selectedData?.productTaxType);
      setProductTaxValue(selectedData?.productTaxValue);
      setProductDiscountType(selectedData?.productDiscountType);
      setProductDiscountValue(selectedData?.productDiscountValue);
      setProductUnit(selectedData?.productUnit);
      setProductClubbedUnit(selectedData?.productClubbedUnit);
      setProductAlternateQty(selectedData?.productAlternateQty);
      setProductQtyAlert(selectedData?.productQtyAlert);
    }
  }, [selectedData]);

  useEffect(() => {
    if (productName)
      setProductSKU(
        `${productName?.slice(0, 4)}/${
          productCategory ? productCategory.label.slice(0, 4) : "cat"
        }/${productBrand ? productBrand.label.slice(0, 4) : "brnd"}`
      );
  }, [productName, productCategory, productBrand]);

  async function submitData() {
    if (
      !productName ||
      !productCategory ||
      !productBrand ||
      !productSellingType ||
      !productItemCode ||
      !productUnit ||
      !productClubbedUnit ||
      !productAlternateQty ||
      !productQtyAlert
    ) {
      dispatch(
        alertData({
          type: "error",
          msg: "Please fill the required fields",
        })
      );
      setIsLoading(false);
      return;
    }

    setIsLoading(true);

    let dataBody = {
      id: selectedData?._id,
      productStore,
      productWarehouse,
      productName,
      productSKU,
      productCategory,
      productSubCategory,
      productBrand,
      productUnit,
      productSellingType,
      productItemCode,
      productDescription,
      productTaxType,
      productTaxValue,
      productDiscountType,
      productDiscountValue,
      productClubbedUnit,
      productAlternateQty,
      productQtyAlert,
      storeId: selected_store[0]?._id,
      createdOn: selectedData?.createdOn,
      updatedOn: new Date().toLocaleDateString().split(",")[0],
      userCode: login_data?.empCode,
    };

    let stockDataBody = {
      id: selectedStockData?._id,
      productId: selectedStockData?.productId,
      productStore,
      productWarehouse,
      productName,
      productSKU,
      productUnit,
      productCurrentQty: selectedStockData?.productCurrentQty,
      productClubbedUnit,
      productCurrentAlternateQty: (
        Number(selectedStockData?.productCurrentQty) /
        Number(productAlternateQty)
      ).toFixed(2),
      productAlternateQty: productAlternateQty,
      productItemCode,
      productQtyAlert,
      storeId: selected_store[0]?._id,
      stockStatus: selectedData?.stockStatus,
      createdOn: selectedData?.createdOn,
      updatedOn: new Date().toLocaleDateString().split(",")[0],
      userCode: login_data?.empCode,
    };

    try {
      let response = await axios.put(
        API_URL + "products/" + selectedData?._id,
        dataBody
      );
      if (response.status === 200) {
        dispatch(
          alertData({
            type: "success",
            msg: "Product Updated successfully!",
          })
        );

        setTimeout(() => {
          setIsLoading(false);
        }, 1000);

        setTimeout(async () => {
          let response2 = await axios.put(
            API_URL + "stocks/" + selectedData?._id,
            stockDataBody
          );
          if (response2.status === 200) {
            dispatch(
              alertData({
                type: "success",
                msg: "Stock Updated successfully!",
              })
            );
          }
        }, 2500);
      }
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="add-item d-flex">
            <div className="page-title">
              <h4>Edit Product</h4>
              <span style={{ fontStyle: "italic" }}>
                All fields with <span style={{ color: "red" }}>*</span> are
                required
              </span>
            </div>
          </div>
          <ul className="table-top-head">
            <li>
              <div className="page-btn">
                <Link to={route.productlist} className="btn btn-secondary">
                  <ArrowLeft className="me-2" />
                  Back to Product
                </Link>
              </div>
            </li>
            <li>
              <OverlayTrigger placement="top" overlay={renderCollapseTooltip}>
                <Link
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Collapse"
                  id="collapse-header"
                  className={data ? "active" : ""}
                  onClick={() => {
                    dispatch(setToogleHeader(!data));
                  }}
                >
                  <ChevronUp className="feather-chevron-up" />
                </Link>
              </OverlayTrigger>
            </li>
          </ul>
        </div>
        {/* /add */}
        <form>
          <div className="card">
            <div className="card-body add-product pb-0">
              <div
                className="accordion-card-one accordion"
                id="accordionExample"
              >
                <div className="accordion-item">
                  <div className="accordion-header" id="headingOne">
                    <div
                      className="accordion-button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-controls="collapseOne"
                    >
                      <div className="addproduct-icon">
                        <h5>
                          <Info className="add-info" />

                          <span>Product Information</span>
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <div className="row">
                        <div className="col-lg-6 col-sm-6 col-12">
                          <div className="mb-3 add-product">
                            <label className="form-label">Store</label>
                            <input
                              disabled
                              type="text"
                              className="form-control"
                              value={selected_store[0]?.storeName}
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-sm-6 col-12">
                          <div className="mb-3 add-product">
                            <label className="form-label">
                              Warehouse<span style={{ color: "red" }}>*</span>
                            </label>
                            <div className="d-flex justify-content-between align-items-center">
                              <div style={{ width: "92%" }}>
                                <Select
                                  value={
                                    productWarehouse?.value
                                      ? productWarehouse
                                      : [
                                          {
                                            label: "Choose",
                                            value: null,
                                          },
                                        ]
                                  }
                                  className="select"
                                  options={warehouse_data?.map((item) => {
                                    return {
                                      label: item.name,
                                      value: item.id,
                                    };
                                  })}
                                  placeholder="Choose Warehouse"
                                  onChange={(value) => {
                                    setProductWarehouse(value);
                                  }}
                                />
                              </div>
                              <div className="add-icon" style={{ width: "7%" }}>
                                <Link
                                  data-bs-toggle="modal"
                                  data-bs-target={`#add-warehouse`}
                                  onClick={(e) => e.preventDefault()}
                                  className="choose-add"
                                >
                                  <PlusCircle className="plus" />
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6 col-sm-6 col-12">
                          <div className="mb-3 add-product">
                            <label className="form-label">
                              Product Name{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={productName}
                              onChange={(e) => setProductName(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 v col-sm-6 col-12">
                          <div className="input-blocks add-product list">
                            <label>SKU</label>
                            <input
                              disabled
                              readOnly
                              type="text"
                              className="form-control list"
                              placeholder="Enter SKU"
                              value={productSKU}
                              onChange={(e) => setProductSKU(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="addservice-info">
                        <div className="row">
                          <div className="col-lg-6 col-sm-6 col-12">
                            <div className="mb-3 add-product">
                              <div className="add-newplus">
                                <label className="form-label">
                                  Category{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                              </div>
                              <div className="d-flex justify-content-between align-items-center">
                                <div style={{ width: "92%" }}>
                                  <Select
                                    value={
                                      productCategory?.value
                                        ? productCategory
                                        : [
                                            {
                                              label: "Choose",
                                              value: null,
                                            },
                                          ]
                                    }
                                    className="select"
                                    options={category_data?.map((item) => {
                                      return {
                                        label: item.categoryName,
                                        value: item.id,
                                      };
                                    })}
                                    placeholder="Choose Category"
                                    onChange={(value) => {
                                      setProductCategory(value);
                                    }}
                                  />
                                </div>
                                <div
                                  className="add-icon"
                                  style={{ width: "7%" }}
                                >
                                  <Link
                                    data-bs-toggle="modal"
                                    data-bs-target={`#add-category`}
                                    onClick={(e) => e.preventDefault()}
                                    className="choose-add"
                                  >
                                    <PlusCircle className="plus" />
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 col-sm-6 col-12">
                            <div className="mb-3 add-product">
                              <label className="form-label">
                                Sub Category{" "}
                              </label>

                              <div className="d-flex justify-content-between align-items-center">
                                <div style={{ width: "92%" }}>
                                  <Select
                                    className="select sub-category-dropdown"
                                    options={productSubCategoryDropdown}
                                    value={
                                      productSubCategory?.value
                                        ? productSubCategory
                                        : productSubCategoryDropdown[0]
                                    }
                                    placeholder="Choose Sub Category"
                                    onChange={(value) => {
                                      setProductSubCategory(value);
                                    }}
                                  />
                                </div>
                                <div
                                  className="add-icon"
                                  style={{ width: "7%" }}
                                >
                                  <Link
                                    data-bs-toggle="modal"
                                    data-bs-target={`#add-subcategory`}
                                    onClick={(e) => e.preventDefault()}
                                    className="choose-add"
                                  >
                                    <PlusCircle className="plus" />
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="add-product-new">
                      <div className="row">
                        <div className="col-lg-6 col-sm-6 col-12">
                          <div className="mb-3 add-product">
                            <div className="add-newplus">
                              <label className="form-label">
                                Brand<span style={{ color: "red" }}>*</span>
                              </label>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                              <div style={{ width: "92%" }}>
                                <Select
                                  value={
                                    productBrand?.value
                                      ? productBrand
                                      : [
                                          {
                                            label: "Choose",
                                            value: null,
                                          },
                                        ]
                                  }
                                  className="select"
                                  options={brand_data?.map((item) => {
                                    return {
                                      label: item.brandName,
                                      value: item.id,
                                    };
                                  })}
                                  placeholder="Choose Brand"
                                  onChange={(value) => setProductBrand(value)}
                                />
                              </div>
                              <div className="add-icon" style={{ width: "7%" }}>
                                <Link
                                  data-bs-toggle="modal"
                                  data-bs-target={`#add-brand`}
                                  onClick={(e) => e.preventDefault()}
                                  className="choose-add"
                                >
                                  <PlusCircle className="plus" />
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-sm-6 col-12">
                          <div className="mb-3 add-product">
                            <label className="form-label">
                              Selling Type{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <Select
                              value={
                                productSellingType?.value
                                  ? productSellingType
                                  : [
                                      {
                                        label: "Choose",
                                        value: null,
                                      },
                                    ]
                              }
                              className="select"
                              options={sellingtype}
                              placeholder="Choose"
                              onChange={(value) => setProductSellingType(value)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12 col-sm-6 col-12">
                          <div className="input-blocks add-product list">
                            <label>Item Code</label>
                            <input
                              disabled
                              value={productItemCode}
                              onChange={(e) =>
                                setProductItemCode(e.target.value)
                              }
                              type="text"
                              className="form-control list"
                              placeholder="Please Enter Item Code"
                            />
                          </div>
                        </div>
                      </div>
                      {/* Editor */}
                      <div className="col-lg-12">
                        <div className="input-blocks summer-description-box transfer mb-3">
                          <label>Description</label>
                          <textarea
                            className="form-control h-100"
                            rows={5}
                            maxLength={1000}
                            defaultValue={""}
                            value={productDescription}
                            onChange={(e) =>
                              setProductDescription(e.target.value)
                            }
                          />
                          <p className="mt-1">Maximum 1000 Characters</p>
                        </div>
                      </div>
                      {/* /Editor */}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="accordion-card-one accordion"
                id="accordionExample2"
              >
                <div className="accordion-item">
                  <div className="accordion-header" id="headingTwo">
                    <div
                      className="accordion-button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-controls="collapseTwo"
                    >
                      <div className="text-editor add-list">
                        <div className="addproduct-icon list icon">
                          <h5>
                            <LifeBuoy className="add-info" />
                            <span>Pricing &amp; Stocks</span>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse show"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample2"
                  >
                    <div className="accordion-body">
                      <div className="input-blocks add-products">
                        <label className="d-block">Product Type</label>
                        <div className="single-pill-product">
                          <ul
                            className="nav nav-pills"
                            id="pills-tab1"
                            role="tablist"
                          >
                            <li className="nav-item" role="presentation">
                              <span
                                className="custom_radio me-4 mb-0 active"
                                id="pills-home-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-home"
                                role="tab"
                                aria-controls="pills-home"
                                aria-selected="true"
                              >
                                <input
                                  type="radio"
                                  className="form-control"
                                  name="payment"
                                />
                                <span className="checkmark" /> Single Product
                              </span>
                            </li>
                            {/* <li className="nav-item" role="presentation">
                              <span
                                className="custom_radio me-2 mb-0"
                                id="pills-profile-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-profile"
                                role="tab"
                                aria-controls="pills-profile"
                                aria-selected="false"
                              >
                                <input
                                  type="radio"
                                  className="form-control"
                                  name="sign"
                                />
                                <span className="checkmark" /> Variable Product
                              </span>
                            </li> */}
                          </ul>
                        </div>
                      </div>
                      <div className="tab-content" id="pills-tabContent">
                        <div
                          className="tab-pane fade show active"
                          id="pills-home"
                          role="tabpanel"
                          aria-labelledby="pills-home-tab"
                        >
                          <div className="row"></div>
                          <div className="row">
                            <div className="col-lg-4 col-sm-6 col-12">
                              <div className="input-blocks add-product">
                                <label>Tax Type</label>
                                <Select
                                  className="select"
                                  options={taxtype}
                                  placeholder="Select Option"
                                  value={productTaxType}
                                  onChange={(value) => setProductTaxType(value)}
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 col-12">
                              <div className="input-blocks add-product">
                                <label>Tax Value(%)</label>
                                <input
                                  type="number"
                                  min={0}
                                  placeholder="Choose"
                                  value={productTaxValue}
                                  onChange={(e) =>
                                    setProductTaxValue(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-4 col-sm-6 col-12">
                              <div className="input-blocks add-product">
                                <label>Discount Type</label>
                                <Select
                                  className="select"
                                  options={discounttype}
                                  placeholder="Choose"
                                  value={productDiscountType}
                                  onChange={(value) =>
                                    setProductDiscountType(value)
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 col-12">
                              <div className="input-blocks add-product">
                                <label>Discount Value</label>
                                <input
                                  type="number"
                                  min={0}
                                  placeholder="Choose"
                                  value={productDiscountValue}
                                  onChange={(e) =>
                                    setProductDiscountValue(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-4 col-sm-6 col-12">
                              <div className="mb-3 add-product">
                                <div className="add-newplus">
                                  <label className="form-label">
                                    Main Unit
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                </div>
                                <Select
                                  value={
                                    productUnit?.value
                                      ? productUnit
                                      : [
                                          {
                                            label: "Choose",
                                            value: null,
                                          },
                                        ]
                                  }
                                  className="select"
                                  options={unit_data?.map((item) => {
                                    return {
                                      label: item.unit,
                                      value: item.id,
                                    };
                                  })}
                                  placeholder="Choose"
                                  onChange={(value) => setProductUnit(value)}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-4 col-sm-6 col-12">
                              <div className="input-blocks add-product">
                                <label>
                                  Alternate Unit{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <Select
                                  value={
                                    productClubbedUnit?.value
                                      ? productClubbedUnit
                                      : [
                                          {
                                            label: "Choose",
                                            value: null,
                                          },
                                        ]
                                  }
                                  className="select"
                                  options={unit_data?.map((item) => {
                                    return {
                                      label: item.unit,
                                      value: item.id,
                                    };
                                  })}
                                  placeholder="Choose"
                                  onChange={(value) =>
                                    setProductClubbedUnit(value)
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 col-12">
                              <div className="input-blocks add-product">
                                <label>
                                  Alternate Qty{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <input
                                  type="number"
                                  min={0}
                                  placeholder=""
                                  value={productAlternateQty}
                                  onChange={(e) =>
                                    setProductAlternateQty(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 col-12">
                              <div className="input-blocks add-product">
                                <label>
                                  Low Stock Alert Qty(Main Unit){" "}
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <input
                                  min={0}
                                  type="number"
                                  placeholder=""
                                  value={productQtyAlert}
                                  onChange={(e) =>
                                    setProductQtyAlert(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="btn-addproduct mb-4">
              <button
                type="button"
                className="btn btn-cancel me-2"
                onClick={() => navigate(route.productlist)}
              >
                Cancel
              </button>
              <div
                className={`btn btn-submit ${isLoading ? "disabled" : ""}`}
                onClick={submitData}
              >
                Save Product
              </div>
            </div>
          </div>
        </form>
        {/* /add */}
      </div>

      <AddCategory />
      <AddSubcategory />
      <AddBrand />
      <AddWarehouseModal />
    </div>
  );
};

export default EditProduct;
