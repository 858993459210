/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../core/breadcrumbs";
import { Link } from "react-router-dom";
import { Filter, Sliders, User, Globe, Edit, Eye, Trash2 } from "react-feather";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Table } from "antd";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import SupplierModal from "../../core/modals/peoples/supplierModal";
import axios from "axios";
import { API_URL } from "../../constants/AppConstants";
import { loaderData, supplierData } from "../../core/redux/action";
import { fetchSuppliersData } from "../../utils/utilities";
import "./suppliers.css";

const Suppliers = () => {
  const login_data = useSelector((state) => state.login_data);
  const supplier_data = useSelector((state) => state.supplier_data);
  const purchase_data = useSelector((state) => state.purchase_data);
  const selected_store = useSelector((state) => state.selected_store);
  const dispatch = useDispatch();

  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [supplierModal, setSupplierModal] = useState(false);
  const [accountList, setAccountList] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState({});
  const [selectedDataId, setSelectedDataId] = useState("");
  const [selectedData, setSelectedData] = useState({});

  const toggleFilterVisibility = () => {
    setIsFilterVisible((prevVisibility) => !prevVisibility);
  };

  const options = [
    { value: "sortByDate", label: "Sort by Date" },
    // { value: "140923", label: "14 09 23" },
    // { value: "110923", label: "11 09 23" },
  ];
  const optionsTwo = [{ label: "Choose Customer Name", value: "" }];

  const countries = [{ label: "Choose Country", value: "" }];

  const columns = [
    {
      title: "Supplier Name",
      dataIndex: "supplierName",
    },

    {
      title: "Email",
      dataIndex: "supplierEmail",
    },

    {
      title: "Phone",
      dataIndex: "supplierPhone",
    },

    {
      title: "Country",
      dataIndex: "supplierCountry",
      render: (data) => data.label,
    },
    {
      title: "State",
      dataIndex: "supplierState",
      render: (data) => data.label,
    },
    {
      title: "City",
      dataIndex: "supplierCity",
      render: (data) => data.label,
    },

    {
      title: "Action",
      dataIndex: "_id",
      render: (data) => (
        <div className="action-table-data">
          <div className="edit-delete-action">
            <div className="input-block add-lists"></div>

            {/* Edit button */}
            <Link
              className="me-2 p-2"
              data-bs-toggle="modal"
              data-bs-target="#edit-units"
              onClick={(e) => {
                e.stopPropagation();
                setSelectedDataId(data);
              }}
            >
              <Edit className="feather-edit" />
            </Link>

            {/* Delete button */}
            <div
              className="confirm-text p-2 feather-trash-container"
              onClick={(e) => {
                e.stopPropagation();
                setSelectedDataId(data);
                deleteData(data);
              }}
            >
              <Trash2 className="feather-trash-2" />
            </div>
          </div>
        </div>
      ),
    },
  ];

  const MySwal = withReactContent(Swal);

  useEffect(() => {
    fetchSuppliersData(login_data, selected_store[0]);
  }, [selected_store]);

  useEffect(() => {
    if (selectedDataId) {
      supplier_data?.forEach((supplier) => {
        if (supplier._id == selectedDataId) {
          setSelectedData(supplier);
        }
      });
    }
  }, [selectedDataId]);

  async function deleteData(dataId) {
    dispatch(
      loaderData({
        type: "Loader_Data",
        payload: true,
      })
    );
    if (dataId) {
      try {
        let response = await axios.put(API_URL + "suppliers/delete/" + dataId);
        if (response.status === 200) {
          fetchSuppliersData(login_data, selected_store[0]);
          dispatch(
            loaderData({
              type: "Loader_Data",
              payload: false,
            })
          );
        }
      } catch (err) {
        console.log(err);
      }
    }
  }

  const showConfirmationAlert = () => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes, delete it!",
      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        MySwal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          className: "btn btn-success",
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      } else {
        MySwal.close();
      }
    });
  };

  const handleModalClose = () => {
    setSupplierModal(false);
  };

  const fetchLedger = async (id) => {
    const res = await axios.get(API_URL + "supplierledger/supplier/" + id);
    const data = await res.data;
    setAccountList(data);
  };

  const supplierDetails = (data) => {
    if (data) {
      setSupplierModal(true);
      if (supplier_data) {
        const filteredData = supplier_data.filter(
          (item) => item._id === data._id
        );
        setSelectedSupplier(filteredData[0]);
      }
    }
    fetchLedger(data._id);
  };

  let closingBalance = 0;

  return (
    <div className="page-wrapper">
      <div className="content">
        <Breadcrumbs
          maintitle="Supplier List "
          subtitle="Manage Your Supplier"
          addButton="Add New Supplier"
          modalId="add-supplier"
        />
        {/* /product list */}
        <div className="card table-list-card">
          <div className="card-body">
            <div className="table-top">
              <div className="search-set">
                <div className="search-input">
                  <input
                    type="text"
                    placeholder="Search"
                    className="form-control form-control-sm formsearch"
                  />
                  <Link to className="btn btn-searchset">
                    <i data-feather="search" className="feather-search" />
                  </Link>
                </div>
              </div>
              {/* <div className="search-path">
                <Link
                  className={`btn btn-filter ${
                    isFilterVisible ? "setclose" : ""
                  }`}
                  id="filter_search"
                >
                  <Filter
                    className="filter-icon"
                    onClick={toggleFilterVisibility}
                  />
                  <span onClick={toggleFilterVisibility}>
                    <ImageWithBasePath
                      src="assets/img/icons/closes.svg"
                      alt="img"
                    />
                  </span>
                </Link>
              </div> */}
            </div>
            {/* /Filter */}
            <div
              className={`card${isFilterVisible ? " visible" : ""}`}
              id="filter_inputs"
              style={{ display: isFilterVisible ? "block" : "none" }}
            >
              <div className="card-body pb-0">
                <div className="row">
                  <div className="col-lg-3 col-sm-6 col-12">
                    <div className="input-blocks">
                      <User className="info-img" />
                      <Select
                        options={optionsTwo}
                        placeholder="Choose Customer Name"
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6 col-12">
                    <div className="input-blocks">
                      <Globe className="info-img" />
                      <Select
                        options={countries}
                        placeholder="Choose Country"
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6 col-12 ms-auto">
                    <div className="input-blocks">
                      <a className="btn btn-filters ms-auto">
                        {" "}
                        <i
                          data-feather="search"
                          className="feather-search"
                        />{" "}
                        Search{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /Filter */}
            <div className="table-responsive">
              <Table
                className="table datanew"
                columns={columns}
                dataSource={supplier_data}
                rowKey={(record) => record._id}
                style={{ cursor: "pointer" }}
                onRow={(record) => ({
                  onClick: () => {
                    supplierDetails(record);
                  },
                })}
              />
            </div>
          </div>
        </div>
        {/* /product list */}
      </div>

      <Modal
        title="Supplier Details"
        open={supplierModal}
        onCancel={handleModalClose}
        footer={false}
        // footer={() => {
        //   return (
        //     <p>Footer</p>
        //   )
        // }
        // }
        width={"90%"}
        style={{ maxWidth: "90%", top: 20 }}
      >
        {selectedSupplier && (
          <div className="container">
            <div className="row supplier-details">
              <div className="col-md-4">
                <table className="table">
                  <tbody>
                    <tr>
                      <td className="fw-bold" style={{ maxWidth: "120px" }}>
                        Name:
                      </td>
                      <td>{selectedSupplier.supplierName}</td>
                    </tr>
                    <tr>
                      <td className="fw-bold" style={{ maxWidth: "120px" }}>
                        Address:
                      </td>
                      <td>{selectedSupplier.supplierAddress}</td>
                    </tr>
                    <tr>
                      <td className="fw-bold" style={{ maxWidth: "120px" }}>
                        City:
                      </td>
                      <td>{selectedSupplier?.supplierCity?.label}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="col-md-4">
                <table className="table">
                  <tbody>
                    <tr>
                      <td className="fw-bold" style={{ maxWidth: "120px" }}>
                        State:
                      </td>
                      <td>{selectedSupplier?.supplierState?.label}</td>
                    </tr>

                    <tr>
                      <td className="fw-bold" style={{ maxWidth: "120px" }}>
                        Zip:
                      </td>
                      <td>{selectedSupplier.supplierZip}</td>
                    </tr>
                    <tr>
                      <td className="fw-bold" style={{ maxWidth: "120px" }}>
                        Phone Number:
                      </td>
                      <td>{selectedSupplier?.supplierPhone}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="col-md-4">
                <table className="table">
                  <tbody>
                    <tr>
                      <td className="fw-bold" style={{ maxWidth: "120px" }}>
                        Whatsapp Number:
                      </td>
                      <td>{selectedSupplier.supplierPhone}</td>
                    </tr>
                    <tr>
                      <td className="fw-bold" style={{ maxWidth: "120px" }}>
                        Purchaser Manager:
                      </td>
                      <td>-</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="br-20">
              <table className="table table-bordered ledger-table mt-3 br-20">
                <thead>
                  <tr>
                    <th>Ref Order #</th>
                    <th>Invoice No. #</th>
                    <th>Date</th>
                    <th>Description</th>
                    <th>UTR No.</th>
                    <th>Status</th>
                    <th>Credit</th>
                    <th>Debit</th>
                    <th>Closing Balance</th>
                  </tr>
                </thead>
                <tbody>
                  {accountList.length > 0 ? (
                    accountList.map((transaction, index) => {
                      const formattedDate = new Date(
                        transaction.transactionDate || transaction.createdAt
                      ).toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      });
                      // const [prefix, refNumber] = transaction.description.split('#');
                      if (transaction.typeOfLedger === "credit") {
                        closingBalance += parseFloat(transaction.amount);
                      } else {
                        closingBalance -= parseFloat(transaction.amount);
                      }

                      return (
                        <tr key={index}>
                          <td className="ref-number">{"-"}</td>
                          <td
                            className={`ref-number ${
                              transaction.typeOfLedger === "credit"
                                ? "credit"
                                : "debit"
                            }`}
                          >
                            {`#${transaction.transactionId}` || "-"}
                          </td>
                          <td>{formattedDate}</td>
                          {/* {
                        transaction.description.includes("#")
                       ? <td>
                        {prefix}
                        <span className="ref-number color">#{refNumber}</span>
                      </td> : 
                      
                      } */}
                          <td
                            style={{
                              textAlign: "left",
                              maxWidth: "100px",
                              textWrap: "balance",
                            }}
                          >
                            {transaction.description || "-"}
                          </td>
                          <td>{transaction.utr || "-"}</td>
                          <td>{transaction.status || "-"}</td>
                          <td>
                            {transaction.typeOfLedger === "credit"
                              ? `₹ ${parseFloat(transaction.amount).toFixed(2)}`
                              : "-"}
                          </td>
                          <td>
                            {transaction.typeOfLedger === "debit"
                              ? `₹ ${parseFloat(transaction.amount).toFixed(2)}`
                              : "-"}
                          </td>
                          <td>₹ {closingBalance.toFixed(2)}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td
                        colSpan={9}
                        style={{ textAlign: "center", fontStyle: "italic" }}
                      >
                        No Ledger Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </Modal>
      <SupplierModal selectedData={selectedData} />
    </div>
  );
};

export default Suppliers;
