/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Select from "react-select";
import ImageWithBasePath from "../../img/imagewithbasebath";
import { Link } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../../constants/AppConstants";
import { alertData, storesData } from "../../../core/redux/action";
import { useDispatch, useSelector } from "react-redux";
import stateCityListArr from "../../../data/StateCityList.json";
import { fetchStoresData } from "../../../utils/utilities";

const StoreModal = () => {
  const login_data = useSelector((state) => state.login_data);
  const detail_data = useSelector((state) => state.detail_data);
  const stores_data = useSelector((state) => state.stores_data);

  const [storeName, setStoreName] = useState("");
  const [storeEmail, setStoreEmail] = useState("");
  const [storePhone, setStorePhone] = useState("");
  const [storeAddress, setStoreAddress] = useState("");
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [storeCity, setStoreCity] = useState({
    label: "Choose",
    value: null,
  });
  const [storeState, setStoreState] = useState({
    label: "Choose",
    value: null,
  });
  const [storeDescription, setStoreDescription] = useState("");
  const [storeStatus, setStoreStatus] = useState("");
  const [selectedData, setSelectedData] = useState({});

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [inValid, setInValid] = useState(false);

  useEffect(() => {
    if (detail_data?.id && stores_data?.length) {
      stores_data?.forEach((store) => {
        if (store._id == detail_data.id) {
          setSelectedData(store);
        }
      });
    }
  }, [detail_data]);

  useEffect(() => {
    if (Object.keys(selectedData).length) {
      setStoreName(selectedData.storeName);
      setStoreEmail(selectedData.storeEmail);
      setStorePhone(selectedData.storePhone);
      setStoreAddress(selectedData.storeAddress);
      setStoreCity(selectedData.storeCity);
      setStoreState(selectedData.storeState);
      setStoreDescription(selectedData.storeDescription);
      setStoreStatus(selectedData.storeStatus == "Active" ? true : false);
    }
  }, [selectedData]);

  useEffect(() => {
    console.log(storeStatus);
  }, [selectedData]);

  useEffect(() => {
    if (Object.keys(stateCityListArr)?.length) {
      let stateArr = [];
      Object.keys(stateCityListArr).forEach((state) => {
        stateArr.push({
          label: state,
          value: state,
        });
      });
      setStateList(stateArr);
    }
  }, []);

  useEffect(() => {
    if (storeState?.value) {
      if (Object.keys(stateCityListArr)?.length) {
        let cityArr = [];
        Object.entries(stateCityListArr).forEach((state, index) => {
          if (state[0] == storeState?.value) {
            state[1]?.length &&
              state[1]?.forEach((city) => {
                cityArr.push({
                  label: city,
                  value: city,
                });
              });
          }
        });
        setCityList(cityArr);
      }
    }
  }, [storeState]);

  useEffect(() => {
    fetchStoresData();
  }, [isLoading]);

  function clearData() {
    setStoreName("");
    setStoreEmail("");
    setStorePhone("");
    setStoreAddress("");
    setStoreCity({
      label: "Choose",
      value: null,
    });
    setStoreState({
      label: "Choose",
      value: null,
    });
    setStoreDescription("");
  }

  async function submitData() {
    if (inValid) {
      dispatch(
        alertData({
          type: "error",
          msg: "Please enter valid email!",
        })
      );
      setInValid(false);
      return;
    }

    if (
      !storeName ||
      !storeEmail ||
      !storeAddress ||
      !storeCity ||
      !storeState ||
      !storePhone
    ) {
      dispatch(
        alertData({
          type: "error",
          msg: "Please fill the required fields!",
        })
      );
      return;
    }

    let dataBody = {
      storeName,
      storeEmail,
      storePhone,
      storeAddress,
      storeCity,
      storeState,
      storeDescription,
      storeStatus: "Active",
      createdOn: new Date().toLocaleDateString().split(",")[0],
      userCode: login_data?.empCode,
      itemActive: true,
    };

    try {
      setIsLoading(true);
      let response = await axios.post(API_URL + "stores", dataBody);
      if (response.status === 200) {
        dispatch(
          alertData({
            type: "success",
            msg: "Store added successfully!",
          })
        );
        fetchStoresData();
        setTimeout(() => {
          setIsLoading(false);
          const myModal = document.querySelector("#add-units .close");
          myModal.click();
        }, 1000);
        clearData();
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  }

  async function editData() {
    if (inValid) {
      dispatch(
        alertData({
          type: "error",
          msg: "Please enter valid email!",
        })
      );
      setInValid(false);
      return;
    }

    if (
      !storeName ||
      !storeEmail ||
      !storeAddress ||
      !storeCity ||
      !storeState ||
      !storePhone
    ) {
      dispatch(
        alertData({
          type: "error",
          msg: "Please fill the required fields!",
        })
      );
      return;
    }

    let dataBody = {
      storeName,
      storeEmail,
      storePhone,
      storeAddress,
      storeCity,
      storeState,
      storeDescription,
      storeStatus: storeStatus ? "Active" : "InActive",
    };

    try {
      setIsLoading(true);
      let response = await axios.put(
        API_URL + "stores/" + selectedData?._id,
        dataBody
      );
      if (response.status === 200) {
        dispatch(
          alertData({
            type: "success",
            msg: "Store updated successfully!",
          })
        );
        fetchStoresData();
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  }

  return (
    <>
      {/* Add Store */}
      <div className="modal fade" id="add-units">
        <div className="modal-dialog modal-dialog-centered custom-modal-two">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>Add Store</h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={clearData}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <form>
                    {/* <div className="modal-title-head people-cust-avatar">
                      <h6>Avatar</h6>
                    </div> */}
                    {/* <div className="new-employee-field">
                      <div className="profile-pic-upload">
                        <div className="profile-pic">
                          <span>
                            <i
                              data-feather="plus-circle"
                              className="plus-down-add"
                            />{" "}
                            Add Image
                          </span>
                        </div>
                        <div className="mb-3">
                          <div className="image-upload mb-0">
                            <input type="file" />
                            <div className="image-uploads">
                              <h4>Change Image</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                    <div className="row">
                      <div className="col-lg-4 pe-0">
                        <div className="mb-3">
                          <label className="form-label">
                            Store Name<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            required
                            type="text"
                            className="form-control"
                            value={storeName}
                            onChange={(e) => setStoreName(e.target.value)}
                            placeholder="Enter Store Name"
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 pe-0">
                        <div className="mb-3">
                          <label className="form-label">
                            Email<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            value={storeEmail}
                            placeholder="Enter Email Address"
                            onChange={(e) => {
                              const email = e.target.value;
                              // Update the email in state regardless of its validity
                              setStoreEmail(email);
                              // Regex pattern to validate email
                              const emailRegex =
                                /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
                              // Validate the email and update the invalid flag accordingly
                              if (emailRegex.test(email) || email === "") {
                                setInValid(false); // Assuming there's a state to track validity
                              } else {
                                setInValid(true);
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 pe-0">
                        <div className="input-blocks">
                          <label className="mb-2">
                            Phone<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            value={storePhone}
                            onChange={(e) => {
                              // Filter out non-numeric characters
                              const filteredValue = e.target.value.replace(
                                /[^0-9]/g,
                                ""
                              );
                              // You can apply additional validation for phone number length if needed
                              // For example, if phone numbers should be exactly 10 digits
                              if (filteredValue.length <= 10) {
                                setStorePhone(filteredValue);
                              }
                            }}
                            className="form-control form-control-lg group_formcontrol"
                            id="phone"
                            name="phone"
                            type="text"
                            placeholder="Enter Phone Number"
                          />
                        </div>
                      </div>
                      <div className="col-lg-12 pe-0">
                        <div className="mb-3">
                          <label className="form-label">
                            Address<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            value={storeAddress}
                            className="form-control"
                            onChange={(e) => setStoreAddress(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 pe-0">
                        <div className="mb-3">
                          <label className="form-label">
                            State<span style={{ color: "red" }}>*</span>
                          </label>
                          <Select
                            value={
                              storeState?.value
                                ? storeState
                                : [
                                    {
                                      label: "Choose",
                                      value: null,
                                    },
                                  ]
                            }
                            onChange={(item) => setStoreState(item)}
                            className="select"
                            options={stateList}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 pe-0">
                        <div className="mb-3">
                          <label className="form-label">
                            City<span style={{ color: "red" }}>*</span>
                          </label>
                          <Select
                            value={
                              storeCity?.value
                                ? storeCity
                                : [
                                    {
                                      label: "Choose",
                                      value: null,
                                    },
                                  ]
                            }
                            className="select"
                            options={cityList}
                            onChange={(item) => setStoreCity(item)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="mb-3 input-blocks">
                          <label className="form-label">Descriptions</label>
                          <textarea
                            value={storeDescription}
                            onChange={(e) =>
                              setStoreDescription(e.target.value)
                            }
                            className="form-control mb-1"
                            defaultValue={""}
                          />
                          <p>Maximum 60 Characters</p>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer-btn">
                      <button
                        type="button"
                        className="btn btn-cancel me-2"
                        data-bs-dismiss="modal"
                        onClick={clearData}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className={`btn btn-submit ${
                          isLoading ? "disabled" : ""
                        }`}
                        onClick={submitData}
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                  <span style={{ fontStyle: "italic" }}>
                    All fields with <span style={{ color: "red" }}>*</span> are
                    required
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Add Store */}

      {/* Edit Store */}
      <div className="modal fade" id="edit-units">
        <div className="modal-dialog modal-dialog-centered custom-modal-two">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>Edit Store</h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <form>
                    {/* <div className="modal-title-head people-cust-avatar">
                      <h6>Avatar</h6>
                    </div> */}
                    {/* <div className="new-employee-field">
                      <div className="profile-pic-upload">
                        <div className="profile-pic people-profile-pic">
                          <ImageWithBasePath
                            src="assets/img/profiles/profile.png"
                            alt="Img"
                          />
                          <Link to="#">
                            <i
                              data-feather="x-square"
                              className="x-square-add"
                            />
                          </Link>
                        </div>
                        <div className="mb-3">
                          <div className="image-upload mb-0">
                            <input type="file" />
                            <div className="image-uploads">
                              <h4>Change Image</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                    <div className="row">
                      <div className="col-lg-4 pe-0">
                        <div className="mb-3">
                          <label className="form-label">
                            Store Name<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={storeName}
                            onChange={(e) => setStoreName(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 pe-0">
                        <div className="mb-3">
                          <label className="form-label">
                            Email<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            value={storeEmail}
                            onChange={(e) => setStoreEmail(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 pe-0">
                        <div className="input-blocks">
                          <label className="mb-2">
                            Phone<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            value={storePhone}
                            className="form-control form-control-lg group_formcontrol"
                            id="phone2"
                            name="phone2"
                            type="text"
                            onChange={(e) => setStorePhone(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12 pe-0">
                        <div className="mb-3">
                          <label className="form-label">
                            Address<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={storeAddress}
                            onChange={(e) => setStoreAddress(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 pe-0">
                        <div className="mb-3">
                          <label className="form-label">
                            State<span style={{ color: "red" }}>*</span>
                          </label>
                          <Select
                            className="select"
                            options={stateList}
                            value={storeState}
                            onChange={(value) => {
                              setStoreState(value);
                              setStoreCity({
                                label: "Choose",
                                value: null,
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 pe-0">
                        <div className="mb-3">
                          <label className="form-label">
                            City<span style={{ color: "red" }}>*</span>
                          </label>
                          <Select
                            className="select"
                            options={cityList}
                            value={storeCity}
                            onChange={(value) => setStoreCity(value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="mb-3 input-blocks">
                          <label className="form-label">Descriptions</label>
                          <textarea
                            className="form-control mb-1"
                            defaultValue={""}
                            value={storeDescription}
                            onChange={(e) =>
                              setStoreDescription(e.target.value)
                            }
                          />
                          <p>Maximum 60 Characters</p>
                        </div>
                      </div>
                      <div
                        style={{ width: "fit-content" }}
                        className="status-toggle modal-status d-inline-flex align-items-center"
                      >
                        <span
                          className="status-label"
                          style={{ marginRight: 20 }}
                        >
                          Status
                        </span>
                        <input
                          value={storeStatus}
                          checked={storeStatus}
                          type="checkbox"
                          id="user2"
                          className="check"
                          onChange={() => {
                            setStoreStatus(!storeStatus);
                          }}
                        />
                        <label htmlFor="user2" className="checktoggle" />
                      </div>
                    </div>
                    <div className="modal-footer-btn">
                      <button
                        type="button"
                        className="btn btn-cancel me-2"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className={`btn btn-submit ${
                          isLoading ? "disabled" : ""
                        }`}
                        onClick={editData}
                      >
                        Save Changes
                      </button>
                    </div>
                  </form>
                  <span style={{ fontStyle: "italic" }}>
                    All fields with <span style={{ color: "red" }}>*</span> are
                    required
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Edit Store */}
    </>
  );
};

export default StoreModal;
