export const all_routes = {
  //--------------------------------------------- Main Page Routes -----------------------------------------------------
  dashboard: "/dashboard",
  activity: "/activity",
  // salesdashboard: "/sales-dashboard",

  //--------------------------------------------- Inventory Routes -----------------------------------------------------
  productlist: "/product-list",
  productdetails: "/product-details",
  editproduct: "/edit-product",
  addproduct: "/add-product",
  // expiredproduct: "/expired-products",
  lowstock: "/low-stocks",
  categorylist: "/category-list",
  subcategories: "/sub-categories",
  brandlist: "/brand-list",
  units: "/units",
  // variantattributes: "/variant-attributes",
  // variantyattributes: "/variant-attributes",
  // warranty: "/warranty",
  // barcode: "/barcode",
  // qrcode: "/qrcode",

  //--------------------------------------------- Stock Routes -----------------------------------------------------
  managestock: "/manage-stocks",
  managetransport: "/manage-transport",

  // stockadjustment: "/stock-adjustment",
  // stocktransfer: "/stock-transfer",

  //--------------------------------------------- Sales Routes -----------------------------------------------------
  saleslist: "/sales-list",
  // invoiceReport: "/invoices",
  salesreturn: "/sales-returns",
  saleQuotationList: "/sale-quotation-list",
  saleBooking: "/sale-booking",
  referrals: "/referrals",
  // pos: "/pos",

  //--------------------------------------------- Promo Routes -----------------------------------------------------
  coupons: "/coupons",

  //--------------------------------------------- Purchases Routes -----------------------------------------------------
  purchaseQuotationList: "/purchase-quotation-list",
  purchaseBooking: "/purchase-booking",
  purchaselist: "/purchase-list",
  // purchaseorderreport: "/purchase-order-report",
  purchasereturn: "/purchase-returns",

  //--------------------------------------------- Finance & Accounts Routes -----------------------------------------------------
  expenselist: "/expense-list",
  expensecategory: "/expense-category",

  //--------------------------------------------- People Routes -----------------------------------------------------
  customers: "/customers",
  suppliers: "/suppliers",
  storelist: "/store-list",
  warehouses: "/warehouse",

  //--------------------------------------------- HRM Routes -----------------------------------------------------
  employeegrid: "/employees-grid",
  addemployee: "/add-employee",
  editemployee: "/edit-employee",
  // leavesadmin: "leaves-admin",
  // leavesemployee: "/leaves-employee",
  // leavestype: "/leave-types",
  departmentgrid: "/department-grid",
  departmentlist: "/department-list",
  designation: "/designation",
  shift: "/shift",
  // attendanceemployee: "/attendance-employee",
  // attendanceadmin: "attendance-admin",
  // payslip: "/payslip",
  // holidays: "/holidays",

  //--------------------------------------------- Reports Routes -----------------------------------------------------
  salesreport: "/sales-report",
  purchasereport: "/purchase-report",
  inventoryreport: "/inventory-report",
  invoicereport: "/invoice-report", // Already in Sales Routes
  supplierreport: "/supplier-report",
  customerreport: "/customer-report",
  expensereport: "/expense-report",
  incomereport: "/income-report",
  taxreport: "/tax-report",
  profitloss: "/profit-loss-report",

  //--------------------------------------------- User Management Routes -----------------------------------------------------
  users: "/users",
  rolespermission: "/roles-permissions",
  // permissions: "/permissions",
  // deleteaccount: "/delete-account",

  //---------------------------------------------Transfers -----------------------------------------------------
  receipts: "/receipts",
  payments: "/payments",
  bankaccounts: "/bankaccounts",

  //--------------------------------------------- Pages Routes -----------------------------------------------------
  profile: "/profile",
  // signin: "/signin",
  // signintwo: "/signin-2",
  signinthree: "/signin",
  // register: "/register",
  // registerTwo: "/register-2",
  // registerThree: "/register",
  // forgotPassword: "/forgot-password",
  // forgotPasswordTwo: "/forgot-password-2",
  forgotPasswordThree: "/forgot-password",
  // resetpassword: "/reset-password",
  // resetpasswordTwo: "/reset-password-2",
  resetpasswordThree: "/reset-password",
  // emailverification: "/email-verification",
  // emailverificationTwo: "/email-verification-2",
  emailverificationThree: "/email-verification",
  // twostepverification: "/two-step-verification",
  // twostepverificationTwo: "/two-step-verification-2",
  twostepverificationThree: "/two-step-verification",
  lockscreen: "/lock-screen",
  error404: "/error-404",
  error500: "/error-500",
  blankpage: "/blank-page",
  comingsoon: "/coming-soon",
  undermaintenance: "/under-maintenance",

  //--------------------------------------------- Settings Routes -----------------------------------------------------
  chat: "/chat",
  videocall: "/video-call",
  audiocall: "/audio-call",
  email: "/email",
  callhistory: "/call-history",
  todo: "/todo",
  wizard: "/form-wizard",
  calendar: "/calendar",
  appearance: "/appearance",
  socialauthendication: "/social-authentication",
  languagesettings: "/language-settings",
  invoicesettings: "/invoice-settings",
  printersettings: "/printer-settings",
  possettings: "/pos-settings",
  customfields: "/custom-fields",
  emailsettings: "/email-settings",
  smssettings: "/sms-gateway",
  otpsettings: "/otp-settings",
  gdbrsettings: "/gdpr-settings",
  paymentgateway: "/payment-gateway-settings",
  banksettingslist: "/bank-settings-list",
  banksettingsgrid: "/bank-settings-grid",
  taxrates: "/tax-rates",
  currencysettings: "/currency-settings",
  storagesettings: "/storage-settings",
  banipaddress: "/ban-ip-address",
  generalsettings: "/general-settings",
  securitysettings: "/security-settings",
  notification: "/notification",
  connectedapps: "/connected-apps",
  systemsettings: "/system-settings",
  companysettings: "/company-settings",
  localizationsettings: "/localization-settings",
  prefixes: "/prefixes",
  preference: "/preference",
  notes: "/notes",
  filemanager: "/file-manager",

  //--------------------------------------------- UI Interface Routes -----------------------------------------------------
  // alerts: "/ui-alerts",
  // accordion: "/ui-accordion",
  // avatar: "/ui-avatar",
  // badges: "/ui-badges",
  // borders: "/ui-borders",
  // buttons: "ui-buttons",
  // buttonsgroup: "/ui-buttons-group",
  // breadcrumb: "/ui-breadcrumb",
  // cards: "/ui-cards",
  // dropdowns: "/ui-dropdowns",
  // colors: "/ui-colors",
  // carousel: "/ui-carousel",
  // navtabs: "/ui-nav-tabs",
  // grid: "/ui-grid",
  // images: "/ui-images",
  // lightbox: "/ui-lightbox",
  // media: "/ui-media",
  // modals: "/ui-modals",
  // offcanvas: "/ui-offcanvas",
  // pagination: "/ui-pagination",
  // typography: "/ui-typography",
  // tooltip: "/ui-tooltips",
  // draganddrop: "/ui-drag-drop",
  // clipboard: "/ui-clipboard",
  // popover: "/ui-popovers",
  // tablebasic: "/tables-basic",
  // datatable: "/data-tables",
  // basicinput: "/form-basic-inputs",
  // checkboxradio: "/form-checkbox-radios",
  // inputgroup: "/form-input-groups",
  // gridgutters: "/form-grid-gutters",
  // formselect: "/form-select",
  // fileupload: "/form-fileupload",
  // formmask: "/form-mask",
  // formhorizontal: "/form-horizontal",
  // formvertical: "/form-vertical",
  // floatinglabel: "/form-floating-labels",
  // formvalidation: "/form-validation",
  // select2: "/form-select2",
  // toasts: "/ui-toasts",
  // video: "/ui-video",
  // sweetalerts: "/ui-sweetalerts",
  // spinner: "/ui-spinner",
  // progress: "/ui-progress",
  // placeholder: "/ui-placeholders",
  // rating: "/ui-rating",
  // texteditor: "/ui-text-editor",
  // counter: "/ui-counter",
  // scrollbar: "/ui-scrollbar",
  // stickynote: "/ui-stickynote",
  // timeline: "/ui-timeline",
  // apexchart: "/chart-apex",
  // chartjs: "/chart-js",
  // rangeslider: "/ui-rangeslider",
  // fontawesome: "/icon-fontawesome",
  // feathericon: "/icon-feather",
  // ionicicons: "/icon-ionic",
  // materialicons: "/icon-material",
  // pe7icons: "/icon-pe7",
  // simpleline: "/icon-simpleline",
  // themifyicons: "/icon-themify",
  // iconweather: "/icon-weather",
  // typicons: "/icon-typicon",
  // flagicons: "/icon-flag",
  // ribbon: "/ui-ribbon",
};
