/* eslint-disable no-unused-vars */
import { DatePicker } from "antd";
import { ChevronUp, Info } from "feather-icons-react/build/IconComponents";
import ArrowLeft from "feather-icons-react/build/IconComponents/ArrowLeft";
import React, { useEffect, useState } from "react";
import { PlusCircle } from "react-feather";
import { Link } from "react-router-dom";
import Select from "react-select";
import { all_routes } from "../../Router/all_routes";
import { useDispatch, useSelector } from "react-redux";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { alertData, setToogleHeader } from "../../core/redux/action";
import { API_URL } from "../../constants/AppConstants.js";
import axios from "axios";
import { employeesData } from "../../core/redux/action";
import stateCityListArr from "../../data/StateCityList.json";

const AddEmployee = () => {
  const login_data = useSelector((state) => state.login_data);
  const route = all_routes;
  const dispatch = useDispatch();
  const data = useSelector((state) => state.toggle_header);
  const selected_store = useSelector((state) => state.selected_store);
  const employees_data = useSelector((state) => state.employees_data);
  const employees_data_length = useSelector(
    (state) => state.employees_data_length
  );

  const [isLoading, setIsLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [empCode, setEmpCode] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState(new Date());
  const [gender, setGender] = useState({ label: "NA", value: "NA" });
  const [nationality, setNationality] = useState({
    label: "Choose",
    value: null,
  });
  const [joiningDate, setJoiningDate] = useState(new Date());
  const [shift, setShift] = useState({ label: "Choose", value: "Choose" });
  const [department, setDepartment] = useState({
    label: "Choose",
    value: null,
  });
  const [designation, setDesignation] = useState({
    label: "Choose",
    value: "Choose",
  });
  const [bloodGroup, setBloodGroup] = useState({
    label: "Choose",
    value: "Choose",
  });
  const [statesArr, setStateList] = useState([]);
  const [citiesArr, setCityList] = useState([]);

  const [emergencyContact1, setEmergencyContact1] = useState("");
  const [emergencyContact2, setEmergencyContact2] = useState("");
  const [address, setAddress] = useState("");
  const [country, setCountry] = useState({ label: "Choose", value: null });
  const [state, setState] = useState({ label: "Choose", value: null });
  const [city, setCity] = useState({ label: "Choose", value: null });
  const [zipcode, setZipcode] = useState("");
  const [password, setPassword] = useState("44345");
  const [showPassword, setShowPassword] = useState(false);
  const [profileImage, setProfileImage] = useState(null);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Check file size
      if (file.size > 2 * 1024 * 1024) {
        // 2MB in bytes
        dispatch(
          alertData({
            type: "error",
            msg: "File size exceeds the 2MB limit.",
          })
        );
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        const img = new Image();
        img.src = reader.result;
        img.onload = () => {
          if (img.width !== img.height) {
            dispatch(
              alertData({
                type: "error",
                msg: "Image dimensions must be 1:1  ratio (examples: 300px x 300px, 400px x 400px etc).",
              })
            );
          } else {
            setProfileImage(reader.result);
          }
        };
      };
      reader.readAsDataURL(file);
    }
  };

  const renderCollapseTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Collapse
    </Tooltip>
  );

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const genderArr = [
    { value: "Choose", label: "Choose" },
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
  ];
  const nationalityArr = [
    { value: "Choose", label: "Choose" },
    { value: "India", label: "India" },
  ];
  const typesArr = [
    { value: "Choose", label: "Choose" },
    { value: "Regular", label: "Regular" },
  ];
  const departmentsArr = [{ value: "Choose", label: "Choose" }];
  const designationArr = [
    { value: "Choose", role: null, label: "Choose" },
    { value: "Store_Manager", role: "2", label: "Store Manager" },
    { value: "Sales_Manager", role: "4", label: "Sales Manager" },
    { value: "Accountant", role: "3", label: "Accountant" },
    { value: "Sales_Person", role: "5", label: "Sales Person" },
  ];
  const bloodgroupArr = [
    { value: "Select", label: "Select" },
    { value: "A+", label: "A+" },
    { value: "A-", label: "A-" },
    { value: "B+", label: "B+" },
    { value: "B-", label: "B-" },
    { value: "O+", label: "O+" },
    { value: "O-", label: "O-" },
    { value: "AB+", label: "AB+" },
    { value: "AB-", label: "AB-" },
  ];
  const countryArr = [
    {
      label: "India",
      value: "India",
    },
  ];

  useEffect(() => {
    if (Object.keys(stateCityListArr)?.length) {
      let stateArr = [];
      Object.keys(stateCityListArr).forEach((state) => {
        stateArr.push({
          label: state,
          value: state,
        });
      });
      setStateList(stateArr);
    }
  }, []);

  useEffect(() => {
    // if (
    //   (state &&
    //     state?.value != selectedData?.customerState?.value) ||
    //   customerStateChanged.current
    // ) {
    //   customerStateChanged.current = true;
    setCity({
      label: "Choose",
      value: null,
    });
    // }
    if (Object.keys(stateCityListArr)?.length) {
      let cityArr = [];
      Object.entries(stateCityListArr).forEach((currState, index) => {
        if (currState[0] == state?.value) {
          currState[1]?.length &&
            currState[1]?.forEach((city) => {
              cityArr.push({
                label: city,
                value: city,
              });
            });
        }
      });
      setCityList(cityArr);
    }
  }, [state]);

  function clearData() {
    setFirstName("");
    setLastName("");
    setEmail("");
    setContact("");
    setEmpCode("");
    setDateOfBirth(new Date());
    setGender({ label: "Choose", value: null });
    setNationality({ label: "Choose", value: null });
    setJoiningDate(new Date());
    setShift({ label: "Choose", value: "Choose" });
    setDepartment({ label: "Choose", value: null });
    setDesignation({ label: "Choose", value: null });
    setBloodGroup({ label: "Choose", value: null });
    setEmergencyContact1("");
    setEmergencyContact2("");
    setAddress("");
    setCountry({ label: "Choose", value: null });
    setState({ label: "Choose", value: null });
    setCity({ label: "Choose", value: null });
    setZipcode("");
    setPassword("");
  }

  async function fetchData() {
    try {
      let response = await axios.get(API_URL + "employees");
      if (response.status === 200) {
        dispatch(employeesData(response.data));
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function submitData() {
    setIsLoading(true);
    if (
      !firstName ||
      !lastName ||
      !email ||
      !contact ||
      !dateOfBirth ||
      !gender ||
      !nationality ||
      !joiningDate ||
      !designation ||
      !emergencyContact1 ||
      !address ||
      !country ||
      !state ||
      !city ||
      !zipcode
    ) {
      dispatch(
        alertData({
          type: "error",
          msg: "Please fill all the required fields.",
        })
      );

      setIsLoading(false);
      return;
    }

    if (!email.includes("@")) {
      dispatch(
        alertData({
          type: "error",
          msg: "Please fill all the required fields.",
        })
      );
      setIsLoading(false);
      return;
    }

    if (!email.includes("@")) {
      dispatch(
        alertData({
          type: "error",
          msg: "Please fill valid email.",
        })
      );
      setIsLoading(false);
      return;
    }

    if (
      isNaN(contact) ||
      contact.length != 10 ||
      isNaN(emergencyContact1) ||
      emergencyContact1.length != 10
    ) {
      dispatch(
        alertData({
          type: "error",
          msg: "Please fill valid contact.",
        })
      );
      setIsLoading(false);
      return;
    }

    if (isNaN(zipcode) || zipcode.length != 6) {
      dispatch(
        alertData({
          type: "error",
          msg: "Please fill valid contact.",
        })
      );
      setIsLoading(false);
      return;
    }

    // const validateEmail = (email) => {
    //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    //   return emailRegex.test(email);
    // };

    // validateEmail(email);

    // if (!validateEmail) {
    //   dispatch(
    //     alertData({
    //       type: "error",
    //       msg: "Please fill all the required fields.",
    //     })
    //   );
    //   setIsLoading(false);
    //   return;
    // }

    const createPassword = (fname, lname, dob, joiningDate, designation) => {
      // Helper function to get initials
      const getInitials = (str) => {
        return str ? str[0].toUpperCase() : "";
      };

      // Extract parts of the inputs
      const initials = getInitials(fname) + getInitials(lname);
      const dobPart = dob.$D; // Last 2 digits of DOB year
      const joiningPart = joiningDate.$D; // Last 2 digits of joining year
      const employeenumber = employees_data?.length + 1;
      // Combine parts and ensure length is 8 characters
      let password = `${initials}${dobPart}${joiningPart}${employeenumber}`;

      // If password length is less than 8, pad with random alphanumeric characters
      while (password.length < 8) {
        password += Math.random().toString(36).charAt(2); // Random alphanumeric character
      }

      // If password length is more than 8, trim it
      if (password.length > 8) {
        password = password.slice(0, 8);
      }

      return password;
    };

    let dataBody = {
      storeId: selected_store[0]?._id,
      profileImage,
      firstName,
      lastName,
      email,
      contact,
      empCode,
      dateOfBirth: new Date(dateOfBirth).toLocaleDateString().split(",")[0],
      gender: gender.value,
      nationality: nationality.value,
      joiningDate: new Date(joiningDate).toLocaleDateString().split(",")[0],
      shift: shift,
      designation: designation,
      bloodGroup: bloodGroup,
      emergencyContact1,
      emergencyContact2,
      address,
      country: country.value,
      state: state.value,
      city: city.value,
      zipcode,
      // password: createPassword(
      //   firstName,
      //   lastName,
      //   dateOfBirth,
      //   joiningDate,
      //   designation
      // ),
      password: "1234",
      status: "Active",
      createdOn: new Date().toLocaleDateString().split(",")[0],
      userCode: login_data?.empCode,
      itemActive: true,
    };

    try {
      setIsLoading(true);
      let response = await axios.post(API_URL + "employees", dataBody);
      if (response.status === 200) {
        dispatch(
          alertData({
            type: "success",
            msg: "Employee added successfully!",
          })
        );
        setIsLoading(false);
        fetchData();
        clearData();
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  }

  const getEmpCode = (fname, lname, dob) => {
    console.log(employees_data_length);
    setEmpCode(
      `EMP-${
        Number(employees_data_length) < 10
          ? `0${employees_data_length + 1}`
          : employees_data_length + 1
      }`
    );
  };

  const getBase64 = () => {
    if (profileImage) {
      console.log(profileImage);
    }
  };

  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>New Employee</h4>
                <h6>Create new Employee</h6>
              </div>
            </div>
            <ul className="table-top-head">
              <li>
                <div className="page-btn">
                  <Link to={route.employeegrid} className="btn btn-secondary">
                    <ArrowLeft className="me-2" />
                    Back to Employee List
                  </Link>
                </div>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderCollapseTooltip}>
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    id="collapse-header"
                    className={data ? "active" : ""}
                    onClick={() => {
                      dispatch(setToogleHeader(!data));
                    }}
                  >
                    <ChevronUp />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
          </div>
          {/* /product list */}
          <form>
            <div className="card">
              <div className="card-body">
                <div className="new-employee-field">
                  <div className="card-title-head">
                    <h6>
                      <span>
                        <Info className="feather-edit" />
                      </span>
                      Employee Information
                    </h6>
                  </div>
                  <div className="profile-pic-upload">
                    <div className="profile-pic">
                      {profileImage ? (
                        <img
                          src={profileImage}
                          alt="Profile"
                          className="profile-image"
                        />
                      ) : (
                        <span>
                          <PlusCircle className="plus-down-add" />
                          Profile Photo
                        </span>
                      )}
                    </div>
                    <div className="input-blocks mb-0">
                      <div className="image-upload mb-0">
                        <input type="file" onChange={handleImageUpload} />
                        <div className="image-uploads">
                          <h4>Change Image</h4>
                        </div>
                        <span style={{ fontStyle: "italic" }}>
                          Upload maximum file size of 2MB, with dimensions 500px
                          x 500px
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-4 col-md-6">
                      <div className="mb-3">
                        <label className="form-label">
                          First Name<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          required
                          type="text"
                          className="form-control"
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="mb-3">
                        <label className="form-label">
                          Last Name<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          required
                          type="text"
                          className="form-control"
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="mb-3">
                        <label className="form-label">
                          Email<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          required
                          type="email"
                          className="form-control"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="mb-3">
                        <label className="form-label">
                          Contact Number<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          required
                          type="text"
                          className="form-control"
                          value={contact}
                          onChange={(e) => {
                            const phoneNumber = e.target.value;
                            const phoneNumberRegex = /^[0-9]*$/;
                            if (
                              phoneNumberRegex.test(phoneNumber) &&
                              phoneNumber.length <= 10
                            ) {
                              setContact(phoneNumber);
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="mb-3">
                        <label className="form-label">Emp Code</label>
                        <input
                          disabled
                          type="text"
                          className="form-control"
                          value={empCode}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="input-blocks">
                        <label>
                          Date of Birth<span style={{ color: "red" }}>*</span>
                        </label>
                        <div className="input-groupicon calender-input">
                          <DatePicker
                            required
                            selected={dateOfBirth}
                            onChange={(value) => {
                              setDateOfBirth(value);
                              getEmpCode(firstName, lastName, value);
                            }}
                            type="date"
                            className="filterdatepicker"
                            dateFormat="dd-MM-yyyy"
                            placeholder="Choose Date"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="mb-3">
                        <label className="form-label">
                          Gender<span style={{ color: "red" }}>*</span>
                        </label>
                        <Select
                          required
                          value={gender}
                          onChange={(value) => setGender(value)}
                          className="select"
                          options={genderArr}
                          placeholder="Choose"
                        />
                      </div>
                    </div>
                    {/* <div className="col-lg-4 col-md-6">
                      <div className="mb-3">
                        <label className="form-label">Nationality</label>
                        <Select
                          value={nationality}
                          onChange={(value) => setNationality(value)}
                          className="select"
                          options={nationalityArr}
                          placeholder="Choose"
                        />
                      </div>
                    </div> */}
                    <div className="col-lg-4 col-md-6">
                      <div className="input-blocks">
                        <label>
                          Joining Date<span style={{ color: "red" }}>*</span>
                        </label>
                        <div className="input-groupicon calender-input">
                          <DatePicker
                            required
                            selected={joiningDate}
                            onChange={(value) => setJoiningDate(value)}
                            type="date"
                            className="filterdatepicker"
                            dateFormat="dd-MM-yyyy"
                            placeholder="Choose Date"
                          />
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-lg-4 col-md-6">
                      <div className="mb-3">
                        <div className="add-newplus">
                          <label className="form-label">Shift</label>
                          <Link to="#">
                            <span>
                              <PlusCircle className="plus-down-add" />
                              Add new
                            </span>
                          </Link>
                        </div>
                        <Select
                          value={shift}
                          onChange={(value) => setShift(value)}
                          className="select"
                          options={typesArr}
                          placeholder="Choose"
                        />
                      </div>
                    </div> */}
                    {/* <div className="col-lg-4 col-md-6">
                      <div className="mb-3">
                        <label className="form-label">Department</label>
                        <Select
                          value={department}
                          onChange={(value) => setDepartment(value)}
                          className="select"
                          options={departmentsArr}
                          placeholder="Choose"
                        />
                      </div>
                    </div> */}
                    <div className="col-lg-4 col-md-6">
                      <div className="mb-3">
                        <label className="form-label">
                          Designation<span style={{ color: "red" }}>*</span>
                        </label>
                        <Select
                          value={designation}
                          required
                          onChange={(value) => setDesignation(value)}
                          className="select"
                          options={[
                            {
                              label: "Choose",
                              value: "Choose",
                            },
                            ...designationArr.filter(
                              (data) =>
                                data.role != login_data?.roleId &&
                                data.role > login_data.roleId
                            ),
                          ]}
                          placeholder="Choose"
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="mb-3">
                        <label className="form-label">Blood Group</label>
                        <Select
                          value={bloodGroup}
                          onChange={(value) => setBloodGroup(value)}
                          className="select"
                          options={bloodgroupArr}
                          placeholder="Choose"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="other-info">
                    <div className="card-title-head">
                      <h6>
                        <span>
                          <Info className="feather-edit" />
                        </span>
                        Other Information
                      </h6>
                    </div>
                    <div className="row">
                      <div className="col-lg-4 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">
                            Emergency No 1{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            required
                            type="text"
                            className="form-control"
                            value={emergencyContact1}
                            onChange={(e) => {
                              const phoneNumber = e.target.value;
                              const phoneNumberRegex = /^[0-9]*$/;
                              if (
                                phoneNumberRegex.test(phoneNumber) &&
                                phoneNumber.length <= 10
                              ) {
                                setEmergencyContact1(phoneNumber);
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Emergency No 2</label>
                          <input
                            type="text"
                            className="form-control"
                            value={emergencyContact2}
                            onChange={(e) =>
                              setEmergencyContact2(e.target.value)
                            }
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6"></div>
                      <div className="col-lg-4 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">
                            Address<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            required
                            type="text"
                            className="form-control"
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">
                            Country<span style={{ color: "red" }}>*</span>
                          </label>
                          <Select
                            required
                            value={country}
                            onChange={(value) => setCountry(value)}
                            className="select"
                            options={countryArr}
                            placeholder="Select"
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">
                            State<span style={{ color: "red" }}>*</span>
                          </label>
                          <Select
                            required
                            value={state}
                            onChange={(value) => setState(value)}
                            className="select"
                            options={statesArr}
                            placeholder="Select"
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">
                            City<span style={{ color: "red" }}>*</span>
                          </label>
                          <Select
                            required
                            value={city}
                            onChange={(value) => setCity(value)}
                            className="select"
                            options={citiesArr}
                            placeholder="Select"
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">
                            Zipcode<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            required
                            type="text"
                            className="form-control"
                            value={zipcode}
                            onChange={(e) => setZipcode(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="pass-info">
                    <div className="card-title-head">
                      <h6>
                        <span>
                          <Info />
                        </span>
                        Password
                      </h6>
                    </div>
                    <div className="row">
                      <div className="col-lg-4 col-md-6">
                        <div className="input-blocks mb-md-0 mb-sm-3">
                          <label>Password</label>
                          <div className="pass-group">
                            <input
                              disabled
                              type="password"
                              className="pass-input"
                              placeholder="Enter your password"
                              value={password}
                            />
                            {/* <span
                              className={`fas toggle-password ${
                                showPassword ? "fa-eye" : "fa-eye-slash"
                              }`}
                              onClick={handleTogglePassword}
                            /> */}
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-lg-4 col-md-6">
                        <div className="input-blocks mb-0">
                          <label>Confirm Password</label>
                          <div className="pass-group">
                            <input
                              type={showConfirmPassword ? "text" : "password"}
                              className="pass-input"
                              placeholder="Enter your password"
                            />
                            <span
                              className={`fas toggle-password ${
                                showConfirmPassword ? "fa-eye" : "fa-eye-slash"
                              }`}
                              onClick={handleToggleConfirmPassword}
                            />
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /product list */}
            <div className="text-end mb-3">
              <button
                type="button"
                className="btn btn-cancel me-2"
                data-bs-dismiss="modal"
                onClick={clearData}
              >
                Cancel
              </button>
              <button
                type="button"
                className={`btn btn-submit ${isLoading ? "disabled" : ""}`}
                onClick={submitData}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddEmployee;
