/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom/dist";
import AddBrand from "../../core/modals/inventory/addbrand";
import EditBrand from "../../core/modals/inventory/editbrand";
import Swal from "sweetalert2";
import Table from "../../core/pagination/datatable";
import Select from "react-select";
import Sliders from "feather-icons-react/build/IconComponents/Sliders";
import {
  ChevronUp,
  Edit,
  Filter,
  PlusCircle,
  RotateCcw,
  StopCircle,
  Zap,
} from "feather-icons-react/build/IconComponents";
import { DatePicker } from "antd";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import {
  setToogleHeader,
  detailData,
  loaderData,
} from "../../core/redux/action";
import withReactContent from "sweetalert2-react-content";
import axios from "axios";
import { API_URL } from "../../constants/AppConstants";
import { brandData } from "../../core/redux/action";
import FilterModal from "../../core/modals/FilterModal";
import { fetchBrandsData } from "../../utils/utilities";

const BrandList = () => {
  const data = useSelector((state) => state.toggle_header);
  const login_data = useSelector((state) => state.login_data);

  const brand_data = useSelector((state) => state.brand_data);
  const selected_store = useSelector((state) => state.selected_store);
  const dispatch = useDispatch();
  const [isCategoryUpdated, setIsCategoryUpdated] = useState(false);

  const oldandlatestvalue = [{ value: null, label: "Sort by Date" }];
  const brandOptions = [{ value: null, label: "Choose" }];
  const status = [
    { value: null, label: "Choose" },
    { value: "Active", label: "Active" },
    { value: "InActive", label: "InActive" },
  ];
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [filters, setFilters] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState(brand_data);
  const toggleFilterVisibility = () => {
    setIsFilterVisible((prevVisibility) => !prevVisibility);
  };
  const [selectedDate, setSelectedDate] = useState(new Date());
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const filtersConfig = [
    {
      key: "status",
      label: "Status",
      type: "select",
      options: [
        { value: "active", label: "Active" },
        { value: "inactive", label: "Inactive" },
      ],
      placeholder: "Select Status",
    },
    {
      key: "sortOrder",
      label: "Sort By Date",
      type: "select",
      options: [
        { value: "newToOld", label: "Newest to Oldest" },
        { value: "oldToNew", label: "Oldest to Newest" },
      ],
      placeholder: "Sort Order",
    },
  ];

  const handleApplyFilters = (newFilters) => {
    setFilters(newFilters);
  };

  const renderTooltip = (props) => (
    <Tooltip id="pdf-tooltip" {...props}>
      Pdf
    </Tooltip>
  );
  const renderExcelTooltip = (props) => (
    <Tooltip id="excel-tooltip" {...props}>
      Excel
    </Tooltip>
  );
  const renderPrinterTooltip = (props) => (
    <Tooltip id="printer-tooltip" {...props}>
      Printer
    </Tooltip>
  );
  const renderRefreshTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Refresh
    </Tooltip>
  );
  const renderCollapseTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Collapse
    </Tooltip>
  );
  const columns = [
    {
      title: "Brand",
      dataIndex: "brandName",
      // sorter: (a, b) => a.brand.length - b.brand.length,
    },

    // {
    //     title: "Logo",
    //     dataIndex: "logo",
    //     render: (text, record) => (
    //         <span className="productimgname">
    //             <Link to="#" className="product-img stock-img">
    //                 <ImageWithBasePath alt="" src={record.logo} />
    //             </Link>
    //         </span>
    //     ),
    //     sorter: (a, b) => a.logo.length - b.logo.length,
    //     width: "5%"
    // },
    // {
    //     title: "Createdon",
    //     dataIndex: "createdon",
    //     sorter: (a, b) => a.createdon.length - b.createdon.length,
    // },
    {
      title: "Status",
      dataIndex: "brandStatus",
      render: (text) => (
        <span
          className={`badge ${text ? "badge-linesuccess" : "badge-linedanger"}`}
        >
          {text ? "Active" : "InActive"}
        </span>
      ),
      //   sorter: (a, b) => a.status.length - b.status.length,
    },
    {
      title: "Actions",
      dataIndex: "_id",
      render: (data) => (
        <td className="action-table-data">
          <div className="edit-delete-action">
            <Link
              className="me-2 p-2"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#edit-brand"
              onClick={() => {
                dispatch(
                  detailData({
                    type: "Brand",
                    id: data,
                  })
                );
              }}
            >
              <Edit className="feather-edit" />
            </Link>
            <Link
              className="confirm-text p-2"
              onClick={(e) => {
                e.preventDefault();
                deleteData(data);
              }}
            >
              <i data-feather="trash-2" className="feather-trash-2"></i>
            </Link>
          </div>
        </td>
      ),
    },
  ];
  const MySwal = withReactContent(Swal);

  useEffect(() => {
    if (searchTerm) {
      let brandArray = [...brand_data];
      let filteredArray = [];
      filteredArray = brandArray.filter((item) =>
        item.brandName.toLowerCase().includes(searchTerm)
      );
      setFilteredData(filteredArray);
    } else {
      setFilteredData(brand_data);
    }
  }, [searchTerm]);

  useEffect(() => {
    setFilteredData(brand_data);
  }, [brand_data]);

  useEffect(() => {
    fetchBrandsData(login_data, selected_store[0]);
  }, [selected_store, isCategoryUpdated]);

  async function deleteData(dataId) {
    dispatch(
      loaderData({
        type: "Loader_Data",
        payload: true,
      })
    );
    if (dataId) {
      try {
        let response = await axios.put(API_URL + "brands/delete/" + dataId);
        if (response.status === 200) {
          fetchBrandsData(login_data, selected_store[0]);
          dispatch(
            loaderData({
              type: "Loader_Data",
              payload: false,
            })
          );
        }
      } catch (err) {
        console.log(err);
      }
    }
  }

  const showConfirmationAlert = () => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes, delete it!",
      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        MySwal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          className: "btn btn-success",
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      } else {
        MySwal.close();
      }
    });
  };
  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Brand</h4>
                <h6>Manage your brands</h6>
              </div>
            </div>
            <ul className="table-top-head">
              {/* <li>
                <OverlayTrigger placement="top" overlay={renderTooltip}>
                  <Link>
                    <ImageWithBasePath
                      src="assets/img/icons/pdf.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderExcelTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top">
                    <ImageWithBasePath
                      src="assets/img/icons/excel.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderPrinterTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top">
                    <i data-feather="printer" className="feather-printer" />
                  </Link>
                </OverlayTrigger>
              </li> */}
              <li>
                <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top">
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderCollapseTooltip}>
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    id="collapse-header"
                    className={data ? "active" : ""}
                    onClick={() => {
                      dispatch(setToogleHeader(!data));
                    }}
                  >
                    <ChevronUp />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
            <div className="page-btn">
              <Link
                to="#"
                className="btn btn-added"
                data-bs-toggle="modal"
                data-bs-target="#add-brand"
              >
                <PlusCircle className="me-2" />
                Add New Brand
              </Link>
            </div>
          </div>
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <input
                      type="text"
                      placeholder="Search"
                      className="form-control form-control-sm formsearch"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <Link to className="btn btn-searchset">
                      <i data-feather="search" className="feather-search" />
                    </Link>
                  </div>
                </div>
                {/* <div className="search-path">
                  <span
                    className={`btn btn-filter ${
                      isFilterVisible ? "setclose" : ""
                    }`}
                    id="filter_search"
                  >
                    <Filter
                      className="filter-icon"
                      onClick={() => setIsModalVisible(true)}
                    />
                  </span>
                </div> */}
                {/* <div className="form-sort">
                  <Sliders className="info-img" />
                  <Select
                    className="select"
                    options={oldandlatestvalue}
                    placeholder="Newest"
                  />
                </div> */}
              </div>
              {/* /Filter */}

              <div
                className={`card${isFilterVisible ? " visible" : ""}`}
                id="filter_inputs"
                style={{ display: isFilterVisible ? "block" : "none" }}
              >
                <div className="card-body pb-0">
                  <div className="row">
                    <div className="col-lg-3 col-sm-6 col-12">
                      <div className="input-blocks">
                        <Zap className="info-img" />
                        <Select
                          className="select"
                          options={brandOptions}
                          placeholder="Choose Brand"
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-12">
                      <div className="input-blocks">
                        <div className="input-groupicon">
                          <DatePicker
                            selected={selectedDate}
                            onChange={handleDateChange}
                            type="date"
                            className="filterdatepicker"
                            dateFormat="dd-MM-yyyy"
                            placeholder="Choose Date"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-12">
                      <div className="input-blocks">
                        <i data-feather="stop-circle" className="info-img" />
                        <StopCircle className="info-img" />
                        <Select
                          className="select"
                          options={status}
                          placeholder="Choose Brand"
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-12 ms-auto">
                      <div className="input-blocks">
                        <Link className="btn btn-filters ms-auto">
                          {" "}
                          <i
                            data-feather="search"
                            className="feather-search"
                          />{" "}
                          Search{" "}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* /Filter */}
              <div className="table-responsive">
                <Table columns={columns} dataSource={filteredData} />
              </div>
            </div>
            {/* /product list */}
          </div>
        </div>
      </div>
      <FilterModal
        visible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        filtersConfig={filtersConfig}
        onApplyFilters={handleApplyFilters}
        initialValues={filters}
      />
      <AddBrand />
      <EditBrand setIsCategoryUpdated={setIsCategoryUpdated} />
    </div>
  );
};

export default BrandList;
