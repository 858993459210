/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useRef, useState } from "react";
import { redirect } from "react-router-dom";
import { API_BASE_URL } from "../constants/AppConstants";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function PrintPreview({ children, fileName, type, party }) {
  const [downloadPDFURL, setDownloadPDFURL] = useState(null);
  const [whatsappLink, setWhatsappLink] = useState("");

  const contentRef = useRef(null);
  const navigate = useNavigate();
  const uploadPDF = async () => {
    const canvas = await html2canvas(contentRef.current, { scale: 2 }); // Adjust scale as needed
    const imageData = canvas.toDataURL("image/jpeg", 1.0); // Use JPEG format for better compression
    const pdf = new jsPDF("p", "mm", "a4"); // Set PDF size to A4

    // Calculate the width and height to fit the A4 size
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();
    const aspectRatio = canvas.width / canvas.height;

    // Calculate the new dimensions to fit within A4 size
    let width = pdfWidth;
    let height = pdfWidth / aspectRatio;

    // If the height exceeds the A4 height, adjust the width and height accordingly
    if (height > pdfHeight) {
      height = pdfHeight;
      width = height * aspectRatio;
    }

    pdf.addImage(imageData, "JPEG", 0, 0, width, height); // Add the image to PDF

    const pdfFile = pdf.output("blob");
    if (!pdfFile) return;
    console.log(fileName);
    try {
      const response = await axios.post(`${API_BASE_URL}generate-upload-url`, {
        fileName: `${fileName}.${type}`,
        contentType: pdfFile.type,
      });

      const { uploadUrl } = response.data;

      await fetch(uploadUrl, {
        method: "PUT",
        headers: {
          "Content-type": "application/pdf",
        },
        body: pdfFile,
      });
      setDownloadPDFURL(
        `https://proedgebackend.s3.ap-south-1.amazonaws.com/${fileName}.${type}`
      );
      console.log(
        `https://proedgebackend.s3.ap-south-1.amazonaws.com/${fileName}.${type}`
      );
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const sendPDFLink = () => {
    const link = `https://wa.me/91${"7987278543"}?text=Hi%20Shalabh%2C%20here%20is%20your%20PDF%20${downloadPDFURL}%20Purchase%20Bill%20Link%3A%20Please%20download%20this%20is%20valid%20only%20this%207%20days%20from%20today.%0AThank%20you%2C%0ABest%20Regards%2C%0ATeam%20The%20FM%20Marketing%0Ahttps%3A%2F%2Fthefmmarketing.in `;
    window.location.href = link;
  };

  return (
    <div>
      <div style={{ padding: 20 }} ref={contentRef}>
        {children}
      </div>

      <button
        style={{
          position: "absolute",
          outline: "none",
          border: "1px solid teal",
          background: "rgb(255, 255, 255)",
          borderRadius: "8px",
          fontSize: "14px",
          padding: "5px 10px",
          color: "grey",
          fontWeight: 500,
          top: "40px",
          left: "40px",
        }}
        onClick={uploadPDF}
      >
        Confirm PDF
      </button>
      {downloadPDFURL && (
        <a
          href={downloadPDFURL}
          target="_blank"
          rel="noreferrer"
          style={{
            position: "absolute",
            outline: "none",
            border: "1px solid teal",
            background: "rgb(255, 255, 255)",
            borderRadius: "8px",
            fontSize: "14px",
            padding: "5px 10px",
            color: "grey",
            fontWeight: 500,
            top: "40px",
            left: "160px",
          }}
        >
          Download Uploaded PDF
        </a>
      )}
      {/* {downloadPDFURL && (
        <button onClick={sendPDFLink} target="_blank" rel="noreferrer">
          Share on Whatsapp
        </button>
      )} */}
    </div>
  );
}

export default PrintPreview;
