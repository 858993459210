import React, { useState } from "react";
import "./CommonFilter.scss";
import { Link } from "react-router-dom";
import { Filter } from "react-feather";
import ImageWithBasePath from "../core/img/imagewithbasebath";

function CommonFilter() {
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const toggleFilterVisibility = () => {
    setIsFilterVisible(!isFilterVisible);
  };
  return (
    <div className="common-filter">
      <Link
        onClick={toggleFilterVisibility}
        className={`btn btn-filter ${isFilterVisible ? "setclose" : ""}`}
        id="filter_search"
      >
        <Filter className="filter-icon" />
        <span>
          <ImageWithBasePath src="assets/img/icons/closes.svg" alt="img" />
        </span>
      </Link>

      {isFilterVisible ? (
        <div className="common-filter-dropdown">
          <div className="filter-header">Filter By:</div>
          <div className="filter-body">
            <div className="filter-left-panel">
              <div className="filter-item">
                <input type="checkbox" id="filter1" name="filter1" />
                <label htmlFor="filter1">Option 1</label>
              </div>
              <div className="filter-item">
                <input type="checkbox" id="filter2" name="filter2" />
                <label htmlFor="filter2">Option 2</label>
              </div>
              <div className="filter-item">
                <input type="checkbox" id="filter3" name="filter3" />
                <label htmlFor="filter3">Option 3</label>
              </div>
            </div>
            <div className="filter-right-panel">
              <div className="filter-item">
                <input type="checkbox" id="filter4" name="filter4" />
                <label htmlFor="filter4">Option 4</label>
              </div>
              <div className="filter-item">
                <input type="checkbox" id="filter5" name="filter5" />
                <label htmlFor="filter5">Option 5</label>
              </div>
              <div className="filter-item">
                <input type="checkbox" id="filter6" name="filter6" />
                <label htmlFor="filter6">Option 6</label>
              </div>
            </div>
          </div>
          <div className="filter-footer">
            <span className="clr-btn">Clear</span>
            <span className="apply-btn">Apply</span>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default CommonFilter;
