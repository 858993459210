/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import { useSelector } from "react-redux";
import { ArrowLeft } from "feather-icons-react/build/IconComponents";
import { Link } from "react-router-dom";

const ProductDetail = () => {
  const detail_data = useSelector((state) => state.detail_data);
  const products_data = useSelector((state) => state.products_data);
  const [selectedData, setSelectedData] = useState({});

  useEffect(() => {
    if (products_data?.length) {
      products_data?.forEach((product) => {
        if (product._id == detail_data.id) {
          setSelectedData(product);
        }
      });
    }
  }, []);

  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="page-title">
              <h4>Product Details</h4>
              <h6>Full details of a product</h6>
            </div>
            <div className="page-btn">
              <Link to={"/product-list"} className="btn btn-secondary">
                <ArrowLeft className="me-2" />
                Back to Product
              </Link>
            </div>
          </div>
          {/* /add */}
          <div className="row">
            <div className="col-lg-8 col-sm-12">
              <div className="card">
                <div className="card-body">
                  {/* <div className="bar-code-view">
                                        <ImageWithBasePath src="assets/img/barcode/barcode1.png" alt="barcode" />
                                        <a className="printimg">
                                            <ImageWithBasePath src="assets/img/icons/printer.svg" alt="print" />
                                        </a>
                                    </div> */}
                  <div className="productdetails">
                    <ul className="product-bar">
                      <li>
                        <h4>Product</h4>
                        <h6>{selectedData?.productName}</h6>
                      </li>
                      <li>
                        <h4>Item Code</h4>
                        <h6>{selectedData?.productItemCode}</h6>
                      </li>
                      <li>
                        <h4>SKU</h4>
                        <h6>{selectedData?.productSKU}</h6>
                      </li>
                      <li>
                        <h4>Category</h4>
                        <h6>{selectedData?.productCategory?.label}</h6>
                      </li>
                      <li>
                        <h4>Sub Category</h4>
                        <h6>{selectedData?.productSubCategory?.label}</h6>
                      </li>
                      <li>
                        <h4>Brand</h4>
                        <h6>{selectedData?.productBrand?.label}</h6>
                      </li>
                      <li>
                        <h4>Main Unit</h4>
                        <h6>{selectedData?.productUnit?.label}</h6>
                      </li>
                      <li>
                        <h4>Alternate Unit</h4>
                        <h6>{selectedData?.productClubbedUnit?.label}</h6>
                      </li>
                      <li>
                        <h4>Alternate Qty</h4>
                        <h6>{selectedData?.productAlternateQty}</h6>
                      </li>
                      <li>
                        <h4>Tax Type</h4>
                        <h6>{selectedData?.productTaxType?.label}</h6>
                      </li>
                      <li>
                        <h4>Tax Value</h4>
                        <h6>{selectedData?.productTaxValue}</h6>
                      </li>
                      <li>
                        <h4>Discount Type</h4>
                        <h6>{selectedData?.productDiscountType?.label}</h6>
                      </li>
                      <li>
                        <h4>Discount Value</h4>
                        <h6>{selectedData?.productDiscountValue}</h6>
                      </li>
                      <li>
                        <h4>Selling Type</h4>
                        <h6>{selectedData?.productSellingType?.label}</h6>
                      </li>
                      <li>
                        <h4>Description</h4>
                        <h6>{selectedData?.productDescription}</h6>
                      </li>
                      <li>
                        <h4>Store</h4>
                        <h6>{selectedData?.productStore?.[0]?.storeName}</h6>
                      </li>
                      <li>
                        <h4>Warehouse</h4>
                        <h6>{selectedData?.productWarehouse?.label}</h6>
                      </li>
                      <li>
                        <h4>Created On</h4>
                        <h6>{selectedData?.createdOn}</h6>
                      </li>
                      {/* <li>
                        <h4>Status</h4>
                        <h6>{selectedData?.productStatus}</h6>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-lg-4 col-sm-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="slider-product-details">
                                        <div className="owl-carousel owl-theme product-slide">
                                            <div className="slider-product">
                                                <ImageWithBasePath src="assets/img/products/product69.jpg" alt="img" />
                                                <h4>macbookpro.jpg</h4>
                                                <h6>581kb</h6>
                                            </div>
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
          </div>
          {/* /add */}
        </div>
      </div>
    </div>
  );
};

export default ProductDetail;
